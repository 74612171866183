<template>
  <v-card>
    <v-card-title class="px-2 py-0">
      <span class="text-overline primary--text pt-1">
        {{ getPartyFunction(item.partyFunction) }}
      </span>
      <v-spacer />
      <v-switch
        v-if="showToOrder(item.partyFunction)"
        true-value="Shipped on board"
        false-value="Received for shipment"
        class="shipped-switch ma-0 pa-0 pt-2"
        :ripple="false"
        hide-details
        inset
      >
        <template v-slot:label>
          <span class="text-caption primary--text">
            {{ $t('label.toOrder') }}
          </span>
        </template>
      </v-switch>

      <v-switch
        v-if="showSameAsConsignee(item.partyFunction)"
        true-value="Shipped on board"
        false-value="Received for shipment"
        class="shipped-switch ma-0 pa-0 pt-2"
        :ripple="false"
        hide-details
        inset
      >
        <template v-slot:label>
          <span class="text-caption primary--text">
            {{ $t('label.sameAsConsignee') }}
          </span>
        </template>
      </v-switch>
    </v-card-title>
    <v-divider class="ma-0 mb-2" />
    <v-card-text class="pa-0 pb-2">
      <v-form ref="form" class="pa-0">
        <v-row class="my-0 mx-2">
          <v-col class="pa-0">
            <v-text-field
              ref="partyName"
              :value="item.party.partyName"
              :rules="required"
              :hide-details="item.party.partyName != null"
              :class="getPartyNameClass(item.party.partyName)"
              validate-on-blur
              readonly
              flat
              solo
            />
            <span class="text-caption">{{ item.party.nmftaCode }}</span>
          </v-col>
        </v-row>
        <v-row class="my-0 mx-2">
          <v-col class="pa-0 text-caption">
            <span class="text-caption primary--text text-decoration-underline" @click="openPartyEditDialog(item)">
              {{ $t('label.change') }}
            </span>
            <v-dialog
              v-model="partyEditDialog"
              :retain-focus="false"
              width="30%"
            >
              <v-card>
                <v-card-title class="pa-2 px-3 text-overline primary--text">
                  {{ $t('label.selectParty') }}
                </v-card-title>
                <v-divider class="ma-0" />
                <v-card-text class="pa-0">
                  <v-container class="pb-2">
                    <v-form ref="partyEditForm" class="pa-0">
                      <v-row dense>
                        <v-col>
                          <v-autocomplete
                            :label="$t('label.party')"
                            v-model="customerSelection"
                            item-text="customerName"
                            :filter="filterCustomers"
                            :items="customers"
                            :menu-props="menuProps"
                            :rules="required"
                            @update:search-input="getCustomers"
                            @change="updatePartyCustomer"
                            @click.stop
                            class="xs-input"
                            return-object
                            clearable
                            outlined
                            dense
                          >
                            <template v-slot:item="data">
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ data.item.customerName }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  {{ getCarrierCode(data.item) }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col>
                          <v-text-field
                            :label="$t('label.customerCarrierCode')"
                            :value="getCarrierCode(customerSelection)"
                            @change="addNewCustomerCode"
                            :rules="required"
                            maxlength="20"
                            class="xs-input"
                            outlined
                            dense
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <v-divider class="my-2" />
        <v-row class="my-0 mx-2">
          <v-col class="pa-0 text-caption">
            <span class="text-caption primary--text">
              {{ $t('label.address') }}
            </span>
          </v-col>
          <v-col cols="auto" class="pa-0">
            <v-dialog width="30%">
              <template v-slot:activator="{ on }">
                <v-icon color="primary" v-on="on" x-small>
                  mdi-pencil
                </v-icon>
              </template>
              <v-card>
                <v-card-title class="pa-2 px-3 text-overline primary--text">
                  <span>{{ $t('label.address') }}</span>
                </v-card-title>
                <v-divider class="ma-0" />
                <v-card-text class="pa-0">
                  <v-container>
                    <v-row dense>
                      <v-col>
                        <v-text-field
                          :label="$t('label.street')"
                          v-model="item.party.street"
                          class="xs-input"
                          hide-details
                          clearable
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          :label="$t('label.number')"
                          v-model="item.party.streetNumber"
                          class="xs-input"
                          hide-details
                          clearable
                          outlined
                          dense
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col>
                        <v-text-field
                          :label="$t('label.city')"
                          v-model="item.party.city"
                          class="xs-input"
                          hide-details
                          clearable
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          :label="$t('label.country')"
                          v-model="item.party.country"
                          class="xs-input"
                          hide-details
                          clearable
                          outlined
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row class="my-0 mx-2">
          <v-col class="pa-0">
            <span class="text-caption">
              {{ partyAddressStreetLabel(item.party.street, item.party.streetNumber)
              }}
            </span>
          </v-col>
        </v-row>
        <v-row class="my-0 mx-2">
          <v-col class="pa-0">
            <span class="text-caption">
              {{ partyAddressCityLabel(item.party.city, item.party.country) }}
            </span>
          </v-col>
        </v-row>
        <v-divider class="my-2" />
        <v-row class="my-0 mx-2">
          <v-col class="pa-0 text-caption">
            <span class="text-caption primary--text">
              {{ $t('label.contact') }}
            </span>
          </v-col>
          <v-col cols="auto" class="pa-0">
            <v-dialog width="30%">
              <template v-slot:activator="{ on }">
                <v-icon color="primary" v-on="on" x-small>
                  mdi-pencil
                </v-icon>
              </template>
              <v-card>
                <v-card-title class="pa-2 px-3">
                  <span class="text-overline primary--text">
                    {{ $t('label.contact') }}
                  </span>
                </v-card-title>
                <v-divider class="ma-0" />
                <v-card-text class="pa-0">
                  <v-container>
                    <v-row dense>
                      <v-col>
                        <v-text-field
                          :label="$t('label.name')"
                          v-model="item.contactDetails.name"
                          class="xs-input"
                          hide-details
                          clearable
                          outlined
                          dense
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col>
                        <v-text-field
                          :label="$t('label.email')"
                          v-model="item.contactDetails.email"
                          class="xs-input"
                          hide-details
                          clearable
                          outlined
                          dense
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col>
                        <v-text-field
                          :label="$t('label.phone')"
                          v-model="item.contactDetails.phone"
                          class="xs-input"
                          hide-details
                          clearable
                          outlined
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row class="my-0 mx-2">
          <v-col class="pa-0">
            <span class="text-caption">
              {{ item.contactDetails.name }}
            </span>
          </v-col>
        </v-row>
        <v-row class="my-0 mx-2">
          <v-col class="pa-0">
            <span class="text-caption">
              {{ item.contactDetails.email }}
            </span>
          </v-col>
        </v-row>
        <v-row class="my-0 mx-2">
          <v-col class="pa-0">
            <span class="text-caption">
              {{ item.contactDetails.phone }}
            </span>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Parties from '@/mixins/Parties.js'
import { customersService } from '@/services/customers.service.js'

export default {
  name: 'ShippingInstructionParty',
  props: ['company', 'partyFunction', 'preselectedParty'],
  emits: ['select-party'],
  mixins: [Parties],
  data() {
    return {
      item: {
        partyFunction: this.partyFunction,
        party: {},
        contactDetails: {},
      },
      customers: [],
      customerSelection: null,
      partyEditDialog: false,
      menuProps: {
        offsetY: true,
        overflowY: 'auto',
        maxHeight: '10rem',
      },
      partyTemplate: {
        partyFunction: null,
        party: {
          partyName: null,
          taxReference1: null,
          publicKey: null,
          street: null,
          streetNumber: null,
          floor: null,
          postCode: null,
          city: null,
          stateRegion: null,
          country: null,
          taxReference2: null,
          nmftaCode: null,
        },
        contactDetails: {
          name: null,
          email: null,
          phone: null,
        },
        isToBeNotified: null,
      },
    }
  },
  computed: {
    required() {
      return [(v) => !!v || this.$t('message.required')]
    },
  },
  methods: {
    init() {
      if (!this.preselectedParty) return
      this.item = this._.cloneDeep(this.preselectedParty)
      this.$emit('select-party', this.item)
    },
    validateParty() {
      return this.$refs.form.validate()
    },
    async openPartyEditDialog(item) {
      if (item.party.partyName) {
        let customers = await this.browseCustomers(item.party.partySapCode, 'customerId')
        this.customers = customers ? customers.resultList : []
        this.customerSelection = this.customers.length ? this.customers[0] : null
      } else this.customerSelection = null
      this.partyEditDialog = true
    },
    // Customers handling
    getCustomers(input) {
      if (!input) return
      clearTimeout(this.searchTimeoutId)
      this.searchTimeoutId = setTimeout(async () => {
        let customers = await this.browseCustomers(input, 'customerName')
        this.customers = customers ? customers.resultList : []
      }, 500)
    },
    browseCustomers(input, field) {
      if (!input || !input.length) return
      let browseRequest = {
        offset: 0,
        limit: 1000,
        filter: {},
        sortingList: [{ column: 'customerName', direction: 'ASC' }],
      }
      browseRequest.filter[field] = input
      return customersService.browse(browseRequest)
    },
    addNewCustomerCode(code) {
      if (!code || !code.length) return
      let customer = this.customerSelection
      let carrierCodes = { sapCustomerId: this.customerSelection.id }
      switch (this.company) {
        case 'CMACGM':
          carrierCodes.cmacgmCode = code
          break
        case 'MAERSK':
          carrierCodes.maerskCode = code
          break
        case 'YANGMING':
          carrierCodes.yangmingCode = code
          break
      }
      customer.carrierCodes = carrierCodes
      this.updateCustomer(customer.id, customer).then((resp) => {
        this.customerSelection = resp
        this.updatePartyCustomer()
      })
    },
    updateCustomer(id, data) {
      return customersService.updateCustomer(id, data)
    },
    updatePartyCustomer() {
      this.item = this.setParty(this.customerSelection, this.partyFunction)
      this.$emit('select-party', this.item)
    },
    filterCustomers(item, queryText) {
      return item.customerName.toLowerCase().indexOf(queryText.toLowerCase()) > -1
    },
    // Visualization
    getPartyFunction(partyFunction) {
      switch (partyFunction) {
        case 'REQ':
          return 'TRANSPORT DOCUMENT RECEIVER'
        case 'OS':
          return 'SHIPPER'
        case 'CN':
          return 'CONSIGNEE'
        case 'N1':
          return 'NOTIFY'
        case 'PAY':
          return 'PAYER'
      }
    },
    showToOrder(partyFunction) {
      return partyFunction == 'CN'
    },
    showSameAsConsignee(partyFunction) {
      return partyFunction == 'N1'
    },
    partyAddressCityLabel(city, country) {
      let label = ''
      label += city ? `${city}` : ''
      label += country ? `, ${country}` : ''
      return label
    },
    partyAddressStreetLabel(street, streetNumber) {
      let label = ''
      label += street ? `${street}` : ''
      label += streetNumber ? ` ${streetNumber}` : ''
      return label
    },
    getPartyNameClass(partyName) {
      let baseClass = 'party-name text-caption xs-input'
      baseClass = partyName ? baseClass.concat(' visible-input') : baseClass.concat(' invisible-input')
      return baseClass
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>
.xs-input::v-deep input {
  padding: 0.5rem !important;
  padding-left: 0 !important;
}
.xs-input::v-deep .v-label {
  position: unset !important;
  min-width: max-content;
  margin-bottom: 0 !important;
}
.xs-input::v-deep .v-input__slot {
  min-height: 0 !important;
  padding-right: 4px !important;
}
.xs-input::v-deep .v-input__append-inner {
  margin: 0 !important;
  align-self: center !important;
}
.xs-input::v-deep .v-label--active {
  position: absolute !important;
  margin-bottom: 0 !important;
}
.xs-input::v-deep .v-select__selections {
  padding: 0 !important;
}
.xs-input::v-deep .v-text-field__details {
  margin: 0 !important;
}
.v-icon:focus::after {
  opacity: 0 !important;
}
.shipped-switch {
  max-width: max-content !important;
}
.shipped-switch::v-deep .v-input--switch__track {
  height: 20px !important;
  width: 40px !important;
}
.shipped-switch::v-deep .v-input--switch__thumb {
  height: 12px !important;
  width: 12px !important;
}
.shipped-switch::v-deep .v-input--selection-controls__input {
  margin: 0 !important;
  height: 20px !important;
  width: 40px !important;
}
.visible-input {
  --height: initial;
  --margin-bottom: initial;
}
.invisible-input {
  --height: 0;
  --margin-bottom: 0;
}
.party-name::v-deep .v-input__control {
  min-height: 0;
}
.party-name::v-deep .v-input__slot {
  padding: 0 !important;
  height: var(--height);
  margin-bottom: var(--margin-bottom);
}
.party-name::v-deep input {
  padding: 0 !important;
}
.party-name::v-deep .v-text-field__details {
  padding: 0 !important;
  margin: 0 !important;
}
/*.row {
  border: solid 1px red;
}
.col {
  border: solid 1px orange;
}*/
</style>
