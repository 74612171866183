const state = {
  isProduction: true,
  isDemo: false,
}

const actions = {
  async checkIsProd({commit}) {
    if (!window.location.href.match(/fedtracking.sacmigroup.com.*/))
      commit('updateIsProduction', false)
  },
  async checkIsDemo({commit}) {
    if (window.location.href.match(/gscvdemo.circletouch.eu.*/))
      commit('updateIsDemo', true)
  },
}
const mutations = {
  updateIsProduction(state, isProduction) {
    state.isProduction = isProduction
  },
  updateIsDemo(state, isDemo) {
    state.isDemo = isDemo
  },
}

export const application = {
  namespaced: true,
  state,
  actions,
  mutations,
}
