<template>
  <v-container class="d-block pa-0 pt-1" fill-height fluid>
    <v-form ref="form">
      <v-row class="ma-0">
        <v-col cols="auto" class="pt-1 pb-2">
          <v-subheader class="pa-0 primary--text text-overline">
            {{ $t('label.voyage') }}
          </v-subheader>
        </v-col>
        <v-col class="d-flex align-center pa-0 pr-3">
          <v-divider class="my-0" />
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col class="d-flex pa-0">
          <v-col cols="auto" class="d-flex align-center py-0">
            <v-icon class="mr-1">mdi-calendar</v-icon>
            <span>{{ formatDate(savedVoyage.departureDateTime) }}</span>
          </v-col>
          <v-col cols="auto" class="d-flex align-center py-0">
            <v-icon class="mr-1">mdi-map-marker</v-icon>
            <span>{{ savedPolLabel }}</span>
          </v-col>
          <v-col cols="auto" class="d-flex align-center py-0">
            <v-radio-group
              v-model="polHaulage"
              class="radio-group ma-0 pt-0"
              hide-details
              readonly
              dense
            >
              <v-radio value="CY" :ripple="false">
                <template slot="label">
                  <span class="text-caption black--text">
                    CY
                  </span>
                </template>
              </v-radio>
              <v-radio value="SD" :ripple="false">
                <template slot="label">
                  <span class="text-caption black--text">
                    SD
                  </span>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-col>
        <v-col class="d-flex pa-0">
          <v-col cols="auto" class="d-flex align-center py-0">
            <v-icon class="mr-1">mdi-calendar</v-icon>
            <span>{{ formatDate(savedVoyage.arrivalDateTime) }}</span>
          </v-col>
          <v-col cols="auto" class="d-flex align-center py-0">
            <v-icon class="mr-1">mdi-map-marker</v-icon>
            <span>{{ savedPodLabel }}</span>
          </v-col>
          <v-col cols="auto" class="d-flex align-center py-0">
            <v-radio-group
              v-model="podHaulage"
              class="radio-group ma-0 pt-0"
              hide-details
              readonly
              dense
            >
              <v-radio value="CY" :ripple="false">
                <template slot="label">
                  <span class="text-caption black--text">
                    CY
                  </span>
                </template>
              </v-radio>
              <v-radio value="SD" :ripple="false">
                <template slot="label">
                  <span class="text-caption black--text">
                    SD
                  </span>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="ma-0 py-2 pb-1" v-if="polHaulage == 'SD' || podHaulage == 'SD'">
        <v-col cols="auto" class="py-0">
          <v-subheader class="pa-0 primary--text text-overline">
            {{ $t('label.transportDetails') }}
          </v-subheader>
        </v-col>
        <v-col class="d-flex align-center py-0 pl-0">
          <v-divider class="my-0" />
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col class="pa-0">
          <div v-if="polHaulage == 'SD'">
            <v-row class="ma-0">
              <v-col class="pa-0 pb-2">
                <v-subheader class="primary--text text-caption font-weight-bold">
                  {{ $t('label.placeOfReceipt') }}
                </v-subheader>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col class="d-flex pa-0 pb-1">
                <v-col class="py-0">
                  <HaulageLocation
                    :title="$t('label.placeOfReceipt')"
                    :label="$t('label.address')"
                    :location="polHaulgeAddressDetails"
                    @set-address="(address) => (polHaulgeAddressDetails = address)"
                    :rules="validatePlaceOfReceipt"
                  />
                </v-col>
                <v-col class="py-0">
                  <ContactDialog
                    :title="$t('label.contact')"
                    :label="$t('label.contact')"
                    :contact="polHaulageContact"
                    :rules="validatePlaceOfReceiptCompany"
                    @set-contact="(contact) => (polHaulageContact = contact)"
                  />
                </v-col>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col class="pt-0 pb-1">
                <DateTimePickerDialog
                  @select-date="(date) => (polHaulageRemarks.pickupDateFrom = date)"
                  :label="$t('label.pickupDateFrom')"
                  :appearance="'outlined'"
                  :preselectedDate="polHaulageRemarks.pickupDateFrom"
                  :inputClass="'xs-input'"
                  :hideDetails="false"
                  :rules="required"
                />
              </v-col>
              <v-col class="pt-0 pb-1">
                <DateTimePickerDialog
                  @select-date="(date) => (polHaulageRemarks.pickupDateTo = date)"
                  :label="$t('label.pickupDateTo')"
                  :appearance="'outlined'"
                  :preselectedDate="polHaulageRemarks.pickupDateTo"
                  :inputClass="'xs-input'"
                  :hideDetails="false"
                  :rules="required"
                />
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col class="pt-0 pb-3">
                <v-textarea
                  :label="$t('label.placeOfReceiptRemarks')"
                  v-model="polHaulageRemarks.haulageInstructions"
                  :readonly="uneditableRequest"
                  class="text-caption"
                  hide-details
                  auto-grow
                  outlined
                  rows="1"
                />
              </v-col>
              <v-col class="pt-0 pb-3">
                <v-text-field
                  :label="$t('label.loadRef')"
                  v-model="polHaulageRemarks.haulageReference"
                  :rules="required"
                  class="xs-input"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-divider vertical v-if="polHaulage == 'SD' && podHaulage == 'SD'" />
        <v-col class="pa-0">
          <div v-if="podHaulage == 'SD'">
            <v-row class="ma-0">
              <v-col class="pa-0 pb-2">
                <v-subheader class="primary--text text-caption font-weight-bold">
                  {{ $t('label.placeOfDelivery') }}
                </v-subheader>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col class="d-flex pa-0 pb-1">
                <v-col class="py-0">
                  <HaulageLocation
                    :title="$t('label.placeOfDelivery')"
                    :label="$t('label.address')"
                    :location="podHaulgeAddressDetails"
                    @set-address="(address) => (podHaulgeAddressDetails = address)"
                    :rules="validatePlaceOfDelivery"
                  />
                </v-col>
                <v-col class="py-0">
                  <ContactDialog
                    :title="$t('label.contact')"
                    :label="$t('label.contact')"
                    :contact="podHaulageContact"
                    :rules="validatePlaceOfDeliveryCompany"
                    @set-contact="(contact) => (podHaulageContact = contact)"
                  />
                </v-col>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col class="pt-0 pb-1">
                <DateTimePickerDialog
                  @select-date="(date) => (podHaulageRemarks.deliveryDateFrom = date)"
                  :label="$t('label.deliveryDateFrom')"
                  :appearance="'outlined'"
                  :preselectedDate="podHaulageRemarks.deliveryDateFrom"
                  :inputClass="'xs-input'"
                  :hideDetails="false"
                  :rules="required"
                />
              </v-col>
              <v-col class="pt-0 pb-1">
                <DateTimePickerDialog
                  @select-date="(date) => (podHaulageRemarks.deliveryDateTo = date)"
                  :label="$t('label.deliveryDateTo')"
                  :appearance="'outlined'"
                  :preselectedDate="podHaulageRemarks.deliveryDateTo"
                  :inputClass="'xs-input'"
                  :hideDetails="false"
                  :rules="required"
                />
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col class="pt-0 pb-3">
                <v-textarea
                  :label="$t('label.placeOfDeliveryRemarks')"
                  v-model="podHaulageRemarks.haulageInstructions"
                  :readonly="uneditableRequest"
                  class="text-caption"
                  hide-details
                  auto-grow
                  outlined
                  rows="1"
                />
              </v-col>
              <v-col class="pt-0 pb-3">
                <v-text-field
                  :label="$t('label.loadRef')"
                  v-model="podHaulageRemarks.haulageReference"
                  :rules="required"
                  class="xs-input"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-row class="ma-0 py-2">
        <v-col cols="auto" class="py-0">
          <v-subheader class="pa-0 primary--text text-overline">
            {{ $t('label.commodityDetails') }}
          </v-subheader>
        </v-col>
        <v-col class="d-flex align-center py-0 pl-0">
          <v-divider class="my-0" />
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col cols="6" class="d-flex py-0" v-if="company == 'YANGMING'">
          <v-col class="py-0 pl-0">
            <v-text-field
              :label="$t('label.commodity')"
              v-model="commoditySelected.commodityName"
              :rules="required"
              @change="formatCommodity(commoditySelected)"
              class="xs-input"
              clearable
              outlined
              dense
            />
          </v-col>
          <v-col class="py-0">
            <v-text-field
              :label="$t('label.commodityCode')"
              v-model="commoditySelected.commodityCode"
              :rules="required"
              @change="formatCommodity(commoditySelected)"
              class="xs-input"
              clearable
              outlined
              dense
            />
          </v-col>
        </v-col>
        <v-col cols="6" class="py-0" v-else>
          <v-autocomplete
            v-model="commoditySelected"
            :label="$t('label.commodity')"
            item-text="commodityName"
            :filter="filterCommodities"
            :items="commodities"
            :menu-props="menuProps"
            :rules="required"
            validate-on-blur
            @update:search-input="getBookingRequestCommodities"
            @click.stop
            :readonly="uneditableRequest"
            class="xs-input"
            return-object
            outlined
            attach
            dense
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.item.commodityName }} -
                  {{ data.item.commodityCode }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.item.commodityCode }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <!-- <v-col cols="auto" class="d-flex align-center pt-0 pb-4">
          <v-checkbox
            v-model="dangerous"
            :label="$t('label.dangerousCargo')"
            class="dangerous-check ma-0 pa-0"
            :readonly="uneditableRequest"
            hide-details
          />
        </v-col> -->
      </v-row>
      <!-- <v-row class="ma-0" v-if="dangerous">
        <v-col class="pt-1 pb-2">
          <v-autocomplete
            :label="$t('label.imoClassification')"
            dense
            attach
            :items="[]"
            :menu-props="menuProps"
            @click.stop
            class="xs-input"
            hide-details
            outlined
          />
        </v-col>
        <v-col class="pt-1 pb-2">
          <v-text-field
            label="UN number"
            class="xs-input"
            outlined
            hide-details
            dense
          />
        </v-col>
      </v-row> -->
      <v-row class="ma-0">
        <v-col class="pa-0">
          <BookingContainers
            :preselectedContainers="savedEquipments"
            :company="company"
            :disabled="uneditableRequest"
            @update-equipments="updateEquipments"
            @update-unit-type="updateWeightUnitType"
            @update-volume-unit-type="updateVolumeUnitType"
          />
        </v-col>
      </v-row>
      <v-row class="ma-0 py-1">
        <v-col cols="auto" class="py-0">
          <v-subheader class="pa-0 primary--text text-overline">
            {{ $t('label.serviceContract') }}
          </v-subheader>
        </v-col>
        <v-col class="d-flex align-center py-0 pl-0">
          <v-divider class="my-0" />
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col cols="auto" class="d-flex align-center pt-0 pb-4 pl-4">
          <v-switch
            v-model="contractType"
            :label="contractType == contractValue ? 'Contract' : 'Spot'"
            :true-value="contractValue"
            :false-value="spotValue"
            class="contract-switch ma-0 pa-0"
            :readonly="uneditableRequest"
            hide-details
            inset
          />
        </v-col>
        <v-col class="py-0">
          <v-combobox
            v-if="contractType == contractValue"
            :label="$t('label.contract')"
            v-model="contract"
            :items="serviceContracts"
            item-value="code"
            item-text="code"
            @click.stop
            :menu-props="menuProps"
            :readonly="uneditableRequest"
            :rules="required"
            class="xs-input"
            validate-on-blur
            outlined
            attach
            dense
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.item.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.item.code }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-combobox>
          <v-text-field
            v-if="contractType == spotValue"
            v-model="quotationNbr"
            :label="$t('label.quotation')"
            :rules="required"
            class="xs-input"
            validate-on-blur
            outlined
            dense
          />
        </v-col>
        <v-col class="py-0" v-if="company == 'MAERSK'">
          <v-autocomplete
            v-model="bookingOffice"
            :label="$t('label.bookingOffice')"
            item-value="officeName"
            item-text="officeName"
            :filter="filterBookingOffices"
            :items="bookingOffices"
            :menu-props="menuProps"
            :rules="required"
            validate-on-blur
            @update:search-input="getBookingOffices"
            @click.stop
            :readonly="uneditableRequest"
            class="xs-input"
            outlined
            attach
            dense
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.item.officeName }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col class="py-0">
          <ContactDialog
            :title="$t('label.contact')"
            :label="$t('label.contact')"
            :contact="savedForwarderParty?.partyContactDetails"
            :rules="validateForwarderPartyContact"
            :showField="'name'"
            @set-contact="(contact) => (forwarderPartyContact = contact)"
          />
        </v-col>
        <v-col class="py-0">
          <v-text-field
            :label="$t('label.vgmFilingBy')"
            v-model="vgmFilingBy"
            @change="addVgmFilingByRemark"
            class="xs-input"
            outlined
            dense
          />
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col class="py-1">
          <v-textarea
            :label="$t('label.bookingRemarks')"
            v-model="bookingRemarks"
            :readonly="uneditableRequest"
            hide-details
            auto-grow
            outlined
            rows="1"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import Booking from '@/mixins/Booking.js'
import { electronicFlowService } from '@/services/electronicFlow.service.js'
import BookingContainers from '@/components/elements/booking/BookingContainers.vue'
import DateTimePickerDialog from '@/components/elements/DateTimePickerDialog.vue'
import HaulageLocation from '@/components/elements/booking/HaulageLocation.vue'
import ContactDialog from '@/components/elements/booking/ContactDialog.vue'

export default {
  name: 'BookingCommoditiesStandard',
  emits: ['loaded'],
  mixins: [Booking],
  components: {
    BookingContainers,
    HaulageLocation,
    ContactDialog,
    DateTimePickerDialog,
  },
  data() {
    return {
      dangerous: false,
      commodity: {},
      commodities: [],
      commoditySelected: {},
      bookingOffice: null,
      bookingOffices: [],
      bookingRequestEquipments: [],
      selectedEquipments: [],
      containersTotWeight: null,
      containersWeightUnitType: 'KGS',
      containersTotVolume: null,
      containersVolumeUnitType: 'MTQ',
      contract: null,
      quotationNbr: null,
      contractType: null,
      serviceContracts: [],
      bookingRemarks: null,
      polHaulgeAddressDetails: {},
      polHaulageContact: {},
      polHaulageRemarks: {},
      podHaulgeAddressDetails: {},
      podHaulageContact: {},
      podHaulageRemarks: {},
      forwarderPartyContact: {},
      consumerRemarks: [],
      vgmFilingBy: null,
      vgmFilingByLabel: 'VGM filing by: '
    }
  },
  computed: {
    contractValue() {
      switch (this.company) {
        case 'MAERSK':
          return 'MaerskContract'
        case 'CMACGM':
          return 'CmaCgmContract'
        case 'YANGMING':
          return 'YangMingContract'
        default:
          return ''
      }
    },
    spotValue() {
      switch (this.company) {
        case 'MAERSK':
          return 'MaerskSpot'
        case 'CMACGM':
          return 'CmaCgmSpot'
        case 'YANGMING':
          return 'YangMingSpot'
        default:
          return ''
      }
    },
    required() {
      return [(v) => !!v || this.$t('message.required')]
    },
    validateForwarderPartyContact() {
      return [() => this.forwarderPartyContactFilled || this.$t('message.required')]
    },
    validatePlaceOfReceipt() {
      return [() => this.placeOfReceiptFilled || this.$t('message.required')]
    },
    validatePlaceOfReceiptCompany() {
      return [() => this.polHaulageContactFilled || this.$t('message.required')]
    },
    validatePlaceOfDelivery() {
      return [() => this.placeOfDeliveryFilled || this.$t('message.required')]
    },
    validatePlaceOfDeliveryCompany() {
      return [() => this.podHaulageContactFilled || this.$t('message.required')]
    },
    forwarderPartyContactFilled() {
      return this.savedForwarderParty &&
        this.savedForwarderParty.partyContactDetails.name &&
        this.savedForwarderParty.partyContactDetails.email &&
        this.savedForwarderParty.partyContactDetails.phone
        ? true
        : false
    },
    placeOfReceiptFilled() {
      return this.polHaulgeAddressDetails &&
        this.polHaulgeAddressDetails.companyName &&
        this.polHaulgeAddressDetails.street &&
        this.polHaulgeAddressDetails.streetNumber &&
        this.polHaulgeAddressDetails.postCode
        ? true
        : false
    },
    polHaulageContactFilled() {
      return this.polHaulageContact &&
        this.polHaulageContact.name &&
        this.polHaulageContact.email &&
        this.polHaulageContact.phone
        ? true
        : false
    },
    placeOfDeliveryFilled() {
      return this.podHaulgeAddressDetails &&
        this.podHaulgeAddressDetails.companyName &&
        this.podHaulgeAddressDetails.street &&
        this.podHaulgeAddressDetails.streetNumber &&
        this.podHaulgeAddressDetails.postCode
        ? true
        : false
    },
    podHaulageContactFilled() {
      return this.podHaulageContact &&
        this.podHaulageContact.name &&
        this.podHaulageContact.email &&
        this.podHaulageContact.phone
        ? true
        : false
    },
  },
  watch: {
    serviceContracts: {
      handler(contracts) {
        if (!this.savedContractQuotationReference && contracts.length)
          this.contract = contracts[0].code
      },
      deep: true,
    },
    commoditySelected: {
      handler(commodity) {
        this.formatCommodity(commodity)
      },
      deep: true,
    },
  },
  methods: {
    async init() {
      this.contractType = this.savedContractType ? this.savedContractType : this.contractValue
      this.selectedEquipments = this.savedEquipments
      this.bookingRemarks = this.savedBookingRemarks
      this.vgmFilingBy = this.savedVgmFilingBy
      this.forwarderPartyContact = this._.cloneDeep(this.savedForwarderParty.partyContactDetails)

      if (this.savedPolHaulageLocation)
        this.polHaulgeAddressDetails = this._.cloneDeep(this.savedPolHaulageLocation)
      if (this.savedPolHaulageCompany)
        this.polHaulgeAddressDetails.companyName = this.savedPolHaulageCompany
      
      this.polHaulageContact = this._.cloneDeep(this.savedPolHaulageContact)
      this.polHaulageRemarks.haulageReference = this.savedPolHaulageLoadRef
      this.polHaulageRemarks.haulageInstructions = this.savedPolHaulageRemarks
      this.polHaulageRemarks.pickupDateFrom = this.savedExportRemarksPickupDateFrom
      this.polHaulageRemarks.pickupDateTo = this.savedExportRemarksPickupDateTo

      if (this.savedPodHaulageLocation)
        this.podHaulgeAddressDetails = this._.cloneDeep(this.savedPodHaulageLocation)
      if (this.savedPodHaulageCompany)
        this.podHaulgeAddressDetails.companyName = this.savedPodHaulageCompany
      
      this.podHaulageContact = this._.cloneDeep(this.savedPodHaulageContact)
      this.podHaulageRemarks.haulageReference = this.savedPodHaulageLoadRef
      this.podHaulageRemarks.haulageInstructions = this.savedPodHaulageRemarks
      this.podHaulageRemarks.deliveryDateFrom = this.savedImportRemarksDeliveryDateFrom
      this.podHaulageRemarks.deliveryDateTo = this.savedImportRemarksDeliveryDateTo

      let methods = []
      if (this.company != 'YANGMING') methods.push(this.setSavedBookingOffice())
      methods.push(this.setSavedCommodityOrDefault())
      methods.push(this.getServiceContracts())
      for (let method of methods) {
        await method
      }
      this.initContract()
    },
    async initContract() {
      let contracts
      if (!this.savedContractQuotationReference) return
      switch (this.contractType) {
        case this.contractValue:
          await this.getServiceContracts()
          if (!this.serviceContracts.length) return
          contracts = this.serviceContracts.filter((contract) => contract.code == this.savedContractQuotationReference)
          this.contract = contracts.length ? contracts[0].code : this.savedContractQuotationReference
          break
        case this.spotValue:
          this.quotationNbr = this.savedContractQuotationReference
          break
      }
    },
    async setSavedCommodityOrDefault() {
      let commodityCode
      let commoditiesByCode
      switch (this.company) {
        case 'YANGMING':
          this.commodity = this.savedCommodity
          this.commoditySelected.commodityCode = this.savedCommodity.HSCode 
          this.commoditySelected.commodityName = this.savedCommodity.commodityType
          break;
        default:
          if (!this.savedCommodity.HSCode) commodityCode = this.getDefaultCommodityCode()
          else commodityCode = this.savedCommodity.HSCode
          this.commodities = await this.browseCommodities(commodityCode)
          if (!this.commodities) return
          commoditiesByCode = this.commodities.filter((commodity) => commodity.commodityCode == commodityCode)
          if (commoditiesByCode.length) this.commoditySelected = commoditiesByCode[0]
          break;
      }
    },
    getDefaultCommodityCode() {
      switch (this.company) {
        case 'MAERSK':
          return '001408'
        case 'CMACGM':
          return '847490'
      }
    },
    getBookingRequestCommodities(input) {
      if (!input || (this.commoditySelected && input == this.commoditySelected.commodityName)) return
      clearTimeout(this.searchTimeoutId)
      this.searchTimeoutId = setTimeout(async () => {
        this.commodities = await this.browseCommodities(input)
      }, 500)
    },
    async setSavedBookingOffice() {
      if (this.company != 'MAERSK') return
      let officeName
      if (!this.savedRequest.bookingOfficeName) officeName = 'Genova - it'
      else officeName = this.savedRequest.bookingOfficeName
      await this.browseBookingOffices(officeName)
      let officesByName = this.bookingOffices.filter((office) => office.officeName == officeName)
      if (officesByName.length) this.bookingOffice = officesByName[0].officeName
    },
    getBookingOffices(input) {
      if (!input || (this.bookingOffice && input == this.bookingOffice)) return
      clearTimeout(this.searchTimeoutId)
      this.searchTimeoutId = setTimeout(() => {
        this.browseBookingOffices(input)
      }, 1000)
    },
    browseBookingOffices(officeName) {
      return electronicFlowService
        .getBookingOffices(this.company, 'MAEU', officeName, '')
        .then((resp) => {
          this.bookingOffices = resp
        })
    },
    getServiceContracts() {
      return electronicFlowService
        .getServiceContracts(this.company)
        .then((resp) => {
          this.serviceContracts = resp
        })
    },
    filterCommodities(item, queryText) {
      return item.commodityName.toLowerCase().indexOf(queryText.toLowerCase()) > -1 || item.commodityCode.toLowerCase().indexOf(queryText.toLowerCase()) > -1
    },
    filterBookingOffices(item, queryText) {
      return item.officeName.toLowerCase().indexOf(queryText.toLowerCase()) > -1
    },
    updateEquipments(equipments, containers, totWeight, totVolume) {
      this.bookingRequestEquipments = equipments
      this.selectedEquipments = containers
      this.containersTotWeight = totWeight
      this.containersTotVolume = totVolume
      this.formatCommodity(this.commoditySelected)
    },
    updateWeightUnitType(unitType) {
      this.containersWeightUnitType = unitType
      this.formatCommodity(this.commoditySelected)
    },
    updateVolumeUnitType(unitType) {
      this.containersVolumeUnitType = unitType
      this.formatCommodity(this.commoditySelected)
    },
    formatCommodity() {
      if (!this.commoditySelected) return
      this.commodity = {
        HSCode: this.commoditySelected.commodityCode,
        commodityType: this.commoditySelected.commodityName,
        cargoGrossWeight: this.containersTotWeight,
        cargoGrossWeightUnit: this.containersWeightUnitType,
        cargoGrossVolume: this.containersTotVolume,
        cargoGrossVolumeUnit: this.containersVolumeUnitType,
      }
    },
    addVgmFilingByRemark(value){
      if (!value || !value.length) {
        this.consumerRemarks = this.consumerRemarks.filter((rmk) => rmk.label != this.vgmFilingByLabel)
      } else {
        let index = this.consumerRemarks.findIndex((rmk) => rmk.label == this.vgmFilingByLabel)
        index >= 0 ? this.consumerRemarks[index].value = value : this.consumerRemarks.push({label: this.vgmFilingByLabel, value: value})
      }
    },
    addHaulageContactToRemarksForMaersk(contact, isExport){
      if (!contact || this.objIsEmpty(contact)) return
      let contactRemark = {}
      let importExport = isExport ? 'Export' : 'Import'
      contactRemark.label = `${importExport} haulage contact: `
      contactRemark.value = `${contact.name}, ${contact.email}, ${contact.phone}\n`
      return contactRemark
    },
    // Submit
    setCommodityFields() {
      if (this.electronicFlowStatus == this.voyageSelectedStatus)
        this.setStatus(this.commoditySelectedStatus)
      let contractQuotationRef = this.contractType == this.contractValue ? this.contract : this.quotationNbr
      this.setContractQuotationReference(contractQuotationRef)
      this.setCarrierServiceName(this.contractType)
      this.setCommodity(this.commodity)
      this.setBookingRemarks(this.bookingRemarks)
      this.setExportRemarks(this.polHaulageRemarks)
      this.setImportRemarks(this.podHaulageRemarks)
      this.setBookingOfficeName(this.bookingOffice)
      this.updateHaulageDetails(this.bookingRequestEquipments, false)
      this.setRequestedEquipments(this.bookingRequestEquipments)
      this.updateApplicantPartyContact()
      
      // if (this.company == 'MAERSK') {
      //   this.addHaulageContactToRemarksForMaersk(this.polHaulageContact, true)
      //   this.addHaulageContactToRemarksForMaersk(this.podHaulageContact, false)
      // }

      this.setConsumerRemarks(this.consumerRemarks)
      // Set equipments on Request in ElectronicFlow
      this.updateHaulageDetails(this.selectedEquipments, true)
      this.setRequestEquipments(JSON.stringify(this.selectedEquipments))
      this.updateRequestAndSaveElectronicFlow()
    },
    updateHaulageDetails(equipments, hasCode) {
      equipments.forEach((equipment) => {
        let list = hasCode ? equipment.requestedEquipments.haulageDetails : equipment.haulageDetails
        if (!list) return
        list.forEach((detail) => {
          let address = detail.haulageSites[0].address
          let contact = detail.haulageSites[0].contactDetails
          let haulageSiteDetail = detail.haulageSites[0]
          switch (detail.operationalStage) {
            case 'EXPORT':
              // detail.loadReference = this.polHaulageRemarks ? this.polHaulageRemarks.loadReference : null
              detail.haulageInstructions = this.polHaulageRemarks.haulageInstructions
              address.street = this.polHaulgeAddressDetails.street
              address.streetNumber = this.polHaulgeAddressDetails.streetNumber
              address.postCode = this.polHaulgeAddressDetails.postCode
              haulageSiteDetail.companyName = this.polHaulgeAddressDetails.companyName
              haulageSiteDetail.haulageReference = this.polHaulageRemarks.haulageReference
              haulageSiteDetail.haulageStartDateTime = this.polHaulageRemarks.pickupDateFrom
              haulageSiteDetail.haulageEndDateTime = this.polHaulageRemarks.pickupDateTo
              
              contact.name = this.polHaulageContact.name
              contact.email = this.polHaulageContact.email
              contact.phone = this.polHaulageContact.phone
              // if (this.company != 'MAERSK') contact.phone = this.polHaulageContact.phone
              // else this.polHaulageRemarks.haulageContact = this.addHaulageContactToRemarksForMaersk(contact, true)
              break
            case 'IMPORT':
              // detail.loadReference = this.podHaulageRemarks ? this.podHaulageRemarks.loadReference : null
              detail.haulageInstructions = this.podHaulageRemarks.haulageInstructions
              address.street = this.podHaulgeAddressDetails.street
              address.streetNumber = this.podHaulgeAddressDetails.streetNumber
              address.postCode = this.podHaulgeAddressDetails.postCode
              haulageSiteDetail.companyName = this.podHaulgeAddressDetails.companyName
              haulageSiteDetail.haulageReference = this.podHaulageRemarks.haulageReference
              haulageSiteDetail.haulageStartDateTime = this.podHaulageRemarks.deliveryDateFrom
              haulageSiteDetail.haulageEndDateTime = this.podHaulageRemarks.deliveryDateTo

              contact.name = this.podHaulageContact.name
              contact.email = this.podHaulageContact.email
              contact.phone = this.podHaulageContact.phone
              // if (this.company != 'MAERSK') contact.phone = this.podHaulageContact.phone
              // else this.podHaulageRemarks.haulageContact = this.addHaulageContactToRemarksForMaersk(contact, false)
              break
          }
        })
      })
    },
    updateApplicantPartyContact() {
      let userParties = this._.cloneDeep(this.bookingRequest.documentParties)
      userParties.forEach((docParty) => {
        if (docParty.party.partyName == this.applicantCompanyName)
          docParty.party.partyContactDetails = this.forwarderPartyContact
      })
      this.setDocumentParties(userParties)
    },
  },
  mounted() {
    this.init()
      .then(() => this.$emit('loaded', true))
      .catch((error) =>
        this.setSnackbarMessage({ text: error, color: 'error' }),
      )
  },
}
</script>

<style scoped>
.radio-group::v-deep .v-input--radio-group__input {
  flex-direction: row;
}
.v-radio {
  max-width: max-content;
  margin: 0 1rem 0 0 !important;
}
.v-radio::v-deep .v-label {
  margin: 0 !important;
}
.add-btn {
  text-transform: unset !important;
}
.dangerous-check::v-deep label {
  margin-bottom: 0 !important;
}
.contract-switch::v-deep label {
  margin: 0 !important;
  width: 4rem;
}
.v-input::v-deep .v-text-field__details {
  margin: 0 !important;
}
.xs-input::v-deep input {
  padding: 0.5rem !important;
  padding-left: 0 !important;
}
.xs-input::v-deep .v-label {
  position: unset !important;
  min-width: max-content;
  margin-bottom: 0 !important;
}
.xs-input::v-deep .v-input__slot {
  min-height: 0 !important;
  padding-right: 4px !important;
}
.xs-input::v-deep .v-input__append-inner {
  margin: 0 !important;
  align-self: center !important;
}
.xs-input::v-deep .v-label--active {
  position: absolute !important;
  margin-bottom: 0 !important;
}
.v-subheader {
  height: 1rem;
}
.v-textarea::v-deep textarea {
  line-height: 1rem !important;
}
/*.v-textarea::v-deep .v-input__slot {
  min-height: 0 !important;
}
.contract-switch::v-deep .v-input__slot {
  flex-direction: row-reverse;
}
.row {
  border: solid 1px green;
}
.col {
  border: solid 1px orange;
}*/
</style>
