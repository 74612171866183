var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "my-1", attrs: { outlined: "" } },
    [
      _c(
        "v-row",
        { staticClass: "ma-0", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            {
              staticClass:
                "py-0 text-caption font-weight-bold d-flex align-center",
              attrs: { cols: "auto" },
            },
            [
              _c(
                "v-row",
                {
                  staticClass:
                    "ma-0 d-flex flex-column justify-center text-left",
                  attrs: { dense: "" },
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "d-flex flex-column py-0" },
                    [
                      _c("span", {
                        staticClass: "text-subtitle-1 font-weight-black",
                        domProps: {
                          textContent: _vm._s(_vm.invoice.invoiceNbr),
                        },
                      }),
                      _c(
                        "v-chip",
                        {
                          staticClass: "py-0",
                          attrs: { color: "#e0e0e0", label: "", small: "" },
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "#757575", small: "" } },
                            [_vm._v("mdi-file-document-outline")]
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-medium ml-1 text-caption text-center",
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("label.invoiceNbr")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0 d-flex justify-start align-center",
                      staticStyle: { "min-height": "40px" },
                    },
                    [
                      _c("ReadonlyField", {
                        attrs: {
                          label: _vm.$t("label.documentDate"),
                          value: _vm.invoice.documentDate,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-col",
            {
              staticClass:
                "py-0 text-caption font-weight-bold d-flex align-center",
            },
            [
              _c(
                "v-row",
                {
                  staticClass:
                    "ma-0 d-flex flex-column justify-center text-left",
                  attrs: { dense: "" },
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0" },
                    [
                      _c("ReadonlyField", {
                        attrs: {
                          label: _vm.$t("label.invoiceHolderName"),
                          value: _vm.invoice.invoiceHolderName,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "ma-1" }),
                  _c(
                    "v-col",
                    { staticClass: "py-0" },
                    [
                      _c("ReadonlyField", {
                        attrs: {
                          label: _vm.$t("label.sellerName"),
                          value: _vm.invoice.sellerName,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-col",
            {
              staticClass:
                "py-0 text-caption font-weight-bold d-flex align-center",
            },
            [
              _c(
                "v-row",
                {
                  staticClass:
                    "ma-0 d-flex flex-column justify-center text-left",
                  attrs: { dense: "" },
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0" },
                    [
                      _c("ReadonlyField", {
                        attrs: {
                          label: _vm.$t("label.consigneeName"),
                          value: _vm.invoice.consigneeName,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "ma-1" }),
                  _c(
                    "v-col",
                    { staticClass: "py-0 d-flex justify-space-between" },
                    [
                      _c("ReadonlyField", {
                        attrs: {
                          label: _vm.$t("label.incoterms"),
                          value: _vm.invoice.incoterms,
                        },
                      }),
                      _c("ReadonlyField", {
                        attrs: {
                          label: _vm.$t("label.taxCode"),
                          value: _vm.invoice.taxCode,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-col",
            {
              staticClass:
                "py-0 text-caption font-weight-bold d-flex align-center",
            },
            [
              _c(
                "v-row",
                {
                  staticClass:
                    "ma-0 d-flex flex-column justify-center text-left",
                  attrs: { dense: "" },
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0" },
                    [
                      _c("ReadonlyField", {
                        attrs: {
                          label: _vm.$t("label.totalAmount"),
                          value: _vm.invoice.totalAmount.toString(),
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "ma-1" }),
                  _c(
                    "v-col",
                    { staticClass: "py-0" },
                    [
                      _c("ReadonlyField", {
                        attrs: {
                          label: _vm.$t("label.totalGrossWeight"),
                          value: _vm.invoice.totalGrossWeight.toString(),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-col",
            {
              staticClass:
                "py-0 text-caption font-weight-bold d-flex align-center",
            },
            [
              _c(
                "v-row",
                {
                  staticClass:
                    "ma-0 d-flex flex-column justify-center text-left",
                  attrs: { dense: "" },
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0" },
                    [
                      _c("ReadonlyField", {
                        attrs: {
                          label: _vm.$t("label.currency"),
                          value: _vm.invoice.currency,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "ma-1" }),
                  _c(
                    "v-col",
                    { staticClass: "py-0" },
                    [
                      _c("ReadonlyField", {
                        attrs: {
                          label: _vm.$t("label.totalNetWeight"),
                          value: _vm.invoice.totalNetWeight.toString(),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-col",
            {
              staticClass:
                "py-0 text-caption font-weight-bold d-flex align-center",
            },
            [
              _c(
                "v-row",
                {
                  staticClass:
                    "ma-0 d-flex flex-column justify-center text-left",
                  attrs: { dense: "" },
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0" },
                    [
                      _c("ReadonlyField", {
                        attrs: {
                          label: _vm.$t("label.saleOrderReference"),
                          value: _vm.invoice.saleOrderReference,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "ma-1" }),
                  _c(
                    "v-col",
                    { staticClass: "py-0" },
                    [
                      _c("ReadonlyField", {
                        attrs: {
                          label: _vm.$t("label.shipmentNbr"),
                          value: _vm.shipmentDocumentNbr,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-col",
            {
              staticClass: "pa-1 d-flex align-center",
              staticStyle: { "max-width": "60px" },
              attrs: { cols: "1" },
            },
            [
              _c(
                "v-row",
                { staticClass: "ma-0", attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { fab: "", depressed: "", "x-small": "" },
                          on: {
                            click: function ($event) {
                              _vm.expandSapInvoiceDetail =
                                !_vm.expandSapInvoiceDetail
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-arrow-expand-vertical")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showSelection
            ? _c("v-divider", { attrs: { vertical: "" } })
            : _vm._e(),
          _vm.showSelection
            ? _c(
                "v-col",
                {
                  staticClass: "d-flex justify-center align-center pa-1",
                  staticStyle: { "max-width": "60px" },
                  attrs: { cols: "1" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex justify-center align-center pa-1",
                      staticStyle: { "max-height": "fit-content" },
                      attrs: { justify: "space-around" },
                    },
                    [
                      _c("v-checkbox", {
                        staticClass: "checkbox ma-0 pa-0",
                        attrs: {
                          color: "primary",
                          "hide-details": "",
                          disabled:
                            _vm.invoice.status === "SENT" ||
                            _vm.shipmentDocumentNbr === "",
                        },
                        on: { change: _vm.addRowToSelection },
                        model: {
                          value: _vm.selected,
                          callback: function ($$v) {
                            _vm.selected = $$v
                          },
                          expression: "selected",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "space-around", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "pa-0", attrs: { cols: "12" } },
            [
              _c("InvoiceDetail", {
                attrs: {
                  open: _vm.expandSapInvoiceDetail,
                  documentNbr: _vm.invoice.documentNbr,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }