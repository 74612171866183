import { authHeader } from '@/helpers/auth-header'
import { commons } from './commons'

export const electronicFlowService = {
  findById,
  findByDocumentNbr,
  findByCarrierReference,
  createElectronicFlow,
  updateElectronicFlow,
  deleteElectronicFlow,
  getBookingRouteSchedules,
  getBookingRequestCommodities,
  getBookingRequestLocations,
  getBookingOffices,
  getServiceContracts,
  submitBookingRequest,
  updateBookingRequest,
  deleteBookingRequest,
  findAllBookingsByDocumentNbr,
  submitShippingInstruction
}

const resourceUrl = process.env.VUE_APP_GSCV_ENGINE_URL + `/electronic-flow`

function findById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/${id}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function findByDocumentNbr(documentNbr) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/pratica/${documentNbr}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function findByCarrierReference(bookingNbr) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/carrier-reference/${bookingNbr}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function createElectronicFlow(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }
  return fetch(resourceUrl, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function updateElectronicFlow(id, data) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }
  return fetch(resourceUrl + `/${id}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function deleteElectronicFlow(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/${id}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function getBookingRouteSchedules(request) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(request),
  }
  return fetch(resourceUrl + `/routes`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function getBookingRequestCommodities(carrier, code, description) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/commodities?carrier=${carrier}&code=${code}&description=${description}`, requestOptions)
  .then(commons.handleJsonResponse)
}

function getBookingRequestLocations(carrier, country, city, locode, locationType) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/locations?carrier=${carrier}&country=${country}&city=${city}&locode=${locode}&locationType=${locationType}`, requestOptions)
  .then(commons.handleJsonResponse)
}

function getBookingOffices(carrier, carrierCode, officeName, page) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/booking-office?carrier=${carrier}&carrierCode=${carrierCode}&officeName=${officeName}&page=${page}`, requestOptions)
  .then(commons.handleJsonResponse)
}

function getServiceContracts(companyId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/service-contracts/${companyId}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function submitBookingRequest(electronicFlowId, request) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(request),
  }
  return fetch(resourceUrl + `/booking/${electronicFlowId}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function updateBookingRequest(electronicFlowId, request) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(request),
  }
  return fetch(resourceUrl + `/booking/${electronicFlowId}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function deleteBookingRequest(electronicFlowId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/booking/${electronicFlowId}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function findAllBookingsByDocumentNbr(documentNbr) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/bookings/${documentNbr}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function submitShippingInstruction(electronicFlowId, request) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(request),
  }
  return fetch(resourceUrl + `/shipping-instruction/${electronicFlowId}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}