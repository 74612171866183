var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "d-block pa-0", attrs: { "fill-height": "", fluid: "" } },
    [
      _vm.internalVisibilityAuth ||
      (!_vm.internalVisibilityAuth &&
        (!_vm.isEmpty(_vm.browseRequest.filter) ||
          !_vm.isEmpty(_vm.browseRequest.sortingList)))
        ? _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              !_vm.isEmpty(_vm.browseRequest.filter)
                ? _c("v-col", { staticClass: "py-0", attrs: { cols: "9" } }, [
                    _c(
                      "div",
                      [
                        _c(
                          "v-chip",
                          {
                            staticClass: "mt-1 mr-1",
                            attrs: { color: "secondary", small: "", dark: "" },
                          },
                          [
                            _c(
                              "v-avatar",
                              { attrs: { left: "" } },
                              [
                                _c("v-icon", { attrs: { size: "20" } }, [
                                  _vm._v("mdi-filter-check"),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(
                              " " + _vm._s(_vm.$t("label.activeFilters")) + " "
                            ),
                          ],
                          1
                        ),
                        _vm._l(
                          Object.keys(_vm.visualFilter),
                          function (key, i) {
                            return _c(
                              "v-chip",
                              {
                                key: i,
                                staticClass: "mr-1 mt-1 px-2",
                                attrs: {
                                  color: "secondary",
                                  outlined: "",
                                  small: "",
                                  dark: "",
                                },
                              },
                              [
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.formatFilterChip(
                                        key,
                                        _vm.visualFilter[key]
                                      )
                                    ),
                                  },
                                }),
                              ]
                            )
                          }
                        ),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
              _c("v-spacer"),
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-end align-start px-0",
                  attrs: { cols: "3" },
                },
                [
                  _vm._l(
                    Object.keys(_vm.browseRequest.sortingList),
                    function (key, i) {
                      return _c(
                        "v-chip",
                        {
                          key: i,
                          staticClass: "mr-1",
                          attrs: {
                            color: "secondary",
                            outlined: "",
                            small: "",
                            dark: "",
                          },
                          on: { click: _vm.resetBrowseRequestSortingList },
                        },
                        [
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.formatSortByChip(
                                  _vm.browseRequest.sortingList[key]
                                )
                              ),
                            },
                          }),
                        ]
                      )
                    }
                  ),
                  _vm.internalVisibilityAuth
                    ? _c("FiltersSectionCustomsDashboard", {
                        ref: "filterSection",
                        on: {
                          sort: _vm.sort,
                          filter: _vm.filter,
                          "reset-filter":
                            _vm.resetAllBrowseRequestFiltersAndBrowse,
                        },
                      })
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-data-table", {
                staticClass: "cards-table",
                attrs: {
                  headers: [],
                  items: _vm.browseResponse.resultList,
                  "items-per-page": _vm.itemsPerPage,
                  "hide-default-footer": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function ({ item, index }) {
                      return [
                        _c("InvoiceLineItem", {
                          class: _vm.selectedRow == index ? "selected-row" : "",
                          attrs: {
                            invoice: item,
                            currentSelection: _vm.invoiceBusinessKeys,
                            showSelection: _vm.showSelectableTable,
                          },
                          on: {
                            "select-row": function ($event) {
                              return _vm.saveSelectedRow(index)
                            },
                            "add-to-selection": _vm.selectItem,
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "top",
                    fn: function () {
                      return [
                        _c(
                          "v-row",
                          { staticClass: "ma-0", attrs: { dense: "" } },
                          [
                            _c(
                              "v-col",
                              { staticClass: "d-flex align-center pa-0" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold ml-1" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.browseResponse.totalCount)
                                    ),
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "text-caption ml-1" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("label.resultsFound")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              { staticClass: "pa-1" },
                              [
                                _c("TableNav", {
                                  ref: "tableNav",
                                  attrs: {
                                    "browse-count":
                                      _vm.browseResponse.totalCount,
                                    "browse-limit": _vm.browseRequest.limit,
                                    "browse-offset": _vm.browseRequest.offset,
                                  },
                                  on: {
                                    "offset-changed":
                                      _vm.browseRequestOffsetChanged,
                                    "get-current-page": _vm.setCurrent,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { staticClass: "d-flex align-center pa-0" },
                              [
                                _c("v-spacer"),
                                _vm.internalVisibilityAuth
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "d-flex align-center pa-0",
                                      },
                                      [
                                        _c("v-select", {
                                          staticClass:
                                            "actions-select text-caption mx-1",
                                          staticStyle: { "max-width": "10rem" },
                                          attrs: {
                                            items: _vm.dashboardActions,
                                            "menu-props": {
                                              offsetY: true,
                                              contentClass: "mx-1",
                                            },
                                            "hide-details": "",
                                            clearable: "",
                                            outlined: "",
                                            "x-small": "",
                                            dense: "",
                                          },
                                          model: {
                                            value: _vm.currentAction,
                                            callback: function ($$v) {
                                              _vm.currentAction = $$v
                                            },
                                            expression: "currentAction",
                                          },
                                        }),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "px-0",
                                            attrs: {
                                              disabled:
                                                !_vm.invoiceBusinessKeys.length,
                                              color: "primary",
                                              icon: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.doAction(
                                                  _vm.currentAction
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { size: "30" } },
                                              [_vm._v(" mdi-play-circle ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _c("FiltersSectionCustomsDashboard", {
                                      ref: "filterSection",
                                      on: {
                                        sort: _vm.sort,
                                        filter: _vm.filter,
                                        "reset-filter":
                                          _vm.resetAllBrowseRequestFiltersAndBrowse,
                                      },
                                    }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.loading
                          ? _c("v-progress-linear", {
                              staticClass: "mt-1",
                              attrs: { indeterminate: "" },
                            })
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _vm.loading && _vm.browseResponse.resultList.length
                          ? _c("v-progress-linear", {
                              attrs: { indeterminate: "" },
                            })
                          : _vm._e(),
                        _c(
                          "v-row",
                          { staticClass: "ma-0", attrs: { dense: "" } },
                          [
                            _c(
                              "v-col",
                              { staticClass: "pa-0" },
                              [
                                _vm.browseResponse.resultList.length
                                  ? _c("TableNav", {
                                      ref: "tableNavFooter",
                                      attrs: {
                                        "browse-count":
                                          _vm.browseResponse.totalCount,
                                        "browse-limit": _vm.browseRequest.limit,
                                        "browse-offset":
                                          _vm.browseRequest.offset,
                                      },
                                      on: {
                                        "offset-changed":
                                          _vm.browseRequestOffsetChanged,
                                        "get-current-page": _vm.setCurrent,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "1000", color: "warning" },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function ({ attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { icon: "" },
                        on: {
                          click: function ($event) {
                            _vm.snackbar = false
                          },
                        },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [
                      _c("v-icon", { attrs: { color: "white" } }, [
                        _vm._v(" mdi-close "),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _c("v-icon", { staticClass: "mr-2", attrs: { color: "white" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.isSuccess ? "mdi-check-circle" : "mdi-alert-circle"
                ) +
                " "
            ),
          ]),
          _c("span", { staticClass: "white--text" }, [
            _vm._v(_vm._s(_vm.message)),
          ]),
        ],
        1
      ),
      [
        _c(
          "div",
          { staticClass: "text-center" },
          [
            _c(
              "v-dialog",
              {
                attrs: { persistent: "", width: "auto" },
                model: {
                  value: _vm.dialog,
                  callback: function ($$v) {
                    _vm.dialog = $$v
                  },
                  expression: "dialog",
                },
              },
              [
                _c(
                  "v-card",
                  { staticClass: "d-flex flex-column align-center" },
                  [
                    _c("v-card-subtitle", { staticClass: "py-1" }, [
                      _vm._v(_vm._s(_vm.dialogMsg)),
                    ]),
                    _c(
                      "v-card-text",
                      { staticClass: "py-1" },
                      [
                        _c("v-data-table", {
                          attrs: {
                            headers: [],
                            items: _vm.shipmentInvoices,
                            "hide-default-footer": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "item",
                              fn: function ({ item }) {
                                return [
                                  _c("ShipmentInvoice", {
                                    attrs: { relationship: item },
                                    on: {
                                      "add-to-selection": _vm.addToSelection,
                                      "remove-from-selection":
                                        _vm.removeFromSelection,
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "display-flex justify-center" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "success" },
                            on: { click: _vm.handleConfirm },
                          },
                          [_vm._v(_vm._s(_vm.$t("label.confirm")))]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "error" },
                            on: { click: _vm.handleCancel },
                          },
                          [_vm._v(_vm._s(_vm.$t("label.cancel")))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      [
        _c(
          "v-dialog",
          {
            attrs: { persistent: "", width: "auto" },
            model: {
              value: _vm.transportJobStatusDialog,
              callback: function ($$v) {
                _vm.transportJobStatusDialog = $$v
              },
              expression: "transportJobStatusDialog",
            },
          },
          [
            _c(
              "v-card",
              {
                staticClass: "d-flex flex-column align-center",
                attrs: { loading: _vm.loadingTransportJob },
              },
              [
                _c("v-card-text", [_vm._v(_vm._s(_vm.transportJobStatusMsg))]),
                _c(
                  "v-card-actions",
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { disabled: _vm.disabled },
                        on: {
                          click: function ($event) {
                            _vm.transportJobStatusDialog = false
                            _vm.browse()
                          },
                        },
                      },
                      [_vm._v("CANCEL")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }