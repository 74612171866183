<template>
  <v-container fill-height fluid>
    <v-app-bar
      app
      clipped-left
      :color="isProduction ? 'primary' : 'rossoSacmi'"
      style="border-bottom: 5px solid #64a70b !important;"
    >
      <v-toolbar-title class="text-caption pa-0">
        <v-img v-if="!isDemo && isProduction" src="@/assets/logo-italiansped.png" max-width="150" contain />
        <v-img v-if="!isDemo && !isProduction" src="@/assets/italiansped-logo.png" max-width="150" contain />
        <v-img v-if="isDemo" src="@/assets/logo-cirle-white.png" max-width="100" contain />
      </v-toolbar-title>
    </v-app-bar>
    <v-main class="fill-height">
      <PraticaExternalTracking :uuid="uuid"/>
    </v-main>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex' 
import PraticaExternalTracking from '@/components/elements/pratica/PraticaExternalTracking.vue'

export default {
  name: 'TrackingPublicShare',
  props: ['uuid'],
  components: {
    PraticaExternalTracking,
  },
  computed: {
    ...mapState('application', ['isProduction', 'isDemo']),
  },
  methods: {
    ...mapActions('application', ['checkIsProd', 'checkIsDemo']),
  },
  created(){
    this.$i18n.locale = 'en'
    this.checkIsProd()
    this.checkIsDemo()
  }
}
</script>
