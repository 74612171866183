var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "ma-0" },
        [
          _vm.icon
            ? _c(
                "v-col",
                { staticClass: "pa-0 pt-6 pr-2", attrs: { cols: "auto" } },
                [_c("v-icon", [_vm._v(_vm._s(_vm.icon))])],
                1
              )
            : _vm._e(),
          _c("v-col", { staticClass: "text-col pa-0" }, [
            _c(
              "span",
              {
                staticClass: "readonly-field-label text-caption primary--text",
              },
              [_vm._v(" " + _vm._s(_vm.label) + " ")]
            ),
            _c("br"),
            _vm.value
              ? _c("p", {
                  class: _vm.valueClass,
                  domProps: { innerHTML: _vm._s(_vm.value) },
                })
              : _c("p", { staticClass: "text-caption one-line" }, [
                  _vm._v(" " + _vm._s(_vm.$t("label.noAvailableData")) + " "),
                ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }