import Vue from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import DatetimePicker from 'vuetify-datetime-picker'
import msal from 'vue-msal'
import VueI18n from 'vue-i18n'
import _ from 'lodash'

import vuetify from '@/plugins/vuetify'
import VTiptap from "@peepi/vuetify-tiptap";
import { router } from '@/router'
import store from '@/store'
import './filters'
import i18n from './i18n'
import { msalMixin } from 'vue-msal'
import BrowseMemory from '@/mixins/BrowseMemory'
import Global from '@/mixins/Global'

Vue.config.productionTip = false
Object.defineProperty(Vue.prototype, '_', { value: _ })

Vue.use(DatetimePicker)
Vue.use(msal, {
  auth: {
    clientId: process.env.VUE_APP_CLIENT_ID,
    tenantId: process.env.VUE_APP_TENANT_ID,
    authority: 'https://login.microsoftonline.com/common/',
    postLogoutRedirectUri: window.location.origin,
    // redirectUri: window.location.origin
  },
  cache: {
    storeAuthStateInCookie: true,
  },
})

Vue.use(VueI18n)
Vue.use(VTiptap);

Vue.mixin(msalMixin)
Vue.mixin(BrowseMemory)
Vue.mixin(Global)

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
