var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      class: _vm.sendingEmail ? "no-pointer" : "",
      attrs: { width: "100%" },
    },
    [
      _c(
        "v-row",
        {
          staticClass: "ma-0",
          on: {
            dragover: function ($event) {
              $event.preventDefault()
              _vm.dragging = true
            },
            dragenter: function ($event) {
              $event.preventDefault()
              _vm.dragging = true
            },
            dragleave: function ($event) {
              $event.preventDefault()
              _vm.dragging = false
            },
          },
        },
        [
          _c(
            "v-col",
            { staticClass: "pa-0" },
            [
              _c(
                "v-flex",
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0" },
                        [
                          _c("v-combobox", {
                            ref: "to",
                            staticClass: "noicon-combo",
                            attrs: {
                              items: _vm.emailAddresses,
                              "item-text": "email",
                              "item-value": "email",
                              "return-object": false,
                              "menu-props": {
                                closeOnClick: true,
                              },
                              "search-input": _vm.searchInputMultiCombobox.to,
                              "hide-details": "",
                              multiple: "",
                              chips: "",
                              dense: "",
                              flat: "",
                              solo: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.onChange($event, "to")
                              },
                              "update:searchInput": function ($event) {
                                return _vm.$set(
                                  _vm.searchInputMultiCombobox,
                                  "to",
                                  $event
                                )
                              },
                              "update:search-input": function ($event) {
                                return _vm.$set(
                                  _vm.searchInputMultiCombobox,
                                  "to",
                                  $event
                                )
                              },
                              focus: function ($event) {
                                return _vm.showButtons()
                              },
                            },
                            nativeOn: {
                              input: function ($event) {
                                return _vm.onInput($event, "to")
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function (data) {
                                  return [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c(
                                          "v-list-item-title",
                                          { attrs: { dense: "" } },
                                          [
                                            _c(
                                              "b",
                                              { staticClass: "text-overline" },
                                              [_vm._v(_vm._s(data.item.agent))]
                                            ),
                                          ]
                                        ),
                                        _c("v-list-item-subtitle", [
                                          _c("span", [
                                            _vm._v(_vm._s(data.item.email)),
                                          ]),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "selection",
                                fn: function (data) {
                                  return [
                                    _c(
                                      "v-chip",
                                      _vm._b(
                                        {
                                          key: JSON.stringify(data.item),
                                          staticClass: "contact-chip",
                                          attrs: {
                                            "input-value": data.selected,
                                            disabled: data.disabled,
                                            close: "",
                                            small: "",
                                          },
                                          on: {
                                            "click:close": function ($event) {
                                              return _vm.removeContact(
                                                data.item,
                                                "to"
                                              )
                                            },
                                          },
                                        },
                                        "v-chip",
                                        data.attrs,
                                        false
                                      ),
                                      [
                                        _c(
                                          "v-avatar",
                                          {
                                            staticClass: "primary white--text",
                                            attrs: { left: "" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  data?.item
                                                    ?.slice(0, 1)
                                                    ?.toUpperCase()
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" " + _vm._s(data.item) + " "),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "prepend-inner",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "text--disabled" },
                                      [_vm._v(" To ")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "append-outer",
                                fn: function () {
                                  return [
                                    _vm.showCcButton
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "primary--text",
                                            attrs: {
                                              "x-small": "",
                                              text: "",
                                              rounded: "",
                                              color: "transparent",
                                            },
                                            on: { click: _vm.toggleCc },
                                          },
                                          [_vm._v(" Cc ")]
                                        )
                                      : _vm._e(),
                                    _vm.showBccButton
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "primary--text ml-1",
                                            attrs: {
                                              "x-small": "",
                                              text: "",
                                              rounded: "",
                                              color: "transparent",
                                            },
                                            on: { click: _vm.toggleBcc },
                                          },
                                          [_vm._v(" Bcc ")]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.newMessage.to,
                              callback: function ($$v) {
                                _vm.$set(_vm.newMessage, "to", $$v)
                              },
                              expression: "newMessage.to",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "my-0 mx-3" }),
                  _vm.showCc
                    ? _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pa-0" },
                            [
                              _c("v-combobox", {
                                ref: "cc",
                                attrs: {
                                  items: _vm.emailAddresses,
                                  "item-text": "email",
                                  "item-value": "email",
                                  "return-object": false,
                                  "menu-props": {
                                    closeOnClick: true,
                                  },
                                  "append-icon": _vm.newMessage.cc.length
                                    ? ""
                                    : "mdi-close",
                                  "search-input":
                                    _vm.searchInputMultiCombobox.cc,
                                  "hide-details": "",
                                  "small-chips": "",
                                  multiple: "",
                                  dense: "",
                                  flat: "",
                                  solo: "",
                                },
                                on: {
                                  "click:append": function ($event) {
                                    _vm.showCc = false
                                  },
                                  change: function ($event) {
                                    return _vm.onChange($event, "cc")
                                  },
                                  "update:searchInput": function ($event) {
                                    return _vm.$set(
                                      _vm.searchInputMultiCombobox,
                                      "cc",
                                      $event
                                    )
                                  },
                                  "update:search-input": function ($event) {
                                    return _vm.$set(
                                      _vm.searchInputMultiCombobox,
                                      "cc",
                                      $event
                                    )
                                  },
                                },
                                nativeOn: {
                                  input: function ($event) {
                                    return _vm.onInput($event, "cc")
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function (data) {
                                        return [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-list-item-title",
                                                { attrs: { dense: "" } },
                                                [
                                                  _c(
                                                    "b",
                                                    {
                                                      staticClass:
                                                        "text-overline",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(data.item.agent)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c("v-list-item-subtitle", [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(data.item.email)
                                                  ),
                                                ]),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "selection",
                                      fn: function (data) {
                                        return [
                                          _c(
                                            "v-chip",
                                            _vm._b(
                                              {
                                                key: JSON.stringify(data.item),
                                                staticClass: "contact-chip",
                                                attrs: {
                                                  "input-value": data.selected,
                                                  disabled: data.disabled,
                                                  close: "",
                                                  small: "",
                                                },
                                                on: {
                                                  "click:close": function (
                                                    $event
                                                  ) {
                                                    return _vm.removeContact(
                                                      data.item,
                                                      "cc"
                                                    )
                                                  },
                                                },
                                              },
                                              "v-chip",
                                              data.attrs,
                                              false
                                            ),
                                            [
                                              _c(
                                                "v-avatar",
                                                {
                                                  staticClass:
                                                    "primary white--text",
                                                  attrs: { left: "" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      data?.item
                                                        ?.slice(0, 1)
                                                        ?.toUpperCase()
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(
                                                " " + _vm._s(data.item) + " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "prepend-inner",
                                      fn: function () {
                                        return [
                                          _c(
                                            "span",
                                            { staticClass: "text--disabled" },
                                            [_vm._v(" Cc ")]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1668209615
                                ),
                                model: {
                                  value: _vm.newMessage.cc,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.newMessage, "cc", $$v)
                                  },
                                  expression: "newMessage.cc",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showCc
                    ? _c("v-divider", { staticClass: "my-0 mx-3" })
                    : _vm._e(),
                  _vm.showBcc
                    ? _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pa-0" },
                            [
                              _c("v-combobox", {
                                ref: "bcc",
                                staticClass: "noicon-combo",
                                attrs: {
                                  items: _vm.emailAddresses,
                                  "item-text": "email",
                                  "item-value": "email",
                                  "return-object": false,
                                  "menu-props": {
                                    closeOnClick: true,
                                  },
                                  "append-icon": _vm.newMessage.bcc.length
                                    ? ""
                                    : "mdi-close",
                                  "search-input":
                                    _vm.searchInputMultiCombobox.bcc,
                                  "hide-details": "",
                                  "small-chips": "",
                                  multiple: "",
                                  dense: "",
                                  flat: "",
                                  solo: "",
                                },
                                on: {
                                  "click:append": function ($event) {
                                    _vm.showBcc = false
                                  },
                                  change: function ($event) {
                                    return _vm.onChange($event, "bcc")
                                  },
                                  "update:searchInput": function ($event) {
                                    return _vm.$set(
                                      _vm.searchInputMultiCombobox,
                                      "bcc",
                                      $event
                                    )
                                  },
                                  "update:search-input": function ($event) {
                                    return _vm.$set(
                                      _vm.searchInputMultiCombobox,
                                      "bcc",
                                      $event
                                    )
                                  },
                                },
                                nativeOn: {
                                  input: function ($event) {
                                    return _vm.onInput($event, "bcc")
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function (data) {
                                        return [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-list-item-title",
                                                { attrs: { dense: "" } },
                                                [
                                                  _c(
                                                    "b",
                                                    {
                                                      staticClass:
                                                        "text-overline",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(data.item.agent)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c("v-list-item-subtitle", [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(data.item.email)
                                                  ),
                                                ]),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "selection",
                                      fn: function (data) {
                                        return [
                                          _c(
                                            "v-chip",
                                            _vm._b(
                                              {
                                                key: JSON.stringify(data.item),
                                                staticClass: "contact-chip",
                                                attrs: {
                                                  "input-value": data.selected,
                                                  disabled: data.disabled,
                                                  close: "",
                                                  small: "",
                                                },
                                                on: {
                                                  "click:close": function (
                                                    $event
                                                  ) {
                                                    return _vm.removeContact(
                                                      data.item,
                                                      "bcc"
                                                    )
                                                  },
                                                },
                                              },
                                              "v-chip",
                                              data.attrs,
                                              false
                                            ),
                                            [
                                              _c(
                                                "v-avatar",
                                                {
                                                  staticClass:
                                                    "primary white--text",
                                                  attrs: { left: "" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      data?.item
                                                        ?.slice(0, 1)
                                                        ?.toUpperCase()
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(
                                                " " + _vm._s(data.item) + " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "prepend-inner",
                                      fn: function () {
                                        return [
                                          _c(
                                            "span",
                                            { staticClass: "text--disabled" },
                                            [_vm._v(" Bcc ")]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  21247983
                                ),
                                model: {
                                  value: _vm.newMessage.bcc,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.newMessage, "bcc", $$v)
                                  },
                                  expression: "newMessage.bcc",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.showBcc
                ? _c("v-divider", { staticClass: "my-0 mx-3" })
                : _vm._e(),
              _c(
                "v-flex",
                { on: { click: _vm.hideButtons } },
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0" },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "hide-details": "",
                              dense: "",
                              flat: "",
                              solo: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "prepend-inner",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "text--disabled" },
                                      [_vm._v(" Subject ")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.newMessage.subject,
                              callback: function ($$v) {
                                _vm.$set(_vm.newMessage, "subject", $$v)
                              },
                              expression: "newMessage.subject",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "ma-0 pl-1 pb-1" },
                    [
                      _c(
                        "v-col",
                        {
                          class: _vm.dragover ? "dashed" : "",
                          on: {
                            dragover: function ($event) {
                              $event.preventDefault()
                              _vm.dragover = true
                            },
                            dragleave: function ($event) {
                              $event.preventDefault()
                              _vm.dragover = false
                            },
                            drop: function ($event) {
                              $event.preventDefault()
                              return _vm.onDrop($event)
                            },
                          },
                        },
                        [
                          _vm.dragging
                            ? _c(
                                "div",
                                { staticClass: "no-pointer" },
                                [
                                  _c("v-tiptap", {
                                    attrs: { toolbar: _vm.toolbar },
                                    model: {
                                      value: _vm.newMessage.text,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.newMessage, "text", $$v)
                                      },
                                      expression: "newMessage.text",
                                    },
                                  }),
                                  _vm.dragover
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-center align-items-center mt-3",
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-3",
                                              attrs: { size: "60" },
                                            },
                                            [_vm._v(" mdi-cloud-upload ")]
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("label.dropFilesHere")
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                [
                                  _c("v-tiptap", {
                                    attrs: { toolbar: _vm.toolbar },
                                    model: {
                                      value: _vm.newMessage.text,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.newMessage, "text", $$v)
                                      },
                                      expression: "newMessage.text",
                                    },
                                  }),
                                ],
                                1
                              ),
                          _vm.uploadedFiles.length > 0
                            ? _c(
                                "v-row",
                                {
                                  staticClass: "d-flex mt-1 mx-0",
                                  attrs: { dense: "" },
                                },
                                _vm._l(_vm.uploadedFiles, function (attach, i) {
                                  return _c(
                                    "v-col",
                                    {
                                      key: i,
                                      staticClass: "pa-0 mr-2",
                                      attrs: { cols: "auto" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "max-width": "max-content",
                                          },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-attachment"),
                                          ]),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "text-caption mx-1",
                                            },
                                            [_vm._v(_vm._s(attach.name))]
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              staticStyle: {
                                                "padding-top": "0.1rem",
                                              },
                                              attrs: { "x-small": "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeFile(
                                                    attach.name
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("mdi-close")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", {
            staticClass: "my-2 mx-1",
            attrs: { vertical: "" },
          }),
          _c(
            "v-col",
            {
              staticClass: "d-flex flex-column align-center pa-0 pt-1 pr-1",
              attrs: { cols: "auto" },
              on: { click: _vm.hideButtons },
            },
            [
              _vm.sendingEmail
                ? _c(
                    "div",
                    { staticClass: "progress" },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          size: "25",
                          indeterminate: "",
                          color: "primary",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.sendingEmail && !_vm.isReply
                ? _c(
                    "v-btn",
                    { attrs: { icon: "" }, on: { click: _vm.sendEmail } },
                    [_c("v-icon", [_vm._v("mdi-email")])],
                    1
                  )
                : _vm._e(),
              !_vm.sendingEmail && _vm.isReply
                ? _c(
                    "v-btn",
                    { attrs: { icon: "" }, on: { click: _vm.updateMail } },
                    [_c("v-icon", [_vm._v("mdi-email")])],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "mt-1" },
                [
                  _c("v-file-input", {
                    staticClass: "pa-0 ma-0",
                    attrs: {
                      "prepend-icon": "mdi-attachment",
                      "hide-input": "",
                    },
                    on: { change: _vm.uploadFile },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }