<template>
  <v-expand-transition>
    <v-card outlined class="rounded-0" v-show="open">
      <v-card-text class="pa-0">
        <v-simple-table dense class="pa-3">
          <template>
            <thead class="t-head">
              <tr>
                <th class="text-left">{{ $t("label.position") }}</th>
                <th class="text-left">{{ $t("label.itemDescription") }}</th>
                <th class="text-left">{{ $t("label.qty") }}</th>
                <th class="text-left">{{ $t("label.grossWeight") }} / {{ $t("label.netWeight") }}</th>
                <th class="text-left">{{ $t("label.lineAmount") }}</th>
                <th class="text-left">{{ $t("label.tariffCode") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(detail, i) in details" :key="i">
                <td>{{ detail.lineNbr }}</td>
                <td>{{ detail.itemDescription }}</td>
                <td>{{ detail.qty }}</td>
                <td>
                  {{ detail.grossWeight }} / {{ detail.netWeight }}
                  {{ detail.unitOfMeasureWeight }}S
                </td>
                <td>{{ detail.lineAmount }}</td>
                <td>{{ detail.tariffCode }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-expand-transition>
</template>

<script>
import { invoicesDetailService } from "@/services/invoicesDetail.service.js";

export default {
  props: ["open", "documentNbr"],
  data() {
    return {
      details: [],
    };
  },
  watch: {
    open(isOpen) {
      if (!isOpen) return;
      this.loadDetailForSapInvoice();
    },
  },
  methods: {
    loadDetailForSapInvoice() {
      const browseFilter = { documentNbr: this.documentNbr };
      invoicesDetailService.browse(0, 10, browseFilter, []).then((resp) => {
        if (resp) this.details = resp.resultList;
      });
    },
  },
};
</script>
