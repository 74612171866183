import { authHeader } from '@/helpers/auth-header'
import { commons } from './commons'

export const nodiService = {
  getAllNodi,
  getNodoById,
}

const resourceUrl = process.env.VUE_APP_GSCV_ENGINE_URL + `/nodo`

function getAllNodi() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl, requestOptions).then(commons.handleJsonResponse)
}

function getNodoById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/${id}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}
