<template>
  <!-- <v-container
    class="d-table pa-0"
    style="background-color: transparent;"
    fill-height
    fluid
  > -->
    <!-- <div class="d-table-header-group">
      <v-card class="d-flex mb-2" light>
        <v-row dense>
          <v-col cols="auto" class="d-flex align-center pa-2 pr-1">
            <v-btn icon color="primary">
              <v-icon size="30">mdi-arrow-left-circle</v-icon>
            </v-btn>
          </v-col>
          <v-col class="d-flex align-center py-2 px-0">
            <span class="text-h5 font-weight-black">
              AAA
            </span>
          </v-col>
          <v-col class="d-flex align-center justify-end py-2 px-2">
            <span class="text-h5 font-weight-black primary--text">
              AAA
            </span>
          </v-col>
        </v-row>
      </v-card>
    </div> -->
    <v-stepper v-model="step" class="d-table fill-height" width="100%" light>
      <!-- non-linear -->
      <v-stepper-header class="d-table-row">
        <div class="d-flex">
          <v-stepper-step :step="1" :editable="!uneditableRequest">
            <span class="text-overline"> {{ $t('label.documentProperties') }} </span>
          </v-stepper-step>
          <v-divider />
          <v-stepper-step :step="2" :editable="editableSelectVoyage">
            <span class="text-overline"> {{ $t('label.voyage') }} </span>
          </v-stepper-step>
          <v-divider />
          <v-stepper-step :step="3" :editable="editableSelectEquipments">
            <span class="text-overline"> {{ $t('label.cargoDetails') }} </span>
          </v-stepper-step>
          <v-divider />
          <v-stepper-step :step="4" :editable="editableSelectParties">
            <span class="text-overline"> {{ $t('label.parties') }} </span>
          </v-stepper-step>
          <v-divider />
          <v-stepper-step :step="5" :editable="editableSelectPayment">
            <span class="text-overline"> {{ $t('label.paymentDetails') }} </span>
          </v-stepper-step>
          <v-divider />
          <v-stepper-step :step="6" :editable="editableReview">
            <span class="text-overline"> {{ $t('label.review') }} </span>
          </v-stepper-step>
        </div>
      </v-stepper-header>

      <v-stepper-items class="d-table-row">
        <component
          :is="stepToShow.component"
          :ref="stepToShow.ref"
          :key="stepToShow.component"
          :pratica="pratica"
          @step="handleSteps"
          v-on="$listeners"
        />
      </v-stepper-items>
    </v-stepper>
  <!-- </v-container> -->
</template>

<script>
import ShippingInstruction from '@/mixins/ShippingInstruction.js'
import ShippingInstructionInfo from '@/components/elements/shipping-instruction/ShippingInstructionInfo.vue'
import ShippingInstructionVoyage from '@/components/elements/shipping-instruction/ShippingInstructionVoyage.vue'
import ShippingInstructionEquipments from '@/components/elements/shipping-instruction/ShippingInstructionEquipments.vue'
import ShippingInstructionParties from '@/components/elements/shipping-instruction/ShippingInstructionParties.vue'
import ShippingInstructionPayments from '@/components/elements/shipping-instruction/ShippingInstructionPayments.vue'
import ShippingInstructionReview from '@/components/elements/shipping-instruction/ShippingInstructionReview.vue'

export default {
  name: 'ShippingInstructionStepper',
  props: ['pratica'],
  emits: [],
  mixins: [ShippingInstruction],
  components: {
    ShippingInstructionInfo,
    ShippingInstructionVoyage,
    ShippingInstructionEquipments,
    ShippingInstructionParties,
    ShippingInstructionPayments,
    ShippingInstructionReview
  },
  data() {
    return {
      step: 1,
      items: [
        {
          component: 'ShippingInstructionInfo',
          ref: 'shippingInstructionInfo',
        },
        {
          component: 'ShippingInstructionVoyage',
          ref: 'shippingInstructionVoyage',
        },
        {
          component: 'ShippingInstructionEquipments',
          ref: 'shippingInstructionEquipments',
        },
        {
          component: 'ShippingInstructionParties',
          ref: 'shippingInstructionParties',
        },
        {
          component: 'ShippingInstructionPayments',
          ref: 'shippingInstructionPayments',
        },
        {
          component: 'ShippingInstructionReview',
          ref: 'shippingInstructionReview',
        },
      ],
    }
  },
  computed: {
    stepToShow() {
      return this.step == 0 ? {} : this.items[this.step - 1]
    },
  },
  methods: {
    init() {
      if (this.savedShippingInstructionRequest) this.setShippingInstruction(this.savedShippingInstructionRequest)
      this.setCurrentStep()
    },
    setCurrentStep() {
      switch (this.electronicFlowStatus) {
        case this.createdStatus:
          this.step = 1
          break
        case this.detailsSelectedStatus:
          this.step = 2
          break
          case this.voyageSelectedStatus:
          this.step = 3
          break
          case this.equipmentsSelectedStatus:
          this.step = 4
          break
          case this.partiesSelectedStatus:
          this.step = 5
          break
        case this.pendingStatus:
        case this.confirmedStatus:
        case this.rejectedStatus:
        case this.cancelledStatus:
        case this.paymentSelectedStatus: 
          this.step = 6
          break
      }
    },
    handleSteps(forward) {
      this.step = forward ? (this.step += 1) : (this.step -= 1)
    },
  },
  created() {
    this.init()
  },
}
</script>

<style scoped>
.v-stepper::v-deep .v-stepper__header {
  height: 0 !important;
}
.v-stepper::v-deep .v-stepper__step {
  padding-block: 0.5rem !important;
}
</style>