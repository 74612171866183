var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "stepper-box flex-column align-center",
      attrs: { fluid: "" },
    },
    [
      _c(
        "v-row",
        { staticClass: "ma-0", staticStyle: { "max-width": "max-content" } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-avatar",
                {
                  class: _vm.convertedStatus > 0 ? "avatar-complete" : "avatar",
                  attrs: { size: "70" },
                },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        size: "40",
                        color: _vm.convertedStatus > 0 ? "primary" : "grey",
                        rounded: "",
                      },
                    },
                    [_vm._v(" mdi-factory ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.$vuetify.breakpoint.smAndDown
            ? _c("v-col", { staticClass: "divider-col" }, [
                _c("hr", {
                  class:
                    _vm.convertedStatus >= 1 ? "solid-line" : "dashed-line",
                }),
              ])
            : _vm._e(),
          _c(
            "v-col",
            [
              _c(
                "v-avatar",
                {
                  class:
                    _vm.convertedStatus >= 1 ? "avatar-complete" : "avatar",
                  attrs: { size: "70" },
                },
                [
                  _vm.saleChannelDesc(_vm.pratica.saleChannel) == "Nave"
                    ? _c(
                        "v-icon",
                        {
                          attrs: {
                            size: "40",
                            color:
                              _vm.convertedStatus >= 1 ? "primary" : "grey",
                            rounded: "",
                          },
                        },
                        [_vm._v(" mdi-ferry ")]
                      )
                    : _vm._e(),
                  _vm.saleChannelDesc(_vm.pratica.saleChannel) == "Aereo"
                    ? _c(
                        "v-icon",
                        {
                          attrs: {
                            size: "40",
                            color: _vm.convertedStatus > 1 ? "primary" : "grey",
                            rounded: "",
                          },
                        },
                        [_vm._v(" mdi-airplane ")]
                      )
                    : _vm._e(),
                  _vm.saleChannelDesc(_vm.pratica.saleChannel) == "Terra"
                    ? _c(
                        "v-icon",
                        {
                          attrs: {
                            size: "40",
                            color: _vm.convertedStatus > 1 ? "primary" : "grey",
                            rounded: "",
                          },
                        },
                        [_vm._v(" mdi-truck ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.$vuetify.breakpoint.smAndDown
            ? _c("v-col", { staticClass: "divider-col" }, [
                _c("hr", {
                  class:
                    _vm.convertedStatus > 2
                      ? "solid-line"
                      : _vm.convertedStatus == 2
                      ? "dashed-line-pending"
                      : "dashed-line",
                }),
              ])
            : _vm._e(),
          _c(
            "v-col",
            [
              _c(
                "v-avatar",
                {
                  class:
                    _vm.convertedStatus == 3 ? "avatar-complete" : "avatar",
                  attrs: { size: "70" },
                },
                [
                  _vm.saleChannelDesc(_vm.pratica.saleChannel) == "Nave"
                    ? _c(
                        "v-icon",
                        {
                          attrs: {
                            size: "40",
                            color:
                              _vm.convertedStatus == 3 ? "primary" : "grey",
                            rounded: "",
                          },
                        },
                        [_vm._v(" mdi-ferry ")]
                      )
                    : _vm._e(),
                  _vm.saleChannelDesc(_vm.pratica.saleChannel) == "Aereo"
                    ? _c(
                        "v-icon",
                        {
                          attrs: {
                            size: "40",
                            color:
                              _vm.convertedStatus == 3 ? "primary" : "grey",
                            rounded: "",
                          },
                        },
                        [_vm._v(" mdi-airplane ")]
                      )
                    : _vm._e(),
                  _vm.saleChannelDesc(_vm.pratica.saleChannel) == "Terra"
                    ? _c(
                        "v-icon",
                        {
                          attrs: {
                            size: "40",
                            color: _vm.convertedStatus > 1 ? "primary" : "grey",
                            rounded: "",
                          },
                        },
                        [_vm._v(" mdi-truck ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "ma-0", staticStyle: { "max-width": "fit-content" } },
        [
          _c("v-col", [
            _c("div", { staticClass: "address-box" }, [
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.steps.pickupLocation) },
              }),
            ]),
          ]),
          !_vm.$vuetify.breakpoint.smAndDown
            ? _c("v-col", { staticClass: "divider-col" })
            : _vm._e(),
          _c("v-col", { staticClass: "d-block" }, [
            _c("div", { staticClass: "address-box" }, [
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.steps.departureLocation) },
              }),
            ]),
            _c("div", { staticClass: "address-box" }, [
              _c("span", { domProps: { innerHTML: _vm._s(_vm.etd) } }),
            ]),
          ]),
          !_vm.$vuetify.breakpoint.smAndDown
            ? _c("v-col", { staticClass: "divider-col" })
            : _vm._e(),
          _c("v-col", { staticClass: "d-block" }, [
            _c("div", { staticClass: "address-box" }, [
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.steps.arrivalLocation) },
              }),
            ]),
            _c("div", { staticClass: "address-box" }, [
              _c("span", { domProps: { innerHTML: _vm._s(_vm.eta) } }),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }