<template>
  <v-container class="d-block" fill-height fluid>
    <div class="pa-5">
      <v-btn color="primary" @click="openDialog">
        <v-icon>mdi-plus</v-icon>
        {{ $t('label.newUser') }}
      </v-btn>

      <v-dialog v-model="dialog" width="40%">
        <v-card>
          <v-card-title>{{ $t('label.newUser') }}</v-card-title>
          <v-alert v-model="errorAlert" type="error" dismissible>
            {{ errorMessage }}
          </v-alert>
          <v-card-text>
            <v-form ref="form">
              <v-row>
                <v-col>
                  <v-text-field v-model="userData.firstName" :label="$t('label.firstName')" required></v-text-field>
                  <v-text-field v-model="userData.username" :label="$t('label.username')" required></v-text-field>
                  <v-text-field v-model="userData.password" :label="$t('label.password')" type="password"
                                required></v-text-field>
                  <v-select
                      v-model="userData.groups"
                      :label="$t('label.groups')"
                      :items="groups"
                      item-text="groupDes"
                      item-value="id"
                      return-object
                      class="pb-5 white--text"
                      hide-details
                      multiple
                      flat
                  />
                  <v-menu
                      v-model="menuCustomerCreate"
                      offset-y
                      attach
                      close-on-click
                      :open-on-click="false"
                      :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                          :label="$t('label.clients')"
                          ref="customersCreate"
                          :value="formatSelectedCustomer"
                          item-text="customerName"
                          v-if="!showSearch['customersCreate']"
                          @click:append="userData.customers = []"
                          @click="showSearch['customersCreate'] = true"
                          prepend-inner-icon="mdi-account-arrow-right"
                          :append-icon="userData.customers.length ? 'mdi-close' : ''"
                          hide-details
                          readonly
                          class="pb-5"
                      />
                      <v-text-field
                          v-else
                          :label="$t('label.clients')"
                          v-model="search.customersCreate"
                          v-on="on"
                          @click.stop
                          @click="handleSearch('customersCreate')"
                          @click:append="
                      searchCustomers(search.customersCreate, 'customersCreate')
                    "
                          @keydown.enter="
                      searchCustomers(search.customersCreate, 'customersCreate')
                    "
                          @blur="resetSearchBox('shipperId')"
                          prepend-inner-icon="mdi-account-arrow-right"
                          append-icon="mdi-magnify"
                          hide-details
                      />
                    </template>
                    <v-card class="dropdown-menu">
                      <v-data-table
                          v-model="userData.customers"
                          :headers="[{ text: '', value: 'customerName' }]"
                          :items="customersFilterList"
                          :items-per-page="4"
                          :page.sync="tablePage"
                          hide-default-header
                          class="custom-table"
                          checkbox-color="primary"
                          show-select
                      >
                        <template v-slot:item.customerName="{ item }">
                          <td class="custom-table-td">{{ item.customerName }}</td>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-menu>
                  <v-menu
                      v-model="menuForeignAgentCreate"
                      offset-y
                      attach
                      close-on-click
                      :open-on-click="false"
                      :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                          :label="$t('label.foreignAgents')"
                          ref="foreignAgentsCreate"
                          :value="formatSelectedForeignAgent"
                          item-text="customerName"
                          v-if="!showSearch['foreignAgentsCreate']"
                          @click:append="userData.foreignAgents = []"
                          @click="showSearch['foreignAgentsCreate'] = true"
                          prepend-inner-icon="mdi-account-arrow-right"
                          :append-icon="userData.foreignAgents.length ? 'mdi-close' : ''"
                          hide-details
                          readonly
                      />
                      <v-text-field
                          v-else
                          :label="$t('label.foreignAgents')"
                          v-model="search.foreignAgentsCreate"
                          v-on="on"
                          @click.stop
                          @click="handleSearch('foreignAgentsCreate')"
                          @click:append="
                      searchCustomers(search.foreignAgentsCreate, 'foreignAgentsCreate')
                    "
                          @keydown.enter="
                      searchCustomers(search.foreignAgentsCreate, 'foreignAgentsCreate')
                    "
                          @blur="resetSearchBox('shipperId')"
                          prepend-inner-icon="mdi-account-arrow-right"
                          append-icon="mdi-magnify"
                          hide-details
                      />
                    </template>
                    <v-card class="dropdown-menu">
                      <v-data-table
                          v-model="userData.foreignAgents"
                          :headers="[{ text: '', value: 'customerName' }]"
                          :items="customersFilterList"
                          :items-per-page="4"
                          :page.sync="tablePage"
                          hide-default-header
                          class="custom-table"
                          checkbox-color="primary"
                          show-select
                      >
                        <template v-slot:item.customerName="{ item }">
                          <td class="custom-table-td">{{ item.customerName }}</td>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-text-field v-model="userData.lastName" :label="$t('label.lastName')" required></v-text-field>
                  <v-text-field v-model="userData.email" :label="$t('label.email')" type="email"
                                required></v-text-field>
                  <v-text-field v-model="userData.repeatPassword" :label="$t('label.repeatPassword')" type="password"
                                required></v-text-field>
                  <br/><br/><br/>
                  <v-menu
                      v-model="menuShipperCreate"
                      offset-y
                      attach
                      close-on-click
                      :open-on-click="false"
                      :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                          :label="$t('label.shippers')"
                          ref="shippersCreate"
                          :value="formatSelectedShippers"
                          item-text="customerName"
                          v-if="!showSearch['shippersCreate']"
                          @click:append="userData.shippers = []"
                          @click="showSearch['shippersCreate'] = true"
                          prepend-inner-icon="mdi-account-arrow-right"
                          :append-icon="userData.shippers.length ? 'mdi-close' : ''"
                          hide-details
                          readonly
                          class="pb-5"
                      />
                      <v-text-field
                          v-else
                          :label="$t('label.shippers')"
                          v-model="search.shippersCreate"
                          v-on="on"
                          @click.stop
                          @click="handleSearch('shippersCreate')"
                          @click:append="
                      searchCustomers(search.shippersCreate, 'shippersCreate')
                    "
                          @keydown.enter="
                      searchCustomers(search.shippersCreate, 'shippersCreate')
                    "
                          @blur="resetSearchBox('shipperId')"
                          prepend-inner-icon="mdi-account-arrow-right"
                          append-icon="mdi-magnify"
                          hide-details
                      />
                    </template>
                    <v-card class="dropdown-menu">
                      <v-data-table
                          v-model="userData.shippers"
                          :headers="[{ text: '', value: 'customerName' }]"
                          :items="customersFilterList"
                          :items-per-page="4"
                          :page.sync="tablePage"
                          hide-default-header
                          class="custom-table"
                          checkbox-color="primary"
                          show-select
                      >
                        <template v-slot:item.customerName="{ item }">
                          <td class="custom-table-td">{{ item.customerName }}</td>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-menu>
                  <v-menu
                      v-model="menuConsigneeCreate"
                      offset-y
                      attach
                      close-on-click
                      :open-on-click="false"
                      :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                          :label="$t('label.consignees')"
                          ref="consigneesCreate"
                          :value="formatSelectedConsignee"
                          item-text="customerName"
                          v-if="!showSearch['consigneesCreate']"
                          @click:append="userData.consignees = []"
                          @click="showSearch['consigneesCreate'] = true"
                          prepend-inner-icon="mdi-account-arrow-right"
                          :append-icon="userData.consignees.length ? 'mdi-close' : ''"
                          hide-details
                          readonly
                      />
                      <v-text-field
                          v-else
                          :label="$t('label.consignees')"
                          v-model="search.consigneesCreate"
                          v-on="on"
                          @click.stop
                          @click="handleSearch('consigneesCreate')"
                          @click:append="
                      searchCustomers(search.consigneesCreate, 'consigneesCreate')
                    "
                          @keydown.enter="
                      searchCustomers(search.consigneesCreate, 'consigneesCreate')
                    "
                          @blur="resetSearchBox('shipperId')"
                          prepend-inner-icon="mdi-account-arrow-right"
                          append-icon="mdi-magnify"
                          hide-details
                      />
                    </template>
                    <v-card class="dropdown-menu">
                      <v-data-table
                          v-model="userData.consignees"
                          :headers="[{ text: '', value: 'customerName' }]"
                          :items="customersFilterList"
                          :items-per-page="4"
                          :page.sync="tablePage"
                          hide-default-header
                          class="custom-table"
                          checkbox-color="primary"
                          show-select
                      >
                        <template v-slot:item.customerName="{ item }">
                          <td class="custom-table-td">{{ item.customerName }}</td>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-menu>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="saveUser">{{ $t('label.save') }}</v-btn>
            <v-btn @click="closeDialog">{{ $t('label.cancel') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-progress-linear
        indeterminate
        color="primary"
        class="mb-1"
        v-if="loading"
    />
    <v-expansion-panels v-model="panel" width="100%" class="align-self-start">
      <v-expansion-panel v-for="(user, i) in users" :key="i">
        <v-expansion-panel-header disable-icon-rotate class="px-3 py-0">
          <template v-slot:actions v-if="panel == i">
            <v-btn color="red" fab small @click.stop="confirmDelete">
              <v-icon size="25" color="white">
                mdi-delete
              </v-icon>
            </v-btn>
            <v-dialog v-model="deleteDialog" max-width="500px">
              <v-card>
                <v-card-title class="headline">{{ $t('label.confirmDelete') }}</v-card-title>
                <v-card-text>
                  {{ $t('message.confirmDeletion') }}
                </v-card-text>
                <v-card-actions>
                  <v-btn color="primary" text @click="deleteDialog = false">{{ $t('label.cancel') }}</v-btn>
                  <v-btn color="red" class="white--text" @click="deleteUser(currentUser)">{{
                      $t('label.delete')
                    }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-btn
                fab
                small
                color="success"
                @click.stop="updateUser(currentUser)"
            >
              <v-icon size="25" color="white">
                mdi-content-save
              </v-icon>
            </v-btn>
          </template>
          <v-row class="ma-0">
            <v-col class="d-flex align-center pa-0">
              <span>{{ user.lastName }}</span>
              <span class="ml-2">{{ user.firstName }}</span>
            </v-col>
            <v-col class="d-flex align-center pa-0">
              <span>{{ user.email }}</span>
            </v-col>
            <v-col class="pa-0">
              <v-chip
                  class="ma-2 pa-2 text-overline"
                  color="primary"
                  small
                  v-for="(element, i) in user.groups"
                  :key="i"
              >
                <v-icon small class="pr-1">
                  {{ getIcon(element.groupDes) }}
                </v-icon>
                {{ element.groupDes }}
              </v-chip>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="ma-0">
            <v-col class="pa-0 pl-3">
              <span class="text-overline primary--text">
                {{ $t('label.settings') }}
              </span>
            </v-col>
          </v-row>
          <v-divider class="mx-2 mt-0 mb-5"/>
          <v-row class="ma-0">
            <v-col class="pa-5">
              <v-text-field v-model="currentUser.firstName" :label="$t('label.firstName')"></v-text-field>
            </v-col>
            <v-col class="pa-5">
              <v-text-field v-model="currentUser.lastName" :label="$t('label.lastName')"></v-text-field>
            </v-col>
            <v-col class="pa-5">
              <v-text-field v-model="currentUser.email" :label="$t('label.email')"></v-text-field>
            </v-col>
            <v-col class="pa-5">
              <v-text-field v-model="currentUser.password" :label="$t('label.password')" type="password"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col
                class="pa-5"
                :cols="$vuetify.breakpoint.smAndDown ? '12' : '2'"
            >
              <v-select
                  v-model="userParams['language']"
                  :items="languageItems"
                  :label="$t('label.language')"
                  item-text="text"
                  return-object
                  prepend-icon="mdi-translate"
                  class="no-overflow white--text"
                  hide-details
                  dense
                  flat
              />
            </v-col>
            <v-col
                class="pa-5"
                :cols="$vuetify.breakpoint.smAndDown ? '12' : '5'"
            >
              <v-select
                  :label="$t('label.groups')"
                  v-model="currentUser.groups"
                  :items="groups"
                  item-text="groupDes"
                  item-value="id"
                  return-object
                  prepend-icon="mdi-account-group"
                  class="no-overflow white--text"
                  hide-details
                  multiple
                  dense
                  flat
              />
            </v-col>
            <v-col
                class="pa-5"
                :cols="$vuetify.breakpoint.smAndDown ? '12' : '5'"
            >
              <v-menu
                  v-model="menuCustomer"
                  offset-y
                  attach
                  close-on-click
                  :open-on-click="false"
                  :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      :label="$t('label.customers')"
                      ref="customers"
                      :value="formatCustomers"
                      item-text="customerName"
                      v-if="!showSearch['customers']"
                      @click:append="currentUser.customers = []"
                      @click="showSearch['customers'] = true"
                      prepend-inner-icon="mdi-account-arrow-right"
                      :append-icon="user.customers.length ? 'mdi-close' : ''"
                      hide-details
                      readonly
                      dense
                  />
                  <v-text-field
                      v-else
                      :label="$t('label.customers')"
                      v-model="search.customers"
                      v-on="on"
                      @click.stop
                      @click="handleSearch('customers')"
                      @click:append="
                      searchCustomers(search.customers, 'customers')
                    "
                      @keydown.enter="
                      searchCustomers(search.customers, 'customers')
                    "
                      @blur="resetSearchBox('shipperId')"
                      prepend-inner-icon="mdi-account-arrow-right"
                      append-icon="mdi-magnify"
                      hide-details
                      dense
                  />
                </template>
                <v-card class="dropdown-menu">
                  <v-data-table
                      v-model="currentUser.customers"
                      :headers="[{ text: '', value: 'customerName' }]"
                      :items="customersFilterList"
                      :items-per-page="4"
                      :page.sync="tablePage"
                      @item-selected="
                      (v) => selectItem(v, 'customers', 'customerId')
                    "
                      hide-default-header
                      class="custom-table"
                      checkbox-color="primary"
                      show-select
                      dense
                  >
                    <template v-slot:item.customerName="{ item }">
                      <td class="custom-table-td">
                        {{ item.customerName }}
                      </td>
                    </template>
                  </v-data-table>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col
                class="pa-5"
                :cols="$vuetify.breakpoint.smAndDown ? '12' : '4'"
            >
              <v-menu
                  v-model="menuShipper"
                  offset-y
                  attach
                  close-on-click
                  :open-on-click="false"
                  :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      :label="$t('label.shippers')"
                      ref="shippers"
                      :value="formatShippers"
                      item-text="customerName"
                      v-if="!showSearch['shippers']"
                      @click:append="currentUser.shippers = []"
                      @click="showSearch['shippers'] = true"
                      prepend-inner-icon="mdi-account-arrow-right"
                      :append-icon="user.shippers.length ? 'mdi-close' : ''"
                      hide-details
                      readonly
                      dense
                  />
                  <v-text-field
                      v-else
                      :label="$t('label.shippers')"
                      v-model="search.shippers"
                      v-on="on"
                      @click.stop
                      @click="handleSearch('shippers')"
                      @click:append="
                      searchCustomers(search.shippers, 'shippers')
                    "
                      @keydown.enter="
                      searchCustomers(search.shippers, 'shippers')
                    "
                      @blur="resetSearchBox('shipperId')"
                      prepend-inner-icon="mdi-account-arrow-right"
                      append-icon="mdi-magnify"
                      hide-details
                      dense
                  />
                </template>
                <v-card class="dropdown-menu">
                  <v-data-table
                      v-model="currentUser.shippers"
                      :headers="[{ text: '', value: 'customerName' }]"
                      :items="customersFilterList"
                      :items-per-page="4"
                      :page.sync="tablePage"
                      @item-selected="
                      (v) => selectItem(v, 'shippers', 'customerId')
                    "
                      hide-default-header
                      class="custom-table"
                      checkbox-color="primary"
                      show-select
                      dense
                  >
                    <template v-slot:item.customerName="{ item }">
                      <td class="custom-table-td">
                        {{ item.customerName }}
                      </td>
                    </template>
                  </v-data-table>
                </v-card>
              </v-menu>
            </v-col>
            <v-col
                class="pa-5"
                :cols="$vuetify.breakpoint.smAndDown ? '12' : '4'"
            >
              <v-menu
                  v-model="menuConsignee"
                  offset-y
                  attach
                  close-on-click
                  :open-on-click="false"
                  :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      :label="$t('label.consignees')"
                      ref="consignees"
                      :value="formatConsignees"
                      item-text="customerName"
                      v-if="!showSearch['consignees']"
                      @click:append="currentUser.consignees = []"
                      @click="showSearch['consignees'] = true"
                      prepend-inner-icon="mdi-account-arrow-right"
                      :append-icon="user.consignees.length ? 'mdi-close' : ''"
                      hide-details
                      readonly
                      dense
                  />
                  <v-text-field
                      v-else
                      :label="$t('label.consignees')"
                      v-model="search.consignees"
                      v-on="on"
                      @click.stop
                      @click="handleSearch('consignees')"
                      @click:append="
                      searchCustomers(search.consignees, 'consignees')
                    "
                      @keydown.enter="
                      searchCustomers(search.consignees, 'consignees')
                    "
                      @blur="resetSearchBox('shipperId')"
                      prepend-inner-icon="mdi-account-arrow-right"
                      append-icon="mdi-magnify"
                      hide-details
                      dense
                  />
                </template>
                <v-card class="dropdown-menu">
                  <v-data-table
                      v-model="currentUser.consignees"
                      :headers="[{ text: '', value: 'customerName' }]"
                      :items="customersFilterList"
                      :items-per-page="4"
                      :page.sync="tablePage"
                      @item-selected="
                      (v) => selectItem(v, 'consignees', 'customerId')
                    "
                      hide-default-header
                      class="custom-table"
                      checkbox-color="primary"
                      show-select
                      dense
                  >
                    <template v-slot:item.customerName="{ item }">
                      <td class="custom-table-td">
                        {{ item.customerName }}
                      </td>
                    </template>
                  </v-data-table>
                </v-card>
              </v-menu>
            </v-col>
            <v-col
                class="pa-5"
                :cols="$vuetify.breakpoint.smAndDown ? '12' : '4'"
            >
              <v-menu
                  v-model="menuForeignAgent"
                  offset-y
                  attach
                  close-on-click
                  :open-on-click="false"
                  :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      :label="$t('label.foreignAgents')"
                      ref="foreignAgents"
                      :value="formatForeignAgents"
                      item-text="customerName"
                      v-if="!showSearch['foreignAgents']"
                      @click:append="currentUser.foreignAgents = []"
                      @click="showSearch['foreignAgents'] = true"
                      prepend-inner-icon="mdi-account-arrow-right"
                      :append-icon="user.foreignAgents.length ? 'mdi-close' : ''"
                      hide-details
                      readonly
                      dense
                  />
                  <v-text-field
                      v-else
                      :label="$t('label.foreignAgents')"
                      v-model="search.foreignAgents"
                      v-on="on"
                      @click.stop
                      @click="handleSearch('foreignAgents')"
                      @click:append="
                      searchCustomers(search.foreignAgents, 'foreignAgents')
                    "
                      @keydown.enter="
                      searchCustomers(search.foreignAgents, 'foreignAgents')
                    "
                      @blur="resetSearchBox('shipperId')"
                      prepend-inner-icon="mdi-account-arrow-right"
                      append-icon="mdi-magnify"
                      hide-details
                      dense
                  />
                </template>
                <v-card class="dropdown-menu">
                  <v-data-table
                      v-model="currentUser.foreignAgents"
                      :headers="[{ text: '', value: 'customerName' }]"
                      :items="customersFilterList"
                      :items-per-page="4"
                      :page.sync="tablePage"
                      @item-selected="
                      (v) => selectItem(v, 'foreignAgents', 'customerId')
                    "
                      hide-default-header
                      class="custom-table"
                      checkbox-color="primary"
                      show-select
                      dense
                  >
                    <template v-slot:item.customerName="{ item }">
                      <td class="custom-table-td">
                        {{ item.customerName }}
                      </td>
                    </template>
                  </v-data-table>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import {userParametersService} from '@/services/userParameters.service.js'
import {usersManagerService} from '@/services/usersManager.service.js'
import {groupsService} from '@/services/groups.service.js'
import {customersService} from '@/services/customers.service.js'

export default {
  name: 'AdminSettingsCircle',
  props: [],
  components: {},
  data: () => ({
    languageItems: [],
    customersFilterList: [],

    // excludeInternal: true,
    search: {},
    showSearch: {
      customers: false,
      shippers: false,
      consignees: false,
      foreignAgents: false,
      customersCreate: false,
      shippersCreate: false,
      consigneesCreate: false,
      foreignAgentsCreate: false,
    },
    menuCustomer: false,
    menuConsignee: false,
    menuShipper: false,
    menuForeignAgent: false,
    menuCustomerCreate: false,
    menuConsigneeCreate: false,
    menuShipperCreate: false,
    menuForeignAgentCreate: false,
    tablePage: 1,

    panel: null,

    users: [],
    groups: [],
    customers: [],
    currentIndex: null,
    currentUser: {},
    userParams: {
      language: '',
    },
    loading: false,
    dialog: false,
    deleteDialog: false,
    userData: {
      id: null,
      username: '',
      password: '',
      repeatPassword: '',
      email: '',
      language: '',
      groups: [],
      customers: [],
      shippers: [],
      consignees: [],
      foreignAgents: [],
    },
    errorAlert: false,
    errorMessage: '',

  }),
  watch: {
    async panel(index) {
      this.userParams = {}
      this.showSearch['customers'] = false
      this.showSearch['customersCreate'] = false
      this.showSearch['shippers'] = false
      this.showSearch['shippersCreate'] = false
      this.showSearch['consignees'] = false
      this.showSearch['consigneesCreate'] = false
      this.showSearch['foreignAgents'] = false
      this.showSearch['foreignAgentsCreate'] = false
      if (index == null || index == undefined) {
        this.currentUser = {}
        return
      }
      this.currentUser = this._.cloneDeep(this.users[index])
      this.currentIndex = index
      setTimeout(() => {
        this.getUserParameters(this.currentUser.id)
      }, 300)
    },
    menuCustomer(v) {
      if (!v) this.resetSearchBox('customers')
    },
    menuConsignee(v) {
      if (!v) this.resetSearchBox('consignees')
    },
    menuForeignAgent(v) {
      if (!v) this.resetSearchBox('foreignAgents')
    },
    menuShipper(v) {
      if (!v) this.resetSearchBox('shippers')
    },
    menuCustomerCreate(v) {
      if (!v) this.resetSearchBox('customersCreate')
    },
    menuConsigneeCreate(v) {
      if (!v) this.resetSearchBox('consigneesCreate')
    },
    menuForeignAgentCreate(v) {
      if (!v) this.resetSearchBox('foreignAgentsCreate')
    },
    menuShipperCreate(v) {
      if (!v) this.resetSearchBox('shippersCreate')
    },
  },
  computed: {
    formatCustomers() {
      return this.formatSelectedCustomers(this.currentUser.customers);
    },
    formatShippers() {
      return this.formatSelectedCustomers(this.currentUser.shippers);
    },
    formatConsignees() {
      return this.formatSelectedCustomers(this.currentUser.consignees);
    },
    formatForeignAgents() {
      return this.formatSelectedCustomers(this.currentUser.foreignAgents);
    },
    formatSelectedCustomer() {
      return this.formatSelectedCustomers(this.userData.customers);
    },
    formatSelectedForeignAgent() {
      return this.formatSelectedCustomers(this.userData.foreignAgents);
    },
    formatSelectedConsignee() {
      return this.formatSelectedCustomers(this.userData.consignees);
    },
    formatSelectedShippers() {
      return this.formatSelectedCustomers(this.userData.shippers);
    },
  },
  methods: {
    selectLanguageItems() {
      return [
        {
          id: this.userParams.language.id,
          key: 'LANGUAGE',
          userId: this.currentUser.id,
          value: 'en',
          text: 'English',
          valueType: 'STRING',
        },
        {
          id: this.userParams.language.id,
          key: 'LANGUAGE',
          userId: this.currentUser.id,
          value: 'it',
          text: 'Italiano',
          valueType: 'STRING',
        },
      ]
    },
    init() {
      this.browseUsers()
      this.browseGroups()
    },
    browseUsers() {
      this.loading = true
      usersManagerService.browse(0, 100, {}, []).then((resp) => {
        this.users = resp.resultList //.sort()
        this.loading = false
      })
    },
    browseGroups() {
      groupsService.browse(0, 100, {}, []).then((resp) => {
        this.groups = resp.resultList
      })
    },
    searchCustomers(search, type) {
      if (!search || !search.length) return
      let browseRequest = {
        offset: 0,
        limit: 1000,
        filter: {
          customerName: search,
          isConsignee: false,
        },
        sortingList: [{column: "customerName", direction: "DESC"}],
      }
      customersService
          .browseInLike(browseRequest)
          .then((resp) => {
            this.customersFilterList = resp
            this.openMenu(type)
          })
    },
    getUserParameters(userId) {
      return userParametersService.getAllByUser(userId).then((resp) => {
        this.userParams = {}
        resp.forEach((param) => {
          this.userParams[param.key.toLowerCase()] = param
        })
        if (!this.userParams.language) this.createLanguageParameter(userId)
        else this.languageItems = this.selectLanguageItems()
      })
    },
    updateUser(user) {
      this.updateUserParameters(user)
      usersManagerService.updateUser(user.id, user).then((resp) => {
        this.currentUser = resp
        this.users[this.currentIndex] = resp
        this.init()
      })
    },
    updateUserParameters(user) {
      Object.keys(this.userParams).forEach((key) => {
        let param = this.userParams[key]
        if (param.id) {
          let userParam = {
            id: param.id,
            userId: user.id,
            key: param.key,
            valueType: param.valueType,
            value: param.value,
          }
          return userParametersService.updateUserParameter(param.id, userParam)
        }
      })
    },
    deleteUser(user) {
      usersManagerService.deleteUser(user.id).then((resp) => {
        this.deleteDialog = false;
        this.init();
      })
    },
    createLanguageParameter(userId) {
      let userParam = {
        userId: userId,
        key: 'LANGUAGE',
        valueType: 'STRING',
        value: null,
      }
      return userParametersService
          .createUserParameter(userParam)
          .then((resp) => {
            this.userParams.language = resp
            this.languageItems = this.selectLanguageItems()
          })
    },
    getIcon(type) {
      switch (type) {
        case 'admin':
          return 'mdi-shield-crown'
        case 'air':
          return 'mdi-airplane'
        case 'sea':
          return 'mdi-ferry'
      }
    },
    selectItem(input, field, id) {
      if (!this.currentUser[field].some((e) => e[id] == input.item[id])) {
        this.currentUser[field].push(input.item)
      } else {
        const current = (element) => element[id] == input.item[id]
        let index = this.currentUser[field].findIndex(current)
        this.currentUser[field].splice(index, 1)
      }
      this.showSearch[field] = false
    },
    handleSearch(type) {
      this.showSearch[type] = !this.showSearch[type]
    },
    resetSearchBox(type) {
      this.showSearch[type] = false
      this.search = {}
      this.tablePage = 1
    },
    formatSelectedCustomers(customersArray) {
      let res = ''
      if (customersArray) {
        customersArray.forEach((customer, index) => {
          res += customer.customerName
          if (index !== customersArray.length - 1) {
            res += ', '
          }
        })
      }
      return res;
    },
    openMenu(type) {
      switch (type) {
        case 'customers':
          this.menuCustomer = true
          break
        case 'shippers':
          this.menuShipper = true
          break
        case 'consignees':
          this.menuConsignee = true
          break
        case 'foreignAgents':
          this.menuForeignAgent = true
          break
        case 'customersCreate':
          this.menuCustomerCreate = true
          break
        case 'consigneesCreate':
          this.menuConsigneeCreate = true
          break
        case 'shippersCreate':
          this.menuShipperCreate = true
          break
        case 'foreignAgentsCreate':
          this.menuForeignAgentCreate = true
          break
      }
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.userData = {
        id: null,
        username: '',
        password: '',
        repeatPassword: '',
        email: '',
        language: '',
        groups: [],
        customers: [],
        shippers: [],
        consignees: [],
        foreignAgents: [],
      };
      this.errorMessage = '';
      this.errorAlert = false;
    },
    saveUser() {
      if (this.userData.password !== this.userData.repeatPassword) {
        this.errorMessage = 'Password and repeat password fields must match';
        this.errorAlert = true;
        return;
      }
      usersManagerService.createUser(this.userData)
          .then((response) => {
            this.closeDialog();
            this.init()
          }).catch(error => {
        console.error('Error saving user:', error);
        this.errorMessage = 'User could not be created';
        this.errorAlert = true;
      });
    },
    confirmDelete() {
      event.stopPropagation();
      this.deleteDialog = true;
    },
  },
  created() {
    this.init()
  },
}
</script>

<style scoped>
.v-expansion-panel::v-deep .v-expansion-panel-content__wrap {
  padding: 0;
}

.custom-table {
  width: 100%;
  height: 100%;
}

.custom-table::v-deep .v-data-footer {
  justify-content: right;
}

.custom-table::v-deep .v-data-footer__select {
  display: none;
}

.v-data-table::v-deep .v-input--selection-controls__ripple {
  height: 0 !important;
}

.v-data-table::v-deep tr {
  display: flex;
}

.v-data-table::v-deep td {
  display: flex;
  align-items: center !important;
  padding: 0.2rem !important;
}

.custom-table-td {
  display: flex;
  width: -webkit-fill-available;
  align-items: center !important;
  padding: 0.2rem !important;
}

.dropdown-menu {
  width: 100%;
  padding: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
