import { authHeader } from '@/helpers/auth-header'
import { commons } from './commons'

export const foreignAgentNonRevenueService = {
  browse,
  checkExisting,
  createForeignAgentNonRevenue,
  updateForeignAgentNonRevenue,
  deleteForeignAgentNonRevenue,
}

const resourceUrl = process.env.VUE_APP_GSCV_ENGINE_URL + `/foreign-agent-non-revenue`

function browse(browseRequest) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      offset: browseRequest.offset,
      limit: browseRequest.limit,
      filter: browseRequest.filter,
      sortingList: browseRequest.sortingList,
    }),
  }
  return fetch(resourceUrl + `/browse`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function createForeignAgentNonRevenue(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }
  return fetch(resourceUrl, requestOptions).then(commons.handleJsonResponse)
}

function updateForeignAgentNonRevenue(id, data) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }
  return fetch(resourceUrl + `/${id}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function deleteForeignAgentNonRevenue(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/${id}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function checkExisting(id, sapCode, destination) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  let url = `/check-existing/`
  if (id != null)
    url += id;

  return fetch(resourceUrl + `${url}?sapCode=${sapCode}&destination=${destination}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}