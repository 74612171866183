<template>
  <v-container class="stepper-wrapper" fluid>
    <v-row dense>
      <v-col>
        <v-container class="d-table pa-1 pr-2 pt-2" fill-height fluid>
          <v-expansion-panels
            v-model="panel"
            class="d-block fill-height overflow-y-auto pa-1"
            style="padding-top: 0.1rem !important;"
            multiple
          >
            <v-expansion-panel class="fit-content">
              <v-expansion-panel-header class="primary--text px-3 py-0">
                Document
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider class="my-0" />
                <div class="pa-2">
                  <v-row dense>
                    <v-col class="d-flex">
                      <v-col class="pa-0">
                        <ReadonlyField
                          :label="$t('label.type')"
                          :value="savedTransportDocumentType"
                          :icon="'mdi-file-document-outline'"
                          :textClass="'text-caption'"
                        />
                      </v-col>
                      <v-col class="pa-0">
                        <ReadonlyField
                          :label="$t('label.placeOfIssue')"
                          :value="savedInvoicePayableAt.locationName"
                          :icon="'mdi-map-marker'"
                          :textClass="'text-caption'"
                        />
                      </v-col>
                      <v-col class="pa-0">
                        <ReadonlyField
                          :label="$t('label.booking')"
                          :value="savedCarrierBookingReference"
                          :icon="'mdi-file-document-outline'"
                          :textClass="'text-caption'"
                        />
                      </v-col>
                      <v-col class="pa-0">
                        <ReadonlyField
                          :label="$t('label.contract')"
                          :value="savedAgreementReference"
                          :icon="'mdi-file-document-outline'"
                          :textClass="'text-caption'"
                        />
                      </v-col>
                      <v-divider vertical />
                      <v-col class="py-0">
                        <v-row dense>
                          <v-col style="max-width: 5rem;">
                            <span class="text-caption primary--text">
                              Original
                            </span>
                          </v-col>
                          <v-col cols="auto">
                            <span class="text-caption primary--text">
                              Freighted
                            </span>
                            <span class="text-caption mx-2">
                              {{ savedNumberOfOriginals.freighted }}
                            </span>
                          </v-col>
                          <v-col cols="auto">
                            <span class="text-caption primary--text">
                              Unfreighted
                            </span>
                            <span class="text-caption mx-2">
                              {{ savedNumberOfOriginals.unfreighted }}
                            </span>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col style="max-width: 5rem;">
                            <span class="text-caption primary--text">
                              Copies
                            </span>
                          </v-col>
                          <v-col cols="auto">
                            <span class="text-caption primary--text">
                              Freighted
                            </span>
                            <span class="text-caption mx-2">
                              {{ savedNumberOfCopies.freighted }}
                            </span>
                          </v-col>
                          <v-col cols="auto">
                            <span class="text-caption primary--text">
                              Unfreighted
                            </span>
                            <span class="text-caption mx-2">
                              {{ savedNumberOfCopies.unfreighted }}
                            </span>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-col>
                  </v-row>
                  <!-- <v-row dense>
                    <v-col class="d-flex" cols="6">
                      <v-col class="pa-0 pl-8">
                        <ReadonlyField
                          :label="'Original Freighted'"
                          :value="savedNumberOfOriginals.freighted"
                          :textClass="'text-caption'"
                        />
                      </v-col>
                      <v-col class="pa-0 pl-8">
                        <ReadonlyField
                          :label="'Original Unfreighted'"
                          :value="savedNumberOfOriginals.unfreighted"
                          :textClass="'text-caption'"
                        />
                      </v-col>
                      <v-col class="pa-0 pl-8">
                        <ReadonlyField
                          :label="'Copies Freighted'"
                          :value="savedNumberOfCopies.freighted"
                          :textClass="'text-caption'"
                        />
                      </v-col>
                      <v-col class="pa-0 pl-8">
                        <ReadonlyField
                          :label="'Copies Unfreighted'"
                          :value="savedNumberOfCopies.unfreighted"
                          :textClass="'text-caption'"
                        />
                      </v-col>
                    </v-col>
                  </v-row> -->
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="fit-content">
              <v-expansion-panel-header class="primary--text px-3 py-0">
                Voyage
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider class="my-0" />
                <div class="pa-2">
                  <v-row dense>
                    <v-col class="d-flex">
                      <v-col class="pa-0" v-if="bookingReqPolHaulageLocation">
                        <ReadonlyField
                          :label="$t('label.placeOfReceipt')"
                          :value="formatAddress(bookingReqPolHaulageLocation)"
                          :icon="'mdi-truck'"
                          :textClass="'text-caption'"
                        />
                      </v-col>
                      <v-col class="pa-0">
                        <ReadonlyField
                          :label="$t('label.pol')"
                          :value="getShortLocationLabel(bookingReqPol)"
                          :icon="'mdi-map-marker'"
                          :textClass="'text-caption'"
                        />
                      </v-col>
                    </v-col>
                    <v-col class="d-flex">
                      <v-col class="pa-0">
                        <ReadonlyField
                          :label="$t('label.pod')"
                          :value="getShortLocationLabel(bookingReqPod)"
                          :icon="'mdi-map-marker'"
                          :textClass="'text-caption'"
                        />
                      </v-col>
                      <v-col class="pa-0" v-if="bookingReqPodHaulageLocation">
                        <ReadonlyField
                          :label="$t('label.placeOfDelivery')"
                          :value="formatAddress(bookingReqPodHaulageLocation)"
                          :icon="'mdi-truck'"
                          :textClass="'text-caption'"
                        />
                      </v-col>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col class="d-flex">
                      <v-col class="pa-0">
                        <ReadonlyField
                          :label="$t('label.departure')"
                          :value="formatDate(departure)"
                          :icon="'mdi-calendar'"
                          :textClass="'text-caption'"
                        />
                      </v-col>
                      <v-col class="pa-0">
                        <ReadonlyField
                          :label="$t('label.arrival')"
                          :value="formatDate(arrival)"
                          :icon="'mdi-calendar'"
                          :textClass="'text-caption'"
                        />
                      </v-col>
                    </v-col>
                    <v-col class="d-flex">
                      <v-col class="pa-0">
                        <ReadonlyField
                          :label="$t('label.vessel')"
                          :value="bookingReqMainVessel"
                          :icon="'mdi-ferry'"
                          :textClass="'text-caption'"
                        />
                      </v-col>
                      <v-col class="pa-0">
                        <ReadonlyField
                          :label="$t('label.voyage')"
                          :value="bookingReqVoyageNbr"
                          :icon="'mdi-ticket-outline'"
                          :textClass="'text-caption'"
                        />
                      </v-col>
                    </v-col>
                  </v-row>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="fit-content">
              <v-expansion-panel-header class="primary--text px-3 py-0">
                Cargo
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider class="my-0" />
                <div class="pa-2">
                  <v-row dense>
                    <v-col class="px-2 py-0">
                      <v-data-table
                        :headers="equipmentsHeaders"
                        :items="savedShippingInstructionEquipments"
                        :items-per-page="1000"
                        item-class="text-caption"
                        hide-default-footer
                        class="pa-0"
                        dense
                      >
                        <!-- <template v-slot:item.containerCode="{ item }">
                          <div class="d-flex">
                            <v-icon class="mr-1" small>
                              mdi-train-car-container
                            </v-icon>
                            <span>
                              {{ item.containerCode }}
                            </span>
                          </div>
                        </template> -->
                        <template v-slot:item="{ item, index }">
                          <tr>
                            <td>
                              <v-icon class="mr-1" small>
                                mdi-train-car-container
                              </v-icon>
                              <span class="text-caption">
                                {{ item.containerCode }}
                              </span>
                            </td>
                            <td>
                              <span class="text-caption">
                                {{ item.containerIsoType }}
                              </span>
                            </td>
                            <td>
                              <span class="text-caption">
                                {{ item.shipperSeal }}
                              </span>
                            </td>
                            <td>
                              <span class="text-caption">
                                {{ item.carrierSeal }}
                              </span>
                            </td>
                            <td>
                              <span class="text-caption">
                                {{ item.packagesNbr }}
                              </span>
                            </td>
                            <td>
                              <span class="text-caption">
                                {{ item.packagesType }}
                              </span>
                            </td>
                            <td>
                              <span class="text-caption">
                                {{ item.commodityDesc }}
                              </span>
                            </td>
                            <td>
                              <span class="text-caption">
                                {{ item.shipperOwned }}
                              </span>
                            </td>
                            <td>
                              <span class="text-caption">
                                {{ item.weight }}
                              </span>
                            </td>
                            <td>
                              <span class="text-caption">
                                {{ item.volume }}
                              </span>
                            </td>
                          </tr>
                          <tr v-if="index == savedShippingInstructionEquipments.length - 1">
                            <td>
                              <span class="text-caption font-weight-medium">
                                {{ $t('label.total') }}
                              </span>
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                              <span class="text-caption font-weight-medium">
                                <!-- {{ totEquipmentsWeight }} -->
                              </span>
                            </td>
                            <!-- <td v-if="totEquipmentsVolume">
                              <span class="text-caption font-weight-medium">
                                {{ totEquipmentsVolume }}
                              </span>
                            </td> -->
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="fit-content">
              <v-expansion-panel-header class="primary--text px-3 py-0">
                Parties
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider class="my-0" />
                <div class="pa-2">
                  <v-row dense>
                    <v-col
                      cols="3"
                      class="pa-1"
                      v-for="(item, i) in parties"
                      :key="i"
                    >
                      <v-card>
                        <v-card-text
                          class="pa-0 text-caption font-weight-medium"
                        >
                          <v-container>
                            <v-row dense>
                              <v-col class="primary--text pa-0">
                                {{ getPartyFunction(item.partyFunction) }}
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <v-col class="black--text pa-0">
                                {{ item.party.partyName }}
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <v-col class="pa-0">
                                <span class="mr-2">
                                  {{ item.party.street }}
                                </span>
                                <span>{{ item.party.streetNumber }}</span>
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <v-col class="pa-0">
                                <span class="mr-2">{{ item.party.city }}</span>
                                <span>{{ item.party.country }}</span>
                              </v-col>
                            </v-row>
                            <v-row dense v-if="item.contactDetails.email">
                              <v-col class="pa-0">
                                <v-divider class="mx-0 my-1"/>
                                <span>{{ item.contactDetails.name }}</span>
                                <br />
                                <span>{{ item.contactDetails.email }}</span>
                                <br />
                                <span>{{ item.contactDetails.phone }}</span>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="fit-content" v-if="payers">
              <v-expansion-panel-header class="primary--text px-3 py-0">
                Payers
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider class="my-0" />
                <div class="pa-2">
                  <v-row dense>
                    <v-col></v-col>
                  </v-row>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
      </v-col>
    </v-row>
    <v-row class="flex-grow-0" dense>
      <v-col class="d-flex pa-3">
        <v-btn small icon color="primary" @click="exitRequest">
          <v-icon size="30">mdi-arrow-left-circle</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn color="primary" small @click="$emit('step', false)">
          <v-icon>mdi-step-backward</v-icon>
          <span>{{ $t('label.back') }}</span>
        </v-btn>
        <v-btn
          small
          color="primary"
          class="ml-2"
          @click="submitShippingInstruction"
        >
          <span>{{ $t('label.confirm') }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ShippingInstruction from '@/mixins/ShippingInstruction.js'
import { electronicFlowService } from '@/services/electronicFlow.service.js'
import ReadonlyField from '@/components/elements/ReadonlyField.vue'

export default {
  name: 'ShippingInstructionReview',
  props: [],
  mixins: [ShippingInstruction],
  components: { ReadonlyField },
  data() {
    return {
      panel: [0, 1, 2, 3],
      equipmentsHeaders: [
        {
          text: this.$t('label.code'),
          value: 'containerCode',
          class: 'primary--text px-3 py-0 font-weight-normal',
          width: '7%',
        },
        {
          text: this.$t('label.isoCode'),
          value: 'containerIsoType',
          class: 'primary--text px-3 py-0 font-weight-normal',
          width: '6%',
        },
        {
          text: this.$t('label.seal'),
          value: 'shipperSeal',
          class: 'primary--text px-3 py-0 font-weight-normal',
          width: '7%',
        },
        {
          text: this.$t('label.seal'),
          value: 'carrierSeal',
          class: 'primary--text px-3 py-0 font-weight-normal',
          width: '7%',
        },
        {
          text: this.$t('label.qty'),
          value: 'packagesNbr',
          class: 'primary--text px-3 py-0 font-weight-normal',
          width: '4%',
        },
        {
          text: this.$t('label.type'),
          value: 'packagesType',
          class: 'primary--text px-3 py-0 font-weight-normal',
          width: '10%',
        },
        {
          text: this.$t('label.commodity'),
          value: 'commodityDesc',
          class: 'primary--text px-3 py-0 font-weight-normal',
          width: '30%',
        },
        {
          text: this.$t('label.shipperOwned'),
          value: 'shipperOwned',
          class: 'primary--text px-3 py-0 font-weight-normal',
          width: '5%',
        },
        {
          text: this.$t('label.weight'),
          value: 'weight',
          class: 'primary--text px-3 py-0 font-weight-normal',
          width: '6%',
        },
        // { text: this.$t('label.weightUOM'), value: 'weightUOM', class: 'select', width: '5.5%' },
        {
          text: this.$t('label.volume'),
          value: 'volume',
          class: 'primary--text px-3 py-0 font-weight-normal',
          width: '5.5%',
        },
        // { text: this.$t('label.volumeUOM'), value: 'volumeUOM', class: 'select', width: '5.5%' },
      ],
    }
  },
  computed: {
    // totEquipmentsWeight() {
    //   if (!this.savedShippingInstructionEquipments) return
    //   let totWeight = 0
    //   this.savedShippingInstructionEquipments.forEach((equipment) => {
    //     totWeight += equipment.requestedEquipments.equipmentWeight
    //   })
    //   return totWeight
    // },
    // totEquipmentsVolume() {
    //   if (!this.savedShippingInstructionEquipments) return
    //   let totVolume = 0
    //   this.savedShippingInstructionEquipments.forEach((equipment) => {
    //     if (equipment.requestedEquipments.equipmentVolume)
    //       totVolume += equipment.requestedEquipments.equipmentVolume
    //   })
    //   return totVolume
    // },
    parties() {
      return this.savedShippingInstructionRequest.documentParties
    },
    departure() {
      return this.bookingReqVoyage ?  this.bookingReqVoyage.departureDateTime : null
    },
    arrival() {
      return this.bookingReqVoyage ? this.bookingReqVoyage.arrivalDateTime : null
    },
    payers(){
      return null
    }
  },
  methods: {
    submitShippingInstruction() {
      return electronicFlowService
        .submitShippingInstruction(
          this.electronicFlow.id,
          this.shippingInstruction,
        )
        .then(() => {
          this.setSnackbarMessage({
            text: this.$t('message.bookingSubmitted'),
            color: 'success',
          })
          this.findElectronicFlowById(this.electronicFlow.id)
        })
        .catch((error) => {
          this.setSnackbarMessage({ text: error, color: 'error' })
        })
    },
  },
  mounted() {},
}
</script>

<style scoped>
.stepper-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
}
.v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 8px;
}
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 48px !important;
}
.v-expansion-panel::v-deep .v-expansion-panel-content__wrap {
  padding: 0;
}
.v-data-table::v-deep th {
  white-space: nowrap;
  padding: 0 !important;
  padding-right: 0 !important;
  height: 1.5rem !important;
}
.v-data-table::v-deep td {
  padding: 0 !important;
}
/*.row {
  border: solid 1px red;
}
.col {
  border: solid 1px orange;
}*/
</style>
