import Vue from "vue";
import Router from "vue-router";

import LandingPage from './components/views/LandingPage'
import NewUserLanding from './components/views/NewUserLanding'
import MainPage from './components/views/MainPage'
import AdminSettings from './components/views/AdminSettings'
import AdminSettingsCircle from './components/views/AdminSettingsCircle'
import DashboardPage from './components/views/DashboardPage'
import CustomsDashboard from './components/views/CustomsDashboard'
import CustomsOperative from './components/views/CustomsOperative'
import PraticaPage from '@/components/views/PraticaPage'
import BookingTrial from '@/components/views/BookingTrial'
import TrackingPublicShare from '@/components/views/TrackingPublicShare'
import InvoiceShipmentLink from './components/views/InvoiceShipmentLink'
import ShipmentLinkDashboard from './components/views/ShipmentLinkDashboard'
import ImportPage from './components/views/ImportPage'
import LandingPageCircle from './components/views/LandingPageCircle'

Vue.use(Router);

export const router = new Router({
  mode: "history",
  routes: [
    { path: "/", name: "landing", component: process.env.VUE_APP_USER_MANAGEMENT_PROVIDER === 'Azure' ? LandingPage : LandingPageCircle },
    {
      path: "/tracking/:uuid",
      name: "trackingShare",
      component: TrackingPublicShare,
      props: true,
    },
    {
      path: "/app",
      name: "main",
      component: MainPage,
      children: [
        { path: '', name: 'dashboard', component: DashboardPage, props: true },
        { path: 'settings', name: 'settings', component: process.env.VUE_APP_USER_MANAGEMENT_PROVIDER === 'Azure' ? AdminSettings : AdminSettingsCircle },
        { path: 'pratica/:id', name: 'pratica', component: PraticaPage, props: true },
        { path: 'booking/:id', name: 'booking', component: BookingTrial, props: true },
        { path: 'newuser', name: 'newUserLanding', component: NewUserLanding },
        { path: 'customs/dashboard', name: 'customsDashboard', component: CustomsDashboard },
        { path: 'customs/import', name: 'import', component: ImportPage },
        { path: 'customs/operative', name: 'customsOperative', component: CustomsOperative },
        {path: "invoice-shipment-link", name: "invoiceShipmentsLink", component: ShipmentLinkDashboard},
      ],
    },
    { path: "*", redirect: "/" },
  ],
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/"];
  const authRequired = !publicPages.includes(to.path) && !to.path.includes("/tracking");
  const loggedIn = process.env.VUE_APP_USER_MANAGEMENT_PROVIDER === 'Azure' ? router.app.$msal.isAuthenticated() : localStorage.getItem('authToken');
  if (authRequired && !loggedIn) {
    return next("/");
  }
  next();
});
