import { authHeader } from '@/helpers/auth-header'
import { commons } from './commons'

export const customersService = {
  browse,
  getAllCustomers,
  getCustomer,
  browseInLike,
  updateCustomer,
}

const resourceUrl = process.env.VUE_APP_GSCV_ENGINE_URL + `/customer`

function browse(browseRequest) {
  if (!browseRequest.sortingList.length) {
    browseRequest.sortingList = []
    browseRequest.sortingList.push({
      column: 'id',
      direction: 'DESC',
    })
  }
  const requestOptions = {
    method: 'POST',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify({
      offset: browseRequest.offset,
      limit: browseRequest.limit,
      filter: browseRequest.filter,
      sortingList: browseRequest.sortingList,
    }),
  }
  return fetch(resourceUrl + `/browse`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function getAllCustomers() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl, requestOptions).then(commons.handleJsonResponse)
}

function getCustomer(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/${id}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function browseInLike(browseRequest) {
  const requestOptions = {
    method: 'POST',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify({
      offset: browseRequest.offset,
      limit: browseRequest.limit,
      filter: browseRequest.filter,
      sortingList: browseRequest.sortingList,
    }),
  }
  return fetch(resourceUrl + `/browse-like`, requestOptions).then(commons.handleJsonResponse)
}

function updateCustomer(id, data) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }
  return fetch(resourceUrl + `/${id}`, requestOptions).then(commons.handleJsonResponse)
}