var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "stepper-wrapper", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "ma-0 fill-height" },
        [
          _c(
            "v-col",
            { staticClass: "pa-0" },
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.electronicFlows,
                  "items-per-page": 1000,
                  "sort-by": _vm.sortBy,
                  "sort-desc": _vm.sortDesc,
                  "item-class": _vm.isSelected,
                  "hide-default-footer": "",
                  dense: "",
                  light: "",
                },
                on: {
                  "update:sortBy": function ($event) {
                    _vm.sortBy = $event
                  },
                  "update:sort-by": function ($event) {
                    _vm.sortBy = $event
                  },
                  "update:sortDesc": function ($event) {
                    _vm.sortDesc = $event
                  },
                  "update:sort-desc": function ($event) {
                    _vm.sortDesc = $event
                  },
                  "click:row": (electronicFlow) =>
                    _vm.selectElectronicFlow(electronicFlow),
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.creationDate",
                    fn: function ({ item }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.formatDateDay(item.creationDate))),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "item.status",
                    fn: function ({ item }) {
                      return [
                        _c("span", { staticClass: "text-caption" }, [
                          _vm._v(_vm._s(_vm.formatStatus(item.status))),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "item.actions",
                    fn: function ({ item }) {
                      return [
                        item.status == _vm.confirmedStatus
                          ? _c(
                              "div",
                              { staticClass: "d-flex justify-end pr-1" },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: { color: "grey" },
                                    on: {
                                      click: [
                                        function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                        },
                                        function ($event) {
                                          return _vm.deleteBookingRequest(item)
                                        },
                                      ],
                                    },
                                  },
                                  [_vm._v(" mdi-delete ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "item.errors",
                    fn: function ({ item }) {
                      return [
                        _vm.getRequestErrors(item)
                          ? _c(
                              "v-expansion-panels",
                              {
                                staticClass: "d-flex align-center",
                                attrs: { flat: "" },
                              },
                              [
                                _c(
                                  "v-expansion-panel",
                                  {
                                    staticClass:
                                      "d-flex flex-column align-center",
                                    staticStyle: {
                                      height: "100%",
                                      "border-radius": "0",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-expansion-panel-header",
                                      {
                                        staticClass: "pa-0",
                                        staticStyle: {
                                          "min-height": "0 !important",
                                          height: "100%",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "header text-caption primary--text ml-2",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("label.requestErrors")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-expansion-panel-content",
                                      {
                                        staticClass: "pa-0 align-self-start",
                                        staticStyle: { width: "100%" },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                          },
                                        },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "text-caption",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.getRequestErrors(item).message
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                            },
                                          },
                                        }),
                                        _c(
                                          "div",
                                          {
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                              },
                                            },
                                          },
                                          _vm._l(
                                            _vm.getRequestErrors(item).details,
                                            function (error, i) {
                                              return _c(
                                                "v-row",
                                                {
                                                  key: i,
                                                  attrs: { dense: "" },
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { staticClass: "pb-0" },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-caption",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(error) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "flex-grow-0 ma-0" },
        [
          _c(
            "v-col",
            { staticClass: "d-flex justify-end pa-0" },
            [
              _c("ElectronicFlowSelectionMenu", {
                on: { select: _vm.newElectronicFlow },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }