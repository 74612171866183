var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expand-transition",
    [
      _c(
        "v-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.open,
              expression: "open",
            },
          ],
          staticClass: "rounded-0",
          attrs: { outlined: "" },
        },
        [
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _c(
                "v-simple-table",
                { staticClass: "pa-3", attrs: { dense: "" } },
                [
                  [
                    _c("thead", { staticClass: "t-head" }, [
                      _c("tr", [
                        _c("th", { staticClass: "text-left" }, [
                          _vm._v(_vm._s(_vm.$t("label.position"))),
                        ]),
                        _c("th", { staticClass: "text-left" }, [
                          _vm._v(_vm._s(_vm.$t("label.itemDescription"))),
                        ]),
                        _c("th", { staticClass: "text-left" }, [
                          _vm._v(_vm._s(_vm.$t("label.qty"))),
                        ]),
                        _c("th", { staticClass: "text-left" }, [
                          _vm._v(
                            _vm._s(_vm.$t("label.grossWeight")) +
                              " / " +
                              _vm._s(_vm.$t("label.netWeight"))
                          ),
                        ]),
                        _c("th", { staticClass: "text-left" }, [
                          _vm._v(_vm._s(_vm.$t("label.lineAmount"))),
                        ]),
                        _c("th", { staticClass: "text-left" }, [
                          _vm._v(_vm._s(_vm.$t("label.tariffCode"))),
                        ]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.details, function (detail, i) {
                        return _c("tr", { key: i }, [
                          _c("td", [_vm._v(_vm._s(detail.lineNbr))]),
                          _c("td", [_vm._v(_vm._s(detail.itemDescription))]),
                          _c("td", [_vm._v(_vm._s(detail.qty))]),
                          _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(detail.grossWeight) +
                                " / " +
                                _vm._s(detail.netWeight) +
                                " " +
                                _vm._s(detail.unitOfMeasureWeight) +
                                "S "
                            ),
                          ]),
                          _c("td", [_vm._v(_vm._s(detail.lineAmount))]),
                          _c("td", [_vm._v(_vm._s(detail.tariffCode))]),
                        ])
                      }),
                      0
                    ),
                  ],
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }