<template>
  <v-app>
    <router-view class="pa-0" />
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {},
  data: () => ({}),
  methods: {
    disableDraggableOnWindow() {
      window.addEventListener(
        'dragover',
        function (event) {
          event.stopPropagation()
          event.preventDefault()
        },
        false,
      )
      window.addEventListener(
        'drop',
        function (event) {
          event.stopPropagation()
          event.preventDefault()
        },
        false,
      )
    },
  },
  created() {
    this.disableDraggableOnWindow()
  },
}
</script>

<style lang="scss">
@import '@/styles/App.scss';
</style>
