var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "stepper-wrapper", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "ma-0 fill-height" },
        [
          _c(
            "v-col",
            { staticClass: "d-table fill-height pa-2 pb-0" },
            [
              _c(
                "v-row",
                {
                  staticClass: "d-table-header-group pb-0 pt-1",
                  attrs: { dense: "" },
                },
                [
                  _c(
                    "v-col",
                    { staticStyle: { "max-width": "20rem" } },
                    [
                      _c("v-file-input", {
                        staticClass: "xs-input ma-0 pa-0",
                        attrs: {
                          accept:
                            ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                          clearable: false,
                          "hide-details": "",
                          outlined: "",
                        },
                        on: {
                          change: (file) => {
                            _vm.resetEditedItem()
                            _vm.uploadExcelToJson(file)
                          },
                          click: function ($event) {
                            _vm.importedExcel = null
                          },
                        },
                        model: {
                          value: _vm.importedExcel,
                          callback: function ($$v) {
                            _vm.importedExcel = $$v
                          },
                          expression: "importedExcel",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "px-5" },
                    [
                      _c(
                        "v-form",
                        { ref: "commodity", attrs: { width: "100%" } },
                        [
                          _c("v-autocomplete", {
                            staticClass: "xs-input",
                            attrs: {
                              label: _vm.$t("label.commodity"),
                              "item-text": "commodityName",
                              filter: _vm.filterCommodities,
                              items: _vm.commodities,
                              "menu-props": _vm.menuProps,
                              rules: _vm.required,
                              "validate-on-blur": "",
                              "return-object": "",
                              outlined: "",
                              attach: "",
                              dense: "",
                            },
                            on: {
                              "update:search-input":
                                _vm.getBookingRequestCommodities,
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function (data) {
                                  return [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(
                                            " " +
                                              _vm._s(data.item.commodityName) +
                                              " "
                                          ),
                                        ]),
                                        _c("v-list-item-subtitle", [
                                          _vm._v(
                                            " " +
                                              _vm._s(data.item.commodityCode) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.commoditySelected,
                              callback: function ($$v) {
                                _vm.commoditySelected = $$v
                              },
                              expression: "commoditySelected",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "d-flex" },
                    [
                      _c("v-spacer"),
                      _vm.selectedRows.length
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "success ml-2",
                              attrs: { "x-small": "" },
                              on: { click: _vm.confirmCopyCommodityDesc },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("label.copy")) + " ")]
                          )
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-2 primary",
                          attrs: { "x-small": "" },
                          on: { click: _vm.getTrackingEquipments },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("label.loadEquipments")) + " "
                          ),
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-2 primary",
                          attrs: { "x-small": "" },
                          on: {
                            click: function ($event) {
                              return _vm.downloadXlsxFromJson(
                                _vm.loadedEquipments
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("label.downloadExcel")) + " "
                          ),
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-2 primary",
                          attrs: {
                            download: "",
                            href: _vm.downloadPath,
                            "x-small": "",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("label.downloadTemplate")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "d-table-row fill-height",
                  attrs: { dense: "" },
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "fill-height overflow-y-auto" },
                    [
                      _vm.loading
                        ? _c("v-progress-linear", {
                            attrs: { indeterminate: "" },
                          })
                        : _c("v-data-table", {
                            key: _vm.updateTable,
                            staticClass: "pa-0",
                            attrs: {
                              headers: _vm.equipmentsHeaders,
                              items: _vm.loadedEquipments,
                              "items-per-page": 1000,
                              "hide-default-footer": "",
                              dense: "",
                            },
                            on: {
                              "update:sort-by": _vm.handleClickOutsideTable,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "header.actions",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "actions" },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: { color: "primary" },
                                            on: { click: _vm.addNewEquipment },
                                          },
                                          [_vm._v("mdi-plus-circle")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "header.copy",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex justify-center" },
                                      [
                                        _vm.copyItemIndex != null
                                          ? _c("v-simple-checkbox", {
                                              staticStyle: {
                                                "max-width": "fit-content",
                                              },
                                              attrs: {
                                                color: "primary",
                                                ripple: false,
                                              },
                                              on: { input: _vm.selectAll },
                                              model: {
                                                value: _vm.allSelected,
                                                callback: function ($$v) {
                                                  _vm.allSelected = $$v
                                                },
                                                expression: "allSelected",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "item",
                                fn: function ({ item, index, headers }) {
                                  return [
                                    _c(
                                      "tr",
                                      { class: _vm.isEditable(index) },
                                      _vm._l(headers, function (header, i) {
                                        return _c(
                                          "td",
                                          { key: i, staticClass: "px-1" },
                                          [
                                            header.class == "text"
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass:
                                                        "ma-0 pa-0 text-caption",
                                                      attrs: {
                                                        disabled:
                                                          index !==
                                                          _vm.editedItemIndex,
                                                        clearable:
                                                          index ==
                                                          _vm.editedItemIndex,
                                                        "hide-details": "",
                                                        "single-line": "",
                                                        dense: "",
                                                        solo: "",
                                                        flat: "",
                                                      },
                                                      model: {
                                                        value:
                                                          item[header.value],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            header.value,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item[header.value]",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            header.class == "long-text"
                                              ? _c(
                                                  "div",
                                                  { staticClass: "d-flex" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-menu",
                                                          {
                                                            attrs: {
                                                              "offset-y": "",
                                                              "close-on-click":
                                                                "",
                                                              "close-on-content-click": false,
                                                              "open-on-click":
                                                                _vm.isEditable(
                                                                  index
                                                                ) &&
                                                                item[
                                                                  header.value
                                                                ] &&
                                                                item[
                                                                  header.value
                                                                ].length > 10
                                                                  ? true
                                                                  : false,
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-text-field",
                                                                        _vm._g(
                                                                          {
                                                                            staticClass:
                                                                              "ma-0 pa-0 text-caption",
                                                                            attrs:
                                                                              {
                                                                                disabled:
                                                                                  index !==
                                                                                  _vm.editedItemIndex,
                                                                                clearable:
                                                                                  index ==
                                                                                  _vm.editedItemIndex,
                                                                                "hide-details":
                                                                                  "",
                                                                                "single-line":
                                                                                  "",
                                                                                dense:
                                                                                  "",
                                                                                solo: "",
                                                                                flat: "",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  item[
                                                                                    header
                                                                                      .value
                                                                                  ],
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      item,
                                                                                      header.value,
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "item[header.value]",
                                                                              },
                                                                          },
                                                                          on
                                                                        )
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c(
                                                              "v-card",
                                                              {
                                                                staticClass:
                                                                  "dropdown-menu",
                                                              },
                                                              [
                                                                _c(
                                                                  "v-textarea",
                                                                  {
                                                                    staticClass:
                                                                      "text-caption",
                                                                    attrs: {
                                                                      maxlength:
                                                                        "100",
                                                                      "hide-details":
                                                                        "",
                                                                      "auto-grow":
                                                                        "",
                                                                      "x-small":
                                                                        "",
                                                                      solo: "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item[
                                                                          header
                                                                            .value
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            header.value,
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "item[header.value]",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            header.class == "actions"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-center",
                                                  },
                                                  [
                                                    !_vm.isEditable(index)
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex",
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    _vm.isEditable(
                                                                      index
                                                                    )
                                                                      ? "primary"
                                                                      : "grey",
                                                                  small: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.editEquipment(
                                                                        item,
                                                                        index
                                                                      )
                                                                      _vm.abortCopyCommodityDesc()
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " mdi-pencil "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    _vm.isEditable(
                                                                      index
                                                                    )
                                                                      ? "primary"
                                                                      : "grey",
                                                                  small: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.prepareDeleteEquipment(
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " mdi-delete "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex",
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "success",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.editEquipment(
                                                                        item,
                                                                        index
                                                                      )
                                                                      _vm.abortCopyCommodityDesc()
                                                                      _vm.updateUOMs(
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " mdi-check "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "error",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.restoreOldValues(
                                                                        item,
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " mdi-close "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            header.class == "copy"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-center",
                                                  },
                                                  [
                                                    _vm.copyItemIndex == null
                                                      ? _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  small: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.prepareCopyCommodityDesc(
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " mdi-content-copy "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "div",
                                                          {
                                                            staticClass: "pa-0",
                                                          },
                                                          [
                                                            _vm.copyItemIndex !=
                                                            _vm.loadedEquipments.indexOf(
                                                              item
                                                            )
                                                              ? _c(
                                                                  "v-simple-checkbox",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        _vm.selectedRows.includes(
                                                                          index
                                                                        ),
                                                                      color:
                                                                        "primary",
                                                                      ripple: false,
                                                                    },
                                                                    on: {
                                                                      input:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.allSelected = false
                                                                          _vm.selectRow(
                                                                            item
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                )
                                                              : _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "error",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            _vm.abortCopyCommodityDesc,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-close"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                          ],
                                                          1
                                                        ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            header.class == "select"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-center",
                                                  },
                                                  [
                                                    _c("v-select", {
                                                      staticClass:
                                                        "ma-0 pa-0 text-caption",
                                                      attrs: {
                                                        items:
                                                          _vm.getSelectItems(
                                                            header.value
                                                          ),
                                                        disabled:
                                                          index !==
                                                          _vm.editedItemIndex,
                                                        clearable:
                                                          index ==
                                                          _vm.editedItemIndex,
                                                        "hide-details": "",
                                                        "single-line": "",
                                                        dense: "",
                                                        solo: "",
                                                        flat: "",
                                                      },
                                                      model: {
                                                        value:
                                                          item[header.value],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            header.value,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item[header.value]",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("ConfirmDialog", {
                attrs: {
                  open: _vm.confirmDelete,
                  message: _vm.confirmDeleteMsg,
                },
                on: {
                  "open-dialog": _vm.openConfirmDeleteDialog,
                  cancel: function ($event) {
                    _vm.confirmDelete = false
                  },
                  confirm: function ($event) {
                    return _vm.deleteEquipment(_vm.deleteItemIndex)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "flex-grow-0 ma-0" },
        [
          _c(
            "v-col",
            { staticClass: "d-flex pa-2" },
            [
              _c(
                "v-btn",
                {
                  attrs: { small: "", icon: "", color: "primary" },
                  on: { click: _vm.exitRequest },
                },
                [
                  _c("v-icon", { attrs: { size: "30" } }, [
                    _vm._v("mdi-arrow-left-circle"),
                  ]),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  attrs: { small: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("step", false)
                    },
                  },
                },
                [
                  _c("v-icon", [_vm._v("mdi-step-backward")]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("label.back")))]),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { small: "", color: "primary", disabled: _vm.loading },
                  on: { click: _vm.submitEquipments },
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.$t("label.next")))]),
                  _c("v-icon", [_vm._v("mdi-play")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }