var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "d-flex justify-center",
      attrs: { "fill-height": "", fluid: "" },
    },
    [
      _c(
        "v-card",
        { staticClass: "d-flex align-center pa-5" },
        [
          _c(
            "v-icon",
            { staticClass: "mr-3", attrs: { size: "50", color: "primary" } },
            [_vm._v(" mdi-information ")]
          ),
          _c("h3", { staticClass: "ma-0 primary--text" }, [
            _vm._v(_vm._s(_vm.$t("label.contactAdminNewUser"))),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }