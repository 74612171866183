<template>
  <div v-click-outside="onClickOutside">
    <v-btn v-if="!selected" @click.stop="selected = true" class="px-2" icon>
      <v-icon :color="color">mdi-delete</v-icon>
    </v-btn>
    <div v-if="selected">
      <v-btn @click.stop="submit" color="success" class="px-2 mr-2" icon>
        <v-icon>mdi-check</v-icon>
      </v-btn>
      <v-btn
        @click.stop="selected = false"
        color="error"
        class="px-2"
        icon
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonConfirm',
  emits: ['enabled', 'submit'],
  props: {
    color: {
      type: String,
      default: 'grey',
    },
  },
  data() {
    return {
      selected: false,
    }
  },
  watch: {
    selected(isSelected) {
      this.$emit('enabled', isSelected)
    },
  },
  methods: {
    submit() {
      this.selected = false
      this.$emit('submit')
    },
    onClickOutside() {
      this.selected = false
    },
  },
}
</script>
