import { commons } from "./commons";

export const shipmentLinkService = {
  getShipmentDocumentNbr,
  saveRelationship,
  deleteRelationship,
  getInvoicesForShipment,
  getAssociatedInvoices,
};

const resourceUrl =
  process.env.VUE_APP_GSCV_CUSTOMS_ENGINE_URL + "/shipment-link";
const authorizationHeader =
  process.env.VUE_APP_GSCV_CUSTOMS_ENGINE_BASIC_AUTH_HEADER;

function getShipmentDocumentNbr(invoiceBusinessKey) {
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: authorizationHeader,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(invoiceBusinessKey),
  };
  return fetch(resourceUrl + "/get-shipment-document-nbr", requestOptions);
}

function saveRelationship(shipmentLink) {
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: authorizationHeader,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(shipmentLink),
  };
  return fetch(resourceUrl + "/save", requestOptions);
}

function deleteRelationship(shipmentLink) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      Authorization: authorizationHeader,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(shipmentLink),
  };
  return fetch(resourceUrl, requestOptions);
}

function getInvoicesForShipment(shipmentNbr) {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: authorizationHeader,
      "Content-Type": "application/json",
    },
  };
  return fetch(
    resourceUrl + "/get-invoices-for-shipment/" + shipmentNbr,
    requestOptions
  ).then(commons.handleJsonResponse);
}

function getAssociatedInvoices(invoiceBusinessKeys) {
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: authorizationHeader,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(invoiceBusinessKeys),
  };
  return fetch(resourceUrl + "/get-associated-invoices", requestOptions).then(
    commons.handleJsonResponse
  );
}
