var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "stepper-content" },
    [
      _c(
        "v-container",
        { staticClass: "stepper-wrapper", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "overflow-y-hidden ma-0" },
            [
              _c(
                "v-col",
                { staticClass: "fill-height pa-0" },
                [
                  _c(
                    "v-container",
                    {
                      staticClass: "pa-1",
                      attrs: { "fill-height": "", fluid: "" },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "fill-height ma-0" },
                        [
                          !_vm.selectedVoyage
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "pa-1",
                                  attrs: { cols: "auto" },
                                },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { width: "23.5rem" } },
                                    [
                                      _c(
                                        "v-row",
                                        { staticClass: "ma-0" },
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "pb-2" },
                                            [
                                              _vm.selectedPolHaulage == "CY"
                                                ? _c("v-autocomplete", {
                                                    ref: "pol",
                                                    staticClass: "xs-input",
                                                    attrs: {
                                                      label:
                                                        _vm.$t("label.pol"),
                                                      value: _vm.polNode,
                                                      "item-text":
                                                        "countryName",
                                                      "item-value": "id",
                                                      items: _vm.nodi,
                                                      filter:
                                                        _vm.filterLocations,
                                                      "menu-props":
                                                        _vm.menuProps,
                                                      readonly:
                                                        _vm.uneditableRequest,
                                                      "return-object": "",
                                                      "hide-details": "",
                                                      outlined: "",
                                                      attach: "",
                                                      dense: "",
                                                    },
                                                    on: {
                                                      change: (pol) => {
                                                        if (pol)
                                                          _vm.updatePol(
                                                            pol.locode
                                                          )
                                                      },
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "item",
                                                          fn: function (data) {
                                                            return [
                                                              _c(
                                                                "v-list-item-content",
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            data
                                                                              .item
                                                                              .countryId
                                                                          ) +
                                                                          " - " +
                                                                          _vm._s(
                                                                            data
                                                                              .item
                                                                              .countryName
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-list-item-subtitle",
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            data
                                                                              .item
                                                                              .locode
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      4223325786
                                                    ),
                                                  })
                                                : _c("v-autocomplete", {
                                                    ref: "placeOfReceipt",
                                                    staticClass: "xs-input",
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "label.placeOfReceipt"
                                                      ),
                                                      value:
                                                        _vm.placeOfReceiptNode,
                                                      "item-text":
                                                        "countryName",
                                                      "item-value": "id",
                                                      items: _vm.nodi,
                                                      filter:
                                                        _vm.filterLocations,
                                                      "menu-props":
                                                        _vm.menuProps,
                                                      readonly:
                                                        _vm.uneditableRequest,
                                                      "return-object": "",
                                                      "hide-details": "",
                                                      outlined: "",
                                                      attach: "",
                                                      dense: "",
                                                    },
                                                    on: {
                                                      change: (
                                                        placeOfReceipt
                                                      ) => {
                                                        if (placeOfReceipt)
                                                          _vm.updatePlaceOfReceipt(
                                                            placeOfReceipt.locode
                                                          )
                                                      },
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "item",
                                                          fn: function (data) {
                                                            return [
                                                              _c(
                                                                "v-list-item-content",
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            data
                                                                              .item
                                                                              .countryId
                                                                          ) +
                                                                          " - " +
                                                                          _vm._s(
                                                                            data
                                                                              .item
                                                                              .countryName
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-list-item-subtitle",
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            data
                                                                              .item
                                                                              .locode
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      4223325786
                                                    ),
                                                  }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ma-0" },
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "py-0" },
                                            [
                                              _c(
                                                "v-radio-group",
                                                {
                                                  staticClass:
                                                    "radio-group ma-0 pt-0",
                                                  attrs: {
                                                    readonly:
                                                      _vm.loading ||
                                                      _vm.loadingLocation ||
                                                      _vm.uneditableRequest,
                                                    "hide-details": "",
                                                    dense: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.selectedPolHaulage,
                                                    callback: function ($$v) {
                                                      _vm.selectedPolHaulage =
                                                        $$v
                                                    },
                                                    expression:
                                                      "selectedPolHaulage",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-radio",
                                                    { attrs: { value: "CY" } },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "label" },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-caption black--text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Merchant Haulage (CY) "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                  _c(
                                                    "v-radio",
                                                    { attrs: { value: "SD" } },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "label" },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-caption black--text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Carrier Haulage (SD) "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.selectedPolHaulage == "SD" &&
                                      _vm.company == "CMACGM"
                                        ? _c(
                                            "v-row",
                                            { staticClass: "ma-0" },
                                            [
                                              _c(
                                                "v-col",
                                                { staticClass: "py-2" },
                                                [
                                                  _c("v-select", {
                                                    staticClass: "xs-input",
                                                    attrs: {
                                                      label:
                                                        _vm.$t(
                                                          "label.movementType"
                                                        ),
                                                      items: _vm.movementTypes,
                                                      "menu-props":
                                                        _vm.menuProps,
                                                      "hide-details": "",
                                                      outlined: "",
                                                      dense: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedMovementTypePol,
                                                      callback: function ($$v) {
                                                        _vm.selectedMovementTypePol =
                                                          $$v
                                                      },
                                                      expression:
                                                        "selectedMovementTypePol",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c("v-divider", {
                                        staticClass: "ma-2 mb-1",
                                      }),
                                      _c(
                                        "v-row",
                                        { staticClass: "ma-0" },
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "pb-2" },
                                            [
                                              _vm.selectedPodHaulage == "CY"
                                                ? _c("v-autocomplete", {
                                                    ref: "pod",
                                                    staticClass: "xs-input",
                                                    attrs: {
                                                      label:
                                                        _vm.$t("label.pod"),
                                                      value: _vm.podNode,
                                                      "item-text":
                                                        "countryName",
                                                      "item-value": "id",
                                                      items: _vm.nodi,
                                                      filter:
                                                        _vm.filterLocations,
                                                      "menu-props":
                                                        _vm.menuProps,
                                                      readonly:
                                                        _vm.uneditableRequest,
                                                      "return-object": "",
                                                      "hide-details": "",
                                                      outlined: "",
                                                      attach: "",
                                                      dense: "",
                                                    },
                                                    on: {
                                                      change: (pod) => {
                                                        if (pod)
                                                          _vm.updatePod(
                                                            pod.locode
                                                          )
                                                      },
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "item",
                                                          fn: function (data) {
                                                            return [
                                                              _c(
                                                                "v-list-item-content",
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            data
                                                                              .item
                                                                              .countryId
                                                                          ) +
                                                                          " - " +
                                                                          _vm._s(
                                                                            data
                                                                              .item
                                                                              .countryName
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-list-item-subtitle",
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            data
                                                                              .item
                                                                              .locode
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      4223325786
                                                    ),
                                                  })
                                                : _c("v-autocomplete", {
                                                    ref: "placeOfDelivery",
                                                    staticClass: "xs-input",
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "label.placeOfDelivery"
                                                      ),
                                                      value:
                                                        _vm.placeOfDeliveryNode,
                                                      "item-text":
                                                        "countryName",
                                                      "item-value": "id",
                                                      items: _vm.nodi,
                                                      filter:
                                                        _vm.filterLocations,
                                                      "menu-props":
                                                        _vm.menuProps,
                                                      readonly:
                                                        _vm.uneditableRequest,
                                                      "return-object": "",
                                                      "hide-details": "",
                                                      outlined: "",
                                                      attach: "",
                                                      dense: "",
                                                    },
                                                    on: {
                                                      change: (
                                                        placeOfDelivery
                                                      ) => {
                                                        if (placeOfDelivery)
                                                          _vm.updatePlaceOfDelivery(
                                                            placeOfDelivery.locode
                                                          )
                                                      },
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "item",
                                                          fn: function (data) {
                                                            return [
                                                              _c(
                                                                "v-list-item-content",
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            data
                                                                              .item
                                                                              .countryId
                                                                          ) +
                                                                          " - " +
                                                                          _vm._s(
                                                                            data
                                                                              .item
                                                                              .countryName
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-list-item-subtitle",
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            data
                                                                              .item
                                                                              .locode
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      4223325786
                                                    ),
                                                  }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ma-0" },
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "py-0" },
                                            [
                                              _c(
                                                "v-radio-group",
                                                {
                                                  staticClass:
                                                    "radio-group ma-0 pt-0",
                                                  attrs: {
                                                    readonly:
                                                      _vm.loading ||
                                                      _vm.loadingLocation ||
                                                      _vm.uneditableRequest,
                                                    "hide-details": "",
                                                    dense: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.selectedPodHaulage,
                                                    callback: function ($$v) {
                                                      _vm.selectedPodHaulage =
                                                        $$v
                                                    },
                                                    expression:
                                                      "selectedPodHaulage",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-radio",
                                                    { attrs: { value: "CY" } },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "label" },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-caption black--text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Merchant Haulage (CY) "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                  _c(
                                                    "v-radio",
                                                    { attrs: { value: "SD" } },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "label" },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-caption black--text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Carrier Haulage (SD) "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.selectedPodHaulage == "SD" &&
                                      _vm.company == "CMACGM"
                                        ? _c(
                                            "v-row",
                                            { staticClass: "ma-0" },
                                            [
                                              _c(
                                                "v-col",
                                                { staticClass: "py-2" },
                                                [
                                                  _c("v-select", {
                                                    staticClass: "xs-input",
                                                    attrs: {
                                                      label:
                                                        _vm.$t(
                                                          "label.movementType"
                                                        ),
                                                      items: _vm.movementTypes,
                                                      "menu-props":
                                                        _vm.menuProps,
                                                      "hide-details": "",
                                                      outlined: "",
                                                      dense: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedMovementTypePod,
                                                      callback: function ($$v) {
                                                        _vm.selectedMovementTypePod =
                                                          $$v
                                                      },
                                                      expression:
                                                        "selectedMovementTypePod",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c("v-divider", {
                                        staticClass: "mb-1 mt-2 mx-2",
                                      }),
                                      _c(
                                        "v-row",
                                        { staticClass: "ma-0" },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("DatePickerMenu", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "label.departureDate"
                                                  ),
                                                  appearance: "outlined",
                                                  offset: "x",
                                                  disabled:
                                                    _vm.uneditableRequest,
                                                  preselectedDate:
                                                    _vm.departureDate,
                                                  inputClass: "xs-input",
                                                },
                                                on: {
                                                  "select-date": (date) =>
                                                    (_vm.departureDate = date),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ma-0" },
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "pt-0" },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    width: "100%",
                                                    disabled:
                                                      _vm.loading ||
                                                      _vm.loadingLocation ||
                                                      _vm.uneditableRequest,
                                                    small: "",
                                                  },
                                                  on: {
                                                    click: _vm.getSchedules,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("label.search")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.selectedVoyage
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "fill-height overflow-y-auto pa-1",
                                },
                                [
                                  _c("RouteScheduleRow", {
                                    ref: "routeRow",
                                    attrs: {
                                      routeSchedule: _vm.selectedVoyage,
                                      selectedVoyage: _vm.selectedVoyage,
                                      disabled: _vm.uneditableRequest,
                                      company: _vm.company,
                                    },
                                    on: { "select-voyage": _vm.selectVoyage },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "v-col",
                                { staticClass: "fill-height pa-1 pl-0" },
                                [
                                  _vm.bookingRouteSchedules &&
                                  _vm.bookingRouteSchedules.length
                                    ? _c(
                                        "v-list",
                                        {
                                          ref: "routesList",
                                          staticClass:
                                            "fill-height overflow-y-auto pl-1",
                                          staticStyle: {
                                            padding: "1px 0 0 1px !important",
                                          },
                                        },
                                        _vm._l(
                                          _vm.bookingRouteSchedules,
                                          function (route, i) {
                                            return _c("RouteScheduleRow", {
                                              key: i,
                                              ref: "routeRow",
                                              refInFor: true,
                                              attrs: {
                                                routeSchedule: route,
                                                selectedVoyage:
                                                  _vm.selectedVoyage,
                                                disabled: _vm.uneditableRequest,
                                                company: _vm.company,
                                                id: _vm.checkIsSelected(i),
                                              },
                                              on: {
                                                "select-voyage":
                                                  _vm.selectVoyage,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-center justify-center",
                                        },
                                        [
                                          _vm.loading
                                            ? _c("RouteScheduleRowSkeleton")
                                            : _c(
                                                "div",
                                                { staticClass: "mt-10" },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-1",
                                                      attrs: {
                                                        color: "warning",
                                                      },
                                                    },
                                                    [_vm._v(" mdi-alert ")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-caption font-weight-medium",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "message.noAvailableVoyages"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "flex-grow-0 ma-0" },
            [
              _c(
                "v-col",
                { staticClass: "d-flex pa-2" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", icon: "", color: "primary" },
                      on: { click: _vm.exitRequest },
                    },
                    [
                      _c("v-icon", { attrs: { size: "30" } }, [
                        _vm._v("mdi-arrow-left-circle"),
                      ]),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        small: "",
                        color: "primary",
                        disabled: _vm.disableNext || _vm.loadingLocation,
                      },
                      on: { click: _vm.submitVoyage },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.$t("label.next")))]),
                      _c("v-icon", [_vm._v("mdi-play")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }