import { usersService } from '@/services/users.service'
import {usersManagerService} from '@/services/usersManager.service'
import { authorizationsService } from '@/services/authorizations.service'
import { userParametersService } from '@/services/userParameters.service'
import { router } from '@/router'

const state = {
  user: JSON.parse(localStorage.getItem('user')) || null,
  userParameters: {},
  userAuthorizations: JSON.parse(localStorage.getItem('userAuthorizations')) || [],
  isAdmin: localStorage.getItem('isAdmin') ? Boolean(JSON.parse(localStorage.getItem('isAdmin'))) : false,
  internalVisibilityAuth: JSON.parse(localStorage.getItem('internalVisibilityAuth')) || null,
  filtersAreOpen: false
}

const actions = {
  async login({ dispatch, commit }, azureUser) {
    authorizationsService.getUserAuthorizations()
    .then((auths) => {
      commit('setUserAuthorizations', auths)
      commit('setInternalVisibilityAuth', auths.includes('AUTH_DISPLAY_INTERNAL_DATA'))
    })

    let user
    try {
      user = await usersService.findByAzureAccountOrEmail(azureUser.accountIdentifier, azureUser.userName)
    } catch (error) {
      let newUser = {
        id: null,
        username: azureUser.userName,
        email: azureUser.userName,
        firstName: azureUser.name.split(' ')[0],
        lastName: azureUser.name.split(' ')[1],
        azureAccountId: azureUser.accountIdentifier,
      }
      router.push({ name: 'newUserLanding' })
      user = await usersService.createUserFirstAccess(newUser)
    }

    commit('loginSuccess', user)
    commit('setAdminStatus', user.groups.some(g => g.groupDes === 'GR_ADMINISTRATORS'))

    if (!user.azureAccountId) {
      user.azureAccountId = azureUser.accountIdentifier
      usersService.updateUser(user.id, user).then((resp) => (user = resp))
    }

    return userParametersService
      .getUserParameter(user.id, 'LANGUAGE')
      .then((lang) => {
        if (lang.length == 0) {
          let userParam = {
            id: null,
            userId: user.id,
            key: 'LANGUAGE',
            valueType: 'STRING',
            value: 'en',
          }
          commit('setLanguage', userParam)
          userParametersService.createUserParameter(userParam).then((res) => {
            commit('setLanguage', res)
          })
        } else commit('setLanguage', lang[0])
      })
  },
  async loginCircle({commit}, {username, password}) {
    const response = await usersManagerService.login(username, password);
    if (response) {
      const auths = await authorizationsService.getUserAuthorizations();
      commit('setUserAuthorizations', auths);
      localStorage.setItem('auths', auths)

      commit('setInternalVisibilityAuth', auths.includes('AUTH_DISPLAY_INTERNAL_DATA'));
      localStorage.setItem('internalVisibilityAuth', auths.includes('AUTH_DISPLAY_INTERNAL_DATA'))

      const user = await usersManagerService.getByUsername(username);

      if (user) {
        commit('loginSuccess', user);
        localStorage.setItem('user', JSON.stringify(user))
        const isAdmin = user.groups.some(g => g.groupDes === 'GR_ADMINISTRATORS');
        commit('setAdminStatus', isAdmin);
        localStorage.setItem('isAdmin', isAdmin)

        const lang = await userParametersService.getUserParameter(user.id, 'LANGUAGE');

        if (lang.length === 0) {
          const userParam = {
            id: null,
            userId: user.id,
            key: 'LANGUAGE',
            valueType: 'STRING',
            value: 'en',
          };
          commit('setLanguage', userParam);
          const res = await userParametersService.createUserParameter(userParam);
          commit('setLanguage', res);
        } else {
          commit('setLanguage', lang[0]);
        }

        router.push('/app');
      } else {
        console.error('User not found');
      }
    }
  }
}

const mutations = {
  loginSuccess(state, user) {
    state.user = user
  },
  setAdminStatus(state, isAdmin) {
    state.isAdmin = isAdmin
  },
  setLanguage(state, language) {
    state.userParameters.language = language
  },
  setUserAuthorizations(state, auths){
    state.userAuthorizations = auths
  },
  setInternalVisibilityAuth(state, auth) {
    state.internalVisibilityAuth = auth
  },
  setFiltersAreOpen(state, filtersAreOpen) {
    state.filtersAreOpen = filtersAreOpen
  },
}

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
}
