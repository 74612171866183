import { authHeader } from '@/helpers/auth-header'
import { commons } from './commons'

export const documentsService = {
  browse,
  getDocument,
  createDocument,
  updateDocument,
  deleteDocument,
}

const resourceUrl = process.env.VUE_APP_GSCV_ENGINE_URL + `/docs`

function browse(browseRequest) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      offset: browseRequest.offset,
      limit: browseRequest.limit,
      filter: browseRequest.filter,
      sortingList: browseRequest.sortingList,
    }),
  }
  return fetch(resourceUrl + `/browse`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function getDocument(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/${id}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function createDocument(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }
  return fetch(resourceUrl, requestOptions).then(commons.handleJsonResponse)
}

function updateDocument(id, data) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }
  return fetch(resourceUrl + `/${id}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function deleteDocument(id, data) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/${id}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}
