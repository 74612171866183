<template>
  <div class="stepper-content">
    <v-container class="stepper-wrapper" fluid>
      <v-row class="overflow-y-hidden ma-0">
        <v-col class="fill-height pa-0">
          <v-container class="pa-1" fill-height fluid>
            <v-row class="fill-height ma-0">
              <v-col cols="auto" class="pa-1" v-if="!selectedVoyage">
                <v-card width="23.5rem">
                  <v-row class="ma-0">
                    <v-col class="pb-2">
                      <v-autocomplete
                        v-if="selectedPolHaulage == 'CY'"
                        :label="$t('label.pol')"
                        ref="pol"
                        :value="polNode"
                        item-text="countryName"
                        item-value="id"
                        :items="nodi"
                        :filter="filterLocations"
                        :menu-props="menuProps"
                        @change="(pol) => {if (pol) updatePol(pol.locode)}"
                        @click.stop
                        :readonly="uneditableRequest"
                        class="xs-input"
                        return-object
                        hide-details
                        outlined
                        attach
                        dense
                      >
                        <template v-slot:item="data">
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ data.item.countryId }} -
                              {{ data.item.countryName }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ data.item.locode }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                      <v-autocomplete
                        v-else
                        :label="$t('label.placeOfReceipt')"
                        :value="placeOfReceiptNode"
                        ref="placeOfReceipt"
                        item-text="countryName"
                        item-value="id"
                        :items="nodi"
                        :filter="filterLocations"
                        :menu-props="menuProps"
                        @change="(placeOfReceipt) => {if (placeOfReceipt) updatePlaceOfReceipt(placeOfReceipt.locode)}"
                        @click.stop
                        :readonly="uneditableRequest"
                        class="xs-input"
                        return-object
                        hide-details
                        outlined
                        attach
                        dense
                      >
                      <!-- @update:search-input="(v) => findProviderLocation(v, placeOfReceipt)" -->
                        <template v-slot:item="data">
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ data.item.countryId }} -
                              {{ data.item.countryName }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ data.item.locode }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row class="ma-0">
                    <v-col class="py-0">
                      <v-radio-group
                        v-model="selectedPolHaulage"
                        class="radio-group ma-0 pt-0"
                        :readonly="loading || loadingLocation || uneditableRequest"
                        hide-details
                        dense
                      >
                        <v-radio value="CY">
                          <template slot="label">
                            <span class="text-caption black--text">
                              Merchant Haulage (CY)
                            </span>
                          </template>
                        </v-radio>
                        <v-radio value="SD">
                          <template slot="label">
                            <span class="text-caption black--text">
                              Carrier Haulage (SD)
                            </span>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row class="ma-0" v-if="selectedPolHaulage == 'SD' && company == 'CMACGM'">
                    <v-col class="py-2">
                      <v-select
                        v-model="selectedMovementTypePol"
                        :label="$t('label.movementType')"
                        :items="movementTypes"
                        :menu-props="menuProps"
                        class="xs-input"
                        hide-details
                        outlined
                        dense
                      />
                    </v-col>
                  </v-row>
                  <v-divider class="ma-2 mb-1" />
                  <v-row class="ma-0">
                    <v-col class="pb-2">
                      <v-autocomplete
                        v-if="selectedPodHaulage == 'CY'"
                        :label="$t('label.pod')"
                        ref="pod"
                        :value="podNode"
                        item-text="countryName"
                        item-value="id"
                        :items="nodi"
                        :filter="filterLocations"
                        :menu-props="menuProps"
                        @change="(pod) => {if(pod) updatePod(pod.locode)}"
                        @click.stop
                        :readonly="uneditableRequest"
                        class="xs-input"
                        return-object
                        hide-details
                        outlined
                        attach
                        dense
                      >
                        <template v-slot:item="data">
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ data.item.countryId }} -
                              {{ data.item.countryName }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ data.item.locode }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                      <v-autocomplete
                        v-else
                        :label="$t('label.placeOfDelivery')"
                        :value="placeOfDeliveryNode"
                        ref="placeOfDelivery"
                        item-text="countryName"
                        item-value="id"
                        :items="nodi"
                        :filter="filterLocations"
                        :menu-props="menuProps"
                        @change="(placeOfDelivery) => {if(placeOfDelivery) updatePlaceOfDelivery(placeOfDelivery.locode)}"
                        @click.stop
                        :readonly="uneditableRequest"
                        class="xs-input"
                        return-object
                        hide-details
                        outlined
                        attach
                        dense
                      >
                      <!-- @update:search-input="(v) => findProviderLocation(v, placeOfDelivery)" -->
                        <template v-slot:item="data">
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ data.item.countryId }} -
                              {{ data.item.countryName }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ data.item.locode }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row class="ma-0">
                    <v-col class="py-0">
                      <v-radio-group
                        v-model="selectedPodHaulage"
                        class="radio-group ma-0 pt-0"
                        :readonly="loading || loadingLocation || uneditableRequest"
                        hide-details
                        dense
                      >
                        <v-radio value="CY">
                          <template slot="label">
                            <span class="text-caption black--text">
                              Merchant Haulage (CY)
                            </span>
                          </template>
                        </v-radio>
                        <v-radio value="SD">
                          <template slot="label">
                            <span class="text-caption black--text">
                              Carrier Haulage (SD)
                            </span>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row
                    class="ma-0"
                    v-if="selectedPodHaulage == 'SD' && company == 'CMACGM'"
                  >
                    <v-col class="py-2">
                      <v-select
                        v-model="selectedMovementTypePod"
                        :label="$t('label.movementType')"
                        :items="movementTypes"
                        :menu-props="menuProps"
                        class="xs-input"
                        hide-details
                        outlined
                        dense
                      />
                    </v-col>
                  </v-row>
                  <v-divider class="mb-1 mt-2 mx-2" />
                  <v-row class="ma-0">
                    <v-col>
                      <DatePickerMenu
                        @select-date="(date) => (departureDate = date)"
                        :label="$t('label.departureDate')"
                        :appearance="'outlined'"
                        :offset="'x'"
                        :disabled="uneditableRequest"
                        :preselectedDate="departureDate"
                        :inputClass="'xs-input'"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="ma-0">
                    <v-col class="pt-0">
                      <v-btn
                        color="primary"
                        width="100%"
                        :disabled="loading || loadingLocation || uneditableRequest"
                        @click="getSchedules"
                        small
                      >
                        {{ $t('label.search') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col class="fill-height overflow-y-auto pa-1" v-if="selectedVoyage">
                <RouteScheduleRow
                  ref="routeRow"
                  :routeSchedule="selectedVoyage"
                  :selectedVoyage="selectedVoyage"
                  :disabled="uneditableRequest"
                  :company="company"
                  @select-voyage="selectVoyage"
                />
              </v-col>
              <v-col class="fill-height pa-1 pl-0" v-else>
                <v-list
                  ref="routesList"
                  class="fill-height overflow-y-auto pl-1"
                  style="padding: 1px 0 0 1px !important"
                  v-if="bookingRouteSchedules && bookingRouteSchedules.length"
                >
                  <RouteScheduleRow
                    v-for="(route, i) in bookingRouteSchedules"
                    :key="i"
                    ref="routeRow"
                    :routeSchedule="route"
                    :selectedVoyage="selectedVoyage"
                    :disabled="uneditableRequest"
                    :company="company"
                    @select-voyage="selectVoyage"
                    :id="checkIsSelected(i)"
                  />
                </v-list>
                <div v-else class="d-flex align-center justify-center">
                  <RouteScheduleRowSkeleton v-if="loading" />
                  <div v-else class="mt-10">
                    <v-icon color="warning" class="mr-1"> mdi-alert </v-icon>
                    <span class="text-caption font-weight-medium"> {{ $t('message.noAvailableVoyages') }} </span>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row class="flex-grow-0 ma-0">
        <v-col class="d-flex pa-2">
          <v-btn small icon color="primary" @click="exitRequest">
            <v-icon size="30">mdi-arrow-left-circle</v-icon>
          </v-btn>
          <v-spacer />
          <!-- <v-btn small color="primary" class="mr-2" @click="$emit('step', false)">
            <v-icon>mdi-step-backward</v-icon>
            <span>{{ $t('label.back') }}</span>
          </v-btn> -->
          <v-btn
            small
            color="primary"
            :disabled="disableNext || loadingLocation"
            @click="submitVoyage"
          >
            <span>{{ $t('label.next') }}</span>
            <v-icon>mdi-play</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Booking from '@/mixins/Booking.js'
import { nodiService } from '@/services/nodi.service.js'
import { electronicFlowService } from '@/services/electronicFlow.service.js'
import RouteScheduleRow from '@/components/elements/booking/RouteScheduleRow.vue'
import RouteScheduleRowSkeleton from '@/components/elements/booking/RouteScheduleRowSkeleton.vue'
import DatePickerMenu from '@/components/elements/DatePickerMenu.vue'

export default {
  name: 'BookingVoyages',
  props: ['pratica'],
  mixins: [Booking],
  components: { RouteScheduleRow, RouteScheduleRowSkeleton, DatePickerMenu },
  data() {
    return {
      selectedVoyage: null,
      nodi: [],
      maerskLocations: [],
      cmaCgmLocations: [],
      pol: null,
      pod: null,
      polNode: {},
      podNode: {},
      placeOfReceipt: null,
      placeOfDelivery: null,
      placeOfReceiptNode: {},
      placeOfDeliveryNode: {},
      selectedPolHaulage: 'CY',
      selectedPodHaulage: 'CY',
      selectedMovementTypePol: 'DOOR',
      selectedMovementTypePod: 'DOOR',
      movementTypes: ['DOOR', 'RAMP'],
      locodeCodificationType: 'UN/Locode',
      bookingRouteSchedules: null,
      departureDate: new Date().toISOString().substr(0, 10),
      loadingLocation: true,
      loading: true,
    }
  },
  computed: {
    scheduleRequest() {
      return {
        carrier: this.company,
        carrierCode: this.carrierCode,
        countryOrigin: this.selectedPolHaulage == 'CY' ? this.pol.countryCode : this.placeOfReceipt.countryCode,
        cityOrigin: this.selectedPolHaulage == 'CY' ? this.pol.cityName : this.placeOfReceipt.cityName,
        placeOfLoading: this.selectedPolHaulage == 'CY' ? this.pol.locode : this.placeOfReceipt.locode,
        exportServiceMode: this.selectedPolHaulage,
        countryDestination: this.selectedPodHaulage == 'CY' ? this.pod.countryCode : this.placeOfDelivery.countryCode,
        cityDestination: this.selectedPodHaulage == 'CY' ? this.pod.cityName : this.placeOfDelivery.cityName,
        placeOfDischarge: this.selectedPodHaulage == 'CY' ? this.pod.locode : this.placeOfDelivery.locode,
        importServiceMode: this.selectedPodHaulage,
        departureDate: this.departureDate,
      }
    },
    polLocationTypeCode() {
      switch (this.company) {
        case 'MAERSK':
        case 'YANGMING':
          return 'PRE'
        case 'CMACGM':
          return 'POL'
        default:
          return ''
      }
    },
    podLocationTypeCode() {
      switch (this.company) {
        case 'MAERSK':
        case 'YANGMING':
          return 'PDE'
        case 'CMACGM':
          return 'POD'
        default:
          return ''
      }
    },
    getLocationItems() {
      switch (this.company) {
        case 'MAERSK':
          return this.maerskLocations
        case 'CMACGM':
          return this.cmaCgmLocations
        case 'YANGMING':
          return []
        default:
          return []
      }
    },
    modeOfTransportPol() {
      switch (this.selectedMovementTypePol) {
        case 'DOOR':
          return 'Road'
        case 'RAMP':
          return 'Rail'
        default:
          return null
      }
    },
  },
  watch: {
    selectedVoyage: {
      handler(voyage) {
        this.disableNext = voyage && this.electronicFlowStatus != this.pendingStatus ? false : true
      },
      deep: true,
    },
  },
  methods: {
    async init() {
      this.disableNextForEmptyVoyage()
      this.selectedVoyage = this.savedVoyage
      if (this.savedVoyage) {
        this.loading = false
        this.loadingLocation = false
      }
      else {
        await this.setPolAndPod()
        this.getSchedules()
      }
    },
    setPolAndPod(){
      this.loading = true
      this.bookingRouteSchedules = []
      return this.findAllNodi().then(() => {
        return this.getPolAndPod()
      })
    },
    getSchedules() {
      this.loading = true
      this.bookingRouteSchedules = []
      this.getRouteSchedules().then((resp) => {
        this.bookingRouteSchedules = resp
        // this.goToSelectedRow()
        this.loading = false
      })
    },
    getRouteSchedules() {
      return electronicFlowService.getBookingRouteSchedules(this.scheduleRequest)
        .catch((error) => {
          this.setSnackbarMessage({ text: error, color: 'error' })
          this.loading = false
        })
    },
    getMaerskLocation(locode) {
      return electronicFlowService.getBookingRequestLocations('MAERSK', '', '', locode, 'CITY')
    },
    getMaerskLocationByCity(city) {
      return electronicFlowService.getBookingRequestLocations('MAERSK', '', city, '', 'CITY')
    },
    getMaerskLocationByCountryAndCity(country, city) {
      return electronicFlowService.getBookingRequestLocations('MAERSK', country, city, '', 'CITY')
    },
    getCmaCgmLocation(locode) {
      return electronicFlowService.getBookingRequestLocations('CMACGM', '', '', locode, 'CITY')
    },
    getCmaCgmLocationByCity(city) {
      return electronicFlowService.getBookingRequestLocations('CMACGM', '', city, '', 'CITY')
    },
    getCmaCgmLocationByCountryAndCity(country, city) {
      return electronicFlowService.getBookingRequestLocations('CMACGM', country, city, '', 'CITY')
    },
    findAllNodi() {
      return nodiService.getAllNodi().then((resp) => this.nodi = resp)
    },
    async getPolAndPod() {
      let pol, pod
      pol = this.selectedVoyage ? this.selectedVoyage.originPoint.locationCode : this.pratica.pol.locode
      pod = this.selectedVoyage ? this.selectedVoyage.destinationPoint.locationCode : this.pratica.pod.locode
      await this.updatePol(pol)
      await this.updatePod(pod)
    },
    async updatePol(pol) {
      this.loadingLocation = true
      let locations = await this.getProviderLocation(pol)
      if (this.company == 'YANGMING') {
        this.pol = {locode: pol}
      } else {
        if (!locations.length) {
          this.pol = {}
          if (this.$refs.pol) this.$refs.pol.reset()
          this.setSnackbarMessage({text: 'Pol location not found', color: 'error'})
        } else {
          this.pol = {
            countryCode: this.getProviderCountryCode(locations[0]),
            cityName: locations[0].cityName,
            locode: locations[0].locationCode,
            carrierLocationId: locations[0].carrierLocationId,
          }
        }
      }
      this.polNode = this.setCurrentNodes(pol)
      this.loadingLocation = false
    },
    async updatePod(pod) {
      this.loadingLocation = true
      let locations = await this.getProviderLocation(pod)
      if (this.company == 'YANGMING') {
        this.pod = {locode: pod}
      } else {
        if (!locations.length) {
          this.pod = {}
          if (this.$refs.pod) this.$refs.pod.reset()
          this.setSnackbarMessage({text: 'Pod location not found', color: 'error'})
        } else {
          this.pod = {
            countryCode: this.getProviderCountryCode(locations[0]),
            cityName: locations[0].cityName,
            locode: locations[0].locationCode,
            carrierLocationId: locations[0].carrierLocationId,
          }
        }
      }
      this.podNode = this.setCurrentNodes(pod)
      this.loadingLocation = false
    },
    async updatePlaceOfReceipt(placeOfReceipt) {
      this.loadingLocation = true
      let locations = await this.getProviderLocation(placeOfReceipt)
      if (this.company == 'YANGMING') {
        this.placeOfReceipt = {locode: placeOfReceipt}
      } else {
        if (!locations.length) {
          this.placeOfReceipt = {}
          if (this.$refs.placeOfReceipt) this.$refs.placeOfReceipt.reset()
          this.setSnackbarMessage({text: 'Place of Receipt location not found', color: 'error'})
        } else {
          this.placeOfReceipt = {
            countryCode: this.getProviderCountryCode(locations[0]),
            cityName: locations[0].cityName,
            locode: locations[0].locationCode,
            carrierLocationId: locations[0].carrierLocationId,
          }
        }
      }
      this.placeOfReceiptNode = this.setCurrentNodes(placeOfReceipt)
      this.loadingLocation = false
    },
    async updatePlaceOfDelivery(placeOfDelivery) {
      this.loadingLocation = true
      let locations = await this.getProviderLocation(placeOfDelivery)
      if (this.company == 'YANGMING') {
        this.placeOfDelivery = {locode: placeOfDelivery}
      } else {
        if (!locations.length) {
          this.placeOfDelivery = {}
          if (this.$refs.placeOfDelivery) this.$refs.placeOfDelivery.reset()
          this.setSnackbarMessage({text: 'Place of Delivery location not found', color: 'error'})
        } else {
          this.placeOfDelivery = {
            countryCode: this.getProviderCountryCode(locations[0]),
            cityName: locations[0].cityName,
            locode: locations[0].locationCode,
            carrierLocationId: locations[0].carrierLocationId,
          }
        }
      }
      this.placeOfDeliveryNode = this.setCurrentNodes(placeOfDelivery)
      this.loadingLocation = false
    },
    getProviderLocation(location){
      switch (this.company) {
        case 'MAERSK':
          return this.getMaerskLocation(location)
        case 'CMACGM':
          return this.getCmaCgmLocation(location)
        case 'YANGMING':
          return location
      }
    },
    setCurrentNodes(locode) {
      let currentNode = this.nodi.filter((nodo) => nodo.locode == locode)
      return currentNode.length ? currentNode[0] : {}
    },
    getProviderCountryCode(location){
      switch (this.company) {
        case 'MAERSK':
          return location.countryCode
        case 'CMACGM':
          return location.countryName
      }
    },
    async selectVoyage(voyage) {
      this.selectedVoyage = voyage
      if (this.selectedVoyage) return
      this.resetImportExportRemarks()
      await this.setPolAndPod()
      this.getSchedules()
    },
    resetImportExportRemarks(){
      this.setExportRemarks(null)
      this.setExportRemarks(null)
    },
    checkIsSelected(index) {
      if (!this.$refs.routeRow || !this.$refs.routeRow[index]) return
      if (this.$refs.routeRow[index].voyageSelected) return 'selected-row'
    },
    filterLocations(item, queryText) {
      return (
        item.countryName.toLocaleLowerCase().indexOf(queryText.toLowerCase()) > -1 ||
        item.locode.toLocaleLowerCase().indexOf(queryText.toLowerCase()) > -1
      )
    },
    getLocationLabel(item) {
      return `${item.cityName} - ${item.countryCode}`
    },

    // Submit
    submitVoyage() {
      if (!this.pol && !this.pod) return this.$emit('step', true)
      if (this.electronicFlowStatus == this.createdStatus) this.setStatus(this.voyageSelectedStatus)
      this.setVoyageInfo()
      this.setRequestedEquipmentsHaulage()
      this.setRequestVoyageId(JSON.stringify(this.selectedVoyage))

      this.updateRequestAndSaveElectronicFlow()
      this.$emit('step', true)
    },
    setVoyageInfo() {
      if (!this.selectedVoyage.routeLegs) return
      let carrier = this.getCarrier(this.selectedVoyage)

      let shipmentLocations = []
      let transports = []

      let shipmentLocation = this.getShipmentLocation(this.selectedVoyage.originPoint, true)
      shipmentLocations.push(shipmentLocation)
      shipmentLocation = this.getShipmentLocation(this.selectedVoyage.destinationPoint, false)
      shipmentLocations.push(shipmentLocation)

      this.selectedVoyage.routeLegs.forEach((leg) => {
        let transport = this.getTransport(leg)
        transports.push(transport)
      })

      this.setCarrier(carrier)
      this.setExpectedDepartureDate(this.selectedVoyage.departureDateTime)
      this.setShipmentLocations(shipmentLocations)
      this.setTransports(transports)
      this.setReceiptTypeAtOrigin(this.selectedPolHaulage)
      this.setDeliveryTypeAtDestination(this.selectedPodHaulage)
    },
    setRequestedEquipmentsHaulage() {
      let equipments
      if (this.electronicFlow.bookingRequest.equipments)
        equipments = JSON.parse(this.electronicFlow.bookingRequest.equipments)
      else {
        equipments = [{
          code: null,
          requestedEquipments: {units: 0, haulageDetails: {}}
        }]
      }
      let requestedEquipments = []
      equipments.forEach((equipment) => {
        let haulageDetails = []
        if (this.selectedPolHaulage == 'SD')
          haulageDetails.push(this.getPlaceOfReceiptHaulageDetails())
        if (this.selectedPodHaulage == 'SD')
          haulageDetails.push(this.getPlaceOfDeliveryHaulageDetails())
        equipment.requestedEquipments.haulageDetails = haulageDetails
        requestedEquipments.push(equipment.requestedEquipments)
      })
      this.setRequestedEquipments(requestedEquipments)
      this.setRequestEquipments(JSON.stringify(equipments))
    },
    getPlaceOfReceiptHaulageDetails() {
      let haulageDetail = {
        haulageType: this.selectedPolHaulage == 'CY' ? 'MERCHANT' : 'CARRIER',
        movementType: this.selectedMovementTypePol,
        modeOfTransport: this.modeOfTransportPol,
        operationalStage: 'EXPORT',
        haulageSites: [
          {
            companyName: null,
            siteRole: 'CARGO_PICKUP',
            address: {
              city: this.placeOfReceipt.cityName,
              country: this.placeOfReceipt.countryCode,
              street: null,
            },
            contactDetails: {},
          },
        ],
      }
      if (this.company == 'CMACGM')
        haulageDetail.haulageSites[0].locationCodifications = [
          {
            codificationType: this.locodeCodificationType,
            codification: this.placeOfReceipt.locode,
          },
        ]
      return haulageDetail
    },
    getPlaceOfDeliveryHaulageDetails() {
      let haulageDetail = {
        haulageType: this.selectedPodHaulage == 'CY' ? 'MERCHANT' : 'CARRIER',
        movementType: this.selectedMovementTypePod,
        operationalStage: 'IMPORT',
        haulageSites: [
          {
            companyName: null,
            siteRole: 'CARGO_DELIVERY',
            address: {
              city: this.placeOfDelivery.cityName,
              country: this.placeOfDelivery.countryCode,
            },
            contactDetails: {},
          },
        ],
      }
      if (this.company == 'CMACGM')
        haulageDetail.haulageSites[0].locationCodifications = [
          {
            codificationType: this.locodeCodificationType,
            codification: this.placeOfDelivery.locode,
          },
        ]
      return haulageDetail
    },
    getCarrier(voyage) {
      return {
        carrierCode: voyage.carrierCode,
        carrierName: this.company,
      }
    },
    getShipmentLocation(location, isPol) {
      if (isPol){
        return {
          location: this.selectedPolHaulage == 'CY' ? this.getLocation(location, isPol) : this.getLocationStoreDoor(location, isPol),
          shipmentLocationTypeCode: isPol ? this.polLocationTypeCode : this.podLocationTypeCode,
        }
      } else {
        return {
          location: this.selectedPodHaulage == 'CY' ? this.getLocation(location, isPol) : this.getLocationStoreDoor(location, isPol),
          shipmentLocationTypeCode: isPol ? this.polLocationTypeCode : this.podLocationTypeCode
        }
      }
    },
    getLocation(location, isPol) {
      return {
        locationName: location.locationName,
        UNLocationCode: location.locationCode,
        carrierCityGeoId: isPol ? this.pol.carrierLocationId : this.pod.carrierLocationId,
        address: {
          city: location.locationName,
          country: location.countryCode,
        },
      }
    },
    getLocationStoreDoor(location, isPol){
      return {
        locationName: location.locationName,
        UNLocationCode: location.locationCode,
        carrierCityGeoId: isPol ? this.placeOfReceipt.carrierLocationId : this.placeOfDelivery.carrierLocationId,
        address: {
          city: location.locationName,
          country: location.countryCode,
        },
      }
    },
    getTransport(leg) {
      return {
        plannedDepartureDate: leg.departureDateTime,
        plannedArrivalDate: leg.arrivalDateTime,
        vessel: leg.transport ? this.getVessel(leg.transport.vessel) : null,
        voyageNumber: leg.transport ? leg.transport.voyageNumber : null,
        loadLocation: this.getLocationLite(leg.pointFrom),
        dischargeLocation: this.getLocationLite(leg.pointTo),
        modeOfTransport: leg.transport ? leg.transport.transportMode : null,
      }
    },
    getLocationLite(location) {
      return {
        locationName: location.locationName,
        UNLocationCode: location.locationCode,
        carrierSiteGeoId: location.carrierSiteGeoId,
      }
    },
    getVessel(vessel) {
      return {
        vesselName: vessel.vesselName,
        vesselCode: vessel.vesselCode,
        vesselIMONumber: vessel.vesselIMONumber,
      }
    },
    disableNextForEmptyVoyage() {
      if (!this.selectedVoyage) this.disableNext = true
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>
.stepper-content {
  overflow: auto !important;
  height: 100% !important;
}
.stepper-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0;
}
.radio-group::v-deep .v-input--radio-group__input {
  flex-direction: row;
}
.radio-group::v-deep .v-label {
  margin: 0 !important;
}
.v-radio {
  max-width: max-content;
  margin: 0 1rem 0 0 !important;
}
.xs-input::v-deep input {
  padding: 0.5rem !important;
  padding-left: 0 !important;
}
.xs-input::v-deep .v-label {
  position: unset !important;
  min-width: max-content;
  margin-bottom: 0 !important;
}
.xs-input::v-deep .v-input__slot {
  min-height: 0 !important;
  padding-right: 4px !important;
}
.xs-input::v-deep .v-input__append-inner {
  margin: 0 !important;
  align-self: center !important;
}
.xs-input::v-deep .v-label--active {
  position: absolute !important;
  margin-bottom: 0 !important;
}
.xs-input::v-deep .v-select__selections {
  padding: 0 !important;
}
/*.row {
  border: solid 1px red;
}
.col {
  border: solid 1px orange;
}*/
</style>
