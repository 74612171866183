<template>
  <v-container class="stepper-wrapper" fluid>
    <v-row class="ma-0 fill-height">
      <v-col class="d-table fill-height pa-1">
        <v-container class="d-block pa-0 overflow-y-auto" fill-height fluid>
          <v-row class="ma-0">
            <v-col cols="3" class="pa-1" v-for="(item, i) in parties" :key="i">
              <ShippingInstructionParty
                ref="partyForm"
                :company="company"
                :preselectedParty="item" 
                :partyFunction="item.partyFunction" 
                @select-party="(party) => createUpdateParty(party, item.partyFunction)"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row class="flex-grow-0 ma-0">
      <v-col class="d-flex pa-2">
        <v-btn small icon color="primary" @click="exitRequest">
          <v-icon size="30">mdi-arrow-left-circle</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn small color="primary" class="mr-2" @click="$emit('step', false)">
          <v-icon>mdi-step-backward</v-icon>
          <span>{{ $t('label.back') }}</span>
        </v-btn>
        <v-btn small color="primary" @click="submitParties">
          <span>{{ $t('label.next') }}</span>
          <v-icon>mdi-play</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Parties from '@/mixins/Parties.js'
import ShippingInstruction from '@/mixins/ShippingInstruction.js'
import ShippingInstructionParty from '@/components/elements/shipping-instruction/ShippingInstructionParty'

export default {
  name: 'ShippingInstructionParties',
  mixins: [ShippingInstruction, Parties],
  components: {ShippingInstructionParty},
  data() {
    return {
      customers: [],
      partyfunctions: [
        'Transport Document Receiver',
        'Shipper',
        'Consignee',
        'First Notify Party',
      ],
      parties: [],
      partyTemplate: {
        partyFunction: null,
        party: {
          partyName: null,
          taxReference1: null,
          publicKey: null,
          street: null,
          streetNumber: null,
          floor: null,
          postCode: null,
          city: null,
          stateRegion: null,
          country: null,
          taxReference2: null,
          nmftaCode: null,
        },
        contactDetails: {
          name: null,
          email: null,
          phone: null,
        },
        isToBeNotified: null,
      },
      transportDocumentParty: {},
      shipperParty: {},
      consigneeParty: {},
      notifyParty: {},
      customerSelection: {},
      partyEditDialog: false,
      editableItem: null,
    }
  },
  computed: {
    shipper() {
      return this.pratica.shipper
    },
    consignee() {
      return this.pratica.consignee
    },
    forwarderCode(){
      switch (this.company) {
        case 'MAERSK':
          return 'REQ' // Edit
        case 'CMACGM':
          return 'REQ'
      } return null
    },
    shipperCode(){
      switch (this.company) {
        case 'MAERSK':
          return 'OS' // Edit
        case 'CMACGM':
          return 'OS'
      } return null
    },
    consigneeCode(){
      switch (this.company) {
        case 'MAERSK':
          return 'CN'; // Edit
        case 'CMACGM':
          return 'CN'
      } return null
    },
    notifyPartyCode(){
      switch (this.company) {
        case 'MAERSK':
          return 'N1'; // Edit
        case 'CMACGM':
          return 'N1'
      } return null
    },
  },
  methods: {
    init() {
      if (this.savedParties && this.savedParties.length) this.parties = this.savedParties
      else this.initParties()
    },
    initParties() {
      this.transportDocumentParty = this.setTransportDocumentReceiverParty()
      this.createUpdateParty(this.transportDocumentParty, this.forwarderCode)
      this.shipperParty = this.setParty(this.shipper, this.shipperCode)
      this.createUpdateParty(this.shipperParty, this.shipperCode)
      this.consigneeParty = this.setParty(this.consignee, this.consigneeCode)
      this.createUpdateParty(this.consigneeParty, this.consigneeCode)
      this.notifyParty = this.setParty({}, this.notifyPartyCode)
      this.createUpdateParty(this.notifyParty, this.notifyPartyCode)
    },
    setTransportDocumentReceiverParty(){
      let transportDocumentParty = {
        partyFunction: 'REQ',
        party: {
          partyName: this.applicantCompanyName,
          taxReference1: null,
          publicKey: null,
          street: this.companyAddress,
          streetNumber: this.companyAddressNbr,
          floor: null,
          postCode: this.companyPostalCode,
          city: this.companyCity,
          stateRegion: null,
          country: this.companyCountryMaersk,
          taxReference2: null,
          nmftaCode: this.getApplicantCompanyPartyCode(),
        },
        contactDetails: {
          name: this.userCompleteName,
          email: this.user.email,
          phone: this.companyPhoneNbr,
        },
        isToBeNotified: false,
      }
      return transportDocumentParty
    },
    getApplicantCompanyPartyCode(){
      switch (this.company) {
        case 'MAERSK':
          return this.companyCodeMaersk
        case 'CMACGM':
          return this.companyCodeCmaCgm
        case 'YANGMING':
          return this.companyCodeYangMing
      }
    },
    // Submit
    submitParties(){
      if (!this.validateParties()) return
      this.setDocumentParties(this.parties)
      if (this.electronicFlowStatus == this.equipmentsSelectedStatus) this.setStatus(this.partiesSelectedStatus)
      this.updateShippingInstructionAndSaveElectronicFlow()
      this.$emit('step', true)
    },
    validateParties(){
      if (!this.$refs.partyForm) return
      let validated = true
      this.$refs.partyForm.forEach(ref => {
        if (!ref.validateParty()) validated = false
      });
      return validated
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>
.stepper-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
}
/*.row {
  border: solid 1px red;
}
.col {
  border: solid 1px orange;
}*/
</style>
