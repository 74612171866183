<template>
  <v-card class="my-1" outlined>
    <v-row dense @click="addToRowSelection">
      <!--Invoice nbr.-->
      <v-col
        cols="2"
        class="py-0 text-caption font-weight-bold d-flex align-center"
        ><v-row dense class="ma-0 d-flex justify-center text-center">
          <v-col cols="4" class="py-0 d-flex justify-center align-center">
            <v-icon v-if="invoice.isLinked" color="success" size="x-large"
              >mdi-check-network</v-icon
            >
          </v-col>
          <v-col cols="8"
            ><ReadonlyField
              :label="$t('label.invoiceNbr')"
              :value="invoice.invoiceNbr"
          /></v-col> </v-row
      ></v-col>
      <v-divider vertical />

      <!--Invoice Recipient-->
      <v-col
        cols="3"
        class="py-0 text-caption font-weight-bold d-flex align-center"
        ><v-row dense class="ma-0 d-flex justify-center text-center">
          <ReadonlyField
            :label="$t('label.invoiceHolderName')"
            :value="invoice.invoiceHolderName"
        /></v-row>
      </v-col>
      <v-divider vertical />

      <!--Seller-->
      <v-col
        cols="2"
        class="py-0 text-caption font-weight-bold d-flex align-center"
        ><v-row dense class="ma-0 d-flex justify-center text-center">
          <ReadonlyField
            :label="$t('label.sellerName')"
            :value="invoice.sellerName"
        /></v-row>
      </v-col>
      <v-divider vertical />

      <!--Consignee-->
      <v-col
        cols="3"
        class="py-0 text-caption font-weight-bold d-flex align-center"
        ><v-row dense class="ma-0 d-flex justify-center text-center">
          <ReadonlyField
            :label="$t('label.consigneeName')"
            :value="invoice.consigneeName"
        /></v-row>
      </v-col>
      <v-divider vertical />

      <!--Total Amount-->
      <v-col
        cols="2"
        class="py-0 text-caption font-weight-bold d-flex align-center"
        ><v-row dense class="ma-0 d-flex justify-center text-center">
          <ReadonlyField
            :label="$t('label.totalAmount')"
            :value="invoice.totalAmount + ' ' + invoice.currency" /></v-row
      ></v-col>
    </v-row>
  </v-card>
</template>

<script>
import ReadonlyField from "./ReadonlyField.vue";

export default {
  data() {
    return {
      // isActive: false,
    };
  },
  props: ["invoice"],
  emits: ["invoice-document-nbr"],
  methods: {
    addToRowSelection() {
      this.invoice.isActive = !this.invoice.isActive;
      this.$emit("invoice-document-nbr", this.invoice.documentNbr);
    },
  },
  components: { ReadonlyField },
};
</script>
<style scoped>
.invoice-row:hover {
  background-color: #edf5ff;
}

.active {
  background-color: #edf5ff;
}
</style>
