<template>
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-caption primary--text text-left">
            {{ $t("label.documentNbr") }}
            <!-- <br />
                    {{ $t("label.documentDate") }} -->
          </th>
          <th class="text-caption primary--text text-left">
            {{ $t("label.customer") }}
          </th>
          <th class="text-caption primary--text text-left">
            {{ $t("label.shipper") }}
          </th>
          <th class="text-caption primary--text text-left">
            {{ $t("label.consigneeName") }}
          </th>
          <th class="text-caption primary--text text-left">
            {{ $t("label.pol") + "/" + $t("label.pod") }}
          </th>
          <th class="text-caption primary--text text-left">Doc date</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in praticas"
          :key="index"
          @click="selectShipment(item)"
          :class="[{ selected: item.isSelected }]"
        >
          <!-- Document nbr. -->
          <td class="text-caption font-weight-bold">
            {{ item.documentNbr }}
          </td>

          <!-- Customer -->
          <td class="text-caption font-weight-bold">
            <div v-if="item.clientName.length > 5">
              {{ item.clientName.substring(0, 5)
              }}<span
                v-if="!showFullText[index]"
                @click.stop="toggleShowFullText(index)"
                >...</span
              >
              <span v-if="showFullText[index]">{{ item.clientName }}</span>
            </div>
            <div v-else>
              {{ item.clientName }}
            </div>
          </td>

          <!-- Shipper -->
          <td class="text-caption font-weight-bold">
            <div v-if="item.shipperName.length > 5">
              {{ item.shipperName.substring(0, 5)
              }}<span
                v-if="!showFullText[index]"
                @click.stop="toggleShowFullText(index)"
                >...</span
              >
              <span v-if="showFullText[index]">{{ item.shipperName }}</span>
            </div>
            <div v-else>
              {{ item.shipperName }}
            </div>
          </td>

          <!-- Consignee -->
          <td class="text-caption font-weight-bold">
            <div v-if="item.consigneeName.length > 5">
              {{ item.consigneeName.substring(0, 5)
              }}<span
                v-if="!showFullText[index]"
                @click.stop="toggleShowFullText(index)"
                >...</span
              >
              <span v-if="showFullText[index]">{{ item.consigneeName }}</span>
            </div>
            <div v-else>
              {{ item.consigneeName }}
            </div>
          </td>

          <!-- Pol/Pod -->
          <td class="text-caption font-weight-bold">
            {{ item.pol + "/" + item.pod }}
          </td>

          <!-- Document Date -->
          <td class="text-caption font-weight-bold">
            {{
              new Date(item.documentDate).toLocaleDateString("en-GB", {
                day: "numeric",
                month: "numeric",
                year: "2-digit",
              })
            }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  data() {
    return {
      showFullText: [false, false, false],
    };
  },
  props: ["praticas"],
  emits: ["shipment"],
  methods: {
    toggleShowFullText(index) {
      this.$set(this.showFullText, index, !this.showFullText[index]);
    },
    selectShipment(item) {
      this.$emit("shipment", item);
    },
  },
};
</script>

<style scoped>

</style>
