var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onClickOutside,
          expression: "onClickOutside",
        },
      ],
    },
    [
      !_vm.selected
        ? _c(
            "v-btn",
            {
              staticClass: "px-2",
              attrs: { icon: "" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  _vm.selected = true
                },
              },
            },
            [
              _c("v-icon", { attrs: { color: _vm.color } }, [
                _vm._v("mdi-delete"),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.selected
        ? _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  staticClass: "px-2 mr-2",
                  attrs: { color: "success", icon: "" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-check")])],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "px-2",
                  attrs: { color: "error", icon: "" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.selected = false
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }