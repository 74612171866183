<template>
  <div class="text-center">
    <v-pagination
      v-model="page"
      :length="pageCount"
      :total-visible="10"
      class="custom"
    />
  </div>
</template>

<script>
export default {
  name: 'TableNav',
  props: ['browseCount', 'browseLimit', 'browseOffset'],
  emits: ['offset-changed', 'get-current-page'],
  data() {
    return {
      page: 1,
    }
  },
  computed: {
    pageCount() {
      return Math.ceil(this.browseCount / this.browseLimit)
    },
  },
  watch: {
    page(pageNbr) {
      this.$emit('offset-changed', (pageNbr - 1) * this.browseLimit, pageNbr)
    },
    browseOffset(offset) {
      this.page = offset / this.browseLimit + 1
    },
  },
  methods: {
    getCurrentPage() {
      this.$emit('get-current-page')
    },
  },
  mounted() {
    this.getCurrentPage()
  },
}
</script>

<style scoped>
.custom {
  width: auto;
  margin-left: auto;
}
.custom::v-deep .v-pagination__navigation {
  height: 1.5rem !important;
  width: 1.5rem !important;
  margin: 0.2rem;
}
.custom::v-deep .v-pagination__navigation .v-icon {
  font-size: 1rem !important;
}
.custom::v-deep .v-pagination__item,
.custom::v-deep .v-pagination__more {
  height: 1.5rem !important;
  min-width: 1.5rem !important;
  font-size: 0.85rem !important;
  margin: 0.2rem;
}
</style>
