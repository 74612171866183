import { authHeader } from '@/helpers/auth-header'
import { commons } from './commons'

export const foreignAgentContactService = {
  createForeignAgentContact,
  updateForeignAgentContact,
  deleteForeignAgentContact,
  getForeignAgentContactForPratica,
  getCorrispondenteEsteroDraftEmail,
  getMultipleDraftEmail
}

const resourceUrl =
  process.env.VUE_APP_GSCV_ENGINE_URL + `/foreign-agent-contact`

function getForeignAgentContactForPratica(praticaId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/pratica/${praticaId}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function createForeignAgentContact(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }
  return fetch(resourceUrl, requestOptions).then(commons.handleJsonResponse)
}

function deleteForeignAgentContact(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/${id}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function updateForeignAgentContact(id, data) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }
  return fetch(resourceUrl + `/${id}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function getCorrispondenteEsteroDraftEmail(praticaId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(
    resourceUrl + `/pratica/${praticaId}/draft-email`,
    requestOptions,
  ).then(commons.handleJsonResponse)
}

function getMultipleDraftEmail(praticheId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(
    resourceUrl + `/pratiche/draft-email?praticheId=${praticheId}`,
    requestOptions,
  ).then(commons.handleJsonResponse)
}
