import { authHeader } from '@/helpers/auth-header'
import { commons } from './commons'

export const usersService = {
  login,
  logout,
  getUser,
  browse,
  createUser,
  updateUser,
  whoAmI,
  autocompleteUsers,
  findByAzureAccountId,
  findByAzureAccountOrEmail,
  createUserFirstAccess,
}

const resourceUrl = process.env.VUE_APP_GSCV_ENGINE_URL + `/users`

function login(username, password) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  let url = `${process.env.VUE_APP_GSCV_ENGINE_URL}/auth/login?username=${username}&password=${password}`

  return fetch(url, requestOptions)
    .then(commons.handleStringResponse)
    .then((response) => {
      return response
    })
}

function logout() {
  sessionStorage.removeItem('token')
  sessionStorage.removeItem('user')
}

function findByAzureAccountId(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `?azureAccountId=${id}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function findByAzureAccountOrEmail(id, email) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(
    resourceUrl + `/check-email?azureAccountId=${id}&email=${email}`,
    requestOptions,
  ).then(commons.handleJsonResponse)
}

function getUser(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/${id}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function browse(offset, limit, filter, sortingList) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      offset: offset,
      limit: limit,
      filter: filter,
      sortingList: sortingList,
    }),
  }

  return fetch(resourceUrl + `/browse`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function createUser(user) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  }
  return fetch(resourceUrl, requestOptions).then(commons.handleJsonResponse)
}

function updateUser(id, user) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  }
  return fetch(resourceUrl + `/${id}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function whoAmI() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `whoami`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function autocompleteUsers(hint, company) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  let url = resourceUrl + `search?hint=` + hint
  if (company !== null) {
    url += '&company=' + company.id
  }
  return fetch(url, requestOptions).then(commons.handleJsonResponse)
}

function createUserFirstAccess(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  }
  return fetch(resourceUrl + `/first-access`, requestOptions).then(
    commons.handleJsonResponse,
  )
}
