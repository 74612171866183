var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "stepper-wrapper", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-container",
                {
                  staticClass: "d-table pa-1 pb-0",
                  staticStyle: { "table-layout": "fixed" },
                  attrs: { "fill-height": "", fluid: "" },
                },
                [
                  _vm.bookingCarrierReference
                    ? _c(
                        "v-row",
                        { staticClass: "d-table-row", attrs: { dense: "" } },
                        [
                          _c("v-col", [
                            _c("h6", { staticClass: "booking-header mb-1" }, [
                              _vm._v(
                                " " + _vm._s(_vm.bookingCarrierReference) + " "
                              ),
                            ]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    {
                      staticClass: "d-table-row fill-height",
                      attrs: { dense: "" },
                    },
                    [
                      _c(
                        "v-col",
                        { staticClass: "fill-height overflow-y-auto" },
                        [
                          _c(
                            "v-expansion-panels",
                            {
                              attrs: { multiple: "" },
                              model: {
                                value: _vm.panel,
                                callback: function ($$v) {
                                  _vm.panel = $$v
                                },
                                expression: "panel",
                              },
                            },
                            [
                              _c(
                                "v-expansion-panel",
                                { staticClass: "fit-content" },
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    { staticClass: "primary--text px-3 py-0" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("label.info")) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-expansion-panel-content",
                                    [
                                      _c("v-divider", { staticClass: "my-0" }),
                                      _c(
                                        "div",
                                        { staticClass: "pa-2" },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                { staticClass: "d-flex" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c("ReadonlyField", {
                                                        attrs: {
                                                          label:
                                                            _vm.$t(
                                                              "label.commodity"
                                                            ),
                                                          value:
                                                            _vm.savedCommodityLabel,
                                                          icon: "mdi-package-variant-closed",
                                                          textClass:
                                                            "text-caption pt-1",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { staticClass: "d-flex" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c("ReadonlyField", {
                                                        attrs: {
                                                          label:
                                                            _vm.$t(
                                                              "label.userLabel"
                                                            ),
                                                          value: `${
                                                            _vm
                                                              .savedForwarderParty
                                                              .partyName
                                                          } - ${_vm.formatContact(
                                                            _vm
                                                              .savedForwarderParty
                                                              .partyContactDetails
                                                          )}`,
                                                          icon: "mdi-account",
                                                          textClass:
                                                            "text-caption  pt-1",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c("ReadonlyField", {
                                                        attrs: {
                                                          label:
                                                            _vm.$t(
                                                              "label.priceOwner"
                                                            ),
                                                          value:
                                                            _vm.savedPriceOwner,
                                                          icon: "mdi-account",
                                                          textClass:
                                                            "text-caption  pt-1",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                { staticClass: "d-flex" },
                                                [
                                                  _vm.company == "MAERSK"
                                                    ? _c(
                                                        "v-col",
                                                        { staticClass: "pa-0" },
                                                        [
                                                          _c("ReadonlyField", {
                                                            attrs: {
                                                              label: _vm.$t(
                                                                "label.bookingOffice"
                                                              ),
                                                              value:
                                                                _vm.savedBookingOffice,
                                                              icon: "mdi-warehouse",
                                                              textClass:
                                                                "text-caption  pt-1",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { staticClass: "d-flex" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c("ReadonlyField", {
                                                        attrs: {
                                                          label:
                                                            _vm.$t(
                                                              "label.serviceName"
                                                            ),
                                                          value:
                                                            _vm.savedServiceName,
                                                          icon: "mdi-hand-extended-outline",
                                                          textClass:
                                                            "text-caption  pt-1",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c("ReadonlyField", {
                                                        attrs: {
                                                          label:
                                                            _vm.$t(
                                                              "label.contract"
                                                            ),
                                                          value:
                                                            _vm.savedContract,
                                                          icon: "mdi-file-document-outline",
                                                          textClass:
                                                            "text-caption  pt-1",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "d-flex",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c("ReadonlyField", {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "label.bookingRemarks"
                                                          ),
                                                          value:
                                                            _vm.savedBookingRemarks,
                                                          icon: "mdi-note-edit-outline",
                                                          textClass:
                                                            "text-caption  pt-1",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "d-flex",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c("ReadonlyField", {
                                                        attrs: {
                                                          label:
                                                            _vm.$t(
                                                              "label.vgmFilingBy"
                                                            ),
                                                          value:
                                                            _vm.savedVgmFilingBy,
                                                          icon: "mdi-scale-balance",
                                                          textClass:
                                                            "text-caption  pt-1",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.savedPolHaulageLocation ||
                              _vm.savedPodHaulageLocation
                                ? _c(
                                    "v-expansion-panel",
                                    { staticClass: "fit-content" },
                                    [
                                      _c(
                                        "v-expansion-panel-header",
                                        {
                                          staticClass:
                                            "primary--text px-3 py-0",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("label.transport")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-expansion-panel-content",
                                        [
                                          _c("v-divider", {
                                            staticClass: "my-0",
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "d-flex pa-1" },
                                            [
                                              _c(
                                                "v-col",
                                                { staticClass: "pa-1" },
                                                [
                                                  _vm.savedPolHaulageLocation
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              attrs: {
                                                                dense: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "pa-0",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-subheader",
                                                                    {
                                                                      staticClass:
                                                                        "pa-2 pb-0 primary--text text-caption font-weight-bold",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "label.placeOfReceipt"
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-row",
                                                            {
                                                              attrs: {
                                                                dense: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "d-flex",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "pa-0",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "ReadonlyField",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                _vm.$t(
                                                                                  "label.address"
                                                                                ),
                                                                              value: `${_vm.formatAddress(
                                                                                _vm.savedPolHaulageLocation
                                                                              )} <br> ${
                                                                                _vm.savedPolHaulageCompany
                                                                              }`,
                                                                              icon: "mdi-truck",
                                                                              textClass:
                                                                                "text-caption  pt-1",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "pa-0",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "ReadonlyField",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                _vm.$t(
                                                                                  "label.pickupContact"
                                                                                ),
                                                                              value:
                                                                                _vm.formatContact(
                                                                                  _vm.savedPolHaulageContact
                                                                                ),
                                                                              icon: "mdi-account",
                                                                              textClass:
                                                                                "text-caption  pt-1",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-row",
                                                            {
                                                              attrs: {
                                                                dense: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "d-flex",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "pa-0",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "ReadonlyField",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                _vm.$t(
                                                                                  "label.pickupDateFrom"
                                                                                ),
                                                                              value:
                                                                                _vm.formatDate(
                                                                                  _vm.savedExportRemarksPickupDateFrom
                                                                                ),
                                                                              icon: "mdi-calendar",
                                                                              textClass:
                                                                                "text-caption  pt-1",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "pa-0",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "ReadonlyField",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                _vm.$t(
                                                                                  "label.pickupDateTo"
                                                                                ),
                                                                              value:
                                                                                _vm.formatDate(
                                                                                  _vm.savedExportRemarksPickupDateTo
                                                                                ),
                                                                              icon: "mdi-calendar",
                                                                              textClass:
                                                                                "text-caption  pt-1",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-row",
                                                            {
                                                              attrs: {
                                                                dense: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "d-flex",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "pa-0",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "ReadonlyField",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                _vm.$t(
                                                                                  "label.placeOfReceiptRemarks"
                                                                                ),
                                                                              value:
                                                                                _vm.savedPolHaulageRemarks,
                                                                              icon: "mdi-note-edit-outline",
                                                                              textClass:
                                                                                "text-caption  pt-1",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "pa-0",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "ReadonlyField",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                _vm.$t(
                                                                                  "label.loadRef"
                                                                                ),
                                                                              value:
                                                                                _vm.savedPolHaulageLoadRef,
                                                                              icon: "mdi-file-document-outline",
                                                                              textClass:
                                                                                "text-caption  pt-1",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _vm.savedPolHaulageLocation &&
                                              _vm.savedPodHaulageLocation
                                                ? _c("v-divider", {
                                                    staticClass: "ma-1",
                                                    attrs: { vertical: "" },
                                                  })
                                                : _vm._e(),
                                              _c(
                                                "v-col",
                                                { staticClass: "pa-1" },
                                                [
                                                  _vm.savedPodHaulageLocation
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              attrs: {
                                                                dense: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "pa-0",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-subheader",
                                                                    {
                                                                      staticClass:
                                                                        "pa-2 pb-0 primary--text text-caption font-weight-bold",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "label.placeOfDelivery"
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-row",
                                                            {
                                                              attrs: {
                                                                dense: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "d-flex",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "pa-0",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "ReadonlyField",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                _vm.$t(
                                                                                  "label.address"
                                                                                ),
                                                                              value: `${_vm.formatAddress(
                                                                                _vm.savedPodHaulageLocation
                                                                              )} <br> ${
                                                                                _vm.savedPodHaulageCompany
                                                                              }`,
                                                                              icon: "mdi-truck",
                                                                              textClass:
                                                                                "text-caption  pt-1",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "pa-0",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "ReadonlyField",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                _vm.$t(
                                                                                  "label.deliveryContact"
                                                                                ),
                                                                              value:
                                                                                _vm.formatContact(
                                                                                  _vm.savedPodHaulageContact
                                                                                ),
                                                                              icon: "mdi-account",
                                                                              textClass:
                                                                                "text-caption  pt-1",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-row",
                                                            {
                                                              attrs: {
                                                                dense: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "d-flex",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "pa-0",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "ReadonlyField",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                _vm.$t(
                                                                                  "label.deliveryDateFrom"
                                                                                ),
                                                                              value:
                                                                                _vm.formatDate(
                                                                                  _vm.savedImportRemarksDeliveryDateFrom
                                                                                ),
                                                                              icon: "mdi-calendar",
                                                                              textClass:
                                                                                "text-caption  pt-1",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "pa-0",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "ReadonlyField",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                _vm.$t(
                                                                                  "label.deliveryDateTo"
                                                                                ),
                                                                              value:
                                                                                _vm.formatDate(
                                                                                  _vm.savedImportRemarksDeliveryDateTo
                                                                                ),
                                                                              icon: "mdi-calendar",
                                                                              textClass:
                                                                                "text-caption  pt-1",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-row",
                                                            {
                                                              attrs: {
                                                                dense: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "d-flex",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "pa-0",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "ReadonlyField",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                _vm.$t(
                                                                                  "label.placeOfDeliveryRemarks"
                                                                                ),
                                                                              value:
                                                                                _vm.savedPodHaulageRemarks,
                                                                              icon: "mdi-note-edit-outline",
                                                                              textClass:
                                                                                "text-caption  pt-1",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "pa-0",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "ReadonlyField",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                _vm.$t(
                                                                                  "label.loadRef"
                                                                                ),
                                                                              value:
                                                                                _vm.savedPodHaulageLoadRef,
                                                                              icon: "mdi-file-document-outline",
                                                                              textClass:
                                                                                "text-caption  pt-1",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-expansion-panel",
                                { staticClass: "fit-content" },
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    { staticClass: "primary--text px-3 py-0" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("label.voyage")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-expansion-panel-content",
                                    [
                                      _c("v-divider", { staticClass: "my-0" }),
                                      _c(
                                        "div",
                                        { staticClass: "pa-2" },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                { staticClass: "d-flex" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c("ReadonlyField", {
                                                        attrs: {
                                                          label:
                                                            _vm.$t("label.pol"),
                                                          value:
                                                            _vm.getFacilityLocationLabel(
                                                              _vm.pol
                                                            ),
                                                          icon: "mdi-map-marker",
                                                          textClass:
                                                            "text-caption  pt-1",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c("ReadonlyField", {
                                                        attrs: {
                                                          label:
                                                            _vm.$t("label.pod"),
                                                          value:
                                                            _vm.getFacilityLocationLabel(
                                                              _vm.pod
                                                            ),
                                                          icon: "mdi-map-marker",
                                                          textClass:
                                                            "text-caption  pt-1",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("v-col", {
                                                staticClass: "d-flex",
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                { staticClass: "d-flex" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c("ReadonlyField", {
                                                        attrs: {
                                                          label:
                                                            _vm.$t(
                                                              "label.departure"
                                                            ),
                                                          value:
                                                            _vm.savedDepartureDate,
                                                          icon: "mdi-calendar",
                                                          textClass:
                                                            "text-caption  pt-1",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c("ReadonlyField", {
                                                        attrs: {
                                                          label:
                                                            _vm.$t(
                                                              "label.arrival"
                                                            ),
                                                          value:
                                                            _vm.savedArrivalDate,
                                                          icon: "mdi-calendar",
                                                          textClass:
                                                            "text-caption  pt-1",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { staticClass: "d-flex" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c("ReadonlyField", {
                                                        attrs: {
                                                          label:
                                                            _vm.$t(
                                                              "label.vessel"
                                                            ),
                                                          value:
                                                            _vm.savedMainVessel,
                                                          icon: "mdi-ferry",
                                                          textClass:
                                                            "text-caption  pt-1",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c("ReadonlyField", {
                                                        attrs: {
                                                          label:
                                                            _vm.$t(
                                                              "label.voyageNbr"
                                                            ),
                                                          value:
                                                            _vm.savedVoyageNbr,
                                                          icon: "mdi-ticket-outline",
                                                          textClass:
                                                            "text-caption  pt-1",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-expansion-panel",
                                { staticClass: "fit-content" },
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    { staticClass: "primary--text px-3 py-0" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("label.equipments")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-expansion-panel-content",
                                    [
                                      _c("v-divider", { staticClass: "my-0" }),
                                      _c(
                                        "div",
                                        { staticClass: "pa-2" },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                { staticClass: "px-2 py-0" },
                                                [
                                                  _c("v-data-table", {
                                                    staticClass: "pa-0",
                                                    attrs: {
                                                      headers:
                                                        _vm.equipmentsHeaders,
                                                      items:
                                                        _vm.savedEquipments,
                                                      "items-per-page": 1000,
                                                      "hide-default-footer": "",
                                                      dense: "",
                                                    },
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "item",
                                                        fn: function ({
                                                          item,
                                                          index,
                                                        }) {
                                                          return [
                                                            _c("tr", [
                                                              _c(
                                                                "td",
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "mr-1",
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " mdi-train-car-container "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "text-caption",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            item.code
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c("td", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-caption",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item
                                                                            .requestedEquipments
                                                                            .ISOEquipmentCode
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                              _c("td", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-caption",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item
                                                                            .requestedEquipments
                                                                            .units
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                              _c("td", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-caption",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item
                                                                            .requestedEquipments
                                                                            .equipmentWeight
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                              _c("td", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-caption",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item
                                                                            .requestedEquipments
                                                                            .equipmentVolume
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]),
                                                            index ==
                                                            _vm.savedEquipments
                                                              .length -
                                                              1
                                                              ? _c("tr", [
                                                                  _c("td", [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "text-caption font-weight-medium",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "label.total"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _c("td"),
                                                                  _c("td"),
                                                                  _c("td", [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "text-caption font-weight-medium",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.totEquipmentsWeight
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _vm.totEquipmentsVolume
                                                                    ? _c("td", [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "text-caption font-weight-medium",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.totEquipmentsVolume
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ])
                                                                    : _vm._e(),
                                                                ])
                                                              : _vm._e(),
                                                          ]
                                                        },
                                                      },
                                                    ]),
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "flex-grow-0", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "d-flex pa-3" },
            [
              _c(
                "v-btn",
                {
                  attrs: { small: "", icon: "", color: "primary" },
                  on: { click: _vm.exitRequest },
                },
                [
                  _c("v-icon", { attrs: { size: "30" } }, [
                    _vm._v("mdi-arrow-left-circle"),
                  ]),
                ],
                1
              ),
              _c("v-spacer"),
              _vm.editableSelectedCommodity
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("step", false)
                        },
                      },
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-step-backward")]),
                      _c("span", [_vm._v(_vm._s(_vm.$t("label.back")))]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editableSelectedCommodity
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ml-2",
                      attrs: { small: "", color: "primary" },
                      on: { click: _vm.submitBookingRequest },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("label.confirm")))])]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }