var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "" } },
    [
      _c(
        "v-app-bar",
        {
          staticStyle: { "border-bottom": "5px solid #64a70b !important" },
          attrs: {
            app: "",
            "clipped-left": "",
            color: _vm.isProduction ? "primary" : "rossoSacmi",
          },
        },
        [
          _c(
            "v-toolbar-title",
            { staticClass: "text-caption pa-0" },
            [
              !_vm.isDemo && _vm.isProduction
                ? _c("v-img", {
                    attrs: {
                      src: require("@/assets/logo-italiansped.png"),
                      "max-width": "150",
                      contain: "",
                    },
                  })
                : _vm._e(),
              !_vm.isDemo && !_vm.isProduction
                ? _c("v-img", {
                    attrs: {
                      src: require("@/assets/italiansped-logo.png"),
                      "max-width": "150",
                      contain: "",
                    },
                  })
                : _vm._e(),
              _vm.isDemo
                ? _c("v-img", {
                    attrs: {
                      src: require("@/assets/logo-cirle-white.png"),
                      "max-width": "100",
                      contain: "",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-main",
        { staticClass: "fill-height" },
        [_c("PraticaExternalTracking", { attrs: { uuid: _vm.uuid } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }