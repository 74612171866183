var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        transition: "scale-transition",
        "min-width": "auto",
        "offset-x": _vm.offset == "x",
        "offset-y": _vm.offset == "y",
        top: "",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-text-field",
                _vm._g(
                  {
                    class: _vm.inputClass,
                    attrs: {
                      label: _vm.label,
                      value: _vm.selectedDate,
                      "append-icon": "mdi-calendar",
                      outlined: _vm.appearance.includes("outlined"),
                      solo: _vm.appearance.includes("solo"),
                      flat: _vm.appearance.includes("flat"),
                      clearable: !_vm.disabled,
                      "hide-details": _vm.hideDetails,
                      rules: _vm.rules,
                      readonly: "",
                      dense: "",
                    },
                    on: {
                      "click:append": function ($event) {
                        _vm.disabled
                          ? null
                          : (_vm.datePickerMenu = !_vm.datePickerMenu)
                      },
                    },
                  },
                  _vm.disabled ? null : on
                )
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.datePickerMenu,
        callback: function ($$v) {
          _vm.datePickerMenu = $$v
        },
        expression: "datePickerMenu",
      },
    },
    [
      _c("v-date-picker", {
        attrs: { color: "primary", readonly: _vm.disabled },
        on: { input: _vm.select },
        model: {
          value: _vm.date,
          callback: function ($$v) {
            _vm.date = $$v
          },
          expression: "date",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }