<template>
  <v-container fill-height fluid class="d-block pa-0">
    <v-row dense>
      <v-col cols="6" class="py-0">
        <InvoiceFilter
          ref="invoicesFilterSection"
          @set-filter="filter"
        ></InvoiceFilter>
        <v-data-table
          :headers="[]"
          :items="invoiceSearch.browseResponse.resultList"
          :items-per-page="invoiceSearch.itemsPerPage"
          hide-default-footer
          class="cards-table"
        >
          <template v-slot:item="{ item }">
            <InvoicePreview
              :invoice="item"
              class="invoice"
              @invoice-document-nbr="selectInvoice"
            />
          </template>
          <template v-slot:footer>
            <v-row dense class="ma-0">
              <v-col class="pa-0">
                <TableNav
                  ref="tableNavInvoices"
                  :browse-count="invoiceSearch.browseResponse.totalCount"
                  :browse-limit="invoiceSearch.browseRequest.limit"
                  :browse-offset="invoiceSearch.browseRequest.offset"
                  @offset-changed="browseRequestOffsetChangedInvoiceSearch"
                  @get-current-page="setCurrentPageInvoiceSearch"
                />
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="6" class="py-0">
        <PraticheFilter
          ref="praticheFilterSection"
          @set-filter="filter"
        ></PraticheFilter>
        <v-data-table
          :headers="[]"
          :items="praticheSearch.browseResponse.resultList"
          :items-per-page="praticheSearch.itemsPerPage"
          hide-default-footer
          class="cards-table"
        >
          <template v-slot:item="{ item }">
            <PratichePreview
              :pratiche="item"
              :selected-invoice-document-nbr="
                invoiceSearch.selectedInvoiceDocumentNbr
              "
              @document-nbr="matchPraticaWithInvoice"
            />
          </template>
          <template v-slot:footer>
            <v-row dense class="ma-0">
              <v-col class="pa-0">
                <TableNav
                  ref="tableNavPratiche"
                  :browse-count="praticheSearch.browseResponse.totalCount"
                  :browse-limit="praticheSearch.browseRequest.limit"
                  :browse-offset="praticheSearch.browseRequest.offset"
                  @offset-changed="browseRequestOffsetChangedPraticheSearch"
                  @get-current-page="setCurrentPagePraticheSearch"
                />
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" timeout="2000" :color="color">
      <v-icon color="white" class="mr-2">
        {{ isSuccess ? "mdi-check-circle" : "mdi-alert-circle" }}
      </v-icon>
      <span class="white--text">{{ message }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar = false">
          <v-icon color="white"> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { invoicesService } from "../../services/invoices.service";
import { shipmentLinkService } from "../../services/shipmentLink.service";
import { praticheService } from "../../services/pratiche.service";
import TableNav from "../elements/TableNav.vue";
import InvoicePreview from "../elements/InvoicePreview.vue";
import PratichePreview from "../elements/PratichePreview.vue";
import InvoiceFilter from "../ShipmentsPage/InvoiceFilter.vue";
import PraticheFilter from "../ShipmentsPage/PraticheFilter.vue";

export default {
  data() {
    return {
      invoiceSearch: {
        browseRequest: {
          offset: 0,
          limit: 10,
          filter: {},
          sortingList: [],
        },

        browseResponse: {
          resultList: [],
          totalCount: 0,
        },

        currentPage: 1,
        itemsPerPage: 10,

        selectedInvoiceDocumentNbr: "",
        linkedShipmentsLength: 0,
      },

      praticheSearch: {
        browseRequest: {
          offset: 0,
          limit: 10,
          filter: {},
          sortingList: [],
        },

        browseResponse: {
          resultList: [],
          totalCount: 0,
        },

        currentPage: 1,
        itemsPerPage: 10,

        documentNbrsNotIn: [],
      },

      message: "",
      snackbar: false,
      isSuccess: true,
      color: "success",

      load: true,
    };
  },
  computed: {
    ...mapState("account", ["user", "internalVisibilityAuth"]),
    ...mapState("account", [
      "user",
      "internalVisibilityAuth",
      "userAuthorizations",
      "filtersAreOpen",
    ]),
  },
  watch: {
    "invoiceSearch.browseRequest.offset"() {
      this.browse(
        invoicesService,
        this.invoiceSearch.browseRequest,
        this.invoiceSearch.browseResponse
      );
    },
    "invoiceSearch.browseRequest.filter"() {
      this.browse(
        invoicesService,
        this.invoiceSearch.browseRequest,
        this.invoiceSearch.browseResponse
      );
    },
    "praticheSearch.browseRequest.offset"() {
      this.browse(
        praticheService,
        this.praticheSearch.browseRequest,
        this.praticheSearch.browseResponse
      );
    },
    "praticheSearch.browseRequest.filter"() {
      this.browse(
        praticheService,
        this.praticheSearch.browseRequest,
        this.praticheSearch.browseResponse
      );
    },
  },
  methods: {
    browse(service, browseRequest, browseResponse) {
      service
        .browse(
          browseRequest.offset,
          browseRequest.limit,
          browseRequest.filter,
          browseRequest.sortingList
        )
        .then((resp) => {
          if (service === praticheService) {
            browseResponse.resultList.splice(
              this.invoiceSearch.linkedShipmentsLength
            );
            if (browseRequest.offset > 0) {
              browseResponse.resultList = [];
            }
            resp.resultList.forEach((shipment) => {
              shipment.existsMatch = false;
              browseResponse.resultList.push(shipment);
            });
            // if (this.load) {
            //   browseResponse.totalCount += resp.totalCount;
            //   this.load = false;
            // }
            browseResponse.totalCount =
              this.invoiceSearch.linkedShipmentsLength + resp.totalCount;
          } else {
            browseResponse.resultList = [];
            resp.resultList.forEach((invoice) => {
              invoice.isActive =
                invoice.documentNbr ===
                this.invoiceSearch.selectedInvoiceDocumentNbr;
              browseResponse.resultList.push(invoice);
            });
            browseResponse.totalCount = resp.totalCount;
          }
        });
    },
    setCurrentPageInvoiceSearch() {
      this.$refs.tableNavInvoices.page = this.invoiceSearch.currentPage;
    },
    setCurrentPagePraticheSearch() {
      this.$refs.tableNavPratiche.page = this.praticheSearch.currentPage;
    },
    browseRequestOffsetChangedInvoiceSearch(newOffset, page) {
      this.invoiceSearch.browseRequest.offset = newOffset;
      this.invoiceSearch.currentPage = page;
    },
    browseRequestOffsetChangedPraticheSearch(newOffset, page) {
      this.praticheSearch.browseRequest.offset = newOffset;
      this.praticheSearch.currentPage = page;
    },
    filter(browseFilter, context) {
      const documentNbrsNotIn =
        this.praticheSearch.browseRequest.filter.documentNbrsNotIn;
      switch (context) {
        case "InvoiceFilter":
          this.invoiceSearch.browseRequest.filter = browseFilter;
          this.invoiceSearch.currentPage != 1
            ? this.resetBrowseRequestOffset(this.invoiceSearch.browseRequest)
            : this.resetBrowseRequestOffsetAndBrowse(
                invoicesService,
                this.invoiceSearch.browseRequest,
                this.invoiceSearch.browseResponse
              );
          break;
        case "PraticheFilter":
          this.praticheSearch.browseRequest.filter = browseFilter;
          this.praticheSearch.browseRequest.filter.documentNbrsNotIn =
            documentNbrsNotIn;
          this.praticheSearch.currentPage != 1
            ? this.resetBrowseRequestOffset(this.praticheSearch.browseRequest)
            : this.resetBrowseRequestOffsetAndBrowse(
                praticheService,
                this.praticheSearch.browseRequest,
                this.praticheSearch.browseResponse
              );
      }
    },
    resetBrowseRequestOffset(browseRequest) {
      browseRequest.offset = 0;
    },
    resetBrowseRequestOffsetAndBrowse(service, browseRequest, browseResponse) {
      this.resetBrowseRequestOffset(browseRequest);
      this.browse(service, browseRequest, browseResponse);
    },
    isEmpty(object) {
      return Object.keys(object).length === 0;
    },

    selectInvoice(invoiceDocumentNbr) {
      if (this.invoiceSearch.selectedInvoiceDocumentNbr != invoiceDocumentNbr) {
        this.invoiceSearch.selectedInvoiceDocumentNbr = invoiceDocumentNbr;
        this.clearPreviouslySelectedInvoice(invoiceDocumentNbr);
        this.loadPraticheByDocumentNbr(invoiceDocumentNbr);
      } else {
        this.clearSelectedInvoiceRow();
      }
    },
    clearPreviouslySelectedInvoice(invoiceDocumentNbr) {
      this.invoiceSearch.browseResponse.resultList.forEach((obj) => {
        obj.isActive = obj.documentNbr == invoiceDocumentNbr;
      });
    },
    loadPraticheByDocumentNbr(invoiceDocumentNbr) {
      shipmentLinkService
        .browse(invoiceDocumentNbr)
        .then((shipmentDocumentNbrsList) => {
          this.invoiceSearch.linkedShipmentsLength =
            shipmentDocumentNbrsList.length;
          let documentNbrs = "";
          shipmentDocumentNbrsList.forEach((documentNbr) => {
            documentNbrs += documentNbr + ",";
          });
          const browseFilter = {
            documentNbrs: documentNbrs,
          };
          this.praticheSearch.browseResponse.resultList = [];
          // this.praticheSearch.browseResponse.totalCount = 0;
          praticheService
            .browse(0, shipmentDocumentNbrsList.length, browseFilter, [])
            .then((resp) => {
              if (resp) {
                resp.resultList.forEach((pratica) => {
                  pratica.existsMatch = true;
                  this.praticheSearch.browseResponse.resultList.push(pratica);
                });
                // this.praticheSearch.browseResponse.totalCount = resp.totalCount;
              }
            });

          this.praticheSearch.browseRequest.filter.documentNbrsNotIn =
            documentNbrs;
          this.browse(
            praticheService,
            this.praticheSearch.browseRequest,
            this.praticheSearch.browseResponse
          );
        });
    },

    matchPraticaWithInvoice(shipmentDocumentNbr, bound) {
      if (!this.invoiceSearch.selectedInvoiceDocumentNbr) {
        this.openSnackbar("No invoice selected!", false, "error");
      } else {
        if (bound === true) {
          shipmentLinkService
            .createShipmentLink(
              this.invoiceSearch.selectedInvoiceDocumentNbr,
              shipmentDocumentNbr
            )
            .then((response) => {
              if (response.status == 200) {
                this.openSnackbar(
                  "Invoice matched with shipment",
                  true,
                  "success"
                );
              } else {
                this.openSnackbar("Invoice cannot be matched", false, "error");
              }
            });
        } else {
          shipmentLinkService
            .removeShipmentLink(
              this.invoiceSearch.selectedInvoiceDocumentNbr,
              shipmentDocumentNbr
            )
            .then((response) => {
              if (response.status == 200) {
                this.openSnackbar("Invoice unmatched", true, "warning");
              } else {
                this.openSnackbar(
                  "Invoice cannot be unmatched",
                  false,
                  "error"
                );
              }
            });
        }
      }
    },
    openSnackbar(msg, resp, color) {
      this.isSuccess = resp;
      this.message = msg;
      this.snackbar = true;
      this.color = color;
    },

    clearSelectedInvoiceRow() {
      this.invoiceSearch.selectedInvoiceDocumentNbr = "";
      this.invoiceSearch.linkedShipmentsLength = 0;

      delete this.praticheSearch.browseRequest.filter.documentNbrsNotIn;

      this.praticheSearch.browseResponse.resultList = [];
      this.praticheSearch.browseResponse.totalCount = 0;

      this.browse(
        praticheService,
        this.praticheSearch.browseRequest,
        this.praticheSearch.browseResponse
      );
    },
  },
  components: {
    TableNav,
    InvoiceFilter,
    InvoicePreview,
    PraticheFilter,
    PratichePreview,
  },
  created() {
    this.invoiceSearch.browseRequest.sortingList = { documentDate: "DESC" };

    this.browse(
      invoicesService,
      this.invoiceSearch.browseRequest,
      this.invoiceSearch.browseResponse
    );

    this.browse(
      praticheService,
      this.praticheSearch.browseRequest,
      this.praticheSearch.browseResponse
    );
  },
};
</script>

<style scoped>
.cards-table {
  background-color: var(--v-primary-lighten2);
}
</style>
