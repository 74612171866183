<template>
  <v-container class="stepper-wrapper" fluid>
    <v-row class="ma-0 fill-height">
      <v-col class="d-table fill-height pa-2 pb-0">
        <!-- <v-container class="d-table-row" fill-height fluid> -->
          <!-- <v-form class="d-block pa-2 overflow-y-auto fill-height"> -->
            <v-row dense class="d-table-header-group pb-0 pt-1">
              <v-col style="max-width: 20rem">
                <v-file-input
                  v-model="importedExcel"
                  @change="(file) => {resetEditedItem(); uploadExcelToJson(file)}"
                  @click="importedExcel = null"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  class="xs-input ma-0 pa-0"
                  :clearable="false"
                  hide-details
                  outlined
                />
              </v-col>
              <v-col class="px-5">
                <v-form ref="commodity" width="100%">
                  <v-autocomplete
                    :label="$t('label.commodity')" 
                    v-model="commoditySelected"
                    item-text="commodityName"
                    :filter="filterCommodities"
                    :items="commodities"
                    :menu-props="menuProps"
                    :rules="required"
                    validate-on-blur
                    @update:search-input="getBookingRequestCommodities"
                    @click.stop
                    class="xs-input"
                    return-object
                    outlined
                    attach
                    dense
                  >
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ data.item.commodityName }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ data.item.commodityCode }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-form>
              </v-col>
              <v-col class="d-flex">
               <v-spacer />
                <v-btn x-small class="success ml-2" @click="confirmCopyCommodityDesc" v-if="selectedRows.length">
                  <!-- <v-icon color="success" large >mdi-check-circle </v-icon> -->
                  {{ $t('label.copy') }}
                </v-btn> 
                <v-btn @click="getTrackingEquipments" class="ml-2 primary" x-small>
                  {{ $t('label.loadEquipments') }}
                </v-btn>
                <v-btn x-small class="ml-2 primary" @click="downloadXlsxFromJson(loadedEquipments)">
                  {{ $t('label.downloadExcel') }}
                </v-btn>
                <v-btn download :href="downloadPath" class="ml-2 primary" x-small>
                  {{ $t('label.downloadTemplate') }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row dense class="d-table-row fill-height">
              <v-col class="fill-height overflow-y-auto">
                <v-progress-linear v-if="loading" indeterminate />
                <v-data-table
                  v-else
                  :headers="equipmentsHeaders"
                  :items="loadedEquipments"
                  :items-per-page="1000"
                  :key="updateTable"
                  @update:sort-by="handleClickOutsideTable"
                  hide-default-footer
                  class="pa-0"
                  dense
                >
                <!-- v-click-outside="handleClickOutsideTable" -->
                  <template v-slot:header.actions>
                    <div class="actions">
                      <v-icon color="primary" @click="addNewEquipment">mdi-plus-circle</v-icon>
                    </div>
                  </template>
                  <template v-slot:header.copy>
                    <div class="d-flex justify-center">
                      <v-simple-checkbox v-model="allSelected" @input="selectAll" color="primary" :ripple="false" style="max-width: fit-content" v-if="copyItemIndex != null"/>
                    </div>
                  </template>
                  <template v-slot:item="{ item, index, headers }">
                    <tr :class="isEditable(index)">
                      <td v-for="(header, i) in headers" :key="i" class="px-1">
                        <!-- <div v-if="header.class == 'selection'" class="d-flex justify-start">
                          <v-simple-checkbox :value="selectedRows.includes(index)" @input="selectRow(item)" color="primary" :ripple="false" />
                        </div> -->
                        <div v-if="header.class == 'text'">
                        <!-- v-if="checkIsDefaultHeader(header)" -->
                          <v-text-field
                            v-model="item[header.value]"
                            class="ma-0 pa-0 text-caption"
                            :disabled="index !== editedItemIndex"
                            :clearable="index == editedItemIndex"
                            hide-details
                            single-line
                            dense
                            solo
                            flat
                          />
                          <!-- v-if="index === editedItemIndex" -->
                          <!-- <span v-else class="one-line">{{ item[header.value] }}</span> -->
                        </div>
                        <div v-if="header.class == 'long-text'" class="d-flex">
                          <div style="width: 100%">
                            <v-menu
                              offset-y
                              close-on-click
                              :close-on-content-click="false"
                              :open-on-click="isEditable(index) && item[header.value] && item[header.value].length > 10 ? true : false"
                            >
                            <!-- v-if="index === editedItemIndex" -->
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="item[header.value]"
                                  v-on="on"
                                  class="ma-0 pa-0 text-caption"
                                  :disabled="index !== editedItemIndex"
                                  :clearable="index == editedItemIndex"
                                  hide-details
                                  single-line
                                  dense
                                  solo
                                  flat
                                />
                              </template>
                              <v-card class="dropdown-menu">
                                <v-textarea
                                  v-model="item[header.value]"
                                  maxlength="100"
                                  class="text-caption"
                                  hide-details
                                  auto-grow
                                  x-small
                                  solo
                                />
                              </v-card>
                            </v-menu>
                            <!-- <span v-else class="me-auto one-line">{{ item[header.value] }}</span> -->
                          </div>
                         
                          <!-- <div class="d-flex">
                            <div v-if="header.value == 'commodityDesc'" class="ml-2">
                              <div v-if="copyItemIndex == null">
                                <v-icon small v-if="copyItemIndex != loadedEquipments.indexOf(item)" @click="prepareCopyCommodityDesc(item)" class="pr-1">
                                  mdi-content-copy
                                </v-icon>
                              </div> 
                              <div class="pa-0" v-else> -->
                                <!-- <v-icon small v-if="copyItemIndex != loadedEquipments.indexOf(item)" 
                                  @click="selectRow(item)"
                                  :color="selectedRows.includes(index) ? 'success' : 'primary'"
                                  class="pr-1">
                                    mdi-content-copy
                                </v-icon> -->
                                <!-- <v-simple-checkbox :value="selectedRows.includes(index)" @input="selectRow(item)" color="primary" :ripple="false" v-if="copyItemIndex != loadedEquipments.indexOf(item)"/>
                                <div v-else class="d-flex"> -->
                                  <!-- <v-icon color="success" v-if="selectedRows.length" @click="confirmCopyCommodityDesc(item)">mdi-check</v-icon> -->
                                  <!-- <v-icon color="error" @click="abortCopyCommodityDesc">mdi-close</v-icon>                                
                                </div>
                              </div>
                            </div>
                          </div> -->
                        </div>
                        <div v-if="header.class == 'actions'" class="d-flex justify-center">
                          <div v-if="!isEditable(index)" class="d-flex">
                            <v-icon :color="isEditable(index) ? 'primary' : 'grey'" small @click="editEquipment(item, index); abortCopyCommodityDesc()">
                              mdi-pencil
                            </v-icon>
                            <v-icon :color="isEditable(index) ? 'primary' : 'grey'" @click="prepareDeleteEquipment(index)" small>
                              mdi-delete
                            </v-icon>
                          </div>
                          <div v-else class="d-flex">
                            <v-icon @click="editEquipment(item, index); abortCopyCommodityDesc(); updateUOMs(item)" color="success"> mdi-check </v-icon>
                            <v-icon @click="restoreOldValues(item, index)" color="error"> mdi-close </v-icon>
                          </div>
                        </div>
                        <div v-if="header.class == 'copy'" class="d-flex justify-center">
                          <div v-if="copyItemIndex == null">
                            <v-icon small @click="prepareCopyCommodityDesc(item)">
                              mdi-content-copy
                            </v-icon>
                          </div>
                          <div class="pa-0" v-else> 
                                <!-- <v-icon small v-if="copyItemIndex != loadedEquipments.indexOf(item)" 
                                  @click="selectRow(item)"
                                  :color="selectedRows.includes(index) ? 'success' : 'primary'"
                                  class="pr-1">
                                    mdi-content-copy
                                </v-icon> -->
                            <v-simple-checkbox :value="selectedRows.includes(index)" @input="allSelected = false; selectRow(item)" color="primary" :ripple="false" v-if="copyItemIndex != loadedEquipments.indexOf(item)"/>
                            <div v-else class="d-flex">
                              <!-- <v-icon color="success" v-if="selectedRows.length" @click="confirmCopyCommodityDesc(item)">mdi-check</v-icon> -->
                                <v-icon color="error" @click="abortCopyCommodityDesc">mdi-close</v-icon>                                
                            </div>
                          </div>
                        </div>
                        <div v-if="header.class == 'select'" class="d-flex justify-center">
                          <v-select
                            v-model="item[header.value]"
                            class="ma-0 pa-0 text-caption"
                            :items="getSelectItems(header.value)"
                            :disabled="index !== editedItemIndex"
                            :clearable="index == editedItemIndex"
                            hide-details
                            single-line
                            dense
                            solo
                            flat
                          />
                        </div>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <ConfirmDialog
              :open="confirmDelete" 
              :message="confirmDeleteMsg"
              @open-dialog="openConfirmDeleteDialog"
              @cancel="confirmDelete = false"
              @confirm="deleteEquipment(deleteItemIndex)"
            />
          <!-- </v-form> -->
        <!-- </v-container> -->
      </v-col>
    </v-row>
    <v-row class="flex-grow-0 ma-0">
      <v-col class="d-flex pa-2">
        <v-btn small icon color="primary" @click="exitRequest">
          <v-icon size="30">mdi-arrow-left-circle</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn small color="primary" class="mr-2" @click="$emit('step', false)">
          <v-icon>mdi-step-backward</v-icon>
          <span>{{ $t('label.back') }}</span>
        </v-btn>
        <v-btn small color="primary" @click="submitEquipments" :disabled="loading">
          <span>{{ $t('label.next') }}</span>
          <v-icon>mdi-play</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as XLSX from 'xlsx'
import ShippingInstruction from '@/mixins/ShippingInstruction.js'
import { praticheService } from '@/services/pratiche.service.js'
import ConfirmDialog from '@/components/elements/ConfirmDialog'

export default {
  name: 'ShippingInstructionEquipments',
  props: [],
  mixins: [ShippingInstruction],
  components: { ConfirmDialog },
  data() {
    return {
      currentItemIndex: null,
      editedItemIndex: null,
      copyItemIndex: null,
      
      editedItemCache: null,

      loading: false,
      loadedEquipments: [],
      
      selectableTable: false,
      selectedRows: [],
      importedExcel: null,
      updateTable: null,
      allSelected: false,

      commoditySelected: null,
      commodities: [],

      weightUOMItems: ['KGM', 'LBS'],
      volumeUOMItems: ['MTQ', 'FTQ'],
      weightUOMSelected: null,
      volumeUOMSelected: null,

      downloadPath: process.env.VUE_APP_PUBLIC_PATH + 'template/shippingInstructionEquipmentsTemplate.xlsx',

      equipmentsHeaders: [
        { text: this.$t('label.isoCode'), value: 'containerIsoType', class: 'text', width: '6%' },
        { text: this.$t('label.code'), value: 'containerCode', class: 'text', width: '7%' },

        { text: this.$t('label.seal'), value: 'shipperSeal', class: 'text', width: '7%' },
        { text: this.$t('label.seal'), value: 'carrierSeal', class: 'text', width: '7%' },

        { text: this.$t('label.qty'), value: 'packagesNbr', class: 'text', width: '4%' },
        { text: this.$t('label.type'), value: 'packagesType', class: 'long-text', width: '10%' },

        { text: this.$t('label.commodity'), value: 'commodityDesc', class: 'long-text', width: '30%' },
        { value: 'copy', sortable: false, class: 'copy', width: '2%' },
        { text: this.$t('label.shipperOwned'), value: 'shipperOwned', class: 'text', width: '5%' },
        { text: this.$t('label.weight'), value: 'weight', class: 'text', width: '6%' },
        { text: this.$t('label.weightUOM'), value: 'weightUOM', class: 'select', width: '5.5%' },
        { text: this.$t('label.volume'), value: 'volume', class: 'text', width: '5.5%' },
        { text: this.$t('label.volumeUOM'), value: 'volumeUOM', class: 'select', width: '5.5%' },
        
        { value: 'actions', sortable: false, class: 'actions', width: '100%' },
      ],

      confirmDelete: false,
      confirmDeleteMsg: this.$t('message.confirmDeletion'),
      deleteItemIndex: null
    }
  },
  computed: {
    equipmentItem(){
      let commodityDesc = this.commoditySelected ? this.commoditySelected.commodityName.substring(0, 70) : null
      return {
        containerIsoType: null,
        containerCode: null,
        shipperSeal: null,
        carrierSeal: null,
        packagesNbr: 1,
        packagesType: null,
        commodityDesc: commodityDesc,
        shipperOwned: null,
        weight: null,
        weightUOM: this.weightUOMSelected,
        volume: null,
        volumeUOM: this.volumeUOMSelected,
      }
    }
  },
  watch: {
    selectableTable(isSelectable){
      if (isSelectable) this.equipmentsHeaders.unshift({ value: 'selection', sortable: false, class: 'selection' })
      else this.equipmentsHeaders.shift()
    },
  },
  methods: {
    async init(){
      this.loading = true
      this.loadSelectedBooking()
      if (this.savedShippingInstructionEquipments) this.loadedEquipments = this._.cloneDeep(this.savedShippingInstructionEquipments)
      if (this.savedWeightUom) this.weightUOMSelected = this.savedWeightUom
      if (this.savedVolumeUom) this.volumeUOMSelected = this.savedVolumeUom
      await this.initCommodity()
      this.loading = false
    },
    async initCommodity(){
      if (!this.savedCommodity) return
      this.commodities = await this.browseCommodities(this.savedCommodity)
      if (this.commodities.length) this.commoditySelected = this.commodities[0]
    },
    getTrackingEquipments() {
      this.loading = true
      praticheService.getAllEquipmentsFromTracking(this.pratica.id, this.pratica.mainTransportCompany)
      .then((resp) => {
        if (!resp) return 
        this.loadedEquipments = resp
        this.loading = false
      })
      .catch((error) =>
        this.setSnackbarMessage({ text: "Error loading equipments", color: 'error' }),
      )
    },
    uploadExcelToJson(file) {
      if (!file) return
      this.importedExcel = file
      const reader = new FileReader()
      reader.onload = (e) => {
        const bstr = e.target.result
        const wb = XLSX.read(bstr, { type: 'binary' })
        const wsname = wb.SheetNames[0]
        const ws = wb.Sheets[wsname]
        this.loadedEquipments = XLSX.utils.sheet_to_json(ws, { header: 2 })
      }
      reader.readAsBinaryString(file)
    },
    downloadXlsxFromJson(json) {
      if (!json) return
      const ws = XLSX.utils.json_to_sheet(json)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      XLSX.writeFile(wb, 'Equipments.xlsx')
    },
    addNewEquipment(){
      let equipment = this._.cloneDeep(this.equipmentItem)
      this.loadedEquipments.push(equipment)
      let index = this.loadedEquipments.length - 1
      this.editEquipment(equipment, index)
    },
    editEquipment(item, index) {
      if (this.editedItemIndex == index) {
        this.resetEditedItem()
        this.editedItemCache = null
      }
      else {
        this.editedItemIndex = index
        this.editedItemCache = this._.cloneDeep(item)
      }
    },
    openConfirmDeleteDialog(isOpen){
      this.confirmDelete = isOpen
      if (!this.confirmDelete) this.deleteItemIndex = null
    },
    prepareDeleteEquipment(index){
      this.deleteItemIndex = index
      this.confirmDelete = true
    },
    deleteEquipment(index) {
      this.loadedEquipments.splice(index, 1);
      this.deleteItemIndex = null
      this.confirmDelete = false
    },
    checkIsDefaultHeader(header){
      return header.class == 'text'
    },
    prepareCopyCommodityDesc(item){
      const index = this.loadedEquipments.indexOf(item);
      this.copyItemIndex = index
      if (this.selectedRows.includes(index)) this.removeItemFromArray(this.selectedRows, index)
      this.resetEditedItem()
      this.handleTableSelectable(true)
    },
    abortCopyCommodityDesc(){
      this.resetCopyIndex()
      this.handleTableSelectable(false)
      this.allSelected = false
    },
    confirmCopyCommodityDesc(){
      let item = this.loadedEquipments[this.copyItemIndex]
      let commodityDesc = item.commodityDesc
      this.selectedRows.forEach(index => {
        this.loadedEquipments[index].commodityDesc = commodityDesc
      });
      this.abortCopyCommodityDesc()
    },
    handleTableSelectable(isSelectable){
      this.selectableTable = isSelectable
      if (!this.selectableTable) this.selectedRows = []
    },
    selectRow(item){
      const index = this.loadedEquipments.indexOf(item);
      if (this.selectedRows.includes(index)) 
        this.removeItemFromArray(this.selectedRows, index)
      else this.selectedRows.push(index)
    },
    removeItemFromArray(array, item){
      const index = array.indexOf(item);
      if (index > -1) return array = array.splice(index, 1);
    },
    handleClickOutsideTable(){
      this.restoreOldValues(this.editedItemCache, this.editedItemIndex)
      this.resetEditedItem()
      this.resetCopyIndex()
    },
    resetEditedItem(){
      this.editedItemIndex = null
      this.editedItemCache = null
    },
    resetCopyIndex(){
      this.copyItemIndex = null
    },
    isEditable(index) {
      return index == this.editedItemIndex ? 'selected-row' : ''
    },
    restoreOldValues(item, index){
      this.loadedEquipments[index] = this._.cloneDeep(this.editedItemCache)
      this.updateTable = !this.updateTable
      this.editEquipment(item, index)
    },
    selectAll(){ 
      this.selectedRows = []
      if (this.allSelected){
        this.loadedEquipments.forEach((equipment, index) => {
          this.selectedRows.push(index);
        });
      }
    },
    getSelectItems(header){
      switch (header) {
        case 'weightUOM':
          return  this.weightUOMItems
        case 'volumeUOM':
          return this.volumeUOMItems
      } return []
    },
    updateUOMs(item){
      this.weightUOMSelected = item.weightUOM
      this.volumeUOMSelected = item.volumeUOM
      if (!this.loadedEquipments) return
      this.loadedEquipments.forEach(equipment => {
        equipment.weightUOM = item.weightUOM
        equipment.volumeUOM = item.volumeUOM
      });
    },
    // Commodity
    getBookingRequestCommodities(input) {
      if (!input || (this.commoditySelected && input == this.commoditySelected.commodityName))
        return
      clearTimeout(this.searchTimeoutId)
      this.searchTimeoutId = setTimeout(async () => {
        this.commodities = await this.browseCommodities(input)
      }, 500)
    },
    filterCommodities(item, queryText) {
      return item.commodityName.toLowerCase().indexOf(queryText.toLowerCase()) > -1 ||
        item.commodityCode.toLowerCase().indexOf(queryText.toLowerCase()) > -1
    },
    // Submit
    submitEquipments(){
      let validated = true
      if (!this.$refs.commodity.validate()) validated = false
      if (!this.loadedEquipments.length) {
        validated = false
        this.setSnackbarMessage({ text: this.$t('message.equipmentsRequired'), color: 'error' })
      }
      if (!validated) return
      let cargoItems = []
      let utilizedEquipments = []
      this.loadedEquipments.forEach(equipment => {
        cargoItems.push(this.getCargoItem(equipment))
        utilizedEquipments.push(this.getUtilizedTransportEquipments(equipment))
      });
      this.setCargoItems(cargoItems)
      this.setUtilizedTransportEquipments(utilizedEquipments)
      this.setShippingInstructionEquipments(JSON.stringify(this.loadedEquipments))

      if (this.electronicFlowStatus == this.voyageSelectedStatus) this.setStatus(this.equipmentsSelectedStatus)
      this.updateShippingInstructionAndSaveElectronicFlow()
      this.$emit('step', true)
    },
    getCargoItem(equipment){
      return {
        carrierBookingReference: this.selectedBooking.carrierReference,
        descriptionOfGoods: this.commoditySelected ? this.commoditySelected.commodityName.substring(0, 70) : null,
        hsCode: this.commoditySelected ? this.commoditySelected.commodityCode : null, // TO CHANGE
        numberOfPackages: equipment.packagesNbr,
        weight: equipment.weight,
        volume: equipment.volume,
        weightUnit: this.getWeightUOM(),
        volumeUnit: equipment.volumeUOM,
        packageCode: equipment.packagesType,
        equipmentReference: equipment.containerCode,
      }
    },
    getUtilizedTransportEquipments(equipment){
      return {
        equipmentReference: equipment.containerCode,
        weightUnit: this.getWeightUOM(),
        cargoGrossWeight: equipment.weight,
        isoEquipmentCode: equipment.containerIsoType,
        seals: new Array(this.getSeal(equipment.shipperSeal), this.getSeal(equipment.carrierSeal))
      }
    },
    getWeightUOM(){
      switch (this.company) {
        case 'MAERSK':
          return 'KGS'
        case 'CMACGM':
        case 'YANGMING':
          return 'KGM'
      }
    },
    getSeal(seal){
      return {
        sealNumber: seal,
        sealSource: null,
        sealType: null,
      }
    }
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>
.stepper-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
}
.xs-input::v-deep input {
  padding: 0.5rem !important;
  padding-left: 0 !important;
}
.xs-input::v-deep .v-label {
  position: unset !important;
  min-width: max-content;
  margin-bottom: 0 !important;
}
.xs-input::v-deep .v-input__slot {
  min-height: 0 !important;
  padding-right: 4px !important;
}
.xs-input::v-deep .v-input__append-inner {
  margin: 0 !important;
  align-self: center !important;
}
.xs-input::v-deep .v-label--active {
  position: absolute !important;
  margin-bottom: 0 !important;
}
.xs-input::v-deep .v-select__selections {
  padding: 0 !important;
}
.xs-input::v-deep .v-input__prepend-outer{
  margin: 0 !important;
  align-self: center !important;
}
.xs-input::v-deep .v-text-field__slot {
  padding: 0 !important;
}
.dropdown-menu {
  width: 100%;
  padding: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.action-col{
  width: fit-content !important;
  max-width: fit-content !important;
}
.v-icon:focus::after {
  opacity: 0 !important;
}
.v-data-table::v-deep .v-data-table__wrapper {
  overflow-x: hidden !important;
}
.v-data-table::v-deep th {
  white-space: nowrap;
  padding: 0 !important;
  padding-right: 0 !important;
  height: 1.5rem !important;
}
.v-data-table::v-deep td {
  padding: 0 !important;
}
.one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.table-text {
  font-size: 0.875rem;
}
.v-text-field--solo-flat {
  padding: 0 !important;
}
.v-text-field--solo-flat::v-deep .v-input__control {
  min-height: fit-content !important;
}
.v-text-field--solo-flat::v-deep .v-input__slot {
  padding: 0 !important;

  min-height: 0 !important;
  background-color: transparent !important;
}
.v-text-field--solo-flat::v-deep input {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.v-text-field--solo-flat::v-deep .v-icon{
  font-size: 1rem !important;
}
.v-text-field::v-deep .v-text-field__details{
  margin: 0 !important;
}
.v-input--is-disabled::v-deep input {
  color: #616161 !important;
}
.v-simple-checkbox::v-deep .v-input--selection-controls__input {
  margin-right: 0;
}
.actions {
  min-width: 100%;
  display: flex;
  justify-content: center !important;
}
/*.v-data-table::v-deep td {
  white-space: nowrap;
}
.row {
  border: solid 1px red;
}
.col {
  border: solid 1px orange;
}*/
</style>
