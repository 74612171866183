<template>
  <v-expand-transition>
    <v-card outlined class="rounded-0" v-show="open">
      <!-- <v-card-title class="subtitle-1 pb-0">
        {{ $t("upper.packagingsList") }}
      </v-card-title> -->
      <v-card-text class="pa-0">
        <v-simple-table dense class="pa-3">
          <template>
            <thead class="t-head">
              <tr>
                <th class="text-left">{{ $t('label.order') }}</th>
                <th class="text-left">{{ $t('label.position') }}</th>
                <th class="text-left">{{ $t('label.packaging') }}</th>
                <th class="text-left">{{ $t('label.quantity') }}</th>
                <th class="text-left">{{ $t('label.grossWeight') }}</th>
                <th class="text-left">{{ $t('label.netWeight') }}</th>
                <th class="text-left">{{ $t('label.length') }}</th>
                <th class="text-left">{{ $t('label.width') }}</th>
                <th class="text-left">{{ $t('label.height') }}</th>
                <th class="text-left">{{ $t('label.volume') }}</th>
                <th class="text-left">{{ $t('label.billReference') }}</th>
                <th class="text-left">{{ $t('label.booking') }}</th>
                <th class="text-left">{{ $t('label.customerType') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(imballo, i) in imballi" :key="i">
                <td>{{ imballo.saleOrder }}</td>
                <td>{{ imballo.saleOrderLine }}</td>
                <td>{{ imballo.itemDescription }}</td>
                <td>{{ imballo.quantity }}</td>
                <td>kg {{ imballo.grossWeight }}</td>
                <td>kg {{ imballo.netWeight }}</td>
                <td>cm {{ imballo.length }}</td>
                <td>cm {{ imballo.width }}</td>
                <td>{{ imballo.height }}</td>
                <td>
                  m
                  <sup>3</sup>
                  {{ imballo.volume }}
                </td>
                <td>{{ imballo.invoice }}</td>
                <td>{{ imballo.bookingNbr }}</td>
                <td>
                  <v-icon v-if="imballo.carrierMerchantFlag == 'M'">
                    mdi-alpha-m-box
                  </v-icon>
                  <v-icon v-if="imballo.carrierMerchantFlag == 'C'">
                    mdi-alpha-c-box
                  </v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-expand-transition>
</template>

<script>
import { imballiService } from '@/services/imballi.service.js'

export default {
  name: 'Imballi',
  props: ['open', 'documentNbr'],
  data() {
    return {
      imballi: [],
    }
  },
  watch: {
    open(isOpen) {
      if (!isOpen) return
      this.loadImballiForPratica()
    },
  },
  methods: {
    loadImballiForPratica() {
      imballiService.getImballiByDocumentNbr(this.documentNbr).then((resp) => {
        if (resp) this.imballi = resp
      })
    },
  },
}
</script>

<style scoped>
.t-head {
  background-color: #e7eff7;
}
</style>
