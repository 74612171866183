var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "ma-0" },
        [
          _c(
            "v-col",
            { staticClass: "pa-0", attrs: { cols: "auto" } },
            [
              _c(
                "v-icon",
                { staticClass: "mr-2", attrs: { color: _vm.assignColor() } },
                [_vm._v("mdi-account-circle")]
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticStyle: { "margin-top": "0.2rem" } },
            [
              _c("v-row", [
                _c("span", {
                  domProps: { textContent: _vm._s(_vm.message.from) },
                }),
              ]),
              _c("v-row", [
                _c("span", {
                  staticClass: "text-caption",
                  domProps: { textContent: _vm._s(_vm.message.subject) },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.current == _vm.index
        ? _c(
            "v-flex",
            [
              _c("span", { staticClass: "text-caption ml-8" }, [_vm._v("to")]),
              _c("span", {
                staticClass: "text-caption ml-2",
                domProps: { innerHTML: _vm._s(_vm.message.to.join(", ")) },
              }),
              _c(
                "v-menu",
                {
                  attrs: {
                    "close-on-content-click": false,
                    "nudge-width": 200,
                    "offset-overflow": "",
                    "offset-x": "",
                    "z-index": "10",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "mx-1",
                                  attrs: { icon: "", "x-small": "" },
                                },
                                on
                              ),
                              [_c("v-icon", [_vm._v("mdi-menu-down")])],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2623168254
                  ),
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-list",
                        { staticClass: "pa-4 pl-0" },
                        [
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "pa-0 pb-1" },
                                [
                                  _c(
                                    "v-list-item-title",
                                    { staticClass: "text-caption d-flex" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "field text--disabled",
                                          attrs: { align: "right" },
                                        },
                                        [_c("span", [_vm._v("From:")])]
                                      ),
                                      _c(
                                        "v-col",
                                        { staticClass: "content" },
                                        [
                                          _c("v-col", { staticClass: "pa-0" }, [
                                            _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.formatContact(
                                                    _vm.message.from
                                                  )
                                                ),
                                              },
                                            }),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "pa-0 pb-1" },
                                [
                                  _c(
                                    "v-list-item-title",
                                    { staticClass: "text-caption d-flex" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "field text--disabled",
                                          attrs: { align: "right" },
                                        },
                                        [_vm._v(" To: ")]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "d-flex flex-column content",
                                        },
                                        _vm._l(
                                          _vm.message.to,
                                          function (contact, index) {
                                            return _c("span", {
                                              key: index,
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.formatContact(contact)
                                                ),
                                              },
                                            })
                                          }
                                        ),
                                        0
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.message.cc.length
                            ? _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "pa-0 pb-1" },
                                    [
                                      _c(
                                        "v-list-item-title",
                                        { staticClass: "text-caption d-flex" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "field text--disabled",
                                              attrs: { align: "right" },
                                            },
                                            [_vm._v(" Cc: ")]
                                          ),
                                          _c(
                                            "v-col",
                                            { staticClass: "content" },
                                            _vm._l(
                                              _vm.message.cc,
                                              function (contact, index) {
                                                return _c(
                                                  "v-col",
                                                  {
                                                    key: index,
                                                    staticClass: "pa-0",
                                                  },
                                                  [
                                                    _c("span", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.formatContact(
                                                            contact
                                                          )
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.message.bcc.length
                            ? _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "pa-0 pb-1" },
                                    [
                                      _c(
                                        "v-list-item-title",
                                        { staticClass: "text-caption d-flex" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "field text--disabled",
                                              attrs: { align: "right" },
                                            },
                                            [_vm._v(" Bcc: ")]
                                          ),
                                          _c(
                                            "v-col",
                                            { staticClass: "content" },
                                            _vm._l(
                                              _vm.message.bcc,
                                              function (contact, index) {
                                                return _c(
                                                  "v-col",
                                                  {
                                                    key: index,
                                                    staticClass: "pa-0",
                                                  },
                                                  [
                                                    _c("span", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.formatContact(
                                                            contact
                                                          )
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "pa-0 pb-1" },
                                [
                                  _c(
                                    "v-list-item-title",
                                    { staticClass: "text-caption d-flex" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "field text--disabled",
                                          attrs: { align: "right" },
                                        },
                                        [_vm._v(" Subject: ")]
                                      ),
                                      _c(
                                        "v-col",
                                        { staticClass: "content" },
                                        [
                                          _c("v-col", { staticClass: "pa-0" }, [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.message.subject)
                                              ),
                                            ]),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "pa-0" },
                                [
                                  _c(
                                    "v-list-item-title",
                                    { staticClass: "text-caption d-flex" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "field text--disabled",
                                          attrs: { align: "right" },
                                        },
                                        [_vm._v(" Date: ")]
                                      ),
                                      _c(
                                        "v-col",
                                        { staticClass: "content" },
                                        [
                                          _c("v-col", { staticClass: "pa-0" }, [
                                            _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.formatDate(
                                                    _vm.message.receivedDateTime
                                                  )
                                                ),
                                              },
                                            }),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }