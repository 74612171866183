var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expand-transition",
    [
      _c(
        "v-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.open,
              expression: "open",
            },
          ],
          staticClass: "rounded-0",
          attrs: { outlined: "" },
        },
        [
          _c(
            "v-card-text",
            { staticClass: "black--text" },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "d-inline-flex" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0" },
                        [
                          _c("ReadonlyField", {
                            attrs: {
                              label: _vm.$t("label.company"),
                              value: _vm.pratica.supplierName,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "d-inline-flex" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0" },
                        [
                          _c("ReadonlyField", {
                            attrs: {
                              label: "B/L",
                              value: _vm.pratica.billOfLading,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "d-inline-flex" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0" },
                        [
                          _c("ReadonlyField", {
                            attrs: {
                              label: _vm.$t("label.vessel"),
                              value: _vm.pratica.vesselName,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "d-inline-flex" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0" },
                        [
                          _c("ReadonlyField", {
                            attrs: {
                              label: _vm.$t("label.departure"),
                              value:
                                _vm.pratica.pol +
                                (_vm.seaTrackingDepartureDate
                                  ? " - " + _vm.seaTrackingDepartureDate
                                  : ""),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "d-inline-flex" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0" },
                        [
                          _c("ReadonlyField", {
                            attrs: {
                              label: _vm.$t("label.arrival"),
                              value:
                                _vm.pratica.pod +
                                (_vm.seaTrackingArrivalDate
                                  ? " - " + _vm.seaTrackingArrivalDate
                                  : ""),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _c(
                "v-data-table",
                {
                  staticClass: "pa-0",
                  attrs: {
                    headers: _vm.trackingHeadersMare,
                    items: _vm.seaTerminalEvents,
                    "item-class": _vm.isDepartedFromPol,
                    "items-per-page": 1000,
                    "hide-default-footer": "",
                    dense: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "item.eventDateTime",
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            _vm._s(_vm.formatDateShort(item.eventDateTime))
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.eventCode",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "span",
                            {
                              class: _vm.isDepartedFromPol(item)
                                ? _vm.captionBoldClass
                                : _vm.captionClass,
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.eventCode === "EST"
                                      ? "ESTIMATED"
                                      : item.eventCode === "ACT"
                                      ? "ACTUAL"
                                      : item.eventCode === "PLN"
                                      ? "PLANNED"
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.eventName",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "span",
                            {
                              class: _vm.isDepartedFromPol(item)
                                ? _vm.captionBoldClass
                                : _vm.captionClass,
                            },
                            [_vm._v(" " + _vm._s(item.eventName) + " ")]
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.eventType",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "span",
                            {
                              class: _vm.isDepartedFromPol(item)
                                ? _vm.captionBoldClass
                                : _vm.captionClass,
                            },
                            [_vm._v(" " + _vm._s(item.eventType) + " ")]
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.eventDescription",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "span",
                            {
                              class: _vm.isDepartedFromPol(item)
                                ? _vm.captionBoldClass
                                : _vm.captionClass,
                            },
                            [_vm._v(" " + _vm._s(item.eventDescription) + " ")]
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.transportMode",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-center" },
                            [
                              item.transportMode === "TRUCK"
                                ? _c(
                                    "v-icon",
                                    { attrs: { color: "primary" } },
                                    [_vm._v("mdi-truck")]
                                  )
                                : _vm._e(),
                              item.transportMode === "VESSEL" ||
                              item.transportMode === "1"
                                ? _c(
                                    "v-icon",
                                    { attrs: { color: "primary" } },
                                    [_vm._v("mdi-ferry")]
                                  )
                                : _vm._e(),
                              item.transportMode === "RAIL"
                                ? _c(
                                    "v-icon",
                                    { attrs: { color: "primary" } },
                                    [_vm._v("mdi-train")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "template",
                    { slot: "no-data" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-1",
                          attrs: { color: "warning", size: "20" },
                        },
                        [_vm._v("mdi-alert")]
                      ),
                      _c(
                        "span",
                        { staticClass: "font-weight-medium warning--text" },
                        [_vm._v(" " + _vm._s(_vm.$t("label.noTrackingInfo")))]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }