<template>
  <v-menu
    v-model="datePickerMenu"
    transition="scale-transition"
    min-width="auto"
    :offset-x="offset == 'x'"
    :offset-y="offset == 'y'"
    top
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :label="label"
        :value="selectedDate"
        v-on="disabled ? null : on"
        append-icon="mdi-calendar"
        @click:append="disabled ? null : (datePickerMenu = !datePickerMenu)"
        :outlined="appearance.includes('outlined')"
        :solo="appearance.includes('solo')"
        :flat="appearance.includes('flat')"
        :clearable="!disabled"
        :class="inputClass"
        :hide-details="hideDetails"
        :rules="rules"
        readonly
        dense
      />
    </template>
    <v-date-picker
      v-model="date"
      @input="select"
      color="primary"
      :readonly="disabled"
    />
  </v-menu>
</template>

<script>
import { dateFilter } from '@/filters/dateFilter'

export default {
  name: 'DatePickerMenu',
  props: ['label', 'appearance', 'offset', 'disabled', 'preselectedDate', 'inputClass', 'hideDetails', 'rules'],
  emits: ['select-date'],
  components: {},
  data() {
    return {
      date: null,
      selectedDate: null,
      datePickerMenu: false,
    }
  },
  watch: {
    preselectedDate() {
      this.preselectDate()
    },
  },
  methods: {
    // preselectDate() {
    //   this.date = !this.preselectedDate ? new Date().toISOString().substr(0, 10) : this.preselectedDate
    //   this.select()
    // },
    preselectDate() {
      if (this.preselectedDate) this.date = this.preselectedDate
      this.select()
    },
    select() {
      this.selectedDate = this.formatDate(this.date)
      this.$emit('select-date', this.date)
    },
    formatDate(value) {
      return dateFilter.formatDateDay(value)
    },
  },
  mounted() {
    this.preselectDate()
  },
}
</script>

<style scoped>
.xs-input::v-deep input {
  padding: 0.5rem !important;
  padding-left: 0 !important;
}
.xs-input::v-deep .v-label {
  position: unset !important;
  min-width: max-content;
  margin-bottom: 0 !important;
}
.xs-input::v-deep .v-input__slot {
  min-height: 0 !important;
  padding-right: 4px !important;
}
.xs-input::v-deep .v-input__append-inner {
  margin: 0 !important;
  align-self: center !important;
}
.xs-input::v-deep .v-label--active {
  position: absolute !important;
  margin-bottom: 0 !important;
}
.xs-input::v-deep .v-text-field__details {
  margin: 0 !important;
}
</style>