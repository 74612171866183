<template>
  <div class="d-flex align-center pa-0">
    <SortByMenuCustomsDashboard @sort-invoices="sort" />
    <FiltersDialogCustomsDashboard ref="filtersDialog" @set-filter="filter" />
    <v-flex class="d-flex align-center" shrink>
      <v-btn
        x-small
        outlined
        depressed
        color="secondary"
        class="mx-1"
        @click="resetFilter"
      >
        {{ $t("label.resetAllFilters") }}
      </v-btn>
    </v-flex>
  </div>
</template>

<script>
import FiltersDialogCustomsDashboard from "./FiltersDialogCustomsDashboard.vue";
import SortByMenuCustomsDashboard from "./SortByMenuCustomsDashboard.vue";

export default {
  name: "FiltersSectionCustomsDashboard",
  props:[],
  emits: ["sort", "filter", "reset-filter"],
  components: { FiltersDialogCustomsDashboard, SortByMenuCustomsDashboard },
  data() {
    return {};
  },
  methods: {
    sort(sortBy) {
      this.$emit("sort", sortBy);
    },
    filter(browseFilter, visualFilter) {
      this.$emit("filter", browseFilter, visualFilter);
    },
    resetFilter() {
      this.$emit("reset-filter");
    },
    resetAllFilters() {
      this.$refs.filtersDialog.resetAllFilters();
    },
  },
};
</script>
