import { commons } from "./commons";

export const invoicesService = {
  browse,
  findOne,
  createTransportJob,
};

const resourceUrl = process.env.VUE_APP_GSCV_CUSTOMS_ENGINE_URL + "/invoices";
const authorizationHeader =
  process.env.VUE_APP_GSCV_CUSTOMS_ENGINE_BASIC_AUTH_HEADER;

function browse(offset, limit, filter, sortingList) {
  if (!sortingList.length) {
    sortingList = [];
    sortingList.push({ column: "documentDate", direction: "DESC" });
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: authorizationHeader,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      offset: offset,
      limit: limit,
      filter: filter,
      sortingList: sortingList,
    }),
  };
  return fetch(resourceUrl + "/browse", requestOptions).then(
    commons.handleJsonResponse
  );
}

function findOne(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Basic Z3Njdi1jdXN0b21zLWVuZ2luZTo3cFlWOWt0Yjcy",
      "Content-Type": "application/json",
    },
  };
  return fetch(resourceUrl + `/${id}`, requestOptions).then(
    commons.handleJsonResponse
  );
}

function createTransportJob(invoiceBusinessKeys) {
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: authorizationHeader,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(invoiceBusinessKeys),
  };
  return fetch(resourceUrl + "/send-into-mastersped", requestOptions);
}
