<template>
  <v-dialog v-model="dialog" width="60vw">
    <v-card>
      <v-card-title class="pa-2 px-3" @click="hideButtons">
        <span class="text-overline primary--text">
          {{ $t('label.newMessage') }}
        </span>
        <!-- <v-spacer />
        <v-btn icon x-small @click="dialog = false">
          <v-icon color="primary">mdi-close</v-icon>
        </v-btn> -->
      </v-card-title>
      <v-divider class="ma-0" />
      <MailNew
        ref="mailNew"
        :msgTemplate="msgTemplate"
        :emailAddresses="emailAddresses"
        :isVisible="dialog"
        v-on="$listeners"
      />
      <!-- :msgTemplate="msgTemplate" -->
    </v-card>
  </v-dialog>
</template>

<script>
import MailNew from '@/components/elements/mails/MailNew.vue'

export default {
  name: 'EmailDialog',
  props: ['emailAddresses', 'msgTemplate'],
  // emits: ['load'],
  components: { MailNew },
  data() {
    return {
      dialog: false,
    }
  },
  watch: {
    dialog(isOpen) {
      if (!isOpen) this.$refs.mailNew.resetForm()
    },
  },
  methods: {
    openDialog(isOpen) {
      this.dialog = isOpen
    },
    hideButtons(isOpen) {
      this.$refs.mailNew.hideButtons()
    },
  },
}
</script>
