const getDefaultState = () => {
  return {
    selectedBooking: null,
    bookingRequest: null,
    shippingInstruction: {
      carrierName: null,
      transportDocumentType: null,
      paymentCode: null,
      chargeCode: null,
      agreementReference: null,
      isShipperOnboard: null,
      carrierBookingReference: [],
      invoicePayableAt: {
        locationName: null,
        unLocationCode: null,
      },
      originalFreighted: null,
      originalUnfreighted: null,
      copiesFreighted: null,
      copiesUnfreighted: null,
      importTransport: {
        haulageType: null,
        movementType: null,
        shippingType: null,
      },
      exportTransport: {
        haulageType: null,
        movementType: null,
        shippingType: null,
      },
      cargoItems: [],
      utilizedTransportEquipments: [],
      documentParties: [],
      shipmentLocations: [],
      references: [],
      remarks: [],
      clauses: []
    },
  }
}

const state = getDefaultState()

const mutations = {
  setSelectedBooking(state, selectedBooking) {
    state.selectedBooking = selectedBooking
  },
  setBookingRequest(state, bookingRequest) {
    state.bookingRequest = bookingRequest
  },
  resetShippingInstruction(state) {
    Object.assign(state, getDefaultState())
  },
  setShippingInstruction(state, shippingInstruction) {
    state.shippingInstruction = shippingInstruction
  },
  setCarrierName(state, carrierName) {
    state.shippingInstruction.carrierName = carrierName
  },
  setTransportDocumentType(state, transportDocumentType) {
    state.shippingInstruction.transportDocumentType = transportDocumentType
  },
  setPaymentCode(state, paymentCode) {
    state.shippingInstruction.paymentCode = paymentCode
  },
  setChargeCode(state, chargeCode) {
    state.shippingInstruction.chargeCode = chargeCode
  },
  setAgreementReference(state, agreementReference){
    state.shippingInstruction.agreementReference = agreementReference
  },
  setIsShipperOnboard(state, isShipperOnboard) {
    state.shippingInstruction.isShipperOnboard = isShipperOnboard
  },
  setCarrierBookingReference(state, carrierBookingReference) {
    state.shippingInstruction.carrierBookingReference = carrierBookingReference
  },
  setInvoicePayableAt(state, invoicePayableAt) {
    state.shippingInstruction.invoicePayableAt = invoicePayableAt
  },
  setOriginalFreighted(state, originalFreighted){
    state.shippingInstruction.originalFreighted = originalFreighted
  },
  setOriginalUnfreighted(state, originalUnfreighted){
    state.shippingInstruction.originalUnfreighted = originalUnfreighted
  },
  setCopiesFreighted(state, copiesFreighted){
    state.shippingInstruction.copiesFreighted = copiesFreighted
  },
  setCopiesUnfreighted(state, copiesUnfreighted){
    state.shippingInstruction.copiesUnfreighted = copiesUnfreighted
  },
  setImportTransport(state, importTransport) {
    state.shippingInstruction.importTransport = importTransport
  },
  setExportTransport(state, exportTransport) {
    state.shippingInstruction.exportTransport = exportTransport
  },
  setCargoItems(state, cargoItems) {
    state.shippingInstruction.cargoItems = cargoItems
  },
  setUtilizedTransportEquipments(state, utilizedTransportEquipments) {
    state.shippingInstruction.utilizedTransportEquipments = utilizedTransportEquipments
  },
  setDocumentParties(state, parties) {
    state.shippingInstruction.documentParties = parties
  },
  setShipmentLocations(state, locations) {
    state.shippingInstruction.shipmentLocations = locations
  },
  setReferences(state, references) {
    state.shippingInstruction.references = references
  },
  setRemarks(state, remarks) {
    state.shippingInstruction.remarks = remarks
  },
  setClauses(state, clauses) {
    state.shippingInstruction.clauses = clauses
  },
}

export const shippingInstruction = {
  namespaced: true,
  state,
  mutations,
}
