import { authHeader } from '@/helpers/auth-header'
import { commons } from './commons'

export const groupsService = {
  getGroup,
  browse,
  createGroup,
}

const resourceUrl = process.env.VUE_APP_GSCV_ENGINE_URL + `/groups`

function getGroup(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/${id}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function browse(offset, limit, filter, sortingList) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      offset: offset,
      limit: limit,
      filter: filter,
      sortingList: sortingList,
    }),
  }

  return fetch(resourceUrl + `/browse`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function createGroup(group) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(group),
  }
  return fetch(resourceUrl, requestOptions).then(commons.handleJsonResponse)
}
