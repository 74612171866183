var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "stepper-box flex-column align-center" },
    [
      _c(
        "v-row",
        { staticClass: "ma-0", staticStyle: { "max-width": "fit-content" } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-avatar",
                { attrs: { size: "40" } },
                [
                  _c(
                    "v-icon",
                    { attrs: { size: "20", color: "primary", rounded: "" } },
                    [_vm._v("mdi-factory")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.$vuetify.breakpoint.smAndDown
            ? _c("v-col", { staticClass: "divider-col" }, [_c("v-divider")], 1)
            : _vm._e(),
          _c(
            "v-col",
            [
              _c(
                "v-avatar",
                { attrs: { size: "40", clas: "" } },
                [
                  _vm.saleChannelDesc(_vm.pratica.saleChannel) == "Nave"
                    ? _c(
                        "v-icon",
                        {
                          attrs: { size: "20", color: "primary", rounded: "" },
                        },
                        [_vm._v(" mdi-ferry ")]
                      )
                    : _vm._e(),
                  _vm.saleChannelDesc(_vm.pratica.saleChannel) == "Aereo"
                    ? _c(
                        "v-icon",
                        {
                          attrs: { size: "20", color: "primary", rounded: "" },
                        },
                        [_vm._v(" mdi-airplane ")]
                      )
                    : _vm._e(),
                  _vm.saleChannelDesc(_vm.pratica.saleChannel) == "Terra"
                    ? _c(
                        "v-icon",
                        {
                          attrs: { size: "20", color: "primary", rounded: "" },
                        },
                        [_vm._v(" mdi-truck ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.$vuetify.breakpoint.smAndDown
            ? _c("v-col", { staticClass: "divider-col" }, [_c("v-divider")], 1)
            : _vm._e(),
          _c(
            "v-col",
            [
              _c(
                "v-avatar",
                { attrs: { size: "40", clas: "" } },
                [
                  _vm.saleChannelDesc(_vm.pratica.saleChannel) == "Nave"
                    ? _c(
                        "v-icon",
                        {
                          attrs: { size: "20", color: "primary", rounded: "" },
                        },
                        [_vm._v(" mdi-ferry ")]
                      )
                    : _vm._e(),
                  _vm.saleChannelDesc(_vm.pratica.saleChannel) == "Aereo"
                    ? _c(
                        "v-icon",
                        {
                          attrs: { size: "20", color: "primary", rounded: "" },
                        },
                        [_vm._v(" mdi-airplane ")]
                      )
                    : _vm._e(),
                  _vm.saleChannelDesc(_vm.pratica.saleChannel) == "Terra"
                    ? _c(
                        "v-icon",
                        {
                          attrs: { size: "20", color: "primary", rounded: "" },
                        },
                        [_vm._v(" mdi-truck ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.$vuetify.breakpoint.smAndDown
            ? _c("v-col", { staticClass: "divider-col" }, [_c("v-divider")], 1)
            : _vm._e(),
          _c(
            "v-col",
            [
              _c(
                "v-avatar",
                { attrs: { size: "40", clas: "" } },
                [
                  _c(
                    "v-icon",
                    { attrs: { size: "20", color: "primary", rounded: "" } },
                    [_vm._v("mdi-warehouse")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "ma-0", staticStyle: { "max-width": "fit-content" } },
        [
          _c("v-col", [
            _c("div", { staticClass: "address-box" }, [
              _c("span", { staticClass: "ellipsed-span" }, [
                _vm._v(" " + _vm._s(_vm.steps.pickupLocation) + " "),
              ]),
            ]),
          ]),
          !_vm.$vuetify.breakpoint.smAndDown
            ? _c("v-col", { staticClass: "divider-col" })
            : _vm._e(),
          _c("v-col", [
            _c("div", { staticClass: "address-box" }, [
              _c("span", { staticClass: "ellipsed-span" }, [
                _vm._v(" " + _vm._s(_vm.steps.departureLocation) + " "),
              ]),
            ]),
          ]),
          _c("v-col", { staticClass: "vessel-col" }, [
            _c("div", { staticClass: "address-box" }, [
              _c("span", { staticClass: "ellipsed-span" }, [
                _vm._v(" " + _vm._s(_vm.pratica.vesselName) + " "),
              ]),
            ]),
          ]),
          _c("v-col", [
            _c("div", { staticClass: "address-box" }, [
              _c("span", { staticClass: "ellipsed-span" }, [
                _vm._v(" " + _vm._s(_vm.steps.arrivalLocation) + " "),
              ]),
            ]),
          ]),
          !_vm.$vuetify.breakpoint.smAndDown
            ? _c("v-col", { staticClass: "divider-col" })
            : _vm._e(),
          _c("v-col", [
            _c("div", { staticClass: "address-box" }, [
              _c("span", { staticClass: "ellipsed-span" }, [
                _vm._v(" " + _vm._s(_vm.steps.destination) + " "),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm.haveTracking
        ? _c(
            "v-row",
            {
              staticClass: "ma-0",
              staticStyle: { "max-width": "fit-content" },
            },
            [
              _c("v-col", [_c("div", { staticClass: "address-box" })]),
              !_vm.$vuetify.breakpoint.smAndDown
                ? _c("v-col", { staticClass: "divider-col" })
                : _vm._e(),
              _c("v-col", [
                _c("div", { staticClass: "address-box datetime-box" }, [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.trackingAtd ? _vm.trackingAtd : _vm.trackingEtd
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]),
              !_vm.$vuetify.breakpoint.smAndDown
                ? _c("v-col", { staticClass: "divider-col" })
                : _vm._e(),
              _c("v-col", [
                _c("div", { staticClass: "address-box datetime-box" }, [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.trackingAta ? _vm.trackingAta : _vm.trackingEta
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]),
              !_vm.$vuetify.breakpoint.smAndDown
                ? _c("v-col", { staticClass: "divider-col" })
                : _vm._e(),
              _c("v-col", [_c("div", { staticClass: "address-box" })]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }