var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "ma-0", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "pa-0" },
            [
              _c(
                "v-row",
                { staticClass: "ma-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-card",
                        [
                          _c("v-data-table", {
                            staticClass: "pa-2",
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.transportOrders,
                              "items-per-page": 5,
                              "hide-default-footer": "",
                              dense: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function ({ item, index }) {
                                  return [
                                    _c(
                                      "tr",
                                      {
                                        class:
                                          _vm.selectedRow == index
                                            ? "primary lighten-2"
                                            : "",
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectTransportOrder(
                                              index,
                                              item
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(item.transportOrderNbr)
                                          ),
                                        ]),
                                        _c("td", [
                                          _vm._v(_vm._s(item.carrierId)),
                                        ]),
                                        _c("td", [
                                          _vm._v(_vm._s(item.purchaseOrderNbr)),
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatDate(
                                                item.pickupDateActual
                                              )
                                            )
                                          ),
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatDate(
                                                item.deliveryDateActual
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "ma-0 mt-3" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-tabs",
                            {
                              attrs: {
                                "background-color": "accent",
                                height: "2rem",
                                grow: "",
                              },
                              model: {
                                value: _vm.tab,
                                callback: function ($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab",
                              },
                            },
                            [
                              _c("v-tab", [
                                _vm._v(_vm._s(_vm.$t("label.details"))),
                              ]),
                              _c("v-tab", [
                                _vm._v(_vm._s(_vm.$t("label.packages"))),
                              ]),
                              _c("v-tab", [
                                _vm._v(_vm._s(_vm.$t("label.tracking"))),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-tabs-items",
                            {
                              model: {
                                value: _vm.tab,
                                callback: function ($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab",
                              },
                            },
                            [
                              _c(
                                "v-tab-item",
                                { staticClass: "pa-0" },
                                [
                                  _c("PraticaAutotrasportoDettagli", {
                                    attrs: {
                                      pratica: _vm.pratica,
                                      transportOrder:
                                        _vm.selectedTransportOrder,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-tab-item",
                                { staticClass: "pa-0" },
                                [
                                  _c("PraticaAutotrasportoImballi", {
                                    attrs: {
                                      pratica: _vm.pratica,
                                      transportOrder:
                                        _vm.selectedTransportOrder,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("v-tab-item", { staticClass: "pa-0" }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "ml-3 px-0", attrs: { cols: "auto" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "primary",
                  attrs: { fab: "", icon: "", height: "50", width: "50" },
                  on: { click: _vm.loadNewEmailTransport },
                },
                [
                  _c("v-icon", { attrs: { color: "white", size: "30" } }, [
                    _vm._v("mdi-email"),
                  ]),
                ],
                1
              ),
              _c("EmailDialog", {
                ref: "emailDialog",
                attrs: { msgTemplate: _vm.emailTemplate, emailAddresses: [] },
                on: { "email-sent": (resp) => _vm.handleDialog(!resp?.sent) },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }