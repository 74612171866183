var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    { ref: "form", staticClass: "d-block pa-1 overflow-y-auto fill-height" },
    [
      _c(
        "v-row",
        { staticClass: "px-1", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "auto" } },
            [
              _c(
                "v-subheader",
                { staticClass: "pa-0 primary--text text-overline" },
                [_vm._v(" CMA payments ")]
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "d-flex align-center py-0 pl-0" },
            [_c("v-divider")],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c("ShippingInstructionParty", {
                ref: "payerParty",
                attrs: {
                  company: _vm.company,
                  preselectedParty: _vm.savedPayerParty,
                  partyFunction: _vm.payerPartyFunction,
                },
                on: { "select-party": (party) => (_vm.payerParty = party) },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pa-0", attrs: { cols: "auto" } },
            [
              _c(
                "v-col",
                { staticClass: "pa-1" },
                [
                  _c("v-text-field", {
                    staticClass: "xs-input",
                    attrs: {
                      label: _vm.$t("label.placeOfPayment"),
                      value: _vm.savedInvoicePayableAt.locationName,
                      rules: _vm.required,
                      outlined: "",
                      readonly: "",
                      dense: "",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pa-1 py-0" },
                [
                  _c(
                    "v-radio-group",
                    {
                      staticClass: "radio-group ma-0 pt-0",
                      attrs: { rules: _vm.required, dense: "" },
                      model: {
                        value: _vm.paymentCode,
                        callback: function ($$v) {
                          _vm.paymentCode = $$v
                        },
                        expression: "paymentCode",
                      },
                    },
                    [
                      _c(
                        "v-radio",
                        { attrs: { value: "PRE" } },
                        [
                          _c("template", { slot: "label" }, [
                            _c("span", { staticClass: "text-caption" }, [
                              _vm._v("Prepaid"),
                            ]),
                          ]),
                        ],
                        2
                      ),
                      _c(
                        "v-radio",
                        { attrs: { value: "COL" } },
                        [
                          _c("template", { slot: "label" }, [
                            _c("span", { staticClass: "text-caption" }, [
                              _vm._v("Collect"),
                            ]),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }