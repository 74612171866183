import { mapState, mapMutations } from 'vuex'

export default {
  data() {
    return {
      packageVersion: process.env.VUE_APP_VERSION || '0.0.0',
    }
  },
  computed: {
    ...mapState('loading', ['loadingPage']),
  },
  methods: {
    ...mapMutations({ setLoading: 'loading/setLoading' }),
    ...mapMutations({ setSnackbarMessage: 'snackbar/setSnackbarMessage' }),
  }
}
