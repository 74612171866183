<template>
  <v-dialog v-model="dialog" width="fit-content">
    <template v-slot:activator="{ on }">
      <v-text-field
        :label="label"
        v-model="selectedDateTime"
        v-on="disabled ? null : on"
        append-icon="mdi-calendar"
        @click:append="disabled ? null : (dialog = !dialog)"
        :outlined="appearance.includes('outlined')"
        :solo="appearance.includes('solo')"
        :flat="appearance.includes('flat')"
        :clearable="!disabled"
        :class="inputClass"
        :hide-details="hideDetails"
        :rules="rules"
        readonly
        dense
      />
    </template>
    <v-carousel
      v-model="page"
      hide-delimiters
      :show-arrows="false"
      width="100%"
      height="100%"
      touchless
    >
      <v-carousel-item :value="0">
        <v-date-picker
          v-model="date"
          @change="page = 1"
          color="primary"
          :readonly="disabled"
        />
      </v-carousel-item>
      <v-carousel-item :value="1">
        <v-time-picker v-model="time" format="24hr" scrollable v-if="dialog" />
        <!-- @change="formatDateTime" -->
      </v-carousel-item>
    </v-carousel>
  </v-dialog>
</template>

<script>
import { dateFilter } from '@/filters/dateFilter'

export default {
  name: 'DateTimePickerDialog',
  props: [
    'label',
    'appearance',
    'disabled',
    'preselectedDate',
    'inputClass',
    'hideDetails',
    'rules',
  ],
  emits: ['select-date'],
  components: {},
  data() {
    return {
      page: 0,
      dialog: false,

      date: null,
      time: null,
      dateTime: null,

      selectedDateTime: null,
    }
  },
  watch: {
    preselectedDate() {
      this.selectPreselectedDate()
    },
    dialog(visible) {
      this.page = 0
      if (visible) return
      this.formatDateTime()
      this.select()
    },
    selectedDateTime(current) {
      if (current) return
      this.dateTime = new Date().toISOString()
      this.formatDateAndTime(this.dateTime)
    },
  },
  methods: {
    init() {
      this.selectPreselectedDate()
    },
    selectPreselectedDate() {
      this.dateTime = this.preselectedDate ? this.preselectedDate : new Date().toISOString()
      this.formatDateAndTime(this.dateTime)
      if (this.preselectedDate) this.select()
    },
    formatDateAndTime(dt) {
      let dateTime = new Date(dt)
      this.time = dateFilter.formatDateTime(dateTime)
      this.date = dateFilter.formatDateDayIso(dateTime)
      this.formatDateTime()
    },
    select() {
      this.selectedDateTime = this.formatDate(this.dateTime)
      this.$emit('select-date', this.dateTime)
    },
    formatDateTime() {
      let time = this.time + ':00.000'
      this.dateTime = `${this.date}T${time}`
    },
    // Utils
    formatDate(value) {
      return dateFilter.formatDate(value)
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>
.xs-input::v-deep input {
  padding: 0.5rem !important;
  padding-left: 0 !important;
}
.xs-input::v-deep .v-label {
  position: unset !important;
  min-width: max-content;
  margin-bottom: 0 !important;
}
.xs-input::v-deep .v-input__slot {
  min-height: 0 !important;
  padding-right: 4px !important;
}
.xs-input::v-deep .v-input__append-inner {
  margin: 0 !important;
  align-self: center !important;
}
.xs-input::v-deep .v-label--active {
  position: absolute !important;
  margin-bottom: 0 !important;
}
.xs-input::v-deep .v-text-field__details {
  margin: 0 !important;
}
</style>
