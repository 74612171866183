import { authHeader } from '@/helpers/auth-header'
import { commons } from './commons'

export const praticaSemaforiService = {
  getByPraticaId,
}

const resourceUrl = process.env.VUE_APP_GSCV_ENGINE_URL + `/pratica-semafori`

function getByPraticaId(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/pratica/${id}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}
