<template>
  <v-container id="main" class="d-flex justify-center" fill-height fluid>
    <div class="d-flex flex-column align-center">
      <h2 style="color: #c3342f;">{{ appTitle }}</h2>
      <div class="card">
        <div class="card-body">
          <v-form @submit.prevent="submit">
            <v-alert v-if="errorMessage" type="error" dismissible>
              {{ errorMessage }}
            </v-alert>

            <div class="form-group">
              <v-text-field v-model="username"
                            label="Username"></v-text-field>
            </div>
            <div class="form-group">
              <v-text-field v-model="password"
                            label="Password"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showPassword ? 'text' : 'password'"
                            @click:append="showPassword = !showPassword"></v-text-field>
            </div>
            <v-btn color="primary" type="submit">Login</v-btn>
          </v-form>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: "LandingPageCircle",
  data() {
    return {
      username: '',
      password: '',
      showPassword: false,
      appTitle: process.env.VUE_APP_TITLE,
      errorMessage: null
    }
  },
  mounted() {
    this.errorMessage = localStorage.getItem('errorMessage');
    localStorage.removeItem('errorMessage');
  },
  created() {
    if (localStorage.getItem('user')) this.$router.push('/app')
  },
  methods: {
    ...mapActions('account', ['loginCircle']),
    submit() {
      const {username, password} = this;
      if (username && password) {
        this.loginCircle({username, password}).catch(error => {
          this.errorMessage = error;
          localStorage.setItem('errorMessage', this.errorMessage);
        })
      }
    }
  }
}
</script>

<style scoped>

</style>