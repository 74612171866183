var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "d-flex flex-column" },
    [
      _c(
        "v-row",
        { staticClass: "d-flex flex-row align-center" },
        [
          _c(
            "v-col",
            { staticClass: "d-flex justify-start", attrs: { cols: "3" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    download: "",
                    href: _vm.downloadPath,
                  },
                },
                [_vm._v(" Download ")]
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [
              _c("v-checkbox", {
                attrs: { label: "Overwrite" },
                model: {
                  value: _vm.checkbox,
                  callback: function ($$v) {
                    _vm.checkbox = $$v
                  },
                  expression: "checkbox",
                },
              }),
              _c("v-file-input", {
                attrs: { label: "File input", accept: ".xlsx", clearable: "" },
                on: { change: _vm.onFileSelected },
                model: {
                  value: _vm.selectedFile,
                  callback: function ($$v) {
                    _vm.selectedFile = $$v
                  },
                  expression: "selectedFile",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.resultsDisplay
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.results,
                      "hide-default-footer": "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }