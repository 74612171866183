var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-footer",
    { attrs: { color: "primary text-white", app: "", height: "50" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "6" } }, [
            _c(
              "span",
              [
                _c("v-img", {
                  attrs: {
                    src: require("@/assets/logo-cirle-white.png"),
                    "max-width": "100",
                    contain: "",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "v-col",
            { staticClass: "text-right text-caption", attrs: { cols: "6" } },
            [
              _vm._v("2023 - "),
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v("MILOS® Tracking Federative Dashboard"),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }