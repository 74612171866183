var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "stepper-wrapper", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "ma-0 fill-height" },
        [
          _c(
            "v-col",
            { staticClass: "d-table fill-height pa-1" },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  staticClass: "d-block pa-1 overflow-y-auto fill-height",
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "px-1", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-subheader",
                            { staticClass: "pa-0 primary--text text-overline" },
                            [_vm._v(" Voyage info ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "d-flex align-center py-0 pl-0" },
                        [_c("v-divider")],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mt-0 px-1", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("ReadonlyField", {
                            attrs: {
                              label: _vm.$t("label.departure"),
                              value: _vm.formatDate(_vm.departure),
                              icon: "mdi-calendar",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("ReadonlyField", {
                            attrs: {
                              label: _vm.$t("label.arrival"),
                              value: _vm.formatDate(_vm.arrival),
                              icon: "mdi-calendar",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "px-1", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("ReadonlyField", {
                            attrs: {
                              label: _vm.$t("label.vessel"),
                              value: _vm.bookingReqMainVessel,
                              icon: "mdi-ferry",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "div",
                          { staticClass: "pl-8" },
                          [
                            _c("ReadonlyField", {
                              attrs: {
                                label: _vm.$t("label.voyage"),
                                value: _vm.bookingReqVoyageNbr,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mt-5", attrs: { dense: "" } },
                    [
                      _vm.bookingReqPolHaulageLocation
                        ? _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "px-2 py-1",
                                  attrs: {
                                    width: "100%",
                                    height: "fit-content",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("ReadonlyField", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "label.placeOfReceipt"
                                                  ),
                                                  value: _vm.formatAddress(
                                                    _vm.bookingReqPolHaulageLocation
                                                  ),
                                                  icon: "mdi-map-marker-account",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-textarea", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "label.placeOfReceiptOnBillOfLading"
                                                  ),
                                                  rules: _vm.required,
                                                  "auto-grow": "",
                                                  clearable: "",
                                                  outlined: "",
                                                  rows: "2",
                                                },
                                                model: {
                                                  value:
                                                    _vm.placeOfReceiptLabelOnDoc,
                                                  callback: function ($$v) {
                                                    _vm.placeOfReceiptLabelOnDoc =
                                                      $$v
                                                  },
                                                  expression:
                                                    "placeOfReceiptLabelOnDoc",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        { staticClass: "d-inline-flex", attrs: { cols: "3" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "px-2 py-1",
                              attrs: { width: "100%", height: "fit-content" },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("ReadonlyField", {
                                            attrs: {
                                              label: _vm.$t("label.pol"),
                                              value: _vm.pol,
                                              icon: "mdi-map-marker",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-textarea", {
                                            attrs: {
                                              label: _vm.$t(
                                                "label.polOnBillOfLading"
                                              ),
                                              rules: _vm.required,
                                              "auto-grow": "",
                                              clearable: "",
                                              outlined: "",
                                              rows: "2",
                                            },
                                            model: {
                                              value: _vm.polLabelOnDoc,
                                              callback: function ($$v) {
                                                _vm.polLabelOnDoc = $$v
                                              },
                                              expression: "polLabelOnDoc",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "d-inline-flex", attrs: { cols: "3" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "px-2 py-1",
                              attrs: { width: "100%", height: "fit-content" },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("ReadonlyField", {
                                            attrs: {
                                              label: _vm.$t("label.pod"),
                                              value: _vm.pod,
                                              icon: "mdi-map-marker",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-textarea", {
                                            attrs: {
                                              label: _vm.$t(
                                                "label.podOnBillOfLading"
                                              ),
                                              rules: _vm.required,
                                              "auto-grow": "",
                                              clearable: "",
                                              outlined: "",
                                              rows: "2",
                                            },
                                            model: {
                                              value: _vm.podLabelOnDoc,
                                              callback: function ($$v) {
                                                _vm.podLabelOnDoc = $$v
                                              },
                                              expression: "podLabelOnDoc",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.bookingReqPodHaulageLocation
                        ? _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "px-2 py-1",
                                  attrs: {
                                    width: "100%",
                                    height: "fit-content",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("ReadonlyField", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "label.placeOfDelivery"
                                                  ),
                                                  value: _vm.formatAddress(
                                                    _vm.bookingReqPodHaulageLocation
                                                  ),
                                                  icon: "mdi-map-marker-account",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-textarea", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "label.placeOfDeliveryOnBillOfLading"
                                                  ),
                                                  rules: _vm.required,
                                                  "auto-grow": "",
                                                  clearable: "",
                                                  outlined: "",
                                                  rows: "2",
                                                },
                                                model: {
                                                  value:
                                                    _vm.placeOfDischargeLabelOnDoc,
                                                  callback: function ($$v) {
                                                    _vm.placeOfDischargeLabelOnDoc =
                                                      $$v
                                                  },
                                                  expression:
                                                    "placeOfDischargeLabelOnDoc",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "flex-grow-0 ma-0" },
        [
          _c(
            "v-col",
            { staticClass: "d-flex pa-2" },
            [
              _c(
                "v-btn",
                {
                  attrs: { small: "", icon: "", color: "primary" },
                  on: { click: _vm.exitRequest },
                },
                [
                  _c("v-icon", { attrs: { size: "30" } }, [
                    _vm._v("mdi-arrow-left-circle"),
                  ]),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  attrs: { small: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("step", false)
                    },
                  },
                },
                [
                  _c("v-icon", [_vm._v("mdi-step-backward")]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("label.back")))]),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { small: "", color: "primary" },
                  on: { click: _vm.submitVoyage },
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.$t("label.next")))]),
                  _c("v-icon", [_vm._v("mdi-play")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }