var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "offset-y": "",
        "close-on-click": "",
        "open-on-click": false,
        "close-on-content-click": false,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              !_vm.showSearch
                ? _c("v-text-field", {
                    class: _vm.textClass,
                    attrs: {
                      label: _vm.label,
                      value: _vm.currentSelection,
                      "prepend-inner-icon": _vm.icon,
                      "append-icon":
                        _vm.selected.text && _vm.selected.text.length
                          ? "mdi-close"
                          : "",
                      "hide-details": "",
                      readonly: "",
                      dense: "",
                    },
                    on: {
                      click: function ($event) {
                        _vm.showSearch = true
                      },
                      "click:append": _vm.clearSelection,
                    },
                  })
                : _c(
                    "v-text-field",
                    _vm._g(
                      {
                        class: _vm.textClass,
                        attrs: {
                          label: _vm.label,
                          "prepend-inner-icon": _vm.icon,
                          "append-icon": "mdi-magnify",
                          "hide-details": "",
                          dense: "",
                        },
                        on: {
                          click: [
                            function ($event) {
                              $event.stopPropagation()
                            },
                            function ($event) {
                              _vm.showSearch = !_vm.showSearch
                            },
                          ],
                          "click:append": function ($event) {
                            return _vm.browseList(_vm.search)
                          },
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.browseList(_vm.search)
                          },
                          blur: _vm.resetSearchBox,
                        },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      },
                      on
                    )
                  ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.menu,
        callback: function ($$v) {
          _vm.menu = $$v
        },
        expression: "menu",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "dropdown-menu" },
        [
          _c("v-data-table", {
            key: _vm.tableSelection.length,
            staticClass: "custom-table",
            attrs: {
              headers: [{ text: "", value: _vm.text }],
              items: _vm.filterList,
              "items-per-page": 10,
              page: _vm.tablePage,
              "hide-default-header": "",
              "checkbox-color": "primary",
              "show-select": "",
              dense: "",
            },
            on: {
              "update:page": function ($event) {
                _vm.tablePage = $event
              },
              "item-selected": (v) => _vm.selectItem(v),
            },
            scopedSlots: _vm._u([
              {
                key: "item[text]",
                fn: function ({ item }) {
                  return [
                    _c("td", { staticClass: "custom-table-text-td" }, [
                      _vm._v(" " + _vm._s(item[_vm.text]) + " "),
                    ]),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.tableSelection,
              callback: function ($$v) {
                _vm.tableSelection = $$v
              },
              expression: "tableSelection",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }