var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-carousel",
    {
      staticClass: "pa-1",
      attrs: {
        "hide-delimiters": "",
        "show-arrows": false,
        width: "100%",
        height: "100%",
        touchless: "",
      },
      model: {
        value: _vm.page,
        callback: function ($$v) {
          _vm.page = $$v
        },
        expression: "page",
      },
    },
    [
      _c(_vm.pageToShow.component, {
        key: _vm.pageToShow.ref,
        ref: _vm.pageToShow.ref,
        tag: "component",
        attrs: { pratica: _vm.pratica, imballi: _vm.imballi },
        on: {
          "select-request": _vm.selectElectronicFlow,
          "exit-request": _vm.exitRequest,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }