var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expand-transition",
    [
      _c(
        "v-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.open,
              expression: "open",
            },
          ],
          staticClass: "rounded-0",
          attrs: { outlined: "" },
        },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    { staticClass: "black--text" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "d-inline-flex" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pa-0" },
                                [
                                  _c("ReadonlyField", {
                                    attrs: {
                                      label: _vm.$t("label.awb"),
                                      value: _vm.pratica.awb,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "d-inline-flex" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pa-0" },
                                [
                                  _c("ReadonlyField", {
                                    attrs: {
                                      label: _vm.$t("label.airCompany"),
                                      value: _vm.pratica.supplierName,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "d-inline-flex" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pa-0" },
                                [
                                  _c("ReadonlyField", {
                                    attrs: {
                                      label: _vm.$t("label.loadingNode"),
                                      value: _vm.pratica.pol
                                        ? _vm.pratica.pol
                                        : "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "d-inline-flex" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pa-0" },
                                [
                                  _c("ReadonlyField", {
                                    attrs: {
                                      label: _vm.$t("label.dischargeNode"),
                                      value: _vm.pratica.pod
                                        ? _vm.pratica.pod
                                        : "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "d-inline-flex" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pa-0" },
                                [
                                  _c("ReadonlyField", {
                                    attrs: {
                                      label: _vm.$t("label.etd"),
                                      value: _vm.etdDate,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "d-inline-flex" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pa-0" },
                                [
                                  _c("ReadonlyField", {
                                    attrs: {
                                      label: _vm.$t("label.eta"),
                                      value: _vm.etaDate,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "d-inline-flex" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pa-0" },
                                [
                                  _c("ReadonlyField", {
                                    attrs: {
                                      label: _vm.$t("label.status"),
                                      value: _vm.trackResponse.status,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "ma-0 mt-3" },
                [
                  _c("v-card-title", { staticClass: "py-1 px-3" }, [
                    _c("span", { staticClass: "text-overline primary--text" }, [
                      _vm._v(" " + _vm._s(_vm.$t("upper.airportEvents")) + " "),
                    ]),
                  ]),
                  _c("v-divider", { staticClass: "my-0 mx-2" }),
                  _c(
                    "v-data-table",
                    {
                      staticClass: "px-2 py-0",
                      attrs: {
                        headers: _vm.airportEventsHeaders,
                        items: _vm.airportEvents,
                        "items-per-page": 1000,
                        "hide-default-footer": "",
                        dense: "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "item.planned",
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " + _vm._s(_vm.formatDate(item.planned)) + " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.actual",
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " + _vm._s(_vm.formatDate(item.actual)) + " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.isReplanned",
                          fn: function ({ item }) {
                            return [
                              item.isReplanned
                                ? _c(
                                    "v-icon",
                                    { attrs: { color: "primary" } },
                                    [_vm._v(" mdi-check-bold ")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "template",
                        { slot: "no-data" },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-1",
                              attrs: { color: "warning", size: "20" },
                            },
                            [_vm._v(" mdi-alert ")]
                          ),
                          _c(
                            "span",
                            { staticClass: "font-weight-medium warning--text" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("label.noTrackingInfo")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "ma-0 mt-3" },
                [
                  _c("v-card-title", { staticClass: "py-1 px-3" }, [
                    _c("span", { staticClass: "text-overline primary--text" }, [
                      _vm._v(" " + _vm._s(_vm.$t("upper.routingEvents")) + " "),
                    ]),
                  ]),
                  _c("v-divider", { staticClass: "my-0 mx-2" }),
                  _c(
                    "v-data-table",
                    {
                      staticClass: "px-2 py-0",
                      attrs: {
                        headers: _vm.trackingHeadersAereo,
                        items: _vm.routingEvents,
                        "items-per-page": 1000,
                        "hide-default-footer": "",
                        "sort-by": "departureActualTime",
                        "sort-desc": false,
                        dense: "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "item.departureScheduleTime",
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatDate(item.departureScheduleTime)
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.departureActualTime",
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatDate(item.departureActualTime)
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.arrivalScheduleTime",
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatDate(item.arrivalScheduleTime)
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.arrivalActualTime",
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatDate(item.arrivalActualTime)
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.isReplanned",
                          fn: function ({ item }) {
                            return [
                              item.isReplanned
                                ? _c(
                                    "v-icon",
                                    { attrs: { color: "primary" } },
                                    [_vm._v(" mdi-check-bold ")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "template",
                        { slot: "no-data" },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-1",
                              attrs: { color: "warning", size: "20" },
                            },
                            [_vm._v(" mdi-alert ")]
                          ),
                          _c(
                            "span",
                            { staticClass: "font-weight-medium warning--text" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("label.noTrackingInfo")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }