<template>
  <v-card class="my-1" outlined>
    <v-row
      dense
      :class="['ma-0', { disabled: this.invoice.status === 'SENT' }]"
      @click="linkInvoiceToShipment"
    >
      <!-- Invoice nbr. -->
      <v-col
        cols="auto"
        class="py-0 text-caption font-weight-bold d-flex align-center"
      >
        <v-row dense class="ma-0 d-flex flex-column justify-center text-left">
          <v-col class="d-flex flex-column py-0">
            <span
              class="text-subtitle-1 font-weight-black"
              v-text="invoice.invoiceNbr"
            ></span>
            <v-chip color="#e0e0e0" label small class="py-0">
              <v-icon color="#757575" small>mdi-file-document-outline</v-icon>
              <span class="font-weight-medium ml-1 text-caption text-center">
                {{ $t("label.invoiceNbr") }}
              </span>
            </v-chip>
          </v-col>
          <v-col
            class="py-0 d-flex justify-center align-center"
            style="min-height: 40px"
          >
            <v-icon v-if="invoice.isLinked" color="success" class="py-1"
              >mdi-check-bold</v-icon
            >
          </v-col>
        </v-row>
      </v-col>
      <v-divider vertical />

      <!-- Invoice Recipient & Seller -->
      <v-col class="py-0 text-caption font-weight-bold d-flex align-center"
        ><v-row dense class="ma-0 d-flex flex-column justify-center text-left">
          <v-col class="py-0">
            <ReadonlyField
              :label="$t('label.invoiceHolderName')"
              :value="invoice.invoiceHolderName"
            />
          </v-col>
          <v-divider class="ma-1" />
          <v-col class="py-0">
            <ReadonlyField
              :label="$t('label.sellerName')"
              :value="invoice.sellerName"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-divider vertical />

      <!-- Consignee & Total Amount -->
      <v-col class="py-0 text-caption font-weight-bold d-flex align-center"
        ><v-row dense class="ma-0 d-flex flex-column justify-center text-left">
          <v-col class="py-0">
            <ReadonlyField
              :label="$t('label.consigneeName')"
              :value="invoice.consigneeName"
            />
          </v-col>
          <v-divider class="ma-1" />
          <v-col class="py-0">
            <ReadonlyField
              :label="$t('label.totalAmount')"
              :value="invoice.totalAmount + ' ' + invoice.currency"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-divider vertical />
    </v-row>
  </v-card>
</template>

<script>
import ReadonlyField from "../elements/ReadonlyField.vue";

export default {
  data() {
    return {};
  },
  props: ["invoice"],
  emits: ["invoice-document-nbr"],
  methods: {
    linkInvoiceToShipment() {
      if (this.invoice.status != "SENT") {
        const businessKey = {
          organizationUnitId: this.invoice.organizationUnitId,
          documentNbr: this.invoice.documentNbr,
          invoiceNbr: this.invoice.invoiceNbr,
          sellerCode: this.invoice.sellerCode,
        };
        this.$emit("invoice-document-nbr", businessKey, !this.invoice.isLinked);
      }
    },
  },
  components: { ReadonlyField },
};
</script>

<style scoped>
.disabled {
  background-color: #f5f5f5;
}
</style>
