var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: {
            transition: "scale-transition",
            origin: "center center",
            "offset-overflow": "",
            "offset-y": "",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-chip",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            color: _vm.internalVisibilityAuth
                              ? _vm.status
                                ? _vm.status.toLowerCase()
                                : "grey"
                              : "primary",
                            label: "",
                            small: "",
                          },
                          on: { click: _vm.loadSemafori },
                        },
                        "v-chip",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _vm.pratica.saleChannel == 40
                        ? _c("v-icon", { attrs: { size: "15" } }, [
                            _vm._v(" mdi-airplane "),
                          ])
                        : _vm._e(),
                      _vm.pratica.saleChannel == 30
                        ? _c("v-icon", { attrs: { size: "15" } }, [
                            _vm._v(" mdi-truck "),
                          ])
                        : _vm._e(),
                      _vm.pratica.saleChannel == 20
                        ? _c("v-icon", { attrs: { size: "15" } }, [
                            _vm._v(" mdi-ferry "),
                          ])
                        : _vm._e(),
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-medium ml-1 text-uppercase",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.pratica.saleChannel == 40
                                  ? _vm.$t("label.plane")
                                  : _vm.pratica.saleChannel == 30
                                  ? _vm.$t("label.road")
                                  : _vm.$t("label.sea")
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm.internalVisibilityAuth && _vm.semafori.length
            ? [
                _c(
                  "v-simple-table",
                  {
                    staticClass: "py-1",
                    staticStyle: { "background-color": "white" },
                  },
                  _vm._l(_vm.semafori, function (semaforo, i) {
                    return _c("tr", { key: i }, [
                      _c("td", { staticClass: "text-caption pl-2" }, [
                        _vm._v(
                          " " + _vm._s(semaforo.step.replace(/_/g, " ")) + " "
                        ),
                      ]),
                      _c(
                        "td",
                        { staticClass: "px-2 pb-1" },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                small: "",
                                color:
                                  semaforo && semaforo.status
                                    ? semaforo.status.toLowerCase()
                                    : "grey",
                              },
                            },
                            [_vm._v(" mdi-circle ")]
                          ),
                        ],
                        1
                      ),
                    ])
                  }),
                  0
                ),
              ]
            : _vm._e(),
          _vm.internalVisibilityAuth && !_vm.semafori.length
            ? [
                _c("v-card", { staticClass: "d-flex justify-center" }, [
                  _c("span", { staticClass: "text-caption ma-1" }, [
                    _vm._v(" " + _vm._s(_vm.$t("label.noAvailableData")) + " "),
                  ]),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }