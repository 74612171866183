var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "30%" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-textarea",
                _vm._g(
                  {
                    attrs: {
                      label: _vm.label,
                      value: _vm.addressLabel,
                      rules: _vm.rules,
                      rows: "3",
                      "validate-on-blur": "",
                      "auto-grow": "",
                      outlined: "",
                    },
                  },
                  on
                )
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "text-overline primary--text pa-2 px-3" },
            [_vm._v(" " + _vm._s(_vm.title) + " ")]
          ),
          _c("v-divider", { staticClass: "ma-0" }),
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-form",
                    { ref: "form" },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0" },
                            [
                              _c("v-text-field", {
                                staticClass: "xs-input",
                                attrs: {
                                  label: _vm.$t("label.company"),
                                  rules: _vm.required,
                                  outlined: "",
                                  dense: "",
                                },
                                model: {
                                  value: _vm.address.companyName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.address, "companyName", $$v)
                                  },
                                  expression: "address.companyName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0" },
                            [
                              _c("v-text-field", {
                                staticClass: "xs-input",
                                attrs: {
                                  label: _vm.$t("label.street"),
                                  rules: _vm.required,
                                  outlined: "",
                                  dense: "",
                                },
                                model: {
                                  value: _vm.address.street,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.address, "street", $$v)
                                  },
                                  expression: "address.street",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pb-0", attrs: { cols: "3" } },
                            [
                              _c("v-text-field", {
                                staticClass: "xs-input",
                                attrs: {
                                  label: _vm.$t("label.number"),
                                  rules: _vm.required,
                                  outlined: "",
                                  dense: "",
                                },
                                model: {
                                  value: _vm.address.streetNumber,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.address, "streetNumber", $$v)
                                  },
                                  expression: "address.streetNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                staticClass: "xs-input",
                                attrs: {
                                  label: _vm.$t("label.city"),
                                  rules: _vm.required,
                                  "hide-details": "",
                                  outlined: "",
                                  readonly: "",
                                  dense: "",
                                },
                                model: {
                                  value: _vm.address.city,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.address, "city", $$v)
                                  },
                                  expression: "address.city",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                staticClass: "xs-input",
                                attrs: {
                                  label: _vm.$t("label.postCode"),
                                  rules: _vm.required,
                                  "hide-details": "",
                                  outlined: "",
                                  dense: "",
                                },
                                model: {
                                  value: _vm.address.postCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.address, "postCode", $$v)
                                  },
                                  expression: "address.postCode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                staticClass: "xs-input",
                                attrs: {
                                  label: _vm.$t("label.country"),
                                  rules: _vm.required,
                                  "hide-details": "",
                                  outlined: "",
                                  readonly: "",
                                  dense: "",
                                },
                                model: {
                                  value: _vm.address.country,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.address, "country", $$v)
                                  },
                                  expression: "address.country",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }