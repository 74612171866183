var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-stepper",
    {
      staticClass: "d-table fill-height",
      attrs: { width: "100%", "max-width": "100%", light: "" },
      model: {
        value: _vm.step,
        callback: function ($$v) {
          _vm.step = $$v
        },
        expression: "step",
      },
    },
    [
      _c("v-stepper-header", { staticClass: "d-table-row" }, [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c(
              "v-stepper-step",
              { attrs: { step: 1, editable: _vm.editableSelectedVoyage } },
              [
                _c("span", { staticClass: "text-overline" }, [
                  _vm._v(" " + _vm._s(_vm.$t("label.selectVoyage")) + " "),
                ]),
              ]
            ),
            _c("v-divider"),
            _c(
              "v-stepper-step",
              { attrs: { step: 2, editable: _vm.editableSelectedCommodity } },
              [
                _c("span", { staticClass: "text-overline" }, [
                  _vm._v(" " + _vm._s(_vm.$t("label.selectCommodity")) + " "),
                ]),
              ]
            ),
            _c("v-divider"),
            _c(
              "v-stepper-step",
              { attrs: { step: 3, editable: _vm.editableReview } },
              [
                _c("span", { staticClass: "text-overline" }, [
                  _vm._v(" " + _vm._s(_vm.$t("label.review")) + " "),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "v-stepper-items",
        { staticClass: "d-table-row" },
        [
          _c(
            _vm.stepToShow.component,
            _vm._g(
              {
                key: _vm.stepToShow.component,
                ref: _vm.stepToShow.ref,
                tag: "component",
                attrs: { pratica: _vm.pratica, imballi: _vm.imballi },
                on: { step: _vm.handleSteps },
              },
              _vm.$listeners
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }