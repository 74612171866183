import { foreignAgentService } from '@/services/foreignAgent.service.js'
import { foreignAgentContactService } from '@/services/foreignAgentContact.service.js'
import { foreignAgentNonRevenueService } from '@/services/foreignAgentNonRevenue.service.js'
import CustomAutocomplete from '@/components/elements/CustomAutocomplete.vue'
import BrowseMemory from '@/mixins/BrowseMemory'

export default {
  name: 'Filters',
  emits: ['set-filter'],
  mixins: [BrowseMemory],
  components: { CustomAutocomplete },
  data() {
    return {
      foreignAgent: undefined,
      foreignAgentContacts: [],
      foreignAgentNonRevenueSelected: undefined,
      foreignAgentsNonRevenue: [],
    }
  },
  methods: {
    async getForeignAgentForPratica(praticaId) {
      const resp = await foreignAgentService.getForeignAgentForPratica(praticaId);
      return this.createIfNotPresent(resp, praticaId);
    },

    async createIfNotPresent(resp, praticaId) {
      if (!resp) {
        const agent = await this.createForeignAgentForPratica(praticaId);
        this.foreignAgent = agent;
        this.loadForeignAgentContacts(praticaId);
        return agent;
      } else {
        this.foreignAgent = resp;
        return resp;
      }
    },
    setSelectedForeignAgentNonRevenue() {
      if (this.foreignAgent?.foreignAgentNonRevenueId) {
        let agents = this.foreignAgentsNonRevenue.filter((a) => a.sapCode == this.foreignAgent.foreignAgentNonRevenueId && a.destination == this.foreignAgent.destination)
        this.foreignAgentNonRevenueSelected = agents[0]
      } else {
        this.$refs.foreignAgentNRSelect.reset()
      }
    },
    createForeignAgentForPratica(praticaId) {
      return foreignAgentService
        .createForeignAgentForPratica(praticaId)
        .then((resp) => {
          if (resp) return this.foreignAgent = resp
        })
    },
    async updateForeignAgent(id, data) {
      try {
        const resp = await foreignAgentService.updateForeignAgent(id, data)
        if (resp) {
          this.foreignAgent = resp
          this.setSnackbarMessage({ text: this.$t('message.successSaving'), color: 'success' })
          return this.foreignAgent
        } else throw new Error('Response is empty');
      } catch (error) {
        this.setSnackbarMessage({ text: `${this.$t('message.errorSaving')}`, color: 'error' })
      }
    },
    loadForeignAgentsNonRevenue() {
      let browseRequest = {
        offset: 0,
        limit: 1000,
        filter: {},
        sortingList: [],
      }
      return foreignAgentNonRevenueService.browse(browseRequest)
      .then((resp) => {
        if (!resp) return;
        this.foreignAgentsNonRevenue = resp.resultList;
      })
      .catch((e) => this.setSnackbarMessage({ text: e, color: 'error' }))
      .finally(() => this.setLoading(false))
    },
    async addForeignAgentNonRevenue(data) {
      let existing = await foreignAgentNonRevenueService.checkExisting(data.id, data.sapCode, data.destination)
      
      if (existing)
        return this.setSnackbarMessage({ text: `Already existing`, color: 'error' })
      
      let saved = new Promise((resolve) => {
        if (!data.id) {
          resolve(this.createForeignAgentsNonRevenue(data))
        } else  {
          resolve(this.updateForeignAgentsNonRevenue(data))
        }
      })

      saved.then((resp) => {
        if (!resp) return
        this.loadForeignAgentsNonRevenue()
        this.$refs.foreignAgentFormDialog.handleDialog()
      })        
    },
    removeForeignAgentNonRevenue(data) {
      this.setLoading(true)
      this.deleteForeignAgentsNonRevenue(data.id).then(() => {
        this.loadForeignAgentsNonRevenue()
      })
    },
    createForeignAgentsNonRevenue(data) {
      return foreignAgentNonRevenueService.createForeignAgentNonRevenue(data)
      .then((resp) => {
        this.setSnackbarMessage({ text: `${this.$t('message.successSaving')}`, color: 'success' })
        return resp
      })
      .catch((e) =>  this.setSnackbarMessage({ text: `${this.$t('message.errorSaving')}`, color: 'error' }))
    },
    updateForeignAgentsNonRevenue(data) {
      return foreignAgentNonRevenueService.updateForeignAgentNonRevenue(data.id, data)
      .then((resp) => {
        this.setSnackbarMessage({ text: `${this.$t('message.successSaving')}`, color: 'success' })
        return resp
      })
      .catch((e) =>  this.setSnackbarMessage({ text: `${this.$t('message.errorSaving')}`, color: 'error' }))
    },
    deleteForeignAgentsNonRevenue(id) {
      return foreignAgentNonRevenueService.deleteForeignAgentNonRevenue(id)
      .then((resp) => {
        this.setSnackbarMessage({ text: `${this.$t('message.successDeleting')}`, color: 'success' })
        return resp
      })
      .catch((e) =>  this.setSnackbarMessage({ text: `${this.$t('message.errorDeleting')}`, color: 'error' }))
    },
    loadForeignAgentContacts(praticaId) {
      return foreignAgentContactService
        .getForeignAgentContactForPratica(praticaId)
        .then((resp) => this.foreignAgentContacts = resp)
        // .finally(() => this.setLoading(false))
    },
    async saveForeignAgentNonRevenue(praticaId) {
      this.setLoading(true)

      if (!this.foreignAgent)
        await this.createForeignAgentForPratica(praticaId)

      this.mapForeignAgentNonRevenue(this.foreignAgent, this.foreignAgentNonRevenueSelected)

      await this.updateForeignAgent(this.foreignAgent.id, this.foreignAgent)

      this.loadForeignAgentContacts(praticaId).then(() => this.setLoading(false))

      this.showSave = false
    },
    updateForeignAgentNonRevenue(foreignAgent, faNonRevenue) {     
      return new Promise((resolve) => {
        this.mapForeignAgentNonRevenue(foreignAgent, faNonRevenue)
        resolve(this.updateForeignAgent(foreignAgent.id, foreignAgent))
      })
    },
    mapForeignAgentNonRevenue(agent, faNonRevenue) {
      agent.foreignAgentNonRevenueId = faNonRevenue?.sapCode ?? null
      agent.country = faNonRevenue?.country ?? null
      agent.destination = faNonRevenue?.destination ?? null
    },
    // Contact handling
    createForeignAgentContacts(contact) {
      this.setLoading(true)
      foreignAgentContactService
        .createForeignAgentContact(contact)
        .then((resp) => {
          let msg = 'Contatto ' + this.$t('label.successCreate')
          this.setSnackbarMessage({ text: msg, color: 'success' })
          this.$refs.addressBook.handleCancel()
          this.loadForeignAgentContacts(this.pratica.id)
        })
        .finally(() => this.setLoading(false))
    },
    updateForeignAgentContacts(contact) {
      this.setLoading(true)
      return foreignAgentContactService
        .updateForeignAgentContact(contact.id, contact)
        .then((resp) => {
          let msg = 'Contatto ' + this.$t('label.successUpdate')
          this.setSnackbarMessage({ text: msg, color: 'success' })
          this.$refs.addressBook.handleCancel()
          this.loadForeignAgentContacts(this.pratica.id)
        })
        .finally(() => this.setLoading(false))
    },
    deleteForeignAgentContacts(id) {
      this.setLoading(true)
      foreignAgentContactService
        .deleteForeignAgentContact(id)
        .then((resp) => {
          let msg = 'Contatto ' + this.$t('label.successDelete')
          this.setSnackbarMessage({ text: msg, color: 'success' })
          this.loadForeignAgentContacts(this.pratica.id)
        })
        .finally(() => this.setLoading(false))
    },
  }
}
