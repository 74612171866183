var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "my-1", attrs: { outlined: "" } },
    [
      _c(
        "v-row",
        {
          class: ["ma-0", { disabled: this.invoice.status === "SENT" }],
          attrs: { dense: "" },
          on: { click: _vm.linkInvoiceToShipment },
        },
        [
          _c(
            "v-col",
            {
              staticClass:
                "py-0 text-caption font-weight-bold d-flex align-center",
              attrs: { cols: "auto" },
            },
            [
              _c(
                "v-row",
                {
                  staticClass:
                    "ma-0 d-flex flex-column justify-center text-left",
                  attrs: { dense: "" },
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "d-flex flex-column py-0" },
                    [
                      _c("span", {
                        staticClass: "text-subtitle-1 font-weight-black",
                        domProps: {
                          textContent: _vm._s(_vm.invoice.invoiceNbr),
                        },
                      }),
                      _c(
                        "v-chip",
                        {
                          staticClass: "py-0",
                          attrs: { color: "#e0e0e0", label: "", small: "" },
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "#757575", small: "" } },
                            [_vm._v("mdi-file-document-outline")]
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-medium ml-1 text-caption text-center",
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("label.invoiceNbr")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0 d-flex justify-center align-center",
                      staticStyle: { "min-height": "40px" },
                    },
                    [
                      _vm.invoice.isLinked
                        ? _c(
                            "v-icon",
                            {
                              staticClass: "py-1",
                              attrs: { color: "success" },
                            },
                            [_vm._v("mdi-check-bold")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-col",
            {
              staticClass:
                "py-0 text-caption font-weight-bold d-flex align-center",
            },
            [
              _c(
                "v-row",
                {
                  staticClass:
                    "ma-0 d-flex flex-column justify-center text-left",
                  attrs: { dense: "" },
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0" },
                    [
                      _c("ReadonlyField", {
                        attrs: {
                          label: _vm.$t("label.invoiceHolderName"),
                          value: _vm.invoice.invoiceHolderName,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "ma-1" }),
                  _c(
                    "v-col",
                    { staticClass: "py-0" },
                    [
                      _c("ReadonlyField", {
                        attrs: {
                          label: _vm.$t("label.sellerName"),
                          value: _vm.invoice.sellerName,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-col",
            {
              staticClass:
                "py-0 text-caption font-weight-bold d-flex align-center",
            },
            [
              _c(
                "v-row",
                {
                  staticClass:
                    "ma-0 d-flex flex-column justify-center text-left",
                  attrs: { dense: "" },
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0" },
                    [
                      _c("ReadonlyField", {
                        attrs: {
                          label: _vm.$t("label.consigneeName"),
                          value: _vm.invoice.consigneeName,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "ma-1" }),
                  _c(
                    "v-col",
                    { staticClass: "py-0" },
                    [
                      _c("ReadonlyField", {
                        attrs: {
                          label: _vm.$t("label.totalAmount"),
                          value:
                            _vm.invoice.totalAmount +
                            " " +
                            _vm.invoice.currency,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }