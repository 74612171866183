var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "d-table-fixed pa-0",
      attrs: { "fill-height": "", fluid: "" },
    },
    [
      _c(
        "div",
        { staticClass: "d-table-header-group" },
        [
          _c(
            "v-card",
            { staticClass: "mb-2" },
            [
              _c(
                "v-row",
                { staticClass: "ma-0 py-1 pl-1" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.$router.push({
                                name: "dashboard",
                                params: { reload: _vm.search ? false : true },
                              })
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { size: "30" } }, [
                            _vm._v("mdi-arrow-left-circle"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0 pl-1",
                      staticStyle: { "padding-top": "0.1rem !important" },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-0", attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "d-flex align-center pa-0" },
                            [
                              _c(
                                "span",
                                { staticClass: "text-h5 font-weight-black" },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.pratica.documentNbr) + " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "d-flex align-center pa-0" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-h5 primary--text font-weight-black",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.pratica.shipper
                                          ? _vm.pratica.shipper.customerName
                                          : null
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "d-flex align-center pa-0" },
                            [
                              _c("span", { staticClass: "text-overline" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("label.createDate")) + " "
                                ),
                              ]),
                              _c(
                                "span",
                                { staticClass: "font-weight-bold mx-2" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatDate(_vm.pratica.documentDate)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "d-flex align-center pa-0" },
                            [
                              _c(
                                "v-row",
                                { staticClass: "ma-0", attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0 mr-5",
                                      attrs: { cols: "auto" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-overline" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("label.commoditySector")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.pratica.commoditySector
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "auto" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-overline" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("label.saleChannel")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _vm.saleChannelDesc(
                                        _vm.pratica.saleChannel
                                      ) == "Nave"
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticClass: "primary--text",
                                              attrs: { size: "20" },
                                            },
                                            [_vm._v(" mdi-ferry ")]
                                          )
                                        : _vm._e(),
                                      _vm.saleChannelDesc(
                                        _vm.pratica.saleChannel
                                      ) == "Terra"
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticClass: "primary--text",
                                              attrs: { size: "20" },
                                            },
                                            [_vm._v(" mdi-truck ")]
                                          )
                                        : _vm._e(),
                                      _vm.saleChannelDesc(
                                        _vm.pratica.saleChannel
                                      ) == "Aereo"
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticClass: "primary--text",
                                              attrs: { size: "20" },
                                            },
                                            [_vm._v(" mdi-airplane ")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.internalVisibilityAuth == true
        ? _c(
            "v-card",
            {
              staticClass: "d-table-fixed fill-height",
              attrs: { width: "100%" },
            },
            [
              _c(
                "v-tabs",
                {
                  staticClass: "d-table-header-group",
                  attrs: {
                    "background-color": "accent",
                    height: "2rem",
                    "fixed-tabs": "",
                  },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c("v-tab", [_vm._v(_vm._s(_vm.$t("label.info")))]),
                  _c("v-tab", [_vm._v(_vm._s(_vm.$t("label.documents")))]),
                  _c("v-tab", [_vm._v(_vm._s(_vm.$t("label.roadTransport")))]),
                  _c("v-tab", [_vm._v(_vm._s(_vm.$t("label.mainTransport")))]),
                  _c("v-tab", [_vm._v(_vm._s(_vm.$t("label.customsTransit")))]),
                  _c("v-tab", [
                    _vm._v(_vm._s(_vm.$t("label.foreignCorrespondent"))),
                  ]),
                  _vm.bookingAuth && _vm.showCreateBooking
                    ? _c("v-tab", [_vm._v(_vm._s(_vm.$t("label.booking")))])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  staticStyle: { display: "table-row !important" },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c(
                    "v-tab-item",
                    { staticClass: "pa-2" },
                    [
                      _c("PraticaInfo", {
                        attrs: {
                          pratica: _vm.pratica,
                          praticaActions: _vm.praticaActions,
                          company: _vm.company,
                        },
                        on: {
                          "update-props": _vm.updateActions,
                          "open-booking": _vm.openBooking,
                          "open-shipping-instruction":
                            _vm.openShippingInstruction,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { staticClass: "pa-2" },
                    [
                      _c("PraticaDocuments", {
                        attrs: { pratica: _vm.pratica },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { staticClass: "pa-2" },
                    [
                      _c("PraticaAutotrasporto", {
                        attrs: { pratica: _vm.pratica },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { staticClass: "pa-2" },
                    [
                      _vm.pratica.saleChannel == 40
                        ? _c("PraticaTrasportoPrincipaleAereo", {
                            attrs: { pratica: _vm.pratica },
                          })
                        : _vm._e(),
                      _vm.pratica.saleChannel == 20
                        ? _c("PraticaTrasportoPrincipaleMare", {
                            attrs: {
                              pratica: _vm.pratica,
                              imballi: _vm.imballi,
                              praticaActions: _vm.praticaActions,
                              company: _vm.company,
                            },
                            on: { "update-props": _vm.updateActions },
                          })
                        : _vm._e(),
                      _vm.isMastersped && _vm.pratica.saleChannel == 30
                        ? _c("PraticaTrasportoPrincipaleMare", {
                            attrs: {
                              pratica: _vm.pratica,
                              imballi: _vm.imballi,
                              praticaActions: _vm.praticaActions,
                              company: _vm.company,
                            },
                            on: { "update-props": _vm.updateActions },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { staticClass: "pa-2" },
                    [
                      _c("PraticaTransitarioDoganale", {
                        attrs: {
                          pratica: _vm.pratica,
                          praticaActions: _vm.praticaActions,
                        },
                        on: { "update-props": _vm.updateActions },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { staticClass: "pa-2" },
                    [
                      _c("PraticaCorrispondenteEstero", {
                        attrs: {
                          pratica: _vm.pratica,
                          praticaActions: _vm.praticaActions,
                        },
                        on: { "update-props": _vm.updateActions },
                      }),
                    ],
                    1
                  ),
                  _vm.bookingAuth && _vm.showCreateBooking
                    ? _c(
                        "v-tab-item",
                        { staticClass: "pa-1" },
                        [
                          _c("BookingTrial", {
                            attrs: { pratica: _vm.pratica },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.internalVisibilityAuth == false
        ? _c(
            "v-card",
            { attrs: { width: "100%" } },
            [
              _c(
                "v-tabs",
                {
                  attrs: {
                    "fixed-tabs": "",
                    "background-color": "accent",
                    height: "2rem",
                  },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c("v-tab", [
                    _vm._v(_vm._s(_vm.$t("label.trackingInformation"))),
                  ]),
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c(
                    "v-tab-item",
                    { staticClass: "pa-0" },
                    [
                      _c("PraticaExternalTracking", {
                        attrs: { praticaId: _vm.id },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }