<template>
  <v-container class="stepper-wrapper" fluid>
    <v-row class="ma-0 fill-height">
      <v-col class="d-table fill-height pa-1">
        <!-- <v-container class="d-table-row" fill-height fluid> -->
        <v-form ref="form" class="d-block pa-1 overflow-y-auto fill-height">
          <v-row dense class="px-1">
            <v-col cols="auto" class="py-0">
              <v-subheader class="pa-0 primary--text text-overline">
                Voyage info
              </v-subheader>
            </v-col>
            <v-col class="d-flex align-center py-0 pl-0">
              <v-divider />
            </v-col>
          </v-row>
          <v-row dense class="mt-0 px-1">
            <v-col cols="3">
              <ReadonlyField
                :label="$t('label.departure')"
                :value="formatDate(departure)"
                :icon="'mdi-calendar'"
              />
            </v-col>
            <v-col cols="3">
              <ReadonlyField
                :label="$t('label.arrival')"
                :value="formatDate(arrival)"
                :icon="'mdi-calendar'"
              />
            </v-col>
          </v-row>
          <v-row dense class="px-1">
            <v-col cols="3">
              <ReadonlyField
                :label="$t('label.vessel')"
                :value="bookingReqMainVessel"
                :icon="'mdi-ferry'"
              />
            </v-col>
            <v-col cols="3">
              <div class="pl-8">
                <ReadonlyField :label="$t('label.voyage')" :value="bookingReqVoyageNbr" />
              </div>
            </v-col>
          </v-row>
          <v-row dense class="mt-5">
            <v-col cols="3" v-if="bookingReqPolHaulageLocation">
              <v-card class="px-2 py-1" width="100%" height="fit-content">
                <div>
                  <v-row dense>
                    <v-col>
                      <ReadonlyField
                        :label="$t('label.placeOfReceipt')"
                        :value="formatAddress(bookingReqPolHaulageLocation)"
                        :icon="'mdi-map-marker-account'"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-textarea
                        :label="$t('label.placeOfReceiptOnBillOfLading')"
                        v-model="placeOfReceiptLabelOnDoc"
                        :rules="required"
                        auto-grow
                        clearable
                        outlined
                        rows="2"
                      />
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>
            <v-col cols="3" class="d-inline-flex">
              <v-card class="px-2 py-1" width="100%" height="fit-content">
                <div>
                  <v-row dense>
                    <v-col>
                      <ReadonlyField
                        :label="$t('label.pol')"
                        :value="pol"
                        :icon="'mdi-map-marker'"
                      />
                      <!-- :value="`${currentVoyage.originPoint.locationName}, ${currentVoyage.originPoint.countryCode}`" -->
                      <!-- <v-select
                        label="Pol shipping type"
                        v-model="polShippingType"
                        :items="shippingTypes"
                        :menu-props="menuProps"
                        class="xs-input align-self-end ml-2"
                        style="max-width: 12rem;"
                        hide-details
                        outlined
                        dense
                      /> -->
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-textarea
                        :label="$t('label.polOnBillOfLading')"
                        v-model="polLabelOnDoc"
                        :rules="required"
                        auto-grow
                        clearable
                        outlined
                        rows="2"
                      />
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>
            <v-col cols="3" class="d-inline-flex">
              <v-card class="px-2 py-1" width="100%" height="fit-content">
                <div>
                  <v-row dense>
                    <v-col>
                      <ReadonlyField
                        :label="$t('label.pod')"
                        :value="pod"
                        :icon="'mdi-map-marker'"
                      />
                      <!-- :value="`${currentVoyage.destinationPoint.locationName}, ${currentVoyage.destinationPoint.countryCode}`" -->
                      <!-- <v-select
                        label="Pod shipping type"
                        v-model="podShippingType"
                        :items="shippingTypes"
                        :menu-props="menuProps"
                        class="xs-input align-self-end ml-2"
                        style="max-width: 12rem;"
                        hide-details
                        outlined
                        dense
                      /> -->
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-textarea
                        :label="$t('label.podOnBillOfLading')"
                        v-model="podLabelOnDoc"
                        :rules="required"
                        auto-grow
                        clearable
                        outlined
                        rows="2"
                      />
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>
            <v-col cols="3" v-if="bookingReqPodHaulageLocation">
              <v-card class="px-2 py-1" width="100%" height="fit-content">
                <div>
                  <v-row dense>
                    <v-col>
                      <ReadonlyField
                        :label="$t('label.placeOfDelivery')"
                        :value="formatAddress(bookingReqPodHaulageLocation)"
                        :icon="'mdi-map-marker-account'"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-textarea
                        :label="$t('label.placeOfDeliveryOnBillOfLading')"
                        v-model="placeOfDischargeLabelOnDoc"
                        :rules="required"
                        auto-grow
                        clearable
                        outlined
                        rows="2"
                      />
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
        <!-- </v-container> -->
      </v-col>
    </v-row>
    <v-row class="flex-grow-0 ma-0">
      <v-col class="d-flex pa-2">
        <v-btn small icon color="primary" @click="exitRequest">
          <v-icon size="30">mdi-arrow-left-circle</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn small color="primary" class="mr-2" @click="$emit('step', false)">
          <v-icon>mdi-step-backward</v-icon>
          <span>{{ $t('label.back') }}</span>
        </v-btn>
        <v-btn small color="primary" @click="submitVoyage">
          <span>{{ $t('label.next') }}</span>
          <v-icon>mdi-play</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { dateFilter } from '@/filters/dateFilter'
import { electronicFlowService } from '@/services/electronicFlow.service.js'
import ShippingInstruction from '@/mixins/ShippingInstruction.js'
import ReadonlyField from '@/components/elements/ReadonlyField.vue'

export default {
  name: 'ShippingInstructionVoyage',
  props: [],
  mixins: [ShippingInstruction],
  components: { ReadonlyField },
  data() {
    return {
      placeOfReceiptLabelOnDoc: null,
      polLabelOnDoc: null,
      placeOfDischargeLabelOnDoc: null,
      podLabelOnDoc: null,
      shippingTypes: ['Empty', 'FCL', 'LCL'],
      polShippingType: 'FCL',
      podShippingType: 'FCL',
    }
  },
  computed: {
    departure() {
      return this.bookingReqVoyage ? this.bookingReqVoyage.departureDateTime : null
    },
    arrival() {
      return this.bookingReqVoyage ? this.bookingReqVoyage.arrivalDateTime : null
    },
    pol() {
      return this.bookingReqPol ? `${this.bookingReqPol.locationName}, ${this.bookingReqPol.countryCode}\n\n` : null
    },
    pod() {
      return this.bookingReqPod ? `${this.bookingReqPod.locationName}, ${this.bookingReqPod.countryCode}\n\n` : null
    },
  },
  methods: {
    init() {
      // this.findAllBookings()
      this.loadSelectedBooking()
    },
    // findAllBookings() {
    //   electronicFlowService
    //     .findAllBookingsByDocumentNbr(this.electronicFlow.documentNbr)
    //     .then((resp) => {
    //       if (!resp) return
    //       this.bookings = resp
    //     })
    //     .catch((error) =>
    //       this.setSnackbarMessage({ text: error, color: 'error' }),
    //     )
    // },
    formatDate(value) {
      return dateFilter.formatDate(value)
    },
    // Submit
    submitVoyage() {
      if (!this.$refs.form.validate()) return
      this.setExportTransport(this.getImportExportTransport(true))
      this.setImportTransport(this.getImportExportTransport(false))
      this.setShipmentLocations(this.getShipmentLocations(this.bookingRequest.shipmentLocations))

      if (this.electronicFlowStatus == this.detailsSelectedStatus) this.setStatus(this.voyageSelectedStatus)
      this.updateShippingInstructionAndSaveElectronicFlow()
      this.$emit('step', true)
    },
    getImportExportTransport(isExport) {
      return {
        haulageType: isExport ? this.bookingReqPolHaulage : this.bookingReqPodHaulage,
        movementType: this.getMovementType(isExport),
        shippingType: isExport ? this.polShippingType : this.podShippingType,
      }
    },
    getMovementType(isExport){
      if (isExport){
        return this.bookingReqPolHaulageMovementType ? this.bookingReqPolHaulageMovementType : 'PORT'
      } else {
        return this.bookingReqPodHaulageMovementType ? this.bookingReqPodHaulageMovementType : 'PORT'
      }
    },
    getShipmentLocations(){
      let locations = []
      this.bookingRequest.shipmentLocations.forEach(location => {
        locations.push(this.getShipmentLocation(location))
      });
      return locations
    },
    getShipmentLocation(location) {
      return {
        locationType: location.shipmentLocationTypeCode,
        location: {
          unLocationCode: location.location.UNLocationCode,
          locationName: location.location.locationName
        },
        displayedName: this.getDisplayedName(location.shipmentLocationTypeCode),
      }
    },
    getDisplayedName(type){
      switch (type) {
        case 'RECEPIT':
          return this.placeOfReceiptLabelOnDoc
        case 'POD': 
          return this.polLabelOnDoc
        case 'POL':
          return this.podLabelOnDoc
        case 'DELIVERY':
          return this.placeOfDeliveryLabelOnDoc
      }
    }
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>
.stepper-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
}
.v-subheader {
  height: 2rem;
}
.radio-group::v-deep .v-input--radio-group__input {
  flex-direction: row;
}
.v-radio {
  max-width: max-content;
  margin: 0 1rem 0 0 !important;
}
.v-radio::v-deep .v-label {
  margin: 0 !important;
}
.equipment-check::v-deep label {
  margin-bottom: 0 !important;
}
.v-list::v-deep .v-list-item__action {
  margin-right: 0.5rem !important;
}
.xs-input::v-deep input {
  padding: 0.5rem !important;
  padding-left: 0 !important;
}
.xs-input::v-deep .v-label {
  position: unset !important;
  min-width: max-content;
  margin-bottom: 0 !important;
}
.xs-input::v-deep .v-input__slot {
  min-height: 0 !important;
  padding-right: 4px !important;
}
.xs-input::v-deep .v-input__append-inner {
  margin: 0 !important;
  align-self: center !important;
}
.xs-input::v-deep .v-label--active {
  position: absolute !important;
  margin-bottom: 0 !important;
}
.xs-input::v-deep .v-select__selections {
  padding: 0 !important;
}
.v-input::v-deep .v-text-field__details {
  margin: 0 !important;
}
.shipped-switch::v-deep label {
  margin: 0 !important;
  width: 4rem;
}
.number-select {
  max-width: 3rem;
}
/*.row {
  border: solid 1px red;
}
.col {
  border: solid 1px orange;
}*/
</style>
