var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      staticClass: "primary",
      attrs: { fab: "", icon: "", height: "50", width: "50" },
      on: { click: _vm.generateLink },
    },
    [
      _c("v-icon", { attrs: { color: "white", size: "30" } }, [
        _vm._v("mdi-link-variant"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }