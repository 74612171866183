export default {
  primary: {
    base: '#005EB8',
    lighten1: '#D9E6F4',
    lighten2: '#EDF5FF',
  },
  secondary: '#64A70B',
  accent: '#F4F4F4',
  info: '#ECEFF1',
  error: '#FF5252',
  //warning: '#ffbc56',
  success: '#8BC34A',
  rossoSacmi: '#D6001C',
  grey: {
    base: '#616161',
    lighten3: '#EEEEEE',
  }
}
