var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-flex",
    { staticClass: "d-flex align-center mx-1", attrs: { shrink: "" } },
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on: on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "secondary",
                        attrs: { "x-small": "" },
                        on: {
                          click: function ($event) {
                            _vm.dialog = true
                          },
                        },
                      },
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { size: "20" } }, [
                        _vm._v("mdi-tune"),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("span", { staticClass: "text-caption" }, [
            _vm._v(_vm._s(_vm.$t("label.filters"))),
          ]),
        ]
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "60vw" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "pa-3" },
                [
                  _vm.page == 1
                    ? _c(
                        "v-btn",
                        {
                          attrs: { icon: "", small: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.page = 0
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { size: "35" } }, [
                            _vm._v("mdi-arrow-left-circle"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.page == 1
                    ? _c("v-spacer")
                    : _c(
                        "v-btn",
                        {
                          staticClass: "warning pa-1",
                          attrs: { small: "" },
                          on: { click: _vm.loadSavedFilters },
                        },
                        [
                          _c("v-icon", { staticClass: "mr-1" }, [
                            _vm._v("mdi-account-filter"),
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("upper.loadSavedFilters"))),
                          ]),
                        ],
                        1
                      ),
                ],
                1
              ),
              _c("v-divider", { staticClass: "ma-0 mb-0" }),
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-carousel",
                    {
                      attrs: {
                        "hide-delimiters": "",
                        "show-arrows": false,
                        width: "100%",
                        height: "100%",
                        touchless: "",
                      },
                      model: {
                        value: _vm.page,
                        callback: function ($$v) {
                          _vm.page = $$v
                        },
                        expression: "page",
                      },
                    },
                    [
                      _c(
                        "v-carousel-item",
                        { staticClass: "pa-3", attrs: { value: 0 } },
                        [
                          _c(
                            "v-container",
                            {
                              staticClass: "pa-0",
                              staticStyle: { display: "grid" },
                              attrs: { "fill-height": "", fluid: "" },
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "ma-0" },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        ref: "invoiceNbr",
                                        attrs: {
                                          value: _vm.filterSelection.invoiceNbr
                                            ? _vm.filterSelection.invoiceNbr
                                                .text
                                            : "",
                                          label: _vm.$t("label.invoiceNbr"),
                                          "menu-props": _vm.menuProps,
                                          "prepend-inner-icon":
                                            "mdi-file-document-outline",
                                          "hide-details": "",
                                          clearable: "",
                                          dense: "",
                                        },
                                        on: {
                                          change: (v) =>
                                            _vm.onFieldChange(
                                              v,
                                              "invoiceNbr",
                                              "text",
                                              "value"
                                            ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        ref: "sellerName",
                                        attrs: {
                                          value: _vm.filterSelection.sellerName
                                            ? _vm.filterSelection.sellerName
                                                .text
                                            : "",
                                          label: _vm.$t("label.sellerName"),
                                          "menu-props": _vm.menuProps,
                                          "prepend-inner-icon":
                                            "mdi-file-document-outline",
                                          "hide-details": "",
                                          clearable: "",
                                          dense: "",
                                        },
                                        on: {
                                          change: (v) =>
                                            _vm.onFieldChange(
                                              v,
                                              "sellerName",
                                              "text",
                                              "value"
                                            ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "ma-0" },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        ref: "invoiceHolderName",
                                        attrs: {
                                          value: _vm.filterSelection
                                            .invoiceHolderName
                                            ? _vm.filterSelection
                                                .invoiceHolderName.text
                                            : "",
                                          label: _vm.$t(
                                            "label.invoiceHolderName"
                                          ),
                                          "menu-props": _vm.menuProps,
                                          "prepend-inner-icon":
                                            "mdi-file-document-outline",
                                          "hide-details": "",
                                          clearable: "",
                                          dense: "",
                                        },
                                        on: {
                                          change: (v) =>
                                            _vm.onFieldChange(
                                              v,
                                              "invoiceHolderName",
                                              "text",
                                              "value"
                                            ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        ref: "consigneeName",
                                        attrs: {
                                          value: _vm.filterSelection
                                            .consigneeName
                                            ? _vm.filterSelection.consigneeName
                                                .text
                                            : "",
                                          label: _vm.$t("label.consigneeName"),
                                          "menu-props": _vm.menuProps,
                                          "prepend-inner-icon":
                                            "mdi-file-document-outline",
                                          "hide-details": "",
                                          clearable: "",
                                          dense: "",
                                        },
                                        on: {
                                          change: (v) =>
                                            _vm.onFieldChange(
                                              v,
                                              "consigneeName",
                                              "text",
                                              "value"
                                            ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "ma-0" },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        ref: "saleOrderReference",
                                        attrs: {
                                          value: _vm.filterSelection
                                            .saleOrderReference
                                            ? _vm.filterSelection
                                                .saleOrderReference.text
                                            : "",
                                          label: _vm.$t(
                                            "label.saleOrderReference"
                                          ),
                                          "menu-props": _vm.menuProps,
                                          "prepend-inner-icon":
                                            "mdi-file-document-outline",
                                          "hide-details": "",
                                          clearable: "",
                                          dense: "",
                                        },
                                        on: {
                                          change: (v) =>
                                            _vm.onFieldChange(
                                              v,
                                              "saleOrderReference",
                                              "text",
                                              "value"
                                            ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        ref: "shipmentDocumentNbrLIKE",
                                        attrs: {
                                          value: _vm.filterSelection.shipmentNbr
                                            ? _vm.filterSelection.shipmentNbr
                                                .text
                                            : "",
                                          label: _vm.$t("label.shipmentNbr"),
                                          "menu-props": _vm.menuProps,
                                          "prepend-inner-icon":
                                            "mdi-file-document-outline",
                                          "hide-details": "",
                                          clearable: "",
                                          dense: "",
                                        },
                                        on: {
                                          change: (v) =>
                                            _vm.onFieldChange(
                                              v,
                                              "shipmentDocumentNbrLIKE",
                                              "text",
                                              "value"
                                            ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-carousel-item",
                        { staticClass: "pa-3 pb-0", attrs: { value: 1 } },
                        [
                          _c(
                            "v-data-table",
                            {
                              staticClass: "filters-table",
                              attrs: {
                                items: _vm.savedFilters,
                                "items-per-page": 8,
                                "hide-default-header": "",
                                "hide-default-footer": _vm.savedFilters.length
                                  ? false
                                  : true,
                                dense: "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item",
                                  fn: function ({ item }) {
                                    return [
                                      _c("tr", [
                                        _c("td", [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(item.value.nameFilter)
                                            ),
                                          ]),
                                        ]),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "px-1 d-flex justify-end",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "actions" },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-3",
                                                    attrs: { color: "green" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.uploadFilter(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" mdi-upload ")]
                                                ),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: { color: "red" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.deleteItem(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" mdi-delete ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c(
                                "template",
                                { slot: "no-data" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { color: "warning", size: "20" },
                                    },
                                    [_vm._v(" mdi-alert ")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-weight-medium warning--text",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("label.noAvailableData")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider", { staticClass: "ma-0 mt-0" }),
              _c(
                "v-card-actions",
                { staticClass: "pa-3", staticStyle: { height: "3.25rem" } },
                [
                  _vm.page === 0
                    ? [
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              top: "",
                              "offset-y": "",
                              transition: "scale-transition",
                              "close-on-content-click": false,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "primary pa-1",
                                            attrs: { small: "" },
                                            on: { click: _vm.focusFilterName },
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mr-1" },
                                            [_vm._v("mdi-filter-plus")]
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("upper.saveFilter"))
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              782462688
                            ),
                          },
                          [
                            _c(
                              "v-list",
                              {
                                directives: [
                                  {
                                    name: "click-outside",
                                    rawName: "v-click-outside",
                                    value: _vm.clearFilterName,
                                    expression: "clearFilterName",
                                  },
                                ],
                                staticClass: "pa-0",
                                attrs: { width: "16rem" },
                              },
                              [
                                _c(
                                  "v-list-item",
                                  { staticClass: "py-1 px-2" },
                                  [
                                    _c(
                                      "v-list-item-title",
                                      [
                                        _c("v-text-field", {
                                          ref: "filterName",
                                          attrs: {
                                            label: _vm.$t("label.filterName"),
                                          },
                                          model: {
                                            value: _vm.filterName,
                                            callback: function ($$v) {
                                              _vm.filterName = $$v
                                            },
                                            expression: "filterName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-icon",
                                      { staticClass: "ml-1" },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: { color: "success" },
                                            on: { click: _vm.saveFilter },
                                          },
                                          [_vm._v(" mdi-content-save ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "primary pa-1 ml-3",
                            attrs: { small: "" },
                            on: { click: _vm.resetAllFilters },
                          },
                          [
                            _c("v-icon", { staticClass: "mr-1" }, [
                              _vm._v("mdi-filter-remove"),
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("upper.resetFilter"))),
                            ]),
                          ],
                          1
                        ),
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            staticClass: "secondary pa-1",
                            attrs: { small: "" },
                            on: { click: _vm.setFilter },
                          },
                          [
                            _c("v-icon", { staticClass: "mr-1" }, [
                              _vm._v("mdi-content-save"),
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("upper.search"))),
                            ]),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }