<template>
  <v-container class="d-block pa-0" fill-height fluid>
    <v-row class="ma-0">
      <v-col cols="auto" class="d-flex align-center py-0">
        <v-subheader class="pa-0 primary--text text-overline">
          Container
        </v-subheader>
      </v-col>
      <v-col class="d-flex align-center py-0 pl-0">
        <v-divider class="my-0" />
      </v-col>
      <v-col cols="auto" class="d-flex align-center py-0" v-if="!disabled">
        <v-icon color="primary" @click="addContainer">
          mdi-plus-circle
        </v-icon>
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col class="pa-0">
        <v-list >
          <v-list-item
            v-for="(container, i) in containers"
            :key="i"
            class="d-block px-0 mt-1"
          >
            <template>
              <v-row class="ma-0">
                <v-col class="py-0">
                  <v-autocomplete
                    :label="$t('label.containerType')"
                    :value="getContainerCodeObj(container)"
                    :items="containerCodes"
                    :menu-props="menuProps"
                    :item-text="getContainerTypeText"
                    @change="(containerCode) => setContainerInfo(container, containerCode)"
                    :readonly="disabled"
                    :rules="required"
                    class="xs-input"
                    validate-on-blur
                    return-object
                    outlined
                    attach
                    dense
                  >
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ data.item.code }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ data.item.isoCode }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="auto" class="py-0">
                  <v-text-field
                    v-model="container.requestedEquipments.ISOEquipmentCode"
                    :label="$t('label.code')"
                    @input="(v) => (container.requestedEquipments.ISOEquipmentCode = v.toUpperCase())"
                    :readonly="disabled"
                    :rules="required"
                    class="number-select xs-input"
                    
                    outlined
                    dense
                  />
                  <!-- validate-on-blur -->
                  <!-- <v-text-field
                    :label="$t('label.code')"
                    :value="container.requestedEquipments.ISOEquipmentCode"
                    @input="(v) => container.requestedEquipments.ISOEquipmentCode = v.toUpperCase()"
                    :rules="required"
                    class="weight-select"
                    outlined
                    dense
                  /> -->
                </v-col>
                <v-col class="d-flex py-0" cols="auto">
                  <v-btn
                    slot="prepend"
                    icon
                    :disabled="disabled"
                    @click="editContainerQuantity(container, false)"
                  >
                    <v-icon>
                      mdi-minus
                    </v-icon>
                  </v-btn>
                  <v-text-field
                    v-model="container.requestedEquipments.units"
                    class="number-select xs-input"
                    type="number"
                    :readonly="disabled"
                    :rules="required"
                    hide-spin-buttons
                    
                    outlined
                    dense
                  />
                  <!-- validate-on-blur -->
                  <v-btn
                    slot="append"
                    icon
                    :disabled="disabled"
                    @click="editContainerQuantity(container, true)"
                  >
                    <v-icon>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="auto" class="py-0">
                  <v-text-field
                    :label="$t('label.totWeightKgm')"
                    v-model.number="container.requestedEquipments.equipmentWeight"
                    @change="setWeightUOM(container.requestedEquipments)"
                    class="weight-select xs-input"
                    type="number"
                    :readonly="disabled"
                    :rules="required"
                    hide-spin-buttons
                    validate-on-blur
                    outlined
                    dense
                  />
                </v-col>
                <!-- <v-col cols="auto" class="py-0">
                  <v-select
                    :label="$t('label.weightUnitType')"
                    v-model="weightUnitOfMeasure"
                    :items="[kilosUnitMeasure, 'LBS']"
                    :menuProps="menuProps"
                    class="weight-select"
                    :readonly="disabled"
                    hide-details
                    outlined
                    dense
                  />
                </v-col> -->
                <v-col cols="auto" class="py-0">
                  <v-text-field
                    :label="$t('label.totVolumeMtq')"
                    v-model.number="container.requestedEquipments.equipmentVolume"
                    @change="setVolumeUOM(container.requestedEquipments)"
                    class="weight-select xs-input"
                    type="number"
                    :readonly="disabled"
                    hide-spin-buttons
                    outlined
                    dense
                  />
                </v-col>
                <!-- <v-col cols="auto" class="py-0">
                  <v-select
                    :label="$t('label.weightUnitType')"
                    v-model="volumeUnitOfMeasure"
                    :items="[squareMeterUnitMeasure]"
                    :menuProps="menuProps"
                    class="weight-select"
                    :readonly="disabled"
                    hide-details
                    outlined
                    dense
                  />
                </v-col> -->
                <v-col cols="auto" class="d-flex align-center pt-0 pb-4">
                  <v-checkbox
                    v-model="container.requestedEquipments.isShipperOwned"
                    class="dangerous-check ma-0 pa-0"
                    :readonly="disabled"
                    hide-details
                  >
                    <template v-slot:label>
                      <span class="text-caption">
                        {{ $t('label.shipperOwnedContainer') }}
                      </span>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="auto" class="d-flex align-center pt-0 pb-4">
                  <v-btn
                    small
                    outlined
                    depressed
                    class="primary--text px-2"
                    :disabled="disabled"
                    @click="removeContainer(i)"
                  >
                    <span class="font-weight-bold">
                      {{ $t('label.remove') }}
                    </span>
                    <v-icon class="ml-1" size="20">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { containerCodeService } from '@/services/containerCode.service.js'

export default {
  name: 'BookingContainers',
  props: ['preselectedContainers', 'company', 'disabled'],
  emits: ['update-equipments', 'update-unit-type', 'update-volume-unit-type'],
  data() {
    return {
      weightUnitOfMeasure: null,
      volumeUnitOfMeasure: null,
      containers: [],
      containerCodes: [],
      updatedEquipments: [],
      menuProps: {
        offsetY: true,
        overflowY: 'auto',
        maxHeight: '10rem',
      },
      haulageDetails: [],
    }
  },
  computed: {
    containerItem() {
      return {
        code: null,
        requestedEquipments: {
          ISOEquipmentCode: null,
          equipmentWeight: null,
          equipmentWeightUnit: this.weightUnitOfMeasure,
          equipmentVolume: null,
          equipmentVolumeUnit: this.volumeUnitOfMeasure,
          isShipperOwned: false,
          units: 0,
          haulageDetails: this.haulageDetails,
        },
        // totWeight: 0,
        // totVolume: null
      }
    },
    totalWeight() {
      if (!this.containers) return null
      let totWeight = 0
      this.containers.forEach((container) => {
        if (!container.requestedEquipments.equipmentWeight) return
        totWeight += container.requestedEquipments.equipmentWeight
      })
      return totWeight
    },
    kilosUnitMeasure() {
      switch (this.company) {
        case 'MAERSK':
          return 'KGS'
        case 'CMACGM':
        case 'YANGMING':
          return 'KGM'
      }
      return null
    },
    totalVolume() {
      if (!this.containers) return null
      let totVolume = 0
      this.containers.forEach((container) => {
        if (!container.requestedEquipments.equipmentVolume) return
        totVolume += container.requestedEquipments.equipmentVolume
      })
      return totVolume
    },
    squareMeterUnitMeasure() {
      switch (this.company) {
        case 'MAERSK':
          return 'MTQ'
        case 'CMACGM':
          return 'MTQ'
      }
      return null
    },
    required() {
      return [(v) => !!v || this.$t('message.required')]
    },
  },
  watch: {
    containers: {
      handler() {
        this.$emit('update-equipments', this.updatedEquipments, this.containers, this.totalWeight, this.totalVolume)
      },
      deep: true,
    },
    weightUnitOfMeasure(unitType) {
      this.$emit('update-unit-type', unitType)
    },
    volumeUnitOfMeasure(unitType) {
      this.$emit('update-volume-unit-type', unitType)
    },
  },
  methods: {
    init() {
      this.findAllContainerCodes()
      this.weightUnitOfMeasure = this.kilosUnitMeasure
      this.volumeUnitOfMeasure = this.squareMeterUnitMeasure
      this.updatedEquipments = []
      this.setHaulage()
      this.containers = this._.cloneDeep(this.preselectedContainers)
      this.copyContainers()
    },
    findAllContainerCodes() {
      containerCodeService.findAll().then((resp) => {
        if (!resp) return
        this.containerCodes = this.sortList(resp, 'code', true)
      })
    },
    copyContainers() {
      this.containers.forEach((containerType) => {
        this.updatedEquipments.push(containerType.requestedEquipments)
      })
    },
    setHaulage(){
      if (this.preselectedContainers.length)
      this.haulageDetails = this.preselectedContainers[0].requestedEquipments.haulageDetails
    },
    getContainerCodeObj(container) {
      if (!container) return
      let containerCodes = this.containerCodes.filter((containerCode) => containerCode.code == container.code)
      return containerCodes.length ? containerCodes[0] : null
    },
    setContainerInfo(container, containerCode) {
      if (!containerCode) return
      container.code = containerCode.code
      container.requestedEquipments.ISOEquipmentCode = containerCode.isoCode
      if (!container.requestedEquipments.units)
        this.editContainerQuantity(container, true)
    },
    addContainer() {
      let container = this._.cloneDeep(this.containerItem)
      this.containers.push(container)
      this.updatedEquipments.push(container.requestedEquipments)
    },
    removeContainer(index) {
      this.containers.splice(index, 1)
      this.updatedEquipments.splice(index, 1)
    },
    editContainerQuantity(container, add) {
      if (!container.code) return
      if (add) container.requestedEquipments.units += 1
      else if (container.requestedEquipments.units > 1)
        container.requestedEquipments.units -= 1
      // let containerCode = this.getContainerCodeObj(container.requestedEquipments.ISOEquipmentCode)
      // container.totVolume = containerCode.externalVolume * container.requestedEquipments.units
    },
    getContainerTypeText(item) {
      return `${item.isoCode} - ${item.code}`
    },
    setWeightUOM(requestedEquipments){
      requestedEquipments.equipmentWeightUnit = this.weightUnitOfMeasure        
    },
    setVolumeUOM(requestedEquipments){
      requestedEquipments.equipmentVolumeUnit = this.volumeUnitOfMeasure
    },
    // Utils
    sortList(list, field, asc) {
      if (!list.length) return []
      return list.sort(function (a, b) {
        if (a[field] > b[field]) return asc ? 1 : -1
        if (a[field] < b[field]) return asc ? -1 : 1
        return 0
      })
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>
.add-btn {
  text-transform: unset !important;
}
.dangerous-check::v-deep label {
  margin-bottom: 0 !important;
}
.number-select {
  max-width: 6rem;
}
.weight-select {
  max-width: 10rem;
}
.number-select::v-deep input,
.weight-select::v-deep input {
  text-align: center;
  text-transform: uppercase;
}
.v-input::v-deep .v-text-field__details {
  margin: 0 !important;
}
.xs-input::v-deep input {
  padding: 0.5rem !important;
  padding-left: 0 !important;
}
.xs-input::v-deep .v-label {
  position: unset !important;
  min-width: max-content;
  margin-bottom: 0 !important;
}
.xs-input::v-deep .v-input__slot {
  min-height: 0 !important;
  padding-right: 4px !important;
}
.xs-input::v-deep .v-input__append-inner {
  margin: 0 !important;
  align-self: center !important;
}
.xs-input::v-deep .v-label--active {
  position: absolute !important;
  margin-bottom: 0 !important;
}
.v-subheader {
  height: 1rem;
}
/*.row {
  border: solid 1px red;
}
.col {
  border: solid 1px orange;
}*/
</style>
