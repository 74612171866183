<template>
  <v-stepper class="pa-0" elevation="0">
    <v-stepper-header>
      <template v-for="(value, i) in values">
        <v-stepper-step
          :key="i"
          :complete="markComplete(i)"
          :step="value.text"
          color="secondary"
          @click="update(value, i)"
        >
          {{ value.text }}
        </v-stepper-step>
        <v-divider
          :key="value.text"
          v-if="values.indexOf(value) != values.length - 1"
        />
      </template>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
export default {
  name: 'ActionsStepperEnum',
  props: ['entity', 'prop', 'values'],
  emits: ['update-props'],
  computed: {
    currentValue() {
      let index = -1
      Object.keys(this.values).forEach((key, i) => {
        if (this.values[key].value == this.entity[this.prop]) index = i
      })
      return index
    },
  },
  methods: {
    update(value, i) {
      if (this.entity[this.prop] != value.value)
        this.entity[this.prop] = value.value
      else this.entity[this.prop] = i > 0 ? this.values[i - 1].value : null
      this.$emit('update-props', this.entity)
    },
    markComplete(i) {
      return i <= this.currentValue
    },
  },
}
</script>

<style scoped>
.v-stepper::v-deep .v-stepper__step__step {
  color: transparent !important;
}
</style>
