<template>
  <v-container class="d-block" fill-height fluid>
    <v-progress-linear
      indeterminate
      color="primary"
      class="mb-1"
      v-if="loading"
    />
    <v-expansion-panels v-model="panel" width="100%" class="align-self-start">
      <v-expansion-panel v-for="(user, i) in users" :key="i">
        <v-expansion-panel-header disable-icon-rotate class="px-3 py-0">
          <template v-slot:actions v-if="panel == i">
            <v-btn
              fab
              small
              color="success"
              @click.stop="updateUser(currentUser)"
            >
              <v-icon size="25" color="white">
                mdi-content-save
              </v-icon>
            </v-btn>
          </template>
          <v-row class="ma-0">
            <v-col class="d-flex align-center pa-0">
              <span>{{ user.lastName }}</span>
              <span class="ml-2">{{ user.firstName }}</span>
            </v-col>
            <v-col class="d-flex align-center pa-0">
              <span>{{ user.email }}</span>
            </v-col>
            <v-col class="pa-0">
              <v-chip
                class="ma-2 pa-2 text-overline"
                color="primary"
                small
                v-for="(element, i) in user.groups"
                :key="i"
              >
                <v-icon small class="pr-1">
                  {{ getIcon(element.groupDes) }}
                </v-icon>
                {{ element.groupDes }}
              </v-chip>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="ma-0">
            <v-col class="pa-0 pl-3">
              <span class="text-overline primary--text">
                {{ $t('label.settings') }}
              </span>
            </v-col>
          </v-row>
          <v-divider class="mx-2 mt-0 mb-5" />
          <v-row class="ma-0">
            <v-col
              class="pa-5"
              :cols="$vuetify.breakpoint.smAndDown ? '12' : '2'"
            >
              <v-select
                v-model="userParams['language']"
                :items="languageItems"
                :label="$t('label.language')"
                item-text="text"
                return-object
                prepend-icon="mdi-translate"
                class="no-overflow white--text"
                hide-details
                dense
                flat
              />
            </v-col>
            <v-col
              class="pa-5"
              :cols="$vuetify.breakpoint.smAndDown ? '12' : '5'"
            >
              <v-select
                :label="$t('label.groups')"
                v-model="currentUser.groups"
                :items="groups"
                item-text="groupDes"
                item-value="id"
                return-object
                prepend-icon="mdi-account-group"
                class="no-overflow white--text"
                hide-details
                multiple
                dense
                flat
              />
            </v-col>
            <v-col
              class="pa-5"
              :cols="$vuetify.breakpoint.smAndDown ? '12' : '5'"
            >
              <v-menu
                v-model="menuCustomer"
                offset-y
                attach
                close-on-click
                :open-on-click="false"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :label="$t('label.customers')"
                    ref="customers"
                    :value="formatCustomers"
                    item-text="customerName"
                    v-if="!showSearch['customers']"
                    @click:append="currentUser.customers = []"
                    @click="showSearch['customers'] = true"
                    prepend-inner-icon="mdi-account-arrow-right"
                    :append-icon="user.customers.length ? 'mdi-close' : ''"
                    hide-details
                    readonly
                    dense
                  />
                  <v-text-field
                    v-else
                    :label="$t('label.customers')"
                    v-model="search.customers"
                    v-on="on"
                    @click.stop
                    @click="handleSearch('customers')"
                    @click:append="
                      searchCustomers(search.customers, 'customers')
                    "
                    @keydown.enter="
                      searchCustomers(search.customers, 'customers')
                    "
                    @blur="resetSearchBox('shipperId')"
                    prepend-inner-icon="mdi-account-arrow-right"
                    append-icon="mdi-magnify"
                    hide-details
                    dense
                  />
                </template>
                <v-card class="dropdown-menu">
                  <v-data-table
                    v-model="currentUser.customers"
                    :headers="[{ text: '', value: 'customerName' }]"
                    :items="customersFilterList"
                    :items-per-page="4"
                    :page.sync="tablePage"
                    @item-selected="
                      (v) => selectItem(v, 'customers', 'customerId')
                    "
                    hide-default-header
                    class="custom-table"
                    checkbox-color="primary"
                    show-select
                    dense
                  >
                    <template v-slot:item.customerName="{ item }">
                      <td class="custom-table-td">
                        {{ item.customerName }}
                      </td>
                    </template>
                  </v-data-table>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import { userParametersService } from '@/services/userParameters.service.js'
import { usersService } from '@/services/users.service.js'
import { groupsService } from '@/services/groups.service.js'
import { customersService } from '@/services/customers.service.js'

export default {
  name: 'AdminSettings',
  props: [],
  components: {},
  data: () => ({
    languageItems: [],
    customersFilterList: [],

    // excludeInternal: true,
    search: {},
    showSearch: {
      customers: false,
    },
    menuCustomer: false,
    tablePage: 1,

    panel: null,

    users: [],
    groups: [],
    customers: [],
    currentIndex: null,
    currentUser: {},
    userParams: {
      language: '',
    },
    loading: false,
  }),
  watch: {
    async panel(index) {
      this.userParams = {}
      this.showSearch['customers'] = false
      if (index == null || index == undefined) {
        this.currentUser = {}
        return
      }
      this.currentUser = this._.cloneDeep(this.users[index])
      this.currentIndex = index
      setTimeout(() => {
        this.getUserParameters(this.currentUser.id)
      }, 300)
    },
    menuCustomer(v) {
      if (!v) this.resetSearchBox('customers')
    },
  },
  computed: {
    formatCustomers() {
      let res = ''
      let customers = this.currentUser.customers
      if (this.currentUser.customers)
        this.currentUser.customers.forEach((customer) => {
          const current = (element) => element.customerId == customer.customerId
          let index = customers.findIndex(current)
          res +=
            index != customers.length - 1
              ? `${customer.customerName}, `
              : `${customer.customerName}`
        })
      return res
    },
  },
  methods: {
    selectLanguageItems() {
      return [
        {
          id: this.userParams.language.id,
          key: 'LANGUAGE',
          userId: this.currentUser.id,
          value: 'en',
          text: 'English',
          valueType: 'STRING',
        },
        {
          id: this.userParams.language.id,
          key: 'LANGUAGE',
          userId: this.currentUser.id,
          value: 'it',
          text: 'Italiano',
          valueType: 'STRING',
        },
      ]
    },
    init() {
      this.browseUsers()
      this.browseGroups()
    },
    browseUsers() {
      this.loading = true
      usersService.browse(0, 100, {}, []).then((resp) => {
        this.users = resp.resultList //.sort()
        this.loading = false
      })
    },
    browseGroups() {
      groupsService.browse(0, 100, {}, []).then((resp) => {
        this.groups = resp.resultList
      })
    },
    searchCustomers(search, type) {
      if (!search || !search.length) return
      let browseRequest = {
        offset: 0,
        limit: 1000,
        filter: {
          customerName: search,
          isConsignee: false,
        },
        sortingList: [{column: "customerName", direction: "DESC"}],
      }
      customersService
        .browseInLike(browseRequest)
        .then((resp) => {
          this.customersFilterList = resp
          this.openMenu(type)
        })
    },
    getUserParameters(userId) {
      return userParametersService.getAllByUser(userId).then((resp) => {
        this.userParams = {}
        resp.forEach((param) => {
          this.userParams[param.key.toLowerCase()] = param
        })
        if (!this.userParams.language) this.createLanguageParameter(userId)
        else this.languageItems = this.selectLanguageItems()
      })
    },
    updateUser(user) {
      this.updateUserParameters(user)
      usersService.updateUser(user.id, user).then((resp) => {
        this.currentUser = resp
        this.users[this.currentIndex] = resp
      })
    },
    updateUserParameters(user) {
      Object.keys(this.userParams).forEach((key) => {
        let param = this.userParams[key]
        if (param.id) {
          let userParam = {
            id: param.id,
            userId: user.id,
            key: param.key,
            valueType: param.valueType,
            value: param.value,
          }
          return userParametersService.updateUserParameter(param.id, userParam)
        }
      })
    },
    createLanguageParameter(userId) {
      let userParam = {
        userId: userId,
        key: 'LANGUAGE',
        valueType: 'STRING',
        value: null,
      }
      return userParametersService
        .createUserParameter(userParam)
        .then((resp) => {
          this.userParams.language = resp
          this.languageItems = this.selectLanguageItems()
        })
    },
    getIcon(type) {
      switch (type) {
        case 'admin':
          return 'mdi-shield-crown'
        case 'air':
          return 'mdi-airplane'
        case 'sea':
          return 'mdi-ferry'
      }
    },
    selectItem(input, field, id) {
      if (!this.currentUser[field].some((e) => e[id] == input.item[id])) {
        this.currentUser[field].push(input.item)
      } else {
        const current = (element) => element[id] == input.item[id]
        let index = this.currentUser[field].findIndex(current)
        this.currentUser[field].splice(index, 1)
      }
      this.showSearch[field] = false
    },
    handleSearch(type) {
      this.showSearch[type] = !this.showSearch[type]
    },
    resetSearchBox(type) {
      this.showSearch[type] = false
      this.search = {}
      this.tablePage = 1
    },
    openMenu(type) {
      switch (type) {
        case 'customers':
          this.menuCustomer = true
          break
      }
    },
  },
  created() {
    this.init()
  },
}
</script>

<style scoped>
.v-expansion-panel::v-deep .v-expansion-panel-content__wrap {
  padding: 0;
}
.custom-table {
  width: 100%;
  height: 100%;
}
.custom-table::v-deep .v-data-footer {
  justify-content: right;
}
.custom-table::v-deep .v-data-footer__select {
  display: none;
}
.v-data-table::v-deep .v-input--selection-controls__ripple {
  height: 0 !important;
}
.v-data-table::v-deep tr {
  display: flex;
}
.v-data-table::v-deep td {
  display: flex;
  align-items: center !important;
  padding: 0.2rem !important;
}
.custom-table-td {
  display: flex;
  width: -webkit-fill-available;
  align-items: center !important;
  padding: 0.2rem !important;
}
.dropdown-menu {
  width: 100%;
  padding: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
