<template>
  <div>
    <v-menu
      transition="scale-transition"
      origin="center center"
      offset-overflow
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          :color="internalVisibilityAuth ? status ? status.toLowerCase() : 'grey' : 'primary'"
          label
          small
          v-bind="attrs"
          v-on="on"
          @click="loadSemafori"
        >
          <v-icon v-if="pratica.saleChannel == 40" size="15">
            mdi-airplane
          </v-icon>
          <v-icon v-if="pratica.saleChannel == 30" size="15">
            mdi-truck
          </v-icon>
          <v-icon v-if="pratica.saleChannel == 20" size="15">
            mdi-ferry
          </v-icon>
          <span class="font-weight-medium ml-1 text-uppercase">
            {{
              pratica.saleChannel == 40
                ? $t('label.plane')
                : pratica.saleChannel == 30
                ? $t('label.road')
                : $t('label.sea')
            }}
          </span>
        </v-chip>
      </template>
      <template v-if="internalVisibilityAuth && semafori.length">
        <v-simple-table style="background-color: white;" class="py-1">
          <tr v-for="(semaforo, i) in semafori" :key="i">
            <td class="text-caption pl-2">
              {{ semaforo.step.replace(/_/g, ' ') }}
            </td>
            <td class="px-2 pb-1">
              <v-icon
                small
                :color="semaforo && semaforo.status ? semaforo.status.toLowerCase() : 'grey'"
              >
                mdi-circle
              </v-icon>
            </td>
          </tr>
        </v-simple-table>
      </template>
      <template v-if="internalVisibilityAuth && !semafori.length">
        <v-card class="d-flex justify-center">
          <span class="text-caption ma-1">
            {{ $t('label.noAvailableData') }}
          </span>
        </v-card>
      </template>
    </v-menu>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { praticaSemaforiService } from '@/services/praticaSemafori.service.js'

export default {
  name: 'Semaphore',
  props: ['pratica'],
  emit: ['show-status'],
  data() {
    return {
      semafori: [],
    }
  },
  computed: {
    ...mapState('account', ['internalVisibilityAuth']),
    status(){
      return this.pratica.status
    }
  },
  methods: {
    loadSemafori() {
      praticaSemaforiService.getByPraticaId(this.pratica.id).then((resp) => {
        if (resp) this.semafori = resp
      })
    },
  },
}
</script>

<style scoped>
.v-chip {
  width: 7rem;
  display: flex;
  justify-content: left;
  align-items: left;
  padding-top: 2px;
  padding-left: 3px;
}
</style>
