const state = {
  loadingPage: false,
}

const mutations = {
  setLoading(state, loading) {
    state.loadingPage = loading
  },
}

export const loading = {
  namespaced: true,
  state,
  mutations
}