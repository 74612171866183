var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-simple-table", {
    attrs: { dense: "" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c("thead", [
              _c("tr", [
                _c(
                  "th",
                  { staticClass: "text-caption primary--text text-left" },
                  [_vm._v(" " + _vm._s(_vm.$t("label.documentNbr")) + " ")]
                ),
                _c(
                  "th",
                  { staticClass: "text-caption primary--text text-left" },
                  [_vm._v(" " + _vm._s(_vm.$t("label.customer")) + " ")]
                ),
                _c(
                  "th",
                  { staticClass: "text-caption primary--text text-left" },
                  [_vm._v(" " + _vm._s(_vm.$t("label.shipper")) + " ")]
                ),
                _c(
                  "th",
                  { staticClass: "text-caption primary--text text-left" },
                  [_vm._v(" " + _vm._s(_vm.$t("label.consigneeName")) + " ")]
                ),
                _c(
                  "th",
                  { staticClass: "text-caption primary--text text-left" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("label.pol") + "/" + _vm.$t("label.pod")
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "th",
                  { staticClass: "text-caption primary--text text-left" },
                  [_vm._v("Doc date")]
                ),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.praticas, function (item, index) {
                return _c(
                  "tr",
                  {
                    key: index,
                    class: [{ selected: item.isSelected }],
                    on: {
                      click: function ($event) {
                        return _vm.selectShipment(item)
                      },
                    },
                  },
                  [
                    _c("td", { staticClass: "text-caption font-weight-bold" }, [
                      _vm._v(" " + _vm._s(item.documentNbr) + " "),
                    ]),
                    _c("td", { staticClass: "text-caption font-weight-bold" }, [
                      item.clientName.length > 5
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(item.clientName.substring(0, 5))
                            ),
                            !_vm.showFullText[index]
                              ? _c(
                                  "span",
                                  {
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.toggleShowFullText(index)
                                      },
                                    },
                                  },
                                  [_vm._v("...")]
                                )
                              : _vm._e(),
                            _vm.showFullText[index]
                              ? _c("span", [_vm._v(_vm._s(item.clientName))])
                              : _vm._e(),
                          ])
                        : _c("div", [
                            _vm._v(" " + _vm._s(item.clientName) + " "),
                          ]),
                    ]),
                    _c("td", { staticClass: "text-caption font-weight-bold" }, [
                      item.shipperName.length > 5
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(item.shipperName.substring(0, 5))
                            ),
                            !_vm.showFullText[index]
                              ? _c(
                                  "span",
                                  {
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.toggleShowFullText(index)
                                      },
                                    },
                                  },
                                  [_vm._v("...")]
                                )
                              : _vm._e(),
                            _vm.showFullText[index]
                              ? _c("span", [_vm._v(_vm._s(item.shipperName))])
                              : _vm._e(),
                          ])
                        : _c("div", [
                            _vm._v(" " + _vm._s(item.shipperName) + " "),
                          ]),
                    ]),
                    _c("td", { staticClass: "text-caption font-weight-bold" }, [
                      item.consigneeName.length > 5
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(item.consigneeName.substring(0, 5))
                            ),
                            !_vm.showFullText[index]
                              ? _c(
                                  "span",
                                  {
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.toggleShowFullText(index)
                                      },
                                    },
                                  },
                                  [_vm._v("...")]
                                )
                              : _vm._e(),
                            _vm.showFullText[index]
                              ? _c("span", [_vm._v(_vm._s(item.consigneeName))])
                              : _vm._e(),
                          ])
                        : _c("div", [
                            _vm._v(" " + _vm._s(item.consigneeName) + " "),
                          ]),
                    ]),
                    _c("td", { staticClass: "text-caption font-weight-bold" }, [
                      _vm._v(" " + _vm._s(item.pol + "/" + item.pod) + " "),
                    ]),
                    _c("td", { staticClass: "text-caption font-weight-bold" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            new Date(item.documentDate).toLocaleDateString(
                              "en-GB",
                              {
                                day: "numeric",
                                month: "numeric",
                                year: "2-digit",
                              }
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }