import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import light from "./theme.js";

Vue.use(Vuetify, {
  options: {
    customProperties: true,
  },
});

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    themes: { light },
    options: { customProperties: true },
  },
});
