var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "d-flex justify-center",
      attrs: { id: "main", "fill-height": "", fluid: "" },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-column align-center" },
        [
          _vm.isDemo
            ? _c("v-img", {
                staticClass: "mb-2",
                attrs: {
                  src: require("@/assets/circle-group-color.png"),
                  "max-width": "300",
                  contain: "",
                },
              })
            : _c("v-img", {
                attrs: {
                  src: require("@/assets/logo-italiansped-footer_140x50.png"),
                  "max-width": "140",
                  contain: "",
                },
              }),
          _c("h2", { staticStyle: { color: "#c3342f" } }, [
            _vm._v(_vm._s(_vm.appTitle)),
          ]),
          _c(
            "v-btn",
            {
              staticClass: "mt-3 white--text",
              attrs: { color: "rossoSacmi" },
              on: { click: _vm.signIn },
            },
            [_vm._v(" " + _vm._s(_vm.$t("label.login")) + " ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }