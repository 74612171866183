<template>
  <v-container fluid class="pa-0">
    <v-row dense class="ma-0">
      <v-col class="d-flex flex-column" style="max-width: 90vw;">
        <v-container fluid class="text pt-4 pa-0" v-html="mail.text"/>
        <v-flex class="d-flex mt-2">
          <v-col
            cols="auto"
            class="pa-0 mr-2"
            v-for="(attachment, i) in mail.attachments"
            :key="i"
          >
            <div @click="$emit('download-attachment', attachment)">
              <v-icon>mdi-attachment</v-icon>
              <span class="text-caption ml-1">{{ attachment.filename }}</span>
            </div>
          </v-col>
        </v-flex>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'MailBox',
  props: ['mail'],
  emits: ['download-attachment'],
}
</script>

<style scoped>
.text {
  max-height: 40vh;
  overflow: auto;
}
.text::v-deep pre {
  margin: 0;
}
/*.row {
  border: solid 1px orange;
}
.col {
  border: solid 1px green;
}*/
</style>
