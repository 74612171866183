<template>
  <div class="d-flex align-center pa-0">
    <SortByMenu v-on="$listeners" />
    <FiltersDialog ref="filtersDialog" v-on="$listeners" v-if="filtersPopUpAuth" />
    <v-flex class="d-flex align-center" shrink>
      <v-btn x-small class="secondary" v-if="!filtersPopUpAuth" @click="openFiltersPanel">
        <v-icon size="20">mdi-tune</v-icon>
      </v-btn>
      <v-btn x-small outlined depressed color="secondary" class="mx-1" @click="resetFilter">
        {{ $t('label.resetAllFilters') }}
      </v-btn>
    </v-flex>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BrowseMemory from '@/mixins/BrowseMemory'
import FiltersDialog from '@/components/elements/FiltersDialog.vue'
import SortByMenu from '@/components/elements/SortByMenu.vue'

export default {
  name: 'FiltersSection',
  emits: ['open-filters', 'reset-filter', 'set-filter'],
  mixins: [BrowseMemory],
  components: {
    FiltersDialog,
    SortByMenu,
  },
  data() { return {} },
  computed: {
    ...mapState('account', ['userAuthorizations', 'internalVisibilityAuth']),
    filtersPopUpAuth() {
      return this.userAuthorizations.includes('AUTH_FILTERS_POPUP')
    },
  },
  methods: {
    openFiltersPanel() {
      this.$emit('open-filters')
    },
    resetFilter() {
      let filterMemory = {
        currentFilter: {},
        browseFilter: {},
        visualFilter: {},
      }
      this.setFilterMemory('FiltersCard', filterMemory)
      this.setFilterMemory('FiltersDialog', filterMemory)
      this.$emit('set-filter', {}, {})
      this.$emit('reset-filter')
    },
  }
}
</script>
