<template>
  <v-container class="stepper-wrapper" fluid>
    <v-row class="ma-0 fill-height">
      <v-col class="pa-0">
        <v-data-table
          :headers="headers"
          :items="electronicFlows"
          :items-per-page="1000"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :item-class="isSelected"
          @click:row="(electronicFlow) => selectElectronicFlow(electronicFlow)"
          hide-default-footer
          dense
          light
        >
          <template v-slot:item.creationDate="{ item }">
            <span>{{ formatDateDay(item.creationDate) }}</span>
          </template>
          <template v-slot:item.status="{ item }">
            <span class="text-caption">{{ formatStatus(item.status) }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="d-flex justify-end pr-1" v-if="item.status == confirmedStatus">
              <v-icon
                color="grey"
                @click.stop.prevent
                @click="deleteBookingRequest(item)"
              >
                mdi-delete
              </v-icon>
            </div>
          </template>
          <template v-slot:item.errors="{ item }">
            <v-expansion-panels
              v-if="getRequestErrors(item)"
              class="d-flex align-center"
              flat
            >
              <v-expansion-panel
                class="d-flex flex-column align-center"
                style="height: 100%; border-radius: 0;"
                @click.stop
              >
                <v-expansion-panel-header
                  class="pa-0"
                  style="min-height: 0 !important; height: 100%;"
                >
                  <span class="header text-caption primary--text ml-2">
                    {{$t('label.requestErrors')}}
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  class="pa-0 align-self-start"
                  style="width: 100%;"
                  @click.native.stop
                >
                  <span class="text-caption" @click.stop v-html="getRequestErrors(item).message" />
                  <div @click.stop.prevent>
                    <v-row dense v-for="(error, i) in getRequestErrors(item).details" :key="i">
                      <v-col class="pb-0">
                        <span class="text-caption">
                          {{ error }}
                        </span>
                      </v-col>
                    </v-row>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row class="flex-grow-0 ma-0">
      <v-col class="d-flex justify-end pa-0">
        <ElectronicFlowSelectionMenu @select="newElectronicFlow" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Booking from '@/mixins/Booking.js'
import { dateFilter } from '@/filters/dateFilter'
import { electronicFlowService } from '@/services/electronicFlow.service.js'
import ElectronicFlowSelectionMenu from '@/components/elements/booking/ElectronicFlowSelectionMenu.vue'

export default {
  name: 'ElectronicFlowsPage',
  props: ['pratica'],
  emits: ['select-request'],
  mixins: [Booking],
  components: { ElectronicFlowSelectionMenu },
  data() {
    return {
      refreshPageTimer: null,
      currentElectronicFlowId: null,
      electronicFlows: [],
      sortBy: 'creationDate',
      sortDesc: true,
      headers: [
        {
          text: this.$t('label.documentNbr'),
          value: 'documentNbr',
          sortable: false,
        },
        {
          text: this.$t('label.status'),
          value: 'status',
          sortable: false,
        },
        {
          text: this.$t('label.type'),
          value: 'objectType',
          sortable: false,
        },
        {
          text: this.$t('label.booking'),
          value: 'carrierReference',
          sortable: false,
        },
        {
          text: this.$t('label.createDate'),
          value: 'creationDate',
          sortable: false,
        },
        {
          text: this.$t('label.errors'),
          value: 'errors',
          sortable: false,
          class: 'pl-6',
        },
        {
          value: 'actions',
          sortable: false,
          class: 'text-end pr-1',
        },
      ],
    }
  },
  methods: {
    init() {
      this.getElectronicFlow().then(() => {
        this.pollElectronicFlow()
        this.currentElectronicFlowId = this.lastSelectedFlow
      })
    },
    getElectronicFlow() {
      return electronicFlowService
        .findByDocumentNbr(this.pratica.documentNbr)
        .then((resp) => {
          if (resp && resp.length) this.electronicFlows = resp
          else this.newElectronicFlow('BK')
        })
    },
    pollElectronicFlow() {
      this.refreshPageTimer = setInterval(this.getElectronicFlow, 5000)
    },
    cancelPollElectronicFlow() {
      clearInterval(this.refreshPageTimer)
    },
    async newElectronicFlow(type) {
      let newElectronicFlow = await this.createElectronicFlow(type)
      this.selectElectronicFlow(newElectronicFlow)
    },
    createElectronicFlow(type) {
      let data = {
        objectType: type,
        status: 'CREATED',
        documentNbr: this.pratica.documentNbr,
      }
      return electronicFlowService.createElectronicFlow(data)
    },
    deleteBookingRequest(electronicFlow) {
      electronicFlowService
        .deleteBookingRequest(electronicFlow.id)
        .then(() =>
          this.setSnackbarMessage({
            text: this.$t('message.bookingDeleted'),
            color: 'success',
          }),
        )
        .catch((error) =>
          this.setSnackbarMessage({ text: error, color: 'error' }),
        )
    },
    selectElectronicFlow(electronicFlow) {
      this.currentElectronicFlowId = electronicFlow.id
      this.setLastSelectedFlow(electronicFlow.id)
      this.$emit('select-request', electronicFlow)
    },
    isSelected(electronicFlow) {
      return electronicFlow.id == this.currentElectronicFlowId ? 'selected-row' : ''
    },
    getRequestErrors(electronicFlow) {
      if (!electronicFlow.bookingRequest) return
      let errors = JSON.parse(electronicFlow.bookingRequest.response)
      if (errors && errors.errorCode && errors.errorCode.startsWith('20'))
        return
      if (errors && errors.errorMessage) {
        let msgParts = errors.errorMessage.split(/-(.*)/s)
        let msg = `<b>${msgParts[0]}</b>`
        if (msgParts[1]) msg += `<br> <span>${msgParts[1]}</span>`
        return { message: msg, details: errors.subErrors }
      }
    },
    // Utils
    formatDateDay(value) {
      return dateFilter.formatDateDay(value)
    },
    formatStatus(status){
      return status.replace("_", " ")
    }
  },
  mounted() {
    this.init()
  },
  destroyed() {
    this.cancelPollElectronicFlow()
  },
}
</script>

<style scoped>
.stepper-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
}
.v-expansion-panels {
  height: 100%;
  width: 25rem;
  border-radius: 0;
  /*overflow: hidden !important;
  white-space: nowrap;
  text-overflow: ellipsis !important;*/
}
.v-expansion-panel::v-deep .v-expansion-panel-content__wrap {
  padding-inline: 0.5rem;
  padding-top: 0;
  padding-bottom: 4px;
}
.v-list-item {
  min-height: 0;
  padding-inline: 0 !important;
  overflow: visible !important;
}
</style>
