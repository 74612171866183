<template>
  <v-flex class="d-flex align-center mx-1" shrink>
    <v-menu
      v-model="menu"
      transition="scale-transition"
      :close-on-content-click="false"
      max-height="250"
      class="py-0"
      offset-y
    >
      <template #activator="{ on: onMenu }">
        <v-tooltip bottom>
          <template #activator="{ on: onTooltip }">
            <v-btn v-on="{ ...onMenu, ...onTooltip }" x-small class="secondary">
              <v-icon size="20">mdi-sort</v-icon>
            </v-btn>
          </template>
          <span class="text-caption">{{ $t('label.sortBy') }}</span>
        </v-tooltip>
      </template>
      <v-form ref="form" class="pa-0">
        <!-- <v-list flat>
          <v-list-item-group v-model="selected">
            <v-list-item v-for="(item, index) in items" :key="index">
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active" color="primary" />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ item.label }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list> -->
        <v-list flat>
          <v-list-item-group>
            <v-list-item v-for="(item, index) in items" :key="index">
              <v-list-item-content @click="select(index)">
                <v-list-item-title>{{ item.label }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-form>
    </v-menu>
  </v-flex>
</template>

<script>
export default {
  name: 'SortByMenu',
  emits: 'sort-pratiche',
  data() {
    return {
      menu: false,
      selected: -1,
      sortBy: {},
      items: [
        { column: 'shipper', label: this.$t('label.shipper'), direction: 'ASC' },
        { column: 'carrier', label: this.$t('label.carrier'), direction: 'ASC' },
        { column: 'pol', label: this.$t('label.loadingNode'), direction: 'ASC' },
        { column: 'pod', label: this.$t('label.dischargeNode'), direction: 'ASC' },
        { column: 'documentDate', label: this.$t('label.createDate'), direction: 'DESC' },
        { column: 'consignee', label: this.$t('label.consignee'), direction: 'ASC' },

        // { column: "createTS", label: this.$t("label.createDate") },
        // { column: "mode", label: this.$t("label.mode") },
        // { column: "operativeStatus", label: this.$t("label.operativeStatus") },
        // { column: "accountUser", label: this.$t("label.accountUser") },
        // { column: "saleOrder", label: this.$t("label.saleOrder") },
        // { column: "sku", label: this.$t("label.sku") },
        // { column: "transhipmentPortLocations", label: this.$t("label.transhipmentPortLocations") },
        // { column: "departureDate", label: this.$t("label.departureDate") },
        // { column: "arrivalDate", label: this.$t("label.arrivalDate") },
        // { column: "deliveryLocation", label: this.$t("label.deliveryLocation") },
        // { column: "trackingInformation", label: this.$t("label.trackingInformation") },
        // { column: "roadHauler", label: this.$t("label.roadHauler") },
        // { column: "incoterms", label: this.$t("label.incoterms") },
      ],
    }
  },
  methods: {
    select(index) {
      this.selected = index
      this.setSortBy()
      this.$emit('sort-pratiche', this.sortBy)
      this.menu = false
      this.resetSelection()
    },
    setSortBy() {
      if (this.selected || this.selected == 0) {
        this.sortBy['column'] = this.items[this.selected].column
        this.sortBy['direction'] = this.items[this.selected].direction
      } else return this.sortBy = null
    },
    resetSelection() {
      this.sortBy = {}
      this.selected = -1
    },
  },
}
</script>

<style scoped>
.checkbox {
  display: grid;
  padding: 0;
}
</style>
