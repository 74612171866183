import { mapState, mapMutations } from 'vuex'
import { dateFilter } from '@/filters/dateFilter'
import { electronicFlowService } from '@/services/electronicFlow.service.js'
import { customersService } from '@/services/customers.service.js'
import Snackbar from '@/components/views/Snackbar.vue'

export default {
  props: ['pratica'],
  emits: ['exit-request', 'step'],
  components: { Snackbar },
  data() {
    return {
      applicantCompanyName: process.env.VUE_APP_APPLICANT_COMPANY,
      companyPhoneNbr: process.env.VUE_APP_APPLICANT_COMPANY_NUMBER,
      companyCity: process.env.VUE_APP_APPLICANT_COMPANY_CITY,
      companyAddress: process.env.VUE_APP_APPLICANT_COMPANY_ADDRESS,
      companyAddressNbr: process.env.VUE_APP_APPLICANT_COMPANY_ADDRESS_NBR,
      companyPostalCode: process.env.VUE_APP_APPLICANT_COMPANY_POSTAL_CODE,
      companyCountryMaersk: process.env.VUE_APP_APPLICANT_COMPANY_COUNTRY_MAERSK,
      companyCountryCmaCgm: process.env.VUE_APP_APPLICANT_COMPANY_COUNTRY_CMACGM,

      companyCodeMaersk: process.env.VUE_APP_APPLICANT_COMPANY_CODE_MAERSK,
      companyCodeCmaCgm: process.env.VUE_APP_APPLICANT_COMPANY_CODE_CMACGM,
      companyCodeYangMing: process.env.VUE_APP_APPLICANT_COMPANY_CODE_YANGMING,

      createdStatus: 'CREATED',
      detailsSelectedStatus: 'DETAILS_SELECTED',
      voyageSelectedStatus: 'VOYAGE_SELECTED',
      equipmentsSelectedStatus: 'EQUIPMENTS_SELECTED',
      partiesSelectedStatus: 'PARTIES_SELECTED',
      paymentSelectedStatus: 'PAYMENT_SELECTED',
      pendingStatus: 'PENDING',
      confirmedStatus: 'CONFIRMED',
      rejectedStatus: 'REJECTED',
      disableNext: false,
      menuProps: {
        offsetY: true,
        overflowY: 'auto',
        maxHeight: '10rem',
      },
      partyTemplate: {
        partyFunction: null,
        party: {
          partyName: null,
          taxReference1: null,
          publicKey: null,
          street: null,
          streetNumber: null,
          floor: null,
          postCode: null,
          city: null,
          stateRegion: null,
          country: null,
          taxReference2: null,
          nmftaCode: null,
        },
        contactDetails: {
          name: null,
          email: null,
          phone: null,
        },
        isToBeNotified: null,
      },

      bookings: []
    }
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapState('electronicFlow', ['company', 'lastSelectedFlow', 'electronicFlow']),
    ...mapState('shippingInstruction', ['selectedBooking', 'bookingRequest', 'shippingInstruction']),
    electronicFlowStatus() {
      return this.electronicFlow.status
    },
    savedShippingInstructionRequest() {
      if (!this.electronicFlow.shippingInstruction) return
      let request = JSON.parse(this.electronicFlow.shippingInstruction.request)
      return request ? request : null
    },
    savedShippingInstructionEquipments() {
      if (!this.electronicFlow.shippingInstruction) return
      let equipments = JSON.parse(this.electronicFlow.shippingInstruction.equipments)
      return equipments ? equipments : null
    },
    savedCommodity() {
      if (!this.savedShippingInstructionRequest) return
      let cargoItems = this.savedShippingInstructionRequest.cargoItems
      return cargoItems.length ? cargoItems[0].hsCode : null
    },
    savedWeightUom(){
      if (!this.savedShippingInstructionRequest) return
      let cargoItems = this.savedShippingInstructionRequest.cargoItems
      return cargoItems.length ? cargoItems[0].weightUnit : null
    },
    savedVolumeUom(){
      if (!this.savedShippingInstructionRequest) return
      let cargoItems = this.savedShippingInstructionRequest.cargoItems
      return cargoItems.length ? cargoItems[0].volumeUnit : null
    },
    savedAgreementReference(){
      return this.savedShippingInstructionRequest ? this.savedShippingInstructionRequest.agreementReference : null
    },
    savedCarrierBookingReference(){
      return this.savedShippingInstructionRequest && this.savedShippingInstructionRequest.carrierBookingReference.length ? this.savedShippingInstructionRequest.carrierBookingReference[0] : null
    },
    savedNumberOfOriginals(){
      if (!this.savedShippingInstructionRequest) return
      return {
        freighted: this.savedShippingInstructionRequest.originalFreighted,
        unfreighted: this.savedShippingInstructionRequest.originalUnfreighted
      }
    },
    savedNumberOfCopies(){
      if (!this.savedShippingInstructionRequest) return
      return {
        freighted: this.savedShippingInstructionRequest.copiesFreighted,
        unfreighted: this.savedShippingInstructionRequest.copiesUnfreighted
      }
    },
    savedClauses(){
      return this.savedShippingInstructionRequest ? this.savedShippingInstructionRequest.clauses : null
    },
    savedInvoicePayableAt(){
      return this.savedShippingInstructionRequest ? this.savedShippingInstructionRequest.invoicePayableAt : null
    },
    savedTransportDocumentType(){
      return this.savedShippingInstructionRequest ? this.savedShippingInstructionRequest.transportDocumentType : null
    },
    savedExportTransport(){
      return this.savedShippingInstructionRequest ? this.savedShippingInstructionRequest.exportTransport : null
    },
    savedImportTransport(){
      return this.savedShippingInstructionRequest ? this.savedShippingInstructionRequest.importTransport : null
    },
    savedShipmentLocations(){
      return this.savedShippingInstructionRequest ? this.savedShippingInstructionRequest.shipmentLocations : null
    },
    savedParties(){
      return this.savedShippingInstructionRequest ? this.savedShippingInstructionRequest.documentParties : null
    },
    savedPayerParty(){
      if (!this.savedParties) return
      let parties = this.savedParties.filter((party) => party.partyFunction == 'PAY')
      return parties.length ? parties[0] : null
    },
    savedPaymentCode(){
      return this.savedShippingInstructionRequest ? this.savedShippingInstructionRequest.paymentCode : null
    },
    // From Booking Request
    bookingReqContract(){
      return this.bookingRequest ? this.bookingRequest.contractQuotationReference : null
    },
    bookingReqVoyage() {
      return this.selectedBooking ? JSON.parse(this.selectedBooking.voyageId) : null
    },
    bookingReqMainVessel(){
      if (!this.bookingReqVoyage) return
      let vesselLegs = this.bookingReqVoyage.routeLegs.filter((leg) => leg.transport.vessel.vesselName != null)
      if (!vesselLegs.length) return
      vesselLegs = this.sortByDepartureDateAsc(vesselLegs)
      return vesselLegs[0].transport.vessel.vesselName
    },
    bookingReqVoyageNbr(){
      if (!this.bookingReqVoyage) return
      let vesselLegs = this.bookingReqVoyage.routeLegs.filter((leg) => leg.transport.vessel.vesselName != null)
      if (!vesselLegs.length) return
      vesselLegs = this.sortByDepartureDateAsc(vesselLegs)
      return vesselLegs[0].transport.voyageNumber
    },
    bookingReqPol() {
      if (!this.bookingReqVoyage) return
      let pol = this.bookingReqVoyage.originPoint ? this.bookingReqVoyage.originPoint : null
      return pol
    },
    bookingReqPod() {
      if (!this.bookingReqVoyage) return
      let pod = this.bookingReqVoyage.destinationPoint ? this.bookingReqVoyage.destinationPoint : null
      return pod
    },
    bookingReqPolHaulageDetails() {
      if (!this.bookingRequest || !this.bookingRequest.requestedEquipments.length) return []
      let haulageDetails = this.bookingRequest.requestedEquipments[0].haulageDetails
      let polHaulage = haulageDetails ? haulageDetails.filter((detail) => detail.operationalStage == 'EXPORT') : []
      return polHaulage.length ? polHaulage[0] : null
    },
    bookingReqPodHaulageDetails() {
      if (!this.bookingRequest || !this.bookingRequest.requestedEquipments.length) return []
      let haulageDetails = this.bookingRequest.requestedEquipments[0].haulageDetails
      let podHaulage = haulageDetails ? haulageDetails.filter((detail) => detail.operationalStage == 'IMPORT') : []
      return podHaulage.length ? podHaulage[0] : null
    },
    bookingReqPolHaulageMovementType() {
      return this.bookingReqPolHaulageDetails ? this.bookingReqPolHaulageDetails.movementType : null
    },
    bookingReqPodHaulageMovementType() {
      return this.savedPodHaulageDetails ? this.savedPodHaulageDetails.movementType : null
    },
    // savedPolHaulage(){
    //   if (!this.bookingReqPolHaulageDetails) return
    //   return this.bookingReqPolHaulageDetails.haulageType
    // },
    // savedPodHaulage(){
    //   if (!this.savedPodHaulageDetails) return
    //   return this.savedPodHaulageDetails.haulageType
    // },
    bookingReqPolHaulage(){
      let polHaulage = this.bookingRequest ? this.bookingRequest.receiptTypeAtOrigin : null
      if (polHaulage) {
        switch (polHaulage) {
        case 'CY':
          return 'MERCHANT'
        case 'SD':
          return 'CARRIER'
        }
      } return null
    },
    bookingReqPodHaulage(){
      let podHaulage = this.bookingRequest ? this.bookingRequest.deliveryTypeAtDestination : null
      if (podHaulage) {
        switch (podHaulage) {
        case 'CY':
          return 'MERCHANT'
        case 'SD':
          return 'CARRIER'
        }
      } return null
    },
    bookingReqPolHaulageLocation() {
      return this.bookingReqPolHaulageDetails && this.bookingReqPolHaulageDetails.haulageSites ? this.bookingReqPolHaulageDetails.haulageSites[0].address : null
    },
    bookingReqPodHaulageLocation() {
      return this.bookingReqPodHaulageDetails && this.bookingReqPodHaulageDetails.haulageSites ? this.bookingReqPodHaulageDetails.haulageSites[0].address : null
    },
    // Editable steps
    uneditableRequest() {
      return this.electronicFlowStatus == this.pendingStatus || this.electronicFlowStatus == this.rejectedStatus || this.electronicFlowStatus == this.cancelledStatus
    },
    editableSelectVoyage() {
      return this.electronicFlowStatus == this.detailsSelectedStatus || this.editableSelectEquipments
    },
    editableSelectEquipments() {
      return this.electronicFlowStatus == this.voyageSelectedStatus || this.editableSelectParties
    },
    editableSelectParties() {
      return this.electronicFlowStatus == this.equipmentsSelectedStatus || this.editableSelectPayment
    },
    editableSelectPayment() {
      return this.electronicFlowStatus == this.partiesSelectedStatus || this.editableReview
    },
    editableReview() {
      return this.electronicFlowStatus == this.paymentSelectedStatus || this.electronicFlowStatus == this.confirmedStatus
    },
    // Utils
    userCompleteName() {
      return `${this.user.firstName} ${this.user.lastName}`.replace(/\b\w/g, (x) => x.toUpperCase())
    },
    required() {
      return [(v) => !!v || this.$t('message.required')]
    },
  },
  methods: {
    ...mapMutations('snackbar', ['setSnackbarMessage']),
    ...mapMutations('shippingInstruction', [
      'setSelectedBooking',
      'setBookingRequest',
      'resetShippingInstruction',
      'setShippingInstruction',
      'setCarrierName',
      'setTransportDocumentType',
      'setPaymentCode',
      'setChargeCode',
      'setAgreementReference',
      'setIsShipperOnboard',
      'setCarrierBookingReference',
      'setInvoicePayableAt',
      'setOriginalFreighted',
      'setOriginalUnfreighted',
      'setCopiesFreighted',
      'setCopiesUnfreighted',
      'setImportTransport',
      'setExportTransport',
      'setCargoItems',
      'setUtilizedTransportEquipments',
      'setDocumentParties',
      'setShipmentLocations',
      'setReferences',
      'setRemarks',
      'setClauses'
    ]),
    ...mapMutations('electronicFlow', [
      'resetElectronicFlow',
      'setCompany',
      'setLastSelectedFlow',
      'setElectronicFlow',
      'setDocumentNbr',
      'setObjectType',
      'setStatus',
      'setShippingInstructionJson',
      'setShippingInstructionEquipments'
    ]),
    findElectronicFlowById(id) {
      return electronicFlowService.findById(id).then((resp) => {
        if (!resp) return
        this.setElectronicFlow(resp)
      })
    },
    updateElectronicFlow(id, data) {
      electronicFlowService
        .updateElectronicFlow(id, data)
        .then(() => this.findElectronicFlowById(id))
    },
    updateShippingInstructionAndSaveElectronicFlow() {
      this.setShippingInstructionJson(JSON.stringify(this.shippingInstruction))
      this.updateElectronicFlow(this.electronicFlow.id, this.electronicFlow)
    },
    browseCommodities(input) {
      if (!input) return
      let regExp = /[a-zA-Z]/g
      let code = ''
      let description = ''
      if (regExp.test(input)) description = input
      else code = input
      return electronicFlowService.getBookingRequestCommodities(this.company, code, description)
    },
    exitRequest(){
      this.$emit('exit-request')
    },
    loadSelectedBooking(){
      return this.findAllBookings().then(() => {
        if (this.savedCarrierBookingReference)
          this.selectBookingRequest(this.savedCarrierBookingReference)
      })
    },
    findAllBookings() {
      return electronicFlowService
        .findAllBookingsByDocumentNbr(this.electronicFlow.documentNbr)
        .then((resp) => {
          if (!resp) return
          this.bookings = resp
        })
        .catch((error) =>
          this.setSnackbarMessage({ text: error, color: 'error' }),
        )
    },
    selectBookingRequest(selectedBookings) {
      // let selectedBooking = selectedBookings[0]
      let bookingRequest
      let selectedBooking
      if (!selectedBookings) return
        selectedBooking = selectedBookings
      if (!selectedBookings.request)
        selectedBooking = this.selectedBookings = this.bookings.filter((booking) => booking.carrierReference == selectedBooking)[0]
      bookingRequest = JSON.parse(selectedBooking.request)

      this.setSelectedBooking(selectedBooking)
      this.setBookingRequest(bookingRequest)
    },
    // Customers list handling
    getCustomers(input) {
      if (!input) return
      clearTimeout(this.searchTimeoutId)
      this.searchTimeoutId = setTimeout(async () => {
        let customers = await this.browseCustomers(input, 'customerName')
        this.customers = customers ? customers.resultList : []
      }, 500)
    },
    browseCustomers(input, field) {
      if (!input || !input.length) return
      let browseRequest = {
        offset: 0,
        limit: 1000,
        filter: {},
        sortingList: [{column: "customerName", direction: "ASC"}],
      }
      browseRequest.filter[field] = input
      return customersService.browse(browseRequest)
    },
    filterCustomers(item, queryText) {
      return item.customerName.toLowerCase().indexOf(queryText.toLowerCase()) > -1
    },
    splitAddress(address) {
      let splittedAddress = { street: '', number: '' }
      splittedAddress.number = this.extractCivicNumber(address)
      splittedAddress.street = address.replace(splittedAddress.number, '').trim()
      return splittedAddress
    },
    extractCivicNumber(address) {
      let rx = /\d+[ \w]{0,2}/g
      let arr = rx.exec(address)
      return arr && arr.length ? arr[0] : null
    },
    getPartyFunction(partyFunction){
      switch (partyFunction) {
        case 'REQ':
          return 'TRANSPORT DOCUMENT RECEIVER'
        case 'OS':
          return 'SHIPPER'
        case 'CN':
          return 'CONSIGNEE'
        case 'N1':
          return 'NOTIFY PARTY'
        case 'PAY':
          return 'PAYER'
      }
    },
    // Utils
    formatDate(date) {
      return dateFilter.formatDate(date)
    },
    formatDateDayIso(date) {
      return dateFilter.formatDateDayIso(date)
    },
    formatAddress(address) {
      if (!address) return
      let city = `${address.city}, ${address.country}\n`
      let street = address.street && address.streetNumber ? `${address.street} ${address.streetNumber}` : null
      return street ? city + street : city
    },
    getShortLocationLabel(location) {
      if (!location) return
      return `${location.locationName}, ${location.countryCode}`
    },
    // partyAddressCityLabel(address) {
    //   let label = ''
    //   label += address.city ? `${address.city}` : ''
    //   label += address.country ? `, ${address.country}` : ''
    //   return label
    // },
    // partyAddressStreetLabel(address) {
    //   let label = ''
    //   label += address.street ? `${address.street}` : ''
    //   label += address.streetNumber ? ` ${address.streetNumber}` : ''
    //   return label
    // },
    partyAddressCityLabel(city, country) {
      let label = ''
      label += city ? `${city}` : ''
      label += country ? `, ${country}` : ''
      return label
    },
    partyAddressStreetLabel(street, streetNumber) {
      let label = ''
      label += street ? `${street}` : ''
      label += streetNumber ? ` ${streetNumber}` : ''
      return label
    },
    sortByDepartureDateAsc(list) {
      if (!list.length) return []
      return list.sort(function (a, b) {
        if (a.departureDateTime > b.departureDateTime) return 1
        if (a.departureDateTime < b.departureDateTime) return -1
        return 0
      })
    },
    getCmaCgmHaulageType(type){
      switch (type) {
        case 'CY':
          return 'MERCHANT'
        case 'SD':
          return 'CARRIER'
      } return
    }
  },
  created(){
  }
}
