export default {
  methods: {
    setBrowseMemory(name, browseMemory) {
      let memory = localStorage.getItem('browseMemory')

      if (memory == null) memory = {}
      else memory = JSON.parse(memory)

      memory[name] = {
        browseFilter: browseMemory.browseFilter,
        visualFilter: browseMemory.visualFilter,
        browseLimit: browseMemory.browseLimit,
        browseOffset: browseMemory.browseOffset,
        sortBy: browseMemory.sortBy,
        currentPage: browseMemory.currentPage,
      }
      localStorage.setItem('browseMemory', JSON.stringify(memory))
    },
    getBrowseMemory(name) {
      let memory = localStorage.getItem('browseMemory')

      if (memory == null) memory = {}
      else memory = JSON.parse(memory)

      return memory[name]
    },
    removeBrowseMemory(name) {
      let memory = localStorage.getItem('browseMemory')

      if (memory == null) memory = {}
      else memory = JSON.parse(memory)

      if (Object.prototype.hasOwnProperty.call(memory, name)) {
        delete memory[name]
        Object.keys(memory).length == 0 ? localStorage.removeItem('browseMemory') : localStorage.setItem('browseMemory', JSON.stringify(memory))
      }
    },
    setFilterMemory(name, filterMemory) {
      let filter = localStorage.getItem('currentFilter')

      if (filter == null) filter = {}
      else filter = JSON.parse(filter)

      filter[name] = filterMemory

      localStorage.setItem('currentFilter', JSON.stringify(filter))
    },
    getFilterMemory(name) {
      let filter = localStorage.getItem('currentFilter')

      if (filter == null) filter = {}
      else filter = JSON.parse(filter)

      return filter[name]
    },
    removeFilterMemory(name) {
      let filter = localStorage.getItem('currentFilter')

      if (filter == null) filter = {}
      else filter = JSON.parse(filter)

      if (Object.prototype.hasOwnProperty.call(filter, name)) {
        delete filter[name]
        Object.keys(filter).length == 0 ? localStorage.removeItem('currentFilter') : localStorage.setItem('currentFilter', JSON.stringify(filter))
      }
    },
    setCurrentRow(name, selectedRow) {
      let currentRow = localStorage.getItem('currentRow')

      if (currentRow == null) currentRow = {}
      else currentRow = JSON.parse(currentRow)

      currentRow[name] = selectedRow

      localStorage.setItem('currentRow', JSON.stringify(currentRow))
    },
    getCurrentRow(name) {
      let currentRow = localStorage.getItem('currentRow')

      if (currentRow == null) currentRow = {}
      else currentRow = JSON.parse(currentRow)

      return currentRow[name]
    },
    removeCurrentRow(name) {
      let currentRow = localStorage.getItem('currentRow')

      if (currentRow == null) currentRow = {}
      else currentRow = JSON.parse(currentRow)

      if (Object.prototype.hasOwnProperty.call(currentRow, name)) {
        delete currentRow[name]
        Object.keys(currentRow).length == 0 ? localStorage.removeItem('currentRow') : localStorage.setItem('currentRow', JSON.stringify(currentRow))
      }
    },
  },
}
