<template>
  <v-expand-transition>
    <v-card outlined class="rounded-0" v-show="open">
      <v-card-text class="black--text">
        <v-row dense>
          <v-col class="d-inline-flex">
            <v-col class="pa-0">
              <ReadonlyField
                  :label="$t('label.company')"
                  :value="pratica.supplierName"
              />
            </v-col>
          </v-col>
          <v-col class="d-inline-flex">
            <v-col class="pa-0">
              <ReadonlyField
                  :label="'B/L'"
                  :value="pratica.billOfLading"
              />
            </v-col>
          </v-col>
          <v-col class="d-inline-flex">
            <v-col class="pa-0">
              <ReadonlyField
                  :label="$t('label.vessel')"
                  :value="pratica.vesselName"
              />
            </v-col>
          </v-col>
          <v-col class="d-inline-flex">
            <v-col class="pa-0">
              <ReadonlyField
                  :label="$t('label.departure')"
                  :value="pratica.pol + (seaTrackingDepartureDate ? ' - ' + seaTrackingDepartureDate : '')"
              />
            </v-col>
          </v-col>
          <v-col class="d-inline-flex">
            <v-col class="pa-0">
              <ReadonlyField
                  :label="$t('label.arrival')"
                  :value="pratica.pod + (seaTrackingArrivalDate ? ' - ' + seaTrackingArrivalDate : '')"
              />
            </v-col>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="pa-0">
        <v-data-table
            :headers="trackingHeadersMare"
            :items="seaTerminalEvents"
            :item-class="isDepartedFromPol"
            :items-per-page="1000"
            hide-default-footer
            class="pa-0"
            dense
        >
          <template slot="no-data">
            <v-icon color="warning" class="mr-1" size="20">mdi-alert</v-icon>
            <span class="font-weight-medium warning--text"> {{ $t('label.noTrackingInfo') }}</span>
          </template>
          <template v-slot:item.eventDateTime="{ item }">{{ formatDateShort(item.eventDateTime) }}</template>
          <template v-slot:item.eventCode="{ item }">
            <span :class="isDepartedFromPol(item) ? captionBoldClass : captionClass">
              {{
                item.eventCode === 'EST' ? 'ESTIMATED'
                    : item.eventCode === 'ACT' ? 'ACTUAL'
                        : item.eventCode === 'PLN' ? 'PLANNED' : ''
              }}
            </span>
          </template>
          <template v-slot:item.eventName="{ item }">
            <span :class="isDepartedFromPol(item) ? captionBoldClass : captionClass">
              {{ item.eventName }}
            </span>
          </template>
          <template v-slot:item.eventType="{ item }">
            <span :class="isDepartedFromPol(item) ? captionBoldClass : captionClass">
              {{ item.eventType }}
            </span>
          </template>
          <template v-slot:item.eventDescription="{ item }">
            <span :class="isDepartedFromPol(item) ? captionBoldClass : captionClass">
              {{ item.eventDescription }}
            </span>
          </template>
          <template v-slot:item.transportMode="{ item }">
            <div class="d-flex justify-center">
              <v-icon v-if="item.transportMode === 'TRUCK'" color="primary">mdi-truck</v-icon>
              <v-icon v-if="item.transportMode === 'VESSEL' || item.transportMode === '1'" color="primary">mdi-ferry</v-icon>
              <v-icon v-if="item.transportMode === 'RAIL'" color="primary">mdi-train</v-icon>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-expand-transition>
</template>

<script>
import Tracking from '@/mixins/Tracking'
import ReadonlyField from '@/components/elements/ReadonlyField.vue'

export default {
  name: 'PraticaMareExtension',
  props: ['pratica', 'open'],
  mixins: [Tracking],
  components: {ReadonlyField},
  data() {
    return {}
  },
  watch: {
    open(isOpen) {
      if (!isOpen) return
      this.fixPolPodLocode()
      this.getSeaTracking()
    },
  },
  methods: {},
}
</script>

