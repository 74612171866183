import { authHeader } from '@/helpers/auth-header'
import { commons } from './commons'

export const praticaActionsService = {
  getActionsForPratica,
  updateActions,
}

const resourceUrl = process.env.VUE_APP_GSCV_ENGINE_URL + `/pratica-actions`

function getActionsForPratica(praticaId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/pratica/${praticaId}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function updateActions(id, data) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }
  return fetch(resourceUrl + `/${id}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}
