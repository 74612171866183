var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "stepper-wrapper", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-container",
                {
                  staticClass: "d-table pa-2",
                  attrs: { "fill-height": "", fluid: "" },
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "d-table-row", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            staticClass: "ma-0 pa-0 text-caption",
                            attrs: {
                              label: _vm.$t("label.search"),
                              "append-icon": "mdi-magnify",
                              "single-line": "",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.imballiSearch,
                              callback: function ($$v) {
                                _vm.imballiSearch = $$v
                              },
                              expression: "imballiSearch",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "d-table-row fill-height",
                      attrs: { dense: "" },
                    },
                    [
                      _c(
                        "v-col",
                        { staticClass: "fill-height overflow-y-auto" },
                        [
                          _c(
                            "v-data-table",
                            {
                              staticClass: "pa-0",
                              attrs: {
                                headers: _vm.imballiHeaders,
                                items: _vm.imballi,
                                search: _vm.imballiSearch,
                                "items-per-page": 1000,
                                "show-select": !_vm.uneditableRequest,
                                "hide-default-footer": "",
                                "checkbox-color": "primary",
                                dense: "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item.transportOrderStatus",
                                  fn: function ({ item }) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatTrasportOrderStatus(
                                                item.transportOrderStatus
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.imballiSelected,
                                callback: function ($$v) {
                                  _vm.imballiSelected = $$v
                                },
                                expression: "imballiSelected",
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "no-data" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { color: "warning", size: "20" },
                                    },
                                    [_vm._v(" mdi-alert ")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-weight-medium warning--text",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("label.noAvailableData")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "flex-grow-0", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "d-flex pa-3" },
            [
              _c(
                "v-btn",
                {
                  attrs: { small: "", icon: "", color: "primary" },
                  on: { click: _vm.exitRequest },
                },
                [
                  _c("v-icon", { attrs: { size: "30" } }, [
                    _vm._v("mdi-arrow-left-circle"),
                  ]),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    small: "",
                    color: "primary",
                    disabled: _vm.disableNext,
                  },
                  on: { click: _vm.submitBookingRequestDetails },
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.$t("label.next")))]),
                  _c("v-icon", [_vm._v("mdi-play")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }