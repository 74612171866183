<template>
  <v-container class="stepper-wrapper" fluid>
    <v-row dense>
      <v-col>
        <!-- ADDED -->
        <v-container class="d-table pa-0 pr-2" fill-height fluid>
          <v-row class="d-table-row" dense>
            <v-tabs
              v-model="tab"
              background-color="transparent"
              color="primary"
              height="2rem"
              grow
            >
              <v-tab>
                Standard
              </v-tab>
              <!-- <v-tab>
                Oversized
              </v-tab> -->
            </v-tabs>
          </v-row>

          <v-row class="d-table-row fill-height" dense>
            <v-tabs-items v-model="tab" class="fill-height overflow-y-auto">
              <v-tab-item>
                <BookingCommoditiesStandard
                  ref="commoditiesStandard"
                  @loaded="(isLoaded) => (loading = !isLoaded)"
                />
              </v-tab-item>
              <!-- <v-tab-item>
                <v-card flat></v-card>
              </v-tab-item> -->
            </v-tabs-items>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row class="flex-grow-0" dense>
      <v-col class="d-flex pa-3">
        <v-btn small icon color="primary" @click="exitRequest">
          <v-icon size="30">mdi-arrow-left-circle</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn small color="primary" class="mr-2" @click="$emit('step', false)">
          <v-icon>mdi-step-backward</v-icon>
          <span>{{ $t('label.back') }}</span>
        </v-btn>
        <v-btn
          small
          color="primary"
          :disabled="disableNext || loading"
          @click="submitCommodityDetails"
        >
          <span>{{ $t('label.next') }}</span>
          <v-icon>mdi-play</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Booking from '@/mixins/Booking.js'
import BookingCommoditiesStandard from '@/components/elements/booking/BookingCommoditiesStandard.vue'

export default {
  name: 'BookingCommodities',
  mixins: [Booking],
  components: { BookingCommoditiesStandard },
  data() {
    return {
      tab: 0,
      loading: true,
    }
  },
  methods: {
    submitCommodityDetails() {
      setTimeout(() => {
        let ref
        switch (this.tab) {
          case 0:
            ref = this.$refs.commoditiesStandard
            break
          case 1:
            ref = this.$refs.commoditiesOversized
            break
        }
        if (!ref.$refs.form.validate()) return
        ref.setCommodityFields()
        this.$emit('step', true)
      }, 200)
    },
  },
}
</script>

<style scoped>
.stepper-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
}
.v-radio::v-deep .v-label {
  margin: 0 !important;
}
/*.row {
  border: solid 1px orange;
}
.col {
  border: solid 1px red;
}*/
</style>
