<template>
  <v-card flat outlined class="pa-0 pb-1 fill-height d-flex flex-column">
    <v-list-item class="pr-1 pl-2">
      <v-list-item-content>
        <v-list-item-title class="text-h6 font-weight-black indigo--text">
          {{ title }}
        </v-list-item-title>
      </v-list-item-content>
      <v-flex shrink class="d-flex pa-1 py-0 mr-3">
        <v-icon class="mr-1" small color="primary">mdi-clock</v-icon>
        <span class="text-caption primary--text">
          {{ $t('label.lastTwelveMonths') }} {{ $t('label.sinceDate') }}
        </span>
      </v-flex>
      <v-chip class="font-weight-black text-h6 ma-0" color="primary lighten-1">
        <div class="indigo--text">{{ count }}</div>
      </v-chip>
    </v-list-item>
    <v-divider class="ma-1 my-0" />
    <v-row class="ma-0" dense>
      <v-col
        v-for="element in elements"
        :key="element.name"
        class="d-flex justify-center align-end pa-0"
      >
        <span class="text-overline text-center text--disabled text-uppercase">
          {{ element.name }}
          <span class="font-weight-black primary--text">
            {{ element.value }}
          </span>
        </span>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'KpiCard',
  props: ['title', 'elements'],
  components: {},
  data() {
    return {}
  },
  computed: {
    count() {
      let count = 0
      this.elements.forEach((element) => {
        count += element.value
      })
      return count
    },
  },
}
</script>

<style scoped>
.v-list-item__content {
  padding: 0;
}
</style>
