const state = {
  search: null,
  searchField: '',
}

const mutations = {
  updateSearch(state, search) {
    state.search = search
  },
  updateSearchField(state, searchField) {
    if (searchField == null) state.searchField = ''
    else state.searchField = searchField
  },
}

export const searchbar = {
  namespaced: true,
  state,
  mutations,
}
