<template>
  <v-card class="my-1" outlined>
    <v-row
      dense
      :class="['ma-0', { selected: shipment.isSelected }]"
      @click="selectShipmentDocumentNbr"
    >
      <!-- Document nbr. & Document Date -->
      <v-col
        cols="auto"
        class="py-0 text-caption font-weight-bold d-flex align-center"
      >
        <v-row dense class="ma-0 d-flex flex-column justify-center text-left">
          <v-col class="py-0 d-flex flex-column">
            <span
              class="text-subtitle-1 font-weight-black"
              v-text="shipment.documentNbr"
            ></span>
            <v-chip color="#e0e0e0" label small>
              <v-icon color="#757575" small>mdi-file-document-outline</v-icon>
              <span class="font-weight-medium ml-1 text-caption text-left">
                {{ $t("label.shipmentNbr") }}
              </span>
            </v-chip>
          </v-col>
          <v-col class="py-0">
            <ReadonlyField
              :label="$t('label.documentDate')"
              :value="
                new Date(shipment.documentDate).toLocaleDateString('en-GB', {
                  day: 'numeric',
                  month: 'numeric',
                  year: '2-digit',
                })
              "
            />
          </v-col>
        </v-row>
      </v-col>
      <v-divider vertical />

      <!-- Shipper & Consignee -->
      <v-col class="py-0 text-caption font-weight-bold d-flex align-center"
        ><v-row dense class="ma-0 d-flex flex-column justify-center text-left">
          <v-col class="py-0">
            <ReadonlyField
              :label="$t('label.shipper')"
              :value="shipment.shipperName"
            />
          </v-col>
          <v-divider class="ma-1" />
          <v-col class="py-0">
            <ReadonlyField
              :label="$t('label.consigneeName')"
              :value="shipment.consigneeName"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-divider vertical />

      <!-- Customer &  Pol/Pod-->
      <v-col class="py-0 text-caption font-weight-bold d-flex align-center"
        ><v-row dense class="ma-0 d-flex flex-column justify-center text-left">
          <v-col class="py-0">
            <ReadonlyField
              :label="$t('label.customer')"
              :value="shipment.clientName"
            />
          </v-col>
          <v-divider class="ma-1" />
          <v-row class="ma-0">
            <v-col class="py-0 px-1">
              <ReadonlyField
                :label="$t('label.boardingNode')"
                :value="shipment.pol"
              />
            </v-col>
            <v-col class="py-0 px-1">
              <ReadonlyField
                :label="$t('label.landingNode')"
                :value="shipment.pod"
              />
            </v-col>
          </v-row>
        </v-row>
      </v-col>
      <!-- <v-divider vertical /> -->

      <!-- Pol/Pod -->
      <!-- <v-col
        cols="2"
        class="py-0 text-caption font-weight-bold d-flex align-center"
        ><v-row dense class="ma-0 d-flex justify-center text-left">
          <ReadonlyField
            :label="$t('label.pol') + '/' + $t('label.pod')"
            :value="shipment.pol + '/' + shipment.pod"
        /></v-row>
      </v-col>
      <v-divider vertical /> -->

      <!-- Document Date -->
      <!-- <v-col
        cols="2"
        class="py-0 text-caption font-weight-bold d-flex align-center"
        ><v-row dense class="ma-0 d-flex justify-center text-center">
          <ReadonlyField
            :label="$t('label.documentDate')"
            :value="shipment.documentDate"
        /></v-row>
      </v-col> -->
    </v-row>
  </v-card>
</template>

<script>
import ReadonlyField from "../elements/ReadonlyField.vue";

export default {
  data() {
    return {};
  },
  props: ["shipment"],
  emits: ["selected-selected-shipment-document-nbr"],
  methods: {
    selectShipmentDocumentNbr() {
      this.shipment.isSelected = !this.shipment.isSelected;
      this.$emit("selected-shipment-document-nbr", this.shipment.documentNbr);
    },
  },
  components: { ReadonlyField },
};
</script>

<style scoped>
.v-card:not(.selected):hover {
  background-color: #edf5ff;
}

.selected {
  background-color: #edf5ff;
}
</style>
