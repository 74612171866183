<template>
  <v-btn fab icon height="50" width="50" class="primary" @click="generateLink">
    <v-icon color="white" size="30">mdi-link-variant</v-icon>
  </v-btn>
</template>

<script>
import { externalTrackingService } from '@/services/externalTracking.service.js'

export default {
  name: 'LinkPratica',
  props: ['documentNbr'],
  emits: ['send-link'],
  data() {
    return {
      link: null,
    }
  },
  methods: {
    generateLink() {
      externalTrackingService.createLink(this.documentNbr).then((resp) => {
        if (!resp) return
        this.link = resp
        this.$emit('send-link', this.link)
      })
    },
  },
}
</script>
