<template>
  <v-card>
    <!-- <v-divider class="ma-0 mb-0" /> -->
    <v-card-text class="pa-0">
      <v-carousel
        v-model="page"
        hide-delimiters
        :show-arrows="false"
        width="100%"
        height="100%"
        touchless
      >
        <v-carousel-item :value="0">
          <v-container class="pa-0" fill-height fluid style="display: grid;">
            <v-row class="ma-0">
              <v-col class="pb-0">
                <CustomAutocomplete
                  ref="client"
                  :current="filterSelection.clientId"
                  :value="'customerId'"
                  :text="'customerName'"
                  :label="$t('label.client')"
                  :icon="'mdi-account'"
                  :textClass="'text-caption'"
                  @select="(v) => onFieldChange(v, 'clientId', 'value', 'text')"
                  @browse="(v) => browseCustomers(v, 'client')"
                />
              </v-col>
              <v-col class="pb-0">
                <CustomAutocomplete
                  ref="shipper"
                  :current="filterSelection.shipperId"
                  :value="'customerId'"
                  :text="'customerName'"
                  :label="$t('label.shipper')"
                  :icon="'mdi-account-arrow-right'"
                  :textClass="'text-caption'"
                  @select="(v) => onFieldChange(v, 'shipperId', 'value', 'text')"
                  @browse="(v) => browseCustomers(v, 'shipper')"
                />
              </v-col>
              <v-col class="pb-0">
                <CustomAutocomplete
                  ref="consignee"
                  :current="filterSelection.consigneeId"
                  :value="'customerId'"
                  :text="'customerName'"
                  :label="$t('label.consignee')"
                  :icon="'mdi-account-arrow-left'"
                  :textClass="'text-caption'"
                  @select="(v) => onFieldChange(v, 'consigneeId', 'value', 'text')"
                  @browse="(v) => browseCustomers(v, 'consignee')"
                />
              </v-col>
              <v-col class="pb-0">
                <CustomAutocomplete
                  ref="carrier"
                  :current="filterSelection.carrier"
                  :value="'supplierId'"
                  :text="'supplierName'"
                  :label="$t('label.shippingLine')"
                  :icon="'mdi-account-settings'"
                  :textClass="'text-caption'"
                  @select="(v) => onFieldChange(v, 'carrier', 'value', 'text')"
                  @browse="(v) => browseSuppliers(v, 'carrier')"
                />
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col class="pb-0" v-if="internalVisibilityAuth">
                <v-text-field
                  :value="filterSelection.shipperInvoiceReference?.text"
                  :label="$t('label.shipperInvoiceReference')"
                  ref="shipperInvoiceReference"
                  @change="(v) => onFieldChange(v, 'shipperInvoiceReference', 'text', 'value')"
                  prepend-inner-icon="mdi-file-document-outline"
                  class="text-caption"
                  hide-details
                  clearable
                  dense
                />
              </v-col>
              <v-col class="pb-0" v-if="internalVisibilityAuth">
                <v-autocomplete
                  :value="filterSelection.userId"
                  :label="$t('label.accountUser')"
                  ref="accountUser"
                  :items="sapUsersFilterList"
                  item-text="userName"
                  return-object
                  @click.stop
                  @change="(v) => onFieldChange(v, 'userId', 'userId', 'userName')"
                  :menu-props="menuProps"
                  prepend-inner-icon="mdi-account"
                  class="text-caption"
                  hide-details
                  clearable
                  dense
                />
              </v-col>
              <v-col class="pb-0">
                <v-select
                  :value="filterSelection.saleChannelIn"
                  :label="$t('label.saleChannel')"
                  ref="saleChannel"
                  :items="saleChannelFilterList"
                  @change="(v) => onFieldChange(v, 'saleChannelIn', 'value', 'text')"
                  return-object
                  prepend-inner-icon="mdi-cube-send"
                  class="text-caption"
                  :menu-props="menuProps"
                  hide-details
                  clearable
                  dense
                />
              </v-col>
              <v-col class="pb-0">
                <v-select
                  :value="filterSelection.status"
                  :label="$t('label.status')"
                  ref="status"
                  :items="statusFilterList"
                  return-object
                  @change="(v) => onFieldChange(v, 'status', 'value', 'text')"
                  :menu-props="menuProps"
                  prepend-inner-icon="mdi-list-status"
                  class="text-caption"
                  hide-details
                  clearable
                  dense
                />
              </v-col>
              <v-col class="pb-0">
                <v-menu
                    v-model="deliveryDatePickerMenu"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :label="$t('label.deliveryDate')"
                        ref="deliveryDate"
                        :value="filterSelection.deliveryDate ? filterSelection.deliveryDate.text : ''"
                        v-bind="attrs"
                        v-on="on"
                        prepend-inner-icon="mdi-calendar"
                        hide-details
                        clearable
                        readonly
                        dense
                    />
                  </template>
                  <v-date-picker
                      :label="$t('label.deliveryDate')"
                      v-model="deliveryDate"
                      :text-field-props="{ prependInnerIcon: 'mdi-calendar' }"
                      @input="(v) => onFieldChange(v, 'deliveryDate', 'value', 'text')"
                      color="primary"
                  />
                </v-menu>
              </v-col>
            </v-row>

            <v-row class="ma-0 mb-1">
              <!-- <v-col class="pb-0">
                <v-menu
                  v-model="datePickerMenu"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :label="$t('label.createDate')"
                      ref="documentDate"
                      :value="filterSelection.documentDate ? filterSelection.documentDate.text : ''"
                      v-bind="attrs"
                      v-on="on"
                      prepend-inner-icon="mdi-calendar"
                      hide-details
                      clearable
                      readonly
                      dense
                    />
                  </template>
                  <v-date-picker
                    :label="$t('label.createDate')"
                    v-model="date"
                    :text-field-props="{ prependInnerIcon: 'mdi-calendar' }"
                    @input="(v) => onFieldChange(v, 'documentDate', 'value', 'text')"
                  />
                </v-menu>
              </v-col> -->
              <v-col class="pb-0">
                <v-autocomplete
                  :value="filterSelection.pol"
                  :label="$t('label.loadingNode')"
                  ref="pol"
                  :items="nodiFilterList"
                  :item-text="(nodo) => `${nodo.locode} ${nodo.countryName}`"
                  return-object
                  @click.stop
                  @change="(v) => onFieldChange(v, 'pol', 'locode', 'countryName')"
                  :menu-props="menuProps"
                  prepend-inner-icon="mdi-upload"
                  class="text-caption"
                  hide-details
                  clearable
                  dense
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.countryId }} -
                        {{ data.item.countryName }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <span>
                          {{ data.item.locode }}
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col class="pb-0">
                <v-autocomplete
                  :value="filterSelection.pod"
                  :label="$t('label.dischargeNode')"
                  ref="pod"
                  :items="nodiFilterList"
                  :item-text="(nodo) => `${nodo.locode} ${nodo.countryName}`"
                  return-object
                  @click.stop
                  @change="(v) => onFieldChange(v, 'pod', 'locode', 'countryName')"
                  :menu-props="menuProps"
                  prepend-inner-icon="mdi-download"
                  class="text-caption"
                  hide-details
                  clearable
                  dense
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.countryId }} -
                        {{ data.item.countryName }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <span>
                          {{ data.item.locode }}
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <!-- <v-col class="pb-0">
                <v-autocomplete
                  :value="filterSelection.incoterms"
                  :label="$t('label.incoterms')"
                  ref="incoterms"
                  :items="incotermsFilterList"
                  return-object
                  @click.stop
                  @change="(v) => onFieldChange(v, 'incoterms', 'text', 'value')"
                  :menu-props="menuProps"
                  prepend-inner-icon="mdi-file-document-outline"
                  class="text-caption"
                  hide-details
                  clearable
                  dense
                />
              </v-col> -->
              <v-col class="pb-0">
                <v-autocomplete
                  :value="filterSelection.shipperIncoterms"
                  :label="$t('label.shipperIncoterms')"
                  ref="incoterms"
                  :items="incotermsFilterList"
                  return-object
                  @click.stop
                  @change="(v) => onFieldChange(v, 'incoterms', 'text', 'value')"
                  :menu-props="menuProps"
                  prepend-inner-icon="mdi-file-document-outline"
                  class="text-caption"
                  hide-details
                  clearable
                  dense
                />
              </v-col>
              <v-col class="pb-0">
                <v-text-field
                  :value="filterSelection.saleOrder?.text"
                  :label="$t('label.saleOrder')"
                  ref="shipperInvoiceReference"
                  @input="(v) => onFieldChange(v?.toUpperCase() ?? v, 'saleOrder', 'text', 'value')"
                  class="to-upper-text"
                  prepend-inner-icon="mdi-file-sign"
                  hide-details
                  clearable
                  dense
                />
              </v-col>
              <v-col class="pb-0">
                <v-text-field
                  :value="filterSelection.equipment?.text"
                  :label="$t('label.equipment')"
                  ref="equipment"
                  @input="(v) => onFieldChange(v?.toUpperCase() ?? v, 'equipment', 'text', 'value')"
                  class="to-upper-text"
                  prepend-inner-icon="mdi-train-car-container"
                  hide-details
                  clearable
                  dense
                />
              </v-col>
            </v-row>


          </v-container>
        </v-carousel-item>
        <v-carousel-item class="pa-3 pb-0" :value="1">
          <v-data-table
            :items="savedFilters"
            :items-per-page="8"
            hide-default-header
            :hide-default-footer="savedFilters.length ? false : true"
            class="filters-table"
            dense
          >
            <template slot="no-data">
              <div class="no-data">
                <v-icon color="warning" class="mr-1" size="20">
                  mdi-alert
                </v-icon>
                <span class="font-weight-medium warning--text">
                  {{ $t('label.noAvailableData') }}
                </span>
              </div>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <span>{{ item.value.nameFilter }}</span>
                </td>
                <td class="px-1 d-flex justify-end">
                  <div class="actions">
                    <v-icon
                      color="green"
                      class="mr-3"
                      @click="uploadFilter(item)"
                    >
                      mdi-upload
                    </v-icon>
                    <v-icon color="red" @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-carousel-item>
      </v-carousel>
    </v-card-text>
    <!-- <v-divider class="ma-0 mt-0" /> -->
    <v-card-actions class="pa-2">
      <template class="d-inline-flex" style="width: 100%;" v-if="page == 1">
        <v-btn icon x-small color="primary" v-if="page == 1" @click="page = 0">
          <v-icon size="25">mdi-arrow-left-circle</v-icon>
        </v-btn>
      </template>
      <template class="d-inline-flex" style="width: 100%;" v-if="page == 0">
        <v-menu
          v-model="saveFilterMenu"
          top
          offset-y
          transition="scale-transition"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              class="secondary pa-1"
              v-on="on"
              @click="focusFilterName"
            >
              <v-icon x-small class="mr-1">mdi-filter-plus</v-icon>
              <span>{{ $t('upper.saveFilter') }}</span>
            </v-btn>
          </template>
          <v-list class="pa-0" width="16rem" v-click-outside="clearFilterName">
            <v-list-item class="py-1 px-2">
              <v-list-item-title>
                <v-text-field
                  v-model="filterName"
                  :label="$t('label.filterName')"
                  ref="filterName"
                />
              </v-list-item-title>
              <v-list-item-icon class="ml-1">
                <v-icon color="success" @click="saveFilter">
                  mdi-content-save
                </v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn x-small class="secondary pa-1 ml-2" @click="loadSavedFilters">
          <v-icon x-small class="mr-1">mdi-account-filter</v-icon>
          <span>{{ $t('upper.loadSavedFilters') }}</span>
        </v-btn>
        <v-spacer />
        <v-btn x-small class="secondary pa-1" @click="resetAllFilters">
          <v-icon x-small class="mr-1">mdi-filter-remove</v-icon>
          <span>{{ $t('upper.resetFilter') }}</span>
        </v-btn>
        <v-btn x-small class="secondary pa-1" @click="setFilter">
          <v-icon x-small class="mr-1">mdi-magnify</v-icon>
          <span>{{ $t('upper.search') }}</span>
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
import Filters from '@/mixins/Filters'

export default {
  name: 'FiltersCard',
  mixins: [Filters],
  created(){
    this.findAllNodi()
    this.findAllSapUsers()
  },
}
</script>

<style scoped>
.filters-table {
  width: 100%;
  height: 100%;
}
.filters-table::v-deep .v-data-footer {
  justify-content: right;
}
.filters-table::v-deep .v-data-footer__select {
  display: none;
}
.filters-table::v-deep .v-input--selection-controls__ripple {
  height: 0 !important;
}
.filters-table::v-deep tr {
  display: flex;
}
.filters-table::v-deep td {
  display: grid;
  align-items: center !important;
  padding: 0.3rem !important;
  width: -webkit-fill-available;
}
.actions {
  display: flex;
  align-content: center;
  max-width: max-content;
}
.no-data {
  justify-self: center !important;
}
.to-upper-text::v-deep input {
  text-transform: uppercase !important;
}
/*.row {
  border: solid 1px red;
}
.col {
  border: solid 1px orange;
}*/
</style>
