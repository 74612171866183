import FileSaver from 'file-saver';
import { mapState } from 'vuex'
import { mailService } from '@/services/mail.service.js'
import { foreignAgentContactService } from '@/services/foreignAgentContact.service.js'

export default {
  name: 'Email',
  data() {
    return {
      emailTemplate: {
        to: [],
        cc: [],
        bcc: [],
        subject: '',
        text: '',
        attachments: [],
      },
      newMessage: {},
      messages: [],
      showReplyMail: false,
      uploadedFiles: [],
      contactsColors: [],
      airEmailAddress: process.env.VUE_APP_APPLICANT_COMPANY_AIR_EMAIL,
      generalEmailAddress: process.env.VUE_APP_APPLICANT_COMPANY_EMAIL
    }
  },
  computed: {
    ...mapState('account', ['user']),
    userCompleteName() {
      let name =
        this.user.firstName.charAt(0).toUpperCase() +
        this.user.firstName.slice(1)
      let surname =
        this.user.lastName.charAt(0).toUpperCase() + this.user.lastName.slice(1)
      return name.concat(' ').concat(surname)
    },
  },
  watch: {
    emailTemplate: {
      handler(template) {
        this.newMessage = this._.cloneDeep(template)

        if (this.pratica) {
          // this.newMessage.from = this.pratica.saleChannel == 40 ? this.airEmailAddress : this.generalEmailAddress
          this.newMessage.from = this.generalEmailAddress
        }
      },
      deep: true,
    },
  },
  methods: {
    loadEmailTemplateTracking(link) {
      let fullLink = `${process.env.VUE_APP_BASE_URL}/tracking/${link.uuid}`
      this.emailTemplate = {}
      this.emailTemplate.to = []
      this.emailTemplate.cc = []
      this.emailTemplate.bcc = []
      this.emailTemplate.subject = `Italiansped tracking`
      this.emailTemplate.text = `Dear ,<br>Track your shipment<p><a href="${fullLink}">${fullLink}</a></p><br><br>Kind regards<p>${this.userCompleteName}</p>`
    },
    loadEmailTemplateDogana() {
      this.emailTemplate = {}
      this.emailTemplate.to = []
      if (this.customsOperatorContacts[0])
        this.emailTemplate.to.push(this.customsOperatorContacts[0].email)
      this.emailTemplate.cc = []
      this.emailTemplate.cc.push(this.user.email)
      this.emailTemplate.bcc = []
      this.emailTemplate.subject = `Pre Alert #DOGANA #${this.pratica.documentNbr}`
      this.emailTemplate.text = ''
    },
    loadEmailTemplateForeignAgent() {
      return foreignAgentContactService
        .getCorrispondenteEsteroDraftEmail(this.pratica.id)
        .then((resp) => {
          this.emailTemplate = resp
          this.emailTemplate.to = []
          this.emailTemplate.cc = []
          this.emailTemplate.bcc = []
        })
    },
    loadEmailTemplateTransport() {
      this.emailTemplate = {
        to: [],
        cc: [],
        bcc: [],
        subject: '',
        text: '',
        attachments: [],
      }
    },
    loadNewMailTracking(link) {
      this.setLoading(true)
      this.loadEmailTemplateTracking(link)
      this.handleDialog(true)
      this.setLoading(false)
    },
    loadNewEmailDogana() {
      this.setLoading(true)
      this.loadEmailTemplateDogana()
      this.handleDialog(true)
      this.setLoading(false)
    },
    loadNewEmailForeignAgent() {
      this.setLoading(true)
      this.loadEmailTemplateForeignAgent().then(() => {
        this.handleDialog(true)
        this.setLoading(false)
      })
    },
    loadNewEmailTransport() {
      this.setLoading(true)
      this.loadEmailTemplateTransport()
      this.handleDialog(true)
      this.setLoading(false)
    },
    handleEmailSent(resp) {
      this.newMessage.draft = false
      this.handleDialog(!resp.sent)
     
      if (this.$refs.mailPanel)
      this.$refs.mailPanel.forEach(ref => {
        ref.showReplyMail = false
      });

      setTimeout(() => {
        this.updateInbox()
          .then(() => this.browseMessages(this.emailTag))
          .then(() => {
            if (this.messages.length > 1) this.panel = 0
          })
      }, 1000)
    },
    handleDialog(open) {
      if (this.$refs.emailDialog) this.$refs.emailDialog.openDialog(open)
    },
    browseMessages(tag) {
      mailService
        .getConversation(tag, this.pratica.documentNbr)
        .then((resp) => {
          this.messages = resp
        })
    },
    createNewDraft(message) {
      let text = '<p/>'
      this.createReplyAll(message.messageId, text)
    },
    createReplyAll(messageId, text) {
      mailService.createReplyAll(messageId, text).then((resp) => {
        this.showReplyMail = true
        this.emailTemplate = resp
      })
    },
    updateMail() {
      let messageId = this.newMessage.messageId
      mailService.updateMail(messageId, this.newMessage).then((resp) => {
        this.sendReply(messageId)
      })
    },
    sendReply(messageId) {
      // this.snackbarOnResponse(mailService.send(messageId))
      mailService.send(messageId)
        .then(() => {
          this.setSnackbarMessage({ text: this.$t('label.emailSent'), color: 'success' })
          this.$emit('email-sent', { sent: true, msg: this.$t('label.emailSent') })
        })
        .catch((e) => {
          this.setSnackbarMessage({ text: e, color: 'error' })
          this.$emit('email-sent', { sent: false, msg: this.$t('label.error') })
        })
    },
    updateInbox() {
      return mailService.updateInbox()
    },
    deleteEmail(messageId) {
      mailService.deleteMail(messageId)
    },
    async sendEmail() {
      const checkFields = new Promise((resolve) => {
        setTimeout(() => {
          resolve(this.checkMessageFields())
        }, 200)
      })
      this.newMessage.attachments = await this.convertAttachments()
      checkFields.then((isOk) => {
        if (!isOk) return
        this.sendingEmail = true
        // this.snackbarOnResponse(mailService.sendMail(this.newMessage))
        mailService.sendMail(this.newMessage)
        .then(() => {
          this.setSnackbarMessage({ text: this.$t('label.emailSent'), color: 'success' })
          this.$emit('email-sent', { sent: true, msg: this.$t('label.emailSent') })
        })
        .catch((e) => {
          this.setSnackbarMessage({ text: e, color: 'error' })
          this.$emit('email-sent', { sent: false, msg: this.$t('label.error') })
        })
      })
    },
    downloadAttachment(value) {
      const blob = new Blob([Buffer.from(value.content, 'base64')], { type: value.contentType });
      FileSaver.saveAs(blob, value.filename);
    },
    convertAttachments() {
      let attach = []
      this.uploadedFiles.forEach((file) => {
        this.getBase64(file).then((data) => {
          let attachment = {
            filename: file.name,
            contentType: file.type,
            content: data,
          }
          attach.push(attachment)
        })
      })
      return attach
    },
    checkMessageFields() {
      if (this.newMessage.to.length == 0)
        return this.setSnackbarMessage({ text: `${this.$t('label.recipient')} ${this.$t('label.required')}`, color: 'warning' })
      if (!this.newMessage.subject)
        return this.setSnackbarMessage({ text: `${this.$t('label.subject')} ${this.$t('label.required')}`, color: 'warning' }) 
      return true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          const base64String = reader.result
            .replace('data:', '')
            .replace(/^.+,/, '')
          resolve(base64String)
        }
        reader.onerror = (error) => reject(error)
      })
    },
  },
  created() {
    this.newMessage = this._.cloneDeep(this.emailTemplate)
  },
}
