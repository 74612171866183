var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-center pa-0" },
    [
      _c("SortByMenuCustomsDashboard", { on: { "sort-invoices": _vm.sort } }),
      _c("FiltersDialogCustomsDashboard", {
        ref: "filtersDialog",
        on: { "set-filter": _vm.filter },
      }),
      _c(
        "v-flex",
        { staticClass: "d-flex align-center", attrs: { shrink: "" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "mx-1",
              attrs: {
                "x-small": "",
                outlined: "",
                depressed: "",
                color: "secondary",
              },
              on: { click: _vm.resetFilter },
            },
            [_vm._v(" " + _vm._s(_vm.$t("label.resetAllFilters")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }