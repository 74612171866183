var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "ma-0", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "pa-0" },
            [
              _c(
                "v-row",
                { staticClass: "ma-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-card",
                        { staticClass: "pa-2" },
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "d-inline-flex" },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "pa-0" },
                                    [
                                      _c("ReadonlyField", {
                                        attrs: {
                                          label: _vm.$t("label.airCompany"),
                                          value: `${
                                            _vm.pratica.airwayCompany
                                              ? `${_vm.pratica.airwayCompany.description} - ${_vm.pratica.airwayCompany.threeDigitCode}`
                                              : ""
                                          }`,
                                          icon: "mdi-airplane",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "d-inline-flex" },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "pa-0 pl-7" },
                                    [
                                      _c("ReadonlyField", {
                                        attrs: {
                                          label: _vm.$t("label.loadingNode"),
                                          value: _vm.pratica.pol
                                            ? _vm.pratica.pol.locode
                                            : "",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { staticClass: "pa-0 pl-7" },
                                    [
                                      _c("ReadonlyField", {
                                        attrs: {
                                          label: _vm.$t("label.dischargeNode"),
                                          value: _vm.pratica.pod
                                            ? _vm.pratica.pod.locode
                                            : "",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "d-inline-flex" },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "pa-0" },
                                    [
                                      _c("ReadonlyField", {
                                        attrs: {
                                          label: _vm.$t("label.awb"),
                                          value: _vm.pratica.awb,
                                          icon: "mdi-file-document-outline",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { staticClass: "pa-0" },
                                    [
                                      _c("ReadonlyField", {
                                        attrs: {
                                          label: _vm.$t("label.status"),
                                          value: _vm.trackResponse.status,
                                          icon: "mdi-list-status",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "d-inline-flex" },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "pa-0" },
                                    [
                                      _c("ReadonlyField", {
                                        attrs: {
                                          label: _vm.$t("label.etd"),
                                          value: _vm.etdDate,
                                          icon: "mdi-calendar",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { staticClass: "pa-0" },
                                    [
                                      _c("ReadonlyField", {
                                        attrs: {
                                          label: _vm.$t("label.eta"),
                                          value: _vm.etaDate,
                                          icon: "mdi-calendar",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "ma-0 mt-3" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-card",
                        [
                          _c("v-card-title", { staticClass: "py-1 px-3" }, [
                            _c(
                              "span",
                              { staticClass: "text-overline primary--text" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("upper.airportEvents")) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("v-divider", { staticClass: "my-0 mx-2" }),
                          _c(
                            "v-data-table",
                            {
                              staticClass: "px-2 py-0",
                              attrs: {
                                headers: _vm.airportEventsHeaders,
                                items: _vm.airportEvents,
                                "items-per-page": 1000,
                                "hide-default-footer": "",
                                dense: "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item.planned",
                                  fn: function ({ item }) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.formatDate(item.planned)) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.actual",
                                  fn: function ({ item }) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.formatDate(item.actual)) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.isReplanned",
                                  fn: function ({ item }) {
                                    return [
                                      item.isReplanned
                                        ? _c(
                                            "v-icon",
                                            { attrs: { color: "primary" } },
                                            [_vm._v(" mdi-check-bold ")]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c(
                                "template",
                                { slot: "no-data" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { color: "warning", size: "20" },
                                    },
                                    [_vm._v(" mdi-alert ")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-weight-medium warning--text",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("label.noTrackingInfo")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "ma-0 mt-3" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-card",
                        [
                          _c("v-card-title", { staticClass: "py-1 px-3" }, [
                            _c(
                              "span",
                              { staticClass: "text-overline primary--text" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("upper.routingEvents")) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("v-divider", { staticClass: "my-0 mx-2" }),
                          _c(
                            "v-data-table",
                            {
                              staticClass: "px-2 py-0",
                              attrs: {
                                headers: _vm.trackingHeadersAereo,
                                items: _vm.routingEvents,
                                "items-per-page": 1000,
                                "hide-default-footer": "",
                                "sort-by": "departureActualTime",
                                "sort-desc": false,
                                dense: "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item.departureScheduleTime",
                                  fn: function ({ item }) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatDate(
                                              item.departureScheduleTime
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.departureActualTime",
                                  fn: function ({ item }) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatDate(
                                              item.departureActualTime
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.arrivalScheduleTime",
                                  fn: function ({ item }) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatDate(
                                              item.arrivalScheduleTime
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.arrivalActualTime",
                                  fn: function ({ item }) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatDate(
                                              item.arrivalActualTime
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.isReplanned",
                                  fn: function ({ item }) {
                                    return [
                                      item.isReplanned
                                        ? _c(
                                            "v-icon",
                                            { attrs: { color: "primary" } },
                                            [_vm._v(" mdi-check-bold ")]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c(
                                "template",
                                { slot: "no-data" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { color: "warning", size: "20" },
                                    },
                                    [_vm._v(" mdi-alert ")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-weight-medium warning--text",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("label.noTrackingInfo")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "ma-0 mt-3" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-card",
                        [
                          _c("v-card-title", { staticClass: "py-1 px-3" }, [
                            _c(
                              "span",
                              { staticClass: "text-overline primary--text" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("upper.alertEvents")) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("v-divider", { staticClass: "my-0 mx-2" }),
                          _c(
                            "v-data-table",
                            {
                              staticClass: "px-2 py-0",
                              attrs: {
                                headers: _vm.alertEventsHeaders,
                                items: _vm.alertEvents,
                                "items-per-page": 1000,
                                "hide-default-footer": "",
                                "calculate-widths": "",
                                dense: "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item.programmato",
                                  fn: function ({ item }) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatDate(item.programmato)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.effettivo",
                                  fn: function ({ item }) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatDate(item.effettivo)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.isReplanned",
                                  fn: function ({ item }) {
                                    return [
                                      item.isReplanned
                                        ? _c(
                                            "v-icon",
                                            { attrs: { color: "primary" } },
                                            [_vm._v(" mdi-check-bold ")]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c(
                                "template",
                                { slot: "no-data" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { color: "warning", size: "20" },
                                    },
                                    [_vm._v(" mdi-alert ")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-weight-medium warning--text",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("label.noAlertEvents")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex flex-column ml-3 px-0",
              attrs: { cols: "auto" },
            },
            [
              _c("LinkPratica", {
                attrs: { documentNbr: _vm.pratica.documentNbr },
                on: { "send-link": _vm.loadNewMailTracking },
              }),
              _c("EmailDialog", {
                ref: "emailDialog",
                attrs: { msgTemplate: _vm.emailTemplate, emailAddresses: [] },
                on: { "email-sent": (resp) => _vm.handleDialog(!resp?.sent) },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }