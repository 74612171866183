<template>
  <v-container class="stepper-wrapper" fluid>
    <v-row dense>
      <v-col>
        <v-container class="d-table pa-1 pb-0" fill-height fluid style="table-layout: fixed">
          <v-row class="d-table-row" dense v-if="bookingCarrierReference">
            <v-col>
              <h6 class="booking-header mb-1">
                {{ bookingCarrierReference }}
              </h6>
            </v-col>
          </v-row>
          <v-row class="d-table-row fill-height" dense>
            <v-col class="fill-height overflow-y-auto" >
              <v-expansion-panels v-model="panel" multiple>
                <v-expansion-panel class="fit-content">
                  <v-expansion-panel-header class="primary--text px-3 py-0">
                    {{$t('label.info')}}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-divider class="my-0" />
                    <div class="pa-2">
                      <v-row dense>
                        <v-col class="d-flex">
                          <v-col class="pa-0">
                            <ReadonlyField
                              :label="$t('label.commodity')"
                              :value="savedCommodityLabel"
                              :icon="'mdi-package-variant-closed'"
                              :textClass="'text-caption pt-1'"
                            />
                          </v-col>
                        </v-col>
                        <v-col class="d-flex">
                          <v-col class="pa-0">
                            <ReadonlyField
                              :label="$t('label.userLabel')"
                              :value="`${savedForwarderParty.partyName} - ${formatContact(savedForwarderParty.partyContactDetails)}`"
                              :icon="'mdi-account'"
                              :textClass="'text-caption  pt-1'"
                            />
                          </v-col>
                          <v-col class="pa-0">
                            <ReadonlyField
                              :label="$t('label.priceOwner')"
                              :value="savedPriceOwner"
                              :icon="'mdi-account'"
                              :textClass="'text-caption  pt-1'"
                            />
                          </v-col>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col class="d-flex">
                          <v-col class="pa-0" v-if="company == 'MAERSK'">
                            <ReadonlyField
                              :label="$t('label.bookingOffice')"
                              :value="savedBookingOffice"
                              :icon="'mdi-warehouse'"
                              :textClass="'text-caption  pt-1'"
                            />
                          </v-col>
                        </v-col>
                        <v-col class="d-flex">
                          <v-col class="pa-0">
                            <ReadonlyField
                              :label="$t('label.serviceName')"
                              :value="savedServiceName"
                              :icon="'mdi-hand-extended-outline'"
                              :textClass="'text-caption  pt-1'"
                            />
                          </v-col>
                          <v-col class="pa-0">
                            <ReadonlyField
                              :label="$t('label.contract')"
                              :value="savedContract"
                              :icon="'mdi-file-document-outline'"
                              :textClass="'text-caption  pt-1'"
                            />
                          </v-col>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col class="d-flex" cols="6">
                          <v-col class="pa-0">
                            <ReadonlyField
                              :label="$t('label.bookingRemarks')"
                              :value="savedBookingRemarks"
                              :icon="'mdi-note-edit-outline'"
                              :textClass="'text-caption  pt-1'"
                            />
                          </v-col>
                        </v-col>
                        <v-col class="d-flex" cols="6">
                          <v-col class="pa-0">
                            <ReadonlyField
                              :label="$t('label.vgmFilingBy')"
                              :value="savedVgmFilingBy"
                              :icon="'mdi-scale-balance'"
                              :textClass="'text-caption  pt-1'"
                            />
                          </v-col>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="fit-content" v-if="savedPolHaulageLocation || savedPodHaulageLocation">
                <v-expansion-panel-header class="primary--text px-3 py-0">
                    {{$t('label.transport')}}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-divider class="my-0" />
                    <div class="d-flex pa-1">
                      <v-col class="pa-1">
                        <div v-if="savedPolHaulageLocation">
                          <v-row dense>
                            <v-col class="pa-0">
                              <v-subheader class="pa-2 pb-0 primary--text text-caption font-weight-bold">
                                {{ $t('label.placeOfReceipt') }}
                              </v-subheader>
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col class="d-flex">
                              <v-col class="pa-0">
                                <ReadonlyField
                                  :label="$t('label.address')"
                                  :value="`${formatAddress(savedPolHaulageLocation)} <br> ${savedPolHaulageCompany}`"
                                  :icon="'mdi-truck'"
                                  :textClass="'text-caption  pt-1'"
                                />
                              </v-col>
                              <v-col class="pa-0">
                                <ReadonlyField
                                  :label="$t('label.pickupContact')"
                                  :value="formatContact(savedPolHaulageContact)"
                                  :icon="'mdi-account'"
                                  :textClass="'text-caption  pt-1'"
                                />
                              </v-col>
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col class="d-flex">
                              <v-col class="pa-0">
                                <ReadonlyField
                                  :label="$t('label.pickupDateFrom')"
                                  :value="formatDate(savedExportRemarksPickupDateFrom)"
                                  :icon="'mdi-calendar'"
                                  :textClass="'text-caption  pt-1'"
                                />
                              </v-col>
                              <v-col class="pa-0">
                                <ReadonlyField
                                  :label="$t('label.pickupDateTo')"
                                  :value="formatDate(savedExportRemarksPickupDateTo)"
                                  :icon="'mdi-calendar'"
                                  :textClass="'text-caption  pt-1'"
                                />
                              </v-col>
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col class="d-flex">
                              <v-col class="pa-0">
                                <ReadonlyField
                                  :label="$t('label.placeOfReceiptRemarks')"
                                  :value="savedPolHaulageRemarks"
                                  :icon="'mdi-note-edit-outline'"
                                  :textClass="'text-caption  pt-1'"
                                />
                              </v-col>
                              <v-col class="pa-0">
                                <ReadonlyField
                                  :label="$t('label.loadRef')"
                                  :value="savedPolHaulageLoadRef"
                                  :icon="'mdi-file-document-outline'"
                                  :textClass="'text-caption  pt-1'"
                                />
                              </v-col>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                      <v-divider vertical class="ma-1" v-if="savedPolHaulageLocation && savedPodHaulageLocation" />
                      <v-col class="pa-1">
                        <div v-if="savedPodHaulageLocation">
                          <v-row dense>
                            <v-col class="pa-0">
                              <v-subheader class="pa-2 pb-0 primary--text text-caption font-weight-bold">
                                {{ $t('label.placeOfDelivery') }}
                              </v-subheader>
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col class="d-flex">
                              <v-col class="pa-0">
                                <ReadonlyField
                                  :label="$t('label.address')"
                                  :value="`${formatAddress(savedPodHaulageLocation)} <br> ${savedPodHaulageCompany}`"
                                  :icon="'mdi-truck'"
                                  :textClass="'text-caption  pt-1'"
                                />
                              </v-col>
                              <v-col class="pa-0">
                                <ReadonlyField
                                  :label="$t('label.deliveryContact')"
                                  :value="formatContact(savedPodHaulageContact)"
                                  :icon="'mdi-account'"
                                  :textClass="'text-caption  pt-1'"
                                />
                              </v-col>
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col class="d-flex">
                              <v-col class="pa-0">
                                <ReadonlyField
                                  :label="$t('label.deliveryDateFrom')"
                                  :value="formatDate(savedImportRemarksDeliveryDateFrom)"
                                  :icon="'mdi-calendar'"
                                  :textClass="'text-caption  pt-1'"
                                />
                              </v-col>
                              <v-col class="pa-0">
                                <ReadonlyField
                                  :label="$t('label.deliveryDateTo')"
                                  :value="formatDate(savedImportRemarksDeliveryDateTo)"
                                  :icon="'mdi-calendar'"
                                  :textClass="'text-caption  pt-1'"
                                />
                              </v-col>
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col class="d-flex">
                              <v-col class="pa-0">
                                <ReadonlyField
                                  :label="$t('label.placeOfDeliveryRemarks')"
                                  :value="savedPodHaulageRemarks"
                                  :icon="'mdi-note-edit-outline'"
                                  :textClass="'text-caption  pt-1'"
                                />
                              </v-col>
                              <v-col class="pa-0">
                                <ReadonlyField
                                  :label="$t('label.loadRef')"
                                  :value="savedPodHaulageLoadRef"
                                  :icon="'mdi-file-document-outline'"
                                  :textClass="'text-caption  pt-1'"
                                />
                              </v-col>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="fit-content">
                  <v-expansion-panel-header class="primary--text px-3 py-0">
                    {{$t('label.voyage')}}
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <v-divider class="my-0" />
                    <div class="pa-2">
                      <v-row dense>
                        <v-col class="d-flex">
                          <v-col class="pa-0">
                            <ReadonlyField
                              :label="$t('label.pol')"
                              :value="getFacilityLocationLabel(pol)"
                              :icon="'mdi-map-marker'"
                              :textClass="'text-caption  pt-1'"
                            />
                          </v-col>
                          <v-col class="pa-0">
                            <ReadonlyField
                              :label="$t('label.pod')"
                              :value="getFacilityLocationLabel(pod)"
                              :icon="'mdi-map-marker'"
                              :textClass="'text-caption  pt-1'"
                            />
                          </v-col>
                        </v-col>
                        <v-col class="d-flex" />
                      </v-row>
                      <v-row dense>
                        <v-col class="d-flex">
                          <v-col class="pa-0">
                            <ReadonlyField
                              :label="$t('label.departure')"
                              :value="savedDepartureDate"
                              :icon="'mdi-calendar'"
                              :textClass="'text-caption  pt-1'"
                            />
                          </v-col>
                          <v-col class="pa-0">
                            <ReadonlyField
                              :label="$t('label.arrival')"
                              :value="savedArrivalDate"
                              :icon="'mdi-calendar'"
                              :textClass="'text-caption  pt-1'"
                            />
                          </v-col>
                        </v-col>
                        <v-col class="d-flex">
                          <v-col class="pa-0">
                            <ReadonlyField
                              :label="$t('label.vessel')"
                              :value="savedMainVessel"
                              :icon="'mdi-ferry'"
                              :textClass="'text-caption  pt-1'"
                            />
                          </v-col>
                          <v-col class="pa-0">
                            <ReadonlyField
                              :label="$t('label.voyageNbr')"
                              :value="savedVoyageNbr"
                              :icon="'mdi-ticket-outline'"
                              :textClass="'text-caption  pt-1'"
                            />
                          </v-col>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="fit-content">
                  <v-expansion-panel-header class="primary--text px-3 py-0">
                    {{$t('label.equipments')}}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-divider class="my-0" />
                    <div class="pa-2">
                      <v-row dense>
                        <v-col class="px-2 py-0">
                          <v-data-table
                            :headers="equipmentsHeaders"
                            :items="savedEquipments"
                            :items-per-page="1000"
                            hide-default-footer
                            class="pa-0"
                            dense
                          >
                            <template v-slot:item="{ item, index }">
                              <tr>
                                <td>
                                  <v-icon class="mr-1" small>
                                    mdi-train-car-container
                                  </v-icon>
                                  <span class="text-caption">
                                    {{ item.code }}
                                  </span>
                                </td>
                                <td>
                                  <span class="text-caption">
                                    {{
                                      item.requestedEquipments.ISOEquipmentCode
                                    }}
                                  </span>
                                </td>
                                <td>
                                  <span class="text-caption">
                                    {{ item.requestedEquipments.units }}
                                  </span>
                                </td>
                                <td>
                                  <span class="text-caption">
                                    {{ item.requestedEquipments.equipmentWeight }}
                                  </span>
                                </td>
                                <td>
                                  <span class="text-caption">
                                    {{ item.requestedEquipments.equipmentVolume }}
                                  </span>
                                </td>
                              </tr>
                              <tr v-if="index == savedEquipments.length - 1">
                                <td>
                                  <span class="text-caption font-weight-medium">
                                    {{ $t('label.total') }}
                                  </span>
                                </td>
                                <td></td>
                                <td></td>
                                <td>
                                  <span class="text-caption font-weight-medium">
                                    {{ totEquipmentsWeight }}
                                  </span>
                                </td>
                                <td v-if="totEquipmentsVolume">
                                  <span class="text-caption font-weight-medium">
                                    {{ totEquipmentsVolume }}
                                  </span>
                                </td>
                              </tr>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row class="flex-grow-0" dense>
      <v-col class="d-flex pa-3">
        <v-btn small icon color="primary" @click="exitRequest">
          <v-icon size="30">mdi-arrow-left-circle</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="editableSelectedCommodity"
          @click="$emit('step', false)"
          color="primary"
          small
        >
          <v-icon>mdi-step-backward</v-icon>
          <span>{{ $t('label.back') }}</span>
        </v-btn>
        <v-btn
          small
          color="primary"
          class="ml-2"
          v-if="editableSelectedCommodity"
          @click="submitBookingRequest"
        >
          <span>{{ $t('label.confirm') }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Booking from '@/mixins/Booking.js'
import { electronicFlowService } from '@/services/electronicFlow.service.js'
import ReadonlyField from '@/components/elements/ReadonlyField.vue'

export default {
  name: 'BookingReview',
  mixins: [Booking],
  components: { ReadonlyField },
  data() {
    return {
      panel: [0, 1, 2, 3],
      savedCommodityLabel: null,
      pol: null,
      pod: null,
      equipmentsHeaders: [
        {
          text: this.$t('label.containerType'),
          value: 'code',
          class: 'primary--text px-3 py-0 font-weight-normal',
        },
        {
          text: this.$t('label.isoCode'),
          value: 'requestedEquipments.ISOEquipmentCode',
          class: 'primary--text px-3 py-0 font-weight-normal',
        },
        {
          text: this.$t('label.quantity'),
          value: 'requestedEquipments.units',
          class: 'primary--text px-3 py-0 font-weight-normal',
        },
        {
          text: this.$t('label.weight'),
          value: 'requestedEquipments.equipmentWeight',
          class: 'primary--text px-3 py-0 font-weight-normal',
        },
        {
          text: this.$t('label.volume'),
          value: 'requestedEquipments.equipmentVolume',
          class: 'primary--text px-3 py-0 font-weight-normal',
        },
      ],
      voyageHeaders: [
        {
          text: this.$t('label.vessel'),
          value: 'vessel',
          class: 'primary--text px-3 py-0 font-weight-normal',
        },
        {
          text: this.$t('label.voyageNbr'),
          value: 'voyageNbr',
          class: 'primary--text px-3 py-0 font-weight-normal',
        },
        {
          text: this.$t('label.leg'),
          value: 'leg',
          class: 'primary--text px-3 py-0 font-weight-normal',
        },
      ],
    }
  },
  computed: {
    totEquipmentsWeight() {
      let totWeight = 0
      this.savedEquipments.forEach((equipment) => {
        totWeight += equipment.requestedEquipments.equipmentWeight
      })
      return totWeight
    },
    totEquipmentsVolume() {
      let totVolume = 0
      this.savedEquipments.forEach((equipment) => {
        if (equipment.requestedEquipments.equipmentVolume)
          totVolume += equipment.requestedEquipments.equipmentVolume
      })
      return totVolume
    },
  },
  methods: {
    init() {
      this.setCommodityLabel()
      this.getPolAndPodForHaulageVoyage()
    },
    getTransportIcon(transport) {
      switch (transport.mode) {
        case 'Vessel':
        case 'MVS':
          return 'mdi-ferry'
        case 'Rail':
        case 'RR':
          return 'mdi-train'
        case 'Road':
        case 'TRK':
          return 'mdi-truck'
      }
      return 'mdi-train-car'
    },
    setCommodityLabel() {
      if (!this.savedCommodity) return
      this.savedCommodityLabel = this.savedCommodity.commodityType
    },
    getPolAndPodForHaulageVoyage() {
      switch (this.company) {
        case 'MAERSK':
          this.getMaerskPolAndPolHaulaged()
          break
        case 'CMACGM':
          this.pol = this.savedPol
          this.pod = this.savedPod
          break
        case 'YANGMING':
          this.pol = this.savedPol.locationCode
          this.pod = this.savedPod.locationCode
          break
      }
    },
    getMaerskPolAndPolHaulaged() {
      let shippingLegs = []
      this.savedVoyage.routeLegs.forEach((leg) => {
        if (leg.transport.vessel.vesselName) shippingLegs.push(leg)
      })
      shippingLegs = this.sortByDepartureDateAsc(shippingLegs)
      switch (shippingLegs.length) {
        case 1:
          this.pol = shippingLegs[0].pointFrom
          this.pod = shippingLegs[0].pointTo
          break
        default:
          this.pol = shippingLegs[0].pointFrom
          this.pod = shippingLegs[shippingLegs.length - 1].pointTo
          break
      }
    },
    // Save ElectronicFlow Request
    submitBookingRequest() {
      let msg = !this.electronicFlow.bookingRequest.uuid ? this.$t('message.bookingSubmitted')  : this.$t('message.bookingUpdateSubmitted')
      return electronicFlowService
        .submitBookingRequest(this.electronicFlow.id, this.bookingRequest)
        .then(() => {
          this.setSnackbarMessage({
            text: msg,
            color: 'success',
          })
          this.findElectronicFlowById(this.electronicFlow.id)
        })
        .catch((error) => {
          this.setSnackbarMessage({ text: error, color: 'error' })
        })
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>
.stepper-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
}
.v-data-table::v-deep th {
  height: 1.5rem !important;
  padding-left: 0 !important;
}
.v-data-table::v-deep td {
  height: 1rem !important;
  padding-left: 0 !important;
}
.v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 8px;
}
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 48px !important;
}
.v-expansion-panel::v-deep .v-expansion-panel-content__wrap {
  padding: 0;
}
.booking-header {
  background-color: var(--v-grey-lighten3);
  color: var(--v-grey-base);
  padding: 0.5rem;
}
.v-subheader {
  height: 1rem;
}
/*.row {
  border: solid 1px red;
}
.col {
  border: solid 1px orange;
}*/
</style>
