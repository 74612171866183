<template>
  <v-footer color="primary text-white" app height="50">
    <v-row>
      <v-col cols="6"><span><v-img src="@/assets/logo-cirle-white.png" max-width="100" contain></v-img></span></v-col>
      <v-col cols="6" class="text-right text-caption">2023 - <span class="font-weight-bold">MILOS&reg; Tracking Federative Dashboard</span></v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "DashboardFooter"
}
</script>

<style scoped>
.v-footer {
  z-index: 10;
}
</style>