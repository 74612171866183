var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "d-block pa-0", attrs: { "fill-height": "", fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "6" } },
            [
              _c("InvoiceFilter", {
                ref: "invoicesFilterSection",
                on: { "set-filter": _vm.filter },
              }),
              _c("v-data-table", {
                staticClass: "cards-table",
                attrs: {
                  headers: [],
                  items: _vm.invoiceSearch.browseResponse.resultList,
                  "items-per-page": _vm.invoiceSearch.itemsPerPage,
                  "hide-default-footer": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function ({ item }) {
                      return [
                        _c("InvoicePreview", {
                          staticClass: "invoice",
                          attrs: { invoice: item },
                          on: { "invoice-document-nbr": _vm.selectInvoice },
                        }),
                      ]
                    },
                  },
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "v-row",
                          { staticClass: "ma-0", attrs: { dense: "" } },
                          [
                            _c(
                              "v-col",
                              { staticClass: "pa-0" },
                              [
                                _c("TableNav", {
                                  ref: "tableNavInvoices",
                                  attrs: {
                                    "browse-count":
                                      _vm.invoiceSearch.browseResponse
                                        .totalCount,
                                    "browse-limit":
                                      _vm.invoiceSearch.browseRequest.limit,
                                    "browse-offset":
                                      _vm.invoiceSearch.browseRequest.offset,
                                  },
                                  on: {
                                    "offset-changed":
                                      _vm.browseRequestOffsetChangedInvoiceSearch,
                                    "get-current-page":
                                      _vm.setCurrentPageInvoiceSearch,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "6" } },
            [
              _c("PraticheFilter", {
                ref: "praticheFilterSection",
                on: { "set-filter": _vm.filter },
              }),
              _c("v-data-table", {
                staticClass: "cards-table",
                attrs: {
                  headers: [],
                  items: _vm.praticheSearch.browseResponse.resultList,
                  "items-per-page": _vm.praticheSearch.itemsPerPage,
                  "hide-default-footer": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function ({ item }) {
                      return [
                        _c("PratichePreview", {
                          attrs: {
                            pratiche: item,
                            "selected-invoice-document-nbr":
                              _vm.invoiceSearch.selectedInvoiceDocumentNbr,
                          },
                          on: { "document-nbr": _vm.matchPraticaWithInvoice },
                        }),
                      ]
                    },
                  },
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "v-row",
                          { staticClass: "ma-0", attrs: { dense: "" } },
                          [
                            _c(
                              "v-col",
                              { staticClass: "pa-0" },
                              [
                                _c("TableNav", {
                                  ref: "tableNavPratiche",
                                  attrs: {
                                    "browse-count":
                                      _vm.praticheSearch.browseResponse
                                        .totalCount,
                                    "browse-limit":
                                      _vm.praticheSearch.browseRequest.limit,
                                    "browse-offset":
                                      _vm.praticheSearch.browseRequest.offset,
                                  },
                                  on: {
                                    "offset-changed":
                                      _vm.browseRequestOffsetChangedPraticheSearch,
                                    "get-current-page":
                                      _vm.setCurrentPagePraticheSearch,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "2000", color: _vm.color },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function ({ attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { icon: "" },
                        on: {
                          click: function ($event) {
                            _vm.snackbar = false
                          },
                        },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [
                      _c("v-icon", { attrs: { color: "white" } }, [
                        _vm._v(" mdi-close "),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _c("v-icon", { staticClass: "mr-2", attrs: { color: "white" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.isSuccess ? "mdi-check-circle" : "mdi-alert-circle"
                ) +
                " "
            ),
          ]),
          _c("span", { staticClass: "white--text" }, [
            _vm._v(_vm._s(_vm.message)),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }