<template>
  <v-container fill-height fluid class="d-flex justify-center">
    <v-btn class="primary pa-1" @click="openTabWithPostParams">
      <span>{{ $t("label.masterSpedLight") }}</span>
    </v-btn>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "CustomsOperative",
  data() {
    return {};
  },
  computed: {
    ...mapState("account", ["user"]),
  },
  methods: {
    openTabWithPostParams() {
      const link =
        "https://spedsecure.progettoadele.org/italiansped/PageAS.aspx";

      const params = [
        { name: "UserMail", value: "stefan.abageru@external.sacmigroup.com" },
        // { name: "UserMail", value: this.user.email },
        { name: "Company", value: "IT" },
        { name: "Language", value: "it-IT" },
      ];

      const form = document.createElement("form");
      form.method = "post";
      form.action = link;
      form.target = "_blank";

      for (let i = 0; i < params.length; i++) {
        let input = document.createElement("input");
        input.type = "hidden";
        input.name = params[i].name;
        input.value = params[i].value;
        form.appendChild(input);
      }

      document.body.appendChild(form);
      form.submit();
    },
  },
};
</script>
