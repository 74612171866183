<template>
  <v-flex class="d-flex align-center mx-1" shrink>
    <v-tooltip bottom>
      <template #activator="{ on: on }">
        <v-btn x-small class="secondary" @click="dialog = true" v-on="on">
          <v-icon size="20">mdi-tune</v-icon>
        </v-btn>
      </template>
      <span class="text-caption">{{ $t("label.filters") }}</span>
    </v-tooltip>
    <v-dialog v-model="dialog" width="60vw">
      <v-card>
        <v-card-title class="pa-3">
          <v-btn icon small color="primary" v-if="page == 1" @click="page = 0">
            <v-icon size="35">mdi-arrow-left-circle</v-icon>
          </v-btn>
          <v-spacer v-if="page == 1" />
          <v-btn small class="warning pa-1" @click="loadSavedFilters" v-else>
            <v-icon class="mr-1">mdi-account-filter</v-icon>
            <span>{{ $t("upper.loadSavedFilters") }}</span>
          </v-btn>
        </v-card-title>

        <v-divider class="ma-0 mb-0" />
        <v-card-text class="pa-0">
          <v-carousel
            v-model="page"
            hide-delimiters
            :show-arrows="false"
            width="100%"
            height="100%"
            touchless
          >
            <v-carousel-item class="pa-3" :value="0">
              <v-container class="pa-0" fill-height fluid style="display: grid">
                <v-row class="ma-0">
                  <v-col>
                    <v-text-field
                      :value="
                        filterSelection.invoiceNbr
                          ? filterSelection.invoiceNbr.text
                          : ''
                      "
                      :label="$t('label.invoiceNbr')"
                      ref="invoiceNbr"
                      @change="
                        (v) => onFieldChange(v, 'invoiceNbr', 'text', 'value')
                      "
                      :menu-props="menuProps"
                      prepend-inner-icon="mdi-file-document-outline"
                      hide-details
                      clearable
                      dense
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      :value="
                        filterSelection.sellerName
                          ? filterSelection.sellerName.text
                          : ''
                      "
                      :label="$t('label.sellerName')"
                      ref="sellerName"
                      @change="
                        (v) => onFieldChange(v, 'sellerName', 'text', 'value')
                      "
                      :menu-props="menuProps"
                      prepend-inner-icon="mdi-file-document-outline"
                      hide-details
                      clearable
                      dense
                    />
                  </v-col>
                </v-row>
                <v-row class="ma-0">
                  <v-col>
                    <v-text-field
                      :value="
                        filterSelection.invoiceHolderName
                          ? filterSelection.invoiceHolderName.text
                          : ''
                      "
                      :label="$t('label.invoiceHolderName')"
                      ref="invoiceHolderName"
                      @change="
                        (v) =>
                          onFieldChange(v, 'invoiceHolderName', 'text', 'value')
                      "
                      :menu-props="menuProps"
                      prepend-inner-icon="mdi-file-document-outline"
                      hide-details
                      clearable
                      dense
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      :value="
                        filterSelection.consigneeName
                          ? filterSelection.consigneeName.text
                          : ''
                      "
                      :label="$t('label.consigneeName')"
                      ref="consigneeName"
                      @change="
                        (v) =>
                          onFieldChange(v, 'consigneeName', 'text', 'value')
                      "
                      :menu-props="menuProps"
                      prepend-inner-icon="mdi-file-document-outline"
                      hide-details
                      clearable
                      dense
                    />
                  </v-col>
                </v-row>
                <v-row class="ma-0">
                  <v-col>
                    <v-text-field
                      :value="
                        filterSelection.saleOrderReference
                          ? filterSelection.saleOrderReference.text
                          : ''
                      "
                      :label="$t('label.saleOrderReference')"
                      ref="saleOrderReference"
                      @change="
                        (v) =>
                          onFieldChange(
                            v,
                            'saleOrderReference',
                            'text',
                            'value'
                          )
                      "
                      :menu-props="menuProps"
                      prepend-inner-icon="mdi-file-document-outline"
                      hide-details
                      clearable
                      dense
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      :value="
                        filterSelection.shipmentNbr
                          ? filterSelection.shipmentNbr.text
                          : ''
                      "
                      :label="$t('label.shipmentNbr')"
                      ref="shipmentDocumentNbrLIKE"
                      @change="
                        (v) =>
                          onFieldChange(
                            v,
                            'shipmentDocumentNbrLIKE',
                            'text',
                            'value'
                          )
                      "
                      :menu-props="menuProps"
                      prepend-inner-icon="mdi-file-document-outline"
                      hide-details
                      clearable
                      dense
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-carousel-item>
            <v-carousel-item class="pa-3 pb-0" :value="1">
              <v-data-table
                :items="savedFilters"
                :items-per-page="8"
                hide-default-header
                :hide-default-footer="savedFilters.length ? false : true"
                class="filters-table"
                dense
              >
                <template slot="no-data">
                  <v-icon color="warning" class="mr-1" size="20">
                    mdi-alert
                  </v-icon>
                  <span class="font-weight-medium warning--text">
                    {{ $t("label.noAvailableData") }}
                  </span>
                </template>
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      <span>{{ item.value.nameFilter }}</span>
                    </td>
                    <td class="px-1 d-flex justify-end">
                      <div class="actions">
                        <v-icon
                          color="green"
                          class="mr-3"
                          @click="uploadFilter(item)"
                        >
                          mdi-upload
                        </v-icon>
                        <v-icon color="red" @click="deleteItem(item)">
                          mdi-delete
                        </v-icon>
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-carousel-item>
          </v-carousel>
        </v-card-text>

        <v-divider class="ma-0 mt-0" />
        <v-card-actions class="pa-3" style="height: 3.25rem">
          <template v-if="page === 0">
            <v-menu
              top
              offset-y
              transition="scale-transition"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  small
                  class="primary pa-1"
                  v-on="on"
                  @click="focusFilterName"
                >
                  <v-icon class="mr-1">mdi-filter-plus</v-icon>
                  <span>{{ $t("upper.saveFilter") }}</span>
                </v-btn>
              </template>
              <v-list
                class="pa-0"
                width="16rem"
                v-click-outside="clearFilterName"
              >
                <v-list-item class="py-1 px-2">
                  <v-list-item-title>
                    <v-text-field
                      v-model="filterName"
                      :label="$t('label.filterName')"
                      ref="filterName"
                    />
                  </v-list-item-title>
                  <v-list-item-icon class="ml-1">
                    <v-icon color="success" @click="saveFilter">
                      mdi-content-save
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn small class="primary pa-1 ml-3" @click="resetAllFilters">
              <v-icon class="mr-1">mdi-filter-remove</v-icon>
              <span>{{ $t("upper.resetFilter") }}</span>
            </v-btn>
            <v-spacer />
            <v-btn small class="secondary pa-1" @click="setFilter">
              <v-icon class="mr-1">mdi-content-save</v-icon>
              <span>{{ $t("upper.search") }}</span>
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-flex>
</template>

<script>
import { mapState } from "vuex";
import { userParametersService } from "../../../services/userParameters.service";

export default {
  data() {
    return {
      dialog: false,

      page: 0,

      filterSelection: {},
      filterName: "",

      savedFilters: [],

      browseFilter: {},
      visualFilter: {},

      menuProps: {
        offsetY: true,
        overflowY: "auto",
        maxHeight: "10rem",
      },
    };
  },
  computed: {
    ...mapState("account", [
      "user",
      "userParameters",
      "internalVisibilityAuth",
    ]),
  },
  methods: {
    // find all sellers
    init() {},

    // load saved filters
    loadSavedFilters() {
      this.findAllSavedFilters().then(() => {
        this.page = 1;
      });
    },
    findAllSavedFilters() {
      return userParametersService
        .getUserParameter(this.user.id, "FILTER-INVOICE")
        .then((resp) => {
          this.savedFilters = [];
          resp.forEach((filter) => {
            filter.value = JSON.parse(filter.value);
            this.savedFilters.push(filter);
          });
        });
    },
    uploadFilter(item) {
      let convertedFilters = this.convertForUpload(item.value);
      this.filterSelection = item.value;
      this.browseFilter = convertedFilters.browse;
      this.visualFilter = convertedFilters.visual;
      this.setFilter();
    },
    convertForUpload(filter) {
      let convertedFilters = {
        browse: {},
        visual: {},
      };

      Object.keys(filter).forEach((key) => {
        if (key == "nameFilter") return;
        let field = filter[key];
        convertedFilters.browse[key] = [];
        convertedFilters.visual[key] = [];
        switch (true) {
          case Array.isArray(field): // field is []
            field.forEach((element) => {
              convertedFilters.visual[key].push(element.text);
              convertedFilters.browse[key].push(element.value);
            });
            break;
          case Array.isArray(field.text): // field is {text: [] value: []}
            field.text.forEach((element) =>
              convertedFilters.visual[key].push(element)
            );
            field.value.forEach((element) =>
              convertedFilters.browse[key].push(element)
            );
            break;
          default:
            // field is single value
            convertedFilters.visual[key].push(field[Object.keys(field)[0]]);
            convertedFilters.browse[key].push(field[Object.keys(field)[1]]);
            break;
        }
      });
      return convertedFilters;
    },
    deleteItem(item) {
      const index = this.savedFilters.indexOf(item);
      this.savedFilters.splice(index, 1);
      userParametersService.deleteUserParameter(item.id);
    },

    // save filter
    focusFilterName() {
      setTimeout(() => {
        this.$refs.filterName.focus();
      }, 100);
    },
    saveFilter() {
      this.filterSelection.nameFilter = this.filterName;
      let filter = {
        id: null,
        userId: this.user.id,
        key: "FILTER-INVOICE",
        valueType: "JSON",
        value: JSON.stringify(this.filterSelection),
      };
      userParametersService.createUserParameter(filter).then((res) => {
        this.page = 0;
        this.dialog = false;
      });
    },
    clearFilterName() {
      this.filterName = "";
    },

    // clear filter
    resetAllFilters() {
      this.resetFilters();
      this.setFilter();
    },
    resetFilters() {
      this.resetInputs();
      this.filterSelection = {};
      this.browseFilter = {};
      this.visualFilter = {};
    },
    resetInputs() {
      if (this.isEmpty(this.$refs)) return;
      this.$refs.invoiceNbr.reset();
      this.$refs.sellerName.reset();
      this.$refs.invoiceHolderName.reset();
      this.$refs.consigneeName.reset();
      this.$refs.saleOrderReference.reset();
      this.$refs.shipmentDocumentNbrLIKE.reset();
    },
    isEmpty(object) {
      return Object.keys(object).length === 0;
    },

    // find
    setFilter() {
      let filterMemory = {
        currentFilter: this.filterSelection,
        browseFilter: this.browseFilter,
        visualFilter: this.visualFilter,
      };
      this.setFilterMemory(this.$options.name, filterMemory);
      this.$emit(
        "set-filter",
        this.convertFilter(this.browseFilter),
        this.convertFilter(this.visualFilter)
      );
      this.dialog = false;
    },
    convertFilter(filter) {
      let convertedFilter = {};
      Object.keys(filter).forEach((key) => {
        if (Array.isArray(filter[key]))
          convertedFilter[key] = this.convertArrayToStringSplitted(filter[key]);
      });
      return convertedFilter;
    },
    convertArrayToStringSplitted(list) {
      let arrayToStringSplitted = "";
      list.forEach((element) => {
        let isLastElement = list.indexOf(element) == list.length - 1;
        arrayToStringSplitted += element += !isLastElement ? ", " : "";
      });
      return arrayToStringSplitted;
    },

    onFieldChange(input, field, value, text) {
      if (!input) {
        delete this.filterSelection[field];
        delete this.browseFilter[field];
        delete this.visualFilter[field];
        return;
      }
      this.filterSelection[field] = {};
      this.browseFilter[field] = [];
      this.visualFilter[field] = [];
      switch (true) {
        case Array.isArray(input[text]): // input is text: [] value: []
          this.filterSelection[field].text = input[text];
          this.filterSelection[field].value = input[value];
          if (!this.filterSelection[field].value.length)
            delete this.filterSelection[field];

          this.browseFilter[field] = input[value];
          if (!this.browseFilter[field].length) delete this.browseFilter[field];

          this.visualFilter[field] = input[text];
          if (!this.visualFilter[field].length) delete this.visualFilter[field];

          break;
        case Array.isArray(input): // input is []
          this.filterSelection[field] = [];
          if (!input.length) {
            delete this.browseFilter[field];
            delete this.visualFilter[field];
          }
          input.forEach((element) => {
            let item = {
              text: element[text],
              value: element[value],
            };
            this.filterSelection[field].push(item);
            this.browseFilter[field].push(element[value]);
            this.visualFilter[field].push(element[text]);
          });
          break;
        case typeof input === "string": // input is String
          this.filterSelection[field][text] = input;
          this.filterSelection[field][value] = input;
          this.browseFilter[field].push(input);
          this.visualFilter[field].push(input);
          break;
        default:
          // input is single value
          this.filterSelection[field][text] = input[text];
          this.filterSelection[field][value] = input[value];
          this.browseFilter[field].push(input[value]);
          this.visualFilter[field].push(input[text]);
          break;
      }
    },
  },
  watch: {
    dialog(isOpen) {
      if (isOpen) {
        let filterMemory = this.getFilterMemory(this.$options.name);
        if (!filterMemory) return;
        if (filterMemory.currentFilter)
          this.filterSelection = filterMemory.currentFilter;
        if (filterMemory.browseFilter)
          this.browseFilter = filterMemory.browseFilter;
        if (filterMemory.visualFilter)
          this.visualFilter = filterMemory.visualFilter;
      } else this.resetFilters();
    },
  },
  emits: ["set-filter"],
  name: "FiltersDialogCustomsDashboard",
  mounted() {
    this.init();
  },
};
</script>

<style>
.filters-table {
  width: 100%;
  height: 100%;
}
.filters-table::v-deep .v-data-footer {
  justify-content: right;
}
.filters-table::v-deep .v-data-footer__select {
  display: none;
}
.filters-table::v-deep .v-input--selection-controls__ripple {
  height: 0 !important;
}
.filters-table::v-deep tr {
  display: flex;
}
.filters-table::v-deep td {
  display: flex;
  align-items: center !important;
  padding: 0.3rem !important;
  width: -webkit-fill-available;
}
.actions {
  display: flex;
  align-content: center;
  max-width: max-content;
}
</style>
