var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "my-1", attrs: { outlined: "" } },
    [
      _c(
        "v-row",
        { staticClass: "ma-0", attrs: { justify: "space-around", dense: "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-center align-center pa-0",
              attrs: { cols: "1" },
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "d-flex justify-center align-center pa-0",
                  attrs: {
                    justify: "space-around",
                    "no-gutters": "",
                    dense: "",
                  },
                },
                [
                  _c("v-checkbox", {
                    staticClass: "checkbox ma-0 pa-0",
                    attrs: { color: "primary", "hide-details": "" },
                    on: { change: _vm.modifyRowSelection },
                    model: {
                      value: _vm.selected,
                      callback: function ($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-col",
            { staticClass: "d-flex justify-center align-center pa-1" },
            [
              _c(
                "v-row",
                { staticClass: "ma-0", attrs: { dense: "" } },
                [
                  _c("ReadonlyField", {
                    attrs: {
                      label: _vm.$t("label.invoiceNbr"),
                      value: _vm.relationship.shipmentLink.invoiceNbr,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-col",
            { staticClass: "d-flex justify-center align-center pa-1" },
            [
              _c(
                "v-row",
                { staticClass: "ma-0", attrs: { dense: "" } },
                [
                  _c("ReadonlyField", {
                    attrs: {
                      label: _vm.$t("label.documentNbr"),
                      value: _vm.relationship.shipmentLink.documentNbr,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-col",
            { staticClass: "d-flex justify-center align-center pa-1" },
            [
              _c(
                "v-row",
                { staticClass: "ma-0", attrs: { dense: "" } },
                [
                  _c("ReadonlyField", {
                    attrs: {
                      label: _vm.$t("label.shipmentNbr"),
                      value: _vm.relationship.shipmentLink.shipmentDocumentNbr,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-col",
            { staticClass: "d-flex justify-center align-center pa-1" },
            [
              _c(
                "v-row",
                { staticClass: "ma-0", attrs: { dense: "" } },
                [
                  _c("ReadonlyField", {
                    attrs: {
                      label: "Description message",
                      value: _vm.relationship.description,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }