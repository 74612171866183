import { authHeader } from '@/helpers/auth-header'
import { commons } from './commons'

export const emailAddressesService = {
  getByAddressId,
  getDefaultEmail
}

const resourceUrl = process.env.VUE_APP_GSCV_ENGINE_URL + `/email-address`

function getByAddressId(addressId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/address-id/${addressId}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function getDefaultEmail(addressId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/default/${addressId}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}
