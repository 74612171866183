<template>
  <v-container class="pa-0" fill-height fluid>
    <v-card
      v-if="!showConversation"
      @click="showConversation = true"
      width="100%"
      class="pa-3"
    >
      <v-row class="ma-0">
        <v-col cols="auto" class="pa-0">
          <v-icon :color="assignColor()" class="mr-2">
            mdi-account-circle
          </v-icon>
        </v-col>
        <v-col style="margin-top: 0.2rem;">
          <v-row>
            <span v-text="lastElement.from" class="from" />
          </v-row>
          <v-row>
            <span v-text="lastElement.subject" class="text-caption" />
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-expansion-panels accordion v-model="panel" v-else>
      <v-expansion-panel
        v-for="(message, index) in conversation[Object.keys(conversation)[0]]"
        :key="index"
      >
        <v-expansion-panel-header
          :class="panel == index ? 'pa-3 pb-0' : 'pa-3'"
          hide-actions
        >
          <v-row class="ma-0">
            <v-col class="pa-0">
              <MailDetails
                :message="message"
                :index="index"
                :current="panel"
                :contactsColors="contactsColors"
              />
            </v-col>
            <v-col class="pa-0" cols="auto">
              <v-icon v-if="index == 0" small @click="showConversation = false">
                mdi-chevron-down
              </v-icon>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="ma-0" v-if="showReplyMail">
            <v-col class="pl-5 pr-0">
              <MailNew
                :ref="`replyMail_${index}`"
                :msgTemplate="emailTemplate"
                :isVisible="showReplyMail"
                :isReply="showReplyMail"
                :emailAddresses="[]"
                v-on="$listeners"
              />
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col>
              <MailBox
                :mail="message"
                @download-attachment="downloadAttachment"
              />
            </v-col>
          </v-row>
          <v-row class="ma-0" v-if="!showReplyMail">
            <v-col class="pa-0" align="right">
              <v-btn
                x-small
                outlined
                depressed
                rounded
                color="primary"
                class="pa-2"
                @click="createNewDraft(message)"
              >
                <v-icon small>mdi-arrow-left-top</v-icon>
                <span>Reply</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import Email from '@/mixins/Email'
import MailBox from '@/components/elements/mails/MailBox.vue'
import MailDetails from '@/components/elements/mails/MailDetails.vue'
import MailNew from '@/components/elements/mails/MailNew.vue'

export default {
  name: 'MailPanel',
  props: ['conversation'],
  mixins: [Email],
  components: {
    MailBox,
    MailDetails,
    MailNew,
  },
  data() {
    return {
      panel: null,
      showConversation: false,
    }
  },
  watch: {
    showConversation(isVisible) {
      this.panel = isVisible ? 0 : null
    },
    panel(index) {
      this.showReplyMail = false
      if (!index && this.newMessage.draft == true)
        this.deleteEmail(this.newMessage.messageId)
    },
  },
  computed: {
    lastElement() {
      if (!this.conversation) return
      return this.conversation[Object.keys(this.conversation)[0]][0]
    },
  },
  methods: {
    chooseRandomColor() {
      let colors = [
        'red',
        'green',
        'purple',
        'orange',
        'primary',
        'teal',
        '#FFD700',
      ]
      let result = []
      let conversationMssgs = this.conversation[
        Object.keys(this.conversation)[0]
      ]
      conversationMssgs.forEach((message) => {
        const contact = result.filter((cntct) => {
          return cntct.email === message.from || cntct.email === message.to
        })
        if (contact && contact.length) return
        const random = Math.floor(Math.random() * colors.length)
        let obj = {
          email: message.from,
          color: colors[random],
        }
        result.push(obj)
        colors.splice(random, 1)
      })
      return result
    },
    assignColor() {
      const results = this.contactsColors.filter((contact) => {
        return contact.email === this.lastElement.from
      })
      return results[0].color
    },
  },
  created() {
    this.contactsColors = this.chooseRandomColor()
  },
}
</script>

<style scoped>
.from {
  line-height: 1;
}
.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
  padding: 0 0.75rem 0.75rem !important;
}
/*.row {
  border: solid 1px red;
}
.col {
  border: solid 1px orange;
}*/
</style>
