var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "d-block pa-0 pt-1",
      attrs: { "fill-height": "", fluid: "" },
    },
    [
      _c(
        "v-form",
        { ref: "form" },
        [
          _c(
            "v-row",
            { staticClass: "ma-0" },
            [
              _c(
                "v-col",
                { staticClass: "pt-1 pb-2", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-subheader",
                    { staticClass: "pa-0 primary--text text-overline" },
                    [_vm._v(" " + _vm._s(_vm.$t("label.voyage")) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "d-flex align-center pa-0 pr-3" },
                [_c("v-divider", { staticClass: "my-0" })],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-0" },
            [
              _c(
                "v-col",
                { staticClass: "d-flex pa-0" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center py-0",
                      attrs: { cols: "auto" },
                    },
                    [
                      _c("v-icon", { staticClass: "mr-1" }, [
                        _vm._v("mdi-calendar"),
                      ]),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.formatDate(_vm.savedVoyage.departureDateTime)
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center py-0",
                      attrs: { cols: "auto" },
                    },
                    [
                      _c("v-icon", { staticClass: "mr-1" }, [
                        _vm._v("mdi-map-marker"),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.savedPolLabel))]),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center py-0",
                      attrs: { cols: "auto" },
                    },
                    [
                      _c(
                        "v-radio-group",
                        {
                          staticClass: "radio-group ma-0 pt-0",
                          attrs: {
                            "hide-details": "",
                            readonly: "",
                            dense: "",
                          },
                          model: {
                            value: _vm.polHaulage,
                            callback: function ($$v) {
                              _vm.polHaulage = $$v
                            },
                            expression: "polHaulage",
                          },
                        },
                        [
                          _c(
                            "v-radio",
                            { attrs: { value: "CY", ripple: false } },
                            [
                              _c("template", { slot: "label" }, [
                                _c(
                                  "span",
                                  { staticClass: "text-caption black--text" },
                                  [_vm._v(" CY ")]
                                ),
                              ]),
                            ],
                            2
                          ),
                          _c(
                            "v-radio",
                            { attrs: { value: "SD", ripple: false } },
                            [
                              _c("template", { slot: "label" }, [
                                _c(
                                  "span",
                                  { staticClass: "text-caption black--text" },
                                  [_vm._v(" SD ")]
                                ),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "d-flex pa-0" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center py-0",
                      attrs: { cols: "auto" },
                    },
                    [
                      _c("v-icon", { staticClass: "mr-1" }, [
                        _vm._v("mdi-calendar"),
                      ]),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.formatDate(_vm.savedVoyage.arrivalDateTime)
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center py-0",
                      attrs: { cols: "auto" },
                    },
                    [
                      _c("v-icon", { staticClass: "mr-1" }, [
                        _vm._v("mdi-map-marker"),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.savedPodLabel))]),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center py-0",
                      attrs: { cols: "auto" },
                    },
                    [
                      _c(
                        "v-radio-group",
                        {
                          staticClass: "radio-group ma-0 pt-0",
                          attrs: {
                            "hide-details": "",
                            readonly: "",
                            dense: "",
                          },
                          model: {
                            value: _vm.podHaulage,
                            callback: function ($$v) {
                              _vm.podHaulage = $$v
                            },
                            expression: "podHaulage",
                          },
                        },
                        [
                          _c(
                            "v-radio",
                            { attrs: { value: "CY", ripple: false } },
                            [
                              _c("template", { slot: "label" }, [
                                _c(
                                  "span",
                                  { staticClass: "text-caption black--text" },
                                  [_vm._v(" CY ")]
                                ),
                              ]),
                            ],
                            2
                          ),
                          _c(
                            "v-radio",
                            { attrs: { value: "SD", ripple: false } },
                            [
                              _c("template", { slot: "label" }, [
                                _c(
                                  "span",
                                  { staticClass: "text-caption black--text" },
                                  [_vm._v(" SD ")]
                                ),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.polHaulage == "SD" || _vm.podHaulage == "SD"
            ? _c(
                "v-row",
                { staticClass: "ma-0 py-2 pb-1" },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-subheader",
                        { staticClass: "pa-0 primary--text text-overline" },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("label.transportDetails")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "d-flex align-center py-0 pl-0" },
                    [_c("v-divider", { staticClass: "my-0" })],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            { staticClass: "ma-0" },
            [
              _c("v-col", { staticClass: "pa-0" }, [
                _vm.polHaulage == "SD"
                  ? _c(
                      "div",
                      [
                        _c(
                          "v-row",
                          { staticClass: "ma-0" },
                          [
                            _c(
                              "v-col",
                              { staticClass: "pa-0 pb-2" },
                              [
                                _c(
                                  "v-subheader",
                                  {
                                    staticClass:
                                      "primary--text text-caption font-weight-bold",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("label.placeOfReceipt")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { staticClass: "ma-0" },
                          [
                            _c(
                              "v-col",
                              { staticClass: "d-flex pa-0 pb-1" },
                              [
                                _c(
                                  "v-col",
                                  { staticClass: "py-0" },
                                  [
                                    _c("HaulageLocation", {
                                      attrs: {
                                        title: _vm.$t("label.placeOfReceipt"),
                                        label: _vm.$t("label.address"),
                                        location: _vm.polHaulgeAddressDetails,
                                        rules: _vm.validatePlaceOfReceipt,
                                      },
                                      on: {
                                        "set-address": (address) =>
                                          (_vm.polHaulgeAddressDetails =
                                            address),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { staticClass: "py-0" },
                                  [
                                    _c("ContactDialog", {
                                      attrs: {
                                        title: _vm.$t("label.contact"),
                                        label: _vm.$t("label.contact"),
                                        contact: _vm.polHaulageContact,
                                        rules:
                                          _vm.validatePlaceOfReceiptCompany,
                                      },
                                      on: {
                                        "set-contact": (contact) =>
                                          (_vm.polHaulageContact = contact),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { staticClass: "ma-0" },
                          [
                            _c(
                              "v-col",
                              { staticClass: "pt-0 pb-1" },
                              [
                                _c("DateTimePickerDialog", {
                                  attrs: {
                                    label: _vm.$t("label.pickupDateFrom"),
                                    appearance: "outlined",
                                    preselectedDate:
                                      _vm.polHaulageRemarks.pickupDateFrom,
                                    inputClass: "xs-input",
                                    hideDetails: false,
                                    rules: _vm.required,
                                  },
                                  on: {
                                    "select-date": (date) =>
                                      (_vm.polHaulageRemarks.pickupDateFrom =
                                        date),
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { staticClass: "pt-0 pb-1" },
                              [
                                _c("DateTimePickerDialog", {
                                  attrs: {
                                    label: _vm.$t("label.pickupDateTo"),
                                    appearance: "outlined",
                                    preselectedDate:
                                      _vm.polHaulageRemarks.pickupDateTo,
                                    inputClass: "xs-input",
                                    hideDetails: false,
                                    rules: _vm.required,
                                  },
                                  on: {
                                    "select-date": (date) =>
                                      (_vm.polHaulageRemarks.pickupDateTo =
                                        date),
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { staticClass: "ma-0" },
                          [
                            _c(
                              "v-col",
                              { staticClass: "pt-0 pb-3" },
                              [
                                _c("v-textarea", {
                                  staticClass: "text-caption",
                                  attrs: {
                                    label: _vm.$t(
                                      "label.placeOfReceiptRemarks"
                                    ),
                                    readonly: _vm.uneditableRequest,
                                    "hide-details": "",
                                    "auto-grow": "",
                                    outlined: "",
                                    rows: "1",
                                  },
                                  model: {
                                    value:
                                      _vm.polHaulageRemarks.haulageInstructions,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.polHaulageRemarks,
                                        "haulageInstructions",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "polHaulageRemarks.haulageInstructions",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { staticClass: "pt-0 pb-3" },
                              [
                                _c("v-text-field", {
                                  staticClass: "xs-input",
                                  attrs: {
                                    label: _vm.$t("label.loadRef"),
                                    rules: _vm.required,
                                    outlined: "",
                                    dense: "",
                                  },
                                  model: {
                                    value:
                                      _vm.polHaulageRemarks.haulageReference,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.polHaulageRemarks,
                                        "haulageReference",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "polHaulageRemarks.haulageReference",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _vm.polHaulage == "SD" && _vm.podHaulage == "SD"
                ? _c("v-divider", { attrs: { vertical: "" } })
                : _vm._e(),
              _c("v-col", { staticClass: "pa-0" }, [
                _vm.podHaulage == "SD"
                  ? _c(
                      "div",
                      [
                        _c(
                          "v-row",
                          { staticClass: "ma-0" },
                          [
                            _c(
                              "v-col",
                              { staticClass: "pa-0 pb-2" },
                              [
                                _c(
                                  "v-subheader",
                                  {
                                    staticClass:
                                      "primary--text text-caption font-weight-bold",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("label.placeOfDelivery")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { staticClass: "ma-0" },
                          [
                            _c(
                              "v-col",
                              { staticClass: "d-flex pa-0 pb-1" },
                              [
                                _c(
                                  "v-col",
                                  { staticClass: "py-0" },
                                  [
                                    _c("HaulageLocation", {
                                      attrs: {
                                        title: _vm.$t("label.placeOfDelivery"),
                                        label: _vm.$t("label.address"),
                                        location: _vm.podHaulgeAddressDetails,
                                        rules: _vm.validatePlaceOfDelivery,
                                      },
                                      on: {
                                        "set-address": (address) =>
                                          (_vm.podHaulgeAddressDetails =
                                            address),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { staticClass: "py-0" },
                                  [
                                    _c("ContactDialog", {
                                      attrs: {
                                        title: _vm.$t("label.contact"),
                                        label: _vm.$t("label.contact"),
                                        contact: _vm.podHaulageContact,
                                        rules:
                                          _vm.validatePlaceOfDeliveryCompany,
                                      },
                                      on: {
                                        "set-contact": (contact) =>
                                          (_vm.podHaulageContact = contact),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { staticClass: "ma-0" },
                          [
                            _c(
                              "v-col",
                              { staticClass: "pt-0 pb-1" },
                              [
                                _c("DateTimePickerDialog", {
                                  attrs: {
                                    label: _vm.$t("label.deliveryDateFrom"),
                                    appearance: "outlined",
                                    preselectedDate:
                                      _vm.podHaulageRemarks.deliveryDateFrom,
                                    inputClass: "xs-input",
                                    hideDetails: false,
                                    rules: _vm.required,
                                  },
                                  on: {
                                    "select-date": (date) =>
                                      (_vm.podHaulageRemarks.deliveryDateFrom =
                                        date),
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { staticClass: "pt-0 pb-1" },
                              [
                                _c("DateTimePickerDialog", {
                                  attrs: {
                                    label: _vm.$t("label.deliveryDateTo"),
                                    appearance: "outlined",
                                    preselectedDate:
                                      _vm.podHaulageRemarks.deliveryDateTo,
                                    inputClass: "xs-input",
                                    hideDetails: false,
                                    rules: _vm.required,
                                  },
                                  on: {
                                    "select-date": (date) =>
                                      (_vm.podHaulageRemarks.deliveryDateTo =
                                        date),
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { staticClass: "ma-0" },
                          [
                            _c(
                              "v-col",
                              { staticClass: "pt-0 pb-3" },
                              [
                                _c("v-textarea", {
                                  staticClass: "text-caption",
                                  attrs: {
                                    label: _vm.$t(
                                      "label.placeOfDeliveryRemarks"
                                    ),
                                    readonly: _vm.uneditableRequest,
                                    "hide-details": "",
                                    "auto-grow": "",
                                    outlined: "",
                                    rows: "1",
                                  },
                                  model: {
                                    value:
                                      _vm.podHaulageRemarks.haulageInstructions,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.podHaulageRemarks,
                                        "haulageInstructions",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "podHaulageRemarks.haulageInstructions",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { staticClass: "pt-0 pb-3" },
                              [
                                _c("v-text-field", {
                                  staticClass: "xs-input",
                                  attrs: {
                                    label: _vm.$t("label.loadRef"),
                                    rules: _vm.required,
                                    outlined: "",
                                    dense: "",
                                  },
                                  model: {
                                    value:
                                      _vm.podHaulageRemarks.haulageReference,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.podHaulageRemarks,
                                        "haulageReference",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "podHaulageRemarks.haulageReference",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-0 py-2" },
            [
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-subheader",
                    { staticClass: "pa-0 primary--text text-overline" },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("label.commodityDetails")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "d-flex align-center py-0 pl-0" },
                [_c("v-divider", { staticClass: "my-0" })],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-0" },
            [
              _vm.company == "YANGMING"
                ? _c(
                    "v-col",
                    { staticClass: "d-flex py-0", attrs: { cols: "6" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0 pl-0" },
                        [
                          _c("v-text-field", {
                            staticClass: "xs-input",
                            attrs: {
                              label: _vm.$t("label.commodity"),
                              rules: _vm.required,
                              clearable: "",
                              outlined: "",
                              dense: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.formatCommodity(
                                  _vm.commoditySelected
                                )
                              },
                            },
                            model: {
                              value: _vm.commoditySelected.commodityName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.commoditySelected,
                                  "commodityName",
                                  $$v
                                )
                              },
                              expression: "commoditySelected.commodityName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "py-0" },
                        [
                          _c("v-text-field", {
                            staticClass: "xs-input",
                            attrs: {
                              label: _vm.$t("label.commodityCode"),
                              rules: _vm.required,
                              clearable: "",
                              outlined: "",
                              dense: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.formatCommodity(
                                  _vm.commoditySelected
                                )
                              },
                            },
                            model: {
                              value: _vm.commoditySelected.commodityCode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.commoditySelected,
                                  "commodityCode",
                                  $$v
                                )
                              },
                              expression: "commoditySelected.commodityCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c("v-autocomplete", {
                        staticClass: "xs-input",
                        attrs: {
                          label: _vm.$t("label.commodity"),
                          "item-text": "commodityName",
                          filter: _vm.filterCommodities,
                          items: _vm.commodities,
                          "menu-props": _vm.menuProps,
                          rules: _vm.required,
                          "validate-on-blur": "",
                          readonly: _vm.uneditableRequest,
                          "return-object": "",
                          outlined: "",
                          attach: "",
                          dense: "",
                        },
                        on: {
                          "update:search-input":
                            _vm.getBookingRequestCommodities,
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function (data) {
                              return [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(
                                        " " +
                                          _vm._s(data.item.commodityName) +
                                          " - " +
                                          _vm._s(data.item.commodityCode) +
                                          " "
                                      ),
                                    ]),
                                    _c("v-list-item-subtitle", [
                                      _vm._v(
                                        " " +
                                          _vm._s(data.item.commodityCode) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.commoditySelected,
                          callback: function ($$v) {
                            _vm.commoditySelected = $$v
                          },
                          expression: "commoditySelected",
                        },
                      }),
                    ],
                    1
                  ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-0" },
            [
              _c(
                "v-col",
                { staticClass: "pa-0" },
                [
                  _c("BookingContainers", {
                    attrs: {
                      preselectedContainers: _vm.savedEquipments,
                      company: _vm.company,
                      disabled: _vm.uneditableRequest,
                    },
                    on: {
                      "update-equipments": _vm.updateEquipments,
                      "update-unit-type": _vm.updateWeightUnitType,
                      "update-volume-unit-type": _vm.updateVolumeUnitType,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-0 py-1" },
            [
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-subheader",
                    { staticClass: "pa-0 primary--text text-overline" },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("label.serviceContract")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "d-flex align-center py-0 pl-0" },
                [_c("v-divider", { staticClass: "my-0" })],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-0" },
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex align-center pt-0 pb-4 pl-4",
                  attrs: { cols: "auto" },
                },
                [
                  _c("v-switch", {
                    staticClass: "contract-switch ma-0 pa-0",
                    attrs: {
                      label:
                        _vm.contractType == _vm.contractValue
                          ? "Contract"
                          : "Spot",
                      "true-value": _vm.contractValue,
                      "false-value": _vm.spotValue,
                      readonly: _vm.uneditableRequest,
                      "hide-details": "",
                      inset: "",
                    },
                    model: {
                      value: _vm.contractType,
                      callback: function ($$v) {
                        _vm.contractType = $$v
                      },
                      expression: "contractType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0" },
                [
                  _vm.contractType == _vm.contractValue
                    ? _c("v-combobox", {
                        staticClass: "xs-input",
                        attrs: {
                          label: _vm.$t("label.contract"),
                          items: _vm.serviceContracts,
                          "item-value": "code",
                          "item-text": "code",
                          "menu-props": _vm.menuProps,
                          readonly: _vm.uneditableRequest,
                          rules: _vm.required,
                          "validate-on-blur": "",
                          outlined: "",
                          attach: "",
                          dense: "",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function (data) {
                                return [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          " " + _vm._s(data.item.name) + " "
                                        ),
                                      ]),
                                      _c("v-list-item-subtitle", [
                                        _vm._v(
                                          " " + _vm._s(data.item.code) + " "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          674045169
                        ),
                        model: {
                          value: _vm.contract,
                          callback: function ($$v) {
                            _vm.contract = $$v
                          },
                          expression: "contract",
                        },
                      })
                    : _vm._e(),
                  _vm.contractType == _vm.spotValue
                    ? _c("v-text-field", {
                        staticClass: "xs-input",
                        attrs: {
                          label: _vm.$t("label.quotation"),
                          rules: _vm.required,
                          "validate-on-blur": "",
                          outlined: "",
                          dense: "",
                        },
                        model: {
                          value: _vm.quotationNbr,
                          callback: function ($$v) {
                            _vm.quotationNbr = $$v
                          },
                          expression: "quotationNbr",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.company == "MAERSK"
                ? _c(
                    "v-col",
                    { staticClass: "py-0" },
                    [
                      _c("v-autocomplete", {
                        staticClass: "xs-input",
                        attrs: {
                          label: _vm.$t("label.bookingOffice"),
                          "item-value": "officeName",
                          "item-text": "officeName",
                          filter: _vm.filterBookingOffices,
                          items: _vm.bookingOffices,
                          "menu-props": _vm.menuProps,
                          rules: _vm.required,
                          "validate-on-blur": "",
                          readonly: _vm.uneditableRequest,
                          outlined: "",
                          attach: "",
                          dense: "",
                        },
                        on: {
                          "update:search-input": _vm.getBookingOffices,
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function (data) {
                                return [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          " " +
                                            _vm._s(data.item.officeName) +
                                            " "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          925602927
                        ),
                        model: {
                          value: _vm.bookingOffice,
                          callback: function ($$v) {
                            _vm.bookingOffice = $$v
                          },
                          expression: "bookingOffice",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                { staticClass: "py-0" },
                [
                  _c("ContactDialog", {
                    attrs: {
                      title: _vm.$t("label.contact"),
                      label: _vm.$t("label.contact"),
                      contact: _vm.savedForwarderParty?.partyContactDetails,
                      rules: _vm.validateForwarderPartyContact,
                      showField: "name",
                    },
                    on: {
                      "set-contact": (contact) =>
                        (_vm.forwarderPartyContact = contact),
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0" },
                [
                  _c("v-text-field", {
                    staticClass: "xs-input",
                    attrs: {
                      label: _vm.$t("label.vgmFilingBy"),
                      outlined: "",
                      dense: "",
                    },
                    on: { change: _vm.addVgmFilingByRemark },
                    model: {
                      value: _vm.vgmFilingBy,
                      callback: function ($$v) {
                        _vm.vgmFilingBy = $$v
                      },
                      expression: "vgmFilingBy",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-0" },
            [
              _c(
                "v-col",
                { staticClass: "py-1" },
                [
                  _c("v-textarea", {
                    attrs: {
                      label: _vm.$t("label.bookingRemarks"),
                      readonly: _vm.uneditableRequest,
                      "hide-details": "",
                      "auto-grow": "",
                      outlined: "",
                      rows: "1",
                    },
                    model: {
                      value: _vm.bookingRemarks,
                      callback: function ($$v) {
                        _vm.bookingRemarks = $$v
                      },
                      expression: "bookingRemarks",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }