<template>
<div>
  <v-btn fab icon height="50" width="50" class="primary mt-3" @click="handleDialog">
    <v-icon color="white" size="30">mdi-notebook</v-icon>
  </v-btn>
  <v-dialog v-model="dialog" width="fit-content">
    <v-card>
      <v-card-title class="pa-2 px-3">
        <span class="text-overline primary--text">
          {{ $t('label.addressesBook') }}
        </span>
      </v-card-title>
      <v-divider class="ma-0" />
      <v-container fluid fill-height class="pa-0 pb-3">
        <v-data-table
          :headers="headers"
          :items="contacts"
          :items-per-page="50"
          hide-default-footer
          class="pa-3 pb-0"
          dense
        >
          <template slot="no-data">
            <span class="font-weight-bold">
              {{ $t('label.emptyAddressBook') }}
            </span>
          </template>
          <template v-slot:header.actions>
            <v-icon color="primary" @click="addNew">mdi-plus-circle</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td :class="findIndex(item) === editedIndex ? 'pa-0' : ''">
                <v-text-field
                  v-if="findIndex(item) === editedIndex"
                  v-model="editedItem.agent"
                  :autofocus="findIndex(item) === contacts.length ? false : true"
                  hide-details
                  dense
                  flat
                  solo
                />
                <span v-else>{{ item.agent }}</span>
              </td>
              <td :class="findIndex(item) === editedIndex ? 'pa-0' : ''">
                <v-text-field
                  v-if="findIndex(item) === editedIndex"
                  v-model="editedItem.email"
                  :autofocus="findIndex(item) === contacts.length ? false : true"
                  hide-details
                  dense
                  flat
                  solo
                />
                <span v-else>{{ item.email }}</span>
              </td>
              <td :class="findIndex(item) === editedIndex ? 'pa-0' : ''">
                <span>{{ item.destination }}</span>
              </td>
              <td :class="findIndex(item) === editedIndex ? 'pa-0' : ''">
                <span>{{ item.country }}</span>
              </td>
              <td class="px-1">
                <div v-if="findIndex(item) === editedIndex">
                  <v-icon color="error" class="mr-3" @click="handleCancel">
                    mdi-window-close
                  </v-icon>
                  <v-icon color="success" @click="save">
                    mdi-content-save
                  </v-icon>
                </div>
                <div class="d-inline-flex" v-else>
                  <v-icon color="primary" class="mr-3" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <ButtonConfirm
                    :color="'error'"
                    @submit="deleteItem(item)"
                  />
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-container>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import ButtonConfirm from '@/components/elements/global/ButtonConfirm'

export default {
  name: 'AddressesBook',
  props: {
    foreignAgent: {
      type: Object,
    },
    contacts: {
      type: Array,
      default: () => []
    },
  },
  emits: ['update-contact', 'create-contact', 'delete-contact'],
  components: { ButtonConfirm },
  data() {
    return {
      dialog: false,
      headers: [
        {
          text: this.$t('label.agent'),
          value: 'agent',
          sortable: false,
          width: '20rem',
        },
        {
          text: this.$t('label.email'),
          value: 'email',
          sortable: false,
          width: '20rem',
        },
        {
          text: this.$t('label.destination'),
          value: 'destination',
          sortable: false,
          width: '15rem',
        },
        {
          text: this.$t('label.country'),
          value: 'country',
          sortable: false,
          width: '15rem',
        },
        {
          value: 'actions',
          sortable: false,
          class: 'text-end pr-1',
        },
      ],
      editedIndex: undefined,
      editedItem: undefined,
    }
  },
  computed: {
    isNonRevenue() {
      return this.foreignAgent?.foreignAgentNonRevenueId != null
    },
    isUnselected() {
      return this.foreignAgent?.foreignAgentNonRevenueId == null && this.foreignAgent?.foreignAgentId == null
    }
  },
  watch: {
    dialog(isVisible) {
      if (!isVisible) this.handleCancel()
    }
  },
  methods: {
    findIndex(item) {
      return this.contacts.indexOf(item)
    },
    handleCancel() {
      if (this.editedItem && this.editedItem.id == undefined) {
        this.contacts.splice(0, 1)
      }
      this.editedItem = undefined
      this.editedIndex = undefined
    },
    validateEmail(email) {
      let validated = true
      var emailsPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
      let verifiedMail = emailsPattern.test(email)
      if (!verifiedMail) {
        this.setSnackbarMessage({ text: this.$t('label.insertValidEmail'), color: 'error' })
        validated = false
      }
      return validated
    },
    editItem(item) {
      this.editedIndex = this.contacts.indexOf(item)
      this.editedItem = Object.assign({}, item)
    },
    deleteItem(item) {
      this.handleCancel()
      this.$emit('delete-contact', item.id)
    },
    addNew() {
      let contact = this.getContact()
      this.contacts.unshift(contact)
      this.editItem(contact)
    },
    getContact() {
      let sample = this.contacts?.[0]
      if (sample == undefined) return {
        agentId: !this.isNonRevenue ? this.foreignAgent.foreignAgentId : this.foreignAgent.foreignAgentNonRevenueId,
        country: this.foreignAgent.country,
        destination: this.foreignAgent.destination
      }
      return {
        agent: !this.isNonRevenue ? sample.agent : '',
        agentId: sample.agentId,
        country: sample.country,
        destination: sample.destination
      }
    },
    save() {
      if (!this.validateEmail(this.editedItem.email)) return

      if (this.editedItem.id == undefined)
        this.$emit('create-contact', this.editedItem)
      else
        this.$emit('update-contact', this.editedItem)
    },
    handleDialog() {
      if (this.isUnselected) {
        this.setSnackbarMessage({ text: `${this.$t('message.selectForeignAgent')}`, color: 'warning' })
        return
      }
      this.dialog = !this.dialog
    }
  },
}
</script>
