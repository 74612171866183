import { authHeader } from '@/helpers/auth-header'
import { commons } from './commons'

export const groupParametersService = {
  createGroupParameter,
  updateGroupParameter,
  deleteGroupParameter,
  getGroupParameter,
  getAllByGroup
}

const resourceUrl = process.env.VUE_APP_GSCV_ENGINE_URL + `/group-parameters`

function createGroupParameter(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }
  return fetch(resourceUrl, requestOptions).then(commons.handleJsonResponse)
}

function updateGroupParameter(id, data) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }
  return fetch(resourceUrl + `/${id}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function deleteGroupParameter(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/${id}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function getGroupParameter(groupId, key) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }
  return fetch(
    resourceUrl + `?groupId=${groupId}&key=${key}`,
    requestOptions,
  ).then(commons.handleJsonResponse)
}

function getAllByGroup(groupId) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }
  return fetch(resourceUrl + `/group/${groupId}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}