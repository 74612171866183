import { mapState, mapMutations } from 'vuex'
import { dateFilter } from '@/filters/dateFilter'
import { electronicFlowService } from '@/services/electronicFlow.service.js'
import Snackbar from '@/components/views/Snackbar.vue'

export default {
  components: { Snackbar },
  emits: ['exit-request', 'step'],
  data() {
    return {
      applicantCompanyName: process.env.VUE_APP_APPLICANT_COMPANY,
      companyPhoneNbr: process.env.VUE_APP_APPLICANT_COMPANY_NUMBER,
      companyCity: process.env.VUE_APP_APPLICANT_COMPANY_CITY,
      companyAddress: process.env.VUE_APP_APPLICANT_COMPANY_ADDRESS,
      companyAddressNbr: process.env.VUE_APP_APPLICANT_COMPANY_ADDRESS_NBR,
      companyPostalCode: process.env.VUE_APP_APPLICANT_COMPANY_POSTAL_CODE,
      companyCountryMaersk: process.env.VUE_APP_APPLICANT_COMPANY_COUNTRY_MAERSK,
      companyCountryCmaCgm: process.env.VUE_APP_APPLICANT_COMPANY_COUNTRY_CMACGM,
      companyMaerskCode: process.env.VUE_APP_APPLICANT_COMPANY_MAERSK_CODE,

      createdStatus: 'CREATED',
      detailsSelectedStatus: 'DETAILS_SELECTED',
      voyageSelectedStatus: 'VOYAGE_SELECTED',
      commoditySelectedStatus: 'COMMODITY_SELECTED',
      pendingStatus: 'PENDING',
      confirmedStatus: 'CONFIRMED',
      rejectedStatus: 'REJECTED',
      cancelledStatus: 'CANCELLED',
      disableNext: false,
      menuProps: {
        offsetY: true,
        overflowY: 'auto',
        maxHeight: '10rem',
      },
    }
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapState('electronicFlow', ['company', 'lastSelectedFlow', 'electronicFlow']),
    ...mapState('booking', ['bookingRequest']),
    electronicFlowStatus() {
      return this.electronicFlow.status
    },
    bookingCarrierReference() {
      return this.electronicFlow.carrierReference
    },
    carrierCode(){
      switch (this.company) {
        case 'MAERSK':
          return ''
        case 'CMACGM':
          return ''
        case 'YANGMING':
          return 'YMLU'
      }
    },
    polHaulage() {
      let polHaulage = this.bookingRequest.receiptTypeAtOrigin
      return polHaulage ? polHaulage : 'CY'
    },
    podHaulage() {
      let podHaulage = this.bookingRequest.deliveryTypeAtDestination
      return podHaulage ? podHaulage : 'CY'
    },
    savedRequest() {
      if (!this.electronicFlow.bookingRequest) return
      let request = JSON.parse(this.electronicFlow.bookingRequest.request)
      return request ? request : null
    },
    savedRequestDetails() {
      if (!this.electronicFlow.bookingRequest) return
      return this.electronicFlow.bookingRequest.requestDetails
    },
    savedEquipments() {
      if (!this.electronicFlow.bookingRequest) return
      let equipments = JSON.parse(this.electronicFlow.bookingRequest.equipments)
      return equipments ? equipments : null
    },
    // Saved Voyage Data
    savedVoyage() {
      if (!this.electronicFlow.bookingRequest) return
      let voyage = JSON.parse(this.electronicFlow.bookingRequest.voyageId)
      return voyage ? voyage : null
    },
    savedDepartureDate(){ 
      if (!this.savedVoyage) return
      return this.formatDate(this.savedVoyage.departureDateTime)
    },
    savedArrivalDate() {
      if (!this.savedVoyage) return
      return this.formatDate(this.savedVoyage.arrivalDateTime)
    },
    savedMainVessel(){
      if (!this.savedVoyage) return
      let vesselLegs = this.savedVoyage.routeLegs.filter((leg) => leg.transport && leg.transport.vessel.vesselName != null)
      if (!vesselLegs.length) return
      vesselLegs = this.sortByDepartureDateAsc(vesselLegs)
      return vesselLegs[0].transport.vessel.vesselName
    },
    savedVoyageNbr(){
      if (!this.savedVoyage) return
      let vesselLegs = this.savedVoyage.routeLegs.filter((leg) => leg.transport && leg.transport.vessel.vesselName != null)
      if (!vesselLegs.length) return
      vesselLegs = this.sortByDepartureDateAsc(vesselLegs)
      return vesselLegs[0].transport.voyageNumber
    },
    savedPol() {
      if (!this.savedVoyage) return
      let pol = this.savedVoyage.originPoint ? this.savedVoyage.originPoint : null
      return pol
    },
    savedPod() {
      if (!this.savedVoyage) return
      let pod = this.savedVoyage.destinationPoint ? this.savedVoyage.destinationPoint : null
      return pod
    },
    savedPolLabel(){
      return this.savedPol ? this.getLocationLabel(this.savedPol) : null
    },
    savedPodLabel(){
      return this.savedPod ? this.getLocationLabel(this.savedPod) : null
    },
    savedPolHaulageDetails() {
      if (!this.bookingRequest.requestedEquipments.length) return null
      let haulageDetails = this.bookingRequest.requestedEquipments[0].haulageDetails
      let polHaulage = haulageDetails ? haulageDetails.filter((detail) => detail.operationalStage == 'EXPORT') : []
      return polHaulage.length ? polHaulage[0] : null
    },
    savedPodHaulageDetails() {
      if (!this.bookingRequest.requestedEquipments.length) return null
      let haulageDetails = this.bookingRequest.requestedEquipments[0].haulageDetails
      let podHaulage = haulageDetails ? haulageDetails.filter((detail) => detail.operationalStage == 'IMPORT') : []
      return podHaulage.length ? podHaulage[0] : null
    },
    savedPolHaulageLocation() {
      return this.savedPolHaulageDetails ? this.savedPolHaulageDetails.haulageSites[0].address : null
    },
    savedPodHaulageLocation() {
      return this.savedPodHaulageDetails ? this.savedPodHaulageDetails.haulageSites[0].address : null
    },
    savedPolHaulageContact() {
      return this.savedPolHaulageDetails ? this.savedPolHaulageDetails.haulageSites[0].contactDetails : null
    },
    savedPodHaulageContact() {
      return this.savedPodHaulageDetails ? this.savedPodHaulageDetails.haulageSites[0].contactDetails : null
    },
    savedPolHaulageCompany() {
      return this.savedPolHaulageDetails ? this.savedPolHaulageDetails.haulageSites[0].companyName : null
    },
    savedPodHaulageCompany() {
      return this.savedPodHaulageDetails ? this.savedPodHaulageDetails.haulageSites[0].companyName : null
    },
    savedPolHaulageLoadRef() {
      return this.savedPolHaulageDetails ? this.savedPolHaulageDetails.haulageSites[0].haulageReference : null
    },
    savedPodHaulageLoadRef() {
      return this.savedPodHaulageDetails ? this.savedPodHaulageDetails.haulageSites[0].haulageReference : null
    },
    savedPolHaulageRemarks() {
      return this.savedPolHaulageDetails ? this.savedPolHaulageDetails.haulageInstructions : null
    },
    savedPodHaulageRemarks() {
      return this.savedPodHaulageDetails ? this.savedPodHaulageDetails.haulageInstructions : null
    },
    savedPolHaulageMovementType(){
      return this.savedPolHaulageDetails ? this.savedPolHaulageDetails.movementType : null
    },
    savedPodHaulageMovementType(){
      return this.savedPodHaulageDetails ? this.savedPodHaulageDetails.movementType : null
    },
    // savedLegsFormatted(){
    //   let vesselLegs = []
    //   if (!this.savedVoyage) return vesselLegs
    //   this.savedVoyage.routeLegs.forEach(leg => {
    //     let vesselLeg = {}
    //     vesselLeg.vessel = leg.transport.vessel.vesselName
    //     vesselLeg.voyageNbr = leg.transport.voyageNumber
    //     vesselLeg.leg = `${leg.pointFrom.locationCode} - ${leg.pointTo.locationCode}`
    //     vesselLeg.mode = leg.transport.transportMode
    //     vesselLegs.push(vesselLeg)
    //   });
    //   return vesselLegs
    // },
    // Saved Commodity Data
    savedVgmFilingBy(){
      if (!this.savedRequest) return 
      let remarks = this.savedRequest.consumerRemarks.filter((remark) => remark.label == 'VGM filing by: ')
      if (remarks.length) return remarks[0].value
    },
    savedPriceOwner(){
      if (this.objIsEmpty(this.savedRequest.documentParties)) return
      let priceOwnerArray = this.savedRequest.documentParties.filter((party) => party.partyFunction == 'SCO' || party.partyFunction == 'BA')
      if (!priceOwnerArray.length) return
      return priceOwnerArray[0].party.partyName
    },
    savedBookingOffice(){
      return this.savedRequest ? this.savedRequest.bookingOfficeName : null
    },
    savedServiceName(){
      return this.savedRequest ? this.savedRequest.carrierServiceName : null
    },
    savedContract(){
      return this.savedRequest ? this.savedRequest.contractQuotationReference : null
    },
    savedCommodity(){
      return this.savedRequest.commodity ? this.savedRequest.commodity : null
    },
    // savedForwarderPartyContact(){
    //   if (!this.savedRequest) return
    //   let applicantParties = this.savedRequest.documentParties.filter((party) => party.party.partyName == this.applicantCompanyName)
    //   if (!applicantParties.length) return
    //   return applicantParties[0]?.party?.partyContactDetails
    // },
    savedForwarderParty(){
      if (!this.savedRequest) return
      let applicantParties = this.savedRequest.documentParties.filter((party) => party.party.partyName == this.applicantCompanyName)
      if (!applicantParties.length) return
      return applicantParties[0]?.party
    },
    savedContractType(){
      return this.savedRequest ? this.savedRequest.carrierServiceName : null
    },
    savedContractQuotationReference(){
      return this.savedRequest ? this.savedRequest.contractQuotationReference : null
    },
    savedBookingRemarks(){
      return this.savedRequest ? this.savedRequest.bookingRemarks : null
    },
    savedExportRemarks(){
      return this.savedRequest ? this.savedRequest.exportRemarks : null
    },
    savedExportRemarksPickupDateFrom(){
      return this.savedExportRemarks ? this.savedExportRemarks.pickupDateFrom : null
    },
    savedExportRemarksPickupDateTo(){
      return this.savedExportRemarks ? this.savedExportRemarks.pickupDateTo : null
    },
    savedImportRemarks(){
      return this.savedRequest ? this.savedRequest.importRemarks : null
    },
    savedImportRemarksDeliveryDateFrom(){
      return this.savedImportRemarks ? this.savedImportRemarks.deliveryDateFrom : null
    },
    savedImportRemarksDeliveryDateTo(){
      return this.savedImportRemarks ? this.savedImportRemarks.deliveryDateTo : null
    },
    savedPickUpDate(){
      return this.savedPolHaulageDetails ? this.savedPolHaulageDetails.haulageSites[0].haulageStartDateTime : null
    },
    // Other
    uneditableRequest() {
      return this.electronicFlowStatus == this.pendingStatus || this.electronicFlowStatus == this.rejectedStatus || this.electronicFlowStatus == this.cancelledStatus
    },
    editableSelectedVoyage() {
      return this.electronicFlowStatus == this.createdStatus || this.editableSelectedCommodity
    },
    editableSelectedCommodity() {
      return this.electronicFlowStatus == this.voyageSelectedStatus || this.editableReview
    },
    editableReview() {
      return this.electronicFlowStatus == this.commoditySelectedStatus || this.electronicFlowStatus == this.confirmedStatus
    },
    userCompleteName() {
      return `${this.user.firstName} ${this.user.lastName}`.replace(/\b\w/g, (x) => x.toUpperCase())
    },
    tomorrowDate(){
      var date = new Date();
      date.setDate(date.getDate() + 1);
      return date;
    }
  },
  methods: {
    ...mapMutations('snackbar', ['setSnackbarMessage']),
    ...mapMutations('booking', [
      'resetBookingRequest',
      'setBookingRequest',
      'setDocumentParties',
      'setCarrier',
      'setExpectedDepartureDate',
      'setShipmentLocations',
      'setTransports',
      'setReceiptTypeAtOrigin',
      'setDeliveryTypeAtDestination',
      'setCarrierServiceName',
      'setContractQuotationReference',
      'setBookingOfficeName',
      'setCommodity',
      'setRequestedEquipments',
      'setPaymentTermCode',
      'setChargeCode',
      'setBookingRemarks',
      'setExportRemarks',
      'setImportRemarks',
      'setConsumerRemarks'
    ]),
    ...mapMutations('electronicFlow', [
      'resetElectronicFlow',
      'setCompany',
      'setLastSelectedFlow',
      'setElectronicFlow',
      'setDocumentNbr',
      'setObjectType',
      'setStatus',
      'setBookingRequestJson',
      'setRequestVoyageId',
      'setRequestDetails',
      'setRequestEquipments',
    ]),
    ...mapMutations('shippingInstruction', [ 'resetShippingInstruction' ]),
    findElectronicFlowById(id) {
      return electronicFlowService.findById(id).then((resp) => {
        if (!resp) return
        this.setElectronicFlow(resp)
      })
    },
    updateElectronicFlow(id, data) {
      electronicFlowService
        .updateElectronicFlow(id, data)
        .then(() => this.findElectronicFlowById(id))
    },
    updateRequestAndSaveElectronicFlow() {
      this.setBookingRequestJson(JSON.stringify(this.bookingRequest))
      this.updateElectronicFlow(this.electronicFlow.id, this.electronicFlow)
    },
    browseCommodities(input) {
      if (!input) return
      let regExp = /[a-zA-Z]/g
      let code = ''
      let description = ''
      if (regExp.test(input)) description = input
      else code = input
      return electronicFlowService.getBookingRequestCommodities(this.company, code, description)
    },
    disableNextForPendingRequest() {
      if (this.electronicFlowStatus == this.pendingStatus)
        this.disableNext = true
    },
    exitRequest() {
      this.$emit('exit-request')
    },
    // Utils
    formatDate(date) {
      return dateFilter.formatDate(date)
    },
    formatDateDayIso(date) {
      return dateFilter.formatDateDayIso(date)
    },
    capitalizeFirstLetter(word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    },
    getLocationLabel(location) {
      if (!location) return
      let label = `${location.locationCode}`
      if (location.countryCode) label += ` - ${location.countryCode} ${location.locationName}`
      return label
    },
    getShortLocationLabel(location) {
      if (!location) return
      return `${location.locationName}, ${location.countryCode}`
    },
    getFacilityLocationLabel(location) {
      if (!location) return
      if (!location.locationName) return location
      return `${location.locationName}, ${location.countryCode} <br> ${location.facility.facilityName}`
    },
    formatAddress(address) {
      if (!address) return
      let street = address.street && address.streetNumber ? `${address.street} ${address.streetNumber}\n` : null
      let city = `${address.city}, ${address.postCode}, ${address.country}`
      return street ? street + city : city
    },
    formatContact(contactDetails) {
      if (!contactDetails) return
      let contact = ``
      contact += `${contactDetails.name ? contactDetails.name : ''}\n`
      contact += `${contactDetails.email ? contactDetails.email : ''}\n` 
      contact += `${contactDetails.phone ? contactDetails.phone : ''}`
      return contact
    },
    objIsEmpty(object) {
      return Object.keys(object).length == 0
    },
    sortList(list, field, asc) {
      if (!list.length) return []
      return list.sort(function (a, b) {
        if (a[field] > b[field]) return asc ? 1 : -1
        if (a[field] < b[field]) return asc ? -1 : 1
        return 0
      })
    },
    sortByDepartureDateAsc(list) {
      if (!list.length) return []
      return list.sort(function (a, b) {
        if (a.departureDateTime > b.departureDateTime) return 1
        if (a.departureDateTime < b.departureDateTime) return -1
        return 0
      })
    },
  },
  created() {
    this.disableNextForPendingRequest()
  },
}
