const getDefaultState = () => {
  return {
    bookingRequest: {
      carrierCode: null,
      carrierName: null,
      contractQuotationReference: null,
      carrierServiceName: null,
      bookingOfficeName: null,
      documentParties: [],
      expectedDepartureDate: null,
      receiptTypeAtOrigin: null,
      deliveryTypeAtDestination: null,
      shipmentLocations: [],
      commodity: {},
      requestedEquipments: [],
      transports: [],
      paymentTermCode: null,
      chargeCode: null,
      bookingRemarks: null,
      exportRemarks: null,
      importRemarks: null,
      consumerRemarks: [],
    },
  }
}

const state = getDefaultState()

const mutations = {
  resetBookingRequest(state){
    Object.assign(state, getDefaultState())
  },
  setBookingRequest(state, bookingRequest) {
    state.bookingRequest = bookingRequest
  },
  setCarrier(state, carrier) {
    state.bookingRequest.carrierCode = carrier.carrierCode
    state.bookingRequest.carrierName = carrier.carrierName
  },
  setContractQuotationReference(state, quotation) {
    state.bookingRequest.contractQuotationReference = quotation
  },
  setCarrierServiceName(state, carrierServiceName) {
    state.bookingRequest.carrierServiceName = carrierServiceName
  },
  setBookingOfficeName(state, bookingOfficeName) {
    state.bookingRequest.bookingOfficeName = bookingOfficeName
  },
  setDocumentParties(state, parties) {
    state.bookingRequest.documentParties = parties
  },
  setExpectedDepartureDate(state, departureDate) {
    state.bookingRequest.expectedDepartureDate = departureDate
  },
  setReceiptTypeAtOrigin(state, receiptTypeAtOrigin) {
    state.bookingRequest.receiptTypeAtOrigin = receiptTypeAtOrigin
  },
  setDeliveryTypeAtDestination(state, deliveryTypeAtDestination) {
    state.bookingRequest.deliveryTypeAtDestination = deliveryTypeAtDestination
  },
  setShipmentLocations(state, locations) {
    state.bookingRequest.shipmentLocations = locations
  },
  setCommodity(state, commodity) {
    state.bookingRequest.commodity = commodity
  },
  setRequestedEquipments(state, equipments) {
    state.bookingRequest.requestedEquipments = equipments
  },
  setTransports(state, transports) {
    state.bookingRequest.transports = transports
  },
  setPaymentTermCode(state, paymentTermCode) {
    state.bookingRequest.paymentTermCode = paymentTermCode
  },
  setChargeCode(state, chargeCode) {
    state.bookingRequest.chargeCode = chargeCode
  },
  setBookingRemarks(state, bookingRemarks) {
    state.bookingRequest.bookingRemarks = bookingRemarks
  },
  setExportRemarks(state, exportRemarks) {
    state.bookingRequest.exportRemarks = exportRemarks
  },
  setImportRemarks(state, importRemarks) {
    state.bookingRequest.importRemarks = importRemarks
  },
  setConsumerRemarks(state, consumerRemarks) {
    state.bookingRequest.consumerRemarks = consumerRemarks
  },
}

export const booking = {
  namespaced: true,
  state,
  mutations,
}
