var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "fit-content" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-text-field",
                _vm._g(
                  {
                    class: _vm.inputClass,
                    attrs: {
                      label: _vm.label,
                      "append-icon": "mdi-calendar",
                      outlined: _vm.appearance.includes("outlined"),
                      solo: _vm.appearance.includes("solo"),
                      flat: _vm.appearance.includes("flat"),
                      clearable: !_vm.disabled,
                      "hide-details": _vm.hideDetails,
                      rules: _vm.rules,
                      readonly: "",
                      dense: "",
                    },
                    on: {
                      "click:append": function ($event) {
                        _vm.disabled ? null : (_vm.dialog = !_vm.dialog)
                      },
                    },
                    model: {
                      value: _vm.selectedDateTime,
                      callback: function ($$v) {
                        _vm.selectedDateTime = $$v
                      },
                      expression: "selectedDateTime",
                    },
                  },
                  _vm.disabled ? null : on
                )
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-carousel",
        {
          attrs: {
            "hide-delimiters": "",
            "show-arrows": false,
            width: "100%",
            height: "100%",
            touchless: "",
          },
          model: {
            value: _vm.page,
            callback: function ($$v) {
              _vm.page = $$v
            },
            expression: "page",
          },
        },
        [
          _c(
            "v-carousel-item",
            { attrs: { value: 0 } },
            [
              _c("v-date-picker", {
                attrs: { color: "primary", readonly: _vm.disabled },
                on: {
                  change: function ($event) {
                    _vm.page = 1
                  },
                },
                model: {
                  value: _vm.date,
                  callback: function ($$v) {
                    _vm.date = $$v
                  },
                  expression: "date",
                },
              }),
            ],
            1
          ),
          _c(
            "v-carousel-item",
            { attrs: { value: 1 } },
            [
              _vm.dialog
                ? _c("v-time-picker", {
                    attrs: { format: "24hr", scrollable: "" },
                    model: {
                      value: _vm.time,
                      callback: function ($$v) {
                        _vm.time = $$v
                      },
                      expression: "time",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }