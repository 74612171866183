import { authHeader } from "@/helpers/auth-header";
import { commons } from "./commons";

export const imballiService = {
  browse,
  getImballiByDocumentNbr,
  getImballiByPraticaTransportOrder,
};

const resourceUrl = process.env.VUE_APP_GSCV_ENGINE_URL + `/imballo`;

function browse(offset, limit, filter, sortingList) {
  if (!sortingList.length) {
    sortingList = [];
    sortingList.push({
      column: "id",
      direction: "DESC",
    });
  }
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      offset: offset,
      limit: limit,
      filter: filter,
      sortingList: sortingList,
    }),
  };
  return fetch(resourceUrl + `/browse`, requestOptions).then(
    commons.handleJsonResponse
  );
}

function getImballiByDocumentNbr(documentNbr) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(resourceUrl + `/pratica/${documentNbr}`, requestOptions).then(
    commons.handleJsonResponse
  );
}

function getImballiByPraticaTransportOrder(documentNbr, transportOrder) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    resourceUrl + `/pratica/${documentNbr}/transport-order/${transportOrder}`,
    requestOptions
  ).then(commons.handleJsonResponse);
}
