import { authHeader } from "@/helpers/auth-header";
import { commons } from "./commons";

export const sapUsersService = {
  getAllSapUsers,
  getUserByEmailIdentity
};

const resourceUrl = process.env.VUE_APP_GSCV_ENGINE_URL + `/sap-user`;

function getAllSapUsers() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(resourceUrl, requestOptions).then(commons.handleJsonResponse);
}

function getUserByEmailIdentity(email) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/${email}`, requestOptions).then(commons.handleJsonResponse)
}