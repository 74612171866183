var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "my-1", attrs: { outlined: "" } },
    [
      _c(
        "v-row",
        {
          class: ["ma-0", { selected: _vm.shipment.isSelected }],
          attrs: { dense: "" },
          on: { click: _vm.selectShipmentDocumentNbr },
        },
        [
          _c(
            "v-col",
            {
              staticClass:
                "py-0 text-caption font-weight-bold d-flex align-center",
              attrs: { cols: "auto" },
            },
            [
              _c(
                "v-row",
                {
                  staticClass:
                    "ma-0 d-flex flex-column justify-center text-left",
                  attrs: { dense: "" },
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0 d-flex flex-column" },
                    [
                      _c("span", {
                        staticClass: "text-subtitle-1 font-weight-black",
                        domProps: {
                          textContent: _vm._s(_vm.shipment.documentNbr),
                        },
                      }),
                      _c(
                        "v-chip",
                        { attrs: { color: "#e0e0e0", label: "", small: "" } },
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "#757575", small: "" } },
                            [_vm._v("mdi-file-document-outline")]
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-medium ml-1 text-caption text-left",
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("label.shipmentNbr")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0" },
                    [
                      _c("ReadonlyField", {
                        attrs: {
                          label: _vm.$t("label.documentDate"),
                          value: new Date(
                            _vm.shipment.documentDate
                          ).toLocaleDateString("en-GB", {
                            day: "numeric",
                            month: "numeric",
                            year: "2-digit",
                          }),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-col",
            {
              staticClass:
                "py-0 text-caption font-weight-bold d-flex align-center",
            },
            [
              _c(
                "v-row",
                {
                  staticClass:
                    "ma-0 d-flex flex-column justify-center text-left",
                  attrs: { dense: "" },
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0" },
                    [
                      _c("ReadonlyField", {
                        attrs: {
                          label: _vm.$t("label.shipper"),
                          value: _vm.shipment.shipperName,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "ma-1" }),
                  _c(
                    "v-col",
                    { staticClass: "py-0" },
                    [
                      _c("ReadonlyField", {
                        attrs: {
                          label: _vm.$t("label.consigneeName"),
                          value: _vm.shipment.consigneeName,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-col",
            {
              staticClass:
                "py-0 text-caption font-weight-bold d-flex align-center",
            },
            [
              _c(
                "v-row",
                {
                  staticClass:
                    "ma-0 d-flex flex-column justify-center text-left",
                  attrs: { dense: "" },
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0" },
                    [
                      _c("ReadonlyField", {
                        attrs: {
                          label: _vm.$t("label.customer"),
                          value: _vm.shipment.clientName,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "ma-1" }),
                  _c(
                    "v-row",
                    { staticClass: "ma-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0 px-1" },
                        [
                          _c("ReadonlyField", {
                            attrs: {
                              label: _vm.$t("label.boardingNode"),
                              value: _vm.shipment.pol,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "py-0 px-1" },
                        [
                          _c("ReadonlyField", {
                            attrs: {
                              label: _vm.$t("label.landingNode"),
                              value: _vm.shipment.pod,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }