<template>
  <v-stepper class="pa-0" elevation="0">
    <v-stepper-header>
      <template v-for="(prop, i) in props">
        <v-stepper-step
          :key="i"
          :complete="entity[prop.value]"
          :step="prop.text"
          color="secondary"
          @click="update(prop)"
        >
          {{ prop.text }}
        </v-stepper-step>
        <v-divider
          :key="prop.value"
          v-if="props.indexOf(prop) != props.length - 1"
        />
      </template>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
export default {
  name: 'ActionsStepper',
  props: ['entity', 'props'],
  emits: ['update-props'],
  methods: {
    update(prop) {
      this.entity[prop.value] = !this.entity[prop.value]
      this.$emit('update-props', this.entity)
    },
  },
}
</script>

<style scoped>
.v-stepper::v-deep .v-stepper__step__step {
  color: transparent !important;
}
</style>
