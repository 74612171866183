<template>
  <v-timeline>
    <v-timeline-item
      v-for="(route, i) in steps"
      :key="i"
      right
      color="white"
      small
      :class="removePaddingB(i)"
    >
      <template v-slot:opposite>
        <div v-if="showLocationLabel(i, route)">
          <span class="text-caption font-weight-bold">
            {{ route.location.locationName }}
          </span>
          <br />
          <span class="text-caption">
            {{ route.location.facility ? route.location.facility.facilityName : '' }}
          </span>
        </div>
      </template>
      <template v-slot:icon>
        <v-icon color="primary">{{ route.icon }}</v-icon>
      </template>
      <span class="text-caption" v-if="route.vessel && route.vessel.vesselName">
        {{ `${$t('label.departingOn')} ${route.vessel.vesselName}` }}
      </span>
      <span class="text-caption" v-if="route.isDeparture && !route.vessel && route.date">
        {{ `${$t('label.departure')}` }}
      </span>
      <span v-if="!route.isDeparture && route.date" class="text-caption">
        {{ `${$t('label.arrival')}` }}
      </span>
      <br />
      <span class="text-caption">{{ formatDate(route.date) }}</span>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import { dateFilter } from '@/filters/dateFilter'

export default {
  name: 'BookingRoutesYangMing',
  props: ['routes'],
  data() {
    return {
      steps: [],
    }
  },
  methods: {
    composeSteps() {
      let steps = []
      this.routes.forEach((route) => {
        steps.push(this.getFromPointItem(route))
        steps.push(this.getToPointItem(route))
      })
      this.steps = this.sort(steps)
    },
    getFromPointItem(route) {
      return {
        isDeparture: true,
        location: route.pointFrom,
        date: route.departureDateTime,
        vessel: this.getVessel(route.transport),
        icon: this.getTransportIcon(route.transport)
      }
    },
    getToPointItem(route) {
      return {
        isDeparture: false,
        location: route.pointTo,
        date: route.arrivalDateTime,
        icon: 'mdi-map-marker-outline',
      }
    },
    getVessel(transport){
      if (!transport) return
      return transport.vessel
    },
    getTransportIcon(transport){
      if (!transport) return 'mdi-map-marker'
      switch (transport.transportMode) {
        case 'Road':
        case 'Barge/Road':
        case 'TRK':
          return 'mdi-truck'
        case 'Rail':
        case 'Rail/Barge':
        case 'RCO':
        case 'RR':
          return 'mdi-train'
        case 'Vessel':
        case 'MVS':
        case 'FEO':
          return 'mdi-ferry'
        case 'Rail/Road':
        case 'Mixed':
        case '':
          return 'mdi-train-car'
        default:
          if (transport.vessel && transport.vessel.vesselName) return 'mdi-ferry'
          else return 'mdi-map-marker'
      }
    },
    showLocationLabel(i, route) {
      if (i == 0) return true
      else return !this.steps[i - 1].location.locationName || this.steps[i - 1].location.locationName != route.location.locationName
    },
    removePaddingB(i) {
      if (this.steps.length > 1 && i == this.steps.length - 1) return 'pb-0'
      return ''
    },
    sort(list) {
      if (!list.length) return []
      return list.sort(function (a, b) {
        if (a.date > b.date) return 1
        if (a.date < b.date) return -1
        return 0
      })
    },
    formatDate(date) {
      return dateFilter.formatDate(date)
    },
  },
  mounted() {
    this.composeSteps()
  },
}
</script>

<style scoped>
.v-timeline {
  padding-block: 0.5rem;
  white-space: nowrap;
}
.v-timeline::v-deep .v-timeline-item__dot {
  box-shadow: none !important;
}
.v-timeline::before {
  top: 35px;
  height: calc(100% - 70px);
}
.v-timeline::v-deep .v-timeline-item--after{
  display: flex;
  align-items: center;
}
/*
.row {
  border: solid 1px orange;
}
.col {
  border: solid 1px red;
}*/
</style>
