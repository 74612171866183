import { commons } from "./commons";

export const invoicesDetailService = {
  browse,
  getSapInvoiceDetailByDocumentNbr,
};

const resourceUrl =
  process.env.VUE_APP_GSCV_CUSTOMS_ENGINE_URL + "/invoice_details";
const authorizationHeader = process.env.VUE_APP_GSCV_CUSTOMS_ENGINE_BASIC_AUTH_HEADER

function browse(offset, limit, filter, sortingList) {
  if (!sortingList.length) {
    sortingList = [];
    sortingList.push({ column: "id", direction: "ASC" });
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: authorizationHeader,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      offset: offset,
      limit: limit,
      filter: filter,
      sortingList: sortingList,
    }),
  };
  return fetch(resourceUrl + "/browse", requestOptions).then(
    commons.handleJsonResponse
  );
}

function getSapInvoiceDetailByDocumentNbr() {}
