export default {
  data() {
    return {}
  },
  methods: {
    setParty(customer, partyFunction){
      let customerParty = this._.cloneDeep(this.partyTemplate)
      customerParty.partyFunction = partyFunction
      customerParty.isToBeNotified = partyFunction == 'NOTIFY'
      if (customer.customerName){
        let address = this.splitAddress(customer.address)
        customerParty.party.partyName = customer.customerName
        customerParty.party.partySapCode = customer.customerId
        customerParty.party.taxReference1 = null
        customerParty.party.publicKey = null
        customerParty.party.street = address.street
        customerParty.party.streetNumber = address.streetNumber
        customerParty.party.floor = null
        customerParty.party.postCode = customer.postalCode
        customerParty.party.city = customer.city
        customerParty.party.stateRegion = null          
        customerParty.party.country = customer.countryId
        customerParty.party.taxReference2 = null
        customerParty.party.nmftaCode = this.getCarrierCode(customer.carrierCodes)
      }
      // this.createUpdateParty(customerParty, partyFunction)
      return customerParty
    },
    getCarrierCode(customer) {
      if (!customer || !customer.carrierCodes) return
      switch (this.company) {
        case 'MAERSK':
          return customer.carrierCodes.maerskCode
        case 'CMACGM':
          return customer.carrierCodes.cmacgmCode
        case 'YANGMING':
          return customer.carrierCodes.yangmingCode
      }
    },
    createUpdateParty(party, partyFunction) {
      let parties = this.parties.filter((party) => party.partyFunction == partyFunction)
      let index = parties.length ? this.parties.indexOf(parties[0]) : null
      index != null ? this.parties.splice(index, 1, party) : this.parties.push(party)
    },
    splitAddress(address) {
      let splittedAddress = { street: '', number: '' }
      splittedAddress.number = this.extractCivicNumber(address)
      splittedAddress.street = address.replace(splittedAddress.number, '').trim()
      return splittedAddress
    },
    extractCivicNumber(address) {
      let rx = /\d+[ \w]{0,2}/g
      let arr = rx.exec(address)
      return arr && arr.length ? arr[0] : null
    },
  },
}
