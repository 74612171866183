import { authHeader } from '@/helpers/auth-header'
import { commons } from './commons'

export const externalTrackingService = {
  createLink,
  checkValidity,
}

const resourceUrl = process.env.VUE_APP_GSCV_ENGINE_URL + `/externalTracking`

function createLink(documentNbr) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/createLink/${documentNbr}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function checkValidity(uuid) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/checkValidity/${uuid}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}
