var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { "fill-height": "", fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "fill-height ma-0" },
        [
          _c(
            "v-col",
            { staticClass: "pa-0" },
            [
              _c(
                "v-card",
                { staticClass: "d-flex fill-height" },
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "d-flex flex-column pa-2" },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "d-inline-flex" },
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "pa-0" },
                                        [
                                          _c("ReadonlyField", {
                                            attrs: {
                                              label: _vm.$t("label.customer"),
                                              value: _vm.pratica.client
                                                ? _vm.pratica.client
                                                    .customerName
                                                : null,
                                              icon: "mdi-account",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { staticClass: "pa-0" },
                                        [
                                          _c("ReadonlyField", {
                                            attrs: {
                                              label: _vm.$t("label.consignee"),
                                              value: _vm.pratica.consignee
                                                ? _vm.pratica.consignee
                                                    .customerName
                                                : null,
                                              icon: "mdi-account-check",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "d-inline-flex" },
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "pa-0" },
                                        [
                                          _c("ReadonlyField", {
                                            attrs: {
                                              label: _vm.$t("label.userLabel"),
                                              value: _vm.pratica.user
                                                ? _vm.pratica.user.label
                                                : null,
                                              icon: "mdi-account-hard-hat",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { staticClass: "pa-0" },
                                        [
                                          _c("ReadonlyField", {
                                            attrs: {
                                              label: _vm.$t("label.agent"),
                                              value: _vm.pratica.agent
                                                ? _vm.pratica.agent.customerName
                                                : null,
                                              icon: "mdi-account-tie",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "d-inline-flex" },
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "pa-0" },
                                        [
                                          _c("ReadonlyField", {
                                            attrs: {
                                              label: _vm.$t("label.shipper"),
                                              value: _vm.pratica.shipper
                                                ? _vm.pratica.shipper
                                                    .customerName
                                                : null,
                                              icon: "mdi-account-arrow-right",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { staticClass: "pa-0" },
                                        [
                                          _c("ReadonlyField", {
                                            attrs: {
                                              label: _vm.$t(
                                                "label.goodsConsignee"
                                              ),
                                              value: _vm.pratica.goodsConsignee
                                                ? _vm.pratica.goodsConsignee
                                                    .customerName
                                                : null,
                                              icon: "mdi-account-arrow-left",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("v-divider", { staticClass: "my-2" }),
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "d-inline-flex" },
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "pa-0" },
                                        [
                                          _c("ReadonlyField", {
                                            attrs: {
                                              label: _vm.$t(
                                                "label.shipperInvoiceReference"
                                              ),
                                              value:
                                                _vm.pratica
                                                  .shipperInvoiceReference,
                                              icon: "mdi-file-document-edit-outline",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { staticClass: "pa-0" },
                                        [
                                          _c("ReadonlyField", {
                                            attrs: {
                                              label: _vm.$t("label.saleOrder"),
                                              value: _vm.pratica.saleOrder,
                                              icon: "mdi-file-sign",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { staticClass: "pa-0" },
                                        [
                                          _c("ReadonlyField", {
                                            attrs: {
                                              label: _vm.$t(
                                                "label.shipperIncoterms"
                                              ),
                                              value:
                                                _vm.pratica.shipperIncoterms,
                                              icon: "mdi-file-document-outline",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "align-end mt-1",
                              attrs: { dense: "" },
                            },
                            [
                              _c(
                                "v-col",
                                { staticClass: "d-inline-flex" },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "pa-0" },
                                    [
                                      _c("ReadonlyField", {
                                        attrs: {
                                          label: _vm.$t("label.createDate"),
                                          value: _vm.createDate,
                                          icon: "mdi-calendar",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { staticClass: "pa-0" },
                                    [
                                      _c("ReadonlyField", {
                                        attrs: {
                                          label: _vm.$t("label.updateDate"),
                                          value: _vm.updateDate,
                                          icon: "mdi-calendar",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("v-col"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.showCreateBooking && _vm.bookingAuth
                        ? _c(
                            "v-col",
                            {
                              staticClass: "d-flex flex-column px-0 py-3",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary",
                                  attrs: { icon: "", small: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("open-booking")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { size: "17", color: "white" } },
                                    [_vm._v("mdi-calendar-plus")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        { staticClass: "pa-0" },
                        [
                          _c(
                            "PraticaActions",
                            _vm._g(
                              {
                                attrs: {
                                  entity: _vm.praticaActions,
                                  props: _vm.visibleActions,
                                },
                              },
                              _vm.$listeners
                            )
                          ),
                          _c(
                            "v-row",
                            { staticClass: "ma-0" },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      label: "Notes",
                                      rows: "2",
                                      "hide-details": "",
                                      clearable: "",
                                      "auto-grow": "",
                                      outlined: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$emit(
                                          "update-props",
                                          _vm.praticaActions
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.praticaActions.internalNotes,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.praticaActions,
                                          "internalNotes",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "praticaActions.internalNotes",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }