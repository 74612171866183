var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "ma-0", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "pa-0" },
            [
              _c(
                "v-row",
                { staticClass: "ma-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-card",
                        { staticClass: "pa-2" },
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "d-inline-flex" },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "pa-0" },
                                    [
                                      _c("ReadonlyField", {
                                        attrs: {
                                          label: "Controparte dogana",
                                          value: _vm.pratica.customsOperator
                                            ? _vm.pratica.customsOperator
                                                .supplierName
                                            : null,
                                          icon: "mdi-account",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "d-inline-flex" },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "pa-0" },
                                    [
                                      _c("ReadonlyField", {
                                        attrs: {
                                          label: _vm.$t("label.email"),
                                          value:
                                            _vm.customsOperatorDefaultEmail,
                                          icon: "mdi-email",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "ma-0 mt-3" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "ActionsStepperEnum",
                            _vm._g(
                              {
                                attrs: {
                                  entity: _vm.praticaActions,
                                  prop: "customsClearanceStatus",
                                  values: _vm.customsStatus,
                                },
                              },
                              _vm.$listeners
                            )
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.messages.length
                ? _c(
                    "v-row",
                    { staticClass: "ma-0 mt-3" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0" },
                        _vm._l(_vm.messages, function (conversation, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              class:
                                _vm.messages.length - 1 == index
                                  ? "mb-0"
                                  : "mb-3",
                            },
                            [
                              _c("MailPanel", {
                                ref: "mailPanel",
                                refInFor: true,
                                attrs: { conversation: conversation },
                                on: { "email-sent": _vm.handleEmailSent },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "ml-3 px-0", attrs: { cols: "auto" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "primary",
                  attrs: { fab: "", icon: "", height: "50", width: "50" },
                  on: {
                    click: function ($event) {
                      _vm.panel = null
                      _vm.loadNewEmailDogana()
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { color: "white", size: "30" } }, [
                    _vm._v("mdi-email"),
                  ]),
                ],
                1
              ),
              _c("EmailDialog", {
                ref: "emailDialog",
                attrs: {
                  msgTemplate: _vm.emailTemplate,
                  emailAddresses: _vm.customsOperatorContacts,
                },
                on: { "email-sent": _vm.handleEmailSent },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }