<template>
  <v-card class="my-1" outlined>
    <v-row dense class="ma-0 pratica" @click="addToRowSelection">
      <v-col cols="1" class="py-0 d-flex justify-center align-center">
        <v-row dense class="ma-0 d-flex justify-center">
          <v-icon v-if="pratiche.existsMatch" color="success" size="x-large"
            >mdi-check-network</v-icon
          >
        </v-row>
      </v-col>

      <!--Document nbr.-->
      <v-col
        cols="2"
        class="py-0 text-caption font-weight-bold d-flex align-center"
      >
        <v-row dense class="ma-0 d-flex justify-start text-center">
          <ReadonlyField
            :label="$t('label.documentNbr')"
            :value="pratiche.documentNbr"
          />
        </v-row>
      </v-col>
      <v-divider vertical />

      <!--Customer-->
      <v-col
        cols="2"
        class="py-0 text-caption font-weight-bold d-flex align-center"
        ><v-row dense class="ma-0 d-flex justify-center text-center">
          <ReadonlyField
            :label="$t('label.customer')"
            :value="pratiche.clientName"
        /></v-row>
      </v-col>
      <v-divider vertical />

      <!--Shipper-->
      <v-col
        cols="2"
        class="py-0 text-caption font-weight-bold d-flex align-center"
        ><v-row dense class="ma-0 d-flex justify-center text-center">
          <ReadonlyField
            :label="$t('label.shipper')"
            :value="pratiche.shipperName"
        /></v-row>
      </v-col>
      <v-divider vertical />

      <!--Consignee-->
      <v-col
        cols="2"
        class="py-0 text-caption font-weight-bold d-flex align-center"
        ><v-row dense class="ma-0 d-flex justify-center text-center">
          <ReadonlyField
            :label="$t('label.consigneeName')"
            :value="pratiche.consigneeName"
        /></v-row>
      </v-col>
      <v-divider vertical />

      <!--Pol/Pod-->
      <v-col
        cols="1"
        class="py-0 text-caption font-weight-bold d-flex align-center"
        ><v-row dense class="ma-0 d-flex justify-center text-center">
          <ReadonlyField
            :label="$t('label.pol') + '/' + $t('label.pod')"
            :value="pratiche.pol + '/\n' + pratiche.pod"
        /></v-row>
      </v-col>
      <v-divider vertical />

      <!--Document Date-->
      <v-col
        cols="2"
        class="py-0 text-caption font-weight-bold d-flex align-center"
        ><v-row dense class="ma-0 d-flex justify-center text-center">
          <ReadonlyField
            :label="$t('label.documentDate')"
            :value="pratiche.documentDate"
        /></v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ReadonlyField from "./ReadonlyField.vue";

export default {
  data() {
    return {};
  },
  props: ["pratiche", "selectedInvoiceDocumentNbr"],
  components: { ReadonlyField },
  emits: ["document-nbr"],
  watch: {
    "pratiche.existsMatch"() {
      this.$emit(
        "document-nbr",
        this.pratiche.documentNbr,
        this.pratiche.existsMatch
      );
    },
  },
  methods: {
    addToRowSelection() {
      this.pratiche.existsMatch = !this.pratiche.existsMatch;
    },
  },
};
</script>

<style>
.pratica:hover {
  background-color: #edf5ff;
}
</style>
