var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "fit-content" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _vm.label
                ? _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        { attrs: { color: _vm.color, small: "", dark: "" } },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _vm._v(" " + _vm._s(_vm.label) + " "),
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v(_vm._s(_vm.icon)),
                      ]),
                    ],
                    1
                  )
                : _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            color: _vm.color,
                            elevation: "0",
                            height: "50",
                            width: "50",
                            fab: "",
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v(_vm._s(_vm.icon))])],
                    1
                  ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "primary white--text text-caption py-2 px-3" },
            [_vm._v(" " + _vm._s(_vm.title) + " ")]
          ),
          _c("v-card-text", { staticClass: "pa-10" }, [
            _vm._v(" " + _vm._s(_vm.body) + " "),
          ]),
          _c(
            "v-card-actions",
            { staticClass: "justify-start px-2" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "primary",
                  attrs: { small: "" },
                  on: {
                    click: function ($event) {
                      _vm.$emit("submit")
                      _vm.dialog = false
                    },
                  },
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t("label.confirm")))])]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "error",
                  attrs: { small: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t("label.close")))])]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }