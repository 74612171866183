<template>
<v-container>
  <v-dialog v-model="showInsertAgent" width="fit-content">
    <v-card>
      <v-card-title class="pa-6" />
      <v-divider class="ma-0 mb-0" />
      <v-card-text class="pa-12">
        <v-container>
          <v-row>
            <v-col>
              <span>{{ $t('message.selectForeignAgentCode') }}</span>
            </v-col>
          </v-row> 
          <v-row>
            <v-col>
              <v-autocomplete
                ref="foreignAgentSelected"
                v-model="foreignAgentNonRevenueSelected"
                :item-text="(agent) => `${agent.name} ${agent.sapCode} - ${agent.destination}`"
                :items="foreignAgentsNonRevenue"
                return-object
              > 
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ data.item.name }} -
                      {{ data.item.destination }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span>
                        {{ data.item.sapCode }}
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider class="ma-0 mt-0" />
        <v-card-actions class="d-flex justify-end pa-3">
          <v-btn small class="secondary pa-1" @click="confirmSelectedNonRevenue">
            <span>{{ $t('label.confirm') }}</span>
          </v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>

  <EmailDialog
    ref="emailDialog"
    :msgTemplate="emailTemplate"
    :emailAddresses="foreignAgentContacts"
    @email-sent="handleNewMail"
  />
</v-container>
</template>

<script>
import ForeignAgentContacts from '@/mixins/ForeignAgentContacts'
import { foreignAgentContactService } from '@/services/foreignAgentContact.service.js'
import EmailDialog from '@/components/elements/mails/EmailDialog.vue'

export default {
  name: 'ForeignAgentEmail',
  mixins: [ForeignAgentContacts],
  emits: [],
  components: { EmailDialog },
  props: {
    rowsSelection: {
      type: Array,
      default: () => []
    },
    pratiche: {
      type: Array,
    }
  },
  data() {
    return {
      emailTemplate: {},
      showInsertAgent: false,
      foreignAgents: [],
      isUnset: false,
      isNonRevenue: false
    }
  },
  methods: {
    insertAgent() {
      this.loadForeignAgentsNonRevenue().then(() => {
        this.showInsertAgent = true
      })
      .finally(() => this.setLoading(false))
    },
    async checkForeignAgent() {
      this.setLoading(true)
      this.foreignAgents = []
      for (let pratica of this.pratiche) {
        let foreignAgent = await this.getForeignAgentForPratica(pratica.id)
        if (foreignAgent) 
          this.foreignAgents.push(foreignAgent)
      }

      let currentForeignAgent = null
      if (this.checkForeignAgentEqualForAll()) {
        await this.loadForeignAgentContacts(this.pratiche[0]?.id)
        currentForeignAgent = this.foreignAgents[0]
      }

      if ((!this.isUnset && !this.isNonRevenue) || (this.isNonRevenue && currentForeignAgent)) {
        this.loadNewEmail()
      } else {
        this.insertAgent()
      }
    },
    checkForeignAgentEqualForAll() {
      
      this.isUnset = this.foreignAgents.every(a => a.foreignAgentNonRevenueId == null && a.foreignAgentId == null)
      if (this.isUnset) {
        return true
      } else {
        this.foreignAgents.forEach(a => {
          if (a.foreignAgentNonRevenueId) this.isNonRevenue = true
        });
      }
      
      if (this.isNonRevenue) {
        return !!this.foreignAgents.reduce(function(a, b){ return (a.foreignAgentNonRevenueId == b.foreignAgentNonRevenueId && a.destination == b.destination) ? a : NaN; })
      } else {
        return !!this.foreignAgents.reduce(function(a, b){ return (a.foreignAgentId == b.foreignAgentId) ? a : NaN; })
      }
    },
    confirmSelectedNonRevenue() {
      this.showInsertAgent = false
      this.setLoading(true)
      this.saveForeignAgent()
    },
    saveForeignAgent() {
      let faNonRevenue = this.foreignAgentNonRevenueSelected
      this.updateAllPraticaForeignAgent(faNonRevenue)
      this.$refs.foreignAgentSelected.reset()
    },
    async updateAllPraticaForeignAgent(faNonRevenue) {
      for (let foreignAgent of this.foreignAgents) {
        await this.updateForeignAgentNonRevenue(foreignAgent, faNonRevenue)
      }
      await this.loadForeignAgentContacts(this.pratiche[0]?.id)
      this.loadNewEmail()
    },
    loadNewEmail() {
      this.loadEmailTemplate().then(() => {
        this.$refs.emailDialog.openDialog(true)
        this.setLoading(false)
      })
    },
    loadEmailTemplate() {
      let ids = []
      this.rowsSelection.forEach((pratica) => {
        ids.push(pratica.id)
      })
      return foreignAgentContactService
        .getMultipleDraftEmail(ids)
        .then((resp) => {
          this.emailTemplate = resp
          this.emailTemplate.text = this.emailTemplate.text.concat(`<p>${this.userCompleteName}</p>`)
        })
    },
    handleNewMail(resp) {
      this.$refs.emailDialog.openDialog(!resp.sent)
    },
  },
}
</script>
