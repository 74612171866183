<template>
  <v-container class="pa-2" fluid>
    <!-- py-5 px-7 -->
    <v-row dense>
      <v-col class="d-flex">
        <v-col class="pa-0">
          <ReadonlyField
            :label="$t('label.roadHauler')"
            :value="transportOrder.carrierLabel"
            :icon="'mdi-transit-connection-horizontal'"
          />
        </v-col>
      </v-col>
      <v-col class="d-flex">
        <v-col class="pa-0">
          <ReadonlyField
            :label="$t('label.pickupDate')"
            :value="formatDateDay(transportOrder.pickupDateActual)"
            :icon="'mdi-calendar'"
          />
        </v-col>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col class="d-flex">
        <v-col class="pa-0">
          <ReadonlyField
            :label="$t('label.roadHaulerCode')"
            :value="transportOrder.carrierId"
            :icon="'mdi-account'"
          />
        </v-col>
        <v-col class="pa-0">
          <ReadonlyField
            :label="$t('label.truckPlate')"
            :value="transportOrder.vehicleId"
            :icon="'mdi-truck'"
          />
        </v-col>
      </v-col>
      <v-col class="d-flex">
        <v-col class="pa-0">
          <ReadonlyField
            :label="$t('label.deliveryDate')"
            :value="formatDateDay(transportOrder.deliveryDateActual)"
            :icon="'mdi-calendar'"
          />
        </v-col>
      </v-col>
    </v-row>
    <v-divider />
    <v-row dense>
      <v-col class="d-flex">
        <v-col class="pa-0">
          <ReadonlyField
            :label="$t('label.pickupAddress')"
            :value="pickupAddress"
            :icon="'mdi-map-marker'"
          />
        </v-col>
        <v-col class="pa-0">
          <ReadonlyField
            :label="$t('label.deliveryAddress')"
            :value="deliveryAddress"
            :icon="'mdi-map-marker'"
          />
        </v-col>
      </v-col>
      <v-col class="d-flex">
        <v-col class="pa-0">
          <ReadonlyField
            :label="$t('label.empty')"
            :value="full"
            :icon="'mdi-map-marker'"
          />
        </v-col>
        <v-col class="pa-0">
          <ReadonlyField
            :label="$t('label.full')"
            :value="empty"
            :icon="'mdi-map-marker'"
          />
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { dateFilter } from '@/filters/dateFilter'
import ReadonlyField from '@/components/elements/ReadonlyField.vue'

export default {
  name: 'PraticaAutotrasportoDettagli',
  props: ['pratica', 'transportOrder'],
  components: { ReadonlyField },
  computed: {
    pickupAddress() {
      return this.splitByComma(this.transportOrder.pickupAddress)
    },
    deliveryAddress() {
      return this.splitByComma(this.transportOrder.deliveryAddress)
    },
    full() {
      return this.splitByComma(this.transportOrder.pickupSupplierIdLadenLabel)
    },
    empty() {
      return this.splitByComma(this.transportOrder.pickupSupplierIdEmptyLabel)
    },
  },
  methods: {
    splitByComma(value) {
      if (!value) return
      let res = ''
      value.split(',').forEach((el) => {
        if (el.length) res += `${el}\n`
      })
      return res
    },
    formatDateDay(value) {
      return dateFilter.formatDateDay(value)
    },
  },
}
</script>
