import { authHeader } from '@/helpers/auth-header'
import { commons } from './commons'

export const userParametersService = {
  getAllByUser,
  getUserParameter,
  createUserParameter,
  updateUserParameter,
  deleteUserParameter,
}

const resourceUrl = process.env.VUE_APP_GSCV_ENGINE_URL + `/user-parameters`

function getAllByUser(userId) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }
  return fetch(resourceUrl + `/user/${userId}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function getUserParameter(userId, key) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }
  return fetch(
    resourceUrl + `?userId=${userId}&key=${key}`,
    requestOptions,
  ).then(commons.handleJsonResponse)
}

function createUserParameter(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }
  return fetch(resourceUrl, requestOptions).then(commons.handleJsonResponse)
}

function updateUserParameter(id, data) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }
  return fetch(resourceUrl + `/${id}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function deleteUserParameter(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/${id}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}
