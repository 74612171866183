<template>
  <v-flex shrink>
    <v-card class="shadow-none" outlined>
      <v-card-text class="pa-0">
        <v-row class="my-1" dense>
          <v-col cols="2">
            <v-text-field
              :value="
                filterSelection.sellerName
                  ? filterSelection.sellerName.text
                  : ''
              "
              :label="$t('label.sellerName')"
              ref="sellerName"
              @change="(v) => onFieldChange(v, 'sellerName', 'text', 'value')"
              @keypress.enter="setFilter"
              :menu-props="menuProps"
              hide-details
              clearable
              @click:clear="onClear('sellerName')"
              dense
              class="py-1 pl-2 custom-text-field"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              :value="
                filterSelection.invoiceHolderName
                  ? filterSelection.invoiceHolderName.text
                  : ''
              "
              :label="$t('label.invoiceHolderName')"
              ref="invoiceHolderName"
              @change="
                (v) => onFieldChange(v, 'invoiceHolderName', 'text', 'value')
              "
              @keypress.enter="setFilter"
              :menu-props="menuProps"
              hide-details
              clearable
              @click:clear="onClear('invoiceHolderName')"
              dense
              class="pa-1 custom-text-field"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              :value="
                filterSelection.consigneeName
                  ? filterSelection.consigneeName.text
                  : ''
              "
              :label="$t('label.consigneeName')"
              ref="consigneeName"
              @change="
                (v) => onFieldChange(v, 'consigneeName', 'text', 'value')
              "
              @keypress.enter="setFilter"
              :menu-props="menuProps"
              hide-details
              clearable
              @click:clear="onClear('consigneeName')"
              dense
              class="pa-1 custom-text-field"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              :value="
                filterSelection.invoiceNbr
                  ? filterSelection.invoiceNbr.text
                  : ''
              "
              :label="$t('label.invoiceNbr')"
              ref="invoiceNbr"
              @change="(v) => onFieldChange(v, 'invoiceNbr', 'text', 'value')"
              @keypress.enter="setFilter"
              :menu-props="menuProps"
              hide-details
              clearable
              @click:clear="onClear('invoiceNbr')"
              dense
              class="pa-1 custom-text-field"
            />
          </v-col>
          <v-col cols="3">
            <v-select
              :value="filterSelection.shipmentNbr"
              :label="$t('label.shipmentNbr')"
              ref="shipmentNbr"
              :items="['Already Matched']"
              @change="
                (v) =>
                  onFieldChange(v, 'shipmentNbrNotMatched', 'value', 'text')
              "
              return-object
              :menu-props="menuProps"
              hide-details
              clearable
              @click:clear="onClear('shipmentNbrNotMatched')"
              dense
              class="py-1 pr-2 custom-text-field"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-flex>
</template>

<script>
export default {
  data() {
    return {
      filterSelection: {},

      browseFilter: {},

      menuProps: {
        offsetY: true,
        overflowY: "auto",
        maxHeight: "10rem",
      },
    };
  },
  emits: ["set-filter"],
  name: "InvoiceFilter",
  methods: {
    resetAllFilters() {
      this.resetFilters();
      this.setFilter();
    },
    resetFilters() {
      this.resetInputs();
      this.filterSelection = {};
      this.browseFilter = {};
    },
    resetInputs() {
      if (this.isEmpty(this.$refs)) return;
      this.$refs.sellerName.reset();
      this.$refs.invoiceHolderName.reset();
      this.$refs.consigneeName.reset();
      this.$refs.invoiceNbr.reset();
      this.$refs.shipmentNbr.reset();
    },
    isEmpty(object) {
      return Object.keys(object).length === 0;
    },
    setFilter() {
      this.$emit(
        "set-filter",
        this.convertFilter(this.browseFilter),
        this.$options.name
      );
    },
    convertFilter(filter) {
      let convertedFilter = {};
      Object.keys(filter).forEach((key) => {
        if (Array.isArray(filter[key]))
          convertedFilter[key] = this.convertArrayToStringSplitted(filter[key]);
      });
      return convertedFilter;
    },
    convertArrayToStringSplitted(list) {
      let arrayToStringSplitted = "";
      list.forEach((element) => {
        let isLastElement = list.indexOf(element) == list.length - 1;
        arrayToStringSplitted += element += !isLastElement ? ", " : "";
      });
      return arrayToStringSplitted;
    },
    onFieldChange(input, field, value, text) {
      if (!input) {
        delete this.filterSelection[field];
        delete this.browseFilter[field];
        return;
      }
      this.filterSelection[field] = {};
      this.browseFilter[field] = [];
      switch (true) {
        case Array.isArray(input[text]): // input is text: [] value: []
          this.filterSelection[field].text = input[text];
          this.filterSelection[field].value = input[value];
          if (!this.filterSelection[field].value.length)
            delete this.filterSelection[field];

          this.browseFilter[field] = input[value];
          if (!this.browseFilter[field].length) delete this.browseFilter[field];

          break;
        case Array.isArray(input): // input is []
          this.filterSelection[field] = [];
          if (!input.length) {
            delete this.browseFilter[field];
          }
          input.forEach((element) => {
            let item = {
              text: element[text],
              value: element[value],
            };
            this.filterSelection[field].push(item);
            this.browseFilter[field].push(element[value]);
          });
          break;
        case typeof input === "string": // input is String
          this.filterSelection[field][text] = input;
          this.filterSelection[field][value] = input;

          if (field == "shipmentNbrNotMatched") {
            this.browseFilter[field].push("false");
          } else {
            this.browseFilter[field].push(input);
          }
          break;
        default:
          // input is single value
          this.filterSelection[field][text] = input[text];
          this.filterSelection[field][value] = input[value];
          this.browseFilter[field].push(input[value]);
          break;
      }
      if (field == "shipmentNbrNotMatched") {
        this.setFilter();
      }
    },
    onClear(field) {
      delete this.filterSelection[field];
      if (field == "shipmentNbrNotMatched") {
        this.browseFilter[field] = ["true"];
      } else {
        delete this.browseFilter[field];
      }

      this.setFilter();
    },
  },
};
</script>

<style>
.custom-text-field .v-input__control input {
  font-size: 12px;
}

.custom-text-field .v-input__control label {
  font-size: 12px;
}
</style>
