<template>
  <v-dialog v-model="dialog" width="30%">
    <template v-slot:activator="{ on }">
      <v-text-field
        v-on="on"
        :label="label"
        :value="contactLabel"
        :rules="rules"
        class="xs-input"
        validate-on-blur
        outlined
        v-if="showField"
      />
      <v-textarea
        v-on="on"
        :label="label"
        :value="contactLabel"
        :rules="rules"
        rows="3"
        validate-on-blur
        auto-grow
        outlined
        v-else
      />
    </template>
    <v-card>
      <v-card-title class="text-overline primary--text pa-2 px-3">
        {{ title }}
      </v-card-title>
      <v-divider class="ma-0" />
      <v-card-text class="pa-0">
        <v-container>
          <v-form ref="form">
            <v-row dense>
              <v-col class="pb-0">
                <v-text-field
                  :label="$t('label.nameAndSurname')"
                  v-model="contactDetails.name"
                  :rules="required"
                  class="xs-input"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="pb-0">
                <v-text-field
                  :label="$t('label.email')"
                  v-model="contactDetails.email"
                  :rules="required"
                  class="xs-input"
                  clearable
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="pb-0">
                <v-text-field
                  :label="$t('label.phone')"
                  v-model="contactDetails.phone"
                  :rules="required"
                  :hint="phoneNbrFormat"
                  class="xs-input"
                  clearable
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ContactDialog',
  props: {
    title: {
      type: String,
    },
    label: {
      type: String,
    },
    rules: {
      type: Array,
    },
    contact: {
      type: Object,
      default: () => {},
    },
    showField: {
      type: String,
    },
  },
  emits: ['set-contact'],
  components: {},
  data() {
    return {
      dialog: false,
      phoneNbrFormat: '+0(0) 000-000(0)-0000',
      contactDetails: {},
      contactLabel: null,
    }
  },
  computed: {
    required() {
      return [(v) => !!v || this.$t('message.required')]
    },
  },
  watch: {
    contactDetails: {
      handler() {
        this.getLabel()
        this.setContact()
      },
      deep: true,
    },
  },
  methods: {
    init() {
      this.$nextTick(() => {
        if (this.contact) this.contactDetails = this.contact
        this.getLabel()
        this.setContact()
      })
    },
    getLabel() {
      if (this.showField) return this.contactLabel = this.contactDetails[this.showField]
      let label = ``
      label += `${this.contactDetails.name ? this.contactDetails.name : ''}\n`
      label += `${this.contactDetails.email ? this.contactDetails.email : ''}\n`
      label += `${this.contactDetails.phone ? this.contactDetails.phone : ''}`
      this.contactLabel = label
    },
    setContact() {
      this.$emit('set-contact', this.contactDetails)
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>
.v-textarea::v-deep textarea {
  line-height: 1.2 !important;
  max-height: fit-content;
  height: fit-content !important;
  margin-bottom: 0.5rem;
}
.v-textarea::v-deep .v-input__slot {
  margin-bottom: 4px !important;
}
.v-input::v-deep .v-text-field__details {
  margin: 0 !important;
}
.xs-input::v-deep input {
  padding: 0.5rem !important;
  padding-left: 0 !important;
}
.xs-input::v-deep .v-label {
  position: unset !important;
  min-width: max-content;
  margin-bottom: 0 !important;
}
.xs-input::v-deep .v-input__slot {
  min-height: 0 !important;
  padding-right: 4px !important;
}
.xs-input::v-deep .v-input__append-inner {
  margin: 0 !important;
  align-self: center !important;
}
.xs-input::v-deep .v-label--active {
  position: absolute !important;
  margin-bottom: 0 !important;
}
.v-textarea::v-deep label {
  font-weight: normal;
}
/*.row {
  border: solid 1px red;
}
.col {
  border: solid 1px orange;
}*/
</style>
