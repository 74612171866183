var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "d-block pa-0", attrs: { "fill-height": "", fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "ma-0" },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center py-0",
              attrs: { cols: "auto" },
            },
            [
              _c(
                "v-subheader",
                { staticClass: "pa-0 primary--text text-overline" },
                [_vm._v(" Container ")]
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "d-flex align-center py-0 pl-0" },
            [_c("v-divider", { staticClass: "my-0" })],
            1
          ),
          !_vm.disabled
            ? _c(
                "v-col",
                {
                  staticClass: "d-flex align-center py-0",
                  attrs: { cols: "auto" },
                },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.addContainer },
                    },
                    [_vm._v(" mdi-plus-circle ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "ma-0" },
        [
          _c(
            "v-col",
            { staticClass: "pa-0" },
            [
              _c(
                "v-list",
                _vm._l(_vm.containers, function (container, i) {
                  return _c(
                    "v-list-item",
                    { key: i, staticClass: "d-block px-0 mt-1" },
                    [
                      [
                        _c(
                          "v-row",
                          { staticClass: "ma-0" },
                          [
                            _c(
                              "v-col",
                              { staticClass: "py-0" },
                              [
                                _c("v-autocomplete", {
                                  staticClass: "xs-input",
                                  attrs: {
                                    label: _vm.$t("label.containerType"),
                                    value: _vm.getContainerCodeObj(container),
                                    items: _vm.containerCodes,
                                    "menu-props": _vm.menuProps,
                                    "item-text": _vm.getContainerTypeText,
                                    readonly: _vm.disabled,
                                    rules: _vm.required,
                                    "validate-on-blur": "",
                                    "return-object": "",
                                    outlined: "",
                                    attach: "",
                                    dense: "",
                                  },
                                  on: {
                                    change: (containerCode) =>
                                      _vm.setContainerInfo(
                                        container,
                                        containerCode
                                      ),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "item",
                                        fn: function (data) {
                                          return [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(data.item.code) +
                                                      " "
                                                  ),
                                                ]),
                                                _c("v-list-item-subtitle", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        data.item.isoCode
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { staticClass: "py-0", attrs: { cols: "auto" } },
                              [
                                _c("v-text-field", {
                                  staticClass: "number-select xs-input",
                                  attrs: {
                                    label: _vm.$t("label.code"),
                                    readonly: _vm.disabled,
                                    rules: _vm.required,
                                    outlined: "",
                                    dense: "",
                                  },
                                  on: {
                                    input: (v) =>
                                      (container.requestedEquipments.ISOEquipmentCode =
                                        v.toUpperCase()),
                                  },
                                  model: {
                                    value:
                                      container.requestedEquipments
                                        .ISOEquipmentCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        container.requestedEquipments,
                                        "ISOEquipmentCode",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "container.requestedEquipments.ISOEquipmentCode",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex py-0",
                                attrs: { cols: "auto" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      slot: "prepend",
                                      icon: "",
                                      disabled: _vm.disabled,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editContainerQuantity(
                                          container,
                                          false
                                        )
                                      },
                                    },
                                    slot: "prepend",
                                  },
                                  [_c("v-icon", [_vm._v(" mdi-minus ")])],
                                  1
                                ),
                                _c("v-text-field", {
                                  staticClass: "number-select xs-input",
                                  attrs: {
                                    type: "number",
                                    readonly: _vm.disabled,
                                    rules: _vm.required,
                                    "hide-spin-buttons": "",
                                    outlined: "",
                                    dense: "",
                                  },
                                  model: {
                                    value: container.requestedEquipments.units,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        container.requestedEquipments,
                                        "units",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "container.requestedEquipments.units",
                                  },
                                }),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      slot: "append",
                                      icon: "",
                                      disabled: _vm.disabled,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editContainerQuantity(
                                          container,
                                          true
                                        )
                                      },
                                    },
                                    slot: "append",
                                  },
                                  [_c("v-icon", [_vm._v(" mdi-plus ")])],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { staticClass: "py-0", attrs: { cols: "auto" } },
                              [
                                _c("v-text-field", {
                                  staticClass: "weight-select xs-input",
                                  attrs: {
                                    label: _vm.$t("label.totWeightKgm"),
                                    type: "number",
                                    readonly: _vm.disabled,
                                    rules: _vm.required,
                                    "hide-spin-buttons": "",
                                    "validate-on-blur": "",
                                    outlined: "",
                                    dense: "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.setWeightUOM(
                                        container.requestedEquipments
                                      )
                                    },
                                  },
                                  model: {
                                    value:
                                      container.requestedEquipments
                                        .equipmentWeight,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        container.requestedEquipments,
                                        "equipmentWeight",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression:
                                      "container.requestedEquipments.equipmentWeight",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { staticClass: "py-0", attrs: { cols: "auto" } },
                              [
                                _c("v-text-field", {
                                  staticClass: "weight-select xs-input",
                                  attrs: {
                                    label: _vm.$t("label.totVolumeMtq"),
                                    type: "number",
                                    readonly: _vm.disabled,
                                    "hide-spin-buttons": "",
                                    outlined: "",
                                    dense: "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.setVolumeUOM(
                                        container.requestedEquipments
                                      )
                                    },
                                  },
                                  model: {
                                    value:
                                      container.requestedEquipments
                                        .equipmentVolume,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        container.requestedEquipments,
                                        "equipmentVolume",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression:
                                      "container.requestedEquipments.equipmentVolume",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex align-center pt-0 pb-4",
                                attrs: { cols: "auto" },
                              },
                              [
                                _c("v-checkbox", {
                                  staticClass: "dangerous-check ma-0 pa-0",
                                  attrs: {
                                    readonly: _vm.disabled,
                                    "hide-details": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _c(
                                              "span",
                                              { staticClass: "text-caption" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "label.shipperOwnedContainer"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value:
                                      container.requestedEquipments
                                        .isShipperOwned,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        container.requestedEquipments,
                                        "isShipperOwned",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "container.requestedEquipments.isShipperOwned",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex align-center pt-0 pb-4",
                                attrs: { cols: "auto" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "primary--text px-2",
                                    attrs: {
                                      small: "",
                                      outlined: "",
                                      depressed: "",
                                      disabled: _vm.disabled,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeContainer(i)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-bold" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("label.remove")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "ml-1",
                                        attrs: { size: "20" },
                                      },
                                      [_vm._v(" mdi-delete ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                    ],
                    2
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }