var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "d-block pa-0", attrs: { "fill-height": "", fluid: "" } },
    [
      _vm.internalVisibilityAuth && _vm.kpiAuth
        ? _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                [
                  _c("KpiCard", {
                    attrs: {
                      title: _vm.$t("label.airPracticesStatus"),
                      elements: [
                        {
                          name: _vm.$t("label.toProcess"),
                          value: _vm.praticheAereoRedCount,
                        },
                        {
                          name: _vm.$t("label.waitingFeedback"),
                          value: _vm.praticheAereoYellowCount,
                        },
                        {
                          name: _vm.$t("label.processed"),
                          value: _vm.praticheAereoGreenCount,
                        },
                      ],
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("KpiCard", {
                    attrs: {
                      title: _vm.$t("label.seaPracticesStatus"),
                      elements: [
                        {
                          name: _vm.$t("label.toProcess"),
                          value: _vm.praticheMareRedCount,
                        },
                        {
                          name: _vm.$t("label.waitingFeedback"),
                          value: _vm.praticheMareYellowCount,
                        },
                        {
                          name: _vm.$t("label.processed"),
                          value: _vm.praticheMareGreenCount,
                        },
                      ],
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("KpiCard", {
                    attrs: {
                      title: _vm.$t("label.practicesStatus"),
                      elements: [
                        {
                          name: _vm.$t("label.airPractices"),
                          value: _vm.praticheAereoLast12MonthsCount,
                        },
                        {
                          name: _vm.$t("label.seaPractices"),
                          value: _vm.praticheMareLast12MonthsCount,
                        },
                      ],
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.filtersPanel == 0
        ? _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-expansion-panels",
                    {
                      model: {
                        value: _vm.filtersPanel,
                        callback: function ($$v) {
                          _vm.filtersPanel = $$v
                        },
                        expression: "filtersPanel",
                      },
                    },
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-content",
                            { staticClass: "pa-0" },
                            [
                              _c("FiltersCard", {
                                ref: "filtersCard",
                                on: { "set-filter": _vm.filter },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.internalVisibilityAuth ||
      (!_vm.internalVisibilityAuth &&
        (!_vm.objIsEmpty(_vm.browseFilter) || !_vm.objIsEmpty(_vm.sortBy)))
        ? _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              !_vm.objIsEmpty(_vm.browseFilter) || _vm.search
                ? _c(
                    "v-col",
                    {
                      staticClass: "d-flex flex-column py-0",
                      attrs: { cols: "9" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c(
                            "v-chip",
                            {
                              staticClass: "mt-1 mr-1",
                              attrs: {
                                color: "secondary",
                                small: "",
                                dark: "",
                              },
                            },
                            [
                              _c(
                                "v-avatar",
                                { attrs: { left: "" } },
                                [
                                  _c("v-icon", { attrs: { size: "18" } }, [
                                    _vm._v("mdi-filter-check"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("label.activeFilters")) +
                                  " "
                              ),
                            ],
                            1
                          ),
                          _vm.search
                            ? _c(
                                "v-chip",
                                {
                                  staticClass: "mt-1 mr-1 px-3",
                                  attrs: {
                                    color: "secondary",
                                    small: "",
                                    dark: "",
                                  },
                                },
                                [
                                  _c(
                                    "v-avatar",
                                    { attrs: { left: "" } },
                                    [
                                      _c("v-icon", { attrs: { size: "18" } }, [
                                        _vm._v("mdi-magnify"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c("span", { staticClass: "search-chip" }, [
                                    _vm._v(" " + _vm._s(_vm.search) + " "),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.search
                            ? _c(
                                "div",
                                { staticStyle: { "max-width": "max-content" } },
                                _vm._l(
                                  Object.keys(_vm.visualFilter),
                                  function (key, i) {
                                    return _c(
                                      "v-chip",
                                      {
                                        key: i,
                                        staticClass: "mr-1 mt-1 px-2",
                                        attrs: {
                                          color: "secondary",
                                          outlined: "",
                                          small: "",
                                          dark: "",
                                        },
                                      },
                                      [
                                        _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.formatFilterChip(
                                                key,
                                                _vm.visualFilter[key]
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _c("v-spacer"),
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-end align-start px-0",
                  attrs: { cols: "3" },
                },
                [
                  _vm._l(Object.keys(_vm.sortBy), function (key, i) {
                    return _c(
                      "v-chip",
                      {
                        key: i,
                        staticClass: "mr-1",
                        attrs: {
                          color: "secondary",
                          outlined: "",
                          small: "",
                          dark: "",
                        },
                        on: { click: _vm.resetSortBy },
                      },
                      [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.formatSortByChip(_vm.sortBy[key])
                            ),
                          },
                        }),
                      ]
                    )
                  }),
                  _vm.internalVisibilityAuth
                    ? _c("FiltersSection", {
                        ref: "filtersSection",
                        on: {
                          "sort-pratiche": _vm.sort,
                          "set-filter": _vm.filter,
                          "reset-filter": _vm.resetAllFilters,
                          "open-filters": _vm.handleFiltersPanel,
                        },
                      })
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-data-table", {
                staticClass: "cards-table",
                attrs: {
                  headers: [],
                  items: _vm.browseResult,
                  "items-per-page": _vm.itemsPerPage,
                  "hide-default-footer": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function ({ item, index }) {
                      return [
                        _c("PraticaRow", {
                          class: _vm.selectedRow == index ? "selected-row" : "",
                          attrs: {
                            pratica: item,
                            currentSelection: _vm.rowsSelection,
                            showSelection: _vm.showSelectableTable,
                          },
                          on: {
                            "select-row": function ($event) {
                              return _vm.saveSelectedRow(index)
                            },
                            "add-to-selection": function ($event) {
                              return _vm.selectItem(item)
                            },
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "top",
                    fn: function () {
                      return [
                        _c(
                          "v-row",
                          { staticClass: "ma-0", attrs: { dense: "" } },
                          [
                            _c(
                              "v-col",
                              { staticClass: "d-flex align-center pa-0" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold ml-1" },
                                  [_vm._v(_vm._s(_vm.browseCount))]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "text-caption ml-1" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("label.resultsFound")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              { staticClass: "pa-0" },
                              [
                                _c("TableNav", {
                                  ref: "tableNav",
                                  attrs: {
                                    "browse-count": _vm.browseCount,
                                    "browse-limit": _vm.browseLimit,
                                    "browse-offset": _vm.browseOffset,
                                  },
                                  on: {
                                    "offset-changed": _vm.offsetChanged,
                                    "get-current-page": _vm.setCurrent,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { staticClass: "d-flex align-center pa-0" },
                              [
                                _c("v-spacer"),
                                _vm.internalVisibilityAuth
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "d-flex align-center pa-0",
                                      },
                                      [
                                        _c("v-select", {
                                          staticClass:
                                            "actions-select text-caption mx-1",
                                          staticStyle: { "max-width": "10rem" },
                                          attrs: {
                                            items: _vm.dashboardActions,
                                            "menu-props": {
                                              offsetY: true,
                                              contentClass: "mx-1",
                                            },
                                            "hide-details": "",
                                            clearable: "",
                                            outlined: "",
                                            "x-small": "",
                                            dense: "",
                                          },
                                          model: {
                                            value: _vm.currentAction,
                                            callback: function ($$v) {
                                              _vm.currentAction = $$v
                                            },
                                            expression: "currentAction",
                                          },
                                        }),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "px-0",
                                            attrs: {
                                              disabled:
                                                !_vm.rowsSelection.length,
                                              color: "primary",
                                              icon: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.doAction(
                                                  _vm.currentAction
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { size: "30" } },
                                              [_vm._v(" mdi-play-circle ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.internalVisibilityAuth == false
                                  ? _c("FiltersSection", {
                                      ref: "filtersSection",
                                      on: {
                                        "sort-pratiche": _vm.sort,
                                        "set-filter": _vm.filter,
                                        "reset-filter": _vm.resetAllFilters,
                                        "open-filters": _vm.handleFiltersPanel,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.loading
                          ? _c("v-progress-linear", {
                              staticClass: "mt-1",
                              attrs: { indeterminate: "" },
                            })
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _vm.loading && _vm.browseResult.length
                          ? _c("v-progress-linear", {
                              attrs: { indeterminate: "" },
                            })
                          : _vm._e(),
                        _c(
                          "v-row",
                          { staticClass: "ma-0", attrs: { dense: "" } },
                          [
                            _c(
                              "v-col",
                              { staticClass: "pa-0" },
                              [
                                _vm.browseResult.length
                                  ? _c("TableNav", {
                                      ref: "tableNavFooter",
                                      attrs: {
                                        "browse-count": _vm.browseCount,
                                        "browse-limit": _vm.browseLimit,
                                        "browse-offset": _vm.browseOffset,
                                      },
                                      on: {
                                        "offset-changed": _vm.offsetChanged,
                                        "get-current-page": _vm.setCurrent,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("ForeignAgentEmail", {
        ref: "foreignAgentEmail",
        attrs: { rowsSelection: _vm.rowsSelection, pratiche: _vm.browseResult },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }