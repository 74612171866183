var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "mb-2" },
    [
      _c(
        "v-card-text",
        { staticClass: "ma-0 black--text" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-1" },
                [
                  _c("ReadonlyField", {
                    attrs: {
                      label: _vm.$t("label.departure"),
                      value: _vm.departure,
                      icon: "mdi-map-marker-outline",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pa-1" },
                [
                  _c("ReadonlyField", {
                    attrs: {
                      label: _vm.$t("label.arrival"),
                      value: _vm.arrival,
                      icon: "mdi-map-marker-outline",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pa-1", attrs: { cols: "3" } },
                [
                  _c("ReadonlyField", {
                    attrs: {
                      label: _vm.$t("label.vessel"),
                      value: _vm.mainVoyageInfo,
                      icon: "mdi-ferry",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "py-1 pr-5 d-flex flex-column justify-center",
                  attrs: { cols: "auto" },
                },
                [
                  _vm.company == "MAERSK"
                    ? _c("ReadonlyField", {
                        attrs: {
                          label: _vm.$t("label.carrier"),
                          value: _vm.routeSchedule
                            ? _vm.routeSchedule.carrierCode
                            : null,
                        },
                      })
                    : _vm._e(),
                  _vm.selectedVoyage
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "primary",
                          attrs: {
                            "x-small": "",
                            width: "4rem",
                            disabled: _vm.disabled,
                          },
                          on: { click: _vm.selectVoyage },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.$t("label.change")))])]
                      )
                    : _c(
                        "v-btn",
                        {
                          class: _vm.voyageSelected ? "success" : "primary",
                          attrs: {
                            "x-small": "",
                            width: "4rem",
                            disabled: _vm.disabled,
                          },
                          on: { click: _vm.selectVoyage },
                        },
                        [
                          _vm.voyageSelected
                            ? _c("v-icon", [_vm._v("mdi-check")])
                            : _c("span", [
                                _vm._v(_vm._s(_vm.$t("label.book"))),
                              ]),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.deadlines.length && !_vm.selectedVoyage
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-0" },
                    [
                      _c("v-divider", { staticClass: "mt-1" }),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pa-1" },
                            [
                              _c("v-icon", { staticClass: "mr-2" }, [
                                _vm._v("mdi-alarm"),
                              ]),
                              _c(
                                "span",
                                { staticClass: "text-caption primary--text" },
                                [_vm._v(_vm._s(_vm.$t("label.deadlines")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "px-6" },
                        _vm._l(_vm.deadlines, function (deadline, i) {
                          return _c(
                            "v-col",
                            {
                              key: i,
                              staticClass: "py-0",
                              attrs: { cols: "3" },
                            },
                            [
                              _c("ReadonlyField", {
                                attrs: {
                                  label: deadline.deadlineName,
                                  value: deadline.deadlineDateTime,
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("v-divider"),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-1", attrs: { cols: "12" } },
                [
                  _c(
                    "v-expansion-panels",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-header",
                            {
                              staticClass: "py-0",
                              staticStyle: { "min-height": "0 !important" },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "header text-caption primary--text ml-2",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("label.showRouteDetails")) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _vm.company == "YANGMING"
                                ? _c("BookingRoutesYangMing", {
                                    attrs: {
                                      routes: _vm.routeSchedule
                                        ? _vm.routeSchedule.routeLegs
                                        : [],
                                    },
                                  })
                                : _c("BookingRoutes", {
                                    attrs: {
                                      routes: _vm.routeSchedule
                                        ? _vm.routeSchedule.routeLegs
                                        : [],
                                    },
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }