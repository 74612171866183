var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-stepper",
    { staticClass: "pa-0", attrs: { elevation: "0" } },
    [
      _c(
        "v-stepper-header",
        [
          _vm._l(_vm.props, function (prop, i) {
            return [
              _c(
                "v-stepper-step",
                {
                  key: i,
                  attrs: {
                    complete: _vm.entity[prop.value],
                    step: prop.text,
                    color: "secondary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.update(prop)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(prop.text) + " ")]
              ),
              _vm.props.indexOf(prop) != _vm.props.length - 1
                ? _c("v-divider", { key: prop.value })
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }