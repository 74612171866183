<template>
  <v-container class="d-flex flex-column">
    <v-row class="d-flex flex-row align-center">
      <v-col cols="3" class="d-flex justify-start">
        <v-btn color="primary" download :href="downloadPath"> Download </v-btn>
      </v-col>
      <v-col cols="9">
        <v-checkbox
          v-model="checkbox"
          label="Overwrite"
        ></v-checkbox>
        <v-file-input
          v-model="selectedFile"
          label="File input"
          accept=".xlsx"
          clearable
          @change="onFileSelected"
        ></v-file-input>
      </v-col>
    </v-row>
    <v-row v-if="resultsDisplay">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="results"
          class="elevation-1"
          hide-default-footer
        ></v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { commons } from "../../services/commons";

export default {
  data() {
    return {
      selectedFile: null,
      checkbox: true,

      importInvoicesUrl: process.env.VUE_APP_GSCV_CUSTOMS_ENGINE_URL + "/invoices",
      downloadPath: process.env.VUE_APP_PUBLIC_PATH + 'template/customsTemplate.xlsx',
      discardedInvoicesList: [],
      createdInvoicesList: [],
      discardedLinesList: [],
      createdLinesList: [],

      resultsDisplay: false,
      headers: [
        {
          text: "Item",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Count", align: "start", sortable: false, value: "count" },
        {
          text: "Identifiers",
          align: "start",
          sortable: false,
          value: "identifiers",
        },
      ],
      results: [],
    };
  },
  methods: {
    onFileSelected() {
      if (this.selectedFile != null) {
        this.uploadFile();
      } else {
        this.resultsDisplay = false;
      }
    },
    async uploadFile() {
      const params = new URLSearchParams();
      params.append("overwrite", this.checkbox);
      const formData = new FormData();
      formData.append("file", this.selectedFile);
      try {
        const response = await fetch(
          this.importInvoicesUrl + "/import/upload?" + params.toString(),
          {
            method: "POST",
            body: formData,
          }
        ).then(commons.handleJsonResponse);
        this.populateImportResultsTable(response);
      } catch (error) {
        console.error("Failed to upload file", error);
      }
    },
    populateImportResultsTable(response) {
      this.discardedInvoicesList = response.discardedInvoicesList;
      this.createdInvoicesList = response.createdInvoicesList;
      this.discardedLinesList = response.discardedLinesList;
      this.createdLinesList = response.createdLinesList;
      this.showImportResultsTable();
    },
    showImportResultsTable() {
      this.results = [];

      this.results.push({
        name: "Invoices imported",
        count: this.createdInvoicesList.length,
        identifiers: this.createdInvoicesList,
      });
      this.results.push({
        name: "Invoices discarded",
        count: this.discardedInvoicesList.length,
        identifiers: this.discardedInvoicesList,
      });
      this.results.push({
        name: "Invoice Lines imported",
        count: this.createdLinesList.length,
        identifiers: this.createdLinesList,
      });
      this.results.push({
        name: "Invoice Lines discarded",
        count: this.discardedLinesList.length,
        identifiers: this.discardedLinesList,
      });

      this.resultsDisplay = true;
    },
  },
};
</script>

<style>
.rr {
  background-color: red;
}
</style>
