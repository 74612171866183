<template>
  <v-form class="d-block pa-2 overflow-y-auto fill-height">
    <v-row dense class="booking-header">
      <v-col cols="auto" class="d-flex align-center">
        <v-checkbox
          color="primary"
          class="checkbox ma-0 pa-0"
          @change="selectAllPayments"
          :ripple="false"
          hide-details
        />
      </v-col>
      <v-col class="d-flex align-center" style="max-width: 20rem;">
        <v-select
          label="Assign a Payment term to selected"
          :items="paymentTerms"
          item-text="customerName"
          return-object
          @change="setPaymentTermToAllSelected"
          :menu-props="menuProps"
          class="xs-input"
          hide-details
          clearable
          outlined
          attach
          dense
        />
      </v-col>
      <v-divider vertical class="mx-3 my-1" />
      <v-col class="d-flex align-center" style="max-width: 20rem;">
        <v-select
          label="Assign a Payer to selected"
          :items="customers"
          item-text="customerName"
          return-object
          @change="setPartyToAllSelected"
          :menu-props="menuProps"
          class="xs-input"
          hide-details
          clearable
          outlined
          attach
          dense
        />
      </v-col>
    </v-row>

    <v-row dense class="mt-1 px-2">
      <v-col cols="auto" class="py-0">
        <v-subheader class="pa-0 primary--text text-overline">
          Origin
        </v-subheader>
      </v-col>
      <v-col class="d-flex align-center py-0 pl-0">
        <v-divider />
      </v-col>
    </v-row>
    <v-row dense class="mt-0">
      <v-col class="py-0">
        <v-list flat dense>
          <v-list-item-group v-model="selectedPayments" multiple>
            <v-list-item
              class="px-2"
              v-for="(item, index) in originPayments"
              :key="index"
              :value="item.value"
              :ripple="false"
            >
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active" :ripple="false" />
                  <!-- @change="resetSelectedDetails(item.value, active)" -->
                </v-list-item-action>
                <v-list-item-content @click.stop>
                  <v-row dense>
                    <v-col
                      class="d-flex align-center"
                      style="max-width: 20rem;"
                    >
                      <span v-html="item.text" />
                    </v-col>
                    <v-col cols="auto" class="d-flex align-center">
                      <v-radio-group
                        v-model="item.value.paymentTerm"
                        class="radio-group ma-0 pt-0"
                        hide-details
                        dense
                      >
                        <v-radio value="PRE">
                          <template slot="label">
                            <span class="text-caption black--text">
                              Prepaid
                            </span>
                          </template>
                        </v-radio>
                        <v-radio value="COL">
                          <template slot="label">
                            <span class="text-caption black--text">
                              Collect
                            </span>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col
                      class="d-flex align-center"
                      style="max-width: 20rem;"
                    >
                      <v-select
                        :label="$t('label.party')"
                        v-model="item.value.party"
                        :items="customers"
                        item-text="customerName"
                        return-object
                        :menu-props="menuProps"
                        class="xs-input"
                        hide-details
                        outlined
                        attach
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="auto" class="py-0 px-2">
        <v-subheader class="pa-0 primary--text text-overline">
          Freight
        </v-subheader>
      </v-col>
      <v-col class="d-flex align-center py-0 pl-0">
        <v-divider />
      </v-col>
    </v-row>
    <v-row dense class="mt-0">
      <v-col class="py-0">
        <v-list flat dense>
          <v-list-item-group v-model="selectedPayments" multiple>
            <v-list-item
              class="px-2"
              v-for="(item, index) in freightPayments"
              :key="index"
              :value="item.value"
              :ripple="false"
            >
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active" :ripple="false" />
                </v-list-item-action>
                <v-list-item-content @click.stop>
                  <v-row dense>
                    <v-col
                      class="d-flex align-center"
                      style="max-width: 20rem;"
                    >
                      <span v-html="item.text" />
                    </v-col>
                    <v-col cols="auto" class="d-flex align-center">
                      <v-radio-group
                        v-model="item.value.paymentTerm"
                        class="radio-group ma-0 pt-0"
                        hide-details
                        dense
                      >
                        <v-radio value="PRE">
                          <template slot="label">
                            <span class="text-caption black--text">
                              Prepaid
                            </span>
                          </template>
                        </v-radio>
                        <v-radio value="COL">
                          <template slot="label">
                            <span class="text-caption black--text">
                              Collect
                            </span>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col
                      class="d-flex align-center"
                      style="max-width: 20rem;"
                    >
                      <v-select
                        :label="$t('label.party')"
                        v-model="item.value.party"
                        :items="customers"
                        item-text="customerName"
                        return-object
                        :menu-props="menuProps"
                        class="xs-input"
                        hide-details
                        outlined
                        attach
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="auto" class="py-0 px-2">
        <v-subheader class="pa-0 primary--text text-overline">
          Destination
        </v-subheader>
      </v-col>
      <v-col class="d-flex align-center py-0 pl-0">
        <v-divider />
      </v-col>
    </v-row>
    <v-row dense class="mt-0">
      <v-col class="py-0">
        <v-list flat dense>
          <v-list-item-group v-model="selectedPayments" multiple>
            <v-list-item
              class="px-2"
              v-for="(item, index) in destinationPayments"
              :key="index"
              :value="item.value"
              :ripple="false"
            >
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active" :ripple="false" />
                </v-list-item-action>
                <v-list-item-content @click.stop>
                  <v-row dense>
                    <v-col
                      class="d-flex align-center"
                      style="max-width: 20rem;"
                    >
                      <span v-html="item.text" />
                    </v-col>
                    <v-col cols="auto" class="d-flex align-center">
                      <v-radio-group
                        v-model="item.value.paymentTerm"
                        class="radio-group ma-0 pt-0"
                        hide-details
                        dense
                      >
                        <v-radio value="PRE">
                          <template slot="label">
                            <span class="text-caption black--text">
                              Prepaid
                            </span>
                          </template>
                        </v-radio>
                        <v-radio value="COL">
                          <template slot="label">
                            <span class="text-caption black--text">
                              Collect
                            </span>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col
                      class="d-flex align-center"
                      style="max-width: 20rem;"
                    >
                      <v-select
                        :label="$t('label.party')"
                        v-model="item.value.party"
                        :items="customers"
                        item-text="customerName"
                        return-object
                        :menu-props="menuProps"
                        class="xs-input"
                        hide-details
                        outlined
                        attach
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import ShippingInstruction from '@/mixins/ShippingInstruction.js'
import { customersService } from '@/services/customers.service.js'

export default {
  name: 'ShippingInstructionPayments',
  props: [],
  mixins: [ShippingInstruction],
  data() {
    return {
      paymentTerms: ['Prepaid', 'Collect'],
      customers: [],
      selectedPayments: [],
      originPayments: [
        {
          text: `Terminal Handling Service - <span class="text-caption">ORIGIN</span>`,
          value: {
            name: 'Terminal Handling Service - ORIGIN',
            paymentTerm: null,
            party: null,
          },
        },
        {
          text: `Documentation Fee - <span class="text-caption">ORIGIN</span>`,
          value: {
            name: 'Documentation Fee - ORIGIN',
            paymentTerm: null,
            party: null,
          },
        },
        {
          text: `Export Service`,
          value: {
            name: 'Export Service',
            paymentTerm: null,
            party: null,
          },
        },
      ],
      freightPayments: [
        {
          text: `Environmental Fuel Fee`,
          value: {
            name: 'Environmental Fuel Fee',
            paymentTerm: null,
            party: null,
          },
        },
        {
          text: `Basic Ocean Freight`,
          value: {
            name: 'Basic Ocean Freight',
            paymentTerm: null,
            party: null,
          },
        },
      ],
      destinationPayments: [
        {
          text: `Terminal Handling Service - <span class="text-caption">DESTINATION</span>`,
          value: {
            name: 'Terminal Handling Service - DESTINATION',
            paymentTerm: null,
            party: null,
          },
        },
        {
          text: `Documentation Fee - <span class="text-caption">DESTINATION</span>`,
          value: {
            name: 'Documentation Fee - DESTINATION',
            paymentTerm: null,
            party: null,
          },
        },
        {
          text: `Container Protect Unlimited`,
          value: {
            name: 'Container Protect Unlimited',
            paymentTerm: null,
            party: null,
          },
        },
      ],
    }
  },
  computed: {},
  methods: {
    selectAllPayments(selected) {
      if (selected) {
        let selectedPayments = this.originPayments
          .concat(this.freightPayments)
          .concat(this.destinationPayments)
        selectedPayments.forEach((payment) => {
          this.selectedPayments.push(payment.value)
        })
      } else this.selectedPayments = []
    },
    resetSelectedDetails(payment, isActive) {
      if (!isActive) return
      payment.paymentTerm = null
      payment.party = null
    },
    setPartyToAllSelected(customer) {
      this.selectedPayments.forEach((payment) => {
        payment.party = customer
      })
    },
    setPaymentTermToAllSelected(paymentTerm) {
      this.selectedPayments.forEach((payment) => {
        payment.paymentTerm = paymentTerm
      })
    },
    // Submit
    submit(){
      if (this.electronicFlowStatus == this.partiesSelectedStatus) this.setStatus(this.paymentSelectedStatus)
      // this.setPaymentCode(this.paymentCode)
      // this.updateShippingInstructionAndSaveElectronicFlow()
    }
  },
}
</script>

<style scoped>
.v-subheader {
  height: 2rem;
}
.radio-group::v-deep .v-input--radio-group__input {
  flex-direction: row;
}
.v-radio {
  max-width: max-content;
  margin: 0 1rem 0 0 !important;
}
.v-radio::v-deep .v-label {
  margin: 0 !important;
}
.xs-input::v-deep input {
  padding: 0.5rem !important;
  padding-left: 0 !important;
}
.xs-input::v-deep .v-label {
  position: unset !important;
  min-width: max-content;
  margin-bottom: 0 !important;
}
.xs-input::v-deep .v-input__slot {
  min-height: 0 !important;
  padding-right: 4px !important;
}
.xs-input::v-deep .v-input__append-inner {
  margin: 0 !important;
  align-self: center !important;
}
.xs-input::v-deep .v-label--active {
  position: absolute !important;
  margin-bottom: 0 !important;
}
.xs-input::v-deep .v-select__selections {
  padding: 0 !important;
}
.booking-header {
  background-color: var(--v-grey-lighten3);
  color: var(--v-grey-base);
  padding: 0.5rem;
}
/*.row {
  border: solid 1px red;
}
.col {
  border: solid 1px orange;
}*/
</style>
