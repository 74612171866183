var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-simple-table", {
    attrs: { dense: "", "fixed-header": "" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c("thead", [
              _c("tr", [
                _c("th", {
                  staticClass: "text-caption primary--text text-left",
                }),
                _c(
                  "th",
                  { staticClass: "text-caption primary--text text-left" },
                  [_vm._v(" " + _vm._s(_vm.$t("label.invoiceNbr")) + " ")]
                ),
                _c(
                  "th",
                  { staticClass: "text-caption primary--text text-left" },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("label.invoiceHolderName")) + " "
                    ),
                  ]
                ),
                _c(
                  "th",
                  { staticClass: "text-caption primary--text text-left" },
                  [_vm._v(" " + _vm._s(_vm.$t("label.sellerName")) + " ")]
                ),
                _c(
                  "th",
                  { staticClass: "text-caption primary--text text-left" },
                  [_vm._v(" " + _vm._s(_vm.$t("label.consigneeName")) + " ")]
                ),
                _c(
                  "th",
                  { staticClass: "text-caption primary--text text-left" },
                  [_vm._v(" " + _vm._s(_vm.$t("label.totalAmount")) + " ")]
                ),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.invoices, function (item, index) {
                return _c(
                  "tr",
                  {
                    key: index,
                    on: {
                      click: function ($event) {
                        return _vm.handleMatch(item.documentNbr, !item.isLinked)
                      },
                    },
                  },
                  [
                    _c(
                      "td",
                      { staticClass: "text-caption font-weight-bold" },
                      [
                        item.isLinked
                          ? _c(
                              "v-icon",
                              { attrs: { color: "success", size: "x-large" } },
                              [_vm._v("mdi-check-network")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("td", { staticClass: "text-caption font-weight-bold" }, [
                      _vm._v(" " + _vm._s(item.invoiceNbr) + " "),
                    ]),
                    _c("td", { staticClass: "text-caption font-weight-bold" }, [
                      item.invoiceHolderName.length > 10
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(item.invoiceHolderName.substring(0, 10))
                            ),
                            !_vm.showFullText[index]
                              ? _c(
                                  "span",
                                  {
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.toggleShowFullText(index)
                                      },
                                    },
                                  },
                                  [_vm._v("...")]
                                )
                              : _vm._e(),
                            _vm.showFullText[index]
                              ? _c("span", [
                                  _vm._v(_vm._s(item.invoiceHolderName)),
                                ])
                              : _vm._e(),
                          ])
                        : _c("div", [
                            _vm._v(" " + _vm._s(item.invoiceHolderName) + " "),
                          ]),
                    ]),
                    _c("td", { staticClass: "text-caption font-weight-bold" }, [
                      _vm._v(" " + _vm._s(item.sellerName) + " "),
                    ]),
                    _c("td", { staticClass: "text-caption font-weight-bold" }, [
                      item.consigneeName.length > 10
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(item.consigneeName.substring(0, 10))
                            ),
                            !_vm.showFullText[index]
                              ? _c(
                                  "span",
                                  {
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.toggleShowFullText(index)
                                      },
                                    },
                                  },
                                  [_vm._v("...")]
                                )
                              : _vm._e(),
                            _vm.showFullText[index]
                              ? _c("span", [_vm._v(_vm._s(item.consigneeName))])
                              : _vm._e(),
                          ])
                        : _c("div", [
                            _vm._v(" " + _vm._s(item.consigneeName) + " "),
                          ]),
                    ]),
                    _c("td", { staticClass: "text-caption font-weight-bold" }, [
                      _vm._v(
                        " " +
                          _vm._s(item.totalAmount + " " + item.currency) +
                          " "
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }