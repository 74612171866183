var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.linkExpired
    ? _c(
        "v-container",
        { staticClass: "pa-2", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "mb-1", attrs: { dense: "" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-0 px-2", attrs: { dense: "" } },
                        [
                          _c("v-col", [
                            _c("span", { staticClass: "text-caption" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("message.trackingResponsibility")
                                  )
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mb-1", attrs: { dense: "" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    [
                      _vm.currentRoute != "pratica"
                        ? _c(
                            "v-row",
                            {
                              staticClass: "ma-0 px-3 pt-1",
                              attrs: { dense: "" },
                            },
                            [
                              _c(
                                "v-col",
                                { staticClass: "d-flex pa-0" },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "d-flex align-center pa-0" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-h5 font-weight-black",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.pratica.documentNbr) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex align-center pa-0 justify-end",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-h5 primary--text font-weight-black",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.pratica.shipper
                                                  ? _vm.pratica.shipper
                                                      .customerName
                                                  : null
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-row",
                        { staticClass: "ma-0 pa-1", attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "d-inline-flex" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0",
                                  staticStyle: {
                                    "min-width": "30rem",
                                    "max-width": "30rem",
                                  },
                                },
                                [
                                  _c("ReadonlyField", {
                                    attrs: {
                                      label: _vm.$t("label.company"),
                                      value: _vm.returnCompany(),
                                      icon:
                                        _vm.saleChannel == "Nave"
                                          ? "mdi-ferry"
                                          : "mdi-airplane",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "node-col pa-0" },
                                [
                                  _c("ReadonlyField", {
                                    attrs: {
                                      label: _vm.$t("label.pol"),
                                      value: _vm.pratica.pol
                                        ? _vm.pratica.pol.locode
                                        : "",
                                      icon: "mdi-map-marker",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "node-col pa-0" },
                                [
                                  _c("ReadonlyField", {
                                    attrs: {
                                      label: _vm.$t("label.pod"),
                                      value: _vm.pratica.pod
                                        ? _vm.pratica.pod.locode
                                        : "",
                                      icon: "mdi-map-marker",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "date-col pa-0" },
                                [
                                  _c("ReadonlyField", {
                                    attrs: {
                                      label: _vm.$t("label.departure"),
                                      value: _vm.etdComp,
                                      icon: "mdi-calendar",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "date-col pa-0" },
                                [
                                  _c("ReadonlyField", {
                                    attrs: {
                                      label: _vm.$t("label.arrival"),
                                      value: _vm.etaComp,
                                      icon: "mdi-calendar",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "pa-0" },
                                [
                                  _vm.pratica.awb
                                    ? _c("ReadonlyField", {
                                        attrs: {
                                          label: _vm.$t("label.awb"),
                                          value: _vm.pratica.awb,
                                          icon: "mdi-file-document-outline",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mb-1", attrs: { dense: "" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    { attrs: { width: "100%" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex d-flex align-center pa-3",
                              staticStyle: {
                                display: "grid",
                                "align-items": "center",
                              },
                            },
                            [
                              _c("PraticaStepperTracking", {
                                attrs: {
                                  pratica: _vm.pratica,
                                  status: _vm.voyageStatus,
                                  etd: _vm.etdComp,
                                  eta: _vm.etaComp,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.airportEvents.length
            ? _c(
                "v-row",
                { staticClass: "mb-1", attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-card",
                        { attrs: { width: "100%" } },
                        [
                          _c(
                            "v-row",
                            { staticClass: "ma-0" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pa-0" },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "ma-1",
                                      attrs: { dense: "" },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "d-inline-flex" },
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "pa-0" },
                                            [
                                              _c("ReadonlyField", {
                                                attrs: {
                                                  label: _vm.$t("label.status"),
                                                  value:
                                                    _vm.trackingAereo.status,
                                                  icon: "mdi-map-marker-radius",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-divider", {
                                    staticClass: "mt-1 mb-0 mx-2",
                                  }),
                                  _c(
                                    "v-data-table",
                                    {
                                      staticClass: "px-2 py-0",
                                      attrs: {
                                        headers: _vm.airportEventsHeaders,
                                        items: _vm.airportEvents,
                                        "items-per-page": 1000,
                                        "hide-default-footer": "",
                                        dense: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "item.planned",
                                            fn: function ({ item }) {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.formatDate(
                                                        item.planned
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.actual",
                                            fn: function ({ item }) {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.formatDate(
                                                        item.actual
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.isReplanned",
                                            fn: function ({ item }) {
                                              return [
                                                item.isReplanned
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " mdi-check-bold "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        282408036
                                      ),
                                    },
                                    [
                                      _c(
                                        "template",
                                        { slot: "no-data" },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: {
                                                color: "warning",
                                                size: "20",
                                              },
                                            },
                                            [_vm._v(" mdi-alert ")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-medium warning--text",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "label.noTrackingInfo"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    { attrs: { width: "100%" } },
                    [
                      _vm.trackingEventsAereo.length
                        ? _c(
                            "v-row",
                            { staticClass: "ma-0" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pa-0" },
                                [
                                  _c(
                                    "v-data-table",
                                    {
                                      staticClass: "px-2 py-0",
                                      attrs: {
                                        headers: _vm.trackingHeadersAereo,
                                        items: _vm.trackingEventsAereo,
                                        "items-per-page": 1000,
                                        "hide-default-footer": "",
                                        "sort-by": "departureActualTime",
                                        "sort-desc": false,
                                        dense: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "item.departureScheduleTime",
                                            fn: function ({ item }) {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.formatDate(
                                                        item.departureScheduleTime
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.departureActualTime",
                                            fn: function ({ item }) {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.formatDate(
                                                        item.departureActualTime
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.arrivalScheduleTime",
                                            fn: function ({ item }) {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.formatDate(
                                                        item.arrivalScheduleTime
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.arrivalActualTime",
                                            fn: function ({ item }) {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.formatDate(
                                                        item.arrivalActualTime
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.isReplanned",
                                            fn: function ({ item }) {
                                              return [
                                                item.isReplanned
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " mdi-check-bold "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3427718948
                                      ),
                                    },
                                    [
                                      _c(
                                        "template",
                                        { slot: "no-data" },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: {
                                                color: "warning",
                                                size: "20",
                                              },
                                            },
                                            [_vm._v(" mdi-alert ")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-medium warning--text",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "label.noTrackingInfo"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.seaTerminalEvents.length
                        ? _c(
                            "v-row",
                            { staticClass: "ma-0" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pa-0" },
                                [
                                  _c(
                                    "v-data-table",
                                    {
                                      staticClass: "pa-0",
                                      attrs: {
                                        headers: _vm.trackingHeadersMare,
                                        items: _vm.seaTerminalEvents,
                                        "items-per-page": 1000,
                                        "item-class": _vm.isDepartedFromPol,
                                        "hide-default-footer": "",
                                        dense: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "item.eventDateTime",
                                            fn: function ({ item }) {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.formatDateShort(
                                                        item.eventDateTime
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.eventCode",
                                            fn: function ({ item }) {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-caption",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.eventCode ==
                                                            "EST"
                                                            ? "ESTIMATED"
                                                            : item.eventCode ==
                                                              "ACT"
                                                            ? "ACTUAL"
                                                            : item.eventCode ==
                                                              "PLN"
                                                            ? "PLANNED"
                                                            : ""
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.eventName",
                                            fn: function ({ item }) {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-caption",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.eventName) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.eventType",
                                            fn: function ({ item }) {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-caption",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.eventType)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.eventDescription",
                                            fn: function ({ item }) {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-caption",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.eventDescription
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.transportMode",
                                            fn: function ({ item }) {
                                              return [
                                                item.transportMode == "TRUCK"
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                      },
                                                      [_vm._v(" mdi-truck ")]
                                                    )
                                                  : _vm._e(),
                                                item.transportMode == "VESSEL"
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                      },
                                                      [_vm._v(" mdi-ferry ")]
                                                    )
                                                  : _vm._e(),
                                                item.transportMode == "RAIL"
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                      },
                                                      [_vm._v(" mdi-train ")]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        19603197
                                      ),
                                    },
                                    [
                                      _c(
                                        "template",
                                        { slot: "no-data" },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: {
                                                color: "warning",
                                                size: "20",
                                              },
                                            },
                                            [_vm._v(" mdi-alert ")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-medium warning--text",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "label.noTrackingInfo"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c("LinkExpired")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }