var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "ma-0", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex flex-column",
              staticStyle: { "max-width": "90vw" },
            },
            [
              _c("v-container", {
                staticClass: "text pt-4 pa-0",
                attrs: { fluid: "" },
                domProps: { innerHTML: _vm._s(_vm.mail.text) },
              }),
              _c(
                "v-flex",
                { staticClass: "d-flex mt-2" },
                _vm._l(_vm.mail.attachments, function (attachment, i) {
                  return _c(
                    "v-col",
                    {
                      key: i,
                      staticClass: "pa-0 mr-2",
                      attrs: { cols: "auto" },
                    },
                    [
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.$emit(
                                "download-attachment",
                                attachment
                              )
                            },
                          },
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-attachment")]),
                          _c("span", { staticClass: "text-caption ml-1" }, [
                            _vm._v(_vm._s(attachment.filename)),
                          ]),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }