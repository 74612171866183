<template>
  <v-stepper v-model="step" class="d-table fill-height" width="100%" max-width="100%" light>
    <v-stepper-header class="d-table-row">
      <div class="d-flex">
        <!-- <v-stepper-step :step="1" :editable="!uneditableRequest">
          <span class="text-overline"> Select packages </span>
        </v-stepper-step>
        <v-divider /> -->
        <v-stepper-step :step="1" :editable="editableSelectedVoyage">
          <span class="text-overline"> {{$t('label.selectVoyage')}} </span>
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :step="2" :editable="editableSelectedCommodity">
          <span class="text-overline"> {{$t('label.selectCommodity')}} </span>
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :step="3" :editable="editableReview">
          <span class="text-overline"> {{$t('label.review')}} </span>
        </v-stepper-step>
      </div>
    </v-stepper-header>

    <v-stepper-items class="d-table-row">
      <component
        :is="stepToShow.component"
        :ref="stepToShow.ref"
        :key="stepToShow.component"
        :pratica="pratica"
        :imballi="imballi"
        @step="handleSteps"
        v-on="$listeners"
      />
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import Booking from '@/mixins/Booking.js'
import BookingImballi from '@/components/elements/booking/BookingImballi.vue'
import BookingVoyages from '@/components/elements/booking/BookingVoyages.vue'
import BookingCommodities from '@/components/elements/booking/BookingCommodities.vue'
import BookingReview from '@/components/elements/booking/BookingReview.vue'

export default {
  name: 'BookingStepper',
  props: ['pratica', 'imballi'],
  emits: ['exit-request'],
  mixins: [Booking],
  components: {
    BookingImballi,
    BookingVoyages,
    BookingCommodities,
    BookingReview
  },
  data() {
    return {
      step: 1,
      items: [
        // {
        //   component: 'BookingImballi',
        //   ref: 'bookingImballi',
        // },
        {
          component: 'BookingVoyages',
          ref: 'bookingVoyages',
        },
        {
          component: 'BookingCommodities',
          ref: 'bookingCommodities',
        },
        {
          component: 'BookingReview',
          ref: 'bookingReview',
        },
      ],
    }
  },
  computed: {
    stepToShow() {
      return this.step == 0 ? {} : this.items[this.step - 1]
    },
  },
  methods: {
    init() {
      if (this.savedRequest) this.setBookingRequest(this.savedRequest)
      this.setCurrentStep()
      this.initBookingRequest()
    },
    handleSteps(forward) {
      this.step = forward ? (this.step += 1) : (this.step -= 1)
    },
    setCurrentStep() {
      switch (this.electronicFlowStatus) {
        case this.createdStatus:
          this.step = 1
          break
        case this.voyageSelectedStatus:
          this.step = 2
          break
        case this.pendingStatus:
        case this.confirmedStatus:
        case this.rejectedStatus:
        case this.cancelledStatus:
        case this.commoditySelectedStatus: 
          this.step = 3
          break
      }
    },
    // Submit
    initBookingRequest() {
      this.initDocumentParties()
      this.setPaymentTermCode('COL')
      if (this.company == 'YANGMING') this.setChargeCode('1')
    },
    initDocumentParties() {
      let italianspedParty = {
        party: {
          partyName: this.applicantCompanyName,
          partyCode: this.companyMaerskCode,
          address: {
            street: this.companyAddress,
            streetNumber: this.companyAddressNbr,
            postCode: this.companyPostalCode,
            city: this.companyCity,
            country: this.getCompanyCountryCode()
          },
          partyContactDetails: {
            name: this.userCompleteName,
            email: this.user.email,
            phone: this.companyPhoneNbr,
          },
        },
        partyFunction: 'DDR',
      }
      
      let parties = [italianspedParty]

      if (this.company == 'MAERSK' || this.company == 'CMACGM'){
        let priceOwnerCompanyParty = {
          party: {
            partyName: this.applicantCompanyName,
            partyCode: this.companyMaerskCode,
            address: {
              street: this.companyAddress,
              streetNumber: this.companyAddressNbr,
              postCode: this.companyPostalCode,
              city: this.companyCity,
              country: this.getCompanyCountryCode()
            },
            partyContactDetails: {
              name: this.userCompleteName,
              email: this.user.email,
              phone: this.companyPhoneNbr,
            },
          },
          partyFunction: this.getPriceOwnerPartyFunction()
        }
        parties.push(priceOwnerCompanyParty)
      }
      
      this.setDocumentParties(parties)
    },
    getCompanyCountryCode(){
      switch (this.company) {
        case 'MAERSK':
          return this.companyCountryMaersk
        case 'CMACGM':
          return this.companyCountryCmaCgm
        case 'YANGMING':
          return null
      }
    },
    getPriceOwnerPartyFunction(){
      switch (this.company) {
        case 'MAERSK':
          return 'SCO'
        case 'CMACGM':
          return 'BA'
        case 'YANGMING':
          return 'SCO' // To check
      }
    }
  },
  created() {
    this.init()
  },
}
</script>

<style scoped>
.v-stepper {
  table-layout: fixed !important;
}
.v-stepper::v-deep .v-stepper__header {
  height: 0 !important;
}
.v-stepper::v-deep .v-stepper__step {
  padding-block: 0.5rem !important;
}
.v-stepper::v-deep .v-stepper__wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.v-stepper::v-deep .v-stepper__content {
  overflow: auto;
}
.v-btn {
  padding-inline: 0.5rem !important;
}
/*.row {
  border: solid 1px red;
}
.col {
  border: solid 1px orange;
}*/
</style>
