var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex justify-end pr-1" },
    [
      _vm.showDownload
        ? _c(
            "v-btn",
            { attrs: { icon: "" }, on: { click: _vm.dowloadFile } },
            [
              _c("v-icon", { attrs: { color: "primary" } }, [
                _vm._v("mdi-download"),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("ButtonConfirm", {
        on: {
          enabled: (enabled) => (_vm.showDownload = !enabled),
          submit: _vm.deleteFile,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }