var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "mb-2 py-4", attrs: { height: "12.8rem", width: "100%" } },
    [
      _c("v-skeleton-loader", {
        staticClass: "d-flex py-0",
        attrs: {
          loading: true,
          type: "list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line",
        },
      }),
      _c("v-divider", { staticClass: "ma-3" }),
      _c("v-skeleton-loader", {
        staticClass: "mb-2",
        attrs: { loading: true, type: "list-item-avatar" },
      }),
      _c("v-skeleton-loader", {
        staticClass: "d-flex pl-10",
        attrs: {
          loading: true,
          type: "list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line",
        },
      }),
      _c("v-divider", { staticClass: "ma-3" }),
      _c("v-skeleton-loader", {
        staticClass: "pl-10",
        attrs: { loading: true, type: "list-item" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }