var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "my-1", attrs: { outlined: "" } },
    [
      _c(
        "v-row",
        {
          staticClass: "ma-0 pratica",
          attrs: { dense: "" },
          on: { click: _vm.addToRowSelection },
        },
        [
          _c(
            "v-col",
            {
              staticClass: "py-0 d-flex justify-center align-center",
              attrs: { cols: "1" },
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "ma-0 d-flex justify-center",
                  attrs: { dense: "" },
                },
                [
                  _vm.pratiche.existsMatch
                    ? _c(
                        "v-icon",
                        { attrs: { color: "success", size: "x-large" } },
                        [_vm._v("mdi-check-network")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass:
                "py-0 text-caption font-weight-bold d-flex align-center",
              attrs: { cols: "2" },
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "ma-0 d-flex justify-start text-center",
                  attrs: { dense: "" },
                },
                [
                  _c("ReadonlyField", {
                    attrs: {
                      label: _vm.$t("label.documentNbr"),
                      value: _vm.pratiche.documentNbr,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-col",
            {
              staticClass:
                "py-0 text-caption font-weight-bold d-flex align-center",
              attrs: { cols: "2" },
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "ma-0 d-flex justify-center text-center",
                  attrs: { dense: "" },
                },
                [
                  _c("ReadonlyField", {
                    attrs: {
                      label: _vm.$t("label.customer"),
                      value: _vm.pratiche.clientName,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-col",
            {
              staticClass:
                "py-0 text-caption font-weight-bold d-flex align-center",
              attrs: { cols: "2" },
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "ma-0 d-flex justify-center text-center",
                  attrs: { dense: "" },
                },
                [
                  _c("ReadonlyField", {
                    attrs: {
                      label: _vm.$t("label.shipper"),
                      value: _vm.pratiche.shipperName,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-col",
            {
              staticClass:
                "py-0 text-caption font-weight-bold d-flex align-center",
              attrs: { cols: "2" },
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "ma-0 d-flex justify-center text-center",
                  attrs: { dense: "" },
                },
                [
                  _c("ReadonlyField", {
                    attrs: {
                      label: _vm.$t("label.consigneeName"),
                      value: _vm.pratiche.consigneeName,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-col",
            {
              staticClass:
                "py-0 text-caption font-weight-bold d-flex align-center",
              attrs: { cols: "1" },
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "ma-0 d-flex justify-center text-center",
                  attrs: { dense: "" },
                },
                [
                  _c("ReadonlyField", {
                    attrs: {
                      label: _vm.$t("label.pol") + "/" + _vm.$t("label.pod"),
                      value: _vm.pratiche.pol + "/\n" + _vm.pratiche.pod,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-col",
            {
              staticClass:
                "py-0 text-caption font-weight-bold d-flex align-center",
              attrs: { cols: "2" },
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "ma-0 d-flex justify-center text-center",
                  attrs: { dense: "" },
                },
                [
                  _c("ReadonlyField", {
                    attrs: {
                      label: _vm.$t("label.documentDate"),
                      value: _vm.pratiche.documentDate,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }