<template>
  <v-card class="mb-2 py-4" height="12.8rem" width="100%">
    <v-skeleton-loader
      :loading="true"
      class="d-flex py-0"
      type="list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line"
    />
    <v-divider class="ma-3" />
    <v-skeleton-loader :loading="true" class="mb-2" type="list-item-avatar" />
    <v-skeleton-loader
      :loading="true"
      class="d-flex pl-10"
      type="list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
    />
    <v-divider class="ma-3" />
    <v-skeleton-loader :loading="true" class="pl-10" type="list-item" />
  </v-card>
</template>

<script>
export default {
  name: 'RouteScheduleRowSkeleton',
}
</script>

<style scoped>
.v-skeleton-loader::v-deep .v-skeleton-loader__list-item {
  width: 15%;
  height: auto !important;
}
.v-skeleton-loader::v-deep .v-skeleton-loader__list-item-two-line {
  width: 100%;
  height: auto !important;
}
.v-skeleton-loader::v-deep .v-skeleton-loader__list-item-avatar {
  width: 15%;
  height: auto !important;
}
.v-skeleton-loader::v-deep .v-skeleton-loader__list-item-avatar-three-line {
  width: 100%;
  height: auto !important;
}
.v-skeleton-loader::v-deep .v-skeleton-loader__avatar {
  width: 1.5rem;
  height: 1.5rem;
}
.v-skeleton-loader::v-deep
  .v-skeleton-loader__list-item-avatar-three-line
  > .v-skeleton-loader__avatar {
  margin-top: 0.7rem;
}
</style>
