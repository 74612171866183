<template>
  <v-flex class="d-flex align-center mx-1" shrink>
    <v-menu
      v-model="menu"
      transition="scale-transition"
      :close-on-content-click="false"
      max-height="250"
      class="py-0"
      offset-y
      top
    >
      <template #activator="{ on: onMenu }">
        <v-btn v-on="{ ...onMenu }" x-small class="primary">
          <span>{{ $t('label.create') }}</span>
        </v-btn>
      </template>
      <v-form ref="form" class="pa-0">
        <v-list flat>
          <v-list-item-group>
            <v-list-item v-for="(item, index) in items" :key="index">
              <v-list-item-content @click="select(index)">
                <v-list-item-title>{{ item.label }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-form>
    </v-menu>
  </v-flex>
</template>

<script>
export default {
  name: 'ElectronicFlowSelectionMenu',
  emits: 'select',
  data() {
    return {
      menu: false,
      selected: -1,
      electronicFlowType: {},
      items: [
        { label: this.$t('label.booking'), value: 'BK' },
        { label: this.$t('label.shippingInstruction'), value: 'SI' },
      ],
    }
  },
  methods: {
    select(index) {
      this.selected = index
      this.setSelectedType()
      this.$emit('select', this.electronicFlowType)
      this.menu = false
      this.resetSelection()
    },
    setSelectedType() {
      if (this.selected || this.selected == 0)
        this.electronicFlowType = this.items[this.selected].value
    },
    resetSelection() {
      this.electronicFlowType = {}
      this.selected = -1
    },
  },
}
</script>

<style scoped>
.checkbox {
  display: grid;
  padding: 0;
}
</style>
