var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "60vw" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "pa-2 px-3", on: { click: _vm.hideButtons } },
            [
              _c("span", { staticClass: "text-overline primary--text" }, [
                _vm._v(" " + _vm._s(_vm.$t("label.newMessage")) + " "),
              ]),
            ]
          ),
          _c("v-divider", { staticClass: "ma-0" }),
          _c(
            "MailNew",
            _vm._g(
              {
                ref: "mailNew",
                attrs: {
                  msgTemplate: _vm.msgTemplate,
                  emailAddresses: _vm.emailAddresses,
                  isVisible: _vm.dialog,
                },
              },
              _vm.$listeners
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }