var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-flex",
    { staticClass: "d-flex align-center mx-1", attrs: { shrink: "" } },
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on: on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "secondary",
                        attrs: { "x-small": "" },
                        on: {
                          click: function ($event) {
                            _vm.dialog = true
                          },
                        },
                      },
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { size: "20" } }, [
                        _vm._v("mdi-tune"),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("span", { staticClass: "text-caption" }, [
            _vm._v(_vm._s(_vm.$t("label.filters"))),
          ]),
        ]
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "60vw" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "pa-3" },
                [
                  _vm.page == 1
                    ? _c(
                        "v-btn",
                        {
                          attrs: { icon: "", small: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.page = 0
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { size: "35" } }, [
                            _vm._v("mdi-arrow-left-circle"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.page == 1
                    ? _c("v-spacer")
                    : _c(
                        "v-btn",
                        {
                          staticClass: "warning pa-1",
                          attrs: { small: "" },
                          on: { click: _vm.loadSavedFilters },
                        },
                        [
                          _c("v-icon", { staticClass: "mr-1" }, [
                            _vm._v("mdi-account-filter"),
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("upper.loadSavedFilters"))),
                          ]),
                        ],
                        1
                      ),
                  _c("v-spacer"),
                ],
                1
              ),
              _c("v-divider", { staticClass: "ma-0 mb-0" }),
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-carousel",
                    {
                      attrs: {
                        "hide-delimiters": "",
                        "show-arrows": false,
                        width: "100%",
                        height: "100%",
                        touchless: "",
                      },
                      model: {
                        value: _vm.page,
                        callback: function ($$v) {
                          _vm.page = $$v
                        },
                        expression: "page",
                      },
                    },
                    [
                      _c(
                        "v-carousel-item",
                        { staticClass: "pa-3", attrs: { value: 0 } },
                        [
                          _c(
                            "v-container",
                            {
                              staticClass: "pa-0",
                              staticStyle: { display: "grid" },
                              attrs: { "fill-height": "", fluid: "" },
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "ma-0" },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: {
                                            transition: "scale-transition",
                                            "offset-y": "",
                                            "min-width": "auto",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-text-field",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          ref: "documentDate",
                                                          attrs: {
                                                            label:
                                                              _vm.$t(
                                                                "label.createDate"
                                                              ),
                                                            value: _vm
                                                              .filterSelection
                                                              .documentDate
                                                              ? _vm
                                                                  .filterSelection
                                                                  .documentDate
                                                                  .text
                                                              : "",
                                                            "prepend-inner-icon":
                                                              "mdi-calendar",
                                                            "hide-details": "",
                                                            clearable: "",
                                                            readonly: "",
                                                            dense: "",
                                                          },
                                                        },
                                                        "v-text-field",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                          model: {
                                            value: _vm.datePickerMenu,
                                            callback: function ($$v) {
                                              _vm.datePickerMenu = $$v
                                            },
                                            expression: "datePickerMenu",
                                          },
                                        },
                                        [
                                          _c("v-date-picker", {
                                            attrs: {
                                              label: _vm.$t("label.createDate"),
                                              "text-field-props": {
                                                prependInnerIcon:
                                                  "mdi-calendar",
                                              },
                                              color: "primary",
                                            },
                                            on: {
                                              input: (v) =>
                                                _vm.onFieldChange(
                                                  v,
                                                  "documentDate",
                                                  "value",
                                                  "text"
                                                ),
                                            },
                                            model: {
                                              value: _vm.date,
                                              callback: function ($$v) {
                                                _vm.date = $$v
                                              },
                                              expression: "date",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-select", {
                                        ref: "saleChannel",
                                        attrs: {
                                          value:
                                            _vm.filterSelection.saleChannelIn,
                                          label: _vm.$t("label.saleChannel"),
                                          items: _vm.saleChannelFilterList,
                                          "return-object": "",
                                          "prepend-inner-icon": "mdi-cube-send",
                                          "menu-props": _vm.menuProps,
                                          "hide-details": "",
                                          clearable: "",
                                          dense: "",
                                        },
                                        on: {
                                          change: (v) =>
                                            _vm.onFieldChange(
                                              v,
                                              "saleChannelIn",
                                              "value",
                                              "text"
                                            ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "ma-0" },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("CustomAutocomplete", {
                                        ref: "client",
                                        attrs: {
                                          current: _vm.filterSelection.clientId,
                                          value: "customerId",
                                          text: "customerName",
                                          label: _vm.$t("label.client"),
                                          icon: "mdi-account",
                                        },
                                        on: {
                                          select: (v) =>
                                            _vm.onFieldChange(
                                              v,
                                              "clientId",
                                              "value",
                                              "text"
                                            ),
                                          browse: (v) =>
                                            _vm.browseCustomers(v, "client"),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-autocomplete", {
                                        ref: "incoterms",
                                        attrs: {
                                          value:
                                            _vm.filterSelection
                                              .shipperIncoterms,
                                          label: _vm.$t(
                                            "label.shipperIncoterms"
                                          ),
                                          items: _vm.incotermsFilterList,
                                          "return-object": "",
                                          "menu-props": _vm.menuProps,
                                          "prepend-inner-icon":
                                            "mdi-file-document-outline",
                                          "hide-details": "",
                                          clearable: "",
                                          dense: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                          },
                                          change: (v) =>
                                            _vm.onFieldChange(
                                              v,
                                              "incoterms",
                                              "text",
                                              "value"
                                            ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "ma-0" },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("CustomAutocomplete", {
                                        ref: "shipper",
                                        attrs: {
                                          current:
                                            _vm.filterSelection.shipperId,
                                          value: "customerId",
                                          text: "customerName",
                                          label: _vm.$t("label.shipper"),
                                          icon: "mdi-account-arrow-right",
                                        },
                                        on: {
                                          select: (v) =>
                                            _vm.onFieldChange(
                                              v,
                                              "shipperId",
                                              "value",
                                              "text"
                                            ),
                                          browse: (v) =>
                                            _vm.browseCustomers(v, "shipper"),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c("CustomAutocomplete", {
                                        ref: "consignee",
                                        attrs: {
                                          current:
                                            _vm.filterSelection.consigneeId,
                                          value: "customerId",
                                          text: "customerName",
                                          label: _vm.$t("label.consignee"),
                                          icon: "mdi-account-arrow-left",
                                        },
                                        on: {
                                          select: (v) =>
                                            _vm.onFieldChange(
                                              v,
                                              "consigneeId",
                                              "value",
                                              "text"
                                            ),
                                          browse: (v) =>
                                            _vm.browseCustomers(v, "consignee"),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "ma-0" },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("CustomAutocomplete", {
                                        ref: "carrier",
                                        attrs: {
                                          current: _vm.filterSelection.carrier,
                                          value: "supplierId",
                                          text: "supplierName",
                                          label: _vm.$t("label.shippingLine"),
                                          icon: "mdi-account-settings",
                                          textClass: "text-caption",
                                        },
                                        on: {
                                          select: (v) =>
                                            _vm.onFieldChange(
                                              v,
                                              "carrier",
                                              "value",
                                              "text"
                                            ),
                                          browse: (v) =>
                                            _vm.browseSuppliers(v, "carrier"),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        ref: "equipment",
                                        staticClass: "to-upper-text",
                                        attrs: {
                                          value:
                                            _vm.filterSelection.equipment?.text,
                                          label: _vm.$t("label.equipment"),
                                          "prepend-inner-icon":
                                            "mdi-train-car-container",
                                          "hide-details": "",
                                          clearable: "",
                                          dense: "",
                                        },
                                        on: {
                                          input: (v) =>
                                            _vm.onFieldChange(
                                              v?.toUpperCase() ?? v,
                                              "equipment",
                                              "text",
                                              "value"
                                            ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "ma-0" },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-autocomplete", {
                                        ref: "pol",
                                        attrs: {
                                          value: _vm.filterSelection.pol,
                                          label: _vm.$t("label.loadingNode"),
                                          items: _vm.nodiFilterList,
                                          "item-text": (nodo) =>
                                            `${nodo.locode} ${nodo.countryName}`,
                                          "return-object": "",
                                          "menu-props": _vm.menuProps,
                                          "prepend-inner-icon": "mdi-upload",
                                          "hide-details": "",
                                          clearable: "",
                                          dense: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                          },
                                          change: (v) =>
                                            _vm.onFieldChange(
                                              v,
                                              "pol",
                                              "locode",
                                              "countryName"
                                            ),
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "item",
                                            fn: function (data) {
                                              return [
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c("v-list-item-title", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.item.countryId
                                                          ) +
                                                          " - " +
                                                          _vm._s(
                                                            data.item
                                                              .countryName
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                    _c("v-list-item-subtitle", [
                                                      _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.item.locode
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-autocomplete", {
                                        ref: "pod",
                                        attrs: {
                                          value: _vm.filterSelection.pod,
                                          label: _vm.$t("label.dischargeNode"),
                                          items: _vm.nodiFilterList,
                                          "item-text": (nodo) =>
                                            `${nodo.locode} ${nodo.countryName}`,
                                          "return-object": "",
                                          "menu-props": _vm.menuProps,
                                          "prepend-inner-icon": "mdi-download",
                                          "hide-details": "",
                                          clearable: "",
                                          dense: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                          },
                                          change: (v) =>
                                            _vm.onFieldChange(
                                              v,
                                              "pod",
                                              "locode",
                                              "countryName"
                                            ),
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "item",
                                            fn: function (data) {
                                              return [
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c("v-list-item-title", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.item.countryId
                                                          ) +
                                                          " - " +
                                                          _vm._s(
                                                            data.item
                                                              .countryName
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                    _c("v-list-item-subtitle", [
                                                      _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.item.locode
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.internalVisibilityAuth
                                ? _c(
                                    "v-row",
                                    { staticClass: "ma-0" },
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            ref: "shipperInvoiceReference",
                                            attrs: {
                                              value: _vm.filterSelection
                                                .shipperInvoiceReference
                                                ? _vm.filterSelection
                                                    .shipperInvoiceReference
                                                    .text
                                                : "",
                                              label: _vm.$t(
                                                "label.shipperInvoiceReference"
                                              ),
                                              "prepend-inner-icon":
                                                "mdi-file-document-edit-outline",
                                              "hide-details": "",
                                              clearable: "",
                                              dense: "",
                                            },
                                            on: {
                                              change: (v) =>
                                                _vm.onFieldChange(
                                                  v,
                                                  "shipperInvoiceReference",
                                                  "text",
                                                  "value"
                                                ),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            ref: "shipperInvoiceReference",
                                            staticClass: "to-upper-text",
                                            attrs: {
                                              value: _vm.filterSelection
                                                .saleOrder
                                                ? _vm.filterSelection.saleOrder
                                                    .text
                                                : "",
                                              label: _vm.$t("label.saleOrder"),
                                              "prepend-inner-icon":
                                                "mdi-file-sign",
                                              "hide-details": "",
                                              clearable: "",
                                              dense: "",
                                            },
                                            on: {
                                              input: (v) =>
                                                _vm.onFieldChange(
                                                  v ? v.toUpperCase() : v,
                                                  "saleOrder",
                                                  "text",
                                                  "value"
                                                ),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-row",
                                { staticClass: "ma-0" },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-select", {
                                        ref: "status",
                                        attrs: {
                                          value: _vm.filterSelection.status,
                                          label: _vm.$t("label.status"),
                                          items: _vm.statusFilterList,
                                          "return-object": "",
                                          "menu-props": _vm.menuProps,
                                          "prepend-inner-icon":
                                            "mdi-list-status",
                                          "hide-details": "",
                                          clearable: "",
                                          dense: "",
                                        },
                                        on: {
                                          change: (v) =>
                                            _vm.onFieldChange(
                                              v,
                                              "status",
                                              "value",
                                              "text"
                                            ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-autocomplete", {
                                        ref: "accountUser",
                                        attrs: {
                                          value: _vm.filterSelection.userId,
                                          label: _vm.$t("label.accountUser"),
                                          items: _vm.sapUsersFilterList,
                                          "item-text": "userName",
                                          "return-object": "",
                                          "menu-props": _vm.menuProps,
                                          "prepend-inner-icon":
                                            "mdi-account-hard-hat",
                                          "hide-details": "",
                                          clearable: "",
                                          dense: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                          },
                                          change: (v) =>
                                            _vm.onFieldChange(
                                              v,
                                              "userId",
                                              "userId",
                                              "userName"
                                            ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "ma-0" },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: {
                                            transition: "scale-transition",
                                            "offset-y": "",
                                            "min-width": "auto",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-text-field",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          ref: "deliveryDate",
                                                          attrs: {
                                                            label:
                                                              _vm.$t(
                                                                "label.deliveryDate"
                                                              ),
                                                            value: _vm
                                                              .filterSelection
                                                              .deliveryDate
                                                              ? _vm
                                                                  .filterSelection
                                                                  .deliveryDate
                                                                  .text
                                                              : "",
                                                            "prepend-inner-icon":
                                                              "mdi-calendar",
                                                            "hide-details": "",
                                                            clearable: "",
                                                            readonly: "",
                                                            dense: "",
                                                          },
                                                        },
                                                        "v-text-field",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                          model: {
                                            value: _vm.deliveryDatePickerMenu,
                                            callback: function ($$v) {
                                              _vm.deliveryDatePickerMenu = $$v
                                            },
                                            expression:
                                              "deliveryDatePickerMenu",
                                          },
                                        },
                                        [
                                          _c("v-date-picker", {
                                            attrs: {
                                              label:
                                                _vm.$t("label.deliveryDate"),
                                              "text-field-props": {
                                                prependInnerIcon:
                                                  "mdi-calendar",
                                              },
                                              color: "primary",
                                            },
                                            on: {
                                              input: (v) =>
                                                _vm.onFieldChange(
                                                  v,
                                                  "deliveryDate",
                                                  "value",
                                                  "text"
                                                ),
                                            },
                                            model: {
                                              value: _vm.deliveryDate,
                                              callback: function ($$v) {
                                                _vm.deliveryDate = $$v
                                              },
                                              expression: "deliveryDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-col"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-carousel-item",
                        { staticClass: "pa-3 pb-0", attrs: { value: 1 } },
                        [
                          _c(
                            "v-data-table",
                            {
                              staticClass: "filters-table",
                              attrs: {
                                items: _vm.savedFilters,
                                "items-per-page": 8,
                                "hide-default-header": "",
                                "hide-default-footer": _vm.savedFilters.length
                                  ? false
                                  : true,
                                dense: "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item",
                                  fn: function ({ item }) {
                                    return [
                                      _c("tr", [
                                        _c("td", [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(item.value.nameFilter)
                                            ),
                                          ]),
                                        ]),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "px-1 d-flex justify-end",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "actions" },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-3",
                                                    attrs: { color: "green" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.uploadFilter(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" mdi-upload ")]
                                                ),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: { color: "red" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.deleteItem(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" mdi-delete ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c("template", { slot: "no-data" }, [
                                _c(
                                  "div",
                                  { staticClass: "no-data" },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-1",
                                        attrs: { color: "warning", size: "20" },
                                      },
                                      [_vm._v(" mdi-alert ")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "font-weight-medium warning--text",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("label.noAvailableData")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider", { staticClass: "ma-0 mt-0" }),
              _c(
                "v-card-actions",
                { staticClass: "pa-3" },
                [
                  _vm.page == 0
                    ? [
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              top: "",
                              "offset-y": "",
                              transition: "scale-transition",
                              "close-on-content-click": false,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "primary pa-1",
                                            attrs: { small: "" },
                                            on: { click: _vm.focusFilterName },
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mr-1" },
                                            [_vm._v("mdi-filter-plus")]
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("upper.saveFilter"))
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              295913280
                            ),
                            model: {
                              value: _vm.saveFilterMenu,
                              callback: function ($$v) {
                                _vm.saveFilterMenu = $$v
                              },
                              expression: "saveFilterMenu",
                            },
                          },
                          [
                            _c(
                              "v-list",
                              {
                                directives: [
                                  {
                                    name: "click-outside",
                                    rawName: "v-click-outside",
                                    value: _vm.clearFilterName,
                                    expression: "clearFilterName",
                                  },
                                ],
                                staticClass: "pa-0",
                                attrs: { width: "16rem" },
                              },
                              [
                                _c(
                                  "v-list-item",
                                  { staticClass: "py-1 px-2" },
                                  [
                                    _c(
                                      "v-list-item-title",
                                      [
                                        _c("v-text-field", {
                                          ref: "filterName",
                                          attrs: {
                                            label: _vm.$t("label.filterName"),
                                          },
                                          model: {
                                            value: _vm.filterName,
                                            callback: function ($$v) {
                                              _vm.filterName = $$v
                                            },
                                            expression: "filterName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-icon",
                                      { staticClass: "ml-1" },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: { color: "success" },
                                            on: { click: _vm.saveFilter },
                                          },
                                          [_vm._v(" mdi-content-save ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "primary pa-1 ml-3",
                            attrs: { small: "" },
                            on: { click: _vm.resetAllFilters },
                          },
                          [
                            _c("v-icon", { staticClass: "mr-1" }, [
                              _vm._v("mdi-filter-remove"),
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("upper.resetFilter"))),
                            ]),
                          ],
                          1
                        ),
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            staticClass: "secondary pa-1",
                            attrs: { small: "" },
                            on: { click: _vm.setFilter },
                          },
                          [
                            _c("v-icon", { staticClass: "mr-1" }, [
                              _vm._v("mdi-magnify"),
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("upper.search"))),
                            ]),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }