import { commons } from './commons'
import { authHeader } from '@/helpers/auth-header'

export const usersManagerService = {
    login,
    createUser,
    updateUser,
    deleteUser,
    getByUsername,
    browse
};

const resourceUrl = process.env.VUE_APP_GSCV_ENGINE_URL + `/users-manager`

function login(username, password) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: username, password: password })
    };

    return fetch(process.env.VUE_APP_USER_MANAGER_URL + `/auth/`, requestOptions)
        .then(commons.handleJsonResponse)
        .then(response => {
            if (response.token) {
                localStorage.setItem('authToken', response.token);
            }
            return response;
        })
}

function createUser(user) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user),
    }
    console.log(resourceUrl)
    return fetch(resourceUrl, requestOptions).then(commons.handleJsonResponse)
}

function updateUser(id, user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user),
    }
    return fetch(resourceUrl + `/${id}`, requestOptions).then(
        commons.handleJsonResponse,
    )
}

function deleteUser(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    }
    return fetch(resourceUrl + `/${id}`, requestOptions).then(
        commons.handleJsonResponse,
    )
}

function getByUsername(username) {
    const requestOptions = {
        method: 'GET',
    }
    return fetch(resourceUrl + `/${username}`, requestOptions).then(
        commons.handleJsonResponse,
    )
}

function browse(offset, limit, filter, sortingList) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({
            offset: offset,
            limit: limit,
            filter: filter,
            sortingList: sortingList,
        }),
    }

    return fetch(resourceUrl + `/browse`, requestOptions).then(
        commons.handleJsonResponse,
    )
}




