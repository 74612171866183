<template>
  <v-container id="main" class="d-flex justify-center" fill-height fluid>
    <div class="d-flex flex-column align-center">
      <v-img
        v-if="isDemo"
        src="@/assets/circle-group-color.png"
        max-width="300"
        class="mb-2"
        contain
      />
      <v-img
        v-else
        src="@/assets/logo-italiansped-footer_140x50.png"
        max-width="140"
        contain
      />
      <h2 style="color: #c3342f;">{{ appTitle }}</h2>
      <v-btn color="rossoSacmi" class="mt-3 white--text" @click="signIn">
        {{ $t('label.login') }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { msalMixin } from 'vue-msal'

export default {
  name: 'LandingPage',
  mixins: [msalMixin],
  data() {
    return {
      appTitle: process.env.VUE_APP_TITLE,
    }
  },
  computed: {
    ...mapState('application', ['isDemo']),
  },
  methods: {
    ...mapActions('application', ['checkIsDemo']),
    signIn() {
      this.$msal.signIn()
    },
  },
  created() {
    this.checkIsDemo()
    if (this.$msal.isAuthenticated()) this.$router.push('/app')
  },
}
</script>
