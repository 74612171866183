var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-center pa-0" },
    [
      _c("SortByMenu", _vm._g({}, _vm.$listeners)),
      _vm.filtersPopUpAuth
        ? _c("FiltersDialog", _vm._g({ ref: "filtersDialog" }, _vm.$listeners))
        : _vm._e(),
      _c(
        "v-flex",
        { staticClass: "d-flex align-center", attrs: { shrink: "" } },
        [
          !_vm.filtersPopUpAuth
            ? _c(
                "v-btn",
                {
                  staticClass: "secondary",
                  attrs: { "x-small": "" },
                  on: { click: _vm.openFiltersPanel },
                },
                [_c("v-icon", { attrs: { size: "20" } }, [_vm._v("mdi-tune")])],
                1
              )
            : _vm._e(),
          _c(
            "v-btn",
            {
              staticClass: "mx-1",
              attrs: {
                "x-small": "",
                outlined: "",
                depressed: "",
                color: "secondary",
              },
              on: { click: _vm.resetFilter },
            },
            [_vm._v(" " + _vm._s(_vm.$t("label.resetAllFilters")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }