var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "30%" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _vm.showField
                ? _c(
                    "v-text-field",
                    _vm._g(
                      {
                        staticClass: "xs-input",
                        attrs: {
                          label: _vm.label,
                          value: _vm.contactLabel,
                          rules: _vm.rules,
                          "validate-on-blur": "",
                          outlined: "",
                        },
                      },
                      on
                    )
                  )
                : _c(
                    "v-textarea",
                    _vm._g(
                      {
                        attrs: {
                          label: _vm.label,
                          value: _vm.contactLabel,
                          rules: _vm.rules,
                          rows: "3",
                          "validate-on-blur": "",
                          "auto-grow": "",
                          outlined: "",
                        },
                      },
                      on
                    )
                  ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "text-overline primary--text pa-2 px-3" },
            [_vm._v(" " + _vm._s(_vm.title) + " ")]
          ),
          _c("v-divider", { staticClass: "ma-0" }),
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-form",
                    { ref: "form" },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0" },
                            [
                              _c("v-text-field", {
                                staticClass: "xs-input",
                                attrs: {
                                  label: _vm.$t("label.nameAndSurname"),
                                  rules: _vm.required,
                                  outlined: "",
                                  dense: "",
                                },
                                model: {
                                  value: _vm.contactDetails.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.contactDetails, "name", $$v)
                                  },
                                  expression: "contactDetails.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0" },
                            [
                              _c("v-text-field", {
                                staticClass: "xs-input",
                                attrs: {
                                  label: _vm.$t("label.email"),
                                  rules: _vm.required,
                                  clearable: "",
                                  outlined: "",
                                  dense: "",
                                },
                                model: {
                                  value: _vm.contactDetails.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.contactDetails, "email", $$v)
                                  },
                                  expression: "contactDetails.email",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0" },
                            [
                              _c("v-text-field", {
                                staticClass: "xs-input",
                                attrs: {
                                  label: _vm.$t("label.phone"),
                                  rules: _vm.required,
                                  hint: _vm.phoneNbrFormat,
                                  clearable: "",
                                  outlined: "",
                                  dense: "",
                                },
                                model: {
                                  value: _vm.contactDetails.phone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.contactDetails, "phone", $$v)
                                  },
                                  expression: "contactDetails.phone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }