var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "fit-content" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { width: "fit-content", height: "fit-content" } },
        [
          _c("v-card-text", { staticClass: "pa-3" }, [
            _c("span", { staticClass: "font-weight-medium" }, [
              _vm._v(_vm._s(_vm.message)),
            ]),
          ]),
          _c("v-divider", { staticClass: "my-0" }),
          _c(
            "v-card-actions",
            { staticClass: "pa-2" },
            [
              _c(
                "v-btn",
                {
                  attrs: { "x-small": "", color: "error" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("cancel")
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v(" mdi-close "),
                  ]),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { "x-small": "", color: "success" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("confirm")
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v(" mdi-check "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }