<template>
  <v-dialog v-model="dialog" :persistent="persistent" width="fit-content">
    <v-card>
      <v-card-title class="pa-6" />
      <v-divider class="ma-0 mb-0" />
      <v-card-text class="pa-12">
        <v-form ref="form">
          <v-container>
            <v-row>
              <v-col>
                <span>
                  {{ $t('message.insertForeignAgentForDestination') }}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  :label="$t('label.name')"
                  v-model="agent.name"
                  :rules="required"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  :label="$t('label.sapCode')"
                  v-model="agent.sapCode"
                  :rules="required"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  :label="$t('label.country')"
                  v-model="agent.country"
                  :rules="required"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  :label="$t('label.destination')"
                  v-model="agent.destination"
                  :rules="required"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider class="ma-0 mt-0" />
      <v-card-actions class="d-flex justify-end pa-3">
        <v-btn class="success" small @click="submit">
          <span>{{ $t('label.save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ForeignAgentFormDialog',
  emits: ['submit', 'blur-input'],
  data() {
    return {
      dialog: false,
      agent: {},
      persistent: false
    }
  },
  computed: {
    required() {
      return [(v) => !!v || this.$t('message.required')]
    }
  },
  watch: {
    dialog(isOpen) {
      if (!isOpen) {
        this.agent = {}
        this.handlePersistence(false)
        this.$emit('blur-input')
        return this.$refs.form.reset()
      }
    },
  },
  methods: {
    submit() {
      const valid = this.$refs.form.validate()
      if (!valid) return
      this.handlePersistence(true)
      this.$emit('submit', this.agent)
    },
    handleDialog() {
      this.dialog = !this.dialog
    },
    handlePersistence(persistent) {
      this.persistent = persistent
    }
  },
}
</script>

<style scoped>
.v-card__title {
  min-height: 36px !important;
}
</style>
