<template>
  <div>
    <v-row class="ma-0">
      <v-col cols="auto" class="pa-0 pt-6 pr-2" v-if="icon">
        <v-icon>{{ icon }}</v-icon>
      </v-col>
      <v-col class="text-col pa-0">
        <span class="readonly-field-label text-caption primary--text">
          {{ label }}
        </span>
        <br />
        <p v-if="value" :class="valueClass" v-html="value" />
        <p v-else class="text-caption one-line">
          {{ $t('label.noAvailableData') }}
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ReadonlyField',
  props: ['label', 'value', 'icon', 'textClass'],
  computed: {
    valueClass(){
      let valueClass = this.textClass
      if (!valueClass) return
      if (valueClass.includes('text-caption')) valueClass = valueClass.concat(' pt-1')
      return valueClass
    }
  }
}
</script>

<style scoped>
.text-col {
  white-space: pre-line; 
  /*max-width: calc(100% - 32px) !important;*/
}
.readonly-field-label {
  white-space: nowrap;
}
.one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
p {
  margin: 0;
}
/*.row {
  border: solid 1px orange;
}
.col {
  border: solid 1px green;
}*/
</style>
