<template>
  <v-container class="stepper-wrapper" fluid>
    <v-row class="ma-0 fill-height">
      <v-col class="d-table fill-height pa-1">
        <v-form ref="form" class="d-block pa-1 overflow-y-auto fill-height">
          <v-row dense>
            <v-col>
              <v-card>
                <div class="pa-2 pt-3">
                  <v-row dense>
                    <v-col class="d-flex">
                      <v-radio-group
                        v-model="documentType"
                        class="radio-group ma-0 pt-0"
                        hide-details
                        dense
                      >
                        <v-radio value="BL">
                          <template slot="label">
                            <span class="text-caption black--text">
                              Bill Of Lading
                            </span>
                          </template>
                        </v-radio>
                        <v-radio value="SW">
                          <template slot="label">
                            <span class="text-caption black--text">
                              Sea Waybill
                            </span>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col class="pt-2 pb-0">
                      <v-autocomplete
                        :label="$t('label.booking')"
                        v-model="selectedBookings"
                        :items="bookings"
                        item-text="carrierReference"
                        @change="(booking) => {selectBookingRequest(booking); updateFields()}"
                        :menu-props="menuProps"
                        :rules="required"
                        class="xs-input"
                        return-object
                        outlined
                        dense
                      />
                      <!-- multiple -->
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col class="pt-1 pb-0">
                      <v-text-field
                        :label="$t('label.placeOfIssue')"
                        :value="placeOfIssue ? placeOfIssue.cityName : null"
                        :rules="required"
                        class="xs-input"
                        outlined
                        dense
                      />
                    </v-col>
                    <v-col class="pt-1 pb-0">
                      <v-text-field
                        :value="contract"
                        :label="$t('label.contract')"
                        :rules="required"
                        class="xs-input"
                        outlined
                        dense
                      />
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>
            <v-col>
              <v-row dense>
                <v-col>
                  <v-card>
                    <div class="pa-2 px-5">
                      <v-row dense>
                        <v-col cols="4" class="d-flex align-center">
                          <span
                            class="text-caption"
                            style="min-width: max-content;"
                          >
                            Number of originals
                          </span>
                        </v-col>
                        <v-col cols="auto">
                          <v-row dense>
                            <v-col cols="auto" class="d-flex align-center">
                              <span class="text-caption">Unfreighted</span>
                            </v-col>
                            <v-col cols="auto">
                              <v-text-field
                                v-model="numberOfOriginals.unfreighted"
                                :rules="required"
                                class="number-select xs-input"
                                type="number"
                                hide-spin-buttons
                                hide-details
                                outlined
                                dense
                              />
                            </v-col>
                            <v-col cols="auto" class="d-flex align-center">
                              <span class="text-caption">Freighted</span>
                            </v-col>
                            <v-col cols="auto">
                              <v-text-field
                                v-model="numberOfOriginals.freighted"
                                :rules="required"
                                class="number-select xs-input"
                                type="number"
                                hide-spin-buttons
                                hide-details
                                outlined
                                dense
                              />
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="4" class="d-flex align-center">
                          <span class="text-caption">Number of copies</span>
                        </v-col>
                        <v-col cols="auto">
                          <v-row dense>
                            <v-col cols="auto" class="d-flex align-center">
                              <span class="text-caption">Unfreighted</span>
                            </v-col>
                            <v-col cols="auto">
                              <v-text-field
                                v-model="numberOfCopies.unfreighted"
                                :rules="required"
                                class="number-select xs-input"
                                type="number"
                                hide-spin-buttons
                                hide-details
                                outlined
                                dense
                              />
                            </v-col>
                            <v-col cols="auto" class="d-flex align-center">
                              <span class="text-caption">Freighted</span>
                            </v-col>
                            <v-col cols="auto">
                              <v-text-field
                                v-model="numberOfCopies.freighted"
                                :rules="required"
                                class="number-select xs-input"
                                type="number"
                                hide-spin-buttons
                                hide-details
                                outlined
                                dense
                              />
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col class="pa-1">
                  <v-card>
                    <div class="pa-5">
                      <v-row dense>
                        <v-col>
                          <v-switch
                            :label="$t('label.isShippedOnBoard')"
                            v-model="clauses.isShippedOnBoard"
                            class="shipped-switch ma-0 pa-0"
                            hide-details
                            inset
                          />
                        </v-col>
                      </v-row>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row class="flex-grow-0 ma-0">
      <v-col class="d-flex pa-2">
        <v-btn small icon color="primary" @click="exitRequest">
          <v-icon size="30">mdi-arrow-left-circle</v-icon>
        </v-btn>
        <v-spacer />
        <!-- <v-btn small color="primary" class="mr-2">
          <v-icon>mdi-step-backward</v-icon>
          <span>{{ $t('label.back') }}</span>
        </v-btn> -->
        <v-btn
          small
          color="primary"
          @click="submitDocumentProps"
          :disabled="loading"
        >
          <span>{{ $t('label.next') }}</span>
          <v-icon>mdi-play</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ShippingInstruction from '@/mixins/ShippingInstruction.js'
import { dateFilter } from '@/filters/dateFilter'
import { electronicFlowService } from '@/services/electronicFlow.service.js'
// import ReadonlyField from '@/components/elements/ReadonlyField.vue'

export default {
  name: 'ShippingInstructionInfo',
  props: [],
  mixins: [ShippingInstruction],
  // components: { ReadonlyField },
  data() {
    return {
      documentType: 'BL',
      placeOfIssue: null,
      contract: null,
      // isShippedOnBoard: null,
      clauses: {},
      numberOfOriginals: {
        freighted: 3,
        unfreighted: 3,
      },
      numberOfCopies: {
        freighted: 3,
        unfreighted: 3,
      },

      selectedBookings: null,

      currentBookingReq: null,
      loading: false,
    }
  },
  computed: {},
  methods: {
    async init() {
      this.loading = true
      this.loadSelectedBooking().then(() => this.updateFields())
      if (this.savedTransportDocumentType)
        this.documentType = this.savedTransportDocumentType
      if (this.savedNumberOfOriginals)
        this.numberOfOriginals = this.savedNumberOfOriginals
      if (this.savedNumberOfCopies)
        this.numberOfCopies = this.savedNumberOfCopies
      this.clauses.isShippedOnBoard = this.findClause('isShippedOnBoard')
      await this.initPlaceOfIssue()
      this.loading = false
    },
    findClause(name) {
      if (!this.savedClauses) return
      let clauses = this.savedClauses.filter((clause) => clause.name == name)
      return clauses.length ? clauses[0].value : null
    },
    async initPlaceOfIssue() {
      if (this.savedInvoicePayableAt)
        this.placeOfIssue = await this.getPlaceOfIssue(
          this.savedInvoicePayableAt.unLocationCode,
        )
      else this.placeOfIssue = await this.getPlaceOfIssue('ITGOA')
    },
    async getPlaceOfIssue(locode) {
      let locations = []
      switch (this.company) {
        case 'MAERSK':
          locations = await this.getMaerskLocation(locode)
          break
        case 'CMACGM':
          locations = await this.getCmaCgmLocation(locode)
          break
      }
      let placeOfIssue = locations.length ? locations[0] : null
      return placeOfIssue
    },
    getMaerskLocation(locode) {
      return electronicFlowService.getBookingRequestLocations('MAERSK', '', '', locode, 'CITY')
    },
    getCmaCgmLocation(locode) {
      return electronicFlowService.getBookingRequestLocations('CMACGM', '', '', locode, 'CITY')
    },
    updateFields() {
      this.contract = this.bookingReqContract
    },
    formatDate(value) {
      return dateFilter.formatDate(value)
    },
    // Submit
    submitDocumentProps() {
      if (!this.$refs.form.validate()) return
      this.setClauses(this.getClauses())
      this.setCarrierName(this.company)
      this.setTransportDocumentType(this.documentType)
      this.setAgreementReference(this.contract)
      this.setOriginalFreighted(this.numberOfOriginals.freighted)
      this.setOriginalUnfreighted(this.numberOfOriginals.unfreighted)
      this.setCopiesFreighted(this.numberOfCopies.freighted)
      this.setCopiesUnfreighted(this.numberOfCopies.unfreighted)
      // this.setCarrierBookingReference(new Array(this.selectedBookings[0].carrierReference))
      this.setCarrierBookingReference(
        new Array(this.selectedBookings.carrierReference),
      )
      this.setIsShipperOnboard(this.clauses.isShippedOnBoard)
      this.setInvoicePayableAt(this.getInvoicePayableAt(this.placeOfIssue))

      if (this.electronicFlowStatus == this.createdStatus)
        this.setStatus(this.detailsSelectedStatus)
      this.updateShippingInstructionAndSaveElectronicFlow()
      this.$emit('step', true)
    },
    getInvoicePayableAt(placeOfIssue) {
      return {
        locationName: placeOfIssue.cityName,
        unLocationCode: placeOfIssue.locationCode,
      }
    },
    // getClauses(){
    // let clause = {
    //   name: 'isShippedOnBoard',
    //   value: this.isShippedOnBoard
    // }
    //   this.selectedClauses.push(clause)
    //   return this.selectedClauses
    // },
    getClauses() {
      let selectedClauses = []
      Object.keys(this.clauses).forEach((key) => {
        let clause = {
          name: key,
          value: this.clauses[key],
        }
        selectedClauses.push(clause)
      })
      return selectedClauses
    },
    // loadBookingRequest() {
    //   electronicFlowService
    //     .findByCarrierReference(this.selectedBooking.carrierReference)
    //     .then((resp) => {
    //       if (!resp) return
    //       this.currentBookingReq = resp
    //     })
    //     .catch((error) =>
    //       this.setSnackbarMessage({ text: error, color: 'error' }),
    //     )
    // },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>
.stepper-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
}
.v-subheader {
  height: 2rem;
}
.radio-group::v-deep .v-input--radio-group__input {
  flex-direction: row;
}
.v-radio {
  max-width: max-content;
  margin: 0 1rem 0 0 !important;
}
.v-radio::v-deep .v-label {
  margin: 0 !important;
}
.equipment-check::v-deep label {
  margin-bottom: 0 !important;
}
.v-list::v-deep .v-list-item__action {
  margin-right: 0.5rem !important;
}
.xs-input::v-deep input {
  padding: 0.5rem !important;
  padding-left: 0 !important;
}
.xs-input::v-deep .v-label {
  position: unset !important;
  min-width: max-content;
  margin-bottom: 0 !important;
}
.xs-input::v-deep .v-input__slot {
  min-height: 0 !important;
  padding-right: 4px !important;
}
.xs-input::v-deep .v-input__append-inner {
  margin: 0 !important;
  align-self: center !important;
}
.xs-input::v-deep .v-label--active {
  position: absolute !important;
  margin-bottom: 0 !important;
}
.xs-input::v-deep .v-select__selections {
  padding: 0 !important;
}
.xs-input::v-deep .v-text-field__details {
  margin: 0 !important;
}
.shipped-switch {
  max-width: max-content !important;
}
.shipped-switch::v-deep label {
  margin: 0 !important;
}
.number-select {
  max-width: 3rem;
}
/*.row {
  border: solid 1px red;
}
.col {
  border: solid 1px orange;
}*/
</style>
