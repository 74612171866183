var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "d-block pa-0", attrs: { "fill-height": "", fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "d-flex flex-column py-0", attrs: { cols: "7" } },
            [
              _c("PraticheFilter", {
                ref: "praticheFilterSection",
                staticClass: "py-1",
                on: { "set-filter": _vm.filter },
              }),
              _c("v-data-table", {
                staticClass: "cards-table",
                attrs: {
                  headers: [],
                  items: _vm.shipmentSearch.browseResponse.resultList,
                  "hide-default-footer": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function ({ item }) {
                      return [
                        _c("ShipmentLineItem", {
                          attrs: { shipment: item },
                          on: {
                            "selected-shipment-document-nbr":
                              _vm.selectShipment,
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "v-row",
                          { staticClass: "ma-0", attrs: { dense: "" } },
                          [
                            _c(
                              "v-col",
                              { staticClass: "pa-0" },
                              [
                                _c("TableNav", {
                                  ref: "tableNavPratiche",
                                  attrs: {
                                    "browse-count":
                                      _vm.shipmentSearch.browseResponse
                                        .totalCount,
                                    "browse-limit":
                                      _vm.shipmentSearch.browseRequest.limit,
                                    "browse-offset":
                                      _vm.shipmentSearch.browseRequest.offset,
                                  },
                                  on: {
                                    "offset-changed":
                                      _vm.browseRequestOffsetChangedPraticheSearch,
                                    "get-current-page":
                                      _vm.setCurrentPagePraticheSearch,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "d-flex flex-column py-0", attrs: { cols: "5" } },
            [
              _c("InvoiceFilter", {
                ref: "invoicesFilterSection",
                staticClass: "py-1",
                on: { "set-filter": _vm.filter },
              }),
              _c("v-data-table", {
                staticClass: "cards-table",
                attrs: {
                  headers: [],
                  items: _vm.invoiceSearch.browseResponse.resultList,
                  "hide-default-footer": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function ({ item }) {
                      return [
                        _c("InvoiceLinkItem", {
                          attrs: { invoice: item },
                          on: { "invoice-document-nbr": _vm.handleMatch },
                        }),
                      ]
                    },
                  },
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "v-row",
                          { staticClass: "ma-0", attrs: { dense: "" } },
                          [
                            _c(
                              "v-col",
                              { staticClass: "pa-0" },
                              [
                                _c("TableNav", {
                                  ref: "tableNavInvoices",
                                  attrs: {
                                    "browse-count":
                                      _vm.invoiceSearch.browseResponse
                                        .totalCount,
                                    "browse-limit":
                                      _vm.invoiceSearch.browseRequest.limit,
                                    "browse-offset":
                                      _vm.invoiceSearch.browseRequest.offset,
                                  },
                                  on: {
                                    "offset-changed":
                                      _vm.browseRequestOffsetChangedInvoiceSearch,
                                    "get-current-page":
                                      _vm.setCurrentPageInvoiceSearch,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "2000", color: _vm.color },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function ({ attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { icon: "" },
                        on: {
                          click: function ($event) {
                            _vm.snackbar = false
                          },
                        },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [
                      _c("v-icon", { attrs: { color: "white" } }, [
                        _vm._v(" mdi-close "),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _c("v-icon", { staticClass: "mr-2", attrs: { color: "white" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.isSuccess ? "mdi-check-circle" : "mdi-alert-circle"
                ) +
                " "
            ),
          ]),
          _c("span", { staticClass: "white--text" }, [
            _vm._v(_vm._s(_vm.message)),
          ]),
        ],
        1
      ),
      [
        _c(
          "div",
          [
            _c(
              "v-dialog",
              {
                attrs: { width: "auto", persistent: "" },
                model: {
                  value: _vm.dialog,
                  callback: function ($$v) {
                    _vm.dialog = $$v
                  },
                  expression: "dialog",
                },
              },
              [
                _c(
                  "v-card",
                  [
                    _c(
                      "v-card-text",
                      { staticClass: "text-center pt-2 pb-0" },
                      [
                        _vm._v(" Invoice "),
                        _c("b", [_vm._v(_vm._s(_vm.params.invoiceNbr))]),
                        _vm._v(
                          " is already associated with the shipment number "
                        ),
                        _c("b", [
                          _vm._v(_vm._s(_vm.params.shipmentDocumentNbr)),
                        ]),
                        _c("br"),
                        _vm._v(" Are you sure you want to proceed? "),
                      ]
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "display-flex justify-center" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "success" },
                            on: { click: _vm.handleConfirm },
                          },
                          [_vm._v("Yes")]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "error" },
                            on: { click: _vm.handleCancel },
                          },
                          [_vm._v("No")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }