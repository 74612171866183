import { mapState, mapMutations } from 'vuex'
import { dateFilter } from '@/filters/dateFilter'
import { nodiService } from '@/services/nodi.service.js'
import { customersService } from '@/services/customers.service.js'
import { suppliersService } from '@/services/suppliers.service.js'
import { sapUsersService } from '@/services/sapUsers.service.js'
import { userParametersService } from '@/services/userParameters.service.js'
import { groupParametersService } from '@/services/groupParameters.service.js'
import CustomAutocomplete from '@/components/elements/CustomAutocomplete.vue'
import BrowseMemory from '@/mixins/BrowseMemory'

export default {
  name: 'Filters',
  emits: ['set-filter'],
  mixins: [BrowseMemory],
  components: { CustomAutocomplete },
  data() {
    return {
      page: 0,
      date: null,
      deliveryDate:null,
      saveFilterMenu: false,
      datePickerMenu: false,
      deliveryDatePickerMenu: false,
      menuProps: {
        offsetY: true,
        overflowY: 'auto',
        maxHeight: '10rem',
      },
      filterName: '',

      filters: [],
      browseFilter: {},
      visualFilter: {},
      filterSelection: {},

      savedFilters: [],
      nodiFilterList: [],
      sapUsersFilterList: [],
      saleChannelFilterList: [
        { text: 'Mare', value: '20' },
        { text: 'Aereo', value: '40' },
      ],
      incotermsFilterList: ['CFR', 'CIF', 'CIP', 'CPT', 'DAP', 'DAT', 'DDP', 'DDU', 'EXW', 'FAS', 'FCA', 'FOB', 'FRT'],
      statusFilterList: [
        { text: this.$t('label.toProcess'), value: 'RED' },
        { text: this.$t('label.waitingFeedback'), value: 'YELLOW' },
        { text: this.$t('label.processed'), value: 'GREEN' },
      ],
    }
  },
  computed: {
    ...mapState('account', ['user', 'userParameters', 'internalVisibilityAuth', 'filtersAreOpen']),
  },
  methods: {
    ...mapMutations('account', ['setFiltersAreOpen']),
    async loadDefaultFilters() {
      if (!this.user) return
      for (const group of this.user.groups) {
        let groupsFilters = await this.getFilterByGroup(group.id)
        groupsFilters.forEach((filter) => {
          this.filters.push(JSON.parse(filter.value))
        })
        let filtersOpen = await this.getFiltersOpenByGroup(group.id)
        filtersOpen.forEach((param) => {
          if (param.value == 'true') this.setFiltersAreOpen(true)
        })
      }
      await this.composeFilters()
      if (!this.getBrowseMemory('DashboardPage'))
      {
        this.saveBrowseMemory('DashboardPage', 50, 0, 1, [])
        this.saveFilterMemory('FiltersDialog')
        this.saveFilterMemory('FiltersDialog')
        this.saveFilterMemory('FiltersCard')
      }
    },
    getFilterByGroup(groupId) {
      return groupParametersService.getGroupParameter(groupId, 'FILTER')
    },
    getFiltersOpenByGroup(groupId) {
      return groupParametersService.getGroupParameter(groupId, 'FILTERS_OPEN')
    },
    async composeFilters() {
      let filters = {
        browseFilter: {},
        visualFilter: {}
      }
      for (const filter of this.filters) {
        for (const key of Object.keys(filter)) {
          let item = filter[key]
          let textKey = Object.keys(item)[0]
          let valueKey = Object.keys(item)[1]
          if (filter[key][valueKey] == null) item = await this.setCustomFilterValues(key)
          if (item == null) continue
          if (!this.visualFilter[key]) this.visualFilter[key] = []
          if (!this.browseFilter[key]) this.browseFilter[key] = []
          if (!this.visualFilter[key].includes(item[textKey])) this.visualFilter[key].push(item[textKey])
          if (!this.browseFilter[key].includes(item[valueKey])) this.browseFilter[key].push(item[valueKey])
          this.setFilterSelection(key, item, textKey, valueKey)
        }
      } return filters
    },
    async setCustomFilterValues(key) {
      let item = {}
      switch (key) {
        case 'userId':
          var sapUser = await this.findSapUsersByEmailIdentity()
          if (!sapUser) return null
          item.userName = sapUser.userName
          item.userId = sapUser.userId
          break
      } return item
    },
    setFilterSelection(key, item, textKey, valueKey){
      this.filterSelection[key] = {}
      this.filterSelection[key][textKey] = item[textKey]
      this.filterSelection[key][valueKey] = item[valueKey]
    },
    restoreFilterMemory() {
      this.resetInputs()
      let filterMemory = this.getFilterMemory(this.$options.name)
      if (!filterMemory) return
      if (filterMemory.currentFilter)
        this.filterSelection = filterMemory.currentFilter
      if (filterMemory.browseFilter)
        this.browseFilter = filterMemory.browseFilter
      if (filterMemory.visualFilter)
        this.visualFilter = filterMemory.visualFilter
    },
    // Filter handling
    onFieldChange(input, field, value, text) {
      if (!input) {
        delete this.filterSelection[field]
        delete this.browseFilter[field]
        delete this.visualFilter[field]
        return
      }
      this.filterSelection[field] = {}
      this.browseFilter[field] = []
      this.visualFilter[field] = []
      switch (true) {
        case Array.isArray(input[text]): // input is text: [] value: []
          this.filterSelection[field].text = input[text]
          this.filterSelection[field].value = input[value]
          if (!this.filterSelection[field].value.length)
            delete this.filterSelection[field]

          this.browseFilter[field] = input[value]
          if (!this.browseFilter[field].length) delete this.browseFilter[field]

          this.visualFilter[field] = input[text]
          if (!this.visualFilter[field].length) delete this.visualFilter[field]

          break
        case Array.isArray(input): // input is []
          this.filterSelection[field] = []
          if (!input.length) {
            delete this.browseFilter[field]
            delete this.visualFilter[field]
          }
          input.forEach((element) => {
            let item = {
              text: element[text],
              value: element[value],
            }
            this.filterSelection[field].push(item)
            this.browseFilter[field].push(element[value])
            this.visualFilter[field].push(element[text])
          })
          break
        case typeof input === 'string': // input is String
          this.filterSelection[field][text] = input
          this.filterSelection[field][value] = input
          this.browseFilter[field].push(input)
          this.visualFilter[field].push(input)
          break
        default:
          // input is single value
          this.filterSelection[field][text] = input[text]
          this.filterSelection[field][value] = input[value]
          this.browseFilter[field].push(input[value])
          this.visualFilter[field].push(input[text])
          break
      }
    },
    setFilter() {
      this.saveFilterMemory(this.$options.name)
      this.$emit('set-filter', this.convertFilter(this.browseFilter), this.convertFilter(this.visualFilter))
      this.dialog = false
    },
    saveFilterMemory(name){
      let filterMemory = {
        currentFilter: this.filterSelection,
        browseFilter: this.browseFilter,
        visualFilter: this.visualFilter,
      }
      this.setFilterMemory(name, filterMemory)
    },
    saveBrowseMemory(name, limit, offset, currentPage, sortBy) {
      let memory = {
        browseFilter: this.convertFilter(this.browseFilter),
        visualFilter: this.convertFilter(this.visualFilter),
        browseLimit: limit,
        browseOffset: offset,
        currentPage: currentPage,
        sortBy: sortBy,
      }
      this.setBrowseMemory(name, memory)
    },
    resetAllFilters() {
      this.resetFilters()
      this.setFilter()
    },
    resetFilters() {
      this.resetInputs()
      this.filterSelection = {}
      this.browseFilter = {}
      this.visualFilter = {}
      this.date = null
      this.deliveryDate = null
    },
    resetInputs() {
      if (this.objIsEmpty(this.$refs)) return
      if (this.$refs.documentDate) this.$refs.documentDate.reset()
      if (this.$refs.deliveryDate) this.$refs.deliveryDate.reset()
      if (this.$refs.accountUser) this.$refs.accountUser.reset()
      if (this.$refs.shipperInvoiceReference) this.$refs.shipperInvoiceReference.reset()
      this.$refs.saleChannel.reset()
      this.$refs.pol.reset()
      this.$refs.pod.reset()
      this.$refs.incoterms.reset()
      this.$refs.client.clearSelection()
      this.$refs.shipper.clearSelection()
      this.$refs.consignee.clearSelection()
      this.$refs.carrier.clearSelection()
      this.$refs.status.reset()
      this.$refs.equipment.reset()
    },
    convertFilter(filter) {
      let convertedFilter = {}
      Object.keys(filter).forEach((key) => {
        if (Array.isArray(filter[key]))
        convertedFilter[key] = filter[key].join(', ')
      })
      return convertedFilter
    },
    // Get data
    browseCustomers(search, ref) {
      let browseRequest = {
        offset: 0,
        limit: 1000,
        filter: {
          customerName: search,
          customerRole: ref,
        },
        sortingList: [{ column: 'customerName', direction: 'ASC' }],
      }
      customersService.browseInLike(browseRequest).then((resp) => {
        this.$refs[ref].showList(resp)
      })
    },
    browseSuppliers(search, ref) {
      let browseRequest = {
        offset: 0,
        limit: 1000,
        filter: { supplierName: search },
        sortingList: [{ column: 'supplierName', direction: 'ASC' }],
      }
      suppliersService.browseInLike(browseRequest).then((resp) => {
        this.$refs[ref].showList(resp)
      })
    },
    findAllNodi() {
      nodiService.getAllNodi().then((resp) => {
        this.nodiFilterList = resp
      })
    },
    findAllSapUsers() {
      sapUsersService.getAllSapUsers().then((resp) => {
        this.sapUsersFilterList = resp
      })
    },
    findAllSavedFilters() {
      return userParametersService
        .getUserParameter(this.user.id, 'FILTER')
        .then((resp) => {
          this.savedFilters = []
          resp.forEach((filter) => {
            filter.value = JSON.parse(filter.value)
            this.savedFilters.push(filter)
          })
        })
    },
    loadSavedFilters() {
      this.findAllSavedFilters().then(() => {
        this.page = 1
      })
    },
    findSapUsersByEmailIdentity() {
      return sapUsersService.getUserByEmailIdentity(this.user.email)
    },
    // Filter name
    focusFilterName() {
      setTimeout(() => {
        this.$refs.filterName.focus()
      }, 100)
    },
    clearFilterName() {
      this.filterName = ''
    },
    // Saved filters
    saveFilter() {
      this.filterSelection.nameFilter = this.filterName
      let filter = {
        id: null,
        userId: this.user.id,
        key: 'FILTER',
        valueType: 'JSON',
        value: JSON.stringify(this.filterSelection),
      }
      userParametersService.createUserParameter(filter).then((res) => {
        this.page = 0
        this.saveFilterMenu = false
      })
    },
    convertForUpload(filter) {
      let convertedFilters = {
        browse: {},
        visual: {},
      }

      Object.keys(filter).forEach((key) => {
        if (key == 'nameFilter') return
        let field = filter[key]
        convertedFilters.browse[key] = []
        convertedFilters.visual[key] = []
        switch (true) {
          case Array.isArray(field): // field is []
            field.forEach((element) => {
              convertedFilters.visual[key].push(element.text)
              convertedFilters.browse[key].push(element.value)
            })
            break
          case Array.isArray(field.text): // field is {text: [] value: []}
            field.text.forEach((element) =>
              convertedFilters.visual[key].push(element),
            )
            field.value.forEach((element) =>
              convertedFilters.browse[key].push(element),
            )
            break
          default:
            // field is single value
            convertedFilters.visual[key].push(field[Object.keys(field)[0]])
            convertedFilters.browse[key].push(field[Object.keys(field)[1]])
            break
        }
      })
      return convertedFilters
    },
    uploadFilter(item) {
      let convertedFilters = this.convertForUpload(item.value)
      this.filterSelection = item.value
      this.browseFilter = convertedFilters.browse
      this.visualFilter = convertedFilters.visual
      this.setFilter()
    },
    deleteItem(item) {
      const index = this.savedFilters.indexOf(item)
      this.savedFilters.splice(index, 1)
      userParametersService.deleteUserParameter(item.id)
    },
    // Utils
    objIsEmpty(object) {
      return Object.keys(object).length == 0
    },
    formatDate(value) {
      return dateFilter.formatDateDayDashed(value)
    },
    getNodoDescription(item) {
      return `${item.countryId} - ${item.countryName} (${item.locode})`
    },
  }
}
