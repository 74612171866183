<template>
  <v-dialog v-model="dialog" width="30%">
    <template v-slot:activator="{ on }">
      <v-textarea
        v-on="on"
        :label="label"
        :value="addressLabel"
        :rules="rules"
        rows="3"
        validate-on-blur
        auto-grow
        outlined
      />
      <!-- class="text-caption font-weight-medium" -->
      <!-- :value="null"
      :rules="validatePlaceOfReceipt" -->
    </template>
    <v-card>
      <v-card-title class="text-overline primary--text pa-2 px-3">
        {{ title }}
      </v-card-title>
      <v-divider class="ma-0" />
      <v-card-text class="pa-0">
        <v-container>
          <v-form ref="form">
            <v-row dense>
              <v-col class="pb-0">
                <v-text-field
                  :label="$t('label.company')"
                  v-model="address.companyName"
                  :rules="required"
                  class="xs-input"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="pb-0">
                <v-text-field
                  :label="$t('label.street')"
                  v-model="address.street"
                  :rules="required"
                  class="xs-input"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="3" class="pb-0">
                <v-text-field
                  :label="$t('label.number')"
                  v-model="address.streetNumber"
                  :rules="required"
                  class="xs-input"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  :label="$t('label.city')"
                  v-model="address.city"
                  class="xs-input"
                  :rules="required"
                  hide-details
                  outlined
                  readonly
                  dense
                />
              </v-col>
              <v-col>
                <v-text-field
                  :label="$t('label.postCode')"
                  v-model="address.postCode"
                  class="xs-input"
                  :rules="required"
                  hide-details
                  outlined
                  dense
                />
              </v-col>
              <v-col>
                <v-text-field
                  :label="$t('label.country')"
                  v-model="address.country"
                  class="xs-input"
                  :rules="required"
                  hide-details
                  outlined
                  readonly
                  dense
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'HaulageLocation',
  props: ['title', 'label', 'rules', 'location'],
  emits: ['set-address'],
  components: {},
  data() {
    return {
      dialog: false,
      address: {},
      addressLabel: null,
    }
  },
  computed: {
    required() {
      return [(v) => !!v || this.$t('message.required')]
    },
  },
  watch: {
    address: {
      handler() {
        this.getLabel()
      },
      deep: true,
    },
  },
  methods: {
    init() {
      this.$nextTick(() => {
        if (this.location) this.address = this.location
        this.getLabel()
      })
    },
    getLabel() {
      let res = ``
      if (this.address.companyName) res += `${this.address.companyName}\n`
      if (this.address.street && this.address.streetNumber) res += `${this.address.street} ${this.address.streetNumber}\n`
      if (this.address.city) res += `${this.address.city}, ` 
      if (this.address.postCode) res += `${this.address.postCode}, `
      if (this.address.country) res += `${this.address.country}`
      this.addressLabel = res
      this.$emit('set-address', this.address)
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>
.v-textarea::v-deep textarea {
  line-height: 1.2 !important;
  max-height: fit-content;
  height: fit-content !important;
  margin-bottom: 0.5rem;
}
.v-textarea::v-deep .v-input__slot {
  margin-bottom: 4px !important;
}
.v-input::v-deep .v-text-field__details {
  margin: 0 !important;
}
.xs-input::v-deep input {
  padding: 0.5rem !important;
  padding-left: 0 !important;
}
.xs-input::v-deep .v-label {
  position: unset !important;
  min-width: max-content;
  margin-bottom: 0 !important;
}
.xs-input::v-deep .v-input__slot {
  min-height: 0 !important;
  padding-right: 4px !important;
}
.xs-input::v-deep .v-input__append-inner {
  margin: 0 !important;
  align-self: center !important;
}
.xs-input::v-deep .v-label--active {
  position: absolute !important;
  margin-bottom: 0 !important;
}
.v-textarea::v-deep label {
  font-weight: normal;
}
/*.row {
  border: solid 1px red;
}
.col {
  border: solid 1px orange;
}*/
</style>
