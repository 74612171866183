var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-flex",
    { attrs: { shrink: "" } },
    [
      _c(
        "v-card",
        { staticClass: "shadow-none", attrs: { outlined: "" } },
        [
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _c(
                "v-row",
                { staticClass: "my-1", attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-text-field", {
                        ref: "sellerName",
                        staticClass: "py-1 pl-2 custom-text-field",
                        attrs: {
                          value: _vm.filterSelection.sellerName
                            ? _vm.filterSelection.sellerName.text
                            : "",
                          label: _vm.$t("label.sellerName"),
                          "menu-props": _vm.menuProps,
                          "hide-details": "",
                          clearable: "",
                          dense: "",
                        },
                        on: {
                          change: (v) =>
                            _vm.onFieldChange(v, "sellerName", "text", "value"),
                          keypress: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.setFilter.apply(null, arguments)
                          },
                          "click:clear": function ($event) {
                            return _vm.onClear("sellerName")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-text-field", {
                        ref: "invoiceHolderName",
                        staticClass: "pa-1 custom-text-field",
                        attrs: {
                          value: _vm.filterSelection.invoiceHolderName
                            ? _vm.filterSelection.invoiceHolderName.text
                            : "",
                          label: _vm.$t("label.invoiceHolderName"),
                          "menu-props": _vm.menuProps,
                          "hide-details": "",
                          clearable: "",
                          dense: "",
                        },
                        on: {
                          change: (v) =>
                            _vm.onFieldChange(
                              v,
                              "invoiceHolderName",
                              "text",
                              "value"
                            ),
                          keypress: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.setFilter.apply(null, arguments)
                          },
                          "click:clear": function ($event) {
                            return _vm.onClear("invoiceHolderName")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-text-field", {
                        ref: "consigneeName",
                        staticClass: "pa-1 custom-text-field",
                        attrs: {
                          value: _vm.filterSelection.consigneeName
                            ? _vm.filterSelection.consigneeName.text
                            : "",
                          label: _vm.$t("label.consigneeName"),
                          "menu-props": _vm.menuProps,
                          "hide-details": "",
                          clearable: "",
                          dense: "",
                        },
                        on: {
                          change: (v) =>
                            _vm.onFieldChange(
                              v,
                              "consigneeName",
                              "text",
                              "value"
                            ),
                          keypress: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.setFilter.apply(null, arguments)
                          },
                          "click:clear": function ($event) {
                            return _vm.onClear("consigneeName")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-text-field", {
                        ref: "invoiceNbr",
                        staticClass: "pa-1 custom-text-field",
                        attrs: {
                          value: _vm.filterSelection.invoiceNbr
                            ? _vm.filterSelection.invoiceNbr.text
                            : "",
                          label: _vm.$t("label.invoiceNbr"),
                          "menu-props": _vm.menuProps,
                          "hide-details": "",
                          clearable: "",
                          dense: "",
                        },
                        on: {
                          change: (v) =>
                            _vm.onFieldChange(v, "invoiceNbr", "text", "value"),
                          keypress: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.setFilter.apply(null, arguments)
                          },
                          "click:clear": function ($event) {
                            return _vm.onClear("invoiceNbr")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-select", {
                        ref: "shipmentNbr",
                        staticClass: "py-1 pr-2 custom-text-field",
                        attrs: {
                          value: _vm.filterSelection.shipmentNbr,
                          label: _vm.$t("label.shipmentNbr"),
                          items: ["Already Matched"],
                          "return-object": "",
                          "menu-props": _vm.menuProps,
                          "hide-details": "",
                          clearable: "",
                          dense: "",
                        },
                        on: {
                          change: (v) =>
                            _vm.onFieldChange(
                              v,
                              "shipmentNbrNotMatched",
                              "value",
                              "text"
                            ),
                          "click:clear": function ($event) {
                            return _vm.onClear("shipmentNbrNotMatched")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }