<template>
  <v-container class="stepper-wrapper" fluid>
    <v-row dense>
      <v-col>
        <v-container class="d-table pa-2" fill-height fluid>
          <v-row class="d-table-row" dense>
            <v-col>
              <v-text-field
                v-model="imballiSearch"
                :label="$t('label.search')"
                append-icon="mdi-magnify"
                class="ma-0 pa-0 text-caption"
                single-line
                hide-details
              />
            </v-col>
          </v-row>
          <v-row class="d-table-row fill-height" dense>
            <v-col class="fill-height overflow-y-auto">
              <v-data-table
                v-model="imballiSelected"
                :headers="imballiHeaders"
                :items="imballi"
                :search="imballiSearch"
                :items-per-page="1000"
                :show-select="!uneditableRequest"
                hide-default-footer
                checkbox-color="primary"
                class="pa-0"
                dense
              >
                <template slot="no-data">
                  <v-icon color="warning" class="mr-1" size="20">
                    mdi-alert
                  </v-icon>
                  <span class="font-weight-medium warning--text">
                    {{ $t('label.noAvailableData') }}
                  </span>
                </template>
                <template v-slot:item.transportOrderStatus="{ item }">
                  <span>
                    {{ formatTrasportOrderStatus(item.transportOrderStatus) }}
                  </span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row class="flex-grow-0" dense>
      <v-col class="d-flex pa-3">
        <v-btn small icon color="primary" @click="exitRequest">
          <v-icon size="30">mdi-arrow-left-circle</v-icon>
        </v-btn>
        <v-spacer />
        <!-- <v-btn small color="primary" class="mr-2" @click="$emit('exit-request')">
          <v-icon>mdi-step-backward</v-icon>
          <span>{{ $t('label.back') }}</span>
        </v-btn> -->
        <v-btn small color="primary" :disabled="disableNext" @click="submitBookingRequestDetails">
          <span>{{ $t('label.next') }}</span>
          <v-icon>mdi-play</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Booking from '@/mixins/Booking.js'

export default {
  name: 'BookingImballi',
  props: ['imballi'],
  mixins: [Booking],
  data() {
    return {
      imballiSearch: null,
      imballiSelected: [],
      imballiHeaders: [
        { text: this.$t('label.saleOrder'), value: 'saleOrder' },
        { text: this.$t('label.position'), value: 'saleOrderLine' },
        { text: this.$t('label.quantity'), value: 'quantity' },
        { text: this.$t('label.transportState'), value: 'transportOrderStatus' },
        { text: this.$t('label.item'), value: 'itemDescription' },
        { text: this.$t('label.booking'), value: 'bookingNbr' },
      ],
    }
  },
  computed: {
    kilosUnitMeasure() {
      switch (this.company) {
        case 'MAERSK':
          return 'KGS'
        case 'CMACGM':
        case 'YANGMING':
          return 'KGM'
      }
      return null
    },
    squareMeterUnitMeasure() {
      switch (this.company) {
        case 'MAERSK':
          return 'MTQ'
        case 'CMACGM':
          return 'MTQ'
      }
      return null
    },
  },
  watch: {
    imballiSelected: {
      handler(list) {
        this.disableNext = list.length && this.electronicFlowStatus != this.pendingStatus ? false : true
      },
      deep: true,
    },
  },
  methods: {
    init(){
      this.setImballiSelected()
    },
    setImballiSelected() {
      this.imballiSelected = this.imballi.filter((i) =>
        this.savedRequestDetails.some((d) => i.id == d.shipmentLineId)
      )
    },
    // Submit
    submitBookingRequestDetails() {
      if (this.electronicFlow.status == this.createdStatus)
        this.setStatus(this.detailsSelectedStatus)

      let equipments = this.imballiToEquipments()

      this.setRequestDetails(this.refreshSelectedDetails())
      this.setRequestEquipments(JSON.stringify(equipments.requestEquipments))
      this.setRequestedEquipments(equipments.requestedEquipments) // BookingRequest

      this.updateRequestAndSaveElectronicFlow()
      this.$emit('step', true)
    },
    refreshSelectedDetails() {
      let requestDetails = this._.cloneDeep(this.electronicFlow.bookingRequest.requestDetails)
      this.imballiSelected.forEach((imballo) => {
        let isAlreadySaved = requestDetails.some((d) => imballo.id == d.shipmentLineId)
        if (!isAlreadySaved) requestDetails.push({ shipmentLineId: imballo.id })
      })
      let toRemove = []
      requestDetails.forEach((detail) => {
        let hasBeenRemoved = !this.imballiSelected.some((i) => detail.shipmentLineId == i.id)
        if (hasBeenRemoved) toRemove.push(detail)
      })
      let clearedDetails = requestDetails.filter((detail) => !toRemove.includes(detail))
      return clearedDetails
    },
    imballiToEquipments() {
      let containersMap = new Map()
      this.imballiSelected.forEach((imballo) => {
        if (imballo.containerCode) {
          if (!containersMap.has(imballo.containerCode.isoCode)) containersMap.set(imballo.containerCode.isoCode, [imballo])
          else containersMap.get(imballo.containerCode.isoCode).push(imballo)
        } else {
          if (!containersMap.has(imballo.itemDescription)) containersMap.set(imballo.itemDescription, [imballo])
          else containersMap.get(imballo.itemDescription).push(imballo)
        }
      })
      let savedEquipments = { requestEquipments: [], requestedEquipments: []}
      containersMap.forEach((equipments, equipmentType) => {
        let totWeight = 0
        let totVolume = 0
        let code
        equipments.forEach((equipment) => {
          totWeight += equipment.grossWeight
          if (equipment.containerCode) totVolume += equipment.containerCode.externalVolume
          code = equipment.itemDescription
        })
        let equipmentItem = this.getRequestedEquipment(equipmentType, equipments.length, totWeight)
        savedEquipments.requestEquipments.push({
          // totWeight: totWeight, // totVolume: totVolume,
          code: code,
          requestedEquipments: equipmentItem,
        })
        savedEquipments.requestedEquipments.push(equipmentItem)
      })
      return savedEquipments
    },
    getRequestedEquipment(equipmentCode, count, totWeight) {
      return {
        ISOEquipmentCode: equipmentCode,
        equipmentWeight: totWeight,
        equipmentWeightUnit: this.kilosUnitMeasure,
        equipmentVolume: null,
        equipmentVolumeUnit: this.squareMeterUnitMeasure,
        isShipperOwned: false,
        units: count
      }
    },
    // Utils
    formatTrasportOrderStatus(transportOrderStatus) {
      switch (transportOrderStatus) {
        case '@00@':
          return 'Ritiro Creato'
        case '@3U@':
          return 'Ritiro Congelato'
        case '@4A@':
          return 'Ritiro Trasportato'
        case '@8N@':
          return 'Ritiro Congelato'
        case '@8R@':
          return 'Annulla Stampa'
      }
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>
.stepper-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
}
.v-data-table::v-deep .v-input--selection-controls__ripple {
  height: 0 !important;
}
</style>
