<template>
  <v-container class="stepper-wrapper" fluid>
    <v-row class="ma-0 fill-height">
      <v-col class="d-table fill-height pa-1">
        <component
          :is="componentToShow.component"
          :ref="componentToShow.ref"
          :key="componentToShow.component"
          @submit-payments="submitPaymentDetails"
          v-on="$listeners"
        />
      </v-col>
    </v-row>
    <v-row class="flex-grow-0 ma-0">
      <v-col class="d-flex pa-2">
        <v-btn small icon color="primary" @click="exitRequest">
          <v-icon size="30">mdi-arrow-left-circle</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn small color="primary" class="mr-2" @click="$emit('step', false)">
          <v-icon>mdi-step-backward</v-icon>
          <span>{{ $t('label.back') }}</span>
        </v-btn>
        <v-btn small color="primary" @click="submitPaymentDetails">
          <span>{{ $t('label.next') }}</span>
          <v-icon>mdi-play</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ShippingInstruction from '@/mixins/ShippingInstruction.js'
import ShippingInstructionPaymentsCmaCgm from '@/components/elements/shipping-instruction/ShippingInstructionPaymentsCmaCgm.vue'
import ShippingInstructionPaymentsMaersk from '@/components/elements/shipping-instruction/ShippingInstructionPaymentsMaersk.vue'

export default {
  name: 'ShippingInstructionPayments',
  props: [],
  mixins: [ShippingInstruction],
  components: {
    ShippingInstructionPaymentsCmaCgm,
    ShippingInstructionPaymentsMaersk,
  },
  data() {
    return {
      paymentComponents: [
        {
          component: 'ShippingInstructionPaymentsMaersk',
          ref: 'maerskPayments',
        },
        {
          component: 'ShippingInstructionPaymentsCmaCgm',
          ref: 'cmaCgmPayments',
        },
      ],
    }
  },
  computed: {
    componentToShow() {
      switch (this.company) {
        case 'MAERSK':
          return this.paymentComponents[0]
        case 'CMACGM':
          return this.paymentComponents[1]
      }
      return null
    },
  },
  methods: {
    // Submit
    submitPaymentDetails() {
      let validated = false
      let ref
      switch (true) {
        case this.$refs.maerskPayments != undefined:
          ref = this.$refs.maerskPayments
          // if (ref.$refs.form.validate()) validated = true
          break
        case this.$refs.cmaCgmPayments != undefined:
          ref = this.$refs.cmaCgmPayments
          if (ref.validateForm()) validated = true
          break
      }
      if (!validated) return
      ref.submit()
      this.$emit('step', true)
    },
  },
}
</script>

<style scoped>
.stepper-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
}
/*.row {
  border: solid 1px red;
}
.col {
  border: solid 1px orange;
}*/
</style>
