import { authHeader } from '@/helpers/auth-header'
import { commons } from './commons'

export const containerCodeService = {
  findAll,
}

const resourceUrl = process.env.VUE_APP_GSCV_ENGINE_URL + `/container-code`

function findAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl, requestOptions).then(commons.handleJsonResponse)
}
