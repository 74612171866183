export const dateFilter = {
  formatDate,
  formatDateShort,
  formatDateDay,
  formatDateDayShort,
  formatDateDayDashed,
  formatDateDayIso,
  formatDateTime,
};

import moment from "moment";

function formatDate(value) {
  if (!value) return;
  return moment(String(value)).format("DD/MM/YYYY HH:mm");
}
function formatDateShort(value) {
  if (!value) return;
  return moment(String(value)).format("DD/MM/YY HH:mm");
}
function formatDateDay(value) {
  if (!value) return;
  return moment(String(value)).format("DD/MM/YYYY");
}

function formatDateDayShort(value) {
  if (!value) return;
  return moment(String(value)).format("DD/MM/YY");
}

function formatDateDayDashed(value) {
  if (!value) return;
  return moment(String(value)).format("DD-MM-YYYY");
}

function formatDateDayIso(value) {
  if (!value) return;
  return moment(String(value)).format("YYYY-MM-DD");
}

function formatDateTime(value) {
  if (!value) return;
  return moment(String(value)).format("HH:mm");
}
