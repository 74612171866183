var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "d-block", attrs: { "fill-height": "", fluid: "" } },
    [
      _vm.loading
        ? _c("v-progress-linear", {
            staticClass: "mb-1",
            attrs: { indeterminate: "", color: "primary" },
          })
        : _vm._e(),
      _c(
        "v-expansion-panels",
        {
          staticClass: "align-self-start",
          attrs: { width: "100%" },
          model: {
            value: _vm.panel,
            callback: function ($$v) {
              _vm.panel = $$v
            },
            expression: "panel",
          },
        },
        _vm._l(_vm.users, function (user, i) {
          return _c(
            "v-expansion-panel",
            { key: i },
            [
              _c(
                "v-expansion-panel-header",
                {
                  staticClass: "px-3 py-0",
                  attrs: { "disable-icon-rotate": "" },
                  scopedSlots: _vm._u(
                    [
                      _vm.panel == i
                        ? {
                            key: "actions",
                            fn: function () {
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      fab: "",
                                      small: "",
                                      color: "success",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.updateUser(_vm.currentUser)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { size: "25", color: "white" } },
                                      [_vm._v(" mdi-content-save ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-0" },
                    [
                      _c("v-col", { staticClass: "d-flex align-center pa-0" }, [
                        _c("span", [_vm._v(_vm._s(user.lastName))]),
                        _c("span", { staticClass: "ml-2" }, [
                          _vm._v(_vm._s(user.firstName)),
                        ]),
                      ]),
                      _c("v-col", { staticClass: "d-flex align-center pa-0" }, [
                        _c("span", [_vm._v(_vm._s(user.email))]),
                      ]),
                      _c(
                        "v-col",
                        { staticClass: "pa-0" },
                        _vm._l(user.groups, function (element, i) {
                          return _c(
                            "v-chip",
                            {
                              key: i,
                              staticClass: "ma-2 pa-2 text-overline",
                              attrs: { color: "primary", small: "" },
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "pr-1", attrs: { small: "" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.getIcon(element.groupDes)) +
                                      " "
                                  ),
                                ]
                              ),
                              _vm._v(" " + _vm._s(element.groupDes) + " "),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel-content",
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-0" },
                    [
                      _c("v-col", { staticClass: "pa-0 pl-3" }, [
                        _c(
                          "span",
                          { staticClass: "text-overline primary--text" },
                          [_vm._v(" " + _vm._s(_vm.$t("label.settings")) + " ")]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "mx-2 mt-0 mb-5" }),
                  _c(
                    "v-row",
                    { staticClass: "ma-0" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-5",
                          attrs: {
                            cols: _vm.$vuetify.breakpoint.smAndDown
                              ? "12"
                              : "2",
                          },
                        },
                        [
                          _c("v-select", {
                            staticClass: "no-overflow white--text",
                            attrs: {
                              items: _vm.languageItems,
                              label: _vm.$t("label.language"),
                              "item-text": "text",
                              "return-object": "",
                              "prepend-icon": "mdi-translate",
                              "hide-details": "",
                              dense: "",
                              flat: "",
                            },
                            model: {
                              value: _vm.userParams["language"],
                              callback: function ($$v) {
                                _vm.$set(_vm.userParams, "language", $$v)
                              },
                              expression: "userParams['language']",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-5",
                          attrs: {
                            cols: _vm.$vuetify.breakpoint.smAndDown
                              ? "12"
                              : "5",
                          },
                        },
                        [
                          _c("v-select", {
                            staticClass: "no-overflow white--text",
                            attrs: {
                              label: _vm.$t("label.groups"),
                              items: _vm.groups,
                              "item-text": "groupDes",
                              "item-value": "id",
                              "return-object": "",
                              "prepend-icon": "mdi-account-group",
                              "hide-details": "",
                              multiple: "",
                              dense: "",
                              flat: "",
                            },
                            model: {
                              value: _vm.currentUser.groups,
                              callback: function ($$v) {
                                _vm.$set(_vm.currentUser, "groups", $$v)
                              },
                              expression: "currentUser.groups",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-5",
                          attrs: {
                            cols: _vm.$vuetify.breakpoint.smAndDown
                              ? "12"
                              : "5",
                          },
                        },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "offset-y": "",
                                attach: "",
                                "close-on-click": "",
                                "open-on-click": false,
                                "close-on-content-click": false,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        !_vm.showSearch["customers"]
                                          ? _c("v-text-field", {
                                              ref: "customers",
                                              refInFor: true,
                                              attrs: {
                                                label:
                                                  _vm.$t("label.customers"),
                                                value: _vm.formatCustomers,
                                                "item-text": "customerName",
                                                "prepend-inner-icon":
                                                  "mdi-account-arrow-right",
                                                "append-icon": user.customers
                                                  .length
                                                  ? "mdi-close"
                                                  : "",
                                                "hide-details": "",
                                                readonly: "",
                                                dense: "",
                                              },
                                              on: {
                                                "click:append": function (
                                                  $event
                                                ) {
                                                  _vm.currentUser.customers = []
                                                },
                                                click: function ($event) {
                                                  _vm.showSearch[
                                                    "customers"
                                                  ] = true
                                                },
                                              },
                                            })
                                          : _c(
                                              "v-text-field",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    label:
                                                      _vm.$t("label.customers"),
                                                    "prepend-inner-icon":
                                                      "mdi-account-arrow-right",
                                                    "append-icon":
                                                      "mdi-magnify",
                                                    "hide-details": "",
                                                    dense: "",
                                                  },
                                                  on: {
                                                    click: [
                                                      function ($event) {
                                                        $event.stopPropagation()
                                                      },
                                                      function ($event) {
                                                        return _vm.handleSearch(
                                                          "customers"
                                                        )
                                                      },
                                                    ],
                                                    "click:append": function (
                                                      $event
                                                    ) {
                                                      return _vm.searchCustomers(
                                                        _vm.search.customers,
                                                        "customers"
                                                      )
                                                    },
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      return _vm.searchCustomers(
                                                        _vm.search.customers,
                                                        "customers"
                                                      )
                                                    },
                                                    blur: function ($event) {
                                                      return _vm.resetSearchBox(
                                                        "shipperId"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.search.customers,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.search,
                                                        "customers",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "search.customers",
                                                  },
                                                },
                                                on
                                              )
                                            ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.menuCustomer,
                                callback: function ($$v) {
                                  _vm.menuCustomer = $$v
                                },
                                expression: "menuCustomer",
                              },
                            },
                            [
                              _c(
                                "v-card",
                                { staticClass: "dropdown-menu" },
                                [
                                  _c("v-data-table", {
                                    staticClass: "custom-table",
                                    attrs: {
                                      headers: [
                                        { text: "", value: "customerName" },
                                      ],
                                      items: _vm.customersFilterList,
                                      "items-per-page": 4,
                                      page: _vm.tablePage,
                                      "hide-default-header": "",
                                      "checkbox-color": "primary",
                                      "show-select": "",
                                      dense: "",
                                    },
                                    on: {
                                      "update:page": function ($event) {
                                        _vm.tablePage = $event
                                      },
                                      "item-selected": (v) =>
                                        _vm.selectItem(
                                          v,
                                          "customers",
                                          "customerId"
                                        ),
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item.customerName",
                                          fn: function ({ item }) {
                                            return [
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "custom-table-td",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.customerName
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.currentUser.customers,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.currentUser,
                                          "customers",
                                          $$v
                                        )
                                      },
                                      expression: "currentUser.customers",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }