import { authHeader } from '@/helpers/auth-header'
import { commons } from './commons'

export const foreignAgentService = {
  getForeignAgentForPratica,
  createForeignAgentForPratica,
  updateForeignAgent,
}

const resourceUrl = process.env.VUE_APP_GSCV_ENGINE_URL + `/foreign-agent`

function getForeignAgentForPratica(praticaId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/pratica/${praticaId}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function createForeignAgentForPratica(praticaId) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/pratica/${praticaId}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function updateForeignAgent(id, data) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }
  return fetch(resourceUrl + `/${id}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}
