<template>
  <v-container class="stepper-box flex-column align-center" fluid>
    <v-row class="ma-0" style="max-width: max-content;">
      <v-col>
        <v-avatar size="70" :class="convertedStatus > 0 ? 'avatar-complete' : 'avatar'">
          <v-icon
            size="40"
            :color="convertedStatus > 0 ? 'primary' : 'grey'"
            rounded
          >
            mdi-factory
          </v-icon>
        </v-avatar>
      </v-col>
      <v-col class="divider-col" v-if="!$vuetify.breakpoint.smAndDown">
        <hr :class="convertedStatus >= 1 ? 'solid-line' : 'dashed-line'" />
      </v-col>
      <v-col>
        <v-avatar size="70" :class="convertedStatus >= 1 ? 'avatar-complete' : 'avatar'">
          <v-icon
            v-if="saleChannelDesc(pratica.saleChannel) == 'Nave'"
            size="40"
            :color="convertedStatus >= 1 ? 'primary' : 'grey'"
            rounded
          >
            mdi-ferry
          </v-icon>
          <v-icon
            v-if="saleChannelDesc(pratica.saleChannel) == 'Aereo'"
            size="40"
            :color="convertedStatus > 1 ? 'primary' : 'grey'"
            rounded
          >
            mdi-airplane
          </v-icon>
          <v-icon
              v-if="saleChannelDesc(pratica.saleChannel) == 'Terra'"
              size="40"
              :color="convertedStatus > 1 ? 'primary' : 'grey'"
              rounded
          >
            mdi-truck
          </v-icon>
        </v-avatar>
      </v-col>
      <v-col class="divider-col" v-if="!$vuetify.breakpoint.smAndDown">
        <hr :class="convertedStatus > 2 ? 'solid-line' : convertedStatus == 2 ? 'dashed-line-pending' : 'dashed-line'"  />
      </v-col>
      <v-col>
        <v-avatar size="70" :class="convertedStatus == 3 ? 'avatar-complete' : 'avatar'">
          <v-icon
            v-if="saleChannelDesc(pratica.saleChannel) == 'Nave'"
            size="40"
            :color="convertedStatus == 3 ? 'primary' : 'grey'"
            rounded
          >
            mdi-ferry
          </v-icon>
          <v-icon
            v-if="saleChannelDesc(pratica.saleChannel) == 'Aereo'"
            size="40"
            :color="convertedStatus == 3 ? 'primary' : 'grey'"
            rounded
          >
            mdi-airplane
          </v-icon>
          <v-icon
              v-if="saleChannelDesc(pratica.saleChannel) == 'Terra'"
              size="40"
              :color="convertedStatus > 1 ? 'primary' : 'grey'"
              rounded
          >
            mdi-truck
          </v-icon>
        </v-avatar>
      </v-col>
    </v-row>

    <v-row class="ma-0" style="max-width: fit-content;">
      <v-col>
        <div class="address-box">
          <span v-html="steps.pickupLocation" />
        </div>
      </v-col>
      <v-col class="divider-col" v-if="!$vuetify.breakpoint.smAndDown" />
      <v-col class="d-block">
        <div class="address-box">
          <span v-html="steps.departureLocation" />
        </div>
          <div class="address-box">
          <span v-html="etd"/>
        </div>
      </v-col>
      <v-col class="divider-col" v-if="!$vuetify.breakpoint.smAndDown" />
      <v-col class="d-block">
        <div class="address-box">
          <span v-html="steps.arrivalLocation" />
        </div>
        <div class="address-box">
          <span v-html="eta"/>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PraticaStepperTracking',
  props: ['pratica', 'status', 'etd', 'eta'],
  computed: {
    steps() {
      return {
        pickupLocation: this.pratica.shipper ? this.pratica.shipper.customerName : null,
        departureLocation: this.pratica.pol ? this.pratica.pol.label : null,
        arrivalLocation: this.pratica.pod ? this.pratica.pod.label : null,
        destination: this.pratica.consignee ? this.pratica.consignee.customerName : null,
      }
    },
    convertedStatus() {
      if (!this.status) return
      switch (this.status) {
        case 'transported':
          return 1
        case 'departed':
          return 2
        case 'arrived':
          return 3
        default:
          return 0
      }
    },
  },
  methods: {
    saleChannelDesc(saleChannel) {
      let description
      switch (saleChannel) {
        case '20':
          description = 'Nave'
          break
        case '30':
          description = 'Terra'
          break
        case '40':
          description = 'Aereo'
          break
      }
      return description
    },
  },
}
</script>

<style scoped>
.stepper-box {
  display: flex;
  padding: 0;
  max-height: 30rem;
}
.col {
  width: 25rem;
  max-width: 25rem;
  padding: 0;
  display: flex;
  justify-content: center;
  font-size: 0.7rem;
}
.divider-col {
  display: inline-flex;
  align-items: center;
}
.address-box {
  font-size: 1rem;
  padding-inline: 0.25rem;
  padding-top: 0.25rem;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1;
}
.avatar-complete {
  border: solid 0.2rem var(--v-primary-base);
}
.avatar {
  border: solid 0.2rem #b0bec5;
}
.dashed-line {
  width: 20rem;
  border-top: 0.15rem dashed #b0bec5; 
}
.dashed-line-pending {
  width: 20rem;
  border-top: 0.15rem dashed var(--v-primary-base); 
}
.solid-line {
  width: 20rem;
  border-top: 0.15rem solid var(--v-primary-base);
}
</style>
