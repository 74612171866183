<template>
  <v-card class="my-1" outlined>
    <v-row dense class="ma-0">
      <!-- Invoice nbr. & Document date -->
      <v-col
        cols="auto"
        class="py-0 text-caption font-weight-bold d-flex align-center"
      >
        <v-row dense class="ma-0 d-flex flex-column justify-center text-left">
          <!-- Invoice nbr. -->
          <v-col class="d-flex flex-column py-0">
            <span
              class="text-subtitle-1 font-weight-black"
              v-text="invoice.invoiceNbr"
            ></span>
            <v-chip color="#e0e0e0" label small class="py-0">
              <v-icon color="#757575" small>mdi-file-document-outline</v-icon>
              <span class="font-weight-medium ml-1 text-caption text-center">
                {{ $t("label.invoiceNbr") }}
              </span>
            </v-chip>
          </v-col>
          <v-col
            class="py-0 d-flex justify-start align-center"
            style="min-height: 40px"
          >
            <ReadonlyField
              :label="$t('label.documentDate')"
              :value="invoice.documentDate"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-divider vertical />

      <!-- Invoice Recipient & Seller -->
      <v-col class="py-0 text-caption font-weight-bold d-flex align-center"
        ><v-row dense class="ma-0 d-flex flex-column justify-center text-left">
          <v-col class="py-0">
            <ReadonlyField
              :label="$t('label.invoiceHolderName')"
              :value="invoice.invoiceHolderName"
            />
          </v-col>
          <v-divider class="ma-1" />
          <v-col class="py-0">
            <ReadonlyField
              :label="$t('label.sellerName')"
              :value="invoice.sellerName"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-divider vertical />

      <!-- Consignee & Incoterms & Tax Code -->
      <v-col class="py-0 text-caption font-weight-bold d-flex align-center"
        ><v-row dense class="ma-0 d-flex flex-column justify-center text-left">
          <v-col class="py-0">
            <ReadonlyField
              :label="$t('label.consigneeName')"
              :value="invoice.consigneeName"
            />
          </v-col>
          <v-divider class="ma-1" />
          <v-col class="py-0 d-flex justify-space-between">
            <ReadonlyField
              :label="$t('label.incoterms')"
              :value="invoice.incoterms"
            />
            <ReadonlyField
              :label="$t('label.taxCode')"
              :value="invoice.taxCode"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-divider vertical />

      <!-- Total Amount & Gross Weight -->
      <v-col class="py-0 text-caption font-weight-bold d-flex align-center"
        ><v-row dense class="ma-0 d-flex flex-column justify-center text-left">
          <v-col class="py-0">
            <ReadonlyField
              :label="$t('label.totalAmount')"
              :value="invoice.totalAmount.toString()"
            />
          </v-col>
          <v-divider class="ma-1" />
          <v-col class="py-0">
            <ReadonlyField
              :label="$t('label.totalGrossWeight')"
              :value="invoice.totalGrossWeight.toString()"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-divider vertical />

      <!-- Currency & Net Weight -->
      <v-col class="py-0 text-caption font-weight-bold d-flex align-center"
        ><v-row dense class="ma-0 d-flex flex-column justify-center text-left">
          <v-col class="py-0">
            <ReadonlyField
              :label="$t('label.currency')"
              :value="invoice.currency"
            />
          </v-col>
          <v-divider class="ma-1" />
          <v-col class="py-0">
            <ReadonlyField
              :label="$t('label.totalNetWeight')"
              :value="invoice.totalNetWeight.toString()"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-divider vertical />

      <!-- Total Amount & Gross Weight -->
      <v-col class="py-0 text-caption font-weight-bold d-flex align-center"
        ><v-row dense class="ma-0 d-flex flex-column justify-center text-left">
          <v-col class="py-0">
            <ReadonlyField
              :label="$t('label.saleOrderReference')"
              :value="invoice.saleOrderReference"
            />
          </v-col>
          <v-divider class="ma-1" />
          <v-col class="py-0">
            <ReadonlyField
              :label="$t('label.shipmentNbr')"
              :value="shipmentDocumentNbr"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-divider vertical />

      <!-- expand-vertical -->
      <v-col cols="1" class="pa-1 d-flex align-center" style="max-width: 60px">
        <v-row dense class="ma-0">
          <v-col class="d-flex justify-center">
            <v-btn
              fab
              depressed
              x-small
              @click="expandSapInvoiceDetail = !expandSapInvoiceDetail"
            >
              <v-icon>mdi-arrow-expand-vertical</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <!-- <v-row dense class="ma-0">
          <v-col>
            <v-btn fab depressed x-small @click="goToInvoice">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </v-col>
        </v-row> -->
      </v-col>
      <v-divider vertical v-if="showSelection" />

      <!-- checkbox -->
      <v-col
        cols="1"
        class="d-flex justify-center align-center pa-1"
        v-if="showSelection"
        style="max-width: 60px"
      >
        <div
          justify="space-around"
          class="d-flex justify-center align-center pa-1"
          style="max-height: fit-content"
        >
          <v-checkbox
            v-model="selected"
            @change="addRowToSelection"
            color="primary"
            class="checkbox ma-0 pa-0"
            hide-details
            :disabled="invoice.status === 'SENT' || shipmentDocumentNbr === ''"
          />
        </div>
      </v-col>
    </v-row>

    <v-row justify="space-around" no-gutters>
      <v-col cols="12" class="pa-0">
        <InvoiceDetail
          :open="expandSapInvoiceDetail"
          :documentNbr="invoice.documentNbr"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import ReadonlyField from "../ReadonlyField.vue";
import InvoiceDetail from "./InvoiceDetail.vue";
import { shipmentLinkService } from "../../../services/shipmentLink.service";

export default {
  props: ["invoice", "currentSelection", "showSelection"],
  emits: ["select-row", "add-to-selection"],
  components: { ReadonlyField, InvoiceDetail },
  data() {
    return {
      expandSapInvoiceDetail: false,
      shipmentDocumentNbr: "",
      selected: false,
    };
  },
  computed: {
    ...mapState("account", ["isAdmin", "internalVisibilityAuth"]),
  },
  watch: {
    "invoice.status"() {
      if (this.invoice.status === "SENT") {
        this.selected = true;
      }
    },
    invoice: {
      handler() {
        this.expandSapInvoiceDetail = false;
        this.getShipmentDocumentNbr();
      },
      deep: true,
    },
  },
  methods: {
    goToInvoice() {
      this.$emit("select-row");
      this.$router.push({
        name: "invoice",
        params: { id: this.invoice.id },
      });
    },
    addRowToSelection() {
      const invoiceBusinessKey = {
        organizationUnitId: this.invoice.organizationUnitId,
        documentNbr: this.invoice.documentNbr,
        invoiceNbr: this.invoice.invoiceNbr,
        sellerCode: this.invoice.sellerCode,
      };
      this.$emit("add-to-selection", invoiceBusinessKey, this.selected);
    },
    getShipmentDocumentNbr() {
      const invoiceBusinessKey = {
        organizationUnitId: this.invoice.organizationUnitId,
        documentNbr: this.invoice.documentNbr,
        invoiceNbr: this.invoice.invoiceNbr,
        sellerCode: this.invoice.sellerCode,
      };
      shipmentLinkService
        .getShipmentDocumentNbr(invoiceBusinessKey)
        .then((resp) => {
          resp.text().then((text) => {
            this.shipmentDocumentNbr = text;
          });
        });
    },
  },
  created() {
    this.getShipmentDocumentNbr();
    this.selected = this.invoice.status === "SENT";
  },
};
</script>

<style scoped>
.checkbox::v-deep .v-input--selection-controls__input {
  margin: 0.3rem !important;
}
.checkbox::v-deep .v-input--selection-controls__ripple {
  height: 0 !important;
}
</style>
