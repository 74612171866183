<template>
  <v-card class="mb-2">
    <v-card-text class="ma-0 black--text">
      <v-row>
        <v-col class="pa-1">
          <ReadonlyField
            :label="$t('label.departure')"
            :value="departure"
            :icon="'mdi-map-marker-outline'"
          />
        </v-col>
        <v-col class="pa-1">
          <ReadonlyField
            :label="$t('label.arrival')"
            :value="arrival"
            :icon="'mdi-map-marker-outline'"
          />
        </v-col>
        <v-col class="pa-1" cols="3">
          <ReadonlyField
            :label="$t('label.vessel')"
            :value="mainVoyageInfo"
            :icon="'mdi-ferry'"
          />
        </v-col>
        <v-col class="py-1 pr-5 d-flex flex-column justify-center" cols="auto">
          <ReadonlyField
            :label="$t('label.carrier')"
            :value="routeSchedule ? routeSchedule.carrierCode : null"
            v-if="company == 'MAERSK'"
          />
          <!-- :icon="'mdi-ferry'" -->
          <v-btn
            v-if="selectedVoyage"
            x-small
            width="4rem"
            class="primary"
            :disabled="disabled"
            @click="selectVoyage"
          >
            <span>{{$t('label.change')}}</span>
          </v-btn>
          <v-btn
            v-else
            x-small
            width="4rem"
            :class="voyageSelected ? 'success' : 'primary'"
            :disabled="disabled"
            @click="selectVoyage"
          >
            <v-icon v-if="voyageSelected">mdi-check</v-icon>
            <span v-else>{{$t('label.book')}}</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="deadlines.length && !selectedVoyage">
        <v-col class="pt-0">
          <v-divider class="mt-1" />
          <v-row>
            <v-col class="pa-1">
              <v-icon class="mr-2">mdi-alarm</v-icon>
              <span class="text-caption primary--text">{{$t('label.deadlines')}}</span>
            </v-col>
          </v-row>
          <v-row class="px-6">
            <v-col
              v-for="(deadline, i) in deadlines"
              :key="i"
              cols="3"
              class="py-0"
            >
              <ReadonlyField
                :label="deadline.deadlineName"
                :value="deadline.deadlineDateTime"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-divider />
      <v-row>
        <v-col cols="12" class="pa-1">
          <v-expansion-panels flat>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="py-0"
                style="min-height: 0 !important;"
              >
                <span class="header text-caption primary--text ml-2">
                  {{$t('label.showRouteDetails')}}
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <BookingRoutesYangMing :routes="routeSchedule ? routeSchedule.routeLegs : []" v-if="company == 'YANGMING'" />
                <BookingRoutes :routes="routeSchedule ? routeSchedule.routeLegs : []" v-else />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { dateFilter } from '@/filters/dateFilter'
import ReadonlyField from '@/components/elements/ReadonlyField.vue'
import BookingRoutes from '@/components/elements/booking/BookingRoutes.vue'
import BookingRoutesYangMing from '@/components/elements/booking/BookingRoutesYangMing.vue'

export default {
  name: 'RouteScheduleRow',
  props: ['company', 'routeSchedule', 'selectedVoyage', 'disabled'],
  emits: ['select-voyage'],
  components: { ReadonlyField, BookingRoutes, BookingRoutesYangMing },
  data() {
    return {
      voyageSelected: false,
    }
  },
  computed: {
    departure() {
      if (!this.routeSchedule) return
      let label = `${dateFilter.formatDate(this.routeSchedule.departureDateTime)} <br> ${this.routeSchedule.originPoint.locationName ? this.routeSchedule.originPoint.locationName : ''} (${this.routeSchedule.originPoint.locationCode})`
      if (this.routeSchedule.originPoint.facility && this.routeSchedule.originPoint.facility.facilityName) label = label.concat(` - ${this.routeSchedule.originPoint.facility.facilityName}`)
      return label
    },
    arrival() {
      if (!this.routeSchedule) return
      let label = `${dateFilter.formatDate(this.routeSchedule.arrivalDateTime)} <br> ${this.routeSchedule.destinationPoint.locationName ? this.routeSchedule.destinationPoint.locationName : ''} (${this.routeSchedule.destinationPoint.locationCode})`
      if (this.routeSchedule.destinationPoint.facility && this.routeSchedule.destinationPoint.facility.facilityName) label = label.concat(` - ${this.routeSchedule.destinationPoint.facility.facilityName}`)
      return label
    },
    deadlines() {
      let deadlines = []
      if (!this.routeSchedule) return deadlines
      this.routeSchedule.routeLegs.forEach((leg) => {
        leg.deadlines.forEach((deadline) => {
          deadline.deadlineDateTime = dateFilter.formatDate(deadline.deadlineDateTime)
          deadlines.push(deadline)
        })
      })
      return deadlines
    },
    mainVoyageInfo(){
      let label = ''
      if (this.mainVessel) label += `${this.mainVessel} <br>`
      if (this.mainVoyage) label += `${this.mainVoyage} <br>`
      if (this.mainVoyageTransitTime) label += `<span class="text-caption">Transit time ${this.mainVoyageTransitTime} days</span>`
      return label
    },
    mainVessel(){
      return this.firstLeg && this.firstLeg.transport && this.firstLeg.transport.vessel ? this.firstLeg.transport.vessel.vesselName : null
    },
    mainVoyage(){
      return this.firstLeg ? this.firstLeg.transport.voyageNumber : null
    },
    mainVoyageTransitTime(){
      return this.routeSchedule.totalTransitTime
    },
    firstLeg(){
      if (!this.routeSchedule) return
      let vesselLegs = this.routeSchedule.routeLegs.filter((leg) => leg.transport && leg.transport.vessel && leg.transport.vessel.vesselName != null)
      if (!vesselLegs.length) return
      vesselLegs = this.sortByDepartureDateAsc(vesselLegs)
      return vesselLegs[0]
    }
  },
  watch: {
    selectedVoyage: {
      handler() {
        this.voyageSelected = this.checkVoyageSelected()
      },
      deep: true,
    },
  },
  methods: {
    init() {
      if (this.checkVoyageSelected()) this.selectVoyage()
    },
    selectVoyage() {
      this.voyageSelected = !this.voyageSelected
      let voyage = this.voyageSelected ? this.routeSchedule : null
      this.$emit('select-voyage', voyage)
    },
    checkVoyageSelected() {
      return this.selectedVoyage
      && this.selectedVoyage.departureDateTime == this.routeSchedule.departureDateTime 
      && this.selectedVoyage.arrivalDateTime == this.routeSchedule.arrivalDateTime 
      && this.selectedVoyage.originPoint.locationCode == this.routeSchedule.originPoint.locationCode 
      && this.selectedVoyage.destinationPoint.locationCode == this.routeSchedule.destinationPoint.locationCode
    },
    // Utils
    sortByDepartureDateAsc(list) {
      if (!list.length) return []
      return list.sort(function (a, b) {
        if (a.departureDateTime > b.departureDateTime) return 1
        if (a.departureDateTime < b.departureDateTime) return -1
        return 0
      })
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>
.checkbox::v-deep .v-input--selection-controls__input {
  margin: 0.3rem !important;
}
.checkbox::v-deep .v-input--selection-controls__ripple {
  height: 0 !important;
}
.v-expansion-panel::v-deep .v-expansion-panel-content__wrap {
  padding-bottom: 0;
}
.v-expansion-panel::v-deep .header {
  order: 1 !important;
  padding-inline: 0 !important;
}
.v-expansion-panel::v-deep .v-expansion-panel-header {
  padding-inline: 0 !important;
}
/*.row {
  border: solid 1px red;
}
.col {
  border: solid 1px orange;
}*/
</style>
