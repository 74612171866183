var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-2", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "d-flex" },
            [
              _c(
                "v-col",
                { staticClass: "pa-0" },
                [
                  _c("ReadonlyField", {
                    attrs: {
                      label: _vm.$t("label.roadHauler"),
                      value: _vm.transportOrder.carrierLabel,
                      icon: "mdi-transit-connection-horizontal",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "d-flex" },
            [
              _c(
                "v-col",
                { staticClass: "pa-0" },
                [
                  _c("ReadonlyField", {
                    attrs: {
                      label: _vm.$t("label.pickupDate"),
                      value: _vm.formatDateDay(
                        _vm.transportOrder.pickupDateActual
                      ),
                      icon: "mdi-calendar",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "d-flex" },
            [
              _c(
                "v-col",
                { staticClass: "pa-0" },
                [
                  _c("ReadonlyField", {
                    attrs: {
                      label: _vm.$t("label.roadHaulerCode"),
                      value: _vm.transportOrder.carrierId,
                      icon: "mdi-account",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pa-0" },
                [
                  _c("ReadonlyField", {
                    attrs: {
                      label: _vm.$t("label.truckPlate"),
                      value: _vm.transportOrder.vehicleId,
                      icon: "mdi-truck",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "d-flex" },
            [
              _c(
                "v-col",
                { staticClass: "pa-0" },
                [
                  _c("ReadonlyField", {
                    attrs: {
                      label: _vm.$t("label.deliveryDate"),
                      value: _vm.formatDateDay(
                        _vm.transportOrder.deliveryDateActual
                      ),
                      icon: "mdi-calendar",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "d-flex" },
            [
              _c(
                "v-col",
                { staticClass: "pa-0" },
                [
                  _c("ReadonlyField", {
                    attrs: {
                      label: _vm.$t("label.pickupAddress"),
                      value: _vm.pickupAddress,
                      icon: "mdi-map-marker",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pa-0" },
                [
                  _c("ReadonlyField", {
                    attrs: {
                      label: _vm.$t("label.deliveryAddress"),
                      value: _vm.deliveryAddress,
                      icon: "mdi-map-marker",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "d-flex" },
            [
              _c(
                "v-col",
                { staticClass: "pa-0" },
                [
                  _c("ReadonlyField", {
                    attrs: {
                      label: _vm.$t("label.empty"),
                      value: _vm.full,
                      icon: "mdi-map-marker",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pa-0" },
                [
                  _c("ReadonlyField", {
                    attrs: {
                      label: _vm.$t("label.full"),
                      value: _vm.empty,
                      icon: "mdi-map-marker",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }