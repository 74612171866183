<template>
  <v-flex shrink>
    <v-card class="shadow-none" outlined>
      <v-card-text class="pa-0">
        <v-row class="my-1" dense>
          <v-col cols="2">
            <v-text-field
              :value="
                filterSelection.documentNbr
                  ? filterSelection.documentNbr.text
                  : ''
              "
              :label="$t('label.documentNbr')"
              ref="documentNbr"
              @change="(v) => onFieldChange(v, 'documentNbr', 'text', 'value')"
              @keypress.enter="setFilter"
              :menu-props="menuProps"
              hide-details
              clearable
              @click:clear="onClear('documentNbr')"
              dense
              class="py-1 pl-2 custom-text-field"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              :value="
                filterSelection.clientName
                  ? filterSelection.clientName.text
                  : ''
              "
              :label="$t('label.customer')"
              ref="clientName"
              @change="(v) => onFieldChange(v, 'clientName', 'text', 'value')"
              @keypress.enter="setFilter"
              :menu-props="menuProps"
              hide-details
              clearable
              @click:clear="onClear('clientName')"
              dense
              class="pa-1 custom-text-field"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              :value="
                filterSelection.consigneeName
                  ? filterSelection.consigneeName.text
                  : ''
              "
              :label="$t('label.consignee')"
              ref="consigneeName"
              @change="
                (v) => onFieldChange(v, 'consigneeName', 'text', 'value')
              "
              @keypress.enter="setFilter"
              :menu-props="menuProps"
              hide-details
              clearable
              @click:clear="onClear('consigneeName')"
              dense
              class="pa-1 custom-text-field"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              :value="
                filterSelection.shipperName
                  ? filterSelection.shipperName.text
                  : ''
              "
              :label="$t('label.shipperName')"
              ref="shipperName"
              @change="(v) => onFieldChange(v, 'shipperName', 'text', 'value')"
              @keypress.enter="setFilter"
              :menu-props="menuProps"
              hide-details
              clearable
              @click:clear="onClear('shipperName')"
              dense
              class="pa-1 custom-text-field"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              :value="
                filterSelection.shipperInvoiceReference
                  ? filterSelection.shipperInvoiceReference.text
                  : ''
              "
              :label="$t('label.shipperInvoiceReference')"
              ref="shipperInvoiceReference"
              @change="
                (v) =>
                  onFieldChange(v, 'shipperInvoiceReference', 'text', 'value')
              "
              @keypress.enter="setFilter"
              :menu-props="menuProps"
              hide-details
              clearable
              @click:clear="onClear('shipperInvoiceReference')"
              dense
              class="pa-1 custom-text-field"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              :value="
                filterSelection.userLabel ? filterSelection.userLabel.text : ''
              "
              :label="$t('label.userLabel')"
              ref="userLabel"
              @change="(v) => onFieldChange(v, 'userLabel', 'text', 'value')"
              @keypress.enter="setFilter"
              :menu-props="menuProps"
              hide-details
              clearable
              @click:clear="onClear('userLabel')"
              dense
              class="py-1 pr-2 custom-text-field"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-flex>
</template>

<script>
export default {
  data() {
    return {
      filterSelection: {},

      browseFilter: {},

      menuProps: {
        offsetY: true,
        overflowY: "auto",
        maxHeight: "10rem",
      },
    };
  },
  emits: ["set-filter"],
  name: "PraticheFilter",
  methods: {
    resetAllFilters() {
      this.resetFilters();
      this.setFilter();
    },
    resetFilters() {
      this.resetInputs();
      this.filterSelection = {};
      this.browseFilter = {};
    },
    resetInputs() {
      if (this.isEmpty(this.$refs)) return;
      this.$refs.documentNbr.reset();
      this.$refs.clientName.reset();
      this.$refs.consigneeName.reset();
      this.$refs.shipperName.reset();
      this.$refs.shipperInvoiceReference.reset();
      this.$refs.userLabel.reset();
    },
    isEmpty(object) {
      return Object.keys(object).length === 0;
    },
    setFilter() {
      this.$emit(
        "set-filter",
        this.convertFilter(this.browseFilter),
        this.$options.name
      );
    },
    convertFilter(filter) {
      let convertedFilter = {};
      Object.keys(filter).forEach((key) => {
        if (Array.isArray(filter[key]))
          convertedFilter[key] = this.convertArrayToStringSplitted(filter[key]);
      });
      return convertedFilter;
    },
    convertArrayToStringSplitted(list) {
      let arrayToStringSplitted = "";
      list.forEach((element) => {
        let isLastElement = list.indexOf(element) == list.length - 1;
        arrayToStringSplitted += element += !isLastElement ? ", " : "";
      });
      return arrayToStringSplitted;
    },
    onFieldChange(input, field, value, text) {
      if (!input) {
        delete this.filterSelection[field];
        delete this.browseFilter[field];
        return;
      }
      this.filterSelection[field] = {};
      this.browseFilter[field] = [];
      switch (true) {
        case Array.isArray(input[text]): // input is text: [] value: []
          this.filterSelection[field].text = input[text];
          this.filterSelection[field].value = input[value];
          if (!this.filterSelection[field].value.length)
            delete this.filterSelection[field];

          this.browseFilter[field] = input[value];
          if (!this.browseFilter[field].length) delete this.browseFilter[field];

          break;
        case Array.isArray(input): // input is []
          this.filterSelection[field] = [];
          if (!input.length) {
            delete this.browseFilter[field];
          }
          input.forEach((element) => {
            let item = {
              text: element[text],
              value: element[value],
            };
            this.filterSelection[field].push(item);
            this.browseFilter[field].push(element[value]);
          });
          break;
        case typeof input === "string": // input is String
          this.filterSelection[field][text] = input;
          this.filterSelection[field][value] = input;
          this.browseFilter[field].push(input);
          break;
        default:
          // input is single value
          this.filterSelection[field][text] = input[text];
          this.filterSelection[field][value] = input[value];
          this.browseFilter[field].push(input[value]);
          break;
      }
    },
    onClear(field) {
      delete this.filterSelection[field];
      delete this.browseFilter[field];
      this.setFilter();
    },
  },
};
</script>
