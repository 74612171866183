<template>
  <v-flex class="d-flex align-center mx-1" shrink>
    <v-menu
      v-model="menu"
      transition="scale-transition"
      :close-on-content-click="false"
      max-height="250"
      class="py-0"
      offset-y
    >
      <template #activator="{ on: onMenu }">
        <v-tooltip bottom>
          <template #activator="{ on: onTooltip }">
            <v-btn v-on="{ ...onMenu, ...onTooltip }" x-small class="secondary">
              <v-icon size="20">mdi-sort</v-icon>
            </v-btn>
          </template>
          <span class="text-caption">{{ $t("label.sortBy") }}</span>
        </v-tooltip>
      </template>
      <v-form ref="form" class="pa-0">
        <v-list flat>
          <v-list-item-group>
            <v-list-item v-for="(item, index) in items" :key="index">
              <v-list-item-content @click="select(index)">
                <v-list-item-title>{{ item.label }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-form>
    </v-menu>
  </v-flex>
</template>
<script>
export default {
  name: "SortByMenuCustomsDashboard",
  emits: "sort-invoices",
  data() {
    return {
      menu: false,
      selected: -1,
      sortBy: {},
      items: [
        {
          column: "consigneeName",
          label: this.$t("label.consigneeName"),
          direction: "ASC",
        },
        {
          column: "documentDate",
          label: this.$t("label.documentDate"),
          direction: "DESC",
        },
        {
          column: "invoiceHolderName",
          label: this.$t("label.invoiceHolderName"),
          direction: "ASC",
        },
        {
          column: "sellerName",
          label: this.$t("label.sellerName"),
          direction: "ASC",
        },
      ],
    };
  },
  methods: {
    select(index) {
      this.selected = index;
      this.setSortBy();
      this.$emit("sort-invoices", this.sortBy);
      this.menu = false;
      this.resetSelection();
    },
    setSortBy() {
      if (this.selected || this.selected == 0) {
        this.sortBy["column"] = this.items[this.selected].column;
        this.sortBy["direction"] = this.items[this.selected].direction;
      } else return (this.sortBy = null);
    },
    resetSelection() {
      this.sortBy = {};
      this.selected = -1;
    },
  },
};
</script>
