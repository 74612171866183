<template>
  <v-container fill-height fluid>
    <MainToolbar ref="mainToolbar" @toggle-drawer="toggleDrawer" />
    <v-navigation-drawer
      v-model="drawer"
      clipped
      app
      hide-overlay
      disable-resize-watcher
    >
      <v-list dense>
        <div
          v-for="(section, i) in drawerSections.filter((a) => isAuth(a))"
          :key="i"
        >
          <v-subheader v-if="section.label" class="text-overline primary--text">
            {{ section.label }}
          </v-subheader>
          <!-- <v-list-item-group dense> -->
          <v-list-item
            v-for="(item, i) in section.menuTree"
            :key="i"
            link
            :to="{ path: item.link }"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- </v-list-item-group> -->
          <v-divider class="ma-0" />
        </div>
      </v-list>
    </v-navigation-drawer>
    <v-main class="fill-height">
      <v-overlay v-model="loadingPage" class="d-flex align-center justify-center">
        <v-progress-circular indeterminate color="primary" />
      </v-overlay>
      <router-view class="pa-2" ref="route" @clear-search="clearFastSearch" />
    </v-main>
    <DashboardFooter :class="isProduction ? 'primary' : 'rossoSacmi'" />
    <snackbar />
  </v-container>
</template>

<script>
import Filters from "@/mixins/Filters";
import { mapState, mapActions } from "vuex";
import MainToolbar from "./MainToolbar";
import Snackbar from "./Snackbar";
import DashboardFooter from "@/components/elements/DashboardFooter";

export default {
  name: "MainPage",
  mixins: [Filters],
  components: { Snackbar, MainToolbar, DashboardFooter },
  data() {
    return {
      currentMenu: [],
      drawer: false,
      drawerSections: [
        {
          label: "Tracking",
          color: null,
          authorization: null,
          menuTree: [
            {
              icon: "mdi-view-dashboard",
              text: "Dashboard",
              link: "/app/dashboard",
              show: true,
            },
          ],
        },
        {
          label: this.$t("label.customs"),
          color: "primary",
          authorization: "AUTH_CUSTOMS",
          menuTree: [
            {
              icon: "mdi-view-dashboard",
              text: "Dashboard",
              link: "/app/customs/dashboard",
              show: true,
            },
            {
              icon: "mdi-view-dashboard",
              text: "Import",
              link: "/app/customs/import",
              show: true,
            },
            {
              icon: "mdi-account",
              text: this.$t("label.operative"),
              link: "/app/customs/operative",
              show: true,
            },
            {
              icon: "mdi-view-dashboard",
              text: "Shipments Dashboard",
              link: "/app/invoice-shipment-link",
              show: true,
            },
          ],
        },
      ],
      mini: true,
    };
  },
  computed: {
    ...mapState("application", ["isProduction"]),
    ...mapState("account", ["userParameters", "userAuthorizations"]),
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions("account", ["login"]),
    toggleDrawer(showDrawer) {
      this.drawer = showDrawer;
    },
    isAuth(section) {
      return (
        !section.authorization ||
        this.userAuthorizations.includes(section.authorization)
      );
    },
    clearFastSearch() {
      this.$refs.mainToolbar.resetFastSearch();
    },
  },
  async created() {
    if (process.env.VUE_APP_USER_MANAGEMENT_PROVIDER === 'Azure') {
      let token = localStorage.getItem('msal.' + this.$msal.data.user.idToken.aud + '.idtoken')
      localStorage.setItem('authToken', token)
      this.login(this.$msal.data.user).then(async () => {
        this.$i18n.locale = this.userParameters.language.value
      });
    }
    await this.loadDefaultFilters()
    if (this.$refs.route.$options.name == 'DashboardPage') {
      let dashboard = this.$refs.route
      dashboard.loadBrowseMemory()
      dashboard.browse()
    }
  },
};
</script>
