<template>
  <v-container class="d-flex justify-center" fill-height fluid>
    <v-card class="d-flex align-center pa-5">
      <v-icon size="50" class="mr-3" color="warning">
        mdi-alert
      </v-icon>
      <h3 class="ma-0 warning--text">{{ $t('message.linkExpired') }}</h3>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'LinkExpired',
}
</script>

<style scoped>
.v-card {
  border: solid 0.3rem var(--v-warning-base);
}
</style>
