<template>
  <v-card class="my-1" outlined>
    <v-row justify="space-around" dense class="ma-0">
      <v-col cols="1" class="d-flex justify-center align-center pa-0">
        <v-row
          justify="space-around"
          no-gutters
          dense
          class="d-flex justify-center align-center pa-0"
        >
          <v-checkbox
            v-model="selected"
            @change="modifyRowSelection"
            color="primary"
            class="checkbox ma-0 pa-0"
            hide-details
          />
        </v-row>
      </v-col>
      <v-divider vertical />

      <v-col class="d-flex justify-center align-center pa-1">
        <v-row dense class="ma-0">
          <ReadonlyField
            :label="$t('label.invoiceNbr')"
            :value="relationship.shipmentLink.invoiceNbr"
          />
        </v-row>
      </v-col>
      <v-divider vertical />

      <v-col class="d-flex justify-center align-center pa-1">
        <v-row dense class="ma-0">
          <ReadonlyField
            :label="$t('label.documentNbr')"
            :value="relationship.shipmentLink.documentNbr"
          />
        </v-row>
      </v-col>
      <v-divider vertical />

      <v-col class="d-flex justify-center align-center pa-1">
        <v-row dense class="ma-0">
          <ReadonlyField
            :label="$t('label.shipmentNbr')"
            :value="relationship.shipmentLink.shipmentDocumentNbr"
          />
        </v-row>
      </v-col>
      <v-divider vertical />

      <v-col class="d-flex justify-center align-center pa-1">
        <v-row dense class="ma-0">
          <ReadonlyField
            :label="'Description message'"
            :value="relationship.description"
          />
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import ReadonlyField from "./ReadonlyField.vue";

export default {
  data() {
    return {
      selected: false,
    };
  },
  props: ["relationship"],
  emits: ["add-to-selection", "remove-from-selection"],
  components: { ReadonlyField },
  methods: {
    modifyRowSelection() {
      const invoiceBusinessKey = {
        organizationUnitId:
          this.relationship.shipmentLink.organizationUnitId,
        documentNbr: this.relationship.shipmentLink.documentNbr,
        invoiceNbr: this.relationship.shipmentLink.invoiceNbr,
        sellerCode: this.relationship.shipmentLink.sellerCode,
      };
      if (this.selected) {
        this.$emit("add-to-selection", invoiceBusinessKey);
      } else {
        this.$emit("remove-from-selection", invoiceBusinessKey);
      }
    },
  },
  created() {
    this.selected = this.relationship.alreadyMatched;
    console.log(this.relationship);
  },
};
</script>

<style scoped>
.checkbox::v-deep .v-input--selection-controls__input {
  margin: 0.3rem !important;
}
.checkbox::v-deep .v-input--selection-controls__ripple {
  height: 0 !important;
}
</style>
