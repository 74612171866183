<template>
  <v-form ref="form" class="d-block pa-1 overflow-y-auto fill-height">
    <v-row dense class="px-1">
      <v-col cols="auto" class="py-0">
        <v-subheader class="pa-0 primary--text text-overline">
          CMA payments
        </v-subheader>
      </v-col>
      <v-col class="d-flex align-center py-0 pl-0">
        <v-divider />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="3">
        <ShippingInstructionParty 
          ref="payerParty"
          :company="company"
          :preselectedParty="savedPayerParty" 
          :partyFunction="payerPartyFunction" 
          @select-party="(party) => payerParty = party"
        />
        <!-- <v-autocomplete
          :label="$t('label.party')"
          v-model="selectedCustomer"
          item-text="customerName"
          :filter="filterCustomers"
          :items="customers"
          :menu-props="menuProps"
          :rules="required"
          @update:search-input="getCustomers"
          @click.stop
          class="xs-input"
          return-object
          clearable
          outlined
          dense
        > -->
        <!-- @change="addPayerParty" -->
          <!-- <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>
                {{ data.item.customerName }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ data.item.carrierCodes ? data.item.carrierCodes.cmacgmCode : null }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete> -->
      </v-col>
      <v-col cols="auto" class="pa-0">
        <v-col class="pa-1">
          <v-text-field
            :label="$t('label.placeOfPayment')"
            :value="savedInvoicePayableAt.locationName"
            :rules="required"
            class="xs-input"
            outlined
            readonly
            dense
          />
        </v-col>
        <v-col class="pa-1 py-0">
          <v-radio-group
            v-model="paymentCode"
            :rules="required"
            class="radio-group ma-0 pt-0"
            dense
          >
            <v-radio value="PRE">
              <template slot="label">
                <span class="text-caption">Prepaid</span>
              </template>
            </v-radio>
            <v-radio value="COL">
              <template slot="label">
                <span class="text-caption">Collect</span>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import ShippingInstruction from '@/mixins/ShippingInstruction.js'
import ShippingInstructionParty from '@/components/elements/shipping-instruction/ShippingInstructionParty.vue'

export default {
  name: 'ShippingInstructionPaymentsCmaCgm',
  props: [],
  mixins: [ShippingInstruction],
  components: {ShippingInstructionParty},
  data() {
    return {
      paymentCode: 'PRE',
      payerPartyFunction: "PAY",
      payerParty: {}
    }
  },
  computed: {},
  methods: {
    init() {
      if (this.savedPaymentCode) this.paymentCode = this.savedPaymentCode
    },
    validateForm(){
      let validated 
      // validated = this.payerParty.partyName ? true : false 
      return this.$refs.payerParty.validateParty()
    },
    submit() {
      if (this.electronicFlowStatus == this.partiesSelectedStatus)
        this.setStatus(this.paymentSelectedStatus)
      this.setDocumentParties(this.createUpdatePayerParty())
      this.setPaymentCode(this.paymentCode)
      
      this.updateShippingInstructionAndSaveElectronicFlow()
    },
    createUpdatePayerParty() {
      let savedParties = this._.cloneDeep(this.shippingInstruction.documentParties)
      let parties = savedParties.filter((party) => party.partyFunction == this.payerPartyFunction)
      let index = parties.length ? savedParties.indexOf(parties[0]) : null
      index != null ? savedParties.splice(index, 1, this.payerParty) : savedParties.push(this.payerParty)
      return savedParties
    },
  },
  created() {
    this.init()
  },
}
</script>

<style scoped>
.v-subheader {
  height: 2rem;
}
.radio-group::v-deep .v-input--radio-group__input {
  flex-direction: row;
}
.v-radio {
  max-width: max-content;
  margin: 0 1rem 0 0 !important;
}
.v-radio::v-deep .v-label {
  margin: 0 !important;
}
.xs-input::v-deep input {
  padding: 0.5rem !important;
  padding-left: 0 !important;
}
.xs-input::v-deep .v-label {
  position: unset !important;
  min-width: max-content;
  margin-bottom: 0 !important;
}
.xs-input::v-deep .v-input__slot {
  min-height: 0 !important;
  padding-right: 4px !important;
}
.xs-input::v-deep .v-input__append-inner {
  margin: 0 !important;
  align-self: center !important;
}
.xs-input::v-deep .v-label--active {
  position: absolute !important;
  margin-bottom: 0 !important;
}
.xs-input::v-deep .v-select__selections {
  padding: 0 !important;
}
.xs-input::v-deep .v-text-field__details {
  margin: 0 !important;
}
/*.row {
  border: solid 1px red;
}
.col {
  border: solid 1px orange;
}*/
</style>
