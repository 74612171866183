import { usersService } from './users.service'

export const commons = {
  handleJsonResponse,
  handleStringResponse,
  handleError,
  handleResponseWithError,
}

function handleJsonResponse(response) {
  if (response.status === 401) {
    usersService.logout()
    location.reload(true)
  }

  if (response.ok) {
    return response.text().then((text) => {
      if (text) {
        return text && JSON.parse(text)
      }
    })
  } else {
    return response.text().then((text) => {
      const error = text || response.statusText
      return Promise.reject(error)
    })
  }
}

function handleStringResponse(response) {
  if (response.status === 401) {
    usersService.logout()
    location.reload(true)
  }

  if (response.ok) {
    return response.text().then((text) => {
      return text
    })
  } else {
    return response.text().then((text) => {
      const error = text || response.statusText
      return Promise.reject(error)
    })
  }
}

function handleError(error) {
  console.error(error)
}

function handleResponseWithError(response) {
  return response.text().then((text) => {
    const data = text && tryParseJson(text)
    if (!response.ok) {
      if (response.status === 401) {
        usersService.logout()
        location.reload(true)
      }
      const error = (data && data.message) || text
      return Promise.reject(error)
    }
    return data
  })
}

function tryParseJson(text) {
  try {
    return JSON.parse(text)
  } catch (e) {
    return text
  }
}
