<template>
  <v-dialog v-model="dialog" width="fit-content">
    <template v-slot:activator="{ on, attrs }">
      <v-btn :color="color" small dark v-bind="attrs" v-on="on" v-if="label">
        {{ label }}
        <v-icon small>{{ icon }}</v-icon>
      </v-btn>
      <v-btn
        :color="color"
        elevation="0"
        height="50"
        width="50"
        fab
        v-bind="attrs"
        v-on="on"
        v-else
      >
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="primary white--text text-caption py-2 px-3">
        {{ title }}
      </v-card-title>
      <v-card-text class="pa-10">
        {{ body }}
      </v-card-text>
      <v-card-actions class="justify-start px-2">
        <v-btn
          class="primary"
          small
          @click="$emit('submit'); dialog = false">
          <span>{{ $t('label.confirm') }}</span>
        </v-btn>
        <v-btn class="error" small @click="dialog = false">
          <span>{{ $t('label.close') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ButtonConfirmDialog',
  emits: ['submit'],
  props: {
    label: {
      type: String,
    },
    icon: {
      type: String,
    },
    color: {
      type: String,
      default: 'primary',
    },
    title: {
      type: String,
    },
    body: {
      type: String,
      default: 'Lore ipsum',
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
}
</script>

<style scoped>
.v-card__title {
  min-height: 36px !important;
}
</style>
