import { authHeader } from '@/helpers/auth-header'
import { commons } from './commons'

export const authorizationsService = {
  getUserAuthorizations
}

const resourceUrl = process.env.VUE_APP_GSCV_ENGINE_URL + `/authorizations`

function getUserAuthorizations() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl, requestOptions).then(
    commons.handleJsonResponse,
  )
}
