<template>
  <v-container class="pa-3" fluid>
    <v-row class="ma-0" align="center" v-for="(prop, i) in props" :key="i">
      <v-col cols="auto" class="d-flex align-center pa-0">
        <!-- <v-icon v-if="prop.value == 'customsClearanceStatus'" class="mr-2">mdi-list-status</v-icon> -->
        <v-checkbox
          v-model="entity[prop.value]"
          @change="update($event, prop)"
          hide-details
          class="ma-0 pa-0"
          :ripple="false"
        />
      </v-col>
      <v-col class="d-flex align-center pa-0">
        <!-- style="max-width: 25rem;" -->
        <span>
          {{ prop.text }}
        </span>
        <!-- <v-select
          v-if="prop.value == 'customsClearanceStatus'"
          v-model="entity[prop.value]"
          :items="customsStatus"
          item-text="text"
          item-value="value"
          @change="update"
          class="ma-0 pa-0 text-caption"
          hide-details
          solo
          flat
        /> -->
      </v-col>
      <v-col class="pa-0">
        <v-menu
          offset-y
          close-on-click
          :close-on-content-click="false"
          :open-on-click="entity[prop.note] && entity[prop.note].length > 50 ? true : false"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="entity[prop.note]"
              v-on="on"
              :ref="prop.value"
              @change="update(null, prop)"
              @click:clear="clearPropNotes(prop)"
              maxlength="100"
              class="ma-0 pa-0 text-caption"
              :append-icon="!entity[prop.note] ? 'mdi-pencil' : ''"
              hide-details
              clearable
            />
          </template>
          <v-card class="dropdown-menu">
            <v-textarea
              v-model="entity[prop.note]"
              maxlength="100"
              hide-details
              auto-grow
              solo
            />
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PraticaActions',
  props: ['entity', 'props'],
  emits: ['update-props'],
  data() {
    return {
      customsStatus: [
        {
          text: this.$t('label.notice'),
          value: 'PRE_ALERT',
        },
        {
          text: this.$t('label.billingRequest'),
          value: 'NOTIFICATION_MERCHANDISE_READY',
        },
        {
          text: this.$t('label.goodsCleared'),
          value: 'REQUEST_PROCESSED',
        },
      ],
    }
  },
  methods: {
    update(value, prop) {
      if (prop.value == 'customsClearanceStatus')
        this.entity['customsClearanceStatus'] = value ? 'PRE_ALERT' : null
      this.$emit('update-props', this.entity)
    },
    clearPropNotes(prop) {
      this.$nextTick(() => {
        this.$refs[prop.value][0].blur()
      })
    },
  },
}
</script>

<style scoped>
.dropdown-menu {
  width: 100%;
  padding: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.v-textarea::v-deep textarea {
  max-height: 5.5rem;
}
.v-select {
  max-width: 13rem;
}
.v-select::v-deep .v-input__control {
  min-height: 0 !important;
}
.v-select::v-deep .v-select__selection {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>
