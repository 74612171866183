<template>
  <v-menu
    v-model="menu"
    offset-y
    close-on-click
    :open-on-click="false"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :label="label"
        :value="currentSelection"
        v-if="!showSearch"
        @click="showSearch = true"
        @click:append="clearSelection"
        :prepend-inner-icon="icon"
        :append-icon="selected.text && selected.text.length ? 'mdi-close' : ''"
        :class="textClass"
        hide-details
        readonly
        dense
      />
      <v-text-field
        v-else
        :label="label"
        v-model="search"
        v-on="on"
        @click.stop
        @click="showSearch = !showSearch"
        @click:append="browseList(search)"
        @keydown.enter="browseList(search)"
        @blur="resetSearchBox"
        :prepend-inner-icon="icon"
        append-icon="mdi-magnify"
        :class="textClass"
        hide-details
        dense
      />
    </template>
    <v-card class="dropdown-menu">
      <v-data-table
        v-model="tableSelection"
        :key="tableSelection.length"
        :headers="[{ text: '', value: text }]"
        :items="filterList"
        :items-per-page="10"
        :page.sync="tablePage"
        @item-selected="(v) => selectItem(v)"
        hide-default-header
        class="custom-table"
        checkbox-color="primary"
        show-select
        dense
      >
        <template v-slot:item[text]="{ item }">
          <td class="custom-table-text-td">
            {{ item[text] }}
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'CustomAutocomplete',
  props: ['current', 'value', 'text', 'label', 'icon', 'textClass'],
  emits: ['select', 'browse'],
  data() {
    return {
      menu: false,
      tablePage: 1,

      search: '',
      showSearch: false,
      tableSelection: [],
      selected: {
        value: [],
        text: [],
      },
      filterList: [],
    }
  },
  computed: {
    currentSelection(){
      let selectionText = this.selected.text
      return selectionText ? selectionText.join(', ') : ''
    }
  },
  watch: {
    menu(v) {
      if (!v) this.resetSearchBox()
    },
    current: {
      handler(value) {
        this.selected = value ? this._.cloneDeep(value) : {}
        if (this.selected) this.setSelectedItems()
      },
      deep: true,
    },
  },
  methods: {
    browseList(search) {
      if (!search || !search.length) return
      this.$emit('browse', search)
    },
    showList(resp) {
      this.filterList = resp
      this.setSelectedItems()
      this.menu = true
    },
    setSelectedItems() {
      let selections = this.selected.value
      if (!selections) return
      let toAdd = []
      selections.forEach((selection) => {
        let itemInList = this.filterList.filter((item) =>
          item[this.value].toUpperCase().includes(selection.toUpperCase()),
        )
        if (itemInList.length) toAdd.push(itemInList[0])
      })
      this.tableSelection = toAdd
    },
    selectItem(input) {
      if (!this.selected.value.includes(input.item[this.value])) {
        this.selected.value.push(input.item[this.value])
        this.selected.text.push(input.item[this.text])
      } else {
        let index = this.selected.value.indexOf(input.item[this.value])
        this.selected.value.splice(index, 1)
        index = this.selected.text.indexOf(input.item[this.text])
        this.selected.text.splice(index, 1)
      }
      this.$emit('select', this.selected)
    },
    clearSelection() {
      this.selected.text = []
      this.selected.value = []
      this.tableSelection = []
      this.$emit('select', this.selected)
    },
    resetSearchBox() {
      this.showSearch = false
      this.search = ''
      this.tablePage = 1
    },
  },
  created() {
    if (this.current) this.selected = this._.cloneDeep(this.current)
  },
}
</script>

<style scoped>
.custom-table {
  width: 100%;
  height: 100%;
}
.custom-table::v-deep .v-data-footer {
  justify-content: right;
}
.custom-table::v-deep .v-data-footer__select {
  display: none;
}
.custom-table::v-deep .v-input--selection-controls__ripple {
  height: 0 !important;
}
.custom-table::v-deep tr {
  display: flex;
}
.custom-table::v-deep td {
  display: flex;
  align-items: center !important;
  padding: 0.2rem !important;
  border: none !important;
}
.custom-table-text-td {
  width: -webkit-fill-available;
}
.dropdown-menu {
  width: 100%;
  padding: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
