var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "fit-content" },
          model: {
            value: _vm.showInsertAgent,
            callback: function ($$v) {
              _vm.showInsertAgent = $$v
            },
            expression: "showInsertAgent",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "pa-6" }),
              _c("v-divider", { staticClass: "ma-0 mb-0" }),
              _c(
                "v-card-text",
                { staticClass: "pa-12" },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("message.selectForeignAgentCode"))
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-autocomplete", {
                                ref: "foreignAgentSelected",
                                attrs: {
                                  "item-text": (agent) =>
                                    `${agent.name} ${agent.sapCode} - ${agent.destination}`,
                                  items: _vm.foreignAgentsNonRevenue,
                                  "return-object": "",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item",
                                    fn: function (data) {
                                      return [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(data.item.name) +
                                                  " - " +
                                                  _vm._s(
                                                    data.item.destination
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            _c("v-list-item-subtitle", [
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(data.item.sapCode) +
                                                    " "
                                                ),
                                              ]),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.foreignAgentNonRevenueSelected,
                                  callback: function ($$v) {
                                    _vm.foreignAgentNonRevenueSelected = $$v
                                  },
                                  expression: "foreignAgentNonRevenueSelected",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider", { staticClass: "ma-0 mt-0" }),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-end pa-3" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "secondary pa-1",
                      attrs: { small: "" },
                      on: { click: _vm.confirmSelectedNonRevenue },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("label.confirm")))])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("EmailDialog", {
        ref: "emailDialog",
        attrs: {
          msgTemplate: _vm.emailTemplate,
          emailAddresses: _vm.foreignAgentContacts,
        },
        on: { "email-sent": _vm.handleNewMail },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }