<template>
  <v-container fill-height fluid class="d-block pa-0">
    <v-row
      dense
      v-if="
        internalVisibilityAuth ||
        (!internalVisibilityAuth &&
          (!isEmpty(browseRequest.filter) ||
            !isEmpty(browseRequest.sortingList)))
      "
    >
      <v-col cols="9" class="py-0" v-if="!isEmpty(browseRequest.filter)">
        <div>
          <v-chip color="secondary" small dark class="mt-1 mr-1">
            <v-avatar left>
              <v-icon size="20">mdi-filter-check</v-icon>
            </v-avatar>
            {{ $t("label.activeFilters") }}
          </v-chip>
          <v-chip
            color="secondary"
            outlined
            small
            dark
            class="mr-1 mt-1 px-2"
            v-for="(key, i) in Object.keys(visualFilter)"
            :key="i"
          >
            <div v-html="formatFilterChip(key, visualFilter[key])"></div>
          </v-chip>
        </div>
      </v-col>
      <v-spacer />
      <v-col cols="3" class="d-flex justify-end align-start px-0">
        <v-chip
          v-for="(key, i) in Object.keys(browseRequest.sortingList)"
          :key="i"
          @click="resetBrowseRequestSortingList"
          color="secondary"
          class="mr-1"
          outlined
          small
          dark
        >
          <div v-html="formatSortByChip(browseRequest.sortingList[key])" />
        </v-chip>
        <FiltersSectionCustomsDashboard
          v-if="internalVisibilityAuth"
          ref="filterSection"
          @sort="sort"
          @filter="filter"
          @reset-filter="resetAllBrowseRequestFiltersAndBrowse"
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-data-table
          :headers="[]"
          :items="browseResponse.resultList"
          :items-per-page="itemsPerPage"
          hide-default-footer
          class="cards-table"
        >
          <template v-slot:item="{ item, index }">
            <InvoiceLineItem
              :invoice="item"
              :currentSelection="invoiceBusinessKeys"
              :showSelection="showSelectableTable"
              @select-row="saveSelectedRow(index)"
              @add-to-selection="selectItem"
              :class="selectedRow == index ? 'selected-row' : ''"
            />
          </template>

          <template v-slot:top>
            <v-row dense class="ma-0">
              <v-col class="d-flex align-center pa-0">
                <span class="font-weight-bold ml-1">{{
                  browseResponse.totalCount
                }}</span>
                <span class="text-caption ml-1">
                  {{ $t("label.resultsFound") }}
                </span>
              </v-col>
              <v-col class="pa-1">
                <TableNav
                  ref="tableNav"
                  :browse-count="browseResponse.totalCount"
                  :browse-limit="browseRequest.limit"
                  :browse-offset="browseRequest.offset"
                  @offset-changed="browseRequestOffsetChanged"
                  @get-current-page="setCurrent"
                />
              </v-col>
              <v-col class="d-flex align-center pa-0">
                <v-spacer />
                <div
                  class="d-flex align-center pa-0"
                  v-if="internalVisibilityAuth"
                >
                  <v-select
                    v-model="currentAction"
                    :items="dashboardActions"
                    :menu-props="{
                      offsetY: true,
                      contentClass: 'mx-1',
                    }"
                    class="actions-select text-caption mx-1"
                    style="max-width: 10rem"
                    hide-details
                    clearable
                    outlined
                    x-small
                    dense
                  />
                  <v-btn
                    :disabled="!invoiceBusinessKeys.length"
                    @click="doAction(currentAction)"
                    class="px-0"
                    color="primary"
                    icon
                  >
                    <v-icon size="30"> mdi-play-circle </v-icon>
                  </v-btn>
                </div>
                <FiltersSectionCustomsDashboard
                  v-else
                  ref="filterSection"
                  @sort="sort"
                  @filter="filter"
                  @reset-filter="resetAllBrowseRequestFiltersAndBrowse"
                />
              </v-col>
            </v-row>
            <v-progress-linear v-if="loading" indeterminate class="mt-1" />
          </template>

          <template v-slot:footer>
            <v-progress-linear
              v-if="loading && browseResponse.resultList.length"
              indeterminate
            />
            <v-row dense class="ma-0">
              <v-col class="pa-0">
                <TableNav
                  v-if="browseResponse.resultList.length"
                  ref="tableNavFooter"
                  :browse-count="browseResponse.totalCount"
                  :browse-limit="browseRequest.limit"
                  :browse-offset="browseRequest.offset"
                  @offset-changed="browseRequestOffsetChanged"
                  @get-current-page="setCurrent"
                />
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" timeout="1000" color="warning">
      <v-icon color="white" class="mr-2">
        {{ isSuccess ? "mdi-check-circle" : "mdi-alert-circle" }}
      </v-icon>
      <span class="white--text">{{ message }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar = false">
          <v-icon color="white"> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <template>
      <div class="text-center">
        <v-dialog v-model="dialog" persistent width="auto">
          <v-card class="d-flex flex-column align-center">
            <v-card-subtitle class="py-1">{{ dialogMsg }}</v-card-subtitle>
            <v-card-text class="py-1">
              <v-data-table
                :headers="[]"
                :items="shipmentInvoices"
                hide-default-footer
              >
                <template v-slot:item="{ item }">
                  <ShipmentInvoice
                    :relationship="item"
                    @add-to-selection="addToSelection"
                    @remove-from-selection="removeFromSelection"
                  ></ShipmentInvoice>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions class="display-flex justify-center">
              <v-btn color="success" @click="handleConfirm">{{
                $t("label.confirm")
              }}</v-btn>
              <v-btn color="error" @click="handleCancel">{{
                $t("label.cancel")
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
    <template>
      <v-dialog v-model="transportJobStatusDialog" persistent width="auto">
        <v-card
          class="d-flex flex-column align-center"
          :loading="loadingTransportJob"
        >
          <v-card-text>{{ transportJobStatusMsg }}</v-card-text>
          <v-card-actions
            ><v-btn
              :disabled="disabled"
              @click="
                transportJobStatusDialog = false;
                browse();
              "
              >CANCEL</v-btn
            ></v-card-actions
          >
        </v-card>
      </v-dialog>
    </template>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { invoicesService } from "../../services/invoices.service";
import InvoiceLineItem from "../elements/invoices/InvoiceLineItem.vue";
import TableNav from "../elements/TableNav.vue";
import FiltersSectionCustomsDashboard from "../elements/invoices/FiltersSectionCustomsDashboard.vue";
import ShipmentInvoice from "../elements/ShipmentInvoice.vue";
import { shipmentLinkService } from "../../services/shipmentLink.service";

export default {
  name: "CustomsDashboard",
  data() {
    return {
      browseRequest: {
        offset: 0,
        limit: 10,
        filter: {},
        sortingList: [],
      },

      browseResponse: {
        resultList: [],
        totalCount: 0,
      },

      loading: false,
      currentPage: 1,
      itemsPerPage: 10,
      visualFilter: {},

      selectedRow: 0,
      rowsSelection: [],
      invoiceBusinessKeys: [],

      currentAction: null,
      sendToMastersped: this.$t("upper.sendToMastersped"),
      showSelectableTable: false,

      emailTemplate: {},
      message: "",
      snackbar: false,
      isSuccess: true,

      transportJobMasterspedRequest: {},

      dialog: false,
      dialogMsg:
        "The following invoices are linked to the same shipment number:",

      shipmentInvoices: [],

      loadingTransportJob: true,
      transportJobStatusDialog: false,
      transportJobStatusMsg: "",
      disabled: true,
    };
  },
  components: {
    TableNav,
    FiltersSectionCustomsDashboard,
    InvoiceLineItem,
    ShipmentInvoice,
  },
  computed: {
    ...mapState("account", ["user", "internalVisibilityAuth"]),
    ...mapState("searchbar", ["search", "searchField"]),
    dashboardActions() {
      return [this.$t("upper.sendToMastersped")];
    },
    userCompleteName() {
      let name =
        this.user.firstName.charAt(0).toUpperCase() +
        this.user.firstName.slice(1);
      let surname =
        this.user.lastName.charAt(0).toUpperCase() +
        this.user.lastName.slice(1);
      return name.concat(" ").concat(surname);
    },
  },
  watch: {
    currentAction(action) {
      this.rowsSelection = [];
      this.showSelectableTable = action ? true : false;
    },
    "browseRequest.offset"() {
      this.browse();
    },
  },
  methods: {
    loadSelectedRow() {
      this.selectedRow = this.getCurrentRow(this.$options.name);
      if (!this.selectedRow) this.saveSelectedRow(0);
    },
    saveSelectedRow(index) {
      this.selectedRow = index;
      this.setCurrentRow(this.$options.name, this.selectedRow);
    },
    loadBrowseMemory() {
      let memory = this.getBrowseMemory(this.$options.name);
      if (!memory) return;
      this.visualFilter = memory.visualFilter;
      this.currentPage = memory.currentPage;

      this.browseRequest.offset = memory.browseOffset;
      this.browseRequest.limit = memory.browseLimit;
      this.browseRequest.filter = memory.browseFilter;
      this.browseRequest.sortingList = memory.sortBy;
    },
    browse() {
      this.loading = true;
      invoicesService
        .browse(
          this.browseRequest.offset,
          this.browseRequest.limit,
          this.browseRequest.filter,
          this.browseRequest.sortingList
        )
        .then((resp) => {
          if (!resp) return (this.loading = false);
          this.browseResponse.resultList = resp.resultList;
          this.browseResponse.totalCount = resp.totalCount;
          this.rowsSelection = [];
          this.loading = false;
          this.saveBrowseMemory();
          this.goToSelectedRow();
        });
    },
    saveBrowseMemory() {
      let memory = {
        visualFilter: this.visualFilter,
        currentPage: this.currentPage,

        browseOffset: this.browseRequest.offset,
        browseLimit: this.browseRequest.limit,
        browseFilter: this.browseRequest.filter,
        sortBy: this.browseRequest.sortingList,
      };
      this.setBrowseMemory(this.$options.name, memory);
    },
    goToSelectedRow() {
      const options = {
        duration: 500,
        offset: 410,
      };
      if (this.selectedRow == 0) return;
      this.$nextTick(() =>
        setTimeout(() => {
          this.$vuetify.goTo(".selected-row", options);
          this.saveSelectedRow(0);
        }, 500)
      );
    },
    isEmpty(object) {
      return Object.keys(object).length === 0;
    },
    formatSortByChip(val) {
      let column = val.column.charAt(0).toUpperCase() + val.column.slice(1);
      let direction = val.direction;
      return `<b>${column} - ${direction}</b>`;
    },
    formatFilterChip(key, value) {
      let chipText = key.replace(/([a-z])([A-Z])/g, "$1 $2");
      if (chipText.includes("Nbr")) {
        chipText =
          chipText.charAt(0).toUpperCase() +
          chipText.slice(1).toLowerCase() +
          ".";
      } else if (chipText.includes("Name")) {
        chipText = chipText.replace(/ .*/, "");
        chipText = chipText.charAt(0).toUpperCase() + chipText.slice(1);
      } else {
        chipText =
          chipText.charAt(0).toUpperCase() + chipText.slice(1).toLowerCase();
      }

      let valueToArray = value.toString().split(",");
      if (valueToArray.length > 1) {
        return (chipText += `<b> +${valueToArray.length}</b>`);
      } else {
        return (chipText += `<b> ${value}</b>`);
      }
    },
    resetBrowseRequestSortingList() {
      this.browseRequest.sortingList = [];
      this.browse();
    },
    sort(sortingList) {
      if (this._.isEqual(this.browseRequest.sortingList[0], sortingList))
        return;
      this.browseRequest.sortingList = [];
      if (sortingList) this.browseRequest.sortingList.push(sortingList);
      this.resetBrowseRequestOffsetAndBrowse();
    },
    filter(browseFilter, visualFilter) {
      this.browseRequest.filter = browseFilter;
      this.visualFilter = visualFilter;
      this.currentPage != 1
        ? this.resetBrowseRequestOffset()
        : this.resetBrowseRequestOffsetAndBrowse();
    },
    resetBrowseRequestOffsetAndBrowse() {
      this.resetBrowseRequestOffset();
      this.browse();
    },
    resetBrowseRequestOffset() {
      this.browseRequest.offset = 0;
    },
    resetAllBrowseRequestFiltersAndBrowse() {
      this.browseRequest.sortingList = [];
      this.$refs.filterSection.resetAllFilters();
    },
    removeSelectedRow() {
      this.removeCurrentRow(this.$options.name);
    },
    browseRequestOffsetChanged(newOffset, page) {
      this.browseRequest.offset = newOffset;
      this.currentPage = page;
    },
    setCurrent() {
      this.$refs.tableNav.page = this.currentPage;
      if (this.$refs.tableNavFooter)
        this.$refs.tableNavFooter.page = this.currentPage;
    },
    doAction(action) {
      switch (action) {
        case this.$t("upper.sendToMastersped"):
          this.sendToMasterspedAction();
          break;
      }
    },
    sendToMasterspedAction() {
      shipmentLinkService
        .getAssociatedInvoices(this.invoiceBusinessKeys)
        .then((resp) => {
          if (resp) {
            resp.forEach((shipmentInvoices) => {
              shipmentInvoices.relatedInvoices.forEach((shipmentLink) => {
                this.shipmentInvoices.push({
                  shipmentLink: shipmentLink,
                  description:
                    shipmentInvoices.shipmentNbr == ""
                      ? "Delivery from warehouse?"
                      : this.invoiceBusinessKeys.includes(shipmentLink)
                      ? "Same shipment selected"
                      : "Same shipment found into the db",
                  alreadyMatched:
                    this.invoiceBusinessKeys.includes(shipmentLink),
                });
              });
            });
          }
          this.invoiceBusinessKeys = [];
          this.dialog = true;
        });
    },
    addToSelection(invoiceBusinessKey) {
      this.invoiceBusinessKeys.push(invoiceBusinessKey);
    },
    removeFromSelection(invoiceBusinessKey) {
      const index = this.invoiceBusinessKeys.indexOf(invoiceBusinessKey);
      if (index > -1) {
        this.invoiceBusinessKeys.splice(index, 1);
      }
    },
    handleConfirm() {
      this.dialog = false;
      this.shipmentInvoices = [];

      this.loadingTransportJob = true;
      this.transportJobStatusDialog = true;
      this.disabled = true;

      invoicesService
        .createTransportJob(this.invoiceBusinessKeys)
        .then((resp) => {
          if (resp.ok) {
            resp.text().then((text) => {
              this.loadingTransportJob = false;
              this.transportJobStatusMsg = text;
              this.disabled = false;
            });
          } else {
            console.log("Error");
          }
        });
    },
    handleCancel() {
      this.dialog = false;
      this.shipmentInvoices = [];
    },

    openSnackbar(msg, resp) {
      this.message = msg;
      this.snackbar = true;
      this.isSuccess = resp;
    },
    selectItem(invoiceBusinessKey, value) {
      if (value) {
        this.addToSelection(invoiceBusinessKey);
      } else {
        this.removeFromSelection(invoiceBusinessKey);
      }
    },
  },
  created() {
    this.loadSelectedRow();
    this.loadBrowseMemory();
    if (this.currentPage == 1) this.browse();
  },
};
</script>

<style scoped>
.nohover tr:hover {
  background-color: transparent !important;
}
.cards-table {
  background-color: var(--v-primary-lighten2);
}
.actions-select::v-deep .v-input__append-inner {
  margin: 0 !important;
  padding: 0 !important;
}
.actions-select::v-deep .v-input__slot {
  min-height: 0 !important;
  max-height: fit-content !important;
  padding-right: 0 !important;
}
.actions-select::v-deep .v-select__selection--comma {
  margin: 0 !important;
  padding: 0 !important;
  padding-top: 0.1rem !important;
}
.actions-select::v-deep input {
  padding: 0 !important;
}
.actions-select::v-deep .v-select__selections {
  padding: 0 !important;
}
.actions-select::v-deep .v-input__append-inner {
  align-self: center !important;
}
.actions-select::v-deep .v-input__icon--clear {
  min-width: 0 !important;
  width: fit-content !important;
}
.actions-select::v-deep .v-input__icon--clear .v-icon {
  font-size: 15px !important;
}
</style>
