var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "d-flex justify-center",
      attrs: { id: "main", "fill-height": "", fluid: "" },
    },
    [
      _c("div", { staticClass: "d-flex flex-column align-center" }, [
        _c("h2", { staticStyle: { color: "#c3342f" } }, [
          _vm._v(_vm._s(_vm.appTitle)),
        ]),
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c(
                "v-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.errorMessage
                    ? _c(
                        "v-alert",
                        { attrs: { type: "error", dismissible: "" } },
                        [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Username" },
                        model: {
                          value: _vm.username,
                          callback: function ($$v) {
                            _vm.username = $$v
                          },
                          expression: "username",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Password",
                          "append-icon": _vm.showPassword
                            ? "mdi-eye"
                            : "mdi-eye-off",
                          type: _vm.showPassword ? "text" : "password",
                        },
                        on: {
                          "click:append": function ($event) {
                            _vm.showPassword = !_vm.showPassword
                          },
                        },
                        model: {
                          value: _vm.password,
                          callback: function ($$v) {
                            _vm.password = $$v
                          },
                          expression: "password",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-btn", { attrs: { color: "primary", type: "submit" } }, [
                    _vm._v("Login"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }