<template>
  <v-container fill-height fluid class="d-block pa-0">
    <v-row dense>
      <v-col cols="7" class="d-flex flex-column py-0">
        <PraticheFilter
          ref="praticheFilterSection"
          @set-filter="filter"
          class="py-1"
        ></PraticheFilter>
        <v-data-table
          :headers="[]"
          :items="shipmentSearch.browseResponse.resultList"
          hide-default-footer
          class="cards-table"
        >
          <template v-slot:item="{ item }">
            <ShipmentLineItem
              :shipment="item"
              @selected-shipment-document-nbr="selectShipment"
            />
          </template>
          <template v-slot:footer>
            <v-row dense class="ma-0">
              <v-col class="pa-0">
                <TableNav
                  ref="tableNavPratiche"
                  :browse-count="shipmentSearch.browseResponse.totalCount"
                  :browse-limit="shipmentSearch.browseRequest.limit"
                  :browse-offset="shipmentSearch.browseRequest.offset"
                  @offset-changed="browseRequestOffsetChangedPraticheSearch"
                  @get-current-page="setCurrentPagePraticheSearch"
                />
              </v-col>
            </v-row>
          </template>
        </v-data-table>

        <!-- <template>
          <PraticheTable
            :praticas="shipmentSearch.browseResponse.resultList"
            @shipment="selectShipment"
          ></PraticheTable>
          <v-row dense class="py-1">
            <v-col>
              <TableNav
                ref="tableNavPratiche"
                :browse-count="shipmentSearch.browseResponse.totalCount"
                :browse-limit="shipmentSearch.browseRequest.limit"
                :browse-offset="shipmentSearch.browseRequest.offset"
                @offset-changed="browseRequestOffsetChangedPraticheSearch"
                @get-current-page="setCurrentPagePraticheSearch"
              />
            </v-col>
          </v-row>
        </template> -->
      </v-col>

      <v-col cols="5" class="d-flex flex-column py-0">
        <InvoiceFilter
          ref="invoicesFilterSection"
          @set-filter="filter"
          class="py-1"
        ></InvoiceFilter>
        <v-data-table
          :headers="[]"
          :items="invoiceSearch.browseResponse.resultList"
          hide-default-footer
          class="cards-table"
        >
          <template v-slot:item="{ item }">
            <InvoiceLinkItem
              :invoice="item"
              @invoice-document-nbr="handleMatch"
            />
          </template>
          <template v-slot:footer>
            <v-row dense class="ma-0">
              <v-col class="pa-0">
                <TableNav
                  ref="tableNavInvoices"
                  :browse-count="invoiceSearch.browseResponse.totalCount"
                  :browse-limit="invoiceSearch.browseRequest.limit"
                  :browse-offset="invoiceSearch.browseRequest.offset"
                  @offset-changed="browseRequestOffsetChangedInvoiceSearch"
                  @get-current-page="setCurrentPageInvoiceSearch"
                />
              </v-col>
            </v-row>
          </template>
        </v-data-table>

        <!-- <template>
          <InvoicesTable
            :invoices="invoiceSearch.browseResponse.resultList"
            @invoice="handleMatch"
          ></InvoicesTable>
          <v-row dense class="py-1">
            <v-col>
              <TableNav
                ref="tableNavInvoices"
                :browse-count="invoiceSearch.browseResponse.totalCount"
                :browse-limit="invoiceSearch.browseRequest.limit"
                :browse-offset="invoiceSearch.browseRequest.offset"
                @offset-changed="browseRequestOffsetChangedInvoiceSearch"
                @get-current-page="setCurrentPageInvoiceSearch"
              />
            </v-col>
          </v-row>
        </template> -->
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" timeout="2000" :color="color">
      <v-icon color="white" class="mr-2">
        {{ isSuccess ? "mdi-check-circle" : "mdi-alert-circle" }}
      </v-icon>
      <span class="white--text">{{ message }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar = false">
          <v-icon color="white"> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <template>
      <div>
        <v-dialog v-model="dialog" width="auto" persistent>
          <v-card>
            <v-card-text class="text-center pt-2 pb-0">
              Invoice <b>{{ params.invoiceNbr }}</b> is already associated with
              the shipment number
              <b>{{ params.shipmentDocumentNbr }}</b>
              <br />
              Are you sure you want to proceed?
            </v-card-text>
            <v-card-actions class="display-flex justify-center">
              <v-btn color="success" @click="handleConfirm">Yes</v-btn>
              <v-btn color="error" @click="handleCancel">No</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
  </v-container>
</template>

<script>
import { invoicesService } from "../../services/invoices.service";
import { praticheService } from "../../services/pratiche.service";
import PraticheFilter from "../ShipmentsPage/PraticheFilter.vue";
import InvoiceFilter from "../ShipmentsPage/InvoiceFilter.vue";
import TableNav from "../elements/TableNav.vue";
import { shipmentLinkService } from "../../services/shipmentLink.service";
import ShipmentLineItem from "../ShipmentsPage/ShipmentLineItem.vue";
import InvoiceLinkItem from "../ShipmentsPage/InvoiceLinkItem.vue";
import PraticheTable from "../ShipmentsPage/PraticheTable.vue";
import InvoicesTable from "../ShipmentsPage/InvoicesTable.vue";

export default {
  data() {
    return {
      invoiceSearch: {
        browseRequest: {
          offset: 0,
          limit: 10,
          filter: {
            shipmentDocumentNbrNot: "",
            shipmentNbrNotMatched: true,
          },
          sortingList: [],
        },

        browseResponse: {
          resultList: [],
          totalCount: 0,
        },

        currentPage: 1,
        itemsPerPage: 10,
      },

      relatedInvoicesBusinessKey: [],

      shipmentSearch: {
        browseRequest: {
          offset: 0,
          limit: 10,
          filter: {},
          sortingList: [],
        },

        browseResponse: {
          resultList: [],
          totalCount: 0,
        },

        currentPage: 1,
        itemsPerPage: 10,
      },

      selectedShipmentDocumentNbr: "",

      message: "",
      snackbar: false,
      isSuccess: true,
      color: "success",

      dialog: false,

      params: {
        organizationUnitId: "",
        documentNbr: "",
        invoiceNbr: "",
        sellerCode: "",
        shipmentDocumentNbr: "",
      },

      showFullText: [false, false, false],
    };
  },
  watch: {
    relatedInvoicesBusinessKey() {
      this.invoiceSearch.browseResponse.resultList = [];
      const filter = {
        shipmentDocumentNbr: this.selectedShipmentDocumentNbr,
      };
      invoicesService
        .browse(0, this.relatedInvoicesBusinessKey.length, filter, [])
        .then((resp) => {
          if (resp) {
            resp.resultList.forEach((item) => {
              item.isLinked = true;
              this.invoiceSearch.browseResponse.resultList.push(item);
            });

            this.invoiceSearch.browseResponse.totalCount = resp.totalCount;
            this.invoiceSearch.browseRequest.filter.shipmentDocumentNbrNot =
              this.selectedShipmentDocumentNbr;
            this.browseInvoices();
          }
        });
    },
    selectedShipmentDocumentNbr() {
      this.shipmentSearch.browseResponse.resultList.forEach((item) => {
        item.isSelected = item.documentNbr === this.selectedShipmentDocumentNbr;
      });
    },

    "invoiceSearch.browseRequest.offset"() {
      this.browseInvoices();
    },
    "invoiceSearch.browseRequest.filter"() {
      this.browseInvoices();
    },
    "shipmentSearch.browseRequest.offset"() {
      this.browseShipments();
    },
    "shipmentSearch.browseRequest.filter"() {
      this.browseShipments();
    },
  },
  methods: {
    toggleShowFullText(index) {
      this.$set(this.showFullText, index, !this.showFullText[index]);
    },

    browseShipments() {
      praticheService
        .browse(
          this.shipmentSearch.browseRequest.offset,
          this.shipmentSearch.browseRequest.limit,
          this.shipmentSearch.browseRequest.filter,
          this.shipmentSearch.browseRequest.sortingList
        )
        .then((resp) => {
          //   this.shipmentSearch.browseResponse.resultList = resp.resultList;
          this.shipmentSearch.browseResponse.resultList = [];
          resp.resultList.forEach((item) => {
            item.isSelected =
              item.documentNbr === this.selectedShipmentDocumentNbr;
            this.shipmentSearch.browseResponse.resultList.push(item);
          });
          this.shipmentSearch.browseResponse.totalCount = resp.totalCount;
        });
    },
    browseInvoices() {
      console.log(this.invoiceSearch.browseRequest.filter);
      invoicesService
        .browse(
          this.invoiceSearch.browseRequest.offset,
          this.invoiceSearch.browseRequest.limit,
          this.invoiceSearch.browseRequest.filter,
          this.invoiceSearch.browseRequest.sortingList
        )
        .then((resp) => {
          this.invoiceSearch.browseResponse.resultList.splice(
            this.relatedInvoicesBusinessKey.length
          );

          if (this.invoiceSearch.browseRequest.offset > 0) {
            this.invoiceSearch.browseResponse.resultList = [];
          }
          resp.resultList.forEach((item) => {
            item.isLinked = false;
            this.invoiceSearch.browseResponse.resultList.push(item);
          });

          this.invoiceSearch.browseResponse.totalCount =
            this.relatedInvoicesBusinessKey.length + resp.totalCount;
        });
    },

    selectShipment(shipmentDocumentNbr) {
      if (shipmentDocumentNbr != this.selectedShipmentDocumentNbr) {
        this.selectedShipmentDocumentNbr = shipmentDocumentNbr;
        this.findRelatedInvoices();
      } else {
        this.deselectShipmentDocumentNbr();
      }
    },
    findRelatedInvoices() {
      shipmentLinkService
        .getInvoicesForShipment(this.selectedShipmentDocumentNbr)
        .then((resp) => {
          this.relatedInvoicesBusinessKey = resp;
        });
    },

    handleMatch(invoiceShipmentLink, value) {
      const invoiceShipmentLinkCopy = invoiceShipmentLink;
      invoiceShipmentLinkCopy.shipmentDocumentNbr =
        this.selectedShipmentDocumentNbr;
      if (value) {
        shipmentLinkService
          .getShipmentDocumentNbr(invoiceShipmentLink)
          .then((resp) => {
            resp.text().then((text) => {
              if (!text) {
                this.linkInvoiceToShipment(invoiceShipmentLinkCopy);
              } else if (text != this.selectedShipmentDocumentNbr) {
                this.params.organizationUnitId =
                  invoiceShipmentLink.organizationUnitId;
                this.params.sellerCode = invoiceShipmentLink.sellerCode;
                this.params.documentNbr = invoiceShipmentLink.documentNbr;
                this.params.invoiceNbr = invoiceShipmentLink.invoiceNbr;
                this.params.shipmentDocumentNbr = text;
                this.dialog = true;
              }
            });
          });
      } else {
        this.unlinkInvoiceToShipment(invoiceShipmentLinkCopy);
      }
    },

    linkInvoiceToShipment(invoiceShipmentLink) {
      shipmentLinkService
        .saveRelationship(invoiceShipmentLink)
        .then((response) => {
          if (response.ok) {
            this.invoiceSearch.browseResponse.resultList.forEach((item) => {
              if (
                item.organizationUnitId ===
                  invoiceShipmentLink.organizationUnitId &&
                item.documentNbr === invoiceShipmentLink.documentNbr &&
                item.invoiceNbr === invoiceShipmentLink.invoiceNbr &&
                item.sellerCode === invoiceShipmentLink.sellerCode
              ) {
                item.isLinked = true;
              }
            });

            this.openSnackbar(
              "You've linked invoice #" +
                invoiceShipmentLink.invoiceNbr +
                " to shipment #" +
                this.selectedShipmentDocumentNbr +
                ".",
              true,
              "success"
            );
          } else {
            this.openSnackbar(
              "Unable to link invoice #" +
                invoiceShipmentLink.invoiceNbr +
                " to shipment #" +
                this.selectedShipmentDocumentNbr +
                ".",
              false,
              "error"
            );
          }
        });
    },
    unlinkInvoiceToShipment(invoiceShipmentLink) {
      shipmentLinkService
        .deleteRelationship(invoiceShipmentLink)
        .then((resp) => {
          if (resp.status == 200) {
            this.invoiceSearch.browseResponse.resultList.forEach((item) => {
              if (
                item.organizationUnitId ===
                  invoiceShipmentLink.organizationUnitId &&
                item.documentNbr === invoiceShipmentLink.documentNbr &&
                item.invoiceNbr === invoiceShipmentLink.invoiceNbr &&
                item.sellerCode === invoiceShipmentLink.sellerCode
              ) {
                item.isLinked = false;
              }
            });

            this.openSnackbar(
              "You've unlinked invoice #" +
                invoiceShipmentLink.invoiceNbr +
                " from shipment #" +
                this.selectedShipmentDocumentNbr +
                ".",
              true,
              "warning"
            );
          } else {
            this.openSnackbar(
              "Oops, unable to link invoice #" +
                invoiceShipmentLink.invoiceNbr +
                " to shipment #" +
                this.selectedShipmentDocumentNbr +
                ".",
              false,
              "error"
            );
          }
        });
    },

    openSnackbar(msg, resp, color) {
      this.isSuccess = resp;
      this.message = msg;
      this.snackbar = true;
      this.color = color;
    },

    handleConfirm() {
      this.dialog = false;

      shipmentLinkService.deleteRelationship(this.params);
      this.params.shipmentDocumentNbr = this.selectedShipmentDocumentNbr;
      this.linkInvoiceToShipment(this.params);
    },
    handleCancel() {
      this.dialog = false;
    },

    deselectShipmentDocumentNbr() {
      this.selectedShipmentDocumentNbr = "";

      this.relatedInvoicesBusinessKey = [];
      delete this.invoiceSearch.browseRequest.filter.shipmentDocumentNbrNot;
    },

    filter(browseFilter, context) {
      const shipmentDocumentNbrNot =
        this.invoiceSearch.browseRequest.filter.shipmentDocumentNbrNot;
      switch (context) {
        case "InvoiceFilter":
          this.invoiceSearch.browseRequest.filter = browseFilter;
          this.invoiceSearch.browseRequest.filter.shipmentDocumentNbrNot =
            shipmentDocumentNbrNot;
          this.invoiceSearch.browseRequest.offset = 0;
          break;
        case "PraticheFilter":
          this.shipmentSearch.browseRequest.filter = browseFilter;
          this.shipmentSearch.browseRequest.offset = 0;
      }
    },

    setCurrentPageInvoiceSearch() {
      this.$refs.tableNavInvoices.page = this.invoiceSearch.currentPage;
    },
    setCurrentPagePraticheSearch() {
      this.$refs.tableNavPratiche.page = this.shipmentSearch.currentPage;
    },
    browseRequestOffsetChangedInvoiceSearch(newOffset, page) {
      this.invoiceSearch.browseRequest.offset = newOffset;
      this.invoiceSearch.currentPage = page;
    },
    browseRequestOffsetChangedPraticheSearch(newOffset, page) {
      this.shipmentSearch.browseRequest.offset = newOffset;
      this.shipmentSearch.currentPage = page;
    },

    isEmpty(object) {
      return Object.keys(object).length === 0;
    },

    selectShipmentDocumentNbr(item) {
      this.selectShipment(item.documentNbr);
    },
  },
  components: {
    TableNav,
    PraticheFilter,
    // PraticheTable,
    ShipmentLineItem,
    InvoiceFilter,
    // InvoicesTable,
    InvoiceLinkItem,
  },
  created() {
    this.browseShipments();
    this.browseInvoices();
  },
};
</script>

<style scoped>
.cards-table {
  background-color: var(--v-primary-lighten2);
}
</style>
