var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "my-1", attrs: { outlined: "" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "" }, on: { click: _vm.addToRowSelection } },
        [
          _c(
            "v-col",
            {
              staticClass:
                "py-0 text-caption font-weight-bold d-flex align-center",
              attrs: { cols: "2" },
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "ma-0 d-flex justify-center text-center",
                  attrs: { dense: "" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0 d-flex justify-center align-center",
                      attrs: { cols: "4" },
                    },
                    [
                      _vm.invoice.isLinked
                        ? _c(
                            "v-icon",
                            { attrs: { color: "success", size: "x-large" } },
                            [_vm._v("mdi-check-network")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "8" } },
                    [
                      _c("ReadonlyField", {
                        attrs: {
                          label: _vm.$t("label.invoiceNbr"),
                          value: _vm.invoice.invoiceNbr,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-col",
            {
              staticClass:
                "py-0 text-caption font-weight-bold d-flex align-center",
              attrs: { cols: "3" },
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "ma-0 d-flex justify-center text-center",
                  attrs: { dense: "" },
                },
                [
                  _c("ReadonlyField", {
                    attrs: {
                      label: _vm.$t("label.invoiceHolderName"),
                      value: _vm.invoice.invoiceHolderName,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-col",
            {
              staticClass:
                "py-0 text-caption font-weight-bold d-flex align-center",
              attrs: { cols: "2" },
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "ma-0 d-flex justify-center text-center",
                  attrs: { dense: "" },
                },
                [
                  _c("ReadonlyField", {
                    attrs: {
                      label: _vm.$t("label.sellerName"),
                      value: _vm.invoice.sellerName,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-col",
            {
              staticClass:
                "py-0 text-caption font-weight-bold d-flex align-center",
              attrs: { cols: "3" },
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "ma-0 d-flex justify-center text-center",
                  attrs: { dense: "" },
                },
                [
                  _c("ReadonlyField", {
                    attrs: {
                      label: _vm.$t("label.consigneeName"),
                      value: _vm.invoice.consigneeName,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-col",
            {
              staticClass:
                "py-0 text-caption font-weight-bold d-flex align-center",
              attrs: { cols: "2" },
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "ma-0 d-flex justify-center text-center",
                  attrs: { dense: "" },
                },
                [
                  _c("ReadonlyField", {
                    attrs: {
                      label: _vm.$t("label.totalAmount"),
                      value:
                        _vm.invoice.totalAmount + " " + _vm.invoice.currency,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }