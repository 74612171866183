<template>
  <v-container class="pa-0" fill-height fluid>
    <v-row class="ma-0 fill-height" dense>
      <v-col class="pa-0">
        <v-card 
          class="d-table fill-height pa-2" 
          width="100%" 
          @dragover.prevent="dragging = true"
          @dragenter.prevent="dragging = true"
          @dragleave.prevent="dragging = false"
        >
          <v-row class="d-table-row fill-height" @drop.prevent="onDrop($event)">
            <div v-if="dragging" :class="dragging ? 'centered dashed' : 'centered'">
              <v-icon size="60" class="mr-3">
                mdi-cloud-upload
              </v-icon>
              <span>
                {{ $t('label.dropFilesHere') }}
              </span>
            </div>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="files"
              item-key="id"
              :items-per-page="1000"
              hide-default-footer
              show-select
              fixed-header
              height="100%"
              class="scrollable"
              v-else
            >
              <template v-slot:item.fileName="{ item }">
                <v-icon color="primary" class="mr-2">
                  {{ getIcon(item) }}
                </v-icon>
                {{ item.fileName }}
              </template>
              <template v-slot:item.createDatetime="{ item }">
                {{ formatDate(item.createDatetime) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <PraticaDocumentActions
                  :document="item"
                  @delete-file="deleteFile"
                  @download-file="downloadDocument"
                />
              </template>
            </v-data-table>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="auto" class="d-flex flex-column ml-3 px-0">
        <input ref="fileUpload" type="file" hidden @change="($event) => uploadFile($event?.target?.files?.[0])" />
        <v-tooltip bottom>
          <template #activator="{ on: on }">
            <v-btn
              v-on="on"
              class="primary mb-2"
              @click="selectFile"
              height="50"
              width="50"
              icon
              fab
            >
              <v-icon color="white">mdi-upload</v-icon>
            </v-btn>
          </template>
          <span class="text-caption">{{ $t('label.uploadDocument') }}</span>
        </v-tooltip>
        <ButtonConfirmDialog
          :icon="'mdi-delete'"
          :color="'error'"
          :body="$t('message.confirmDeletion')"
          :title="$t('label.confirmDelete')"
          @submit="deleteSelected"
          v-if="selected.length"
        />
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import FileSaver from 'file-saver'
import { dateFilter } from '@/filters/dateFilter'
import { documentsService } from '@/services/documents.service.js'
import PraticaDocumentActions from '@/components/elements/pratica/PraticaDocumentActions'
import ButtonConfirmDialog from '@/components/elements/global/ButtonConfirmDialog'

export default {
  name: 'PraticaDocuments',
  props: ['pratica'],
  components: { ButtonConfirmDialog, PraticaDocumentActions },
  data() {
    return {
      selected: [],
      showDownload: {},

      dataFile: {
        fileName: 'Name',
        documentType: 'excel',
        systemTags: new Map(),
        userTags: new Map(),
        dataB64: '',
      },
      files: [],
      headers: [
        { text: this.$t('label.name'), value: 'fileName' },
        { text: this.$t('label.createDate'), value: 'createDatetime' },
        { value: 'actions', sortable: false },
      ],

      dragging: false,
    }
  },
  methods: {
    browseDocuments() {
      this.setLoading(true)
      let filter = { systemTagsAND: '{"tagFilters": [{"key": "DOCUMENT_NBR", "value":"' + this.pratica.documentNbr + '"}]}' }
      let browseRequest = {
        offset: 0,
        limit: 1000,
        filter: filter,
        sortingList: [],
      }
      documentsService
        .browse(browseRequest)
        .then((resp) => {
          if (!resp) return
          this.files = resp.resultList.filter((d) => d.deleteDatetime === null)
        })
        .catch((e) => this.setSnackbarMessage({ text: `${this.$t('message.errorRetrievingDocs')}`, color: 'error' }))
        .finally(() => this.setLoading(false))
    },
    // Upload
    selectFile() {
      this.selected = []
      this.$refs.fileUpload.click()
    },
    uploadFile(file) {
      this.setLoading(true)
      this.getBase64(file)
        .then((data) => {
          let dataFile = {
            fileName: file.name,
            documentType: 'UNDEFINED',
            systemTags: this.getTags(file),
            dataB64: data,
          }
          this.createDocument(dataFile)
        })
        .catch((e) => this.setSnackbarMessage({ text: `${this.$t('message.errorConvertingFile')}`, color: 'error' }))
    },
    createDocument(data) {
      documentsService
        .createDocument(data)
        .then(() => {
          this.setSnackbarMessage({ text: this.$t('message.docSuccessfullyUploaded'), color: 'success' })
          this.browseDocuments()
        })
        .catch((e) => this.setSnackbarMessage({ text: `${this.$t('message.errorUploadingDoc')}`, color: 'error' }))
        .finally(() => this.setLoading(false))
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          const base64String = reader.result.replace('data:', '').replace(/^.+,/, '')
          resolve(base64String)
        }
        reader.onerror = (error) => reject(error)
      })
    },
    getTags(file) {
      return { DOCUMENT_NBR: this.pratica.documentNbr, TYPE: file.type }
    },
    onDrop(e) {
      this.dragging = false
      Object.keys(e.dataTransfer.files).forEach((key) => {
        this.uploadFile(e.dataTransfer.files[key])
      })
    },
    // Download
    downloadDocument(id) {
      this.setLoading(true)
      documentsService
        .getDocument(id)
        .then((resp) => {
          if (!resp) return
          this.downloadFile(resp)
          this.setSnackbarMessage({ text: this.$t('message.docSuccessfullyDownloaded'), color: 'success' })
        })
        .catch((e) => this.setSnackbarMessage({ text: `${this.$t('message.errorDownloadingDoc')}: ${e.error}`, color: 'error' }))
        .finally(() => this.setLoading(false))
    },
    downloadFile(value) {
      const blob = new Blob([Buffer.from(value.dataB64, 'base64')]) //, { type: value.contentType }
      FileSaver.saveAs(blob, value.fileName)
    },
    // Delete
    deleteFile(id) {
      this.setLoading(true)
      this.deleteDocument(id).then(() => {
        this.setSnackbarMessage({ text: this.$t('message.docSuccessfullyDeleted'), color: 'success' })
        this.browseDocuments()
      })
    },
    async deleteSelected() {
      this.setLoading(true)
      for (let doc of this.selected) {
        await this.deleteDocument(doc.id)
      }
      this.setSnackbarMessage({ text: this.$t('message.docsSuccessfullyDeleted'), color: 'success' })
      this.browseDocuments()
      this.selected = []
    },
    deleteDocument(id) {
      return documentsService.deleteDocument(id)
      .catch((e) => this.setSnackbarMessage({ text: `${this.$t('message.errorDeletingDoc')}`, color: 'error' }))
      .finally(() => this.setLoading(false))
    },
    // Display utils
    formatDate(date) {
      return dateFilter.formatDate(date)
    },
    getIcon(doc) {
      let type = doc.systemTags.TYPE
      switch (type) {
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          return 'mdi-file-word-box'
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          return 'mdi-file-excel-box'
        case 'application/pdf':
          return 'mdi-file-pdf-box'
        default:
          return 'mdi-text-box'
      }
    },
  },
  created() {
    this.browseDocuments()
  },
}
</script>

<style scoped>
.scrollable {
  height: 100%;
  overflow-y: auto;
}
.scrollable::v-deep .v-data-table__wrapper::-webkit-scrollbar {
  width: 5px;
}
.scrollable::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background-color: var(--v-primary-lighten1);
  border-radius: 20px;
}
.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.dashed {
  border: dashed 2px var(--v-primary-base);
}
</style>
