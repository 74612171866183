import { authHeader } from '@/helpers/auth-header'
import { commons } from './commons'

export const locodeRegistryService = {
    getMappedLocodes,
}

const resourceUrl = process.env.VUE_APP_GSCV_ENGINE_URL + `/locode-registry`

function getMappedLocodes(locode) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(resourceUrl + `/${locode}`, requestOptions).then(commons.handleStringResponse)
}