var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { "fill-height": "", fluid: "" } },
    [
      !_vm.showConversation
        ? _c(
            "v-card",
            {
              staticClass: "pa-3",
              attrs: { width: "100%" },
              on: {
                click: function ($event) {
                  _vm.showConversation = true
                },
              },
            },
            [
              _c(
                "v-row",
                { staticClass: "ma-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-2",
                          attrs: { color: _vm.assignColor() },
                        },
                        [_vm._v(" mdi-account-circle ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticStyle: { "margin-top": "0.2rem" } },
                    [
                      _c("v-row", [
                        _c("span", {
                          staticClass: "from",
                          domProps: {
                            textContent: _vm._s(_vm.lastElement.from),
                          },
                        }),
                      ]),
                      _c("v-row", [
                        _c("span", {
                          staticClass: "text-caption",
                          domProps: {
                            textContent: _vm._s(_vm.lastElement.subject),
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-expansion-panels",
            {
              attrs: { accordion: "" },
              model: {
                value: _vm.panel,
                callback: function ($$v) {
                  _vm.panel = $$v
                },
                expression: "panel",
              },
            },
            _vm._l(
              _vm.conversation[Object.keys(_vm.conversation)[0]],
              function (message, index) {
                return _c(
                  "v-expansion-panel",
                  { key: index },
                  [
                    _c(
                      "v-expansion-panel-header",
                      {
                        class: _vm.panel == index ? "pa-3 pb-0" : "pa-3",
                        attrs: { "hide-actions": "" },
                      },
                      [
                        _c(
                          "v-row",
                          { staticClass: "ma-0" },
                          [
                            _c(
                              "v-col",
                              { staticClass: "pa-0" },
                              [
                                _c("MailDetails", {
                                  attrs: {
                                    message: message,
                                    index: index,
                                    current: _vm.panel,
                                    contactsColors: _vm.contactsColors,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { staticClass: "pa-0", attrs: { cols: "auto" } },
                              [
                                index == 0
                                  ? _c(
                                      "v-icon",
                                      {
                                        attrs: { small: "" },
                                        on: {
                                          click: function ($event) {
                                            _vm.showConversation = false
                                          },
                                        },
                                      },
                                      [_vm._v(" mdi-chevron-down ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-expansion-panel-content",
                      [
                        _vm.showReplyMail
                          ? _c(
                              "v-row",
                              { staticClass: "ma-0" },
                              [
                                _c(
                                  "v-col",
                                  { staticClass: "pl-5 pr-0" },
                                  [
                                    _c(
                                      "MailNew",
                                      _vm._g(
                                        {
                                          ref: `replyMail_${index}`,
                                          refInFor: true,
                                          attrs: {
                                            msgTemplate: _vm.emailTemplate,
                                            isVisible: _vm.showReplyMail,
                                            isReply: _vm.showReplyMail,
                                            emailAddresses: [],
                                          },
                                        },
                                        _vm.$listeners
                                      )
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-row",
                          { staticClass: "ma-0" },
                          [
                            _c(
                              "v-col",
                              [
                                _c("MailBox", {
                                  attrs: { mail: message },
                                  on: {
                                    "download-attachment":
                                      _vm.downloadAttachment,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        !_vm.showReplyMail
                          ? _c(
                              "v-row",
                              { staticClass: "ma-0" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pa-0",
                                    attrs: { align: "right" },
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "pa-2",
                                        attrs: {
                                          "x-small": "",
                                          outlined: "",
                                          depressed: "",
                                          rounded: "",
                                          color: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.createNewDraft(message)
                                          },
                                        },
                                      },
                                      [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v("mdi-arrow-left-top"),
                                        ]),
                                        _c("span", [_vm._v("Reply")]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }
            ),
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }