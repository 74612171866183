<template>
  <v-snackbar v-model="show" timeout="2000" :color="color">
    <v-icon color="white" class="mr-2">
      {{ color == 'success' ? 'mdi-check-circle' : 'mdi-alert-circle' }}
    </v-icon>
    <span class="white--text">{{ text }}</span>
    <template v-slot:action="{ attrs }">
      <v-btn icon v-bind="attrs" @click="show = false">
        <v-icon color="white">
          mdi-close
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Snackbar',
  data() {
    return {
      show: false,
      text: '',
      color: 'info',
    }
  },
  created: function () {
    this.$store.watch(
      (state) => state.snackbar.text,
      () => {
        const msg = this.$store.state.snackbar.text
        if (msg !== '') {
          this.show = true
          this.text = this.$store.state.snackbar.text
          this.color = this.$store.state.snackbar.color
          this.$store.commit('snackbar/setSnackbarMessage', {
            text: '',
            color: 'info',
          })
        }
      },
    )
  },
}
</script>
