<template>
  <v-expand-transition>
    <v-card outlined class="rounded-0" v-show="open">
      <v-card-text>
        <v-card>
          <v-card-text class="black--text">
            <v-row>
              <v-col class="d-inline-flex">
                <v-col class="pa-0">
                  <ReadonlyField
                      :label="$t('label.awb')"
                      :value="pratica.awb"
                  />
                </v-col>
              </v-col>
              <v-col class="d-inline-flex">
                <v-col class="pa-0">
                  <ReadonlyField
                      :label="$t('label.airCompany')"
                      :value="pratica.supplierName"
                  />
                </v-col>
              </v-col>
              <v-col class="d-inline-flex">
                <v-col class="pa-0">
                  <ReadonlyField
                      :label="$t('label.loadingNode')"
                      :value="pratica.pol ? pratica.pol : ''"
                  />
                </v-col>
              </v-col>
              <v-col class="d-inline-flex">
                <v-col class="pa-0">
                  <ReadonlyField
                      :label="$t('label.dischargeNode')"
                      :value="pratica.pod ? pratica.pod : ''"
                  />
                </v-col>
              </v-col>
              <v-col class="d-inline-flex">
                <v-col class="pa-0">
                  <ReadonlyField
                      :label="$t('label.etd')"
                      :value="etdDate"
                  />
                </v-col>
              </v-col>
              <v-col class="d-inline-flex">
                <v-col class="pa-0">
                  <ReadonlyField
                      :label="$t('label.eta')"
                      :value="etaDate"
                  />
                </v-col>
              </v-col>
              <v-col class="d-inline-flex">
                <v-col class="pa-0">
                  <ReadonlyField
                      :label="$t('label.status')"
                      :value="trackResponse.status"
                  />
                </v-col>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="ma-0 mt-3">
          <v-card-title class="py-1 px-3">
                <span class="text-overline primary--text">
                  {{ $t('upper.airportEvents') }}
                </span>
          </v-card-title>
          <v-divider class="my-0 mx-2"/>
          <v-data-table
              :headers="airportEventsHeaders"
              :items="airportEvents"
              :items-per-page="1000"
              hide-default-footer
              class="px-2 py-0"
              dense
          >
            <template slot="no-data">
              <v-icon color="warning" class="mr-1" size="20">
                mdi-alert
              </v-icon>
              <span class="font-weight-medium warning--text">
                    {{ $t('label.noTrackingInfo') }}
                  </span>
            </template>
            <template v-slot:item.planned="{ item }">
              {{ formatDate(item.planned) }}
            </template>
            <template v-slot:item.actual="{ item }">
              {{ formatDate(item.actual) }}
            </template>
            <template v-slot:item.isReplanned="{ item }">
              <v-icon v-if="item.isReplanned" color="primary">
                mdi-check-bold
              </v-icon>
            </template>
          </v-data-table>
        </v-card>

        <v-card class="ma-0 mt-3">
          <v-card-title class="py-1 px-3">
                <span class="text-overline primary--text">
                  {{ $t('upper.routingEvents') }}
                </span>
          </v-card-title>
          <v-divider class="my-0 mx-2"/>
          <v-data-table
              :headers="trackingHeadersAereo"
              :items="routingEvents"
              :items-per-page="1000"
              hide-default-footer
              sort-by="departureActualTime"
              :sort-desc="false"
              class="px-2 py-0"
              dense
          >
            <template slot="no-data">
              <v-icon color="warning" class="mr-1" size="20">
                mdi-alert
              </v-icon>
              <span class="font-weight-medium warning--text">
                    {{ $t('label.noTrackingInfo') }}
                  </span>
            </template>
            <template v-slot:item.departureScheduleTime="{ item }">
              {{ formatDate(item.departureScheduleTime) }}
            </template>
            <template v-slot:item.departureActualTime="{ item }">
              {{ formatDate(item.departureActualTime) }}
            </template>
            <template v-slot:item.arrivalScheduleTime="{ item }">
              {{ formatDate(item.arrivalScheduleTime) }}
            </template>
            <template v-slot:item.arrivalActualTime="{ item }">
              {{ formatDate(item.arrivalActualTime) }}
            </template>
            <template v-slot:item.isReplanned="{ item }">
              <v-icon v-if="item.isReplanned" color="primary">
                mdi-check-bold
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
    </v-card>
  </v-expand-transition>
</template>

<script>
import Tracking from "@/mixins/Tracking"
import {praticheService} from '@/services/pratiche.service.js'
import {dateFilter} from '@/filters/dateFilter'
import ReadonlyField from '@/components/elements/ReadonlyField.vue'

export default {
  name: 'PraticaAereoExtension',
  props: ['pratica', 'open'],
  mixins: [Tracking],
  components: {ReadonlyField},
  data() {
    return {
      trackResponse: {},
      airportEvents: [],
      routingEvents: [],
    }
  },
  watch: {
    open(isOpen) {
      if (!isOpen) return
      this.trackPratica()
    },
  },
  computed: {
    etdDate() {
      return dateFilter.formatDate(this.trackResponse.etdPlanned)
    },
    etaDate() {
      return dateFilter.formatDate(this.trackResponse.etaPlanned)
    },
  },
  methods: {
    trackPratica() {
      this.airportEvents = [];
      this.routingEvents = [];
      praticheService.trackPraticaAereo(this.pratica.id).then((resp) => {
        if (!resp) return
        this.trackResponse = resp
        this.routingEvents = resp.routing
        let navettamento = resp.lastRoadFeederServiceEvent
        if (navettamento) navettamento.phase = 'ORIGIN'
        if (resp.lastOriginEvent) {
          resp.lastOriginEvent.travelPhaseEnum = 'ORIGIN'
          this.airportEvents.push(resp.lastOriginEvent)
        }
        if (resp.lastDestinationEvent) {
          resp.lastDestinationEvent.travelPhaseEnum = 'DESTINATION'
          this.airportEvents.push(resp.lastDestinationEvent)
        }
      })
    }
  },
}

</script>