var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expand-transition",
    [
      _c(
        "v-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.open,
              expression: "open",
            },
          ],
          staticClass: "rounded-0",
          attrs: { outlined: "" },
        },
        [
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _c(
                "v-simple-table",
                { staticClass: "pa-3", attrs: { dense: "" } },
                [
                  [
                    _c("thead", { staticClass: "t-head" }, [
                      _c("tr", [
                        _c("th", { staticClass: "text-left" }, [
                          _vm._v(_vm._s(_vm.$t("label.order"))),
                        ]),
                        _c("th", { staticClass: "text-left" }, [
                          _vm._v(_vm._s(_vm.$t("label.position"))),
                        ]),
                        _c("th", { staticClass: "text-left" }, [
                          _vm._v(_vm._s(_vm.$t("label.packaging"))),
                        ]),
                        _c("th", { staticClass: "text-left" }, [
                          _vm._v(_vm._s(_vm.$t("label.quantity"))),
                        ]),
                        _c("th", { staticClass: "text-left" }, [
                          _vm._v(_vm._s(_vm.$t("label.grossWeight"))),
                        ]),
                        _c("th", { staticClass: "text-left" }, [
                          _vm._v(_vm._s(_vm.$t("label.netWeight"))),
                        ]),
                        _c("th", { staticClass: "text-left" }, [
                          _vm._v(_vm._s(_vm.$t("label.length"))),
                        ]),
                        _c("th", { staticClass: "text-left" }, [
                          _vm._v(_vm._s(_vm.$t("label.width"))),
                        ]),
                        _c("th", { staticClass: "text-left" }, [
                          _vm._v(_vm._s(_vm.$t("label.height"))),
                        ]),
                        _c("th", { staticClass: "text-left" }, [
                          _vm._v(_vm._s(_vm.$t("label.volume"))),
                        ]),
                        _c("th", { staticClass: "text-left" }, [
                          _vm._v(_vm._s(_vm.$t("label.billReference"))),
                        ]),
                        _c("th", { staticClass: "text-left" }, [
                          _vm._v(_vm._s(_vm.$t("label.booking"))),
                        ]),
                        _c("th", { staticClass: "text-left" }, [
                          _vm._v(_vm._s(_vm.$t("label.customerType"))),
                        ]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.imballi, function (imballo, i) {
                        return _c("tr", { key: i }, [
                          _c("td", [_vm._v(_vm._s(imballo.saleOrder))]),
                          _c("td", [_vm._v(_vm._s(imballo.saleOrderLine))]),
                          _c("td", [_vm._v(_vm._s(imballo.itemDescription))]),
                          _c("td", [_vm._v(_vm._s(imballo.quantity))]),
                          _c("td", [
                            _vm._v("kg " + _vm._s(imballo.grossWeight)),
                          ]),
                          _c("td", [_vm._v("kg " + _vm._s(imballo.netWeight))]),
                          _c("td", [_vm._v("cm " + _vm._s(imballo.length))]),
                          _c("td", [_vm._v("cm " + _vm._s(imballo.width))]),
                          _c("td", [_vm._v(_vm._s(imballo.height))]),
                          _c("td", [
                            _vm._v(" m "),
                            _c("sup", [_vm._v("3")]),
                            _vm._v(" " + _vm._s(imballo.volume) + " "),
                          ]),
                          _c("td", [_vm._v(_vm._s(imballo.invoice))]),
                          _c("td", [_vm._v(_vm._s(imballo.bookingNbr))]),
                          _c(
                            "td",
                            [
                              imballo.carrierMerchantFlag == "M"
                                ? _c("v-icon", [_vm._v(" mdi-alpha-m-box ")])
                                : _vm._e(),
                              imballo.carrierMerchantFlag == "C"
                                ? _c("v-icon", [_vm._v(" mdi-alpha-c-box ")])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                  ],
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }