var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      attrs: { timeout: "2000", color: _vm.color },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function ({ attrs }) {
            return [
              _c(
                "v-btn",
                _vm._b(
                  {
                    attrs: { icon: "" },
                    on: {
                      click: function ($event) {
                        _vm.show = false
                      },
                    },
                  },
                  "v-btn",
                  attrs,
                  false
                ),
                [
                  _c("v-icon", { attrs: { color: "white" } }, [
                    _vm._v(" mdi-close "),
                  ]),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c("v-icon", { staticClass: "mr-2", attrs: { color: "white" } }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.color == "success" ? "mdi-check-circle" : "mdi-alert-circle"
            ) +
            " "
        ),
      ]),
      _c("span", { staticClass: "white--text" }, [_vm._v(_vm._s(_vm.text))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }