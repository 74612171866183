var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "stepper-wrapper", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "ma-0 fill-height" },
        [
          _c(
            "v-col",
            { staticClass: "d-table fill-height pa-1" },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  staticClass: "d-block pa-1 overflow-y-auto fill-height",
                },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-card", [
                            _c(
                              "div",
                              { staticClass: "pa-2 pt-3" },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { dense: "" } },
                                  [
                                    _c(
                                      "v-col",
                                      { staticClass: "d-flex" },
                                      [
                                        _c(
                                          "v-radio-group",
                                          {
                                            staticClass:
                                              "radio-group ma-0 pt-0",
                                            attrs: {
                                              "hide-details": "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.documentType,
                                              callback: function ($$v) {
                                                _vm.documentType = $$v
                                              },
                                              expression: "documentType",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-radio",
                                              { attrs: { value: "BL" } },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "label" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-caption black--text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Bill Of Lading "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              2
                                            ),
                                            _c(
                                              "v-radio",
                                              { attrs: { value: "SW" } },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "label" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-caption black--text",
                                                      },
                                                      [_vm._v(" Sea Waybill ")]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  { attrs: { dense: "" } },
                                  [
                                    _c(
                                      "v-col",
                                      { staticClass: "pt-2 pb-0" },
                                      [
                                        _c("v-autocomplete", {
                                          staticClass: "xs-input",
                                          attrs: {
                                            label: _vm.$t("label.booking"),
                                            items: _vm.bookings,
                                            "item-text": "carrierReference",
                                            "menu-props": _vm.menuProps,
                                            rules: _vm.required,
                                            "return-object": "",
                                            outlined: "",
                                            dense: "",
                                          },
                                          on: {
                                            change: (booking) => {
                                              _vm.selectBookingRequest(booking)
                                              _vm.updateFields()
                                            },
                                          },
                                          model: {
                                            value: _vm.selectedBookings,
                                            callback: function ($$v) {
                                              _vm.selectedBookings = $$v
                                            },
                                            expression: "selectedBookings",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  { attrs: { dense: "" } },
                                  [
                                    _c(
                                      "v-col",
                                      { staticClass: "pt-1 pb-0" },
                                      [
                                        _c("v-text-field", {
                                          staticClass: "xs-input",
                                          attrs: {
                                            label: _vm.$t("label.placeOfIssue"),
                                            value: _vm.placeOfIssue
                                              ? _vm.placeOfIssue.cityName
                                              : null,
                                            rules: _vm.required,
                                            outlined: "",
                                            dense: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { staticClass: "pt-1 pb-0" },
                                      [
                                        _c("v-text-field", {
                                          staticClass: "xs-input",
                                          attrs: {
                                            value: _vm.contract,
                                            label: _vm.$t("label.contract"),
                                            rules: _vm.required,
                                            outlined: "",
                                            dense: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-card", [
                                    _c(
                                      "div",
                                      { staticClass: "pa-2 px-5" },
                                      [
                                        _c(
                                          "v-row",
                                          { attrs: { dense: "" } },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "d-flex align-center",
                                                attrs: { cols: "4" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-caption",
                                                    staticStyle: {
                                                      "min-width":
                                                        "max-content",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Number of originals "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "auto" } },
                                              [
                                                _c(
                                                  "v-row",
                                                  { attrs: { dense: "" } },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "d-flex align-center",
                                                        attrs: { cols: "auto" },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-caption",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Unfreighted"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: { cols: "auto" },
                                                      },
                                                      [
                                                        _c("v-text-field", {
                                                          staticClass:
                                                            "number-select xs-input",
                                                          attrs: {
                                                            rules: _vm.required,
                                                            type: "number",
                                                            "hide-spin-buttons":
                                                              "",
                                                            "hide-details": "",
                                                            outlined: "",
                                                            dense: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .numberOfOriginals
                                                                .unfreighted,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.numberOfOriginals,
                                                                "unfreighted",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "numberOfOriginals.unfreighted",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "d-flex align-center",
                                                        attrs: { cols: "auto" },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-caption",
                                                          },
                                                          [_vm._v("Freighted")]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: { cols: "auto" },
                                                      },
                                                      [
                                                        _c("v-text-field", {
                                                          staticClass:
                                                            "number-select xs-input",
                                                          attrs: {
                                                            rules: _vm.required,
                                                            type: "number",
                                                            "hide-spin-buttons":
                                                              "",
                                                            "hide-details": "",
                                                            outlined: "",
                                                            dense: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .numberOfOriginals
                                                                .freighted,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.numberOfOriginals,
                                                                "freighted",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "numberOfOriginals.freighted",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { attrs: { dense: "" } },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "d-flex align-center",
                                                attrs: { cols: "4" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-caption",
                                                  },
                                                  [_vm._v("Number of copies")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "auto" } },
                                              [
                                                _c(
                                                  "v-row",
                                                  { attrs: { dense: "" } },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "d-flex align-center",
                                                        attrs: { cols: "auto" },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-caption",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Unfreighted"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: { cols: "auto" },
                                                      },
                                                      [
                                                        _c("v-text-field", {
                                                          staticClass:
                                                            "number-select xs-input",
                                                          attrs: {
                                                            rules: _vm.required,
                                                            type: "number",
                                                            "hide-spin-buttons":
                                                              "",
                                                            "hide-details": "",
                                                            outlined: "",
                                                            dense: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.numberOfCopies
                                                                .unfreighted,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.numberOfCopies,
                                                                "unfreighted",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "numberOfCopies.unfreighted",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "d-flex align-center",
                                                        attrs: { cols: "auto" },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-caption",
                                                          },
                                                          [_vm._v("Freighted")]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: { cols: "auto" },
                                                      },
                                                      [
                                                        _c("v-text-field", {
                                                          staticClass:
                                                            "number-select xs-input",
                                                          attrs: {
                                                            rules: _vm.required,
                                                            type: "number",
                                                            "hide-spin-buttons":
                                                              "",
                                                            "hide-details": "",
                                                            outlined: "",
                                                            dense: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.numberOfCopies
                                                                .freighted,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.numberOfCopies,
                                                                "freighted",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "numberOfCopies.freighted",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pa-1" },
                                [
                                  _c("v-card", [
                                    _c(
                                      "div",
                                      { staticClass: "pa-5" },
                                      [
                                        _c(
                                          "v-row",
                                          { attrs: { dense: "" } },
                                          [
                                            _c(
                                              "v-col",
                                              [
                                                _c("v-switch", {
                                                  staticClass:
                                                    "shipped-switch ma-0 pa-0",
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "label.isShippedOnBoard"
                                                    ),
                                                    "hide-details": "",
                                                    inset: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.clauses
                                                        .isShippedOnBoard,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.clauses,
                                                        "isShippedOnBoard",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "clauses.isShippedOnBoard",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "flex-grow-0 ma-0" },
        [
          _c(
            "v-col",
            { staticClass: "d-flex pa-2" },
            [
              _c(
                "v-btn",
                {
                  attrs: { small: "", icon: "", color: "primary" },
                  on: { click: _vm.exitRequest },
                },
                [
                  _c("v-icon", { attrs: { size: "30" } }, [
                    _vm._v("mdi-arrow-left-circle"),
                  ]),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { small: "", color: "primary", disabled: _vm.loading },
                  on: { click: _vm.submitDocumentProps },
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.$t("label.next")))]),
                  _c("v-icon", [_vm._v("mdi-play")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }