var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    {
      staticStyle: { "border-bottom": "5px solid #64a70b !important" },
      attrs: {
        color: _vm.isProduction ? "primary" : "rossoSacmi",
        app: "",
        "clipped-left": "",
      },
    },
    [
      _c("v-app-bar-nav-icon", {
        staticClass: "white--text",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.toggleDrawer.apply(null, arguments)
          },
        },
      }),
      _c(
        "v-toolbar-title",
        { staticClass: "text-caption pa-0" },
        [
          !_vm.isDemo && _vm.isProduction
            ? _c("v-img", {
                attrs: {
                  src: require("@/assets/logo-italiansped.png"),
                  "max-width": "150",
                  contain: "",
                },
              })
            : _vm._e(),
          !_vm.isDemo && !_vm.isProduction
            ? _c("v-img", {
                attrs: {
                  src: require("@/assets/italiansped-logo.png"),
                  "max-width": "150",
                  contain: "",
                },
              })
            : _vm._e(),
          _vm.isDemo
            ? _c("v-img", {
                attrs: {
                  src: require("@/assets/logo-cirle-white.png"),
                  "max-width": "100",
                  contain: "",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("v-spacer"),
      _vm.currentRouteName != "settings" &&
      _vm.currentRouteName != "newUserLanding"
        ? _c("v-select", {
            ref: "searchType",
            staticClass: "fast-search-type mt-6 mx-5 white--text",
            attrs: {
              label: _vm.$t("label.searchType"),
              items: _vm.searchTypeItems,
              "item-text": "text",
              "item-value": "value",
              "menu-props": _vm.menuProps,
              clearable: "",
              dense: "",
              dark: "",
            },
            on: {
              change: (field) => _vm.updateSearchField(field),
              "click:clear": _vm.blurSearchField,
            },
            model: {
              value: _vm.searchType,
              callback: function ($$v) {
                _vm.searchType = $$v
              },
              expression: "searchType",
            },
          })
        : _vm._e(),
      _vm.currentRouteName != "settings" &&
      _vm.currentRouteName != "newUserLanding"
        ? _c("v-text-field", {
            ref: "search",
            staticClass: "fast-search mt-6 white--text",
            attrs: {
              label: _vm.$t("label.search"),
              color: "white",
              clearable: "",
              dense: "",
              dark: "",
            },
            on: {
              input: (search) =>
                _vm.updateSearch(
                  search != null ? search.toUpperCase() : search
                ),
              "click:clear": _vm.blurSearch,
            },
          })
        : _vm._e(),
      _c("v-spacer"),
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "mx-5",
                          attrs: { icon: "", small: "", dark: "" },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-help-circle-outline")])],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("span", { staticClass: "text-caption" }, [
            _vm._v(_vm._s(_vm.$t("label.contactSupport"))),
          ]),
        ]
      ),
      _c("v-divider", { attrs: { vertical: "" } }),
      _c("v-divider", { attrs: { vertical: "" } }),
      _vm.isAdmin
        ? _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "mx-5",
                                attrs: { icon: "", small: "", dark: "" },
                                on: { click: _vm.navigateToSettings },
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [_c("v-icon", [_vm._v("mdi-cog")])],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                892912508
              ),
            },
            [
              _c("span", { staticClass: "text-caption" }, [
                _vm._v(_vm._s(_vm.$t("label.settings"))),
              ]),
            ]
          )
        : _vm._e(),
      _vm.isAdmin ? _c("v-divider", { attrs: { vertical: "" } }) : _vm._e(),
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "ml-5",
                        attrs: { icon: "", small: "", dark: "" },
                        on: {
                          click: function ($event) {
                            _vm.dialog = !_vm.dialog
                          },
                        },
                      },
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-account")])],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("span", { staticClass: "text-caption" }, [
            _vm._v(_vm._s(_vm.$t("label.accountAndSettings"))),
          ]),
        ]
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "300" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            { staticStyle: { "text-align": "center" } },
            [
              _c(
                "v-card-text",
                { staticStyle: { padding: "5px" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "font-weight-bold title",
                      staticStyle: { margin: "3px" },
                    },
                    [_vm._v(" " + _vm._s(_vm.userFullname) + " ")]
                  ),
                  _c("div", { staticStyle: { margin: "2px" } }, [
                    _vm._v(_vm._s(_vm.userUsername)),
                  ]),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            staticClass: "mt-6 mx-2 white--text",
                            attrs: {
                              value: _vm.userParameters.language,
                              items: _vm.selectLanguageItems,
                              label: _vm.$t("label.language"),
                              "item-text": "text",
                              "item-value": "value",
                              "return-object": "",
                              "prepend-icon": "mdi-translate",
                              dense: "",
                              solo: "",
                            },
                            on: { change: (v) => _vm.changeLanguage(v) },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("hr"),
                  _c(
                    "v-btn",
                    {
                      staticStyle: { margin: "3px" },
                      attrs: { color: "primary" },
                      on: { click: _vm.logout },
                    },
                    [_c("v-icon", [_vm._v("mdi-logout")]), _vm._v(" Logout ")],
                    1
                  ),
                  _c("hr"),
                  _c("div", [_vm._v(_vm._s(_vm.appVersion))]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }