var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "stepper-wrapper", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-container",
                {
                  staticClass: "d-table pa-1 pr-2 pt-2",
                  attrs: { "fill-height": "", fluid: "" },
                },
                [
                  _c(
                    "v-expansion-panels",
                    {
                      staticClass: "d-block fill-height overflow-y-auto pa-1",
                      staticStyle: { "padding-top": "0.1rem !important" },
                      attrs: { multiple: "" },
                      model: {
                        value: _vm.panel,
                        callback: function ($$v) {
                          _vm.panel = $$v
                        },
                        expression: "panel",
                      },
                    },
                    [
                      _c(
                        "v-expansion-panel",
                        { staticClass: "fit-content" },
                        [
                          _c(
                            "v-expansion-panel-header",
                            { staticClass: "primary--text px-3 py-0" },
                            [_vm._v(" Document ")]
                          ),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c("v-divider", { staticClass: "my-0" }),
                              _c(
                                "div",
                                { staticClass: "pa-2" },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "d-flex" },
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "pa-0" },
                                            [
                                              _c("ReadonlyField", {
                                                attrs: {
                                                  label: _vm.$t("label.type"),
                                                  value:
                                                    _vm.savedTransportDocumentType,
                                                  icon: "mdi-file-document-outline",
                                                  textClass: "text-caption",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { staticClass: "pa-0" },
                                            [
                                              _c("ReadonlyField", {
                                                attrs: {
                                                  label:
                                                    _vm.$t(
                                                      "label.placeOfIssue"
                                                    ),
                                                  value:
                                                    _vm.savedInvoicePayableAt
                                                      .locationName,
                                                  icon: "mdi-map-marker",
                                                  textClass: "text-caption",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { staticClass: "pa-0" },
                                            [
                                              _c("ReadonlyField", {
                                                attrs: {
                                                  label:
                                                    _vm.$t("label.booking"),
                                                  value:
                                                    _vm.savedCarrierBookingReference,
                                                  icon: "mdi-file-document-outline",
                                                  textClass: "text-caption",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { staticClass: "pa-0" },
                                            [
                                              _c("ReadonlyField", {
                                                attrs: {
                                                  label:
                                                    _vm.$t("label.contract"),
                                                  value:
                                                    _vm.savedAgreementReference,
                                                  icon: "mdi-file-document-outline",
                                                  textClass: "text-caption",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("v-divider", {
                                            attrs: { vertical: "" },
                                          }),
                                          _c(
                                            "v-col",
                                            { staticClass: "py-0" },
                                            [
                                              _c(
                                                "v-row",
                                                { attrs: { dense: "" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticStyle: {
                                                        "max-width": "5rem",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-caption primary--text",
                                                        },
                                                        [_vm._v(" Original ")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "auto" } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-caption primary--text",
                                                        },
                                                        [_vm._v(" Freighted ")]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-caption mx-2",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm
                                                                  .savedNumberOfOriginals
                                                                  .freighted
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "auto" } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-caption primary--text",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Unfreighted "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-caption mx-2",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm
                                                                  .savedNumberOfOriginals
                                                                  .unfreighted
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                { attrs: { dense: "" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticStyle: {
                                                        "max-width": "5rem",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-caption primary--text",
                                                        },
                                                        [_vm._v(" Copies ")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "auto" } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-caption primary--text",
                                                        },
                                                        [_vm._v(" Freighted ")]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-caption mx-2",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm
                                                                  .savedNumberOfCopies
                                                                  .freighted
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "auto" } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-caption primary--text",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Unfreighted "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-caption mx-2",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm
                                                                  .savedNumberOfCopies
                                                                  .unfreighted
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        { staticClass: "fit-content" },
                        [
                          _c(
                            "v-expansion-panel-header",
                            { staticClass: "primary--text px-3 py-0" },
                            [_vm._v(" Voyage ")]
                          ),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c("v-divider", { staticClass: "my-0" }),
                              _c(
                                "div",
                                { staticClass: "pa-2" },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "d-flex" },
                                        [
                                          _vm.bookingReqPolHaulageLocation
                                            ? _c(
                                                "v-col",
                                                { staticClass: "pa-0" },
                                                [
                                                  _c("ReadonlyField", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "label.placeOfReceipt"
                                                      ),
                                                      value: _vm.formatAddress(
                                                        _vm.bookingReqPolHaulageLocation
                                                      ),
                                                      icon: "mdi-truck",
                                                      textClass: "text-caption",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-col",
                                            { staticClass: "pa-0" },
                                            [
                                              _c("ReadonlyField", {
                                                attrs: {
                                                  label: _vm.$t("label.pol"),
                                                  value:
                                                    _vm.getShortLocationLabel(
                                                      _vm.bookingReqPol
                                                    ),
                                                  icon: "mdi-map-marker",
                                                  textClass: "text-caption",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { staticClass: "d-flex" },
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "pa-0" },
                                            [
                                              _c("ReadonlyField", {
                                                attrs: {
                                                  label: _vm.$t("label.pod"),
                                                  value:
                                                    _vm.getShortLocationLabel(
                                                      _vm.bookingReqPod
                                                    ),
                                                  icon: "mdi-map-marker",
                                                  textClass: "text-caption",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.bookingReqPodHaulageLocation
                                            ? _c(
                                                "v-col",
                                                { staticClass: "pa-0" },
                                                [
                                                  _c("ReadonlyField", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "label.placeOfDelivery"
                                                      ),
                                                      value: _vm.formatAddress(
                                                        _vm.bookingReqPodHaulageLocation
                                                      ),
                                                      icon: "mdi-truck",
                                                      textClass: "text-caption",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "d-flex" },
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "pa-0" },
                                            [
                                              _c("ReadonlyField", {
                                                attrs: {
                                                  label:
                                                    _vm.$t("label.departure"),
                                                  value: _vm.formatDate(
                                                    _vm.departure
                                                  ),
                                                  icon: "mdi-calendar",
                                                  textClass: "text-caption",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { staticClass: "pa-0" },
                                            [
                                              _c("ReadonlyField", {
                                                attrs: {
                                                  label:
                                                    _vm.$t("label.arrival"),
                                                  value: _vm.formatDate(
                                                    _vm.arrival
                                                  ),
                                                  icon: "mdi-calendar",
                                                  textClass: "text-caption",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { staticClass: "d-flex" },
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "pa-0" },
                                            [
                                              _c("ReadonlyField", {
                                                attrs: {
                                                  label: _vm.$t("label.vessel"),
                                                  value:
                                                    _vm.bookingReqMainVessel,
                                                  icon: "mdi-ferry",
                                                  textClass: "text-caption",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { staticClass: "pa-0" },
                                            [
                                              _c("ReadonlyField", {
                                                attrs: {
                                                  label: _vm.$t("label.voyage"),
                                                  value:
                                                    _vm.bookingReqVoyageNbr,
                                                  icon: "mdi-ticket-outline",
                                                  textClass: "text-caption",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        { staticClass: "fit-content" },
                        [
                          _c(
                            "v-expansion-panel-header",
                            { staticClass: "primary--text px-3 py-0" },
                            [_vm._v(" Cargo ")]
                          ),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c("v-divider", { staticClass: "my-0" }),
                              _c(
                                "div",
                                { staticClass: "pa-2" },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "px-2 py-0" },
                                        [
                                          _c("v-data-table", {
                                            staticClass: "pa-0",
                                            attrs: {
                                              headers: _vm.equipmentsHeaders,
                                              items:
                                                _vm.savedShippingInstructionEquipments,
                                              "items-per-page": 1000,
                                              "item-class": "text-caption",
                                              "hide-default-footer": "",
                                              dense: "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "item",
                                                fn: function ({ item, index }) {
                                                  return [
                                                    _c("tr", [
                                                      _c(
                                                        "td",
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mr-1",
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " mdi-train-car-container "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-caption",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.containerCode
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c("td", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-caption",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.containerIsoType
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-caption",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.shipperSeal
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-caption",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.carrierSeal
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-caption",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.packagesNbr
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-caption",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.packagesType
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-caption",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.commodityDesc
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-caption",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.shipperOwned
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-caption",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.weight
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-caption",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.volume
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                    ]),
                                                    index ==
                                                    _vm
                                                      .savedShippingInstructionEquipments
                                                      .length -
                                                      1
                                                      ? _c("tr", [
                                                          _c("td", [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-caption font-weight-medium",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "label.total"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]),
                                                          _c("td"),
                                                          _c("td"),
                                                          _c("td", [
                                                            _c("span", {
                                                              staticClass:
                                                                "text-caption font-weight-medium",
                                                            }),
                                                          ]),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        { staticClass: "fit-content" },
                        [
                          _c(
                            "v-expansion-panel-header",
                            { staticClass: "primary--text px-3 py-0" },
                            [_vm._v(" Parties ")]
                          ),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c("v-divider", { staticClass: "my-0" }),
                              _c(
                                "div",
                                { staticClass: "pa-2" },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    _vm._l(_vm.parties, function (item, i) {
                                      return _c(
                                        "v-col",
                                        {
                                          key: i,
                                          staticClass: "pa-1",
                                          attrs: { cols: "3" },
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            [
                                              _c(
                                                "v-card-text",
                                                {
                                                  staticClass:
                                                    "pa-0 text-caption font-weight-medium",
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: { dense: "" },
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "primary--text pa-0",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.getPartyFunction(
                                                                      item.partyFunction
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: { dense: "" },
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "black--text pa-0",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.party
                                                                      .partyName
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: { dense: "" },
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "pa-0",
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item
                                                                          .party
                                                                          .street
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.party
                                                                      .streetNumber
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: { dense: "" },
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "pa-0",
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.party
                                                                        .city
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.party
                                                                      .country
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      item.contactDetails.email
                                                        ? _c(
                                                            "v-row",
                                                            {
                                                              attrs: {
                                                                dense: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "pa-0",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-divider",
                                                                    {
                                                                      staticClass:
                                                                        "mx-0 my-1",
                                                                    }
                                                                  ),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item
                                                                          .contactDetails
                                                                          .name
                                                                      )
                                                                    ),
                                                                  ]),
                                                                  _c("br"),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item
                                                                          .contactDetails
                                                                          .email
                                                                      )
                                                                    ),
                                                                  ]),
                                                                  _c("br"),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item
                                                                          .contactDetails
                                                                          .phone
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.payers
                        ? _c(
                            "v-expansion-panel",
                            { staticClass: "fit-content" },
                            [
                              _c(
                                "v-expansion-panel-header",
                                { staticClass: "primary--text px-3 py-0" },
                                [_vm._v(" Payers ")]
                              ),
                              _c(
                                "v-expansion-panel-content",
                                [
                                  _c("v-divider", { staticClass: "my-0" }),
                                  _c(
                                    "div",
                                    { staticClass: "pa-2" },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [_c("v-col")],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "flex-grow-0", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "d-flex pa-3" },
            [
              _c(
                "v-btn",
                {
                  attrs: { small: "", icon: "", color: "primary" },
                  on: { click: _vm.exitRequest },
                },
                [
                  _c("v-icon", { attrs: { size: "30" } }, [
                    _vm._v("mdi-arrow-left-circle"),
                  ]),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", small: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("step", false)
                    },
                  },
                },
                [
                  _c("v-icon", [_vm._v("mdi-step-backward")]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("label.back")))]),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-2",
                  attrs: { small: "", color: "primary" },
                  on: { click: _vm.submitShippingInstruction },
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t("label.confirm")))])]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }