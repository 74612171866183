<template>
  <v-container class="stepper-box flex-column align-center">
    <v-row class="ma-0" style="max-width: fit-content;">
      <v-col>
        <v-avatar size="40">
          <v-icon size="20" color="primary" rounded>mdi-factory</v-icon>
        </v-avatar>
      </v-col>
      <v-col class="divider-col" v-if="!$vuetify.breakpoint.smAndDown">
        <v-divider />
      </v-col>
      <v-col>
        <v-avatar size="40" clas>
          <v-icon
            v-if="saleChannelDesc(pratica.saleChannel) == 'Nave'"
            size="20"
            color="primary"
            rounded
          >
            mdi-ferry
          </v-icon>
          <v-icon
            v-if="saleChannelDesc(pratica.saleChannel) == 'Aereo'"
            size="20"
            color="primary"
            rounded
          >
            mdi-airplane
          </v-icon>
          <v-icon
              v-if="saleChannelDesc(pratica.saleChannel) == 'Terra'"
              size="20"
              color="primary"
              rounded
          >
            mdi-truck
          </v-icon>
        </v-avatar>
      </v-col>
      <v-col class="divider-col" v-if="!$vuetify.breakpoint.smAndDown">
        <v-divider />
      </v-col>
      <v-col>
        <v-avatar size="40" clas>
          <v-icon
            v-if="saleChannelDesc(pratica.saleChannel) == 'Nave'"
            size="20"
            color="primary"
            rounded
          >
            mdi-ferry
          </v-icon>
          <v-icon
            v-if="saleChannelDesc(pratica.saleChannel) == 'Aereo'"
            size="20"
            color="primary"
            rounded
          >
            mdi-airplane
          </v-icon>
          <v-icon
              v-if="saleChannelDesc(pratica.saleChannel) == 'Terra'"
              size="20"
              color="primary"
              rounded
          >
            mdi-truck
          </v-icon>
        </v-avatar>
      </v-col>

      <v-col class="divider-col" v-if="!$vuetify.breakpoint.smAndDown">
        <v-divider />
      </v-col>
      <v-col>
        <v-avatar size="40" clas>
          <v-icon size="20" color="primary" rounded>mdi-warehouse</v-icon>
        </v-avatar>
      </v-col>
    </v-row>

    <v-row class="ma-0" style="max-width: fit-content;">
      <v-col>
        <div class="address-box">
          <span class="ellipsed-span">
            {{ steps.pickupLocation }}
          </span>
        </div>
      </v-col>
      <v-col class="divider-col" v-if="!$vuetify.breakpoint.smAndDown" />
      <v-col>
        <div class="address-box">
          <span class="ellipsed-span">
            {{ steps.departureLocation }}
          </span>
        </div>
      </v-col>
      <!-- <v-col class="divider-col" v-if="!$vuetify.breakpoint.smAndDown" /> -->
      <v-col class="vessel-col">
        <div class="address-box">
          <span class="ellipsed-span"> {{ pratica.vesselName }} </span>
        </div>
      </v-col>
      <v-col>
        <div class="address-box">
          <span class="ellipsed-span">
            {{ steps.arrivalLocation }}
          </span>
        </div>
      </v-col>
      <v-col class="divider-col" v-if="!$vuetify.breakpoint.smAndDown" />
      <v-col>
        <div class="address-box">
          <span class="ellipsed-span">
            {{ steps.destination }}
          </span>
        </div>
      </v-col>
    </v-row>

    <v-row class="ma-0" style="max-width: fit-content;" v-if="haveTracking">
      <v-col>
        <div class="address-box"/>
      </v-col>
      <v-col class="divider-col" v-if="!$vuetify.breakpoint.smAndDown" />
      <v-col>
        <div class="address-box datetime-box">
          <span>
            {{ trackingAtd ? trackingAtd : trackingEtd }}
          </span>
        </div>
      </v-col>
      <v-col class="divider-col" v-if="!$vuetify.breakpoint.smAndDown" />
      <v-col>
        <div class="address-box datetime-box">
          <span>
            {{ trackingAta ? trackingAta : trackingEta }}
          </span>
        </div>
      </v-col>
      <v-col class="divider-col" v-if="!$vuetify.breakpoint.smAndDown" />
      <v-col>
        <div class="address-box" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { dateFilter } from '@/filters/dateFilter'
import { praticheService } from '@/services/pratiche.service.js'

export default {
  name: 'PraticaStepper',
  props: ['pratica'],
  data() {
    return {
      trackingEtd: null,
      trackingAtd: null,
      trackingEta: null,
      trackingAta: null,
    }
  },
  computed: {
    steps() {
      return {
        pickupLocation: this.pratica.shipperName,
        departureLocation: this.pratica.polLabel,
        arrivalLocation: this.pratica.podLabel,
        destination: this.pratica.consigneeName,
      }
    },
    haveTracking(){
      return this.trackingEtd != null || this.trackingAtd != null || this.trackingEta != null || this.trackingAta != null
    }
  },
  methods: {
    saleChannelDesc(saleChannel) {
      let description
      switch (saleChannel) {
        case '20':
          description = 'Nave'
          break
        case '30':
          description = 'Terra'
          break
        case '40':
          description = 'Aereo'
          break
      }
      return description
    },
    trackPratica(booking) {
      let searchField
      if (this.pratica.mainTransportCompany == '0002102155' || this.pratica.mainTransportCompany == '0002102556')
        searchField = this.pratica.billOfLading
      else {
        searchField = booking
        if (!this.checkPraticaCompany()) return // || !this.checkBooking(booking))
      }
      return praticheService.trackPraticaMareLite(searchField, this.pratica.mainTransportCompany, this.pratica.documentNbr)
    },
    async getTracking() {
      if (this.pratica.saleChannel == '40') return
      let trackingResponse = []
      for (let booking of this.pratica.bookings) {
        let response = await this.trackPratica(booking)
        if (response) trackingResponse.push(response)
      }
      this.extractDates(trackingResponse)
    },
    extractDates(trackingResponse) {
      let etd = [],
        atd = [],
        eta = [],
        ata = []
      trackingResponse.forEach((resp) => {
        if (resp.etd) etd.push(resp.etd)
        if (resp.eta) eta.push(resp.eta)
        if (resp.atd) atd.push(resp.atd)
        if (resp.ata) ata.push(resp.ata)
      })
      if (etd.length)
        this.trackingEtd = dateFilter.formatDate(this.sortDatesDesc(etd)[0]) + " (Estimated)"
      if (eta.length)
        this.trackingEta = dateFilter.formatDate(this.sortDatesDesc(eta)[0]) + " (Estimated)"
      if (atd.length)
        this.trackingAtd = dateFilter.formatDate(this.sortDatesDesc(atd)[0]) + " (Actual)"
      if (ata.length)
        this.trackingAta = dateFilter.formatDate(this.sortDatesDesc(ata)[0]) + " (Actual)"
    },
    sortDatesDesc(list) {
      return list.sort((a, b) => new Date(b) - new Date(a))
    },
    checkPraticaCompany() {
      return this.pratica.mainTransportCompany && this.pratica.mainTransportCompany.length
    },
    checkBooking(booking) {
      return booking && booking.length > 1 && booking != 'N/A'
    },
  },
  created(){
    this.getTracking()
  }
}
</script>

<style scoped>
.stepper-box {
  display: flex;
  padding: 0;
  max-height: 10rem;
}
.col {
  width: 10rem;
  max-width: 10rem;
  padding: 0;
  display: flex;
  justify-content: center;
  font-size: 0.7rem;
}
.divider-col {
  max-width: 6rem !important;
  display: inline-flex;
  align-items: center;
}
.vessel-col {
  max-width: 6rem !important;
  display: inline-flex;
  align-items: flex-start;
}
.address-box {
  padding-inline: 0.25rem;
  padding-top: 0.25rem;
  text-align: center;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  line-height: 1;
}
.datetime-box {
  padding-inline: 0 !important;
  -webkit-line-clamp: 3 !important;
}
.ellipsed-span {
 overflow-wrap: anywhere !important;
}
.v-avatar {
  border: solid 0.2rem var(--v-primary-base);
}
/*.row {
  border: solid 1px red
}
.col {
  border: solid 1px orange
}*/
</style>
