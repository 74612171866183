var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-flex",
    { staticClass: "d-flex align-center mx-1", attrs: { shrink: "" } },
    [
      _c(
        "v-menu",
        {
          staticClass: "py-0",
          attrs: {
            transition: "scale-transition",
            "close-on-content-click": false,
            "max-height": "250",
            "offset-y": "",
            top: "",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on: onMenu }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      { staticClass: "primary", attrs: { "x-small": "" } },
                      { ...onMenu }
                    ),
                    [_c("span", [_vm._v(_vm._s(_vm.$t("label.create")))])]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.menu,
            callback: function ($$v) {
              _vm.menu = $$v
            },
            expression: "menu",
          },
        },
        [
          _c(
            "v-form",
            { ref: "form", staticClass: "pa-0" },
            [
              _c(
                "v-list",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-list-item-group",
                    _vm._l(_vm.items, function (item, index) {
                      return _c(
                        "v-list-item",
                        { key: index },
                        [
                          _c(
                            "v-list-item-content",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.select(index)
                                },
                              },
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(item.label)),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }