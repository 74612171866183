<template>
  <v-dialog v-model="dialog" width="fit-content">
    <v-card width="fit-content" height="fit-content">
      <v-card-text class="pa-3">
        <span class="font-weight-medium">{{ message }}</span>
      </v-card-text>
      <v-divider class="my-0" />
      <v-card-actions class="pa-2">
        <v-btn x-small color="error" @click="$emit('cancel')">
          <v-icon small> mdi-close </v-icon>
        </v-btn>
        <v-spacer />
        <v-btn x-small color="success" @click="$emit('confirm')">
          <v-icon small> mdi-check </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: ['open', 'message'],
  emits: ['open-dialog','cancel', 'confirm'],
  data() {
    return {
      dialog: false,
    }
  },
  watch: {
    open() {
      this.dialog = this.open
    },
    dialog() {
      this.$emit('open-dialog', this.dialog)
    },
  },
}
</script>
