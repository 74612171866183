var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "ma-0", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "pa-0" },
            [
              _c(
                "v-row",
                { staticClass: "ma-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-card",
                        { staticClass: "pa-2" },
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "d-inline-flex" },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "pa-0" },
                                    [
                                      _c("ReadonlyField", {
                                        attrs: {
                                          label: _vm.$t(
                                            "label.foreignCorrespondent"
                                          ),
                                          value: _vm.pratica.foreignAgent
                                            ? _vm.pratica.foreignAgent
                                                .supplierName
                                            : null,
                                          icon: "mdi-account",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0 pr-2",
                                      attrs: { cols: "2" },
                                    },
                                    [
                                      _c(
                                        "v-autocomplete",
                                        {
                                          ref: "foreignAgentNRSelect",
                                          attrs: {
                                            label: _vm.$t(
                                              "label.foreignCorrespondentNonRevenue"
                                            ),
                                            "item-text": (agent) =>
                                              `${agent.name} ${agent.sapCode} - ${agent.destination}`,
                                            items: _vm.foreignAgentsNonRevenue,
                                            "menu-props": {
                                              closeOnContentClick: true,
                                              closeOnClick: true,
                                            },
                                            "return-object": "",
                                            clearable: "",
                                          },
                                          on: {
                                            change: function ($event) {
                                              _vm.showSave = true
                                            },
                                            "click:clear": _vm.handleClear,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "item",
                                              fn: function ({ item }) {
                                                return [
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.name) +
                                                            " - " +
                                                            _vm._s(
                                                              item.destination
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _c(
                                                        "v-list-item-subtitle",
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.sapCode
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item-icon",
                                                    [
                                                      !_vm.hideEditBtn[
                                                        _vm.getIndex(item)
                                                      ]
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mr-2 px-2",
                                                              attrs: {
                                                                icon: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.handleForeignAgentNonRevenueEdit(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-pencil"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _c("ButtonConfirm", {
                                                        attrs: {
                                                          color: "error",
                                                        },
                                                        on: {
                                                          enabled: (enabled) =>
                                                            _vm.$set(
                                                              _vm.hideEditBtn,
                                                              _vm.getIndex(
                                                                item
                                                              ),
                                                              enabled
                                                            ),
                                                          submit: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeForeignAgentNonRevenue(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                          model: {
                                            value:
                                              _vm.foreignAgentNonRevenueSelected,
                                            callback: function ($$v) {
                                              _vm.foreignAgentNonRevenueSelected =
                                                $$v
                                            },
                                            expression:
                                              "foreignAgentNonRevenueSelected",
                                          },
                                        },
                                        [
                                          _c("template", { slot: "no-data" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex justify-center py-3",
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      color: "success",
                                                      small: "",
                                                      dark: "",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.handleForeignAgentNonRevenueCreate,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("label.create")
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        2
                                      ),
                                      _c("ForeignAgentFormDialog", {
                                        ref: "foreignAgentFormDialog",
                                        on: {
                                          submit: _vm.addForeignAgentNonRevenue,
                                          "blur-input": function ($event) {
                                            _vm.$nextTick(() =>
                                              _vm.$refs.foreignAgentNRSelect.blur()
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.showSave
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "auto" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "px-0",
                                              attrs: { icon: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.saveForeignAgentNonRevenue(
                                                    _vm.pratica.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    size: "30",
                                                    color: "success",
                                                  },
                                                },
                                                [_vm._v(" mdi-content-save ")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "px-0",
                                              attrs: { icon: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.resetForeignAgentNonRevenue()
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    size: "30",
                                                    color: "error",
                                                  },
                                                },
                                                [_vm._v(" mdi-window-close ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "ma-0 mt-3" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "ActionsStepper",
                            _vm._g(
                              {
                                attrs: {
                                  entity: _vm.praticaActions,
                                  props: _vm.foreignAgentFlags,
                                },
                              },
                              _vm.$listeners
                            )
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.messages.length
                ? _c(
                    "v-row",
                    { staticClass: "ma-0 mt-3" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0" },
                        _vm._l(_vm.messages, function (conversation, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              class:
                                _vm.messages.length - 1 == index
                                  ? "mb-0"
                                  : "mb-3",
                            },
                            [
                              _c("MailPanel", {
                                ref: "mailPanel",
                                refInFor: true,
                                attrs: { conversation: conversation },
                                on: { "email-sent": _vm.handleEmailSent },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex flex-column ml-3 px-0",
              attrs: { cols: "auto" },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "primary",
                  attrs: { fab: "", icon: "", height: "50", width: "50" },
                  on: { click: _vm.loadNewEmailForeignAgent },
                },
                [
                  _c("v-icon", { attrs: { color: "white", size: "30" } }, [
                    _vm._v("mdi-email"),
                  ]),
                ],
                1
              ),
              _c("EmailDialog", {
                ref: "emailDialog",
                attrs: {
                  msgTemplate: _vm.emailTemplate,
                  emailAddresses: _vm.foreignAgentContacts,
                },
                on: { "email-sent": _vm.handleEmailSent },
              }),
              _c("AddressesBook", {
                ref: "addressBook",
                attrs: {
                  foreignAgent: _vm.foreignAgent,
                  contacts: _vm.foreignAgentContacts,
                },
                on: {
                  "create-contact": _vm.createForeignAgentContacts,
                  "update-contact": _vm.updateForeignAgentContacts,
                  "delete-contact": _vm.deleteForeignAgentContacts,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }