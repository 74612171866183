<template>
  <v-container fluid class="pa-0">
    <v-row class="ma-0" dense>
      <v-col class="pa-0">
        <v-row class="ma-0">
          <v-col class="pa-0">
            <v-card class="pa-2">
              <v-row dense>
                <v-col class="d-inline-flex">
                  <v-col class="pa-0">
                    <ReadonlyField
                      :label="$t('label.foreignCorrespondent')"
                      :value="pratica.foreignAgent ? pratica.foreignAgent.supplierName : null"
                      :icon="'mdi-account'"
                    />
                  </v-col>
                  <v-col cols="2" class="pa-0 pr-2">
                    <v-autocomplete
                      ref="foreignAgentNRSelect"
                      :label="$t('label.foreignCorrespondentNonRevenue')"
                      v-model="foreignAgentNonRevenueSelected"
                      :item-text="(agent) => `${agent.name} ${agent.sapCode} - ${agent.destination}`"
                      :items="foreignAgentsNonRevenue"
                      @change="showSave = true"
                      @click:clear="handleClear"
                      :menu-props="{ closeOnContentClick: true, closeOnClick: true }"
                      return-object
                      clearable
                    >
                      <template v-slot:item="{item}">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.name }} -
                            {{ item.destination }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <span>
                              {{ item.sapCode }}
                            </span>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-icon>
                          <v-btn icon class="mr-2 px-2" @click.stop="handleForeignAgentNonRevenueEdit(item)" v-if="!hideEditBtn[getIndex(item)]">
                            <v-icon color="primary">mdi-pencil</v-icon>
                          </v-btn>
                          <ButtonConfirm
                            :color="'error'"
                            @enabled="(enabled) => $set(hideEditBtn, getIndex(item), enabled)"
                            @submit="removeForeignAgentNonRevenue(item)" 
                          />
                        </v-list-item-icon>
                      </template>
                      <template slot="no-data">
                        <div class="d-flex justify-center py-3">
                          <v-btn :color="'success'" small dark @click="handleForeignAgentNonRevenueCreate">
                            {{$t('label.create')}}
                          </v-btn>
                        </div>
                      </template>
                    </v-autocomplete>
                    <ForeignAgentFormDialog 
                      ref="foreignAgentFormDialog" 
                      @submit="addForeignAgentNonRevenue"
                      @blur-input="$nextTick(() => $refs.foreignAgentNRSelect.blur())"
                    />
                  </v-col>
                  <v-col cols="auto" v-if="showSave">
                    <v-btn
                      class="px-0"
                      icon
                      @click="saveForeignAgentNonRevenue(pratica.id)"
                    >
                      <v-icon size="30" color="success">
                        mdi-content-save
                      </v-icon>
                    </v-btn>
                    <v-btn
                      class="px-0"
                      icon
                      @click="resetForeignAgentNonRevenue()"
                    >
                      <v-icon size="30" color="error">
                        mdi-window-close
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="ma-0 mt-3">
          <v-col class="pa-0">
            <v-card>
              <ActionsStepper
                :entity="praticaActions"
                :props="foreignAgentFlags"
                v-on="$listeners"
              />
            </v-card>
          </v-col>
        </v-row>

        <v-row class="ma-0 mt-3" v-if="messages.length">
          <v-col class="pa-0">
            <div v-for="(conversation, index) in messages" :key="index" :class="messages.length - 1 == index ? 'mb-0' : 'mb-3'">
              <MailPanel ref="mailPanel" :conversation="conversation" @email-sent="handleEmailSent" />
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="auto" class="d-flex flex-column ml-3 px-0">
        <v-btn
          fab
          icon
          height="50"
          width="50"
          class="primary"
          @click="loadNewEmailForeignAgent"
        >
          <v-icon color="white" size="30">mdi-email</v-icon>
        </v-btn>
        <EmailDialog
          ref="emailDialog"
          :msgTemplate="emailTemplate"
          :emailAddresses="foreignAgentContacts"
          @email-sent="handleEmailSent"
        />
        <AddressesBook
          ref="addressBook"
          :foreignAgent="foreignAgent"
          :contacts="foreignAgentContacts"
          @create-contact="createForeignAgentContacts"
          @update-contact="updateForeignAgentContacts"
          @delete-contact="deleteForeignAgentContacts"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Email from '@/mixins/Email'
import ForeignAgentContacts from '@/mixins/ForeignAgentContacts'
import ReadonlyField from '@/components/elements/ReadonlyField.vue'
import ActionsStepper from '@/components/elements/ActionsStepper.vue'
import EmailDialog from '@/components/elements/mails/EmailDialog.vue'
import MailPanel from '@/components/elements/mails/MailPanel.vue'
import AddressesBook from '@/components/elements/AddressesBook.vue'
import ForeignAgentFormDialog from '@/components/elements/pratica/foreign-agent/ForeignAgentFormDialog.vue'
import ButtonConfirm from '@/components/elements/global/ButtonConfirm'

export default {
  name: 'PraticaCorrispondenteEstero',
  props: ['pratica', 'praticaActions'],
  mixins: [Email, ForeignAgentContacts],
  components: {
    ReadonlyField,
    ActionsStepper,
    EmailDialog,
    MailPanel,
    AddressesBook,
    ForeignAgentFormDialog,
    ButtonConfirm
  },
  data() {
    return {
      emailTag: 'PREALERT',
      openAddressesBook: false,
      foreignAgentFlags: [
        { text: this.$t('label.preAlertDone'), value: 'foreignAgentPreAlertDone' },
        { text: this.$t('label.customsDeclarationDone'), value: 'foreignAgentCustomsDeclarationDone' },
        { text: this.$t('label.deliveryOrderDone'), value: 'foreignAgentDeliveryOrderDone' },
        { text: this.$t('label.podDone'), value: 'foreignAgentPodDone' },
      ],
      showSave: false,
      hideEditBtn: {},
    }
  },
  methods: {
    init() {
      this.loadForeignAgentsNonRevenue().then(() => {
        this.getForeignAgentForPratica(this.pratica.id).then(() =>
          this.setSelectedForeignAgentNonRevenue()
        )
        this.loadForeignAgentContacts(this.pratica.id)
      })
      
      this.browseMessages(this.emailTag)
    },
    handleClear() {
      if (!this.foreignAgent?.foreignAgentNonRevenueId) 
        setTimeout(() => {
          this.showSave = false
          this.$refs.foreignAgentNRSelect.blur()
        }, 100); 
    },
    handleForeignAgentNonRevenueCreate() {
      this.resetForeignAgentNonRevenue(); 
      this.$refs.foreignAgentFormDialog.handleDialog()
    },
    handleForeignAgentNonRevenueEdit(agent) {
      this.resetForeignAgentNonRevenue();
      this.$refs.foreignAgentNRSelect.blur()
      
      let faDialog = this.$refs.foreignAgentFormDialog
      faDialog.agent = this._.cloneDeep(agent)
      faDialog.handleDialog()
    },
    resetForeignAgentNonRevenue() {
      this.setSelectedForeignAgentNonRevenue()
      this.showSave = false
    },
    getIndex(item) {
      return this.foreignAgentsNonRevenue.indexOf(item)
    },
  },
  created() {
    this.init()
  },
}
</script>

<style scoped>
/* .row {
  border: solid 1px red;
}
.col {
  border: solid 1px orange;
} */
</style>
