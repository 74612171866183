const getDefaultState = () => {
  return {
    id: null,
    documentNbr: null,
    objectType: null,
    status: null,
    bookingRequest: {
      request: null,
      voyageId: null,
      requestDetails: null,
      equipments: null,
    },
    shippingInstruction: {
      request: null,
      equipments: null,
    }
  }
}

const state = {
  company: null,
  lastSelectedFlow: null,
  electronicFlow: getDefaultState(),
}

const mutations = {
  resetElectronicFlow(state) {
    state.electronicFlow = getDefaultState()
  },
  setCompany(state, company) {
    state.company = company
  },
  setLastSelectedFlow(state, electronicFlowId) {
    state.lastSelectedFlow = electronicFlowId
  },
  setElectronicFlow(state, electronicFlow) {
    state.electronicFlow = electronicFlow
  },
  setDocumentNbr(state, documentNbr) {
    state.electronicFlow.documentNbr = documentNbr
  },
  setObjectType(state, objectType) {
    state.electronicFlow.objectType = objectType
  },
  setStatus(state, status) {
    state.electronicFlow.status = status
  },
  setBookingRequestJson(state, request) {
    state.electronicFlow.bookingRequest.request = request
  },
  setRequestVoyageId(state, voyageId) {
    state.electronicFlow.bookingRequest.voyageId = voyageId
  },
  setRequestDetails(state, requestDetails) {
    state.electronicFlow.bookingRequest.requestDetails = requestDetails
  },
  setRequestEquipments(state, equipments) {
    state.electronicFlow.bookingRequest.equipments = equipments
  },
  setShippingInstructionJson(state, request) {
    state.electronicFlow.shippingInstruction.request = request
  },
  setShippingInstructionEquipments(state, equipments) {
    state.electronicFlow.shippingInstruction.equipments = equipments
  },
}

export const electronicFlow = {
  namespaced: true,
  state,
  mutations,
}
