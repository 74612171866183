var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        { staticClass: "px-2 py-0" },
        [
          _c("span", { staticClass: "text-overline primary--text pt-1" }, [
            _vm._v(
              " " + _vm._s(_vm.getPartyFunction(_vm.item.partyFunction)) + " "
            ),
          ]),
          _c("v-spacer"),
          _vm.showToOrder(_vm.item.partyFunction)
            ? _c("v-switch", {
                staticClass: "shipped-switch ma-0 pa-0 pt-2",
                attrs: {
                  "true-value": "Shipped on board",
                  "false-value": "Received for shipment",
                  ripple: false,
                  "hide-details": "",
                  inset: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c(
                            "span",
                            { staticClass: "text-caption primary--text" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("label.toOrder")) + " "
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  172756616
                ),
              })
            : _vm._e(),
          _vm.showSameAsConsignee(_vm.item.partyFunction)
            ? _c("v-switch", {
                staticClass: "shipped-switch ma-0 pa-0 pt-2",
                attrs: {
                  "true-value": "Shipped on board",
                  "false-value": "Received for shipment",
                  ripple: false,
                  "hide-details": "",
                  inset: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c(
                            "span",
                            { staticClass: "text-caption primary--text" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("label.sameAsConsignee")) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1521575428
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("v-divider", { staticClass: "ma-0 mb-2" }),
      _c(
        "v-card-text",
        { staticClass: "pa-0 pb-2" },
        [
          _c(
            "v-form",
            { ref: "form", staticClass: "pa-0" },
            [
              _c(
                "v-row",
                { staticClass: "my-0 mx-2" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0" },
                    [
                      _c("v-text-field", {
                        ref: "partyName",
                        class: _vm.getPartyNameClass(_vm.item.party.partyName),
                        attrs: {
                          value: _vm.item.party.partyName,
                          rules: _vm.required,
                          "hide-details": _vm.item.party.partyName != null,
                          "validate-on-blur": "",
                          readonly: "",
                          flat: "",
                          solo: "",
                        },
                      }),
                      _c("span", { staticClass: "text-caption" }, [
                        _vm._v(_vm._s(_vm.item.party.nmftaCode)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0 mx-2" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0 text-caption" },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-caption primary--text text-decoration-underline",
                          on: {
                            click: function ($event) {
                              return _vm.openPartyEditDialog(_vm.item)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("label.change")) + " ")]
                      ),
                      _c(
                        "v-dialog",
                        {
                          attrs: { "retain-focus": false, width: "30%" },
                          model: {
                            value: _vm.partyEditDialog,
                            callback: function ($$v) {
                              _vm.partyEditDialog = $$v
                            },
                            expression: "partyEditDialog",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass:
                                    "pa-2 px-3 text-overline primary--text",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("label.selectParty")) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("v-divider", { staticClass: "ma-0" }),
                              _c(
                                "v-card-text",
                                { staticClass: "pa-0" },
                                [
                                  _c(
                                    "v-container",
                                    { staticClass: "pb-2" },
                                    [
                                      _c(
                                        "v-form",
                                        {
                                          ref: "partyEditForm",
                                          staticClass: "pa-0",
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  _c("v-autocomplete", {
                                                    staticClass: "xs-input",
                                                    attrs: {
                                                      label:
                                                        _vm.$t("label.party"),
                                                      "item-text":
                                                        "customerName",
                                                      filter:
                                                        _vm.filterCustomers,
                                                      items: _vm.customers,
                                                      "menu-props":
                                                        _vm.menuProps,
                                                      rules: _vm.required,
                                                      "return-object": "",
                                                      clearable: "",
                                                      outlined: "",
                                                      dense: "",
                                                    },
                                                    on: {
                                                      "update:search-input":
                                                        _vm.getCustomers,
                                                      change:
                                                        _vm.updatePartyCustomer,
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                      },
                                                    },
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "item",
                                                        fn: function (data) {
                                                          return [
                                                            _c(
                                                              "v-list-item-content",
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          data
                                                                            .item
                                                                            .customerName
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-list-item-subtitle",
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.getCarrierCode(
                                                                            data.item
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ]),
                                                    model: {
                                                      value:
                                                        _vm.customerSelection,
                                                      callback: function ($$v) {
                                                        _vm.customerSelection =
                                                          $$v
                                                      },
                                                      expression:
                                                        "customerSelection",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "xs-input",
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "label.customerCarrierCode"
                                                      ),
                                                      value: _vm.getCarrierCode(
                                                        _vm.customerSelection
                                                      ),
                                                      rules: _vm.required,
                                                      maxlength: "20",
                                                      outlined: "",
                                                      dense: "",
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.addNewCustomerCode,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider", { staticClass: "my-2" }),
              _c(
                "v-row",
                { staticClass: "my-0 mx-2" },
                [
                  _c("v-col", { staticClass: "pa-0 text-caption" }, [
                    _c("span", { staticClass: "text-caption primary--text" }, [
                      _vm._v(" " + _vm._s(_vm.$t("label.address")) + " "),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "pa-0", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-dialog",
                        {
                          attrs: { width: "30%" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      {
                                        attrs: {
                                          color: "primary",
                                          "x-small": "",
                                        },
                                      },
                                      on
                                    ),
                                    [_vm._v(" mdi-pencil ")]
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass:
                                    "pa-2 px-3 text-overline primary--text",
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("label.address"))),
                                  ]),
                                ]
                              ),
                              _c("v-divider", { staticClass: "ma-0" }),
                              _c(
                                "v-card-text",
                                { staticClass: "pa-0" },
                                [
                                  _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                staticClass: "xs-input",
                                                attrs: {
                                                  label: _vm.$t("label.street"),
                                                  "hide-details": "",
                                                  clearable: "",
                                                  outlined: "",
                                                  dense: "",
                                                },
                                                model: {
                                                  value: _vm.item.party.street,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.item.party,
                                                      "street",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.party.street",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "3" } },
                                            [
                                              _c("v-text-field", {
                                                staticClass: "xs-input",
                                                attrs: {
                                                  label: _vm.$t("label.number"),
                                                  "hide-details": "",
                                                  clearable: "",
                                                  outlined: "",
                                                  dense: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.item.party.streetNumber,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.item.party,
                                                      "streetNumber",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.party.streetNumber",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                staticClass: "xs-input",
                                                attrs: {
                                                  label: _vm.$t("label.city"),
                                                  "hide-details": "",
                                                  clearable: "",
                                                  outlined: "",
                                                  dense: "",
                                                },
                                                model: {
                                                  value: _vm.item.party.city,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.item.party,
                                                      "city",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.party.city",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "3" } },
                                            [
                                              _c("v-text-field", {
                                                staticClass: "xs-input",
                                                attrs: {
                                                  label:
                                                    _vm.$t("label.country"),
                                                  "hide-details": "",
                                                  clearable: "",
                                                  outlined: "",
                                                  dense: "",
                                                },
                                                model: {
                                                  value: _vm.item.party.country,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.item.party,
                                                      "country",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.party.country",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0 mx-2" },
                [
                  _c("v-col", { staticClass: "pa-0" }, [
                    _c("span", { staticClass: "text-caption" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.partyAddressStreetLabel(
                              _vm.item.party.street,
                              _vm.item.party.streetNumber
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0 mx-2" },
                [
                  _c("v-col", { staticClass: "pa-0" }, [
                    _c("span", { staticClass: "text-caption" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.partyAddressCityLabel(
                              _vm.item.party.city,
                              _vm.item.party.country
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c("v-divider", { staticClass: "my-2" }),
              _c(
                "v-row",
                { staticClass: "my-0 mx-2" },
                [
                  _c("v-col", { staticClass: "pa-0 text-caption" }, [
                    _c("span", { staticClass: "text-caption primary--text" }, [
                      _vm._v(" " + _vm._s(_vm.$t("label.contact")) + " "),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "pa-0", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-dialog",
                        {
                          attrs: { width: "30%" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      {
                                        attrs: {
                                          color: "primary",
                                          "x-small": "",
                                        },
                                      },
                                      on
                                    ),
                                    [_vm._v(" mdi-pencil ")]
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c("v-card-title", { staticClass: "pa-2 px-3" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "text-overline primary--text",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("label.contact")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("v-divider", { staticClass: "ma-0" }),
                              _c(
                                "v-card-text",
                                { staticClass: "pa-0" },
                                [
                                  _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                staticClass: "xs-input",
                                                attrs: {
                                                  label: _vm.$t("label.name"),
                                                  "hide-details": "",
                                                  clearable: "",
                                                  outlined: "",
                                                  dense: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.item.contactDetails
                                                      .name,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.item.contactDetails,
                                                      "name",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.contactDetails.name",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                staticClass: "xs-input",
                                                attrs: {
                                                  label: _vm.$t("label.email"),
                                                  "hide-details": "",
                                                  clearable: "",
                                                  outlined: "",
                                                  dense: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.item.contactDetails
                                                      .email,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.item.contactDetails,
                                                      "email",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.contactDetails.email",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                staticClass: "xs-input",
                                                attrs: {
                                                  label: _vm.$t("label.phone"),
                                                  "hide-details": "",
                                                  clearable: "",
                                                  outlined: "",
                                                  dense: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.item.contactDetails
                                                      .phone,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.item.contactDetails,
                                                      "phone",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.contactDetails.phone",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0 mx-2" },
                [
                  _c("v-col", { staticClass: "pa-0" }, [
                    _c("span", { staticClass: "text-caption" }, [
                      _vm._v(" " + _vm._s(_vm.item.contactDetails.name) + " "),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0 mx-2" },
                [
                  _c("v-col", { staticClass: "pa-0" }, [
                    _c("span", { staticClass: "text-caption" }, [
                      _vm._v(" " + _vm._s(_vm.item.contactDetails.email) + " "),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0 mx-2" },
                [
                  _c("v-col", { staticClass: "pa-0" }, [
                    _c("span", { staticClass: "text-caption" }, [
                      _vm._v(" " + _vm._s(_vm.item.contactDetails.phone) + " "),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }