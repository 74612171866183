var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "d-flex justify-center",
      attrs: { "fill-height": "", fluid: "" },
    },
    [
      _c(
        "v-btn",
        {
          staticClass: "primary pa-1",
          on: { click: _vm.openTabWithPostParams },
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("label.masterSpedLight")))])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }