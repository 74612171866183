var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { "fill-height": "", fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "ma-0 fill-height", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "pa-0" },
            [
              _c(
                "v-card",
                {
                  staticClass: "d-table fill-height pa-2",
                  attrs: { width: "100%" },
                  on: {
                    dragover: function ($event) {
                      $event.preventDefault()
                      _vm.dragging = true
                    },
                    dragenter: function ($event) {
                      $event.preventDefault()
                      _vm.dragging = true
                    },
                    dragleave: function ($event) {
                      $event.preventDefault()
                      _vm.dragging = false
                    },
                  },
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "d-table-row fill-height",
                      on: {
                        drop: function ($event) {
                          $event.preventDefault()
                          return _vm.onDrop($event)
                        },
                      },
                    },
                    [
                      _vm.dragging
                        ? _c(
                            "div",
                            {
                              class: _vm.dragging
                                ? "centered dashed"
                                : "centered",
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-3", attrs: { size: "60" } },
                                [_vm._v(" mdi-cloud-upload ")]
                              ),
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("label.dropFilesHere")) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          )
                        : _c("v-data-table", {
                            staticClass: "scrollable",
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.files,
                              "item-key": "id",
                              "items-per-page": 1000,
                              "hide-default-footer": "",
                              "show-select": "",
                              "fixed-header": "",
                              height: "100%",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item.fileName",
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-2",
                                        attrs: { color: "primary" },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.getIcon(item)) + " "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" " + _vm._s(item.fileName) + " "),
                                  ]
                                },
                              },
                              {
                                key: "item.createDatetime",
                                fn: function ({ item }) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatDate(item.createDatetime)
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "item.actions",
                                fn: function ({ item }) {
                                  return [
                                    _c("PraticaDocumentActions", {
                                      attrs: { document: item },
                                      on: {
                                        "delete-file": _vm.deleteFile,
                                        "download-file": _vm.downloadDocument,
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.selected,
                              callback: function ($$v) {
                                _vm.selected = $$v
                              },
                              expression: "selected",
                            },
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex flex-column ml-3 px-0",
              attrs: { cols: "auto" },
            },
            [
              _c("input", {
                ref: "fileUpload",
                attrs: { type: "file", hidden: "" },
                on: {
                  change: ($event) =>
                    _vm.uploadFile($event?.target?.files?.[0]),
                },
              }),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on: on }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "primary mb-2",
                                attrs: {
                                  height: "50",
                                  width: "50",
                                  icon: "",
                                  fab: "",
                                },
                                on: { click: _vm.selectFile },
                              },
                              on
                            ),
                            [
                              _c("v-icon", { attrs: { color: "white" } }, [
                                _vm._v("mdi-upload"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("span", { staticClass: "text-caption" }, [
                    _vm._v(_vm._s(_vm.$t("label.uploadDocument"))),
                  ]),
                ]
              ),
              _vm.selected.length
                ? _c("ButtonConfirmDialog", {
                    attrs: {
                      icon: "mdi-delete",
                      color: "error",
                      body: _vm.$t("message.confirmDeletion"),
                      title: _vm.$t("label.confirmDelete"),
                    },
                    on: { submit: _vm.deleteSelected },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }