<template>
  <v-simple-table dense fixed-header>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-caption primary--text text-left"></th>
          <th class="text-caption primary--text text-left">
            {{ $t("label.invoiceNbr") }}
          </th>
          <th class="text-caption primary--text text-left">
            {{ $t("label.invoiceHolderName") }}
          </th>
          <th class="text-caption primary--text text-left">
            {{ $t("label.sellerName") }}
          </th>
          <th class="text-caption primary--text text-left">
            {{ $t("label.consigneeName") }}
          </th>
          <th class="text-caption primary--text text-left">
            {{ $t("label.totalAmount") }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in invoices"
          :key="index"
          @click="handleMatch(item.documentNbr, !item.isLinked)"
        >
          <td class="text-caption font-weight-bold">
            <v-icon v-if="item.isLinked" color="success" size="x-large"
              >mdi-check-network</v-icon
            >
          </td>

          <!-- Invoice nbr. -->
          <td class="text-caption font-weight-bold">
            {{ item.invoiceNbr }}
          </td>

          <!-- Invoice Recipient -->
          <td class="text-caption font-weight-bold">
            <div v-if="item.invoiceHolderName.length > 10">
              {{ item.invoiceHolderName.substring(0, 10)
              }}<span
                v-if="!showFullText[index]"
                @click.stop="toggleShowFullText(index)"
                >...</span
              >
              <span v-if="showFullText[index]">{{
                item.invoiceHolderName
              }}</span>
            </div>
            <div v-else>
              {{ item.invoiceHolderName }}
            </div>
          </td>

          <!-- Seller -->
          <td class="text-caption font-weight-bold">
            {{ item.sellerName }}
          </td>

          <!-- Consignee -->
          <td class="text-caption font-weight-bold">
            <div v-if="item.consigneeName.length > 10">
              {{ item.consigneeName.substring(0, 10)
              }}<span
                v-if="!showFullText[index]"
                @click.stop="toggleShowFullText(index)"
                >...</span
              >
              <span v-if="showFullText[index]">{{ item.consigneeName }}</span>
            </div>
            <div v-else>
              {{ item.consigneeName }}
            </div>
          </td>

          <!-- Total Amount -->
          <td class="text-caption font-weight-bold">
            {{ item.totalAmount + " " + item.currency }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  data() {
    return {
      showFullText: [false, false, false],
    };
  },
  props: ["invoices"],
  emits: ["invoice"],
  methods: {
    toggleShowFullText(index) {
      this.$set(this.showFullText, index, !this.showFullText[index]);
    },
    handleMatch(invoiceDocumentNbr, value) {
      this.$emit("invoice", invoiceDocumentNbr, value);
    },
  },
};
</script>

<style scoped></style>
