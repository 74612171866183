var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "stepper-wrapper", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-container",
                {
                  staticClass: "d-table pa-0 pr-2",
                  attrs: { "fill-height": "", fluid: "" },
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "d-table-row", attrs: { dense: "" } },
                    [
                      _c(
                        "v-tabs",
                        {
                          attrs: {
                            "background-color": "transparent",
                            color: "primary",
                            height: "2rem",
                            grow: "",
                          },
                          model: {
                            value: _vm.tab,
                            callback: function ($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab",
                          },
                        },
                        [_c("v-tab", [_vm._v(" Standard ")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "d-table-row fill-height",
                      attrs: { dense: "" },
                    },
                    [
                      _c(
                        "v-tabs-items",
                        {
                          staticClass: "fill-height overflow-y-auto",
                          model: {
                            value: _vm.tab,
                            callback: function ($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab",
                          },
                        },
                        [
                          _c(
                            "v-tab-item",
                            [
                              _c("BookingCommoditiesStandard", {
                                ref: "commoditiesStandard",
                                on: {
                                  loaded: (isLoaded) =>
                                    (_vm.loading = !isLoaded),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "flex-grow-0", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "d-flex pa-3" },
            [
              _c(
                "v-btn",
                {
                  attrs: { small: "", icon: "", color: "primary" },
                  on: { click: _vm.exitRequest },
                },
                [
                  _c("v-icon", { attrs: { size: "30" } }, [
                    _vm._v("mdi-arrow-left-circle"),
                  ]),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  attrs: { small: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("step", false)
                    },
                  },
                },
                [
                  _c("v-icon", [_vm._v("mdi-step-backward")]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("label.back")))]),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    small: "",
                    color: "primary",
                    disabled: _vm.disableNext || _vm.loading,
                  },
                  on: { click: _vm.submitCommodityDetails },
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.$t("label.next")))]),
                  _c("v-icon", [_vm._v("mdi-play")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }