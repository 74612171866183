var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "pa-0 pb-1 fill-height d-flex flex-column",
      attrs: { flat: "", outlined: "" },
    },
    [
      _c(
        "v-list-item",
        { staticClass: "pr-1 pl-2" },
        [
          _c(
            "v-list-item-content",
            [
              _c(
                "v-list-item-title",
                { staticClass: "text-h6 font-weight-black indigo--text" },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              ),
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "d-flex pa-1 py-0 mr-3", attrs: { shrink: "" } },
            [
              _c(
                "v-icon",
                { staticClass: "mr-1", attrs: { small: "", color: "primary" } },
                [_vm._v("mdi-clock")]
              ),
              _c("span", { staticClass: "text-caption primary--text" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("label.lastTwelveMonths")) +
                    " " +
                    _vm._s(_vm.$t("label.sinceDate")) +
                    " "
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-chip",
            {
              staticClass: "font-weight-black text-h6 ma-0",
              attrs: { color: "primary lighten-1" },
            },
            [
              _c("div", { staticClass: "indigo--text" }, [
                _vm._v(_vm._s(_vm.count)),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("v-divider", { staticClass: "ma-1 my-0" }),
      _c(
        "v-row",
        { staticClass: "ma-0", attrs: { dense: "" } },
        _vm._l(_vm.elements, function (element) {
          return _c(
            "v-col",
            {
              key: element.name,
              staticClass: "d-flex justify-center align-end pa-0",
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "text-overline text-center text--disabled text-uppercase",
                },
                [
                  _vm._v(" " + _vm._s(element.name) + " "),
                  _c(
                    "span",
                    { staticClass: "font-weight-black primary--text" },
                    [_vm._v(" " + _vm._s(element.value) + " ")]
                  ),
                ]
              ),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }