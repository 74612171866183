<template>
  <v-container class="d-flex justify-center" fill-height fluid>
    <v-card class="d-flex align-center pa-5">
      <v-icon size="50" class="mr-3" color="primary">
        mdi-information
      </v-icon>
      <h3 class="ma-0 primary--text">{{ $t('label.contactAdminNewUser') }}</h3>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'NewUserLanding',
}
</script>

<style scoped>
.v-card {
  border: solid 0.3rem var(--v-primary-base);
}
</style>
