var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    { staticClass: "d-block pa-2 overflow-y-auto fill-height" },
    [
      _c(
        "v-row",
        { staticClass: "booking-header", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "d-flex align-center", attrs: { cols: "auto" } },
            [
              _c("v-checkbox", {
                staticClass: "checkbox ma-0 pa-0",
                attrs: { color: "primary", ripple: false, "hide-details": "" },
                on: { change: _vm.selectAllPayments },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center",
              staticStyle: { "max-width": "20rem" },
            },
            [
              _c("v-select", {
                staticClass: "xs-input",
                attrs: {
                  label: "Assign a Payment term to selected",
                  items: _vm.paymentTerms,
                  "item-text": "customerName",
                  "return-object": "",
                  "menu-props": _vm.menuProps,
                  "hide-details": "",
                  clearable: "",
                  outlined: "",
                  attach: "",
                  dense: "",
                },
                on: { change: _vm.setPaymentTermToAllSelected },
              }),
            ],
            1
          ),
          _c("v-divider", {
            staticClass: "mx-3 my-1",
            attrs: { vertical: "" },
          }),
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center",
              staticStyle: { "max-width": "20rem" },
            },
            [
              _c("v-select", {
                staticClass: "xs-input",
                attrs: {
                  label: "Assign a Payer to selected",
                  items: _vm.customers,
                  "item-text": "customerName",
                  "return-object": "",
                  "menu-props": _vm.menuProps,
                  "hide-details": "",
                  clearable: "",
                  outlined: "",
                  attach: "",
                  dense: "",
                },
                on: { change: _vm.setPartyToAllSelected },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-1 px-2", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "auto" } },
            [
              _c(
                "v-subheader",
                { staticClass: "pa-0 primary--text text-overline" },
                [_vm._v(" Origin ")]
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "d-flex align-center py-0 pl-0" },
            [_c("v-divider")],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-0", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "py-0" },
            [
              _c(
                "v-list",
                { attrs: { flat: "", dense: "" } },
                [
                  _c(
                    "v-list-item-group",
                    {
                      attrs: { multiple: "" },
                      model: {
                        value: _vm.selectedPayments,
                        callback: function ($$v) {
                          _vm.selectedPayments = $$v
                        },
                        expression: "selectedPayments",
                      },
                    },
                    _vm._l(_vm.originPayments, function (item, index) {
                      return _c("v-list-item", {
                        key: index,
                        staticClass: "px-2",
                        attrs: { value: item.value, ripple: false },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ active }) {
                                return [
                                  _c(
                                    "v-list-item-action",
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          "input-value": active,
                                          ripple: false,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    {
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "d-flex align-center",
                                              staticStyle: {
                                                "max-width": "20rem",
                                              },
                                            },
                                            [
                                              _c("span", {
                                                domProps: {
                                                  innerHTML: _vm._s(item.text),
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "d-flex align-center",
                                              attrs: { cols: "auto" },
                                            },
                                            [
                                              _c(
                                                "v-radio-group",
                                                {
                                                  staticClass:
                                                    "radio-group ma-0 pt-0",
                                                  attrs: {
                                                    "hide-details": "",
                                                    dense: "",
                                                  },
                                                  model: {
                                                    value:
                                                      item.value.paymentTerm,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item.value,
                                                        "paymentTerm",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.value.paymentTerm",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-radio",
                                                    { attrs: { value: "PRE" } },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "label" },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-caption black--text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Prepaid "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                  _c(
                                                    "v-radio",
                                                    { attrs: { value: "COL" } },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "label" },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-caption black--text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Collect "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "d-flex align-center",
                                              staticStyle: {
                                                "max-width": "20rem",
                                              },
                                            },
                                            [
                                              _c("v-select", {
                                                staticClass: "xs-input",
                                                attrs: {
                                                  label: _vm.$t("label.party"),
                                                  items: _vm.customers,
                                                  "item-text": "customerName",
                                                  "return-object": "",
                                                  "menu-props": _vm.menuProps,
                                                  "hide-details": "",
                                                  outlined: "",
                                                  attach: "",
                                                  dense: "",
                                                },
                                                model: {
                                                  value: item.value.party,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item.value,
                                                      "party",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.value.party",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "py-0 px-2", attrs: { cols: "auto" } },
            [
              _c(
                "v-subheader",
                { staticClass: "pa-0 primary--text text-overline" },
                [_vm._v(" Freight ")]
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "d-flex align-center py-0 pl-0" },
            [_c("v-divider")],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-0", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "py-0" },
            [
              _c(
                "v-list",
                { attrs: { flat: "", dense: "" } },
                [
                  _c(
                    "v-list-item-group",
                    {
                      attrs: { multiple: "" },
                      model: {
                        value: _vm.selectedPayments,
                        callback: function ($$v) {
                          _vm.selectedPayments = $$v
                        },
                        expression: "selectedPayments",
                      },
                    },
                    _vm._l(_vm.freightPayments, function (item, index) {
                      return _c("v-list-item", {
                        key: index,
                        staticClass: "px-2",
                        attrs: { value: item.value, ripple: false },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ active }) {
                                return [
                                  _c(
                                    "v-list-item-action",
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          "input-value": active,
                                          ripple: false,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    {
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "d-flex align-center",
                                              staticStyle: {
                                                "max-width": "20rem",
                                              },
                                            },
                                            [
                                              _c("span", {
                                                domProps: {
                                                  innerHTML: _vm._s(item.text),
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "d-flex align-center",
                                              attrs: { cols: "auto" },
                                            },
                                            [
                                              _c(
                                                "v-radio-group",
                                                {
                                                  staticClass:
                                                    "radio-group ma-0 pt-0",
                                                  attrs: {
                                                    "hide-details": "",
                                                    dense: "",
                                                  },
                                                  model: {
                                                    value:
                                                      item.value.paymentTerm,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item.value,
                                                        "paymentTerm",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.value.paymentTerm",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-radio",
                                                    { attrs: { value: "PRE" } },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "label" },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-caption black--text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Prepaid "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                  _c(
                                                    "v-radio",
                                                    { attrs: { value: "COL" } },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "label" },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-caption black--text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Collect "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "d-flex align-center",
                                              staticStyle: {
                                                "max-width": "20rem",
                                              },
                                            },
                                            [
                                              _c("v-select", {
                                                staticClass: "xs-input",
                                                attrs: {
                                                  label: _vm.$t("label.party"),
                                                  items: _vm.customers,
                                                  "item-text": "customerName",
                                                  "return-object": "",
                                                  "menu-props": _vm.menuProps,
                                                  "hide-details": "",
                                                  outlined: "",
                                                  attach: "",
                                                  dense: "",
                                                },
                                                model: {
                                                  value: item.value.party,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item.value,
                                                      "party",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.value.party",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "py-0 px-2", attrs: { cols: "auto" } },
            [
              _c(
                "v-subheader",
                { staticClass: "pa-0 primary--text text-overline" },
                [_vm._v(" Destination ")]
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "d-flex align-center py-0 pl-0" },
            [_c("v-divider")],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-0", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "py-0" },
            [
              _c(
                "v-list",
                { attrs: { flat: "", dense: "" } },
                [
                  _c(
                    "v-list-item-group",
                    {
                      attrs: { multiple: "" },
                      model: {
                        value: _vm.selectedPayments,
                        callback: function ($$v) {
                          _vm.selectedPayments = $$v
                        },
                        expression: "selectedPayments",
                      },
                    },
                    _vm._l(_vm.destinationPayments, function (item, index) {
                      return _c("v-list-item", {
                        key: index,
                        staticClass: "px-2",
                        attrs: { value: item.value, ripple: false },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ active }) {
                                return [
                                  _c(
                                    "v-list-item-action",
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          "input-value": active,
                                          ripple: false,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    {
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "d-flex align-center",
                                              staticStyle: {
                                                "max-width": "20rem",
                                              },
                                            },
                                            [
                                              _c("span", {
                                                domProps: {
                                                  innerHTML: _vm._s(item.text),
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "d-flex align-center",
                                              attrs: { cols: "auto" },
                                            },
                                            [
                                              _c(
                                                "v-radio-group",
                                                {
                                                  staticClass:
                                                    "radio-group ma-0 pt-0",
                                                  attrs: {
                                                    "hide-details": "",
                                                    dense: "",
                                                  },
                                                  model: {
                                                    value:
                                                      item.value.paymentTerm,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item.value,
                                                        "paymentTerm",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.value.paymentTerm",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-radio",
                                                    { attrs: { value: "PRE" } },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "label" },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-caption black--text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Prepaid "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                  _c(
                                                    "v-radio",
                                                    { attrs: { value: "COL" } },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "label" },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-caption black--text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Collect "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "d-flex align-center",
                                              staticStyle: {
                                                "max-width": "20rem",
                                              },
                                            },
                                            [
                                              _c("v-select", {
                                                staticClass: "xs-input",
                                                attrs: {
                                                  label: _vm.$t("label.party"),
                                                  items: _vm.customers,
                                                  "item-text": "customerName",
                                                  "return-object": "",
                                                  "menu-props": _vm.menuProps,
                                                  "hide-details": "",
                                                  outlined: "",
                                                  attach: "",
                                                  dense: "",
                                                },
                                                model: {
                                                  value: item.value.party,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item.value,
                                                      "party",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.value.party",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }