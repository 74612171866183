<template>
  <v-container fluid class="pa-0">
    <v-row class="ma-0">
      <v-col cols="auto" class="pa-0">
        <v-icon :color="assignColor()" class="mr-2">mdi-account-circle</v-icon>
      </v-col>
      <v-col style="margin-top: 0.2rem;">
        <v-row>
          <span v-text="message.from" />
        </v-row>
        <v-row>
          <span v-text="message.subject" class="text-caption" />
        </v-row>
      </v-col>
    </v-row>

    <v-flex v-if="current == index">
      <span class="text-caption ml-8">to</span>
      <span
        class="text-caption ml-2"
        v-html="message.to.join(', ')"
      />
      <!-- <span
        class="text-caption ml-2"
        v-for="(contact, i) in message.to"
        :key="i"
        v-html="contact"
      />  -->
      <v-menu
        :close-on-content-click="false"
        :nudge-width="200"
        offset-overflow
        offset-x
        z-index="10"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon x-small class="mx-1" v-on="on">
            <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list class="pa-4 pl-0">
            <v-list-item>
              <v-list-item-content class="pa-0 pb-1">
                <v-list-item-title class="text-caption d-flex">
                  <v-col class="field text--disabled" align="right">
                    <span>From:</span>
                  </v-col>
                  <v-col class="content">
                    <v-col class="pa-0">
                      <span v-html="formatContact(message.from)" />
                    </v-col>
                  </v-col>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="pa-0 pb-1">
                <v-list-item-title class="text-caption d-flex">
                  <v-col class="field text--disabled" align="right">
                    To:
                  </v-col>
                  <v-col class="d-flex flex-column content">
                    <!-- <v-col
                      class="pa-0"
                      v-for="(contact, index) in message.to"
                      :key="index"
                    > -->
                      <span v-html="formatContact(contact)" v-for="(contact, index) in message.to"
                      :key="index" />
                    <!-- </v-col> -->
                  </v-col>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="message.cc.length">
              <v-list-item-content class="pa-0 pb-1">
                <v-list-item-title class="text-caption d-flex">
                  <v-col class="field text--disabled" align="right">
                    Cc:
                  </v-col>
                  <v-col class="content">
                    <v-col
                      class="pa-0"
                      v-for="(contact, index) in message.cc"
                      :key="index"
                    >
                      <span v-html="formatContact(contact)" />
                    </v-col>
                  </v-col>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="message.bcc.length">
              <v-list-item-content class="pa-0 pb-1">
                <v-list-item-title class="text-caption d-flex">
                  <v-col class="field text--disabled" align="right">
                    Bcc:
                  </v-col>
                  <v-col class="content">
                    <v-col
                      class="pa-0"
                      v-for="(contact, index) in message.bcc"
                      :key="index"
                    >
                      <span v-html="formatContact(contact)" />
                    </v-col>
                  </v-col>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="pa-0 pb-1">
                <v-list-item-title class="text-caption d-flex">
                  <v-col class="field text--disabled" align="right">
                    Subject:
                  </v-col>
                  <v-col class="content">
                    <v-col class="pa-0">
                      <span>{{ message.subject }}</span>
                    </v-col>
                  </v-col>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="pa-0">
                <v-list-item-title class="text-caption d-flex">
                  <v-col class="field text--disabled" align="right">
                    Date:
                  </v-col>
                  <v-col class="content">
                    <v-col class="pa-0">
                      <span v-html="formatDate(message.receivedDateTime)" />
                    </v-col>
                  </v-col>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-flex>
  </v-container>
</template>

<script>
import { dateFilter } from '@/filters/dateFilter'

export default {
  name: 'MailDetails',
  props: ['message', 'index', 'current', 'contactsColors'],
  methods: {
    formatDate(value) {
      return dateFilter.formatDate(value)
    },
    formatContact(contact) {
      return `<b>${contact}</b> <span class="text-caption">&lt;${contact}&gt;</span>`
    },
    assignColor() {
      const results = this.contactsColors.filter((contact) => {
        return contact.email === this.message.from
      })
      return results[0].color
    },
  },
}
</script>

<style scoped>
.v-list-item {
  min-height: 0 !important;
}
.v-list-item .v-list-item__title {
  line-height: unset;
}
.content {
  padding: 0;
  margin-left: 0.5rem;
  max-width: fit-content !important;
}
.field {
  padding: 0;
  padding-top: 0.05rem;
  min-width: 3.5rem;
  max-width: 3.5rem;
  font-weight: bold;
}
/*.row {
  border: solid 1px red;
}
.col {
  border: solid 1px orange;
}*/
</style>
