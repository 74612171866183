var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-3", attrs: { fluid: "" } },
    _vm._l(_vm.props, function (prop, i) {
      return _c(
        "v-row",
        { key: i, staticClass: "ma-0", attrs: { align: "center" } },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center pa-0",
              attrs: { cols: "auto" },
            },
            [
              _c("v-checkbox", {
                staticClass: "ma-0 pa-0",
                attrs: { "hide-details": "", ripple: false },
                on: {
                  change: function ($event) {
                    return _vm.update($event, prop)
                  },
                },
                model: {
                  value: _vm.entity[prop.value],
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, prop.value, $$v)
                  },
                  expression: "entity[prop.value]",
                },
              }),
            ],
            1
          ),
          _c("v-col", { staticClass: "d-flex align-center pa-0" }, [
            _c("span", [_vm._v(" " + _vm._s(prop.text) + " ")]),
          ]),
          _c(
            "v-col",
            { staticClass: "pa-0" },
            [
              _c(
                "v-menu",
                {
                  attrs: {
                    "offset-y": "",
                    "close-on-click": "",
                    "close-on-content-click": false,
                    "open-on-click":
                      _vm.entity[prop.note] && _vm.entity[prop.note].length > 50
                        ? true
                        : false,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              "v-text-field",
                              _vm._g(
                                {
                                  ref: prop.value,
                                  refInFor: true,
                                  staticClass: "ma-0 pa-0 text-caption",
                                  attrs: {
                                    maxlength: "100",
                                    "append-icon": !_vm.entity[prop.note]
                                      ? "mdi-pencil"
                                      : "",
                                    "hide-details": "",
                                    clearable: "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.update(null, prop)
                                    },
                                    "click:clear": function ($event) {
                                      return _vm.clearPropNotes(prop)
                                    },
                                  },
                                  model: {
                                    value: _vm.entity[prop.note],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.entity, prop.note, $$v)
                                    },
                                    expression: "entity[prop.note]",
                                  },
                                },
                                on
                              )
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "dropdown-menu" },
                    [
                      _c("v-textarea", {
                        attrs: {
                          maxlength: "100",
                          "hide-details": "",
                          "auto-grow": "",
                          solo: "",
                        },
                        model: {
                          value: _vm.entity[prop.note],
                          callback: function ($$v) {
                            _vm.$set(_vm.entity, prop.note, $$v)
                          },
                          expression: "entity[prop.note]",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }