var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-timeline",
    _vm._l(_vm.steps, function (route, i) {
      return _c(
        "v-timeline-item",
        {
          key: i,
          class: _vm.removePaddingB(i),
          attrs: { right: "", color: "white", small: "" },
          scopedSlots: _vm._u(
            [
              {
                key: "opposite",
                fn: function () {
                  return [
                    _vm.showLocationLabel(i, route)
                      ? _c("div", [
                          _c(
                            "span",
                            { staticClass: "text-caption font-weight-bold" },
                            [
                              _vm._v(
                                " " + _vm._s(route.location.locationName) + " "
                              ),
                            ]
                          ),
                          _c("br"),
                          _c("span", { staticClass: "text-caption" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  route.location.facility
                                    ? route.location.facility.facilityName
                                    : ""
                                ) +
                                " "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
              {
                key: "icon",
                fn: function () {
                  return [
                    _c("v-icon", { attrs: { color: "primary" } }, [
                      _vm._v(_vm._s(route.icon)),
                    ]),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        [
          route.vessel && route.vessel.vesselName
            ? _c("span", { staticClass: "text-caption" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      `${_vm.$t("label.departingOn")} ${
                        route.vessel.vesselName
                      }`
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          route.isDeparture && !route.vessel && route.date
            ? _c("span", { staticClass: "text-caption" }, [
                _vm._v(" " + _vm._s(`${_vm.$t("label.departure")}`) + " "),
              ])
            : _vm._e(),
          !route.isDeparture && route.date
            ? _c("span", { staticClass: "text-caption" }, [
                _vm._v(" " + _vm._s(`${_vm.$t("label.arrival")}`) + " "),
              ])
            : _vm._e(),
          _c("br"),
          _c("span", { staticClass: "text-caption" }, [
            _vm._v(_vm._s(_vm.formatDate(route.date))),
          ]),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }