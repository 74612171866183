var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "my-1", attrs: { outlined: "" } },
    [
      _c(
        "v-row",
        { staticClass: "ma-0", attrs: { justify: "space-around" } },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex flex-column pa-1 py-0",
              attrs: { cols: "auto" },
            },
            [
              _c(
                "v-row",
                { staticClass: "ma-0", attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0 d-flex flex-column" },
                    [
                      _c("span", {
                        staticClass: "text-h6 font-weight-black mb-1",
                        domProps: {
                          textContent: _vm._s(_vm.pratica.documentNbr),
                        },
                      }),
                      _vm.isMastersped
                        ? _c("span", {
                            domProps: {
                              textContent: _vm._s(_vm.pratica.documentNbrAlt),
                            },
                          })
                        : _vm._e(),
                      _c("Semaphore", {
                        attrs: { pratica: _vm.pratica },
                        on: {
                          "show-status": (status) =>
                            (_vm.generalStatus = status),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "ma-0", attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0 text-caption font-weight-bold" },
                    [
                      _c("ReadonlyField", {
                        attrs: {
                          label: _vm.$t("label.createDate"),
                          value: _vm.createDate,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0 text-caption font-weight-bold" },
                    [
                      _c("ReadonlyField", {
                        attrs: {
                          label: _vm.$t("label.deliveryDate"),
                          value: _vm.deliveryDate,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-col",
            {
              staticClass: "pa-1 d-flex align-center",
              staticStyle: { "max-width": "20rem" },
            },
            [
              _c(
                "v-flex",
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0 px-1 text-caption font-weight-bold" },
                    [
                      _c("ReadonlyField", {
                        attrs: {
                          label: _vm.$t("label.shipper"),
                          value: _vm.pratica.shipperName,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "ma-1" }),
                  _c(
                    "v-col",
                    { staticClass: "py-0 px-1 text-caption font-weight-bold" },
                    [
                      _c("ReadonlyField", {
                        attrs: {
                          label: _vm.$t("label.consignee"),
                          value: _vm.pratica.consigneeName,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-col",
            {
              staticClass: "pa-1 d-flex align-center",
              staticStyle: { "max-width": "11rem" },
            },
            [
              _c(
                "v-flex",
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0 px-1 text-caption font-weight-bold" },
                    [
                      _c("ReadonlyField", {
                        attrs: {
                          label: _vm.$t("label.saleOrder"),
                          value: _vm.pratica.saleOrder,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "ma-1" }),
                  !_vm.isMastersped
                    ? _c(
                        "v-col",
                        {
                          staticClass:
                            "py-0 px-1 text-caption font-weight-bold",
                        },
                        [
                          _c("ReadonlyField", {
                            attrs: {
                              label: _vm.$t("label.shipperInvoiceReference"),
                              value: _vm.pratica.shipperInvoiceReference,
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "v-col",
                        {
                          staticClass:
                            "py-0 px-1 text-caption font-weight-bold",
                        },
                        [
                          _c("ReadonlyField", {
                            attrs: {
                              label: _vm.$t("label.company"),
                              value: _vm.pratica.supplierName,
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-col",
            {
              staticClass: "pa-1 d-flex align-center",
              staticStyle: { width: "12rem" },
              attrs: { cols: "auto" },
            },
            [
              _c(
                "v-flex",
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0 px-1 text-caption font-weight-bold" },
                    [
                      _c("ReadonlyField", {
                        attrs: {
                          label: _vm.$t("label.userLabel"),
                          value: _vm.pratica.userName,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "ma-1" }),
                  _c(
                    "v-row",
                    { staticClass: "ma-0" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "py-0 px-1 text-caption font-weight-bold",
                          attrs: { cols: "auto" },
                        },
                        [
                          _c("ReadonlyField", {
                            attrs: {
                              label: _vm.$t("label.incoterms"),
                              value: _vm.pratica.shipperIncoterms,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "py-0 px-1 ml-2 text-caption font-weight-bold",
                        },
                        [
                          _c("ReadonlyField", {
                            attrs: {
                              label: _vm.$t("label.pol"),
                              value: _vm.pratica.pol,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "py-0 px-1 text-caption font-weight-bold",
                        },
                        [
                          _c("ReadonlyField", {
                            attrs: {
                              label: _vm.$t("label.pod"),
                              value: _vm.pratica.pod,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-col",
            { staticClass: "pa-1 d-flex align-center" },
            [
              _c("PraticaStepper", {
                key: _vm.pratica.id,
                attrs: { pratica: _vm.pratica },
              }),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-col",
            {
              staticClass: "pa-1",
              staticStyle: { display: "grid", "align-items": "center" },
              attrs: { cols: "auto" },
            },
            [
              _c(
                "v-row",
                { staticClass: "ma-0", attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { fab: "", depressed: "", "x-small": "" },
                          on: {
                            click: function ($event) {
                              _vm.expandTracking = !_vm.expandTracking
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-arrow-expand-vertical")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "ma-0", attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { fab: "", depressed: "", "x-small": "" },
                          on: { click: _vm.goToPratica },
                        },
                        [_c("v-icon", [_vm._v("mdi-eye")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showSelection
            ? _c("v-divider", { attrs: { vertical: "" } })
            : _vm._e(),
          _vm.showSelection
            ? _c(
                "v-col",
                { staticClass: "d-flex pa-1", attrs: { cols: "auto" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "align-self-center",
                      staticStyle: { "max-height": "fit-content" },
                    },
                    [
                      _c("v-checkbox", {
                        staticClass: "checkbox ma-0 pa-0",
                        attrs: { color: "primary", "hide-details": "" },
                        on: { change: _vm.addRowToSelection },
                        model: {
                          value: _vm.selected,
                          callback: function ($$v) {
                            _vm.selected = $$v
                          },
                          expression: "selected",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "space-around", "no-gutters": "" } },
        [
          !_vm.isMastersped
            ? _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "12" } },
                [
                  _vm.pratica.saleChannel === "20"
                    ? _c("PraticaMareExtension", {
                        attrs: {
                          open: _vm.expandTracking,
                          pratica: _vm.pratica,
                        },
                      })
                    : _vm._e(),
                  _vm.pratica.saleChannel === "40"
                    ? _c("PraticaAereoExtension", {
                        attrs: {
                          open: _vm.expandTracking,
                          pratica: _vm.pratica,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "12" } },
                [
                  _c("Imballi", {
                    attrs: {
                      open: _vm.expandTracking,
                      documentNbr: _vm.pratica.documentNbr,
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }