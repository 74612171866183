<template>
  <v-form ref="form" :class="sendingEmail ? 'no-pointer' : ''" width="100%">
    <v-row
      class="ma-0"
      @dragover.prevent="dragging = true"
      @dragenter.prevent="dragging = true"
      @dragleave.prevent="dragging = false"
    >
      <v-col class="pa-0">
        <v-flex>
          <!-- <v-row class="ma-0">
            <v-col class="pa-0">
              <v-text-field
                v-model="newMessage.from"
                class="text-caption"
                hide-details
                dense
                flat
                solo
              >
                <template v-slot:prepend-inner>
                  <span class="text-body-1 text--disabled">
                    From
                  </span>
                </template>
              </v-text-field>
            </v-col>
          </v-row> 
          <v-divider class="my-0 mx-3" /> -->
          <v-row class="ma-0">
            <v-col class="pa-0">
              <v-combobox
                ref="to"
                v-model="newMessage.to"
                :items="emailAddresses"
                item-text="email"
                item-value="email"
                :return-object="false"
                class="noicon-combo"
                :menu-props="{
                  closeOnClick: true,
                }"
                @change="onChange($event, 'to')"
                @input.native="onInput($event, 'to')"
                :search-input.sync="searchInputMultiCombobox.to"
                hide-details
                multiple
                chips
                dense
                flat
                solo
                @focus="showButtons()"
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title dense>
                      <b class="text-overline">{{ data.item.agent }}</b>
                      <!-- <span class="text-caption">
                        {{ formatContactCountry(data.item) }}
                      </span> -->
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span>{{ data.item.email }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
                <template v-slot:selection="data">
                  <v-chip
                    :key="JSON.stringify(data.item)"
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    :disabled="data.disabled"
                    class="contact-chip"
                    @click:close="removeContact(data.item, 'to')"
                    close
                    small
                  >
                    <v-avatar class="primary white--text" left> {{ data?.item?.slice(0, 1)?.toUpperCase() }} </v-avatar>
                    {{ data.item }}
                  </v-chip>
                </template>
                <template v-slot:prepend-inner>
                  <span class="text--disabled">
                    To
                  </span>
                </template>
                <template v-slot:append-outer>
                  <v-btn
                    x-small
                    text
                    rounded
                    color="transparent"
                    class="primary--text"
                    v-if="showCcButton"
                    @click="toggleCc"
                  >
                    Cc
                  </v-btn>
                  <v-btn
                    x-small
                    text
                    rounded
                    color="transparent"
                    class="primary--text ml-1"
                    v-if="showBccButton"
                    @click="toggleBcc"
                  >
                    Bcc
                  </v-btn>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
          <v-divider class="my-0 mx-3" />
          <v-row class="ma-0" v-if="showCc">
            <v-col class="pa-0">
              <v-combobox
                ref="cc"
                v-model="newMessage.cc"
                :items="emailAddresses"
                item-text="email"
                item-value="email"
                :return-object="false"
                :menu-props="{
                  closeOnClick: true,
                }"
                :append-icon="newMessage.cc.length ? '' : 'mdi-close'"
                @click:append="showCc = false"
                @change="onChange($event, 'cc')"
                @input.native="onInput($event, 'cc')"
                :search-input.sync="searchInputMultiCombobox.cc"
                hide-details
                small-chips
                multiple
                dense
                flat
                solo
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title dense>
                      <b class="text-overline">{{ data.item.agent }}</b>
                      <!-- <span class="text-caption">
                        {{ formatContactCountry(data.item) }}
                      </span> -->
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span>{{ data.item.email }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
                <template v-slot:selection="data">
                  <v-chip
                    :key="JSON.stringify(data.item)"
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    :disabled="data.disabled"
                    class="contact-chip"
                    @click:close="removeContact(data.item, 'cc')"
                    close
                    small
                  >
                    <v-avatar class="primary white--text" left>{{ data?.item?.slice(0, 1)?.toUpperCase() }}</v-avatar>
                    {{ data.item }}
                  </v-chip>
                </template>
                <template v-slot:prepend-inner>
                  <span class="text--disabled">
                    Cc
                  </span>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
          <v-divider class="my-0 mx-3" v-if="showCc" />
          <v-row class="ma-0" v-if="showBcc">
            <v-col class="pa-0">
              <v-combobox
                ref="bcc"
                v-model="newMessage.bcc"
                :items="emailAddresses"
                item-text="email"
                item-value="email"
                :return-object="false"
                class="noicon-combo"
                :menu-props="{
                  closeOnClick: true,
                }"
                :append-icon="newMessage.bcc.length ? '' : 'mdi-close'"
                @click:append="showBcc = false"
                @change="onChange($event, 'bcc')"
                @input.native="onInput($event, 'bcc')"
                :search-input.sync="searchInputMultiCombobox.bcc"
                hide-details
                small-chips
                multiple
                dense
                flat
                solo
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title dense>
                      <b class="text-overline">{{ data.item.agent }}</b>
                      <!-- <span class="text-caption">
                        {{ formatContactCountry(data.item) }}
                      </span> -->
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span>{{ data.item.email }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
                <template v-slot:selection="data">
                  <v-chip
                    :key="JSON.stringify(data.item)"
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    :disabled="data.disabled"
                    class="contact-chip"
                    @click:close="removeContact(data.item, 'bcc')"
                    close
                    small
                  >
                    <v-avatar class="primary white--text" left>{{ data?.item?.slice(0, 1)?.toUpperCase() }}</v-avatar>
                    {{ data.item }}
                  </v-chip>
                </template>
                <template v-slot:prepend-inner>
                  <span class="text--disabled">
                    Bcc
                  </span>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
        </v-flex>
        <v-divider class="my-0 mx-3" v-if="showBcc" />
        <v-flex @click="hideButtons">
          <v-row class="ma-0">
            <v-col class="pa-0">
              <v-text-field
                v-model="newMessage.subject"
                hide-details
                dense
                flat
                solo
              >
                <template v-slot:prepend-inner>
                  <span class="text--disabled">
                    Subject
                  </span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <!-- <v-divider class="my-0 mx-3" /> -->
          <v-row class="ma-0 pl-1 pb-1">
            <v-col
              :class="dragover ? 'dashed' : ''"
              @dragover.prevent="dragover = true"
              @dragleave.prevent="dragover = false"
              @drop.prevent="onDrop($event)"
            >
              <div class="no-pointer" v-if="dragging">
                <v-tiptap v-model="newMessage.text" :toolbar="toolbar" />
                <div
                  v-if="dragover"
                  class="d-flex justify-center align-items-center mt-3"
                >
                  <v-icon size="60" class="mr-3">
                    mdi-cloud-upload
                  </v-icon>
                  <span>
                    {{ $t('label.dropFilesHere') }}
                  </span>
                </div>
              </div>
              <div v-else>
                <v-tiptap v-model="newMessage.text" :toolbar="toolbar" />
              </div>

              <v-row dense class="d-flex mt-1 mx-0" v-if="uploadedFiles.length > 0">
                <v-col
                  cols="auto"
                  class="pa-0 mr-2"
                  v-for="(attach, i) in uploadedFiles"
                  :key="i"
                >
                  <div style="max-width: max-content;">
                    <v-icon>mdi-attachment</v-icon>
                    <span class="text-caption mx-1">{{ attach.name }}</span>
                    <v-icon x-small style="padding-top: 0.1rem" @click="removeFile(attach.name)">mdi-close</v-icon>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-flex>
      </v-col>
      <v-divider vertical class="my-2 mx-1" />
      <v-col
        cols="auto"
        class="d-flex flex-column align-center pa-0 pt-1 pr-1"
        @click="hideButtons"
      >
        <div class="progress" v-if="sendingEmail">
          <v-progress-circular size="25" indeterminate color="primary" />
        </div>
        <v-btn icon @click="sendEmail" v-if="!sendingEmail && !isReply">
          <v-icon>mdi-email</v-icon>
        </v-btn>
        <v-btn icon @click="updateMail" v-if="!sendingEmail && isReply">
          <v-icon>mdi-email</v-icon>
        </v-btn>
        <div class="mt-1">
          <v-file-input
            class="pa-0 ma-0"
            prepend-icon="mdi-attachment"
            hide-input
            @change="uploadFile"
          />
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Email from '@/mixins/Email'

export default {
  name: 'MailNew',
  props: ['emailAddresses', 'isVisible', 'isReply', 'msgTemplate'],
  mixins: [Email],
  emits: ['email-sent'],
  data() {
    return {
      newMessage: {},
      sendingEmail: false,

      showCcButton: false,
      showBccButton: false,
      showCc: false,
      showBcc: false,

      customToolbar: [],

      dragging: false,
      dragover: false,
      uploadedFiles: [],

      searchInputMultiCombobox: {},

      snackbar: false,
      message: '',

      toolbar: [
        'bold',
        'italic',
        'underline',
        'strike',
        '|',
        'headings',
        '|',
        'left',
        'center',
        'right',
        'justify',
        '|',
        'bulletList',
        'orderedList',
        '|',
        'link',
      ],
    }
  },
  watch: {
    msgTemplate: {
      handler(template) {
        this.newMessage = this._.cloneDeep(template)
      },
      deep: true,
    },
  },
  methods: {
    // Contacts handling
    onInput(event, type) {
      let email = event.srcElement.value
      if (email.includes(';') || email.includes(',')) {
        email = email.slice(0, -1)
        if (this.validateEmail(email)) {
          this.newMessage[type].push(email)
          this.blurCombobox(type)
        }
      }
    },
    onChange(value, type) {
      if (!this.isVisible) return
      let cleanedList = []
      value.forEach((email) => {
        if (this.validateEmail(email)) {
          if (!cleanedList.includes(email)) cleanedList.push(email)
        } else {
          this.setSnackbarMessage({ text: this.$t('label.insertValidEmail'), color: 'error' })
        }
      })
      this.newMessage[type] = cleanedList
      setTimeout(() => {
        this.blurCombobox(type)
      }, 10)
    },
    validateEmail(email) {
      let validated = true
      var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
      let verifiedMail = emailPattern.test(email)
      if (!verifiedMail) {
        validated = false
      }
      return validated
    },
    focusCombobox(type) {
      this.$nextTick(() => {
        this.$refs[type].focus()
      })
    },
    blurCombobox(type) {
      this.$nextTick(() => {
        this.$refs[type].blur()
      })
    },
    removeContact(item, type) {
      let msgList = this.newMessage[type]
      const contact = msgList.find((el) => el === item)
      let index = msgList.indexOf(contact)
      msgList.splice(index, 1)
    },
    // Attachments handling
    uploadFile(file) {
      this.uploadedFiles.push(file)
    },
    removeFile(fileName) {
      const index = this.uploadedFiles.findIndex(
        (file) => file.name === fileName,
      )
      if (index > -1) this.uploadedFiles.splice(index, 1)
    },
    onDrop(e) {
      this.dragging = false
      this.dragover = false
      Object.keys(e.dataTransfer.files).forEach((key) => this.uploadedFiles.push(e.dataTransfer.files[key]))
    },
    // Cc & Bcc handling
    hideButtons() {
      setTimeout(() => {
        if (!this.newMessage.bcc || this.newMessage.bcc.length == 0)
          this.showBcc = false
        this.showBccButton = false
        if (!this.newMessage.cc || this.newMessage.cc.length == 0)
          this.showCc = false
        this.showCcButton = false
      }, 200)
    },
    showButtons() {
      this.showCcButton = true
      this.showBccButton = true
    },
    toggleCc() {
      this.showCc = true
      this.focusCombobox('cc')
    },
    toggleBcc() {
      this.showBcc = true
      this.focusCombobox('bcc')
    },
    checkRecipientsLists() {
      if (this.msgTemplate.cc.length) this.showCc = true
      if (this.msgTemplate.bcc.length) this.showBcc = true
    },
    // Utils
    resetForm() {
      this.showCc = false
      this.showBcc = false
      this.newMessage = this.msgTemplate
      this.uploadedFiles = []
      this.checkRecipientsLists()
    },
    // formatContactCountry(item) {
    //   let dest = item.destination
    //   let country = item.country
    //   return dest ? `- ${dest}` + (country ? ` (${country})` : ``) : ``
    // },
  },
  mounted() {
    this.checkRecipientsLists()
    this.newMessage = this._.cloneDeep(this.msgTemplate)
  },
}
</script>

<style scoped>
.v-tiptap::v-deep .ProseMirror {
  max-height: 50vh;
  overflow-y: auto;
}
.v-file-input::v-deep .v-input__prepend-outer {
  margin: 0 !important;
}
.v-file-input::v-deep .v-input__icon {
  width: 2.25rem;
}
.noicon-combo::v-deep .v-select__slot .v-input__icon {
  display: none !important;
}
.noicon-combo::v-deep input {
  font-size: smaller;
}
.v-select::v-deep .v-chip {
  margin-left: 0 !important;
}
.contact-chip {
  padding-left: 6px;
}
.v-list-iem .v-list-iem--active::before {
  opacity: 0;
}
.dashed {
  border: dashed 2px var(--v-primary-base);
}
.no-pointer {
  pointer-events: none;
}
.progress {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
}
.from {
  line-height: 0;
}
/*.row {
  border: solid 1px red;
}
.col {
  border: solid 1px orange;
}*/
</style>
