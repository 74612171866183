<template>
  <div class="d-flex justify-end pr-1">
    <v-btn icon @click="dowloadFile" v-if="showDownload">
      <v-icon color="primary">mdi-download</v-icon>
    </v-btn>
    <ButtonConfirm
      @enabled="(enabled) => (showDownload = !enabled)"
      @submit="deleteFile"
    />
  </div>
</template>

<script>
import ButtonConfirm from '@/components/elements/global/ButtonConfirm'

export default {
  name: 'PraticaDocumentActions',
  props: ['document'],
  emits: ['delete-file', 'download-file'],
  components: { ButtonConfirm },
  data() {
    return {
      showDownload: true,
    }
  },
  methods: {
    dowloadFile() {
      this.$emit('download-file', this.document?.id)
    },
    deleteFile() {
      this.$emit('delete-file', this.document?.id)
    },
  },
}
</script>
