var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "stepper-wrapper", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "ma-0 fill-height" },
        [
          _c(
            "v-col",
            { staticClass: "d-table fill-height pa-1" },
            [
              _c(
                "v-container",
                {
                  staticClass: "d-block pa-0 overflow-y-auto",
                  attrs: { "fill-height": "", fluid: "" },
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-0" },
                    _vm._l(_vm.parties, function (item, i) {
                      return _c(
                        "v-col",
                        { key: i, staticClass: "pa-1", attrs: { cols: "3" } },
                        [
                          _c("ShippingInstructionParty", {
                            ref: "partyForm",
                            refInFor: true,
                            attrs: {
                              company: _vm.company,
                              preselectedParty: item,
                              partyFunction: item.partyFunction,
                            },
                            on: {
                              "select-party": (party) =>
                                _vm.createUpdateParty(
                                  party,
                                  item.partyFunction
                                ),
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "flex-grow-0 ma-0" },
        [
          _c(
            "v-col",
            { staticClass: "d-flex pa-2" },
            [
              _c(
                "v-btn",
                {
                  attrs: { small: "", icon: "", color: "primary" },
                  on: { click: _vm.exitRequest },
                },
                [
                  _c("v-icon", { attrs: { size: "30" } }, [
                    _vm._v("mdi-arrow-left-circle"),
                  ]),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  attrs: { small: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("step", false)
                    },
                  },
                },
                [
                  _c("v-icon", [_vm._v("mdi-step-backward")]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("label.back")))]),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { small: "", color: "primary" },
                  on: { click: _vm.submitParties },
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.$t("label.next")))]),
                  _c("v-icon", [_vm._v("mdi-play")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }